import { Messages } from '../../../domain/entities/chat';
import ChatRepository from '../../ports/ChatRepository';

export type fetchMessagesOnBoardUseCase = (
  boardCode: string,
) => Promise<Messages | undefined>;

export const buildFetchMessagesOnBoard = (dependencies: {
  chatRepo: ChatRepository;
}): fetchMessagesOnBoardUseCase => {
  const { chatRepo } = dependencies;

  const fetchMessagesOnBoard: fetchMessagesOnBoardUseCase = async boardCode =>
    chatRepo.fetchMessagesOnBoard(boardCode);

  return fetchMessagesOnBoard;
};
