import { URLsType } from '../../constants/urls';
import { ChoosePlan, PaymentHistoryData } from '../../domain/entities/payment';
import { GenericResponse } from '../../domain/entities/rest';
import PaymentRepository from '../../usecases/ports/PaymentRepository';
import { HttpAdapter } from './../../usecases/ports/HttpAdapter';

export default class PaymentRepositoryImpl implements PaymentRepository {
  httpAdapter: HttpAdapter;
  urls: URLsType['payment'];

  constructor(httpAdapter: HttpAdapter, urls: URLsType['payment']) {
    this.httpAdapter = httpAdapter;
    this.urls = urls;
  }

  choosePlan = async (
    planId: number,
    paymentMethodId: string,
  ): Promise<ChoosePlan> => {
    const response = await this.httpAdapter.post(this.urls.choosePlan, {
      plan_id: planId,
      paymentMethodId: paymentMethodId,
    });

    return response.data.data;
  };

  fetchPaymentHistory = async (
    customerId: string,
  ): Promise<PaymentHistoryData[]> => {
    const response = await this.httpAdapter.get(this.urls.fetchPaymentHistory, {
      params: {
        customer_id: customerId,
      },
    });
    const data = response.data.data;

    return data.map(
      (
        value: PaymentHistoryData & {
          payment_date: Date;
          invoice_code: string;
          invoice_url: string;
          payment_price: number;
          payment_status: string;
        },
      ) => {
        return {
          ...value,
          paymentDate: value.payment_date,
          invoiceCode: value.invoice_code,
          invoiceUrl: value.invoice_url,
          paymentPrice: value.payment_price,
          paymentStatus: value.payment_status,
        };
      },
    );
  };

  fetchInvoicePdf = async (invoiceCode: string): Promise<void> => {
    await this.httpAdapter
      .get(this.urls.fetchInvoicePdf, {
        params: {
          invoice_code: invoiceCode,
        },
        responseType: 'arraybuffer',
      })
      .then(response => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: 'application/pdf' }),
        );
        const tempLink = document.createElement('a');
        tempLink.href = url;
        tempLink.setAttribute('download', `${invoiceCode}.pdf`);
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
      });
  };

  attacthCardPayment = async (
    paymentMethodId: string,
  ): Promise<GenericResponse> => {
    const response = await this.httpAdapter.post(this.urls.attachPayment, {
      payment_method_id: paymentMethodId,
    });

    return response;
  };

  cancelSub = async (subscriptionId: string): Promise<GenericResponse> => {
    const response = await this.httpAdapter.post(this.urls.cancelSub, {
      subscriptionId: subscriptionId,
    });

    return response.message;
  };
}
