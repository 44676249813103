import { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import DefaultUser from '../../../assets/images/default-user.png';
import queryKeys from '../../../constants/queryKeys';
import theme from '../../../constants/themes';
import { Board, BoardCustomers } from '../../../domain/entities/board';
import { useAuthHooks } from '../../../hooks/auth';
import { useBoardHooks } from '../../../hooks/board';
import { useGlobalState } from '../../../hooks/global';
import { useTeamHooks } from '../../../hooks/team';
import { handleTextOverflow } from '../../../utils/strings';
import { Options, Pencil, Plus, Share } from '../../atoms/Icons';
import TeamAdd from '../../atoms/Icons/TeamAdd';
import { AppLogo } from '../../atoms/Logos';
import { TextInput } from '../../atoms/TextInput';
import { Props as INotification } from '../../atoms/Toast/Component';
import { Tooltip } from '../../atoms/Tooltip';
import { BOARD_CODE_IF_NEW } from '../../pages/BoardEditor/Component';
import { AddTeamMemberModal } from '../../templates/AddTeamMemberModal';
import { ToastData } from '../../templates/DiagramEditor/types';
import { RoleSelectTemplateModal } from '../../templates/RoleSelectTemplateModal';
import { SelectTemplateModal } from '../../templates/SelectTemplateModal';
import { ShareModal } from '../../templates/ShareModal';
import { Dropdown } from '../Dropdown';
import { Menu } from '../Menu';

const Wrapper = styled.div`
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 12px 0 12px;
  justify-content: space-between;
  color: ${theme.colors.white};
  z-index: 999;
  background-color: ${theme.colors.primary};
`;

const HamburgerContainer = styled.div<{ isOpen?: boolean }>`
  width: 30px;
  height: 16px;
  display: none;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  cursor: pointer;
  margin-left: 15px;

  div {
    width: 100%;
    height: 2px;
    background-color: ${theme.colors.white};
    transition: width 0.25s ease-in-out;

    :nth-child(1) {
      width: 100%;
    }
    :nth-child(2) {
      width: ${props => (props.isOpen ? '20px' : '100%')};
    }
    :nth-child(3) {
      width: ${props => (props.isOpen ? '15px' : '100%')};
    }
  }

  &:hover {
    div {
      filter: brightness(0.75);
    }
  }

  @media ${theme.breakpoints.mobile} {
    display: flex;
  }

  @media ${theme.breakpoints.tablet} {
    display: flex;
  }
`;

const ContentsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledAppLogo = styled(AppLogo)`
  height: 50px;
`;

const NameLabel = styled.span``;

const StyledOptionsIcon = styled(Options)`
  margin: 0 29.5px 0 29.5px;
  cursor: pointer;
  &:hover path {
    filter: brightness(0.75);
  }
`;

const UserLabel = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  display: inline-block;

  @media ${theme.breakpoints.mobile} {
    color: ${theme.colors.textColor};
  }

  @media ${theme.breakpoints.tablet} {
    color: ${theme.colors.textColor};
  }
`;

const StyledShareIcon = styled(Share)`
  margin-right: 10.1px;
`;

const StyledPlusIcon = styled(Plus)`
  margin-right: 10.1px;
`;

const AddMemberButton = styled.span`
  border: 1px solid ${theme.colors.white};
  box-sizing: border-box;
  border-radius: 5px;
  height: 40px;
  min-width: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  cursor: pointer;
  :hover {
    filter: brightness(0.75);
  }

  @media ${theme.breakpoints.mobile} {
    min-width: fit-content;
  }
`;

const AvatarImage = styled.img`
  margin: 0 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

const ProfileMenu = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    filter: brightness(0.75);
  }

  @media ${theme.breakpoints.mobile} {
    margin-top: 20px;
    cursor: default;
    :hover {
      filter: brightness(1);
    }
  }

  @media ${theme.breakpoints.tablet} {
    margin-top: 20px;
    cursor: default;
    :hover {
      filter: brightness(1);
    }
  }
`;
const StyledPencil = styled(Pencil)`
  cursor: pointer;
  margin-left: 5px;
  :hover {
    filter: brightness(0.75);
  }
`;
const StyledTextInput = styled(TextInput)`
  &:focus {
    outline: none;
  }
  border-color: transparent;
`;

const StyleLabel = styled.span`
  font-size: 13px;
`;

const StyledMenu = styled(Menu)``;

const StyledDropdown = styled(Dropdown)`
  display: flex;

  @media ${theme.breakpoints.mobile} {
    display: none;
  }

  @media ${theme.breakpoints.tablet} {
    display: none;
  }
`;

const HamburgerWrapper = styled.div`
  display: none;

  @media ${theme.breakpoints.mobile} {
    display: block;
  }

  @media ${theme.breakpoints.tablet} {
    display: block;
  }
`;

const MenuWrapper = styled.div<{ isOpen?: boolean }>`
  width: 100%;
  background-color: ${theme.colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50px;
  left: 0;
  transition: all 0.5s ease-in-out;
  height: ${props => (props.isOpen ? '195px' : '0')};
  overflow: hidden;
  box-shadow: 0px 15px 10px -15px rgba(0, 0, 0, 0.3);
`;

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  div:nth-child(1) {
    margin-top: 18px;
  }

  div:not(:first-of-type) {
    margin-top: 13px;
  }

  div {
    font-family: ${theme.fonts.primary};
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.2px;
    text-decoration: none;
    color: ${theme.colors.textColor};

    &:hover {
      cursor: pointer;
    }

    &.active {
      color: #60bf91;
      cursor: pointer;
    }
  }
`;

const ButtonContentMobile = styled.div`
  display: none;

  @media ${theme.breakpoints.mobile} {
    display: flex;
  }
`;

const ButtonContent = styled.div`
  display: flex;

  @media ${theme.breakpoints.mobile} {
    display: none;
  }
`;

export type Props = {
  className?: string;
  currentBoardData?: Board;
  boardCode?: string;
  currentBoardCustomer?: BoardCustomers;
  isFetchingCurrentBoardCustomer?: boolean;
  onClickCopy?: () => void;
  onClickKeep?: () => void;
  onClickPaste?: () => void;
  onClickUndo?: () => void;
  onClickRedo?: () => void;
  onClickPng?: () => void;
  onClickPdf?: () => void;
  onClickXML?: () => void;
};

const Component = ({
  className,
  currentBoardData,
  boardCode,
  currentBoardCustomer,
  isFetchingCurrentBoardCustomer,
  onClickCopy = () => {},
  onClickPaste = () => {},
  onClickUndo = () => {},
  onClickRedo = () => {},
  onClickKeep,
  onClickPng,
  onClickPdf,
  onClickXML,
}: Props): React.ReactElement => {
  const diagramName = currentBoardData?.name;
  const { t } = useTranslation();
  const location = useLocation();
  const { state } = location;
  const queryClient = useQueryClient();
  const { useCurrentUser, useToast } = useGlobalState();
  const { currentUser, setCurrentUser } = useCurrentUser;
  const { setNotification } = useToast;
  const { useBoardAPI } = useBoardHooks();
  const {
    updateBoard,
    createBoardFromTemplate,
    fetchTemplateList,
  } = useBoardAPI();
  const { useTeam } = useTeamHooks();
  const { fetchMyBoardTeams } = useTeam();
  const [newName, setNewName] = useState<string | undefined>(undefined);
  const [isSelectingTemplate, setIsSelectingTemplate] = useState<boolean>(
    false,
  );
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [toastTemplate, setToastTemplate] = useState<ToastData | null>(null);
  const [isShareModalOpen, setIsShareModalOpen] = useState<boolean>(false);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isAddTeamMemberModalOpen, setIsAddTeamMemberModal] = useState<boolean>(
    false,
  );
  const { useLogout } = useAuthHooks();
  const { logout } = useLogout();
  const navigate = useNavigate();

  const typedState = state as { teamId: string } | null;
  const numericTeamId = Number(typedState?.teamId);
  const selectedTeamId = !isNaN(numericTeamId) ? numericTeamId : undefined;

  const handleCloseToast = () => {
    setNotification(undefined);
  };

  const { data: teamList = [], isFetching: isFetchingTeams } = useQuery(
    [queryKeys.FETCH_MY_BOARD_TEAMS],
    () => {
      return fetchMyBoardTeams();
    },
    {
      onError: (response: { response: AxiosResponse }) => {
        const res: AxiosResponse = response.response;
        const data = res?.data as any;
        if (data?.status_code === 401) {
          setCurrentUser(undefined);
          navigate('/signin');
        } else if (data?.status_code === 422) {
          let messages = '';
          Object.keys(data.errors).forEach(key => {
            messages += data.errors[key][0];
          });
          setNotification({
            isOpen: true,
            message: messages,
            type: 'error',
            position: 'top-right',
            onClose: handleCloseToast,
          });
        } else {
          setNotification({
            isOpen: true,
            message: data?.errors?.messages,
            type: 'error',
            position: 'top-right',
            onClose: handleCloseToast,
          });
        }
      },
    },
  );

  const { mutate: duplicateBoard } = useMutation(
    (payload: {
      name: string;
      teamId?: number | undefined;
      diagram: string;
    }) => {
      return createBoardFromTemplate({
        name: payload.name,
        teamId: payload.teamId,
        diagramUrl: payload.diagram,
      });
    },
    {
      onSuccess: code => {
        navigate(`/board/${code}`);
      },
      onError: response => {
        setNotification({
          isOpen: true,
          message: String(response),
          type: 'error',
          position: 'top-right',
          onClose: () => setNotification(undefined),
        });
      },
    },
  );
  const { mutate: updateName } = useMutation(
    async (newName: string) => {
      if (!boardCode) {
        throw new Error('invalid value for board code');
      }
      return updateBoard({
        name: newName,
        boardCode,
      });
    },
    {
      onSuccess: (response, requestPayload) => {
        queryClient.invalidateQueries([
          queryKeys.FETCH_BOARD_BY_CODE,
          boardCode,
        ]);
        setNewName(requestPayload);
        setIsEditing(false);
      },
      onError: (res: { response: AxiosResponse }) => {
        const data: { errors?: { name?: string; message?: string[] } } =
          res?.response?.data;
        setNotification({
          isOpen: true,
          message:
            data?.errors?.name || Array.isArray(data?.errors?.message)
              ? data?.errors?.message?.join()
              : String(res),
          type: 'error',
          position: 'top-right',
          onClose: handleCloseToast,
        });
      },
    },
  );
  const { mutate: createNewBoard, isLoading: isAddingBoard } = useMutation(
    ({
      name,
      diagramUrl,
      teamId,
      xmlStr,
    }: {
      name: string;
      teamId?: number;
      diagramUrl?: string;
      xmlStr?: string;
    }) => {
      return createBoardFromTemplate({
        name,
        teamId,
        diagramUrl,
        xmlStr,
      });
    },
    {
      onSuccess: code => {
        setIsSelectingTemplate(false);
        setToastTemplate(null);
        navigate(`/board/${code}`);
      },
      onError: (res: { response: AxiosResponse }) => {
        const data = res?.response?.data as any;
        setNotification({
          isOpen: true,
          message: data?.errors?.message[0] || String(res),
          type: 'error',
          position: 'top-right',
          onClose: () => setNotification(undefined),
        });
      },
    },
  );
  const { data: templates = [] } = useQuery(
    [queryKeys.FETCH_TEMPLATE_LIST],
    async () => {
      const response = await fetchTemplateList();
      return response;
    },
  );
  const templateList = templates.map(data => ({
    name: data.name,
    id: data.id,
    diagramUrl: data.diagramUrl,
  }));

  useEffect(() => {
    setNewName(diagramName);
  }, [diagramName]);

  useEffect(() => {
    if (boardCode === BOARD_CODE_IF_NEW) {
      setIsSelectingTemplate(true);
    } else {
      setIsSelectingTemplate(false);
    }
  }, [boardCode]);
  const displayImage = currentUser?.user?.customer?.avatar || DefaultUser;
  const displayName =
    currentUser?.user?.customer?.fullname ||
    currentUser?.user?.customer?.full_name;
  const isInBoardPage = !!boardCode;
  const isTeamOwner =
    teamList.some(team => team.is_owner) &&
    currentUser?.user?.customer?.plan?.type !== 'FREE';

  return (
    <Wrapper className={className}>
      <ContentsWrapper>
        <a onClick={() => navigate('/my-board/personal')}>
          <StyledAppLogo isWhite />
        </a>
        {isInBoardPage && (
          <>
            <StyledMenu
              menu={[
                {
                  label: '新規',
                  subMenu: [
                    ...templateList.map(template => ({
                      label: template.name,
                      onClick: () => {
                        createNewBoard({
                          name: template.name,
                          diagramUrl: template.diagramUrl,
                          teamId: selectedTeamId,
                        });
                      },
                    })),
                    {
                      label: '他テンプレート',
                      onClick: () => {
                        setIsSelectingTemplate(true);
                      },
                    },
                  ],
                },
                {
                  label: '複製',
                  onClick: () => {
                    if (currentBoardData?.diagramFile) {
                      duplicateBoard({
                        name: newName || '',
                        teamId: selectedTeamId,
                        diagram: currentBoardData.diagramFile,
                      });
                    }
                  },
                },
                {
                  label: '編集',
                  subMenu: [
                    {
                      label: 'コピー',
                      onClick: onClickCopy,
                    },
                    {
                      label: '貼り付け',
                      onClick: onClickPaste,
                    },
                    {
                      label: '元に戻す',
                      onClick: onClickUndo,
                    },
                    { label: 'やり直し', onClick: onClickRedo },
                  ],
                },
                {
                  label: '保存',
                  onClick: onClickKeep,
                },
                {
                  label: 'Export',
                  subMenu: [
                    {
                      label: 'PDF',
                      onClick: onClickPdf,
                    },
                    {
                      label: 'PNG',
                      onClick: onClickPng,
                    },
                    {
                      label: 'XML',
                      onClick: onClickXML,
                    },
                  ],
                },
                {
                  label: '共有',
                  onClick: () => {
                    setIsShareModalOpen(true);
                  },
                },
                {
                  label: '閉じる',
                  onClick: () => {
                    if (currentBoardData?.team?.id) {
                      navigate(`/my-board/team/${currentBoardData.team.id}`);
                    } else {
                      navigate('/my-board/personal');
                    }
                  },
                },
              ]}>
              <StyledOptionsIcon />
            </StyledMenu>
            <NameLabel>
              {isEditing ? (
                <StyledTextInput
                  onChangeText={setNewName}
                  autoFocus
                  value={newName || ''}
                  onKeyUp={event => {
                    if (event.key === 'Enter' && newName) {
                      updateName(newName);
                    }
                  }}
                  onBlur={() => {
                    setNewName(diagramName);
                    setIsEditing(false);
                  }}
                />
              ) : (
                <>
                  {newName && newName.length <= 15 ? (
                    newName
                  ) : (
                    <Tooltip label={newName || ''}>
                      <span>{handleTextOverflow(newName || '', 15)}</span>
                    </Tooltip>
                  )}
                  {diagramName && (
                    <StyledPencil
                      onClick={() => {
                        setIsEditing(true);
                      }}
                    />
                  )}
                </>
              )}
            </NameLabel>
          </>
        )}
      </ContentsWrapper>
      <ContentsWrapper>
        {!diagramName ? (
          <>
            {isTeamOwner && (
              <AddMemberButton onClick={() => setIsAddTeamMemberModal(true)}>
                <ButtonContentMobile>
                  <TeamAdd />
                </ButtonContentMobile>
                <ButtonContent>
                  <StyledPlusIcon />
                  <StyleLabel>{t('addTeamMember')}</StyleLabel>
                </ButtonContent>
              </AddMemberButton>
            )}
          </>
        ) : (
          <AddMemberButton onClick={() => setIsShareModalOpen(true)}>
            <ButtonContentMobile>
              <TeamAdd />
            </ButtonContentMobile>
            <ButtonContent>
              <StyledShareIcon />
              <StyleLabel>{t('boardSharing')}</StyleLabel>
            </ButtonContent>
          </AddMemberButton>
        )}
        <StyledDropdown
          menu={
            isTeamOwner
              ? [
                  {
                    label: 'プロフィール',
                    onClick: () => {
                      navigate('/my-board/profile');
                    },
                  },
                  {
                    label: 'チーム管理',
                    onClick: () => {
                      navigate('/my-board/member-management');
                    },
                  },
                  {
                    label: 'サインアウト',
                    onClick: () => {
                      if (boardCode) {
                        window.history.pushState({ fromBoard: true }, '');
                      }
                      logout();
                    },
                  },
                ]
              : [
                  {
                    label: 'プロフィール',
                    onClick: () => {
                      navigate('/my-board/profile');
                    },
                  },
                  {
                    label: 'サインアウト',
                    onClick: () => {
                      if (boardCode) {
                        window.history.pushState({ fromBoard: true }, '');
                      }
                      logout();
                    },
                  },
                ]
          }>
          <ProfileMenu>
            <AvatarImage src={displayImage} />
            {displayName && displayName.length <= 15 ? (
              <UserLabel>
                {displayName && handleTextOverflow(displayName, 15)}
              </UserLabel>
            ) : (
              <Tooltip label={displayName || ''} position="bottom-end">
                <UserLabel>
                  {displayName && handleTextOverflow(displayName, 15)}
                </UserLabel>
              </Tooltip>
            )}
          </ProfileMenu>
        </StyledDropdown>
        <HamburgerWrapper>
          <HamburgerContainer
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            isOpen={isMenuOpen}>
            <div />
            <div />
            <div />
          </HamburgerContainer>
          <MenuWrapper isOpen={isMenuOpen}>
            <ProfileMenu>
              <AvatarImage src={displayImage} />
              {displayName && displayName.length <= 15 ? (
                <UserLabel>
                  {displayName && handleTextOverflow(displayName, 15)}
                </UserLabel>
              ) : (
                <Tooltip label={displayName || ''} position="bottom-end">
                  <UserLabel>
                    {displayName && handleTextOverflow(displayName, 15)}
                  </UserLabel>
                </Tooltip>
              )}
            </ProfileMenu>
            <LinksWrapper>
              <div onClick={() => navigate('/my-board/profile')}>
                プロフィール
              </div>
              {isTeamOwner ? (
                <div onClick={() => navigate('/my-board/member-management')}>
                  チーム管理
                </div>
              ) : (
                ''
              )}
              <div
                onClick={() => {
                  if (boardCode) {
                    window.history.pushState({ fromBoard: true }, '');
                  }
                  logout();
                }}>
                サインアウト
              </div>
            </LinksWrapper>
          </MenuWrapper>
        </HamburgerWrapper>
      </ContentsWrapper>
      <ShareModal
        boardCode={boardCode}
        boardName={newName}
        isOpen={isShareModalOpen}
        onClose={() => {
          setIsShareModalOpen(false);
        }}
      />
      {!isSelectingTemplate &&
        isInBoardPage &&
        !isFetchingCurrentBoardCustomer &&
        !isSelectingTemplate &&
        isInBoardPage &&
        !isFetchingCurrentBoardCustomer &&
        !!currentBoardCustomer &&
        !currentBoardCustomer.roleName &&
        !currentBoardCustomer.roleContent && (
          <RoleSelectTemplateModal
            boardCode={boardCode}
            onPressRole={() => {}}
            handleSelectRole={(toast: INotification) => {
              setNotification({
                ...toast,
                onClose: () => setNotification(undefined),
              });
            }}
          />
        )}
      <AddTeamMemberModal
        isOpen={isAddTeamMemberModalOpen}
        teamList={teamList}
        isFetchingTeams={isFetchingTeams}
        onClose={() => {
          setIsAddTeamMemberModal(false);
        }}
        onCloseToast={() => setNotification(undefined)}
        onInviteTeamMember={toast => {
          setNotification(toast);
          setIsAddTeamMemberModal(false);
        }}
        onDeleteTeamMember={toast => setNotification(toast)}
      />
      <SelectTemplateModal
        isOpen={isSelectingTemplate}
        templates={templateList}
        disabled={isAddingBoard}
        toastTemplate={toastTemplate}
        isAddingBoard={isAddingBoard}
        onClickDiagram={payload => {
          if (payload.xmlStr) {
            createNewBoard({
              name: payload.name,
              xmlStr: payload.xmlStr,
              teamId: selectedTeamId,
            });
          }
        }}
        onClose={
          boardCode !== BOARD_CODE_IF_NEW
            ? () => {
                setIsSelectingTemplate(false);
              }
            : () => navigate('/my-board/personal')
        }
      />
    </Wrapper>
  );
};

export default Component;
