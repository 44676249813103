import { createContext, useContext } from 'react';
import { Plan } from '../../domain/entities/plan';
import { GenericResponse } from '../../domain/entities/rest';

export type PlanHooks = {
  useFetchPlans: () => {
    fetchPlans: () => Promise<Plan[]>;
    selectFreePlan: (
      planId: number,
      customerId: number,
    ) => Promise<GenericResponse>;
  };
};

export const PlanHooksContext = createContext<PlanHooks | null>(null);

export const usePlanHooks = (): PlanHooks => {
  const context = useContext(PlanHooksContext);
  if (!context) {
    throw new Error(`Context not instantiated`);
  }
  return context;
};
