import React from 'react';

type Props = {
  [key: string]: string | number | Function | object;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      viewBox="0 0 24 24"
      width="24"
      height="24"
      fill="#3d4752"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 13a2 2 0 00-2 2v1a2 2 0 104 0v-1a2 2 0 00-2-2zm1 3v-1a1 1 0 10-2 0v1a1 1 0 102 0z"></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 10V7.5C7 4.966 9.082 3 11.5 3h1C14.918 3 17 4.966 17 7.5V10a2 2 0 012 2v7a2 2 0 01-2 2H7a2 2 0 01-2-2v-7a2 2 0 012-2zm1-2.5C8 5.534 9.618 4 11.5 4h1C14.382 4 16 5.534 16 7.5V10H8V7.5zm9 3.5H7a1 1 0 00-1 1v7a1 1 0 001 1h10a1 1 0 001-1v-7a1 1 0 00-1-1z"></path>
    </svg>
  );
};

export default Component;
