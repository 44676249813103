import { GenericResponse } from '../../../domain/entities/rest';
import AuthService from '../../ports/AuthService';

export type forgotPasswordUseCase = (email: string) => Promise<GenericResponse>;

export const buildForgotPassword = (dependencies: {
  authService: AuthService;
}): forgotPasswordUseCase => {
  const { authService } = dependencies;

  const forgotPassword: forgotPasswordUseCase = email =>
    authService.forgotPassword(email);

  return forgotPassword;
};
