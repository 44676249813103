import React, { SVGProps } from 'react';

type Props = SVGProps<SVGSVGElement>;

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="20"
      height="20"
      viewBox="0 0 487 487"
      {...props}>
      <g>
        <path
          d="M482,234.841l-116.5-67.262c-3.094-1.787-6.906-1.787-10,0c-3.094,1.786-5,5.088-5,8.66v57.28H10c-5.523,0-10,4.478-10,10
   c0,5.522,4.477,10,10,10h340.5v57.242c0,3.571,1.906,6.874,5,8.66c1.547,0.893,3.272,1.34,5,1.34c1.727,0,3.453-0.446,5-1.34
   L482,252.16c3.094-1.786,5-5.088,5-8.66C487,239.929,485.094,236.627,482,234.841z M370.5,293.441v-99.882L457,243.5L370.5,293.441
   z"
        />
      </g>
    </svg>
  );
};

export default Component;
