import React, { useState } from 'react';
import Picker from 'emoji-picker-react';
import styled from 'styled-components';
import { Sticky } from '../../../molecules/Sticky';
import { StickyData } from '../types';
import { LinkDetails } from '../../../molecules/LinkDetails';
import { LinkForm } from '../../../molecules/LinkForm';
import Emojis from './common/Emojis';
import DragLine from './common/DragLine';
import Moveable from './common/Moveable';
import Overlay from './common/Overlay';
import { useGlobalState } from '../../../../hooks/global';
import { useBoardHooks } from '../../../../hooks/board';
import { createReactorObj, removeUndefined } from '../../../../utils/objects';
import { useNodesUtils, useNodeUtils } from './hooks/nodes';
import { extractRotateFromTransform } from '../../../../utils/strings';

const EmojiPickerWrapper = styled.div`
  z-index: 1;
  position: absolute;
  left: 425px;
  top: -50px;
  z-index: 1;
`;

const Component = (props: {
  id: string;
  data: StickyData;
  selected: boolean;
  xPos: number;
  yPos: number;
}): React.ReactElement => {
  const { id, data, xPos, yPos } = props;
  const {
    height,
    width,
    label,
    style,
    link,
    emojis,
    emojiNames,
    reactorNames,
  } = data;
  const { useCurrentUser } = useGlobalState();
  const { currentUser } = useCurrentUser;
  const currentCustomerId = currentUser?.user?.customer?.id;
  const {
    setData,
    setLabel,
    setStyle,
    handleCopyNodes,
    isMultipleSelected,
    isSelectedByCurrentUser,
  } = useNodeUtils(id);
  const { handlePasteNodes, removeNodes } = useNodesUtils();
  const { customersNodeIdsState } = useBoardHooks();
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const [isSelectingEmoji, setIsSelectingEmoji] = useState<boolean>(false);
  const [isSettingLink, setIsSettingLink] = useState<boolean>(false);
  const [isLinkDetailsOpen, setIsLinkDetailsOpen] = useState<boolean>(false);

  const { customersSelectedNodeIds } = customersNodeIdsState;
  const selectedNodeIdsState = customersSelectedNodeIds
    ? Object.values(customersSelectedNodeIds).flat()
    : [];
  const isSelected = isSelectedByCurrentUser && props.selected;
  const isLocked = selectedNodeIdsState.includes(id);

  const onClickDelete = () => {
    removeNodes([id]);
  };

  return (
    <>
      {isSelected && !isMultipleSelected && !isLocked && (
        <DragLine
          parentId={id}
          width={width}
          height={height}
          position={{ x: xPos, y: yPos }}
          angle={
            style?.transform ? extractRotateFromTransform(style.transform) : 0
          }
        />
      )}
      {isSelected && !isMultipleSelected && !isLocked && (
        <>
          {isSettingLink && (
            <LinkForm
              onSubmit={link => {
                setData({ ...props.data, link });
                setIsSettingLink(false);
              }}
              initialLink={link}
              style={{ position: 'absolute', top: -30, left: 250, zIndex: 1 }}
            />
          )}
        </>
      )}
      {link && (
        <LinkDetails
          style={{ position: 'absolute', top: -35, left: width / 2 - 12 }}
          isOpen={isLinkDetailsOpen}
          link={link}
          onClickDelete={() => {
            const newData = JSON.parse(
              JSON.stringify({ ...props.data, link: undefined }),
            );
            setData(newData);
            setIsSettingLink(false);
          }}
          onClickEdit={() => {
            setIsSettingLink(true);
          }}
          onClickLinkIcon={() => {
            !isLocked && setIsLinkDetailsOpen(!isLinkDetailsOpen);
          }}
        />
      )}
      <Sticky
        width={width}
        height={height}
        label={label}
        style={style}
        onRef={setRef}
        isSelected={isSelected && !isMultipleSelected && !isLocked}
        onChangeLabel={setLabel}
        onChangeColor={color => {
          setStyle({ ...style, backgroundColor: color });
        }}
        onClickEmoji={(open?: boolean) => {
          if (open) {
            setIsSelectingEmoji(open);
          } else {
            setIsSelectingEmoji(!isSelectingEmoji);
          }
        }}
        isEmojiOpen={isSelectingEmoji}
        onClickLink={(open?: boolean) => {
          if (open) {
            setIsSettingLink(open);
          } else {
            setIsSettingLink(!isSettingLink);
          }
        }}
        isLinkOpen={isSettingLink}
        onTextRealign={align => {
          setStyle({
            ...style,
            textAlign: align,
          });
        }}
        onClickCopy={handleCopyNodes}
        onClickPaste={handlePasteNodes}
        onClickDelete={onClickDelete}
      />
      <Emojis
        style={{ position: 'absolute', left: 0, bottom: -10, zIndex: 1 }}
        onClickEmoji={emoji => {
          if (!isLocked && currentCustomerId) {
            const newEmojiId = `hex-${currentCustomerId}-${emoji}`;
            if (emojis?.[newEmojiId]) {
              setData({
                ...props.data,
                emojis: removeUndefined({
                  ...props.data.emojis,
                  [newEmojiId]: undefined,
                }) as { [key: string]: string },
              });
            } else {
              setData({
                ...props.data,
                emojis: {
                  ...props.data.emojis,
                  [newEmojiId]: emoji,
                },
              });
            }
            setIsSelectingEmoji(false);
          }
        }}
        emojis={Object.entries(emojis || {}).map(([key, value]) => {
          const emojiHex = key.split('-')[2];
          return {
            emoji: value,
            userId: key.split('-')[1],
            name: emojiNames?.[`hex-${emojiHex}`],
          };
        })}
        customers={{
          ...createReactorObj(reactorNames),
          [`${currentCustomerId}`]: 'You',
        }}
      />
      {isSelected && isSelectingEmoji && !isLocked && (
        <EmojiPickerWrapper className="nowheel">
          <Picker
            groupVisibility={{
              smileys_people: true,
              animals_nature: false,
              food_drink: false,
              travel_places: false,
              activities: false,
              objects: false,
              symbols: false,
              flags: false,
              recently_used: false,
            }}
            onEmojiClick={(event, emojiObj) => {
              const emojiHex = emojiObj?.emoji?.codePointAt(0)?.toString(16);
              if (currentCustomerId && emojiHex) {
                const newEmojiId = `hex-${currentCustomerId}-${emojiHex}`;
                const readableEmojiHex = `hex-${emojiHex}`;
                const newEmojiName = emojiObj.names[emojiObj.names.length - 1];
                const reactorId = `reactor-${currentCustomerId}`;
                if (emojis?.[newEmojiId]) {
                  setData({
                    ...props.data,
                    emojis: removeUndefined({
                      ...props.data.emojis,
                      [newEmojiId]: undefined,
                    }) as { [key: string]: string },
                  });
                } else {
                  setData({
                    ...props.data,
                    emojis: {
                      ...props.data.emojis,
                      [newEmojiId]: emojiHex,
                    },
                    emojiNames: removeUndefined({
                      ...emojiNames,
                      [readableEmojiHex]: newEmojiName,
                    }) as { [key: string]: string },
                    reactorNames: {
                      ...reactorNames,
                      [reactorId]:
                        currentUser?.user?.customer?.fullname ||
                        currentUser?.user?.customer?.fullname,
                    },
                  });
                }
                setIsSelectingEmoji(false);
              }
            }}
          />
        </EmojiPickerWrapper>
      )}
      {isSelected && ref && !isLocked && (
        <Moveable
          target={ref}
          width={width}
          height={height}
          parentStyles={style}
          parentId={id}
        />
      )}
      {isLocked && (
        <Overlay width={width} height={height} transform={style?.transform} />
      )}
    </>
  );
};

export default Component;
