import { AxiosError } from 'axios';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Skeleton from '@mui/material/Skeleton';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import male1 from '../../../assets/images/male-1.png';
import queryKeys from '../../../constants/queryKeys';
import theme from '../../../constants/themes';
import { BoardCustomers } from '../../../domain/entities/board';
import { useBoardHooks } from '../../../hooks/board';
import { useGlobalState } from '../../../hooks/global';
import { arrayToObject } from '../../../utils/objects';
import { DownArrow, UpArrow } from '../../atoms/Icons';
import { Toast } from '../../atoms/Toast';
import { Props as INotification } from '../../atoms/Toast/Component';
import { MemberCard } from '../../molecules/MemberCard';
import { BOARD_CODE_IF_NEW } from '../../pages/BoardEditor/Component';
import { VALID_DROP_AREA_NODE_CLASS_NAME } from '../../templates/DiagramEditor/components/DropAreaNode';
import { RoleDetail } from '../RoleDetail';

const BackDrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
`;

const RoleWrapper = styled.div`
  height: 100%;
  overflow: auto;
`;
const MemberCardsWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledUpArrow = styled(UpArrow)<{ $disabled?: boolean }>`
  margin-bottom: 6px;
  min-height: 8px;
  cursor: pointer;
  filter: brightness(${props => (props.$disabled ? 1 : 0.7)});
  pointer-events: ${props => (props.$disabled ? 'none' : 'auto')};
  path {
    transition: all 0.2s ease;
  }
  &:hover path {
    fill: ${theme.colors.notifToastBorder};
  }
`;

const StyledDownArrow = styled(DownArrow)<{ $disabled?: boolean }>`
  margin-top: 9px;
  min-height: 8px;
  cursor: pointer;
  filter: brightness(${props => (props.$disabled ? 1 : 0.7)});
  pointer-events: ${props => (props.$disabled ? 'none' : 'auto')};
  path {
    transition: all 0.2s ease;
  }
  &:hover path {
    fill: ${theme.colors.notifToastBorder};
  }
`;

const StyledMemberCard = styled(MemberCard)`
  margin-bottom: 15px;
`;

const MemberListWrapper = styled.div`
  flex: 1;
  display: flex;
  overflow: auto;
  flex-direction: column;
  min-width: 150px;
`;

const CARD_HEIGHT = 200;
const CARD_MARGIN = 15;

export type Props = {
  className?: string;
  boardCode?: string;
};

const Component = ({ className, boardCode }: Props): React.ReactElement => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [selectedCustomer, setSelectedCustomer] = useState<
    BoardCustomers | undefined
  >(undefined);
  const [activeCustomers, setActiveCustomers] = useState<BoardCustomers[]>([]);
  const {
    useBoardAPI,
    useBoardSocket,
    boardVoteState = { boardVote: '' },
    activeCustomerIdsState = {
      activeCustomerIds: [],
      setActiveCustomerIds: () => {},
    },
  } = useBoardHooks();
  const { boardVote } = boardVoteState;
  const { activeCustomerIds } = activeCustomerIdsState;
  const { useCurrentUser } = useGlobalState();
  const [canScroll, setCanScroll] = useState<{ top: boolean; bottom: boolean }>(
    { top: false, bottom: false },
  );
  const containerRef = useRef<HTMLDivElement>(null);
  const [notification, setNotification] = useState<INotification | null>(null);
  const { sendVote } = useBoardSocket();
  const { fetchBoardCustomers, voteCustomer } = useBoardAPI();
  const { currentUser } = useCurrentUser;
  const {
    data: customers = [],
    isFetching: isFetchingBoardCustomers,
  } = useQuery(
    [queryKeys.FETCH_BOARD_CUSTOMERS, boardCode],
    () => {
      if (!boardCode || boardCode === BOARD_CODE_IF_NEW) {
        throw new Error('invalid value for `board code`');
      }
      return fetchBoardCustomers(boardCode);
    },
    {
      onSuccess: response => {
        const isCurrentUserIncluded =
          currentUser?.user?.customer?.id !== undefined &&
          response?.length &&
          response?.find(
            member => member.id === currentUser?.user?.customer?.id,
          );
        if (!isCurrentUserIncluded) {
          navigate('/my-board/personal');
        }
        setCanScroll({ top: false, bottom: !!response.length });
      },
    },
  );

  useEffect(() => {
    if (boardVote) {
      const voteData = JSON.parse(boardVote);
      setSelectedCustomer(prev => {
        return prev && prev?.id === voteData.customer_id
          ? {
              ...prev,
              badges: voteData.badges,
              stars: voteData.total_vote,
            }
          : prev;
      });
      setActiveCustomers(prev => {
        return prev.map(item =>
          item.id === voteData.customer_id
            ? {
                ...item,
                badges: voteData.badges,
                stars: voteData.total_vote,
              }
            : item,
        );
      });
    }
  }, [boardVote]);

  useEffect(() => {
    if (activeCustomerIds.length) {
      queryClient.invalidateQueries([
        queryKeys.FETCH_BOARD_CUSTOMERS,
        boardCode,
      ]);
    }
  }, [activeCustomerIds]);

  useEffect(() => {
    if (customers.length > 0 && activeCustomerIds.length > 0) {
      const cutomersObj = arrayToObject(customers) as {
        [id: number]: BoardCustomers;
      };

      const uniqueCustomerIds = Array.from(new Set(activeCustomerIds));
      const activeUsers = uniqueCustomerIds.map(id => cutomersObj[id]);
      setActiveCustomers(activeUsers.filter(item => item !== undefined));
    }
  }, [customers, activeCustomerIds]);

  useEffect(() => {
    if (
      selectedCustomer &&
      activeCustomerIds &&
      !activeCustomerIds.some(item => item === selectedCustomer.id)
    ) {
      setSelectedCustomer(undefined);
    }
  }, [activeCustomerIds, selectedCustomer]);

  const onClick = (id: number) => {
    const selectedCustomer = activeCustomers.filter(
      customer => customer.id === id,
    );

    if (selectedCustomer.length > 0) {
      setSelectedCustomer(selectedCustomer[0]);
    }
  };

  const handleCloseToast = () => {
    setNotification(null);
  };

  const onClose = () => setSelectedCustomer(undefined);

  const {
    mutate: voteCustomerMutation,
    isLoading: isVotingCustomer,
  } = useMutation(
    async (customerId: number) => {
      if (!boardCode) {
        throw new Error('invalid field for board id');
      }
      return voteCustomer(boardCode, customerId);
    },
    {
      onSuccess: res => {
        if (res) {
          if (boardCode)
            sendVote(boardCode, {
              ...res.data?.data,
              time: new Date().getTime(),
            });
          if (res.data?.status_code === 201) {
            if (
              selectedCustomer &&
              selectedCustomer.id === res.data?.data?.customer_id
            ) {
              setSelectedCustomer(prev => {
                return prev
                  ? {
                      ...prev,
                      badges: res.data?.data.badges,
                      stars: res.data?.data.total_vote,
                    }
                  : prev;
              });
            }

            setActiveCustomers(prev => {
              return prev.map(item =>
                item.id === res.data?.data?.customer_id
                  ? {
                      ...item,
                      badges: res.data?.data.badges,
                      stars: res.data?.data.total_vote,
                    }
                  : item,
              );
            });
          }
        }
      },
      onError: res => {
        const err = res as AxiosError;
        setNotification({
          isOpen: true,
          message: String(err?.message)
            .replace('Error:', '')
            .trim(),
          type: 'error',
          position: 'top-right',
          onClose: handleCloseToast,
        });
      },
    },
  );

  const onClickVote = useCallback(
    (customerId: number) => {
      voteCustomerMutation(customerId);
    },
    [boardCode],
  );

  const sortedCustomers = activeCustomers.sort((a, b) =>
    a.id > b.id ? 1 : -1,
  );

  return (
    <>
      {selectedCustomer ? <BackDrop onClick={onClose} /> : ''}
      <Wrapper className={className}>
        <MemberCardsWrapper>
          <StyledUpArrow
            onClick={() => {
              const pixelsFromTop = containerRef.current?.scrollTop || 0;
              const newPosition = !(pixelsFromTop % (CARD_HEIGHT + CARD_MARGIN))
                ? -(CARD_HEIGHT + CARD_MARGIN)
                : -(pixelsFromTop % (CARD_HEIGHT + CARD_MARGIN));

              containerRef?.current?.scrollBy({
                top: newPosition,
                behavior: 'smooth',
              });
            }}
            $disabled={!canScroll?.top}
          />
          <MemberListWrapper
            ref={containerRef}
            onScroll={debounce(ev => {
              const isAtTop = !containerRef.current?.scrollTop;
              const isAtBottom =
                ev.target.scrollHeight - ev.target.scrollTop ===
                ev.target.clientHeight;
              setCanScroll({ top: !isAtTop, bottom: !isAtBottom });
            }, 200)}
            className={VALID_DROP_AREA_NODE_CLASS_NAME}>
            {isFetchingBoardCustomers && activeCustomers.length < 1 ? (
              <Skeleton variant="rectangular" width={150} height={225} />
            ) : (
              <>
                {sortedCustomers.map(item => (
                  <StyledMemberCard
                    id={item.id}
                    onClick={onClick}
                    key={`${item.id}-customer`}
                    name={item.name}
                    role={item.role}
                    englishRole={item.engRole}
                    boards={item.boards}
                    stars={item.stars}
                    trophies={item.trophies}
                    badge={item.badges}
                    displayPhoto={item.roleImage ? item.roleImage : male1}
                    onClickVote={onClickVote}
                    isLoading={isVotingCustomer}
                  />
                ))}
              </>
            )}
          </MemberListWrapper>
          <StyledDownArrow
            onClick={() => {
              const pixelsFromTop = containerRef.current?.scrollTop || 0;
              const newPosition =
                CARD_HEIGHT +
                CARD_MARGIN -
                (pixelsFromTop % (CARD_HEIGHT + CARD_MARGIN));
              containerRef?.current?.scrollBy({
                top: newPosition,
                behavior: 'smooth',
              });
            }}
            $disabled={!canScroll?.bottom}
          />
        </MemberCardsWrapper>
        {(selectedCustomer && (
          <RoleWrapper>
            <RoleDetail customer={selectedCustomer} onClose={onClose} />
          </RoleWrapper>
        )) ||
          null}
      </Wrapper>
      {notification ? <Toast {...notification} /> : null}
    </>
  );
};

export default Component;
