import theme from '../themes';

export const CURSOR_COLOR_OPTIONS = [
  { fontColor: theme.colors.white, cursorColor: 'red' },
  { fontColor: theme.colors.white, cursorColor: '#f96680' },
  { fontColor: theme.colors.white, cursorColor: '#1376f1' },
  { fontColor: theme.colors.white, cursorColor: '#ca31cd' },
  { fontColor: theme.colors.black, cursorColor: 'orange' },
  { fontColor: theme.colors.black, cursorColor: theme.colors.lightBtn },
  { fontColor: theme.colors.white, cursorColor: 'green' },
  { fontColor: theme.colors.white, cursorColor: theme.colors.primaryBtn },
  { fontColor: theme.colors.white, cursorColor: theme.colors.notifToastBorder },
  { fontColor: theme.colors.white, cursorColor: '#2F8EAC' },
  {
    fontColor: theme.colors.white,
    cursorColor: theme.colors.gameCardWrapperBaseColor,
  },
];
