import React from 'react';

type Props = {
  [key: string]: string | number | Function;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="200"
      height="200"
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M194.118 77.5811H5.88215C2.63367 77.5811 0 80.2147 0 83.4632V189.345C0 192.594 2.63367 195.227 5.88215 195.227H194.117C197.366 195.227 199.999 192.594 199.999 189.345V83.4639C200 80.2147 197.366 77.5811 194.118 77.5811Z"
        fill="#BFF796"
      />
      <path
        d="M194.118 77.5811H99.0776V195.228H194.118C197.367 195.228 200 192.594 200 189.346V83.4639C200 80.2147 197.367 77.5811 194.118 77.5811Z"
        fill="#AFE286"
      />
      <path
        d="M136.287 125.226L190.124 91.578C192.723 89.9531 193.514 86.5282 191.889 83.9288C190.265 81.3288 186.838 80.5403 184.24 82.1638L100 134.814L15.7603 82.1638C13.163 80.5389 9.7367 81.3295 8.11111 83.9288C6.48619 86.5282 7.27677 89.9531 9.87609 91.578L63.7131 125.226L9.95151 178.988C7.78384 181.156 7.78384 184.67 9.95151 186.839C11.0357 187.922 12.4559 188.464 13.8768 188.464C15.297 188.464 16.7178 187.922 17.802 186.839L73.3757 131.266L97.0579 146.067C97.9576 146.629 98.9791 146.911 100 146.911C101.021 146.911 102.042 146.629 102.942 146.067L126.624 131.266L182.198 186.839C183.282 187.922 184.702 188.464 186.123 188.464C187.544 188.464 188.964 187.922 190.048 186.839C192.216 184.67 192.216 181.156 190.048 178.988L136.287 125.226Z"
        fill="#86C867"
      />
      <path
        d="M136.287 125.227L190.124 91.578C192.723 89.9531 193.514 86.5282 191.889 83.9289C190.265 81.3289 186.839 80.5403 184.24 82.1639L100 134.814L99.0776 134.238V146.825C99.3834 146.877 99.6911 146.911 100 146.911C101.021 146.911 102.042 146.631 102.942 146.068L126.624 131.266L182.198 186.839C183.282 187.923 184.703 188.465 186.123 188.465C187.544 188.465 188.964 187.923 190.049 186.839C192.216 184.671 192.216 181.156 190.049 178.989L136.287 125.227Z"
        fill="#6CAA4B"
      />
      <path
        d="M146.789 55.6247C145.879 53.4267 143.734 51.9937 141.355 51.9937L126.56 51.9944V10.6536C126.56 9.09404 125.94 7.59775 124.837 6.49404C123.734 5.39101 122.237 4.77148 120.678 4.77148L79.3228 4.77216C76.0743 4.77216 73.4406 7.40583 73.4406 10.6543V51.9944H58.6453C56.2662 51.9944 54.1214 53.428 53.211 55.6254C52.2999 57.824 52.8036 60.3533 54.4857 62.0361L95.8406 103.39C96.9894 104.538 98.4945 105.113 100 105.113C101.506 105.113 103.011 104.538 104.16 103.39L145.515 62.0355C147.197 60.3533 147.701 57.8227 146.789 55.6247Z"
        fill="#17AB93"
      />
      <path
        d="M146.789 55.6247C145.879 53.4267 143.734 51.9937 141.355 51.9937L126.56 51.9944V10.6536C126.56 9.09404 125.94 7.59775 124.837 6.49404C123.734 5.39101 122.237 4.77148 120.678 4.77148H99.0776V105.034C99.3834 105.083 99.6911 105.113 100 105.113C101.506 105.113 103.011 104.538 104.16 103.39L145.515 62.0355C147.197 60.3533 147.701 57.8227 146.789 55.6247Z"
        fill="#11937A"
      />
    </svg>
  );
};

export default Component;
