import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { RightArrow, UploadCloud } from '../../atoms/Icons';
import { Button } from '../../atoms/Button';
import createDOMPurify from 'dompurify';
import theme from '../../../constants/themes';
import { TopBodyCarousel, TopFaq, TopHowToUse, TopFeature, TopPrice } from '.';
import { Plan } from '../../../domain/entities/plan';
import {
  BannerContent,
  CarouselContent as CarouselContentType,
  FaqContent,
  FeatureContent,
  HowToUseContent,
} from './types';
import { Container } from '@mui/material';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import InlineEditor from '@itsmitchyyy/ckeditor5-build-inline';
import { useMutation } from 'react-query';
import { usePageHooks } from '../../../hooks/page';
import { Toast } from '../../atoms/Toast';
import { Props as INotification } from '../../atoms/Toast/Component';
import UploadAdapter from '../../../utils/UploadAdapter';
import urls from '../../../constants/urls';
import { replaceNbsp } from '../../../utils/strings';

const StyledContainer = styled(Container)`
  margin-top: 5em;
  margin-bottom: 8em;

  @media ${theme.breakpoints.tablet} {
    && {
      display: none;
    }
  }
  @media ${theme.breakpoints.mobile} {
    && {
      display: none;
    }
  }
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 2em;
`;

const MainLeftContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  gap: 4em;
`;

const MainRightContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;

  .uploadFileImageSize {
    width: 500px;
    height: 500px;
    object-fit: cover;
    object-position: center;
  }
`;

const MainDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h1,
  h2,
  h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 43px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #737373;
  }
`;

const StyledSignUpBtn = styled(Button)`
  width: 320px;
  height: 90px;
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 40px;
  letter-spacing: -0.02em;
  padding: 1em;
`;

const StyledRightArrow = styled(RightArrow)`
  margin-left: 15px;
  width: 30px;
  height: 25px;
`;

const VideoContainer = styled.div<{ isUploading?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  ${({ isUploading }) =>
    isUploading
      ? `
    ::after {
      content: 'Uploading Media...';
      background: rgba(255, 255, 255, 0.5);
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${theme.colors.white}
    }`
      : ''}
`;

const StyledVideo = styled.video`
  width: 100%;
  height: 600px;
`;

const StyledIframe = styled.iframe`
  width: 100%;
  height: 600px;
`;

const UploadFileButton = styled.div`
  background: #ffd200;
  width: 90px;
  height: 70px;
  display: flex;
  position: absolute;
  top: 10px;
  right: 10px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
`;

const StyledUploadCLoud = styled(UploadCloud)`
  height: 30px;
  width: 30px;
`;

const ErrorWrapper = styled.div`
  color: ${theme.colors.errorToastText};
`;

const DivContainer = styled.div<{ hasError?: boolean }>`
  .ck.ck-editor__editable.ck-rounded-corners:not(.ck-editor__nested-editable) {
    ${({ hasError }) =>
      hasError ? `border: 1px solid ${theme.colors.errorToastText}` : ''};
  }
`;

const DOMPurify = createDOMPurify(window as any);

type BodyContentType = {
  bannerContent: BannerContent;
  featureContent: FeatureContent;
  howToUseContent: HowToUseContent;
  carouselContent: CarouselContentType;
  faqContent: FaqContent;
  planContent: Plan[] | undefined;
};

export type Props = {
  isFetching?: boolean;
  onPressRegistration: () => void;
  onPressPlan: (plan: string) => void;
  allowEditContent?: boolean;
  onChangeBannerData?: (data: BannerContent) => void;
  onChangeFeatureData?: (data: FeatureContent) => void;
  onChangeHowToUseData?: (data: HowToUseContent) => void;
  onChangeCarouselData?: (data: CarouselContentType) => void;
  onChangeFaqData?: (data: FaqContent) => void;
  onUploadMedia?: (uploading: boolean) => void;
  serverErrors?: any;
} & BodyContentType;

const Component = ({
  bannerContent,
  featureContent,
  howToUseContent,
  carouselContent,
  faqContent,
  planContent,
  isFetching,
  onPressPlan,
  onPressRegistration,
  allowEditContent,
  onChangeBannerData,
  onChangeFeatureData,
  onChangeHowToUseData,
  onChangeCarouselData,
  onChangeFaqData,
  onUploadMedia,
  serverErrors,
}: Props): React.ReactElement => {
  const { t } = useTranslation();
  const { useUploadMedia } = usePageHooks();
  const { uploadMedia } = useUploadMedia();
  const imageFile = useRef<HTMLInputElement | null>(null);
  const videoFile = useRef<HTMLInputElement | null>(null);
  const [selectedImageFile, setSelectedImageFile] = useState<string>();
  const [selectedVideoFile, setSelectedVideoFile] = useState<string>();
  const [selectedFileType, setSelectedFileType] = useState<string>('image');
  const [notification, setNotification] = useState<INotification | null>(null);
  const [carouselElements, setCarouselElements] = useState<any[]>([]);
  const [faqElements, setFaqElements] = useState<any[]>([]);

  const [mediaUploading, setMediaUploading] = useState<{
    type: 'image' | 'video' | string;
    uploading: boolean;
  }>({ type: '', uploading: false });

  const changeFileHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;

    if (!fileList) return;

    if (
      selectedFileType === 'video' &&
      fileList[0].type.replace(/(.*)\//g, '') !== 'mp4'
    ) {
      setNotification({
        isOpen: true,
        message: t('invalidVideoFormat'),
        type: 'error',
        position: 'top-right',
        onClose: () => setNotification(null),
      });
      return;
    }

    if (
      selectedFileType === 'image' &&
      !['jpeg', 'jpg', 'png', 'gif', 'svg+xml'].includes(
        fileList[0].type.replace(/(.*)\//g, ''),
      )
    ) {
      setNotification({
        isOpen: true,
        message: t('invalidImageFormat'),
        type: 'error',
        position: 'top-right',
        onClose: () => setNotification(null),
      });
      return;
    }

    const type = selectedFileType === 'video' ? 'video' : 'image';
    setMediaUploading({ type, uploading: true });

    if (onUploadMedia) {
      onUploadMedia(true);
    }

    uploadMediaMutation(fileList[0]);
  };

  const { mutate: uploadMediaMutation } = useMutation(
    (file: File) => {
      return uploadMedia(file);
    },
    {
      onSuccess: data => {
        if (selectedFileType === 'image') {
          setSelectedImageFile(data.url);
        }

        if (selectedFileType === 'video') {
          setSelectedVideoFile(data.url);
        }

        setSelectedFileType('');
        setMediaUploading({ ...mediaUploading, uploading: false });
        if (onUploadMedia) {
          onUploadMedia(false);
        }
      },
      onError: error => {
        if (onUploadMedia) {
          onUploadMedia(false);
        }
        setMediaUploading({ ...mediaUploading, uploading: false });
        setNotification({
          isOpen: true,
          message: String(error)
            .replace('Error:', '')
            .trim(),
          type: 'error',
          position: 'top-right',
          onClose: () => setNotification(null),
        });
      },
    },
  );

  const handleAddNewElement = (type: 'client' | 'faq') => {
    switch (type) {
      case 'client':
        setCarouselElements(
          carouselElements.concat({
            description: '',
            fullname: '',
            rating: 0,
            image: '',
            role: '',
          }),
        );
        break;

      case 'faq':
        setFaqElements(
          faqElements.concat({
            title: '',
            description: '',
          }),
        );
        break;
    }
  };

  useEffect(() => {
    if (onChangeBannerData) {
      if (selectedImageFile && selectedVideoFile) {
        onChangeBannerData({
          ...bannerContent,
          image: selectedImageFile,
          video: selectedVideoFile,
        });
        return;
      }

      if (selectedImageFile) {
        onChangeBannerData({ ...bannerContent, image: selectedImageFile });
        return;
      }

      if (selectedVideoFile) {
        onChangeBannerData({ ...bannerContent, video: selectedVideoFile });
        return;
      }
    }
  }, [selectedImageFile, selectedVideoFile]);

  useEffect(() => {
    if (!isFetching && carouselContent.contents?.length) {
      setCarouselElements(carouselContent.contents);
    }
  }, [isFetching, carouselContent]);

  useEffect(() => {
    if (!isFetching && faqContent.item?.length) {
      setFaqElements(faqContent.item);
    }
  }, [isFetching, faqContent]);

  return (
    <StyledContainer>
      {isFetching ? null : (
        <>
          <MainContainer id="body">
            <MainLeftContent>
              {allowEditContent ? (
                <DivContainer
                  hasError={
                    serverErrors && !!serverErrors?.[`banner.description`]
                  }>
                  <CKEditor
                    id="banner-description"
                    editor={InlineEditor}
                    config={{
                      extraAllowedContent: 'div(*)',
                      allowedContent: true,
                    }}
                    data={bannerContent?.description}
                    onReady={(editor: any) => {
                      editor.plugins.get(
                        'FileRepository',
                      ).createUploadAdapter = (loader: any) => {
                        return new UploadAdapter(loader, urls.page.uploadMedia);
                      };
                    }}
                    onChange={(event: any, editor: any) => {
                      const data = editor.getData();

                      if (onChangeBannerData) {
                        onChangeBannerData({
                          ...bannerContent,
                          description: data,
                        });
                      }
                    }}
                  />
                  {serverErrors && !!serverErrors?.[`banner.description`] && (
                    <ErrorWrapper>
                      <span>{serverErrors?.[`banner.description`]}</span>
                    </ErrorWrapper>
                  )}
                </DivContainer>
              ) : (
                <MainDetails
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      replaceNbsp(bannerContent?.description),
                    ),
                  }}
                />
              )}

              <StyledSignUpBtn
                title={t('topSignup')}
                theme="lightSecondary"
                icon={<StyledRightArrow />}
                iconPosition="right"
                onPress={onPressRegistration}
              />
            </MainLeftContent>
            <MainRightContent>
              <div style={{ position: 'relative' }}>
                <img
                  className="uploadFileImageSize"
                  src={selectedImageFile || bannerContent?.image}
                  alt=""
                />
                {allowEditContent && (
                  <UploadFileButton
                    onClick={() => {
                      if (imageFile) {
                        setSelectedFileType('image');
                        imageFile?.current?.click();
                      }
                    }}>
                    <StyledUploadCLoud />
                    <input
                      ref={imageFile}
                      type="file"
                      onChange={changeFileHandler}
                      id="file-banner"
                      style={{ display: 'none' }}
                    />
                  </UploadFileButton>
                )}
              </div>
              {serverErrors && !!serverErrors?.[`banner.image`] && (
                <ErrorWrapper>
                  <span>{serverErrors?.[`banner.image`]}</span>
                </ErrorWrapper>
              )}
            </MainRightContent>
          </MainContainer>

          <DivContainer>
            <VideoContainer
              isUploading={
                mediaUploading.type === 'video' && mediaUploading.uploading
              }>
              {bannerContent?.video?.includes('.mp4') ? (
                <StyledVideo
                  src={selectedVideoFile || bannerContent?.video}
                  controls>
                  Your browser does not support the video tag.
                </StyledVideo>
              ) : (
                <StyledIframe src={selectedVideoFile || bannerContent?.video} />
              )}
              {allowEditContent && (
                <UploadFileButton
                  onClick={() => {
                    if (videoFile) {
                      setSelectedFileType('video');
                      videoFile?.current?.click();
                    }
                  }}>
                  <StyledUploadCLoud />
                  <input
                    ref={videoFile}
                    type="file"
                    id="video"
                    onChange={changeFileHandler}
                    style={{ display: 'none' }}
                  />
                </UploadFileButton>
              )}
            </VideoContainer>
            {serverErrors && !!serverErrors?.[`banner.video`] && (
              <ErrorWrapper>
                <span>{serverErrors?.[`banner.video`]}</span>
              </ErrorWrapper>
            )}
          </DivContainer>
        </>
      )}
      <TopFeature
        serverErrors={serverErrors}
        onChangeFeatureData={(
          type: 'title' | 'description' | 'image',
          text: string,
          index: number,
        ) => {
          const featureData = { ...featureContent };
          featureData.cards[index][type] = text;
          if (onChangeFeatureData) {
            onChangeFeatureData(featureData);
          }
        }}
        allowEditContent={allowEditContent}
        isFetching={isFetching}
        featureContent={featureContent}
      />
      <TopHowToUse
        serverErrors={serverErrors}
        onUploadVideo={uploading => {
          if (onUploadMedia) onUploadMedia(uploading);
        }}
        onChangeHowToUseData={(
          type: 'image' | 'text',
          items: string | string[],
        ) => {
          const howToUseData = { ...howToUseContent };
          if (type === 'image') {
            howToUseData.media = items;
          } else {
            if (items === '') {
              howToUseData.item = [];
            } else {
              howToUseData.item = items as string[];
            }
          }

          if (onChangeHowToUseData) {
            onChangeHowToUseData(howToUseData);
          }
        }}
        allowEditContent={allowEditContent}
        isFetching={isFetching}
        howToUseContent={howToUseContent}
      />
      <TopPrice
        onSelectPlan={onPressPlan}
        planContent={planContent}
        isFetching={isFetching}
        allowEditContent={allowEditContent}
      />
      {isFetching ? null : (
        <>
          <TopBodyCarousel
            serverErrors={serverErrors}
            carouselContent={{ ...carouselContent, contents: carouselElements }}
            onAddNewElement={() => handleAddNewElement('client')}
            allowEditContent={allowEditContent}
            title={carouselContent.title}
            onChangeCarouselData={onChangeCarouselData}
          />
        </>
      )}
      <TopFaq
        serverErrors={serverErrors}
        onAddNewElement={() => handleAddNewElement('faq')}
        onChangeFaqData={onChangeFaqData}
        allowEditContent={allowEditContent}
        isFetching={isFetching}
        faqContent={{ ...faqContent, item: faqElements }}
      />
      {notification ? <Toast {...notification} /> : null}
    </StyledContainer>
  );
};

export default Component;
