import React from 'react';

type Props = {
  [key: string]: string | number | Function;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M1.77029 3.83684L1.77029 1.77118H0.354004L0.354004 7.15289H5.73566V5.7366H2.19532C4.30611 2.04159 8.89838 0.399357 12.9308 2.06968C16.2331 3.43749 18.23 6.64095 18.2297 10.0111C18.2296 11.1036 18.0197 12.2142 17.5763 13.2848C16.6988 15.403 15.049 17.0528 12.9308 17.9302C10.8125 18.8076 8.47939 18.8076 6.36115 17.9302L5.81918 19.2387C10.9134 21.3488 16.7746 18.921 18.8847 13.8268C19.4014 12.5793 19.646 11.2861 19.646 10.013C19.6459 6.08714 17.3196 2.35459 13.4728 0.761219C9.23598 -0.993801 4.46898 0.390577 1.77029 3.83684Z"
        fill="black"
      />
    </svg>
  );
};

export default Component;
