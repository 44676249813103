import { Form } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import theme from '../../../constants/themes';
import { Button } from '../../atoms/Button';
import { InlineErrorToast } from '../../atoms/InlineErrorToast';
import { TextInput } from '../../atoms/TextInput';

const FormWrapper = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 452px;
  height: max-content;

  @media ${theme.breakpoints.mobile} {
    width: 100%;
  }
`;

const SignUpLabel = styled.p`
  font-family: ${theme.fonts.primary};
  font-weight: 600;
  font-size: 33px;
  line-height: 130%;
  color: ${theme.colors.black};
  padding: 0;
  margin: 0;
`;

const ApiErrorWrapper = styled.div`
  width: 100%;
  height: min-content;
  margin-top: 16px;
`;

const InputLabel = styled.p`
  font-family: ${theme.fonts.primary};
  font-weight: 400;
  font-size: 16px;
  line-height: 175%;
  color: ${theme.colors.text02};
  width: 100%;
  text-align: left;
  padding: 0;
  margin: 0;
  margin-top: 16px;
`;

const StyledInput = styled(TextInput)`
  width: 100%;
  height: 44px;
  margin-top: 8px;
`;

const ButtonWrapper = styled.div`
  && {
    width: 188px;
    height: 44px;
    border-radius: 5px;
    margin-top: 24px;
  }
`;

const ErrorMessage = styled.p`
  width: 100%;
  font-family: ${theme.fonts.primary};
  font-weight: 400;
  font-size: 13px;
  line-height: 14px;
  color: ${theme.colors.textError};
  margin-top: 8px;
  text-align: left;
`;

export type Props = {
  name: string;
  email: string;
  password: string;
  isSignUpDisabled: boolean;
  errors: {
    name: string | undefined;
    email: string | undefined;
    password: string | undefined;
    apiErrors: {
      apiError?: string;
      multipleErrors: {
        [key: string]: string[];
      };
    };
  };
  onChangeName: (name: string) => void;
  onChangeEmail: (email: string) => void;
  onChangePassword: (password: string) => void;
  onPressSignUp: () => void;
  onBlurName: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlurEmail: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlurPassword: (e: React.FocusEvent<HTMLInputElement>) => void;
  setFieldTouched: (
    field: string,
    isTouched?: boolean | undefined,
    shouldValidate?: boolean | undefined,
  ) => void;
  isSigningUp: boolean;
};

const Component = ({
  name,
  email,
  password,
  isSignUpDisabled,
  errors,
  onChangeName,
  onChangeEmail,
  onChangePassword,
  onPressSignUp,
  onBlurName,
  onBlurEmail,
  onBlurPassword,
  setFieldTouched,
  isSigningUp,
}: Props): React.ReactElement => {
  const { t } = useTranslation();
  const [apiError, setApiError] = useState(errors.apiErrors.apiError);
  const [multiError, setMultiError] = useState(errors.apiErrors.multipleErrors);
  const hasMultipleApiErrors = Object.keys(multiError).length > 0;

  useEffect(() => {
    setApiError(errors.apiErrors.apiError);
  }, [errors.apiErrors.apiError]);

  useEffect(() => {
    setMultiError(errors.apiErrors.multipleErrors);
  }, [errors.apiErrors.multipleErrors]);

  const onCloseMultiError = (key: string) => {
    const newMultiError = {
      ...multiError,
      [key]: [],
    };
    setMultiError(newMultiError);
  };

  const handleOnPressSignUp = () => {
    setApiError('');
    setMultiError({});
    onPressSignUp();
  };

  return (
    <FormWrapper>
      <SignUpLabel>{t('signUpTitle')}</SignUpLabel>
      {apiError && (
        <ApiErrorWrapper>
          <InlineErrorToast
            message={apiError}
            onClose={() => setApiError('')}
          />
        </ApiErrorWrapper>
      )}
      {hasMultipleApiErrors && (
        <ApiErrorWrapper>
          {Object.keys(multiError).map(key => {
            const error = multiError[key];
            return !!error[0] ? (
              <InlineErrorToast
                key={key}
                message={error[0]}
                onClose={() => onCloseMultiError(key)}
              />
            ) : null;
          })}
        </ApiErrorWrapper>
      )}
      <InputLabel>{t('nameLabel')}</InputLabel>
      <StyledInput
        onChangeText={(value: string) => {
          setFieldTouched('name');
          onChangeName(value);
        }}
        value={name}
        isError={!!errors.name}
        onBlur={onBlurName}
      />
      {errors.name && <ErrorMessage>{errors.name}</ErrorMessage>}
      <InputLabel>{t('emailLabel')}</InputLabel>
      <StyledInput
        onChangeText={(value: string) => {
          setFieldTouched('email');
          onChangeEmail(value);
        }}
        value={email}
        isError={!!errors.email}
        onBlur={onBlurEmail}
      />
      {errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}
      <InputLabel>{t('passwordLabel')}</InputLabel>
      <StyledInput
        type="password"
        value={password}
        onChangeText={(value: string) => {
          setFieldTouched('password');
          onChangePassword(value);
        }}
        isError={!!errors.password}
        onBlur={onBlurPassword}
      />
      {errors.password && <ErrorMessage>{errors.password}</ErrorMessage>}
      <ButtonWrapper>
        <Button
          theme="secondary"
          title={t('register')}
          onPress={handleOnPressSignUp}
          disabled={isSignUpDisabled}
          type="submit"
          isLoading={isSigningUp}
        />
      </ButtonWrapper>
    </FormWrapper>
  );
};

export default Component;
