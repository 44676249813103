import React from 'react';
import styled from 'styled-components';
import theme from '../../../constants/themes';
import { useTranslation } from 'react-i18next';
import { Gift } from '../../atoms/Icons';
import { Button } from '../../atoms/Button';
import { adjustShade, setOpacity } from '../../../utils/colors';

const Container = styled.div`
  width: calc(100% - 135px);
  height: 168px;
  background-color: ${theme.colors.white};
  border: thin solid ${theme.colors.upgradePlanBorder};
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media ${theme.breakpoints.mobile} {
    width: 100%;
    display: block;
    padding: 20px 0;
  }

  @media ${theme.breakpoints.tablet} {
    width: 100%;
    display: block;
    padding: 20px 0;
  }
`;

const GiftWrapper = styled.div`
  width: max-content;
  height: max-content;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-left: 32px;

  @media ${theme.breakpoints.mobile} {
    width: 100%;
    justify-content: center;
    margin-left: 0;
  }

  @media ${theme.breakpoints.tablet} {
    width: 100%;
    justify-content: center;
    margin-left: 0;
  }
`;

const PriceText = styled.span<{ themeColor: string }>`
  font-family: ${theme.fonts.primary};
  font-weight: 700;
  font-size: 40px;
  line-height: 57px;
  letter-spacing: 0.2px;
  color: ${props => props.themeColor};
  margin-left: 17px;

  @media ${theme.breakpoints.mobile} {
    font-size: 24px;
    margin-left: 7px;
  }
`;

const SubscriptionWrapper = styled.div`
  height: max-content;
  width: max-content;
  display: flex;
  flex-direction: column;
  margin-left: 13px;
`;

const CurrencyLabel = styled.span<{ themeColor: string }>`
  font-family: ${theme.fonts.primary};
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.1px;
  color: ${props => props.themeColor};

  @media ${theme.breakpoints.mobile} {
    font-size: 16px;
  }
`;

const DurationLabel = styled.span<{ themeColor: string }>`
  font-family: ${theme.fonts.primary};
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: ${props => setOpacity(props.themeColor, 0.4)};
  margin-left: 10px;

  @media ${theme.breakpoints.mobile} {
    font-size: 12px;
  }
`;

const PlanLabel = styled.span<{ themeColor: string }>`
  font-family: ${theme.fonts.primary};
  font-weight: 700;
  font-size: 34px;
  line-height: 34px;
  letter-spacing: -0.02em;
  color: ${props => props.themeColor};
  margin-left: 35px;

  @media ${theme.breakpoints.mobile} {
    font-size: 18px;
    margin-left: 10px;
  }
`;

const StyledUpgradeBtn = styled(Button)<{ upgradeColor: string }>`
  width: 137.25px;
  height: 44px;
  border-radius: 37px;
  margin-right: 67.65px;
  background-color: ${props => props.upgradeColor};
  border-color: ${props => props.upgradeColor};
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);

  &:hover {
    background-color: ${props => adjustShade(props.upgradeColor, -10)};
    border-color: ${props => adjustShade(props.upgradeColor, -10)};
  }

  &:active {
    background-color: ${props => adjustShade(props.upgradeColor, -20)};
    border-color: ${props => adjustShade(props.upgradeColor, -20)};
  }

  @media ${theme.breakpoints.mobile} {
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  @media ${theme.breakpoints.tablet} {
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export type Props = {
  price: number;
  planTitle: string;
  themeColor: string;
  upgradeColor: string;
  className?: string;
  onPressUpgradePlan: () => void;
};

const Component = ({
  price,
  planTitle,
  themeColor,
  upgradeColor,
  className,
  onPressUpgradePlan,
}: Props): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <Container className={className}>
      <GiftWrapper>
        <Gift themecolor={themeColor} />
        <PriceText themeColor={themeColor}>{price}</PriceText>
        <SubscriptionWrapper>
          <CurrencyLabel themeColor={themeColor}>{t('yen')}</CurrencyLabel>
          <DurationLabel themeColor={themeColor}>/{t('month')}</DurationLabel>
        </SubscriptionWrapper>
        <PlanLabel themeColor={themeColor}>{planTitle}</PlanLabel>
      </GiftWrapper>
      <StyledUpgradeBtn
        theme="light"
        title={t('upgrade')}
        onPress={onPressUpgradePlan}
        upgradeColor={upgradeColor}
      />
    </Container>
  );
};

export default Component;
