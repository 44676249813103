import { Skeleton } from '@mui/material';
import { useGoogleLogin } from '@react-oauth/google';
import axios, { ResponseType } from 'axios';
import createDOMPurify from 'dompurify';
import React, { useCallback, useState } from 'react';
import {
  ReactFacebookFailureResponse,
  ReactFacebookLoginInfo,
} from 'react-facebook-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

import MicrosoftLogin from 'react-microsoft-login';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import theme from '../../../constants/themes';
import urls from '../../../constants/urls';
import { MicrosoftError } from '../../../domain/entities/errors';
import { useAuthHooks } from '../../../hooks/auth';
import { useLocalStorageHooks } from '../../../hooks/localStorage';
import { Button } from '../../atoms/Button';
import {
  Facebook,
  Flagman,
  Google,
  Microsoft,
  WhiteRightArrow,
} from '../../atoms/Icons';
import { Toast } from '../../atoms/Toast';
import { Props as INotification } from '../../atoms/Toast/Component';
import { blobToBase64 } from '../../templates/Login/Component';
import {
  FbLoginValues,
  GoogleLoginValues,
  MicrosoftValues,
} from '../../templates/Login/types';

const Container = styled.div`
  width: 100%;
  height: 319px;
  background-color: ${theme.colors.topFooterBg};
  display: flex;
  flex-direction: column;

  @media ${theme.breakpoints.mobile} {
    height: 100%;
  }

  @media ${theme.breakpoints.tablet} {
    height: 100%;
  }
`;

const TopWrapper = styled.div`
  @media ${theme.breakpoints.pc} {
    height: 239px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: thin solid ${theme.colors.topHeaderBorder};
    padding: 0px 75px 0px 125px;
  }

  @media ${theme.breakpoints.mobile} {
    width: 100%;
    height: 100%;
    border: none;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    border-bottom: thin solid ${theme.colors.topHeaderBorder};
  }

  @media ${theme.breakpoints.tablet} {
    width: calc(100% - 28px);
    height: 100%;
    border: none;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 0px 0px 10px 28px;
    border-bottom: thin solid ${theme.colors.topHeaderBorder};
  }
`;

const TopLinksContainer = styled.div`
  margin-top: 28px;
  display: flex;
  flex: 0 0 50%;
  justify-content: start;
  flex-direction: row;

  @media ${theme.breakpoints.mobile} {
    margin-top: 22px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding: 0;
    flex: 0 0 100%;
  }

  @media ${theme.breakpoints.tablet} {
    flex: 0 0 100%;
    justify-content: center;
  }
`;

const TopLinkWrapper = styled.div<{ isLast?: boolean }>`
  @media ${theme.breakpoints.pc} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    :not(:last-of-type) {
      margin-right: 70px;
    }

    div {
      :not(:last-of-type) {
        margin-bottom: 10px;
      }
      a {
        font-family: ${theme.fonts.primary};
        font-weight: 400;
        font-size: 13px;
        line-height: 19px;
        letter-spacing: -0.02em;
        color: ${theme.colors.black};
        text-decoration: none;
        white-space: nowrap;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  @media ${theme.breakpoints.mobile} {
    display: flex;
    flex-direction: column;
    flex: 0 0 33%;
    align-items: flex-start;

    div {
      a {
        font-family: ${theme.fonts.primary};
        font-weight: 400;
        font-size: 13px;
        line-height: 19px;
        letter-spacing: -0.02em;
        color: ${theme.colors.black};
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  @media ${theme.breakpoints.tablet} {
    width: max-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 2.5em;
    :not(:last-of-type) {
      margin-bottom: 10px;
    }

    div {
      a {
        font-family: ${theme.fonts.primary};
        font-weight: 400;
        font-size: 13px;
        line-height: 19px;
        letter-spacing: -0.02em;
        color: ${theme.colors.black};
        text-decoration: none;
        white-space: nowrap;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
`;

const LinkTitle = styled.div`
  font-family: ${theme.fonts.primary};
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: ${theme.colors.black};
  margin-bottom: 15px;

  p {
    margin: 0;
    width: 100%;

    a {
      text-decoration: none;
      color: ${theme.colors.black};
    }
  }
`;

const LinkContent = styled.div`
  p {
    margin: 0;

    a {
      text-decoration: none;
    }
  }
`;

const RightContainer = styled.div`
  display: flex;
  flex: 0 0 50%;
  flex-direction: row;
  width: max-content;
  justify-content: end;

  @media ${theme.breakpoints.mobile} {
    flex-wrap: wrap;
    flex: 0 0 100%;
  }

  @media ${theme.breakpoints.tablet} {
    flex-wrap: wrap;
    flex: 0 0 100%;
  }
`;

const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 95px;

  p {
    font-family: ${theme.fonts.primary};
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: ${theme.colors.black};
    margin: 0;
    padding: 0;
    margin-bottom: 8px;
  }

  @media ${theme.breakpoints.tablet} {
    flex: 0 0 100%;
    margin-top: 30px;

    p {
      padding: 0 25px;
    }
  }

  @media ${theme.breakpoints.mobile} {
    flex: 0 0 100%;
    margin-top: 30px;

    p {
      padding: 0 25px;
    }
  }
`;

const SignUpWrapper = styled.div`
  margin-top: 30px;
  margin-left: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${theme.breakpoints.tablet} {
    flex: 0 0 100%;
    margin-top: 30px;
    margin-left: 0;
  }

  @media ${theme.breakpoints.mobile} {
    flex: 0 0 100%;
    margin: 30px 0;
  }
`;

const StyledFlagman = styled(Flagman)`
  margin-bottom: 7px;
`;

const SignUpBtn = styled(Button)`
  width: 229px;
  height: 58px;
  border-radius: 5px;
`;

const ThirdPartyWrapper = styled.div`
  height: max-content;
  width: 120px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
`;

const StyledGoogle = styled(Google)`
  && {
    width: 16px;
    height: 16px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.5));
      transform: translateY(-0.15em);
    }
  }
`;

const StyledFB = styled(Facebook)`
  && {
    width: 24px;
    height: 24px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      filter: drop-shadow(4px 4px 4px rgba(57, 81, 150, 0.5));
      transform: translateY(-0.15em);
    }
  }
`;

const StyledMicrosoft = styled(Microsoft)`
  && {
    width: 24px;
    height: 24px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      filter: drop-shadow(4px 4px 4px rgba(234, 62, 35, 0.5));
      transform: translateY(-0.15em);
    }
  }
`;

const BottomWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media ${theme.breakpoints.pc} {
    padding: 20px 75px 20px 125px;
  }

  @media ${theme.breakpoints.tablet} {
    flex-wrap: wrap;
    padding: 20px 25px;
  }

  @media ${theme.breakpoints.mobile} {
    flex-wrap: wrap;
    padding: 20px 25px;
  }
`;

const BottomLinkWrapper = styled.div`
  width: max-content;
  height: 100%;
  display: flex;
  flex: 0 0 70%;
  flex-direction: row;
  align-items: center;

  div {
    a {
      text-decoration: none;
      font-family: ${theme.fonts.primary};
      font-weight: 400;
      font-size: 13px;
      line-height: 19px;
      letter-spacing: -0.02em;
      color: ${theme.colors.black};

      :hover {
        text-decoration: underline;
      }
    }

    :not(:last-of-type) {
      margin-right: 70px;
    }
  }

  @media ${theme.breakpoints.tablet} {
    flex: 0 0 100%;
    justify-content: space-between;
  }

  @media ${theme.breakpoints.mobile} {
    flex: 0 0 100%;
    justify-content: space-between;
  }
`;

const CreditsText = styled.div`
  width: max-content;
  height: 100%;
  display: flex;
  flex: 0 0 30%;
  align-items: center;
  font-family: ${theme.fonts.primary};
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: -0.02em;
  justify-content: end;
  color: ${theme.colors.black};

  @media ${theme.breakpoints.tablet} {
    margin-top: 20px;
    flex: 0 0 100%;
    justify-content: center;
  }

  @media ${theme.breakpoints.mobile} {
    flex: 0 0 100%;
    margin-top: 20px;
    justify-content: center;
  }
`;

const DOMPurify = createDOMPurify(window);

export type Props = {
  footerContents: { title: string; item: string[] }[];
  addressContents: {
    address: string;
    telephoneNumber: string;
    faxNumber: string;
  };
  faqsContents: {
    privacyPolicy: string;
    termsOfService: string;
    transactionLaw: string;
  };
  isFetching?: boolean;
  fromSignUp?: boolean;
};

const Component = ({
  footerContents,
  addressContents,
  faqsContents,
  isFetching,
  fromSignUp,
}: Props): React.ReactElement => {
  const navigate = useNavigate();
  const { useLogin, useFetchUser } = useAuthHooks();
  const { fetchGoogleUserData } = useFetchUser();
  const { googleLogin, fbLogin, microsoftLogin } = useLogin();
  const { useLocalStorage } = useLocalStorageHooks();
  const { clearSessionStorage } = useLocalStorage();
  const loginGoogle = useGoogleLogin({
    onSuccess: async tokenResponse => {
      const userInfo = await fetchGoogleUserData(tokenResponse.access_token);

      googleLoginMutation({
        email: userInfo.email,
        name: userInfo.name,
        googleId: userInfo.sub,
        invitationCode: '',
        avatar: userInfo.picture,
      });
    },
  });

  const [notification, setNotification] = useState<INotification | null>(null);

  const handleCloseToast = () => {
    setNotification(null);
  };

  const { mutate: googleLoginMutation } = useMutation(
    (params: GoogleLoginValues) => {
      return googleLogin(
        params.email,
        params.name,
        params.googleId,
        '',
        params.avatar,
      );
    },
    {
      onSuccess: () => {
        navigate('/my-board/personal');
      },
      onError: response => {
        setNotification({
          isOpen: true,
          message: String(response)
            .replace('Error:', '')
            .trim(),
          type: 'error',
          position: 'top-right',
          onClose: handleCloseToast,
        });
      },
    },
  );

  const { mutate: fbLoginMutation } = useMutation(
    (params: FbLoginValues) => {
      return fbLogin(
        params.email,
        params.name,
        params.userID,
        '',
        params.avatar,
      );
    },
    {
      onSuccess: () => {
        navigate('/my-board/personal');
      },
      onError: response => {
        setNotification({
          isOpen: true,
          message: String(response)
            .replace('Error:', '')
            .trim(),
          type: 'error',
          position: 'top-right',
          onClose: handleCloseToast,
        });
      },
    },
  );

  const { mutate: microsoftLoginMutation } = useMutation(
    (params: MicrosoftValues) => {
      return microsoftLogin(
        params.account.userName,
        params.account.name,
        params.account.accountIdentifier,
        '',
        '',
      );
    },
    {
      onSuccess: () => {
        navigate('/my-board/personal');
      },
      onError: response => {
        setNotification({
          isOpen: true,
          message: String(response)
            .replace('Error:', '')
            .trim(),
          type: 'error',
          position: 'top-right',
          onClose: handleCloseToast,
        });
      },
    },
  );

  const handleMicrosoftLogin = useCallback(async (err, data) => {
    const error: MicrosoftError = err;
    const response = data as MicrosoftValues;

    if (response?.uniqueId) {
      // Note: This is for preventing the Microsoft Button to call the auth every time the page loads
      clearSessionStorage();

      const options = {
        headers: {
          Authorization: `Bearer ${response.accessToken}`,
          'Content-Type': 'image/jpg',
        },
        responseType: 'blob' as ResponseType,
      };
      const photo = await axios
        .get(urls.microsoft.profilePhoto, options)
        .then(async response => {
          const base64 = await blobToBase64(response.data).then(
            base64data => base64data,
          );
          return base64 as string;
        })
        .catch(function(err) {
          console.log(err);
          return '';
        });

      microsoftLoginMutation({ ...response, avatar: photo ?? '' });
    } else if (!!error && error.errorCode !== 'user_cancelled') {
      setNotification({
        isOpen: true,
        message: `Microsoft Error: ${error.errorMessage}`,
        type: 'error',
        position: 'top-right',
        onClose: handleCloseToast,
      });
    }
  }, []);

  const submitFbLogin = useCallback((response: ReactFacebookLoginInfo) => {
    fbLoginMutation({
      email: response.email || '',
      name: response.name || response.userID,
      userID: response.userID,
      invitationCode: '',
      avatar: response.picture?.data.url || '',
    });
  }, []);

  const handleFailureFb = useCallback(
    (response: ReactFacebookFailureResponse) => {
      if (response.status !== 'unknown') {
        setNotification({
          isOpen: true,
          message: `Facebook Error: ${response.status}`,
          type: 'error',
          position: 'top-right',
          onClose: handleCloseToast,
        });
      }
    },
    [],
  );

  return (
    <Container>
      <TopWrapper>
        <TopLinksContainer>
          {isFetching ? (
            <>
              <TopLinkWrapper>
                <Skeleton
                  sx={{ marginTop: '10px' }}
                  variant="rectangular"
                  height={20}
                  width={90}
                />
                <Skeleton
                  sx={{ marginTop: '10px' }}
                  variant="rectangular"
                  height={20}
                  width={90}
                />
                <Skeleton
                  sx={{ marginTop: '10px' }}
                  variant="rectangular"
                  height={20}
                  width={90}
                />
              </TopLinkWrapper>
              <TopLinkWrapper>
                <Skeleton
                  sx={{ marginTop: '10px' }}
                  variant="rectangular"
                  height={20}
                  width={90}
                />
                <Skeleton
                  sx={{ marginTop: '10px' }}
                  variant="rectangular"
                  height={20}
                  width={90}
                />
                <Skeleton
                  sx={{ marginTop: '10px' }}
                  variant="rectangular"
                  height={20}
                  width={90}
                />
              </TopLinkWrapper>
              <TopLinkWrapper>
                <Skeleton
                  sx={{ marginTop: '10px' }}
                  variant="rectangular"
                  height={20}
                  width={90}
                />
                <Skeleton
                  sx={{ marginTop: '10px' }}
                  variant="rectangular"
                  height={20}
                  width={90}
                />
                <Skeleton
                  sx={{ marginTop: '10px' }}
                  variant="rectangular"
                  height={20}
                  width={90}
                />
              </TopLinkWrapper>
            </>
          ) : (
            <>
              {footerContents?.map((content, index) => (
                <TopLinkWrapper key={index}>
                  <LinkTitle
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(content?.title),
                    }}
                  />
                  {content?.item.map((link, index) => (
                    <LinkContent
                      key={index}
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(link),
                      }}
                    />
                  ))}
                </TopLinkWrapper>
              ))}
            </>
          )}
        </TopLinksContainer>
        <RightContainer>
          <AddressContainer>
            <p
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(addressContents.address),
              }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(addressContents.telephoneNumber),
              }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(addressContents.faxNumber),
              }}
            />
          </AddressContainer>
          <SignUpWrapper>
            <StyledFlagman />
            <SignUpBtn
              theme="lightSecondary"
              title="無料登録"
              onPress={() =>
                fromSignUp ? window.scrollTo(0, 0) : navigate('/signup')
              }
              icon={<WhiteRightArrow />}
              iconPosition="right"
              iconMargin={10}
            />
            <ThirdPartyWrapper>
              <StyledGoogle onClick={loginGoogle} />
              <FacebookLogin
                appId={process.env.REACT_APP_FB_APP_ID || ''}
                callback={submitFbLogin}
                fields="name,picture,email"
                onFailure={handleFailureFb}
                render={(renderProps: {
                  onClick: string | number | Function;
                }) => <StyledFB onClick={renderProps.onClick} />}
              />
              <MicrosoftLogin
                clientId={process.env.REACT_APP_MICROSOFT_CLIENT_ID || ''}
                authCallback={handleMicrosoftLogin}>
                <StyledMicrosoft />
              </MicrosoftLogin>
            </ThirdPartyWrapper>
          </SignUpWrapper>
        </RightContainer>
      </TopWrapper>
      <BottomWrapper>
        <BottomLinkWrapper>
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(faqsContents.privacyPolicy),
            }}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(faqsContents.termsOfService),
            }}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(faqsContents.transactionLaw),
            }}
          />
        </BottomLinkWrapper>
        <CreditsText>&copy;2022 Flagman. All Rights Reserved.</CreditsText>
      </BottomWrapper>
      {notification ? <Toast {...notification} /> : null}
    </Container>
  );
};

export default Component;
