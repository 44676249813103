import BoardRepository from '../../ports/BoardRepository';

export type uploadMediaUseCase = (file: File, type: number) => Promise<void>;

export const buildUploadMedia = (dependencies: {
  boardRepo: BoardRepository;
}): uploadMediaUseCase => {
  const { boardRepo } = dependencies;

  const uploadMedia: uploadMediaUseCase = (file: File, type: number) =>
    boardRepo.uploadMedia(file, type);

  return uploadMedia;
};
