import React, { SVGProps } from 'react';

type Props = SVGProps<SVGSVGElement>;

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="61"
      height="8"
      viewBox="0 0 61 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M8.71428 0L30.5 5L52.2857 0L61 1L30.5 8L-3.09944e-06 1L8.71428 0Z"
        fill="#CACACA"
      />
    </svg>
  );
};

export default Component;
