import React from 'react';
import styled from 'styled-components';
import Box, { BoxProps } from '@mui/material/Box';
import theme from '../../../constants/themes';

const StyledContainer = styled(Box)`
  display: flex;
  background-color: ${theme.colors.white};
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  padding: 15px 10px;
`;

const StyledAvatarWrapper = styled(Box)`
  overflow: hidden;
  position: relative;
`;
const StyledImage = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100px;
`;
const StyledMessageBody = styled(Box)`
  display: flex;
  flex: 1;
  margin: 0 10px;
  padding: 4px 0;
  flex-direction: column;
`;
const StyledDateTimeLabel = styled.span`
  font-family: ${theme.fonts.primary};
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: -0.02em;
  color: ${theme.colors.textColor};
  padding-top: 15px;
`;

const StyledSenderLabel = styled.span`
  font-family: ${theme.fonts.primary};
  font-weight: 700;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: ${theme.colors.black};
  padding-bottom: 4px;
  word-break: break-all;
  white-space: pre-wrap;
`;
const StyledMessage = styled.p`
  font-family: ${theme.fonts.primary};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  align-items: flex-end;
  letter-spacing: -0.02em;
  color: ${theme.colors.black};
  margin: 0;
  word-break: break-all;
  white-space: pre-wrap;

  .mention-name {
    font-family: ${theme.fonts.primary};
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
  }
`;

type Props = {
  name: string;
  role: string;
  displayPhoto: string;
  displayTime: string;
  message: string;
  boxProps?: BoxProps;
};

const Component = ({
  name,
  role,
  displayPhoto,
  displayTime,
  message,
  boxProps,
}: Props): React.ReactElement => {
  const splitMsgs = message.split(/(\s+)/);

  const renderMessage = () => {
    return splitMsgs.map(item => {
      if (item.match(/@__(\d+)_@@_(.*?)__@/g)) {
        const markUp = item.split('_@@_');
        const customerName = markUp[1].split('__@')[0];
        const customerId = markUp[0].split('@__')[1];
        return (
          <span className="mention-name" id={customerId}>
            {customerName}
          </span>
        );
      } else {
        return item;
      }
    });
  };

  return (
    <StyledContainer {...boxProps}>
      <StyledAvatarWrapper width={45} height={45}>
        <StyledImage src={displayPhoto} alt="Avatar" />
      </StyledAvatarWrapper>
      <StyledMessageBody>
        <StyledSenderLabel>
          {role}: {name}
        </StyledSenderLabel>
        <StyledMessage>{renderMessage()}</StyledMessage>
      </StyledMessageBody>
      <StyledDateTimeLabel>{displayTime}</StyledDateTimeLabel>
    </StyledContainer>
  );
};

export default Component;
