export default {
  CLIENT: {
    CREATE_ROOM: 'CREATE_ROOM',
    SEND_ROOM_MESSAGE: 'SEND_ROOM_MESSAGE',
    JOIN_ROOM: 'JOIN_ROOM',
    SEND_DIAGRAM_FILE_CONTENT: 'SEND_DIAGRAM_FILE_CONTENT',
    SEND_POSITION_OF_CURSOR: 'SEND_POSITION_OF_CURSOR',
    USER_DISCONNECT: 'USER_DISCONNECT',
    OUT_ROOM: 'OUT_ROOM',
    VOTE: 'VOTE',
    UN_VOTE: 'UN_VOTE',
    LOCK_OBJECT: 'LOCK_OBJECT',
    VISIT: 'VISIT',
    REQUEST_NEW_DIAGRAM_FILE_CONTENT: 'REQUEST_NEW_DIAGRAM_FILE_CONTENT',
    NEW_DIAGRAM_FILE_CONTENT: 'NEW_DIAGRAM_FILE_CONTENT',
  },
  SERVER: {
    ROOMS: 'ROOMS',
    JOINED_ROOM: 'JOINED_ROOM',
    MEMBER_IN_ROOM: 'MEMBER_IN_ROOM',
    ROOM_MESSAGE: 'ROOM_MESSAGE',
    DIAGRAM_FILE_CONTENT: 'DIAGRAM_FILE_CONTENT',
    POSITION_OF_CURSOR: 'POSITION_OF_CURSOR',
    DISCONNECT: 'DISCONNECT',
    OUTED_ROOM: 'OUTED_ROOM',
    VOTED: 'VOTED',
    UN_VOTED: 'UN_VOTED',
    LOCKED_OBJECT: 'LOCKED_OBJECT',
    VISITED: 'VISITED',
    GET_DIAGRAM_FILE_CONTENT: 'GET_DIAGRAM_FILE_CONTENT',
    RESPONSE_NEW_DIAGRAM_FILE_CONTENT: 'RESPONSE_NEW_DIAGRAM_FILE_CONTENT',
  },
};
