import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import theme from '../../../constants/themes';
import { TextInput } from '../../atoms/TextInput';
import { Button } from '../../atoms/Button';
import { Facebook, Google, Keep, Microsoft } from '../../atoms/Icons';
import { ModalWrapper } from '../../molecules/ModalWrapper';
import { FormikTouched } from 'formik';
import { isWindowsOS } from '../../../utils/booleans';

const Wrapper = styled.div`
  display: flex;
  color: ${theme.colors.text02};

  @media ${theme.breakpoints.mobile} {
    display: block;
    padding-bottom: 58px;
  }

  @media ${theme.breakpoints.tablet} {
    display: block;
    padding-bottom: 58px;
  }
`;

const TitleWrapper = styled.div`
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
  font-weight: 600;
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 42px;

  @media ${theme.breakpoints.mobile} {
    justify-content: center;
  }

  @media ${theme.breakpoints.tablet} {
    justify-content: center;
  }
`;

const DisplayImage = styled.img`
  height: 100px;
  width: 100px;
  border-radius: 50%;
`;

const EditButton = styled.div`
  display: inline;
  cursor: pointer;
  margin-top: 6px;
`;

const FormWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  margin-left: 86px;

  @media ${theme.breakpoints.mobile} {
    width: 100%;
    margin-left: 0;
  }

  @media ${theme.breakpoints.tablet} {
    width: 100%;
    margin-left: 0;
  }
`;

const HighlightedLabel = styled.div`
  display: inline;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  letter-spacing: -0.02em;
  margin-bottom: 7px;
`;

const Label = styled.div`
  font-family: ${theme.fonts.primary};
  font-size: 13px;
  line-height: 19px;
  letter-spacing: -0.02em;
  display: inline;
  white-space: pre;
`;

const RequiredIcon = styled.span`
  color: ${theme.colors.textError};
`;

const Description = styled(Label)`
  width: 400px;

  @media ${theme.breakpoints.mobile} {
    width: 100%;
    display: block;
    white-space: pre-line;
  }

  @media ${theme.breakpoints.tablet} {
    width: 100%;
    display: block;
    white-space: pre-line;
  }
`;

const StyledInput = styled(TextInput)`
  width: 400px;
  height: 50px;
  margin-top: 7px;
  margin-bottom: ${props => (props.isError ? 3 : 17)}px;
  font-size: 13px;
  line-height: 19px;
  border: ${props =>
    props.isError
      ? `thin solid ${theme.colors.textError}`
      : `thin solid ${theme.colors.dropdownBorder}`};
  &:focus {
    outline: ${props =>
      props.isError
        ? `${theme.colors.textError} auto 3px`
        : `${theme.colors.dropdownBorder} auto 3px`};
  }

  @media ${theme.breakpoints.mobile} {
    width: 100%;
  }

  @media ${theme.breakpoints.tablet} {
    width: 100%;
  }
`;

const NameStyledInput = styled(TextInput)`
  width: 200px;
  height: 50px;
  margin-top: 7px;
  margin-bottom: ${props => (props.isError ? 3 : 17)}px;
  font-size: 13px;
  line-height: 19px;
  border: ${props =>
    props.isError
      ? `thin solid ${theme.colors.textError}`
      : `thin solid ${theme.colors.dropdownBorder}`};
  &:focus {
    outline: ${props =>
      props.isError
        ? `${theme.colors.textError} auto 3px`
        : `${theme.colors.dropdownBorder} auto 3px`};
  }

  @media ${theme.breakpoints.mobile} {
    width: 100%;
  }

  @media ${theme.breakpoints.tablet} {
    width: 100%;
  }
`;

const StyledButton = styled(Button)`
  margin-top: 24px;
  width: 120px;

  @media ${theme.breakpoints.mobile} {
    width: 100%;
  }
`;

const Container = styled.div`
  width: 500px;
  display: flex;
  justify-content: space-evenly;
  padding-bottom: 65px;
`;

const ChangePasswordLink = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: -0.02em;
  text-decoration-line: underline;
  color: ${theme.colors.notifToastBorder};
  cursor: pointer;
`;

const DeleteLink = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: -0.02em;
  text-decoration-line: underline;
  color: ${theme.colors.textError};
  cursor: pointer;
  width: fit-content;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
`;

const PassRow = styled.div`
  width: 400px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;

  @media ${theme.breakpoints.mobile} {
    width: 100%;
  }

  @media ${theme.breakpoints.tablet} {
    width: 100%;
  }
`;

const IconWrapper = styled.div<{ isError?: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${props => (props.isError ? '10px' : '24px')};
  margin-left: 21px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  box-shadow: 0px 4px 8px rgba(0, 16, 41, 0.1);
`;

const ErrorMessage = styled.span`
  width: 100%;
  font-family: ${theme.fonts.primary};
  font-weight: 400;
  font-size: 13px;
  line-height: ${isWindowsOS() ? 'normal' : '14px'};
  color: ${theme.colors.textError};
  text-align: left;
  margin-top: 10px;
  margin-bottom: 15px;
`;

export type Props = {
  sns?: 'facebook' | 'microsoft' | 'google';
  email?: string;
  name?: string;
  plan?: string;
  oldPassword?: string;
  newPassword?: string;
  renewedPassword?: string;
  image?: string | File;
  errors?: {
    name?: string;
    oldPassword?: string;
    newPassword?: string;
    renewedPassword?: string;
    email?: string;
  };
  hasInitialEmail?: boolean;
  touched?: FormikTouched<{
    name?: string;
    oldPassword?: string;
    newPassword?: string;
    renewedPassword?: string;
    email?: string;
  }>;
  setFieldTouched: (
    field: string,
    isTouched?: boolean,
    shouldValidate?: boolean,
  ) => void;
  onChangeEmail?: (text: string) => void;
  onChangeName?: (text: string) => void;
  onChangeOldPassword?: (text: string) => void;
  onChangeNewPassword?: (text: string) => void;
  onChangeRenewedPassword?: (text: string) => void;
  onChangeImage: (image: File) => void;
  onPressSubmit: () => void;
  onPressDelete: () => void;
  onChangeNameBlur?: (e: React.FocusEvent<any>) => void;
  onChangeEmailBlur?: (e: React.FocusEvent<any>) => void;
  onOldPasswordBlur?: (e: React.FocusEvent<any>) => void;
  onNewPasswordBlur?: (e: React.FocusEvent<any>) => void;
  onRenewedPasswordBlur?: (e: React.FocusEvent<any>) => void;
  disabled?: boolean;
  editingPasswordState?: [boolean, (editing: boolean) => void];
  isFetchingDetails?: boolean;
  isUpdating: boolean;
};

const SNSLogoMapper = {
  facebook: Facebook,
  microsoft: Microsoft,
  google: Google,
};

const Component = ({
  sns,
  errors,
  email = '',
  name = '',
  plan = '',
  oldPassword = '',
  newPassword = '',
  renewedPassword = '',
  image = '',
  onChangeEmail = () => {},
  onChangeName = () => {},
  onChangeOldPassword = () => {},
  onChangeNewPassword = () => {},
  onChangeRenewedPassword = () => {},
  onChangeImage,
  onPressSubmit,
  onPressDelete,
  onChangeNameBlur,
  onChangeEmailBlur,
  onOldPasswordBlur,
  onNewPasswordBlur,
  onRenewedPasswordBlur,
  disabled,
  editingPasswordState,
  hasInitialEmail,
  touched,
  isFetchingDetails,
  isUpdating,
  setFieldTouched,
}: Props): React.ReactElement => {
  const { t } = useTranslation();
  const [scopedIsEditingPassword, scopedSetIsEditingPassword] = useState<
    boolean
  >(false);

  const isEditingPassword =
    editingPasswordState !== undefined
      ? editingPasswordState[0]
      : scopedIsEditingPassword;
  const setIsEditingPassword = (newValue: boolean) => {
    if (editingPasswordState !== undefined) {
      editingPasswordState[1](newValue);
    } else {
      scopedSetIsEditingPassword(newValue);
    }
  };
  const [disabledBtn, setDisabledBtn] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const SNSLogo = sns && SNSLogoMapper[sns];

  return (
    <Wrapper>
      <ImageWrapper>
        <DisplayImage
          src={
            typeof image === 'string'
              ? image
              : window.URL.createObjectURL(image)
          }
        />
        <EditButton
          onClick={() => {
            if (fileInputRef) {
              fileInputRef.current?.click();
            }
          }}>
          {t('edit')}
        </EditButton>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          accept="image/png, image/jpeg"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const file = event.target.files?.[0];
            if (!file) {
              throw new Error('file not found');
            }
            onChangeImage(file);
          }}
        />
      </ImageWrapper>
      <FormWrapper>
        <HighlightedLabel>{t('basicInformation')}</HighlightedLabel>
        <Label>{t('familyName')}</Label>
        <NameStyledInput
          type="text"
          value={name}
          onChangeText={onChangeName}
          isError={!!errors?.name && !!touched?.name}
          onBlur={onChangeNameBlur}
          disabled={isFetchingDetails}
        />
        {!!errors?.name && !!touched?.name && (
          <ErrorMessage>{errors?.name}</ErrorMessage>
        )}
        <Label>{t('mailAddress')}</Label>
        <Row>
          <StyledInput
            type="text"
            value={email}
            onChangeText={onChangeEmail}
            disabled={!hasInitialEmail || isFetchingDetails}
            onBlur={onChangeEmailBlur}
            isError={!!errors?.email}
          />
          {SNSLogo && (
            <IconWrapper isError={!!errors?.email}>
              <SNSLogo width={30} height={30} />
            </IconWrapper>
          )}
        </Row>
        {errors?.email ? <ErrorMessage>{errors?.email}</ErrorMessage> : ''}
        <Label>{t('plan')}</Label>
        <StyledInput type="text" value={plan} disabled />
        {!sns && (
          <>
            <PassRow>
              <Label>
                {t('oldPassword')}
                {isEditingPassword && <RequiredIcon>*</RequiredIcon>}
              </Label>
              <ChangePasswordLink
                onClick={() => {
                  setIsEditingPassword(!isEditingPassword);
                }}>
                {t('changePassword')}
              </ChangePasswordLink>
            </PassRow>
            <StyledInput
              type="password"
              value={oldPassword}
              isError={!!errors?.oldPassword && touched?.oldPassword}
              onChangeText={(value: string) => {
                setFieldTouched('oldPassword');
                onChangeOldPassword(value);
              }}
              disabled={!isEditingPassword}
              onBlur={onOldPasswordBlur}
            />
            {!!errors?.oldPassword && touched?.oldPassword && (
              <ErrorMessage>{errors?.oldPassword}</ErrorMessage>
            )}
            <Label>
              {t('newProfilePassword')}
              {isEditingPassword && <RequiredIcon>*</RequiredIcon>}
            </Label>
            <StyledInput
              type="password"
              value={newPassword}
              isError={!!errors?.newPassword && !!touched?.newPassword}
              onChangeText={(value: string) => {
                setFieldTouched('newPassword');
                onChangeNewPassword(value);
              }}
              disabled={!isEditingPassword}
              onBlur={onNewPasswordBlur}
            />
            {!!errors?.newPassword && !!touched?.newPassword && (
              <ErrorMessage>{errors?.newPassword}</ErrorMessage>
            )}
            <Label>
              {t('renewedPassword')}
              {isEditingPassword && <RequiredIcon>*</RequiredIcon>}
            </Label>
            <StyledInput
              type="password"
              value={renewedPassword}
              isError={!!errors?.renewedPassword && !!touched?.renewedPassword}
              onChangeText={(value: string) => {
                setFieldTouched('renewedPassword');
                onChangeRenewedPassword(value);
              }}
              disabled={!isEditingPassword}
              onBlur={onRenewedPasswordBlur}
            />
            {!!errors?.renewedPassword && !!touched?.renewedPassword && (
              <ErrorMessage>{errors?.renewedPassword}</ErrorMessage>
            )}
          </>
        )}
        <HighlightedLabel>{t('accountDeletion')}</HighlightedLabel>
        <Description>{t('deleteInfo')}</Description>
        <DeleteLink
          onClick={() => {
            setIsOpen(!isOpen);
          }}>
          {t('withdrawal')}
        </DeleteLink>
        <StyledButton
          theme="lightSecondary"
          title={t('keep')}
          onPress={onPressSubmit}
          disabled={disabled}
          icon={<Keep />}
          isLoading={isUpdating}
        />
      </FormWrapper>
      <ModalWrapper
        isOpen={isOpen}
        title={'削除確認'}
        onClose={() => {
          setIsOpen(!isOpen);
        }}>
        <>
          <TitleWrapper>{t('deleteUserPrompt')}</TitleWrapper>
          <Container>
            <StyledButton
              theme="gray"
              title={'いいえ'}
              onPress={() => {
                setIsOpen(!isOpen);
              }}
              disabled={disabledBtn}
            />
            <StyledButton
              theme={isLoading ? 'gray' : 'lightSecondary'}
              title={'はい'}
              onPress={() => {
                onPressDelete();
                setDisabledBtn(true);
                setIsLoading(true);
                setTimeout(() => {
                  setIsLoading(false);
                  setIsOpen(false);
                }, 1000);
              }}
              disabled={disabledBtn}
              isLoading={isLoading}
            />
          </Container>
        </>
      </ModalWrapper>
    </Wrapper>
  );
};

export default Component;
