import * as Yup from 'yup';
import i18n from '../../../constants/words';

export const DefaultProfileSchema = Yup.object().shape({
  name: Yup.string()
    .required(i18n.t('requiredName'))
    .max(255, i18n.t('maxLengthFullName')),
  oldPassword: Yup.string()
    .min(8, i18n.t('minLengthOldPassword'))
    .max(255, i18n.t('maxLengthOldPassword')),
  newPassword: Yup.string()
    .min(8, i18n.t('passwordMinLength'))
    .max(255, i18n.t('maxLengthPassword')),
  renewedPassword: Yup.string()
    .min(8, i18n.t('minLengthRenewedPassword'))
    .max(255, i18n.t('maxLengthRenewedPassword')),
  email: Yup.string()
    .email(i18n.t('invalidEmail'))
    .required(i18n.t('requiredEmail'))
    .max(255, i18n.t('maxLengthEmail')),
});

export const EditingPasswordProfileSchema = Yup.object().shape({
  name: Yup.string()
    .required(i18n.t('requiredName'))
    .max(255, i18n.t('maxLengthFullName')),
  oldPassword: Yup.string()
    .min(8, i18n.t('minLengthOldPassword'))
    .max(255, i18n.t('maxLengthOldPassword'))
    .required(i18n.t('requiredOldPassword')),
  newPassword: Yup.string()
    .min(8, i18n.t('passwordMinLength'))
    .max(255, i18n.t('maxLengthPassword'))
    .notOneOf([Yup.ref('oldPassword'), null], i18n.t('differentOldPassword'))
    .required(i18n.t('requiredPassword')),
  renewedPassword: Yup.string()
    .min(8, i18n.t('minLengthRenewedPassword'))
    .max(255, i18n.t('maxLengthRenewedPassword'))
    .required(i18n.t('requiredRenewedPassword'))
    .oneOf([Yup.ref('newPassword'), null], i18n.t('passwordsMustMatch')),
});
