import React, { SVGProps } from 'react';

type Props = SVGProps<SVGSVGElement>;

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 15 15"
      width="17"
      height="18"
      {...props}>
      <defs>
        <marker
          id={`step-arrow-end`}
          viewBox="0 0 10 10"
          refX="1"
          refY="5"
          markerUnits="strokeWidth"
          markerWidth="4"
          markerHeight="5"
          orient="auto">
          <path d="M 0 0 L 10 5 L 0 10 z" fill="black" stroke="inherit" />
        </marker>
      </defs>
      <path
        d={'M1 13 L13 3'}
        markerEnd={`url(#step-arrow-end)`}
        fill="none"
        stroke="black"
        strokeWidth={0.8}
      />
    </svg>
  );
};

export default Component;
