import { AxiosError, AxiosResponse } from 'axios';
import { FormikHelpers } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router';
import styled from 'styled-components';
import searchImg from '../../../assets/images/search.png';
import queryKeys from '../../../constants/queryKeys';
import theme from '../../../constants/themes';
import {
  CreateTeamSuccess,
  MyBoardTeam,
  TeamMember,
} from '../../../domain/entities/team';
import { useGlobalState } from '../../../hooks/global';
import { useTeamHooks } from '../../../hooks/team';
import { isWindowsOS } from '../../../utils/booleans';
import { InputWithIcon } from '../../atoms/InputWithIcon';
import { Toast } from '../../atoms/Toast';
import { Props as INotification } from '../../atoms/Toast/Component';
import { AddTeamModal } from '../../organisms/AddTeamModal';
import { CreateTeamValues } from '../../organisms/AddTeamModal/types';
import { ManagementMenu } from '../../organisms/ManagementMenu';
import { MemberDeleteModal } from '../../organisms/MemberDeleteModal';
import { MemberEditModal } from '../../organisms/MemberEditModal';
import { MemberManagementTable } from '../../organisms/MemberManagementTable';
import { AddTeamMemberModal } from '../../templates/AddTeamMemberModal';
import { DeleteTeamMemberValues } from '../AddTeamMemberModal/types';

const StyledContainer = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
`;

const StyledContentWrapper = styled.div`
  padding: 0 40px;
  display: flex;
  flex-direction: column;
  width: 100vw;
`;

const StyledContentHeader = styled.span`
  font-family: ${theme.fonts.primary};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: ${theme.colors.textColor};
  margin-top: 38px;
`;

const Divider = styled.div`
  width: 120px;
  height: 3px;
  background-color: ${theme.colors.notifToastBorder};
  margin-top: 4px;
`;

const StyledSearch = styled(InputWithIcon)`
  width: 280px;
  height: 40px;
  margin-top: 14px;
  margin-bottom: 20px;
  padding-top: ${isWindowsOS() ? 13 : 8}px;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  border: thin solid #dadada;
`;

const FilterWrapper = styled.div`
  height: 143px;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 50px;
  z-index: 998;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${theme.colors.white};
`;

export type Props = {
  activeItem: 'team' | 'invoice';
  teamList?: MyBoardTeam[];
  isFetchingTeams?: boolean;
};

const Component = ({
  activeItem,
  teamList,
  isFetchingTeams,
}: Props): React.ReactElement => {
  const { teamId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { useTeam } = useTeamHooks();
  const { useCurrentUser } = useGlobalState();
  const {
    createMyBoardTeam,
    fetchTeamMembers,
    updateTeamMember,
    deleteTeamMember,
  } = useTeam();
  const { currentUser } = useCurrentUser;
  const [isAddTeamMemberModalOpen, setIsAddTeamMemberModal] = useState<boolean>(
    false,
  );
  const [isAddTeamModalOpen, setIsAddTeamModalOpen] = useState<boolean>(false);
  const [notification, setNotification] = useState<INotification | null>(null);
  const [selectedMember, setSelectedMember] = useState<TeamMember | null>(null);
  const [
    selectedDeleteMember,
    setSelectedDeleteMember,
  ] = useState<TeamMember | null>(null);
  const [filter, setFilter] = useState<string | undefined>('');
  const [defaultTeamId, setDefaultTeamId] = useState<string>('');

  useEffect(() => {
    const isTeamOwner = teamList && teamList.some(team => team.is_owner);

    if (teamList && teamList.length > 0 && !isTeamOwner) {
      navigate('/my-board/personal');
    }
  }, [teamList]);

  const onPressAddTeam = () => {
    setIsAddTeamModalOpen(!isAddTeamModalOpen);
  };

  const onPressAddTeamMember = () =>
    setIsAddTeamMemberModal(!isAddTeamMemberModalOpen);

  const onCloseToast = () => setNotification(null);

  const onInviteTeamMember = (toast: INotification) => {
    setNotification(toast);
    setIsAddTeamMemberModal(false);
  };

  const onPressMemberEditModal = () => setSelectedMember(null);

  const onPressMemberDeleteModal = () => setSelectedDeleteMember(null);

  const { data: members = [], isLoading: isLoadingMembers } = useQuery(
    [queryKeys.FETCH_TEAM_MEMBERS, defaultTeamId, filter],
    () => {
      return fetchTeamMembers(Number(defaultTeamId), filter);
    },
    {
      enabled: Number(defaultTeamId) > 0,
    },
  );

  const { mutate: createTeamMutation, isLoading: isCreatingTeam } = useMutation(
    (teamName: string) => {
      return createMyBoardTeam(teamName);
    },
    {
      onSuccess: (response: CreateTeamSuccess) => {
        queryClient.invalidateQueries([queryKeys.FETCH_MY_BOARD_TEAMS]);
        onPressAddTeam();
        setNotification({
          isOpen: true,
          message: response.message,
          type: 'success',
          position: 'top-right',
          onClose: onCloseToast,
        });
      },
      onError: (response: { response: AxiosResponse }) => {
        onPressAddTeam();
        const res: AxiosResponse = response.response;
        const data = res?.data as any;
        if (data.status_code === 422) {
          let messages = '';
          Object.keys(data.errors).forEach(key => {
            messages += data.errors[key];
          });
          setNotification({
            isOpen: true,
            message: messages,
            type: 'error',
            position: 'top-right',
            onClose: onCloseToast,
          });
        } else {
          setNotification({
            isOpen: true,
            message: data.errors.messages,
            type: 'error',
            position: 'top-right',
            onClose: onCloseToast,
          });
        }
      },
    },
  );

  const handleAddTeam = useCallback(
    (
      values: CreateTeamValues,
      { resetForm }: FormikHelpers<CreateTeamValues>,
    ) => {
      resetForm();
      createTeamMutation(values.teamName);
    },
    [],
  );

  const {
    mutate: updateTeamMemberMutation,
    isLoading: isUpdatingTeamMember,
  } = useMutation(
    ({
      teamId,
      customerId,
      isOwner,
    }: {
      teamId: number;
      customerId: number;
      isOwner: number;
    }) => updateTeamMember(teamId, customerId, isOwner),
  );

  const onSetTeamMemberCb = (
    message: string,
    type: 'success' | 'error' | 'info' | 'notification',
    isEdit: boolean,
  ) => {
    setNotification({
      isOpen: true,
      message,
      type,
      position: 'top-right',
      onClose: handleCloseToast,
    });
    queryClient.invalidateQueries([
      queryKeys.FETCH_TEAM_MEMBERS,
      defaultTeamId,
      filter,
    ]);
    isEdit ? onPressMemberEditModal() : onPressMemberDeleteModal();
  };

  const onUpdateTeamMember = useCallback(
    (values: { role: string }) => {
      const params: {
        teamId: number;
        customerId: number;
        isOwner: number;
      } = {
        teamId: Number(defaultTeamId),
        customerId: selectedMember?.id || 0,
        isOwner: Number(values.role),
      };

      updateTeamMemberMutation(params, {
        onSuccess: res => {
          onSetTeamMemberCb(res?.message || '', 'success', true);
        },
        onError: error => {
          const err = error as AxiosError;
          onSetTeamMemberCb(err.message, 'error', true);
        },
      });
    },
    [defaultTeamId, selectedMember],
  );

  const {
    mutate: deleteTeamMemberMutation,
    isLoading: isDeletingTeamMember,
  } = useMutation((values: DeleteTeamMemberValues) => {
    return deleteTeamMember(values.teamId, values.customerId);
  });

  const handleCloseToast = () => {
    setNotification(null);
  };

  const onEdit = (member: TeamMember) => setSelectedMember(member);

  const onDelete = (member: TeamMember) => setSelectedDeleteMember(member);

  const onDeleteTeamMember = useCallback(() => {
    const params: DeleteTeamMemberValues = {
      teamId: defaultTeamId || '',
      customerId: `${selectedDeleteMember?.id || ''}`,
    };

    deleteTeamMemberMutation(params, {
      onSuccess: response => {
        onSetTeamMemberCb(response.message, 'success', false);
      },
      onError: error => {
        const err = error as AxiosError;
        const errorResponse: {
          errors: {
            message: string[] | string;
          };
        } = err.response?.data || { errors: { message: err.message } };
        const errorMessage: string = Array.isArray(
          errorResponse?.errors?.message,
        )
          ? errorResponse.errors.message.join(',')
          : errorResponse.errors.message;
        onSetTeamMemberCb(errorMessage, 'error', false);
      },
    });
  }, [defaultTeamId, selectedDeleteMember]);

  useEffect(() => {
    if (teamId) {
      setDefaultTeamId(teamId);
    } else if (teamList && teamList?.length) {
      setDefaultTeamId(String(teamList?.[0].id));
    }
  }, [teamId, teamList]);

  return (
    <StyledContainer>
      <ManagementMenu
        teamId={Number(defaultTeamId)}
        teams={teamList || []}
        activeItem={activeItem}
        onPressAddTeam={onPressAddTeam}
        onPressAddTeamMember={onPressAddTeamMember}
      />
      <StyledContentWrapper>
        <FilterWrapper>
          <StyledContentHeader>{t('memberManagement')}</StyledContentHeader>
          <Divider />
          <StyledSearch
            placeholder={t('searchPlaceholder')}
            value={filter}
            onChangeText={setFilter}
            backgroundIcon={searchImg}
          />
        </FilterWrapper>
        <MemberManagementTable
          onEdit={onEdit}
          onDelete={onDelete}
          teamMembers={members}
          isLoading={isLoadingMembers}
          currenUserId={currentUser?.user.customer.id}
        />
        <AddTeamMemberModal
          isOpen={isAddTeamMemberModalOpen}
          teamList={teamList}
          isFetchingTeams={isFetchingTeams}
          onClose={onPressAddTeamMember}
          onCloseToast={onCloseToast}
          onInviteTeamMember={onInviteTeamMember}
          onDeleteTeamMember={toast => setNotification(toast)}
        />
        <AddTeamModal
          isOpen={isAddTeamModalOpen}
          isCreatingTeam={isCreatingTeam}
          onClose={onPressAddTeam}
          onPressAdd={handleAddTeam}
        />
        <MemberEditModal
          isOpen={!!selectedMember}
          onClose={onPressMemberEditModal}
          member={selectedMember}
          onSubmit={onUpdateTeamMember}
          isLoading={isUpdatingTeamMember}
        />
        <MemberDeleteModal
          confirmationText={t('deleteThisAccountInTeam')}
          isOpen={!!selectedDeleteMember}
          onClose={onPressMemberDeleteModal}
          onDelete={onDeleteTeamMember}
          isLoading={isDeletingTeamMember}
        />
        {notification ? <Toast {...notification} /> : null}
      </StyledContentWrapper>
    </StyledContainer>
  );
};

export default Component;
