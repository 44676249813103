import * as Yup from 'yup';
import i18n from '../../../constants/words';

export const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required(i18n.t('resetRequiredPassword'))
    .min(8, i18n.t('passwordMinLength'))
    .max(255, i18n.t('maxLengthPassword')),
  repassword: Yup.string()
    .required(i18n.t('resetRequiredReentryPassword'))
    .min(8, i18n.t('resetConfirmPasswordMinLength'))
    .max(255, i18n.t('resetConfirmPasswordMaxLength'))
    .oneOf([Yup.ref('password')], i18n.t('passwordMustMatch')),
});
