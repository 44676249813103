import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import theme from '../../../constants/themes';
import queryKeys from '../../../constants/queryKeys';
import {
  AmericanExpress,
  Discover,
  MasterCard,
  Visa,
  UnknownCard,
  UnionPay,
  Jcb,
  DinersClub,
} from '../../atoms/Icons';
import { UpgradePlan } from '../../molecules/UpgradePlan';
import { cardTypes } from './types';
import { useGlobalState } from '../../../hooks/global';
import { usePaymentHooks } from '../../../hooks/payment';
import { Plan } from '../../../domain/entities/plan';
import { ModalWrapper } from '../../molecules/ModalWrapper';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { PaymentCardForm } from '../../organisms/PaymentCardForm';
import { useAuthHooks } from '../../../hooks/auth';
import { Skeleton } from '@mui/material';
import './history.css';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 36px 40px 0px 40px;
`;

const SettingsLabel = styled.span`
  width: max-content;
  font-family: ${theme.fonts.primary};
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  letter-spacing: -0.02em;
  color: ${theme.colors.textColor};
  border-bottom: 3px solid ${theme.colors.notifToastBorder};
`;

const CurrentPlanLabel = styled.span`
  font-family: ${theme.fonts.primary};
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: ${theme.colors.textColor};
  margin-top: 11px;
`;

const StyledUpgradePlan = styled(UpgradePlan)`
  margin-top: 9px;
`;

const CardLabel = styled.span`
  font-family: ${theme.fonts.primary};
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: ${theme.colors.textColor};
  margin-top: 26px;
`;

const CardTypeLabel = styled.span`
  font-family: ${theme.fonts.primary};
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: ${theme.colors.black};
  margin-top: 14px;
`;

const CardInfoWrapper = styled.div`
  width: calc(100% - 135px);
  height: max-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
  border-bottom: thin solid #d7d7d7;
  padding-bottom: 16.5px;

  @media ${theme.breakpoints.mobile} {
    width: 100% !important;
  }

  @media ${theme.breakpoints.tablet} {
    width: 100% !important;
  }
`;

const UpgradePlanSkeleton = styled(Skeleton)`
  width: 100%;
  max-width: 1000px;
`;

const CardNumber = styled.span`
  font-family: ${theme.fonts.primary};
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: ${theme.colors.black};
`;

const StyledAmex = styled(AmericanExpress)`
  width: 20px;
  height: 18px;
  margin-left: 5px;
`;

const StyledDiscover = styled(Discover)`
  width: 20px;
  height: 18px;
  margin-left: 5px;
`;

const StyledMaster = styled(MasterCard)`
  width: 20px;
  height: 18px;
  margin-left: 5px;
`;

const StyledVisa = styled(Visa)`
  width: 20px;
  height: 18px;
  margin-left: 5px;
`;

const StyledUnknown = styled(UnknownCard)`
  width: 20px;
  height: 18px;
  margin-left: 5px;
`;

const StyledUnionPay = styled(UnionPay)`
  width: 20px;
  height: 18px;
  margin-left: 5px;
`;

const StyledJcb = styled(Jcb)`
  width: 20px;
  height: 18px;
  margin-left: 5px;
`;

const StyledDinersClub = styled(DinersClub)`
  width: 20px;
  height: 18px;
  margin-left: 5px;
`;

const EditCard = styled.span`
  width: max-content;
  font-family: ${theme.fonts.primary};
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: ${theme.colors.textColor};
  text-decoration: underline;
  margin-left: 45px;
  cursor: pointer;
`;

const PaymentHistoryLabel = styled.span`
  font-family: ${theme.fonts.primary};
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: ${theme.colors.textColor};
  margin-top: 20.5px;
`;

const InvoiceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 14px;

  @media ${theme.breakpoints.mobile} {
    overflow-x: scroll;
  }
`;

const InvoiceValuesWrapper = styled.div`
  width: max-content;
  max-height: calc(100vh - 550px);
  overflow-y: auto;

  @media ${theme.breakpoints.mobile} {
    width: 576px;
  }

  @media ${theme.breakpoints.tablet} {
    width: 100%;
  }
`;

const RowWrapper = styled.div<{ marginBot: number }>`
  width: max-content;
  display: flex;
  flex-direction: row;
  height: max-content;
  align-items: center;
  margin-bottom: ${props => `${props.marginBot}px`};

  @media ${theme.breakpoints.mobile} {
    width: 576px;
  }

  @media ${theme.breakpoints.tablet} {
    width: 100%;
  }
`;

const HistoryHeader = styled.span`
  width: 100%;
  font-family: ${theme.fonts.primary};
  font-weight: 700;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: ${theme.colors.textColor};
`;

const InvoiceText = styled.span`
  width: max-content;
  font-family: ${theme.fonts.primary};
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: ${theme.colors.textColor};
`;

const DateWrapper = styled.div`
  width: 120px;
  height: max-content;

  @media ${theme.breakpoints.mobile} {
    width: 20%;
  }

  @media ${theme.breakpoints.tablet} {
    width: 20%;
  }
`;

const ExplanationWrapper = styled.div`
  width: 379px;
  height: max-content;
  display: flex;
  flex-direction: row;

  @media ${theme.breakpoints.mobile} {
    width: 40%;
  }

  @media ${theme.breakpoints.tablet} {
    width: 40%;
  }
`;

const AmountWrapper = styled.div`
  height: max-content;
  width: 237px;

  @media ${theme.breakpoints.mobile} {
    width: 20%;
  }

  @media ${theme.breakpoints.tablet} {
    width: 20%;
  }
`;

const StatusWrapper = styled.div`
  height: max-content;
  min-width: 50px;

  @media ${theme.breakpoints.mobile} {
    min-width: 20%;
  }

  @media ${theme.breakpoints.tablet} {
    min-width: 20%;
  }
`;

const StyledDownloadInvoice = styled.span`
  width: max-content;
  font-family: ${theme.fonts.primary};
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: -0.02em;
  margin-left: 3px;
  color: ${theme.colors.linkColor};
  cursor: pointer;

  &:hover {
    font-weight: 600;
  }
`;

const StyledModalWrapper = styled(ModalWrapper)`
  width: 536px;
`;

const ModalStripeCardDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em;
  gap: 1em;
`;

const StyledPaymentCardForm = styled(PaymentCardForm)`
  width: 100%;

  form {
    width: 100%;
  }
`;

export type Props = {
  upgradeTheme: string;
};

const Component = ({ upgradeTheme }: Props): React.ReactElement => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    useCurrentUser: { currentUser, setCurrentUser },
  } = useGlobalState();
  const { useFetchUser } = useAuthHooks();
  const { usePaymentHistory } = usePaymentHooks();
  const { fetchPaymentHistory, fetchInvoiceBlob } = usePaymentHistory();
  const [stripeKey, setStripeKey] = useState<string>();
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null>>();
  const [stripeIsLoading, setStripeIsLoading] = useState<boolean>(true);
  const [editingCard, setIsEditingCard] = useState<boolean>(false);

  const [userPlan, setUserPlan] = useState<Plan>();
  const [last4, setLast4] = useState<string>('0000');
  const [cardType, setCardType] = useState<cardTypes>('unknown');
  const { fetchUserDetails } = useFetchUser();
  const [planLoading, setPlanLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!process.env.REACT_APP_STRIPE_API_KEY) {
      throw new Error('Stripe not configured');
    }

    setStripeKey(process.env.REACT_APP_STRIPE_API_KEY);

    getUserDetail();
  }, []);

  useEffect(() => {
    const lastCardNumber = currentUser?.user.customer.last_card_number;
    if (lastCardNumber) {
      setLast4(lastCardNumber);
    }
  }, [currentUser?.user.customer.last_card_number]);

  useEffect(() => {
    const cardType = currentUser?.user.customer.card_type;

    if (cardType) {
      setCardType(cardType);
    }
  }, [currentUser?.user.customer.card_type]);

  useEffect(() => {
    if (stripeKey && !stripePromise) {
      setStripePromise(loadStripe(stripeKey));
      setStripeIsLoading(false);
    }
  }, [stripeKey, stripePromise]);

  const { data: historyList = [] } = useQuery(
    [queryKeys.FETCH_PAYMENT_HISTORY],
    () => {
      return fetchPaymentHistory(String(currentUser?.user.customer.id) || '');
    },
    {
      refetchOnMount: true,
    },
  );

  const { mutate: getInvoicePdfMutation } = useMutation(
    async (invoiceCode: string) => {
      return fetchInvoiceBlob(invoiceCode);
    },
  );

  const { mutate: getUserDetail } = useMutation(
    async () => await fetchUserDetails(),
    {
      onSuccess: res => {
        if (res) {
          const plan = res.plan;

          if (plan) {
            setCurrentUser({
              ...currentUser,
              user: {
                ...currentUser?.user,
                customer: {
                  ...currentUser?.user.customer,
                  plan,
                  plan_id: plan.id,
                },
              },
            });
            setUserPlan(plan);
            setPlanLoading(false);
          }
        }
      },
    },
  );

  const renderCardIcon = (cardType?: cardTypes): React.ReactElement => {
    switch (cardType) {
      case 'amex':
        return <StyledAmex />;
      case 'cartes_bancaires':
        return <StyledVisa />;
      case 'diners':
        return <DinersClub />;
      case 'discover':
        return <StyledDiscover />;
      case 'jcb':
        return <StyledJcb />;
      case 'mastercard':
        return <StyledMaster />;
      case 'visa':
        return <StyledVisa />;
      case 'unionpay':
        return <StyledUnionPay />;
      default:
        return <StyledUnknown />;
    }
  };

  const getBrandLabel = (cardType?: cardTypes): string => {
    switch (cardType) {
      case 'amex':
        return 'American Express';
      case 'cartes_bancaires':
        return 'Cartes Bancaires';
      case 'diners':
        return 'Diners Club';
      case 'discover':
        return 'Discover';
      case 'jcb':
        return 'Japan Credit Bureau (JCB)';
      case 'mastercard':
        return 'Mastercard';
      case 'visa':
        return 'Visa';
      case 'unionpay':
        return 'China UnionPay';
      default:
        return 'UNKNOWN';
    }
  };

  return (
    <Container>
      <StyledModalWrapper
        showTitle={false}
        isOpen={editingCard}
        onClose={() => setIsEditingCard(false)}>
        <ModalStripeCardDetailsWrapper>
          {stripeIsLoading ? (
            <>Initializing Stripe Card Form</>
          ) : (
            <Elements stripe={stripePromise as Promise<Stripe | null>}>
              <StyledPaymentCardForm
                themeColor={userPlan?.themeColor}
                planId={Number(userPlan?.id)}
                className="w-100"
                edit={true}
                onClose={() => setIsEditingCard(false)}
              />
            </Elements>
          )}
        </ModalStripeCardDetailsWrapper>
      </StyledModalWrapper>

      <SettingsLabel>{t('paymentHistorySettings')}</SettingsLabel>
      <CurrentPlanLabel>{t('currentPlan')}</CurrentPlanLabel>
      {planLoading ? (
        <UpgradePlanSkeleton variant="rectangular" height="168px" />
      ) : (
        <StyledUpgradePlan
          price={userPlan?.price || 0}
          planTitle={userPlan?.type || ''}
          themeColor={userPlan?.themeColor || theme.colors.primaryBtn}
          upgradeColor={upgradeTheme}
          onPressUpgradePlan={() => {
            navigate(`/plan-select`, {
              state: {
                currentPlanId: userPlan?.id,
                isFromPlanAndBilling: true,
              },
            });
          }}
        />
      )}
      {userPlan?.type && userPlan?.type !== 'FREE' ? (
        <>
          <CardLabel>{t('cardLabel')}</CardLabel>
          <CardTypeLabel>
            {getBrandLabel(currentUser?.user.customer.card_type)}
          </CardTypeLabel>
          <CardInfoWrapper>
            <span>＊＊＊＊＊＊＊＊＊</span>
            <CardNumber>{last4}</CardNumber>
            {renderCardIcon(cardType)}
            <EditCard onClick={() => setIsEditingCard(!editingCard)}>
              {t('edit')}
            </EditCard>
          </CardInfoWrapper>
        </>
      ) : (
        ''
      )}
      <PaymentHistoryLabel>{t('paymentHistoryLabel')}</PaymentHistoryLabel>
      <InvoiceWrapper>
        <RowWrapper marginBot={2}>
          <DateWrapper>
            <HistoryHeader>{t('date')}</HistoryHeader>
          </DateWrapper>
          <ExplanationWrapper>
            <HistoryHeader>{t('explanation')}</HistoryHeader>
          </ExplanationWrapper>
          <AmountWrapper>
            <HistoryHeader>{t('amount')}</HistoryHeader>
          </AmountWrapper>
          <AmountWrapper>
            <HistoryHeader>{t('status')}</HistoryHeader>
          </AmountWrapper>
        </RowWrapper>
        <InvoiceValuesWrapper>
          {historyList.map((invoice, index) => {
            return (
              <RowWrapper
                key={index}
                marginBot={index === historyList.length - 1 ? 0 : 20}>
                <DateWrapper>
                  <InvoiceText>
                    {new Date(invoice.paymentDate).toLocaleDateString('en-CA')}
                  </InvoiceText>
                </DateWrapper>
                <ExplanationWrapper>
                  <InvoiceText>#{invoice.invoiceCode}</InvoiceText>
                  <StyledDownloadInvoice
                    onClick={() => {
                      getInvoicePdfMutation(invoice.invoiceCode);
                    }}>
                    請求書
                  </StyledDownloadInvoice>
                </ExplanationWrapper>
                <AmountWrapper>
                  <InvoiceText>
                    {invoice.paymentPrice}
                    {t('yen')}
                  </InvoiceText>
                </AmountWrapper>
                <StatusWrapper>
                  <InvoiceText>{invoice.paymentStatus}</InvoiceText>
                </StatusWrapper>
              </RowWrapper>
            );
          })}
        </InvoiceValuesWrapper>
      </InvoiceWrapper>
    </Container>
  );
};

export default Component;
