import BoardRepository from '../../usecases/ports/BoardRepository';
import { HttpAdapter } from '../../usecases/ports/HttpAdapter';
import {
  Board,
  BoardCustomers,
  BoardMedia,
  MyBoard,
  SortType,
  FavoriteSuccess,
  UnfavoriteSuccess,
  Template,
  InviteCustomerToBoardSuccess,
} from '../../domain/entities/board';
import { UpdateRoleSuccess } from '../../domain/entities/role';
import { URLsType } from '../../constants/urls';
import { GenericResponse } from '../../domain/entities/rest';

export default class BoardRepositoryImpl implements BoardRepository {
  httpAdapter: HttpAdapter;
  urls: URLsType['board'];

  constructor(httpAdapter: HttpAdapter, urls: URLsType['board']) {
    this.httpAdapter = httpAdapter;
    this.urls = urls;
  }

  createBoard = async ({
    name,
    teamId,
    diagram,
  }: {
    name: string;
    teamId?: number;
    diagram: string;
  }): Promise<string> => {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('file_name', `${name}.xml`);
    formData.append('team_id', (teamId && `${teamId}`) || '');
    formData.append(
      'diagram_file',
      new Blob([diagram], {
        type: 'text/xml',
      }),
    );
    const response = await this.httpAdapter.post(
      this.urls.createBoard,
      formData,
    );
    return response.data.data.code;
  };

  fetchBoardByCode = async (code: string): Promise<Board | undefined> => {
    const response = await this.httpAdapter.get(this.urls.getBoardByCode, {
      params: { code },
    });
    const data = response.data.data;
    return {
      code: data.code,
      name: data.name,
      url: data.url,
      diagramFile: data.diagram_file,
      roomId: data.room_id,
      team: data.team,
      customers: data.customers,
    };
  };

  fetchBoardCustomer = async (
    customerId: number,
    boardCode: string,
  ): Promise<BoardCustomers> => {
    const params = { customer_id: customerId, board_code: boardCode };
    const response = await this.httpAdapter.post(this.urls.getCustomer, params);
    const res = response.data?.data;

    const latestBoards = Array.isArray(res?.latest_boards)
      ? res.latest_boards.map(
          (item: {
            name: string;
            code: string;
            diagram_file: string;
            customers: { id: number; fullname: string; avatar: string }[];
          }) => ({
            diagramFile: item?.diagram_file || '',
            name: item?.name || '',
            code: item?.code || '',
            members: (Array.isArray(item?.customers) ? item.customers : []).map(
              customer => ({
                id: customer.id,
                fullname: customer.fullname,
                avatar: customer.avatar,
              }),
            ),
          }),
        )
      : [];

    const data: BoardCustomers = {
      id: res.id,
      name: res.fullname,
      role: res.role_name || '',
      boards: res.board_cnt || 0,
      stars: res.vote_count || 0,
      trophies: 0,
      summary: res.summary || 0,
      team: res.team || 0,
      mvpCount: res.mvp_count || 0,
      badges: 0,
      roleContent: res.role_content,
      roleName: res.role_name,
      teamName: res.team_name,
      latestBoards: latestBoards,
      displayPhoto: res.role_image,
      createdAt: res.created_at,
      engRole: res.role_title_english,
    };
    return data;
  };

  fetchBoardCustomers = async (
    boardCode: string,
  ): Promise<BoardCustomers[]> => {
    const response = await this.httpAdapter.get(this.urls.getCustomers, {
      params: { board_code: boardCode },
    });
    const data: BoardCustomers[] =
      response.data?.data?.map(
        (res: {
          fullname: string;
          role_name: string | null;
          avatar: string;
          board_cnt: number;
          id: number;
          mvp_cnt: number;
          vote_cnt: number;
          badges: number;
          role_image: string;
          email: string;
          role_title_english: string;
          team_count: number;
          role_content: string;
          latest_boards: {
            name: string;
            code: string;
            diagram_file: string;
            customers: { id: number; fullname: string; avatar: string }[];
          }[];
        }) => {
          const latestBoards = Array.isArray(res?.latest_boards)
            ? res.latest_boards.map(item => ({
                diagramFile: item?.diagram_file || '',
                name: item?.name || '',
                code: item?.code || '',
                members: (Array.isArray(item?.customers)
                  ? item.customers
                  : []
                ).map(customer => ({
                  id: customer.id,
                  fullname: customer.fullname,
                  avatar: customer.avatar,
                })),
              }))
            : [];
          return {
            id: res.id,
            name: res.fullname,
            role: res.role_name || '',
            boards: res.board_cnt || 0,
            stars: res.vote_cnt || 0,
            trophies: res.mvp_cnt || 0,
            badges: res.badges || 0,
            displayPhoto: res.avatar,
            roleImage: res.role_image,
            engRole: res.role_title_english,
            team: res.team_count,
            roleContent: res.role_content,
            latestBoards,
          };
        },
      ) || [];
    return data;
  };

  updateBoard = async (payload: {
    name: string;
    boardCode: string;
    xmlString?: string;
  }): Promise<void> => {
    await this.httpAdapter.put(this.urls.updateBoard(payload.boardCode), {
      name: payload.name,
    });
  };

  saveBoard = async (payload: {
    xmlString?: string | undefined;
    boardCode: string;
  }): Promise<GenericResponse> => {
    const formData = new FormData();
    if (payload.xmlString) {
      formData.append(
        'diagram_file',
        new Blob([payload.xmlString], {
          type: 'text/xml',
        }),
      );
    }
    formData.append('board_code', payload.boardCode);
    formData.append('file_name', `${payload.boardCode}.xml`);
    const response = await this.httpAdapter.post(
      this.urls.saveBoard,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    );
    const data = response.data;
    return {
      statusCode: data.status_code,
      message: data.message,
    };
  };

  fetchBoards = async (
    search: string,
    sortKey: SortType,
    isFavorite: boolean,
    teamId?: number,
  ): Promise<MyBoard[]> => {
    let sort_key = '変更日';
    if (sortKey === '変更日') sort_key = 'updated_at';
    else if (sortKey === '変更者') sort_key = 'updated_by';
    else if (sortKey === 'ボード名') sort_key = 'name';
    const params = {
      ...(!!search && { keyword: search }),
      sort_key,
      sort_direction: sortKey === '変更日' ? 'DESC' : 'ASC',
      ...(isFavorite && { is_favorite: true }),
      ...(!!teamId && { team_id: teamId }),
    };
    const options = {
      params,
      headers: {
        Accept: 'application/json',
      },
    };

    const response = await this.httpAdapter.get(this.urls.fetchBoards, options);
    const data = response.data.data;

    return data.map(
      (
        value: MyBoard & {
          diagram_file: string;
          created_by: string;
          updated_at: string;
          is_favorite: boolean;
        },
      ) => {
        return {
          ...value,
          diagramFile: value.diagram_file,
          createdBy: value.created_by,
          updatedAt: value.updated_at,
          isFavorite: value.is_favorite,
        };
      },
    );
  };

  inviteCustomers = async (
    boardCode: string,
    emails: string[],
  ): Promise<InviteCustomerToBoardSuccess> => {
    const response = await this.httpAdapter.post(this.urls.inviteCustomers, {
      board_code: boardCode,
      emails,
    });

    return {
      data: response.data.data[0],
    };
  };

  removeCustomer = async (
    boardCode: string,
    customerId: string,
  ): Promise<GenericResponse> => {
    const response = await this.httpAdapter.delete(
      this.urls.removeCustomer(customerId),
      {
        params: {
          board_code: boardCode,
        },
      },
    );

    return {
      statusCode: response.data.status_code,
      message: response.data.message,
    };
  };

  updateCustomerRole = async (
    boardCode: string,
    roleId: number,
  ): Promise<UpdateRoleSuccess> => {
    const params = {
      m_role_id: roleId,
    };
    const response = await this.httpAdapter.put(
      this.urls.updateCustomerRole(`${boardCode}`),
      params,
    );

    return response.data;
  };

  fetchMedia = async (customerId: number): Promise<BoardMedia[]> => {
    const response = await this.httpAdapter.get(
      this.urls.fetchMedia(`${customerId}`),
      {},
    );
    return (
      response.data?.data?.map(
        (data: {
          id: number;
          customer_id: number;
          file_path: string;
          type: number;
        }) => ({
          id: data.id,
          customerId: data.customer_id,
          path: data.file_path,
          type: data.type,
        }),
      ) || []
    );
  };

  uploadMedia = async (file: File, type: number): Promise<void> => {
    const fileName = file.name;

    const formData = new FormData();
    formData.append('upload_file', file);
    formData.append('type', `${type}`);
    formData.append('file_name', fileName);
    await this.httpAdapter.post(this.urls.uploadMedia, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  };

  setBoardAsFave = async (boardCode: string): Promise<FavoriteSuccess> => {
    const params = {
      code: boardCode,
    };

    const response = await this.httpAdapter.post(
      this.urls.setBoardFavorite,
      params,
    );

    return {
      ...response.data,
      data: {
        ...response.data.data,
        boardId: response.data.data.board_id,
        customerId: response.data.data.customer_id,
        createdBy: response.data.data.created_by,
        updatedBy: response.data.data.updatedBy,
        createdAt: response.data.data.created_at,
      },
    };
  };

  unfavoriteBoard = async (boardCode: string): Promise<UnfavoriteSuccess> => {
    const response = await this.httpAdapter.delete(
      this.urls.removeBoardFavorite(boardCode),
      {},
    );

    return response.data;
  };

  fetchTemplateList = async (): Promise<Template[]> => {
    const { data } = await this.httpAdapter.get(
      this.urls.fetchTemplateList,
      {},
    );
    return data.data.map(
      (data: { id: number; name: string; s3_url_xml: string }) => ({
        id: data.id,
        name: data.name,
        diagramUrl: data.s3_url_xml,
      }),
    );
  };

  voteCustomer = async (
    boardCode: string,
    customerId: number,
  ): Promise<GenericResponse> => {
    const params = {
      board_code: boardCode,
      customer_id: customerId,
    };

    const response = await this.httpAdapter.post(
      this.urls.voteCustomer,
      params,
    );

    return response;
  };

  acceptBoardInvitation = async (
    invitationCode: string,
  ): Promise<GenericResponse> => {
    const params = {
      invitation_code: invitationCode,
    };

    const response = await this.httpAdapter.put(
      `${this.urls.acceptBoardInvitation}/${invitationCode}`,
      params,
    );
    return response;
  };
}
