import { AxiosError, AxiosResponse } from 'axios';
import { FormikHelpers } from 'formik';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { NavLink, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import plus from '../../../assets/images/plus.svg';
import searchImg from '../../../assets/images/search.png';
import storageKeys from '../../../constants/localStorage';
import theme from '../../../constants/themes';
import events from '../../../constants/socket';
import queryKeys from '../../../constants/queryKeys';
import {
  FavoriteSuccess,
  MyBoard,
  SortType,
  UnfavoriteSuccess,
} from '../../../domain/entities/board';
import { CreateTeamSuccess } from '../../../domain/entities/team';
import { useDependencies } from '../../../hooks';
import { useBoardHooks } from '../../../hooks/board';
import { useGlobalState } from '../../../hooks/global';
import { useTeamHooks } from '../../../hooks/team';
import { isWindowsOS } from '../../../utils/booleans';
import { InputWithIcon } from '../../atoms/InputWithIcon';
import { Link } from '../../atoms/Link';
import { Toast } from '../../atoms/Toast';
import { Props as INotification } from '../../atoms/Toast/Component';
import { MyBoardFilter } from '../../molecules/MyBoardFilter';
import { MyBoardSort } from '../../molecules/MyBoardSort';
import { AddTeamModal } from '../../organisms/AddTeamModal';
import { CreateTeamValues } from '../../organisms/AddTeamModal/types';
import { CardLayout } from '../../organisms/BoardCardLayout';
import { BoardListLayout } from '../../organisms/BoardListLayout';
import { MyBoardMenu } from '../../organisms/MyBoardMenu';
import { MyBoardMenuMobile } from '../../organisms/MyBoardMenuMobile';
import { BOARD_CODE_IF_NEW } from '../../pages/BoardEditor/Component';
import { PaymentHistoryTemplate } from '../PaymentHistory';
import { SetProfile } from '../SetProfile';
import { MyTeamDropdown } from '../../atoms/MyTeamDropdown';
import { Close } from '../../atoms/Icons';
import { useSocketHooks } from '../../../hooks/socket';

const Container = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: row;
`;

const ContentWrapper = styled.div`
  width: 100%;
  min-height: 100%;
  background-color: ${theme.colors.white};
`;

const WarningContainer = styled.div`
  width: 100%;
  height: 47px;
  background-color: ${theme.colors.warningBg};
  border: thin solid ${theme.colors.warningBorder};
  display: flex;
  flex-direction: row;
  margin-top: 6px;
  position: relative;
  align-items: center;
  justify-content: center;
`;

const StyledClose = styled(Close)`
  position: absolute;
  right: 10px;
  cursor: pointer;
  align-self: center;
`;

const WarningLabel = styled.span`
  font-family: ${theme.fonts.primary};
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: ${theme.colors.warningText};
`;

const StyledUpdateEmailLink = styled(Link)`
  font-family: ${theme.fonts.primary};
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: -0.02em;
`;

const StyledSearch = styled(InputWithIcon)`
  width: 280px;
  height: 40px;
  margin-top: 23px;
  margin-bottom: 26px;
  padding-top: ${isWindowsOS() ? 13 : 8}px;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;

  @media ${theme.breakpoints.mobile} {
    width: 100%;
  }

  @media ${theme.breakpoints.tablet} {
    width: 100%;
  }
`;

const FilterWrapper = styled.div`
  margin-bottom: 14px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media ${theme.breakpoints.mobile} {
    margin-top: 10px;
    display: block;
  }
`;

const StyledSetProfile = styled(SetProfile)`
  margin: 50px;
`;

const FilterContainer = styled.div`
  width: calc(100% - 49px);
  padding-left: 22px;
  padding-right: 27px;
  height: fit-content;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 50px;
  background-color: ${theme.colors.white};
  z-index: 998;
`;

const LayoutContainer = styled.div`
  width: calc(100% - 49px);
  padding-left: 22px;
  padding-right: 27px;

  @media ${theme.breakpoints.mobile} {
    padding-bottom: 58px;
  }
`;

const TeamWrapper = styled(NavLink)`
  border: 1px solid ${theme.colors.notifToastBorder};
  border-top-left-radius: 5px;
  color: ${theme.colors.textColor};
  width: fit-content;
  padding: 5px;
  text-decoration: none;

  &.active {
    color: ${theme.colors.white};
    background-color: ${theme.colors.notifToastBorder};
  }
`;

const TeamAddButton = styled.div`
  border: 1px solid ${theme.colors.notifToastBorder};
  border-top-right-radius: 5px;
  color: ${theme.colors.textColor};
  width: fit-content;
  padding: 5px;
`;

const TeamsWrapper = styled.div`
  display: none;
  margin-bottom: 5px;

  @media ${theme.breakpoints.mobile} {
    display: flex;
    flex-wrap: wrap;
  }

  @media ${theme.breakpoints.tablet} {
    display: flex;
    flex-wrap: wrap;
  }
`;

export type Props = {
  activeItem: 'home' | 'personal' | 'team' | 'profile' | 'plan-billing';
  teamId?: string;
  className?: string;
  onPressBoardCard: (boardId: number, boardCode: string) => void;
};

const Component = ({
  activeItem,
  className,
  teamId,
  onPressBoardCard,
}: Props): React.ReactElement => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const {
    useCurrentUser: { currentUser, setCurrentUser },
  } = useGlobalState();
  const { useTeam } = useTeamHooks();
  const { createMyBoardTeam, fetchMyBoardTeams } = useTeam();
  const { useBoardAPI } = useBoardHooks();
  const {
    fetchMyBoards,
    setBoardAsFavorite,
    unfavoriteBoard,
    acceptBoardInvitation,
  } = useBoardAPI();
  const { localStorageInteractor } = useDependencies();
  const { getItem, removeItem } = localStorageInteractor;
  const [isAddTeamModalOpen, setIsAddTeamModalOpen] = useState(false);
  const [notification, setNotification] = useState<INotification | null>(null);
  const [searchFilter, setSearchFilter] = useState('');
  const [debounceSearch, setDebounceSearch] = useState('');
  const [boardFilter, setBoardFilter] = useState<'latest' | 'favorite'>(
    'latest',
  );
  const [sortFilter, setSortFilter] = useState<SortType>('変更日');
  const [layoutFilter, setLayoutFilter] = useState<'card' | 'list'>('card');
  const [isWarningOpen, setIsWarningOpen] = useState(false);
  const [faveClicked, setFaveClicked] = useState(false);
  const [favedUnfavedBoard, setFavedUnfavedBoard] = useState('');
  const [boards, setBoards] = useState<MyBoard[]>([]);
  const [successFavedUnfavedFlag, setSucessFavedUnfavedFlag] = useState<
    boolean | undefined
  >();

  const invitationCode = getItem(storageKeys.INVITATION_CODE_STORAGE_KEY) || '';
  const { useSocket } = useSocketHooks();
  const { socketInit, socket } = useSocket;

  useEffect(() => {
    window.scrollTo(0, 0);
    if (
      !currentUser?.user.customer.plan_id &&
      Number(currentUser?.user.customer.account_link) >= 1
    ) {
      navigate('/plan-select', {
        state: {
          isFromMyBoardSNS: true,
        },
      });
    }
    if (!currentUser?.user.customer.email) {
      setIsWarningOpen(true);
    }
    socketInit();
  }, []);

  useEffect(() => {
    if (socket) {
      const eventData = {
        admin: '0',
        user_id: currentUser?.user?.customer?.id,
      };

      socket.emit(events.CLIENT.VISIT, eventData);
    }
  }, [socket]);

  const onOpenAddTeamModal = () => {
    setIsAddTeamModalOpen(true);
  };

  const onCloseAddTeamModal = () => {
    setIsAddTeamModalOpen(false);
  };

  const handleCloseToast = () => {
    setNotification(null);
  };

  const onPressNewChannel = () => {
    navigate(`/board/${BOARD_CODE_IF_NEW}`, { state: { teamId } });
  };

  const getTeamName = () => {
    const teamNum = Number(teamId);

    return teamList.length > 0
      ? teamList.find(team => team.id === teamNum)?.name
      : '';
  };

  const getTeamValue = () => {
    return teamList.length > 0
      ? teamList.map(team => ({ id: team.id.toString(), label: team.name }))
      : [];
  };

  const {} = useQuery(
    [queryKeys.ACCEPT_BOARD_INVITATION, invitationCode],
    async () => {
      if (invitationCode) {
        return await acceptBoardInvitation(invitationCode);
      }
    },
    {
      refetchOnMount: true,
      onSuccess: res => {
        if (res) {
          queryClient.invalidateQueries([
            queryKeys.FETCH_MY_BOARD_LIST,
            debounceSearch,
            sortFilter,
            boardFilter,
            teamId,
          ]);
          setNotification({
            isOpen: true,
            message: res?.data?.message ?? '',
            type: 'success',
            position: 'top-right',
            onClose: handleCloseToast,
          });
          removeItem(storageKeys.INVITATION_CODE_STORAGE_KEY);
          queryClient.invalidateQueries([queryKeys.FETCH_MY_BOARD_TEAMS]);
        }
      },
      onError: error => {
        const res = error as AxiosError;
        const data = res.response?.data as any;
        if (data?.errors?.message[0]) {
          setNotification({
            isOpen: true,
            message: data?.errors?.message[0],
            type: 'error',
            position: 'top-right',
            onClose: handleCloseToast,
          });
        }
      },
    },
  );

  const { data: teamList = [] } = useQuery(
    [queryKeys.FETCH_MY_BOARD_TEAMS],
    () => {
      return fetchMyBoardTeams();
    },
    {
      onError: (response: { response: AxiosResponse }) => {
        const res: AxiosResponse = response.response;
        const data = res.data as any;
        if (data?.status_code === 401) {
          setCurrentUser(undefined);
          navigate('/signin');
        } else if (data?.status_code === 422) {
          let messages = '';
          Object.keys(data.errors).forEach(key => {
            messages += data.errors[key][0];
          });
          setNotification({
            isOpen: true,
            message: messages,
            type: 'error',
            position: 'top-right',
            onClose: handleCloseToast,
          });
        } else {
          setNotification({
            isOpen: true,
            message: data?.errors?.messages ?? data?.error,
            type: 'error',
            position: 'top-right',
            onClose: handleCloseToast,
          });
        }
      },
    },
  );

  const { data: boardList = [], isFetching: isLoadingBoards } = useQuery(
    [
      queryKeys.FETCH_MY_BOARD_LIST,
      debounceSearch,
      sortFilter,
      boardFilter,
      teamId,
    ],
    () => {
      const isFavorite = boardFilter === 'favorite' ? true : false;
      return fetchMyBoards(
        searchFilter,
        sortFilter,
        isFavorite,
        Number(teamId),
      );
    },
    {
      refetchOnMount: true,
      onError: (response: { response: AxiosResponse }) => {
        const res: AxiosResponse = response.response;
        const data = res.data as any;

        if (data?.status_code === 401) {
          setCurrentUser();
          navigate('/signin');
        } else if (data?.status_code === 422) {
          let messages = '';
          Object.keys(data.errors).forEach(key => {
            if (typeof data.errors[key] === 'string') {
              messages += data.errors[key];
            } else {
              messages += data.errors[key][0];
            }
          });
          setNotification({
            isOpen: true,
            message: messages,
            type: 'error',
            position: 'top-right',
            onClose: handleCloseToast,
          });
        } else {
          setNotification({
            isOpen: true,
            message: data?.errors?.messages || data?.error || '',
            type: 'error',
            position: 'top-right',
            onClose: handleCloseToast,
          });
        }
      },
    },
  );

  useEffect(() => {
    setBoards(boardList);
  }, [boardList]);

  const { mutate: createTeamMutation, isLoading: isCreatingTeam } = useMutation(
    (teamName: string) => {
      return createMyBoardTeam(teamName);
    },
    {
      onSuccess: (response: CreateTeamSuccess) => {
        queryClient.invalidateQueries([queryKeys.FETCH_MY_BOARD_TEAMS]);
        onCloseAddTeamModal();
        setNotification({
          isOpen: true,
          message: response.message,
          type: 'success',
          position: 'top-right',
          onClose: handleCloseToast,
        });
      },
      onError: (response: { response: AxiosResponse }) => {
        onCloseAddTeamModal();
        const res: AxiosResponse = response.response;
        const data = res.data as any;
        if (data?.status_code === 422) {
          let messages = '';
          Object.keys(data.errors).forEach(key => {
            messages += data.errors[key];
          });
          setNotification({
            isOpen: true,
            message: messages,
            type: 'error',
            position: 'top-right',
            onClose: handleCloseToast,
          });
        } else {
          setNotification({
            isOpen: true,
            message: data.errors.messages,
            type: 'error',
            position: 'top-right',
            onClose: handleCloseToast,
          });
        }
      },
    },
  );

  const {
    mutate: setBoardAsFaveMutation,
    isLoading: isSettingFave,
  } = useMutation(
    ['fave-board'],
    (boardCode: string) => {
      return setBoardAsFavorite(boardCode);
    },
    {
      onSuccess: (response: FavoriteSuccess) => {
        setFaveClicked(false);
        setSucessFavedUnfavedFlag(!successFavedUnfavedFlag);
        setNotification({
          isOpen: true,
          message: response.message,
          type: 'success',
          position: 'top-right',
          onClose: handleCloseToast,
        });
      },
      onError: (response: { response: AxiosResponse }) => {
        setFaveClicked(false);
        const res: AxiosResponse = response.response;
        const data = res.data as any;
        if (data?.status_code === 422) {
          setNotification({
            isOpen: true,
            message: data.errors,
            type: 'error',
            position: 'top-right',
            onClose: handleCloseToast,
          });
        } else {
          setNotification({
            isOpen: true,
            message: data.errors.messages,
            type: 'error',
            position: 'top-right',
            onClose: handleCloseToast,
          });
        }
      },
    },
  );

  const {
    mutate: unfavoriteBoardMutation,
    isLoading: isSettingUnfave,
  } = useMutation(
    ['unfave-board'],
    (boardCode: string) => {
      return unfavoriteBoard(boardCode);
    },
    {
      onSuccess: (response: UnfavoriteSuccess) => {
        setFaveClicked(false);
        setSucessFavedUnfavedFlag(!successFavedUnfavedFlag);
        setNotification({
          isOpen: true,
          message: response.message,
          type: 'success',
          position: 'top-right',
          onClose: handleCloseToast,
        });
      },
      onError: (response: { response: AxiosResponse }) => {
        setFaveClicked(false);
        const res: AxiosResponse = response.response;
        const data = res.data as any;
        if (data?.status_code === 422) {
          setNotification({
            isOpen: true,
            message: data.errors,
            type: 'error',
            position: 'top-right',
            onClose: handleCloseToast,
          });
        } else {
          setNotification({
            isOpen: true,
            message: data.errors.messages,
            type: 'error',
            position: 'top-right',
            onClose: handleCloseToast,
          });
        }
      },
    },
  );

  const handleAddTeam = useCallback(
    (
      values: CreateTeamValues,
      { resetForm }: FormikHelpers<CreateTeamValues>,
    ) => {
      resetForm();
      createTeamMutation(values.teamName);
    },
    [],
  );

  const handlePressFavorite = (boardId: number, isFavorite: boolean) => {
    setFavedUnfavedBoard(String(boardId));
    const pressedBoard = boards.find(board => board.id === boardId);

    if (!isFavorite && !faveClicked && !isSettingFave && !isSettingUnfave) {
      setFaveClicked(true);
      setBoardAsFaveMutation(pressedBoard?.code || '');
    } else if (
      isFavorite &&
      !faveClicked &&
      !isSettingFave &&
      !isSettingUnfave
    ) {
      setFaveClicked(true);
      unfavoriteBoardMutation(pressedBoard?.code || '');
    }
  };

  useEffect(() => {
    if (successFavedUnfavedFlag !== undefined) {
      const updatedBoards = boards.map(board => {
        if (Number(favedUnfavedBoard) === board.id) {
          return {
            ...board,
            isFavorite: !board.isFavorite,
          };
        } else {
          return board;
        }
      });
      setBoards(
        boardFilter === 'favorite'
          ? updatedBoards.filter(board => board.isFavorite)
          : updatedBoards,
      );
    }
  }, [successFavedUnfavedFlag]);

  useEffect(() => {
    handleOnChangeSearch(searchFilter);
  }, [searchFilter]);

  const handleOnChangeSearch = useCallback(
    debounce((value: string) => {
      setDebounceSearch(value);
    }, 500),
    [],
  );

  const renderContent = () => {
    switch (activeItem) {
      case 'home':
      case 'personal':
        return (
          <>
            <FilterContainer>
              {isWarningOpen && (
                <WarningContainer>
                  <WarningLabel>
                    {t('warningLabel')}
                    <StyledUpdateEmailLink
                      label={t('warningLink')}
                      to="/my-board/profile"
                    />
                  </WarningLabel>
                  <StyledClose onClick={() => setIsWarningOpen(false)} />
                </WarningContainer>
              )}
              <StyledSearch
                placeholder={t('searchPlaceholder')}
                value={searchFilter}
                onChangeText={(text: string) => {
                  setSearchFilter(text);
                }}
                backgroundIcon={searchImg}
              />
              <TeamsWrapper>
                <TeamWrapper to={'/my-board/personal'}>{`${t(
                  'personal',
                )}`}</TeamWrapper>
                <MyTeamDropdown
                  values={getTeamValue()}
                  selected={teamId ?? 'none'}
                  placeholder={'チーム選択'}
                  onChangeSelected={(id: string) =>
                    navigate(`/my-board/team/${id}`)
                  }
                />
                <TeamAddButton onClick={onOpenAddTeamModal}>
                  <img src={plus} />
                  {` ${t('addTeam')}`}
                </TeamAddButton>
              </TeamsWrapper>
              <FilterWrapper>
                <MyBoardFilter
                  selected={boardFilter}
                  activeItem="personal"
                  teamName={getTeamName()}
                  onChangeFilter={selected => setBoardFilter(selected)}
                />
                <MyBoardSort
                  selectedSort={sortFilter}
                  layoutFilter={layoutFilter}
                  onChangeLayout={layoutFilter =>
                    setLayoutFilter(layoutFilter as 'card' | 'list')
                  }
                  onChangeSort={selected => setSortFilter(selected as SortType)}
                />
              </FilterWrapper>
            </FilterContainer>
            <LayoutContainer>
              {layoutFilter === 'card' ? (
                <CardLayout
                  boards={boards}
                  isLoading={isLoadingBoards}
                  onPressAddNewBoard={onPressNewChannel}
                  onPressBoardCard={onPressBoardCard}
                  onPressFavorite={handlePressFavorite}
                />
              ) : (
                <BoardListLayout
                  boards={boards}
                  isLoading={isLoadingBoards}
                  onPressAddNewBoard={onPressNewChannel}
                  onPressBoardCard={onPressBoardCard}
                  onPressFavorite={handlePressFavorite}
                />
              )}
            </LayoutContainer>
          </>
        );
      case 'team':
        return (
          <>
            <FilterContainer>
              {isWarningOpen && (
                <WarningContainer>
                  <WarningLabel>
                    {t('warningLabel')}
                    <StyledUpdateEmailLink
                      label={t('warningLink')}
                      to="/my-board/profile"
                    />
                  </WarningLabel>
                  <StyledClose onClick={() => setIsWarningOpen(false)} />
                </WarningContainer>
              )}
              <StyledSearch
                placeholder={t('searchPlaceholder')}
                value={searchFilter}
                onChangeText={(text: string) => {
                  setSearchFilter(text);
                }}
                backgroundIcon={searchImg}
              />
              <TeamsWrapper>
                <TeamWrapper to={'/my-board/personal'}>{`${t(
                  'personal',
                )}`}</TeamWrapper>
                <MyTeamDropdown
                  values={getTeamValue()}
                  selected={teamId ?? 'none'}
                  placeholder={'チーム選択'}
                  onChangeSelected={(id: string) =>
                    navigate(`/my-board/team/${id}`)
                  }
                />
                <TeamAddButton onClick={onOpenAddTeamModal}>
                  <img src={plus} />
                  {` ${t('addTeam')}`}
                </TeamAddButton>
              </TeamsWrapper>
              <FilterWrapper>
                <MyBoardFilter
                  selected={boardFilter}
                  activeItem="team"
                  teamName={getTeamName()}
                  onChangeFilter={selected => setBoardFilter(selected)}
                />
                <MyBoardSort
                  selectedSort={sortFilter}
                  layoutFilter={layoutFilter}
                  onChangeLayout={layoutFilter =>
                    setLayoutFilter(layoutFilter as 'card' | 'list')
                  }
                  onChangeSort={selected => setSortFilter(selected as SortType)}
                />
              </FilterWrapper>
            </FilterContainer>
            <LayoutContainer>
              {layoutFilter === 'card' ? (
                <CardLayout
                  boards={boards}
                  isLoading={isLoadingBoards}
                  onPressAddNewBoard={onPressNewChannel}
                  onPressBoardCard={onPressBoardCard}
                  onPressFavorite={handlePressFavorite}
                />
              ) : (
                <BoardListLayout
                  boards={boards}
                  isLoading={isLoadingBoards}
                  onPressAddNewBoard={onPressNewChannel}
                  onPressBoardCard={onPressBoardCard}
                  onPressFavorite={handlePressFavorite}
                />
              )}
            </LayoutContainer>
          </>
        );
      case 'profile':
        return <StyledSetProfile />;
      case 'plan-billing':
        return <PaymentHistoryTemplate upgradeTheme="#FFD200" />;
      default:
        return (
          <>
            <FilterContainer>
              {isWarningOpen && (
                <WarningContainer>
                  <WarningLabel>
                    {t('warningLabel')}
                    <StyledUpdateEmailLink
                      label={t('warningLink')}
                      to="/my-board/profile"
                    />
                  </WarningLabel>
                  <StyledClose onClick={() => setIsWarningOpen(false)} />
                </WarningContainer>
              )}
              <StyledSearch
                placeholder={t('searchPlaceholder')}
                value={searchFilter}
                onChangeText={(text: string) => {
                  setSearchFilter(text);
                }}
                backgroundIcon={searchImg}
              />
              <FilterWrapper>
                <MyBoardFilter
                  selected={boardFilter}
                  activeItem="personal"
                  teamName={getTeamName()}
                  onChangeFilter={selected => setBoardFilter(selected)}
                />
                <MyBoardSort
                  selectedSort={sortFilter}
                  layoutFilter={layoutFilter}
                  onChangeLayout={layoutFilter =>
                    setLayoutFilter(layoutFilter as 'card' | 'list')
                  }
                  onChangeSort={selected => setSortFilter(selected as SortType)}
                />
              </FilterWrapper>
            </FilterContainer>
            <LayoutContainer>
              {layoutFilter === 'card' ? (
                <CardLayout
                  boards={boards}
                  isLoading={isLoadingBoards}
                  onPressAddNewBoard={onPressNewChannel}
                  onPressBoardCard={onPressBoardCard}
                  onPressFavorite={handlePressFavorite}
                />
              ) : (
                <BoardListLayout
                  boards={boards}
                  isLoading={isLoadingBoards}
                  onPressAddNewBoard={onPressNewChannel}
                  onPressBoardCard={onPressBoardCard}
                  onPressFavorite={handlePressFavorite}
                />
              )}
            </LayoutContainer>
          </>
        );
    }
  };

  return (
    <Container className={className}>
      <MyBoardMenu
        teams={teamList}
        activeItem={activeItem}
        onPressAddTeam={onOpenAddTeamModal}
        onPressNewChannel={onPressNewChannel}
      />
      <ContentWrapper>{renderContent()}</ContentWrapper>
      <AddTeamModal
        isOpen={isAddTeamModalOpen}
        isCreatingTeam={isCreatingTeam}
        onClose={onCloseAddTeamModal}
        onPressAdd={handleAddTeam}
      />
      <MyBoardMenuMobile
        activeItem={activeItem}
        onPressAddTeam={onOpenAddTeamModal}
        onPressNewChannel={onPressNewChannel}
      />
      {notification ? <Toast {...notification} /> : null}
    </Container>
  );
};

export default Component;
