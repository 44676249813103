import { Board } from '../../../domain/entities/board';
import BoardRepository from '../../ports/BoardRepository';

export type fetchBoardByCodeUseCase = (
  boardCode: string,
) => Promise<Board | undefined>;

export const buildFetchBoardByCode = (dependencies: {
  boardRepo: BoardRepository;
}): fetchBoardByCodeUseCase => {
  const { boardRepo } = dependencies;

  const fetchBoardByCode: fetchBoardByCodeUseCase = async boardCode =>
    boardRepo.fetchBoardByCode(boardCode);

  return fetchBoardByCode;
};
