import React, { useState } from 'react';
import styled from 'styled-components';
import theme from '../../../constants/themes';
import { useTranslation } from 'react-i18next';
import { RightArrow } from '../../atoms/Icons';
import { Button } from '../../atoms/Button';
import createDOMPurify from 'dompurify';
import { Skeleton } from '@mui/material';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import InlineEditor from '@itsmitchyyy/ckeditor5-build-inline';
import Logo from '../../../assets/images/Ideaman_logo_white.png';
import { useNavigate } from 'react-router';
import { useGlobalState } from '../../../hooks/global';

const Container = styled.div`
  display: flex;
  padding: 1em;
  border-bottom: 1px solid rgba(4, 4, 4, 0.09);
  margin-bottom: 1em;
  background: ${theme.colors.primary};

  @media ${theme.breakpoints.tablet} {
    display: none;
  }
  @media ${theme.breakpoints.mobile} {
    display: none;
  }
`;

const StyledLogo = styled.img``;

const LinkContainer = styled.div<{ hasError?: boolean }>`
  .ck.ck-editor__editable.ck-rounded-corners:not(.ck-editor__nested-editable) {
    ${({ hasError }) =>
      hasError ? `border: 1px solid ${theme.colors.errorToastText}` : ''};
  }
`;

const LoginSignupWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  gap: 1em;
  align-items: center;
`;

const LoginText = styled.span`
  font-family: ${theme.fonts.primary};
  font-weight: 700;
  font-size: 14px;
  line-height: 28px;
  color: ${theme.colors.notifToastBorder};
  cursor: pointer;
  margin-right: 45px;
  white-space: nowrap;
  padding-bottom: 2px;
  &:hover {
    border-bottom: 2px solid ${theme.colors.notifToastBorder};
    padding-bottom: 0px;
  }
`;

const StyledSignUpBtn = styled(Button)`
  width: 179px;
  height: 58px;
`;

const StyledMyBoardBtn = styled(Button)`
  border: 1px solid ${theme.colors.white};
  background-color: transparent;
  width: 179px;
  height: 58px;
`;

const LinksWrapper = styled.div`
  display: flex;
  flex: 1 1 100%;
  gap: 2em;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.2px;

  div {
    a {
      text-decoration: none;
      color: #737373;

      span {
        display: none;
        height: 2px;
        width: 100%;
      }
    }

    &.active {
      a {
        color: #60bf91;
      }

      span {
        display: flex;
        background: #60bf91;
      }
    }
  }
`;

const LogoContainer = styled.div`
  display: flex;
  flex: 1 1 35%;
  align-items: center;
  cursor: pointer;
`;

const ErrorWrapper = styled.div`
  color: ${theme.colors.errorToastText};
`;

const DOMPurify = createDOMPurify(window as any);

export type Props = {
  links: any[];
  onPressMemberLogin: () => void;
  onPressRegistration: () => void;
  isFetching?: boolean;
  allowEditContent?: boolean;
  onChangeData?: (links: any[]) => void;
  serverErrors?: any;
};

const Component = ({
  links,
  onPressMemberLogin,
  onPressRegistration,
  isFetching,
  allowEditContent,
  onChangeData,
  serverErrors,
}: Props): React.ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [active, setActive] = useState({ index: 0, active: true });
  const { useCurrentUser } = useGlobalState();
  const { currentUser } = useCurrentUser;

  return (
    <Container>
      <LogoContainer onClick={() => navigate('/')}>
        <StyledLogo src={Logo} />
      </LogoContainer>

      <LinksWrapper>
        {isFetching ? (
          <>
            <Skeleton variant="rectangular" height={20} width={90} />
            <Skeleton variant="rectangular" height={20} width={90} />
            <Skeleton variant="rectangular" height={20} width={90} />
          </>
        ) : (
          <>
            {links?.map((link, index) => {
              return allowEditContent ? (
                <LinkContainer
                  hasError={
                    serverErrors && !!serverErrors?.[`header.${index}`]
                  }>
                  <CKEditor
                    key={index}
                    editor={InlineEditor}
                    config={{
                      extraAllowedContent: 'div(*)',
                      allowedContent: true,
                      toolbar: {
                        items: [
                          'Heading',
                          'Bold',
                          'Italic',
                          'Link',
                          'Undo',
                          'Redo',
                        ],
                      },
                    }}
                    data={link}
                    onChange={(event: any, editor: any) => {
                      const data = editor.getData();

                      const newData = [...links];
                      newData[index] = data;

                      if (onChangeData) {
                        onChangeData(newData);
                      }
                    }}
                  />
                  {serverErrors && !!serverErrors?.[`header.${index}`] && (
                    <ErrorWrapper style={{ width: '8em' }}>
                      <span>{serverErrors?.[`header.${index}`]}</span>
                    </ErrorWrapper>
                  )}
                </LinkContainer>
              ) : (
                <div
                  className={`${
                    index === active.index && active.active ? 'active' : ''
                  }`}
                  onClick={() => setActive({ index, active: true })}
                  key={index}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(link),
                  }}
                />
              );
            })}
          </>
        )}
      </LinksWrapper>
      {currentUser?.user.isVerified ? (
        <LoginSignupWrapper>
          <StyledMyBoardBtn
            title="マイボードへ"
            theme="lightSecondary"
            icon={<RightArrow />}
            iconPosition="right"
            onPress={() => {
              if (!allowEditContent) navigate('/my-board');
            }}
          />
        </LoginSignupWrapper>
      ) : (
        <LoginSignupWrapper>
          <LoginText onClick={onPressMemberLogin}>{t('topLogin')}</LoginText>
          <StyledSignUpBtn
            title={t('topSignup')}
            theme="lightSecondary"
            icon={<RightArrow />}
            iconPosition="right"
            onPress={onPressRegistration}
          />
        </LoginSignupWrapper>
      )}
    </Container>
  );
};

export default Component;
