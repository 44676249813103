import BoardRepository from '../../ports/BoardRepository';
import { GenericResponse } from '../../../domain/entities/rest';

export type removeCustomerUseCase = (
  boardCode: string,
  customerId: string,
) => Promise<GenericResponse>;

export const buildRemoveCustomer = (dependencies: {
  boardRepo: BoardRepository;
}): removeCustomerUseCase => {
  const { boardRepo } = dependencies;

  const removeCustomer: removeCustomerUseCase = (boardId, customerId) =>
    boardRepo.removeCustomer(boardId, customerId);

  return removeCustomer;
};
