import React from 'react';
import styled from 'styled-components';
import theme from '../../../constants/themes';
import {
  BannerContent,
  FeatureContent,
  CarouselContent as CarouselContentType,
  HowToUseContent,
  FaqContent,
} from '../TopBody/types';
import createDOMPurify from 'dompurify';
import { Button } from '../../atoms/Button';
import { RightArrow } from '../../atoms/Icons';
import { useTranslation } from 'react-i18next';
import {
  MobileCarousel,
  MobileFaq,
  MobileFeature,
  MobileHowToUse,
  MobilePrice,
} from '.';
import { Plan } from '../../../domain/entities/plan';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;

  @media ${theme.breakpoints.pc} {
    display: none;
  }
`;

const HomePageContainer = styled.div<{ bgImage: string }>`
  background-image: url(${({ bgImage }) => bgImage});
  min-height: 380px;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  color: ${theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: inset 0 0 0 2000px rgba(172, 172, 172, 0.35);
  flex-direction: column;
  padding-bottom: 3em;
`;

const HomePageDescription = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${theme.colors.white};

  h1,
  h2,
  h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 29px;
    text-align: center;
    letter-spacing: -0.02em;
    white-space: pre-wrap;
  }

  img {
    width: 300px;
  }
`;

const StyledRightArrow = styled(RightArrow)`
  margin-left: 15px;
  width: 30px;
  height: 25px;
`;

const StyledSignUpBtn = styled(Button)`
  width: 180px;
  height: 60px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 0.2px;
  padding: 1em;
`;

const VideoContainer = styled.div`
  height: 350px;
`;

const StyledVideo = styled.video`
  width: 100%;
  height: 100%;
`;

const StyledIframe = styled.iframe`
  width: 100%;
  height: 100%;
`;

const DOMPurify = createDOMPurify(window as any);

export type Props = {
  bannerContent: BannerContent;
  featureContent: FeatureContent;
  howToUseContent: HowToUseContent;
  carouselContent: CarouselContentType;
  faqContent: FaqContent;
  planContent: Plan[] | undefined;
  isFetching?: boolean;
  onPressRegistration: () => void;
  onPressPlan: (plan: string) => void;
};

const Component = ({
  isFetching,
  bannerContent,
  featureContent,
  howToUseContent,
  carouselContent,
  faqContent,
  planContent,
  onPressPlan,
  onPressRegistration,
}: Props): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <Container>
      {isFetching ? null : (
        <>
          <HomePageContainer bgImage={bannerContent?.image}>
            <HomePageDescription
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(bannerContent?.description),
              }}
            />
            <StyledSignUpBtn
              title={t('topSignup')}
              theme="lightSecondary"
              icon={<StyledRightArrow />}
              iconPosition="right"
              onPress={onPressRegistration}
            />
          </HomePageContainer>
          <VideoContainer>
            {bannerContent?.video?.includes('.mp4') ? (
              <StyledVideo src={bannerContent?.video} controls>
                Your browser does not support the video tag.
              </StyledVideo>
            ) : (
              <StyledIframe src={bannerContent?.video} />
            )}
          </VideoContainer>
          <MobileFeature
            featureContent={featureContent}
            isFetching={isFetching}
          />
          <MobileHowToUse
            howToUseContent={howToUseContent}
            isFetching={isFetching}
          />
          <MobilePrice
            planContent={planContent}
            onSelectPlan={onPressPlan}
            isFetching={isFetching}
          />
          <MobileCarousel
            carouselContent={carouselContent}
            isFetching={isFetching}
          />
          <MobileFaq faqContent={faqContent} isFetching={isFetching} />
        </>
      )}
    </Container>
  );
};

export default Component;
