import ChatRepository from '../../usecases/ports/ChatRepository';
import { HttpAdapter } from '../../usecases/ports/HttpAdapter';
import { Messages } from '../../domain/entities/chat';

export default class ChatRepositoryImpl implements ChatRepository {
  httpAdapter: HttpAdapter;
  urls: { [key: string]: string };

  constructor(httpAdapter: HttpAdapter, urls: { [key: string]: string }) {
    this.httpAdapter = httpAdapter;
    this.urls = urls;
  }

  fetchMessagesOnBoard = async (
    boardCode: string,
  ): Promise<Messages | undefined> => {
    const response = await this.httpAdapter.get(this.urls.getMessages, {
      params: { board_code: boardCode },
    });
    const data = response.data.data;

    if (data) {
      const messages = data?.messages || [];

      const mappedMessages = messages.map(
        (item: {
          id: string;
          room_id: string;
          board_code: string;
          mention_id: string;
          content: string;
          sent_at: string;
          customer: {
            id: string;
            avatar: string;
            role_name: string;
            fullname: string;
          };
        }) => {
          const customer = {
            id: item.customer.id,
            fullName: item.customer.fullname,
            avatar: item.customer.avatar,
            role: item.customer.role_name,
          };
          return {
            id: item.id,
            boardCode: item.board_code,
            roomId: item.room_id,
            customer,
            mentionId: item.mention_id,
            content: item.content,
            sentAt: item.sent_at,
          };
        },
      );

      return {
        id: data.id,
        boardId: data.board_id,
        boardCode: data.board_code,
        roomName: data.room_name,
        createdAt: data.created_at,
        createdBy: data.created_by,
        updatedAt: data.updated_at,
        updatedBy: data.updated_by,
        messages: mappedMessages,
      };
    }
    return undefined;
  };
}
