import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import theme from '../../../constants/themes';
import shareImg from '../../../assets/images/share.png';
import { useTranslation } from 'react-i18next';
import { Share } from '../../atoms/Icons';
import { InputWithIcon } from '../../atoms/InputWithIcon';
import { Button } from '../../atoms/Button';
import { Tooltip } from '../../atoms/Tooltip';
import {
  handleTextOverflow,
  validateEmailsFormat,
  validateEmailsLength,
} from '../../../utils/strings';
import { useGlobalState } from '../../../hooks/global';

const FormWrapper = styled.div`
  background: ${theme.colors.white};
  display: flex;
  flex-direction: column;
  width: 541px;
`;

const ParticipantLabel = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: ${theme.colors.textColor};
  margin-bottom: 11px;
  margin-top: 3px;
`;

const Participant = styled.span``;

const ParticipantDiv = styled.div`
  max-height: 240px;
  padding-right: 16px;
  overflow-y: auto;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;
`;

const ParticipantDetails = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;

  letter-spacing: -0.02em;
`;

const RoleLabel = styled.span`
  margin-right: 5px;
`;

const DeleteButton = styled.span`
  text-decoration-line: underline;
  color: ${theme.colors.textError};
  cursor: pointer;
`;

const StyledInput = styled(InputWithIcon)<{ haserror?: boolean }>`
  & {
    border: ${props =>
      props.haserror
        ? `thin solid ${theme.colors.textError}`
        : `thin solid ${theme.colors.addTeamMemberDropdownBorder}`};
    &:focus {
      outline: ${props =>
        props.haserror
          ? `thin solid ${theme.colors.textError}`
          : `thin solid ${theme.colors.addTeamMemberDropdownBorder}`};
    }
  }
  flex: 1;
  height: 50px;
  border-radius: 5px;
`;

const InputRow = styled.div`
  margin-bottom: 10px;
  display: flex;
`;

const ShareButton = styled(Button)`
  margin-left: 7px;
  height: 50px;
  width: 94px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: ${theme.colors.white};
  cursor: pointer;
  svg {
    margin-right: 5px;
  }
  :hover {
    filter: brightness(0.8);
  }
`;

const ErrorMsgWrapper = styled.div``;

const EmailErrorMessage = styled.span`
  flex: 1;
  font-family: ${theme.fonts.primary};
  font-weight: 400;
  font-size: 13px;
  line-height: 14px;
  color: ${theme.colors.textError};
  text-align: left;
`;

const EmailInstruction = styled.span`
  font-family: ${theme.fonts.primary};
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: ${theme.colors.emailInstruction};
  text-align: left;
`;

export type Props = {
  keyword: string;
  onChangeKeyWord: (keyword: string) => void;
  onClickDelete?: (customerId: string) => void;
  onClickShare: () => void;
  participants: { id: number; name: string; role: string; email?: string }[];
  disabled?: boolean;
  isSubmittingEmails?: boolean;
};

const Component = ({
  onChangeKeyWord,
  onClickDelete = () => {},
  keyword,
  participants,
  onClickShare,
  disabled,
  isSubmittingEmails,
}: Props): React.ReactElement => {
  const { t } = useTranslation();
  const {
    useCurrentUser: { currentUser },
  } = useGlobalState();
  const [hasInvalidEmail, setHasInvalidEmail] = useState(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState('');
  const [isEmptyEmail, setIsEmptyEmail] = useState(false);

  return (
    <FormWrapper>
      <InputRow>
        <StyledInput
          value={keyword}
          onChangeText={text => {
            if (text.length > 0) {
              setIsEmptyEmail(false);
              if (!validateEmailsFormat(text)) {
                setHasInvalidEmail(true);
                setEmailErrorMsg(t('invalidEmail'));
              } else if (!validateEmailsLength(text)) {
                setHasInvalidEmail(true);
                setEmailErrorMsg(t('maxLengthEmail'));
              } else {
                setHasInvalidEmail(false);
              }
            } else {
              setIsEmptyEmail(true);
              setHasInvalidEmail(false);
              setEmailErrorMsg('');
            }

            onChangeKeyWord(text);
          }}
          haserror={hasInvalidEmail || isEmptyEmail}
          placeholder="メールアドレス"
          onKeyDown={key => {
            if (key === 'Enter') {
              onClickShare();
            }
          }}
          backgroundIcon={shareImg}
        />
        <ShareButton
          onPress={onClickShare}
          disabled={disabled || hasInvalidEmail}
          title={t('send')}
          theme="lightSecondary"
          icon={<Share />}
          isLoading={isSubmittingEmails}
        />
      </InputRow>
      <ErrorMsgWrapper>
        <EmailInstruction>{t('emailInstruction')}</EmailInstruction>
      </ErrorMsgWrapper>
      {isEmptyEmail && (
        <ErrorMsgWrapper>
          <EmailErrorMessage>{t('requiredEmail')}</EmailErrorMessage>
        </ErrorMsgWrapper>
      )}
      {hasInvalidEmail && (
        <ErrorMsgWrapper>
          <EmailErrorMessage>{emailErrorMsg}</EmailErrorMessage>
        </ErrorMsgWrapper>
      )}
      <ParticipantLabel>{t('participant')}</ParticipantLabel>
      <ParticipantDiv>
        {participants.map(({ id, role, name }, index) => {
          const overflowName =
            name.length <= 15 ? name : handleTextOverflow(name, 15);
          const selfName =
            currentUser?.user.customer.id === id
              ? `${overflowName}(自分)`
              : overflowName;
          return (
            <Row key={`participant-${role}-${name}-${index}`}>
              <Tooltip position="right" label={name}>
                <Participant>{selfName}</Participant>
              </Tooltip>
              <ParticipantDetails>
                <RoleLabel>{role}</RoleLabel>
                {currentUser?.user.customer.id === id ? (
                  <span style={{ visibility: 'hidden' }}>{t('delete')}</span>
                ) : (
                  <DeleteButton
                    onClick={() => {
                      onClickDelete(String(id));
                    }}>
                    {t('delete')}
                  </DeleteButton>
                )}
              </ParticipantDetails>
            </Row>
          );
        })}
      </ParticipantDiv>
    </FormWrapper>
  );
};

export default Component;
