import React from 'react';
import { AxiosResponse } from 'axios';
import styled from 'styled-components';
import theme from '../../../constants/themes';
import queryKeys from '../../../constants/queryKeys';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useRoleHooks } from '../../../hooks/role';
import { useBoardHooks } from '../../../hooks/board/';
import { useGlobalState } from '../../../hooks/global';
import { Props as INotification } from '../../atoms/Toast/Component';
import { RoleSelectLayout } from '../../organisms/RoleLayout';
import { UpdateRoleSuccess } from '../../../domain/entities/role';

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background-color: ${theme.colors.roleSelectBg};
  position: absolute;
  top: 0;
  left: 0;
`;

export type Props = {
  boardCode: string;
  onPressRole: () => void;
  handleSelectRole: (toast: INotification) => void;
};

const Component = ({
  boardCode,
  onPressRole,
  handleSelectRole,
}: Props): React.ReactElement => {
  const queryClient = useQueryClient();
  const { useRole } = useRoleHooks();
  const { fetchRoles } = useRole();
  const { useBoardAPI } = useBoardHooks();
  const { updateCustomerRole } = useBoardAPI();
  const {
    useCurrentUser: { currentUser },
  } = useGlobalState();
  const customerId = currentUser?.user?.customer?.id;

  const { data: roleList = [], isFetching } = useQuery(
    [queryKeys.FETCH_ROLE_LIST],
    () => {
      return fetchRoles();
    },
  );

  const { mutate: updateRoleMutation, isLoading: isUpdatingRole } = useMutation(
    (roleId: number) => {
      return updateCustomerRole(boardCode, roleId);
    },
    {
      onSuccess: (response: UpdateRoleSuccess) => {
        handleSelectRole({
          isOpen: true,
          message: response.message,
          type: 'success',
          position: 'top-right',
          onClose: () => {},
        });
        queryClient.invalidateQueries([
          queryKeys.FETCH_BOARD_CUSTOMER,
          boardCode,
          customerId,
        ]);
        onPressRole();
      },
      onError: (response: { response: AxiosResponse }) => {
        const res = response.response;
        const data = res.data as any;
        let message = '';
        if (data.status_code === 422) {
          let messages = '';
          Object.keys(data.errors).forEach(key => {
            messages += data.errors[key][0];
          });
          message = messages;
        } else {
          message = data.errors.messages;
        }
        handleSelectRole({
          isOpen: true,
          message: message,
          type: 'error',
          position: 'top-right',
          onClose: () => {},
        });
        onPressRole();
      },
    },
  );

  const handleOnSelectRole = (roleId: number) => {
    updateRoleMutation(roleId);
  };

  return (
    <Container
      onClick={event => {
        event.stopPropagation();
      }}>
      <RoleSelectLayout
        roles={roleList}
        isLoading={isFetching}
        isUpdatingRole={isUpdatingRole}
        onPressRole={handleOnSelectRole}
      />
    </Container>
  );
};

export default Component;
