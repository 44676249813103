import { ChoosePlan } from '../../../domain/entities/payment';
import PaymentRepository from '../../ports/PaymentRepository';

export type choosePlanUseCase = (
  planId: number,
  paymentMethodId: string,
) => Promise<ChoosePlan>;

export const buildChoosePlan = (dependencies: {
  paymentRepo: PaymentRepository;
}): choosePlanUseCase => {
  const { paymentRepo } = dependencies;

  const choosePlan: choosePlanUseCase = async (planId, paymentMethodId) =>
    paymentRepo.choosePlan(planId, paymentMethodId);

  return choosePlan;
};
