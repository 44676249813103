import { GenericResponse } from '../../../domain/entities/rest';
import AuthService from '../../ports/AuthService';

export type resetPasswordUseCase = (
  token: string,
  password: string,
  passwordConfirmation: string,
) => Promise<GenericResponse>;

export const buildResetPassword = (dependencies: {
  authService: AuthService;
}): resetPasswordUseCase => {
  const { authService } = dependencies;

  const resetPassword: resetPasswordUseCase = (
    token,
    password,
    passwordConfirmation,
  ) => authService.resetPassword(token, password, passwordConfirmation);

  return resetPassword;
};
