import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import activeInvoiceTab from '../../../assets/images/active-invoice-tab.svg';
import activeProfileTab from '../../../assets/images/active-profile-tab.svg';
import invoiceTab from '../../../assets/images/invoice-tab.svg';
import profileTab from '../../../assets/images/profile-tab.svg';
import theme from '../../../constants/themes';
import { AccordionArrow, ActiveHome, Home } from '../../atoms/Icons';

const Container = styled.div`
  display: none;
  position: fixed !important;
  bottom: 0px;
  width: 100%;
  height: 58px;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  z-index: 998;
  background-color: ${theme.colors.myboardMenuBg};

  @media ${theme.breakpoints.mobile} {
    display: flex;
  }

  @media ${theme.breakpoints.tablet} {
    display: flex;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const MenuWrapper = styled.div`
  flex: 0 0 33.3333333333%;
  display: flex;
  height: 100%;
  align-items: center;
  background-color: inherit;
  cursor: pointer;
  justify-content: center;

  &:hover {
    background-color: ${theme.colors.myBoardMenuBgHover};
  }
`;

const MenuLabel = styled.div<{
  item: 'home' | 'personal' | 'team' | 'profile' | 'plan-billing';
  activeItem: 'home' | 'personal' | 'team' | 'profile' | 'plan-billing';
}>`
  font-family: ${theme.fonts.primary};
  font-weight: 600;
  font-size: 14px;
  margin-top: 3px;
  color: ${props =>
    props.item === props.activeItem
      ? theme.colors.notifToastBorder
      : theme.colors.textColor};
  justify-content: center;
  display: flex;
`;

const StyledAccordionArrow = styled(AccordionArrow)`
  position: absolute;
  right: 20px;
  align-self: center;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const MenuItemWrapper = styled.div`
  display: block;
`;

export type Props = {
  activeItem: 'home' | 'personal' | 'team' | 'profile' | 'plan-billing';
  className?: string;
  onPressNewChannel: () => void;
  onPressAddTeam: () => void;
};

const Component = ({ activeItem, className }: Props): React.ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const renderHomeIcon = () => {
    switch (activeItem) {
      case 'home':
        return <ActiveHome />;
      default:
        return <Home />;
    }
  };

  const renderPaymentIcon = () => {
    switch (activeItem) {
      case 'plan-billing':
        return <img src={activeInvoiceTab} />;
      default:
        return <img src={invoiceTab} />;
    }
  };

  const renderProfileIcon = () => {
    switch (activeItem) {
      case 'profile':
        return <img src={activeProfileTab} />;
      default:
        return <img src={profileTab} />;
    }
  };

  return (
    <Container className={className}>
      <ContentWrapper>
        <MenuWrapper onClick={() => navigate('/my-board/personal')}>
          <MenuItemWrapper>
            <IconWrapper>{renderHomeIcon()}</IconWrapper>
            <MenuLabel item="home" activeItem={activeItem}>
              {t('home')}
            </MenuLabel>
          </MenuItemWrapper>
        </MenuWrapper>
        <MenuWrapper onClick={() => navigate('/my-board/plan-billing')}>
          <MenuItemWrapper>
            <IconWrapper>{renderPaymentIcon()}</IconWrapper>
            <MenuLabel item="plan-billing" activeItem={activeItem}>
              {t('invoice')}
            </MenuLabel>
          </MenuItemWrapper>
        </MenuWrapper>
        <MenuWrapper onClick={() => navigate('/my-board/profile')}>
          <MenuItemWrapper>
            <IconWrapper>{renderProfileIcon()}</IconWrapper>
            <MenuLabel item="profile" activeItem={activeItem}>
              {t('profile')}
            </MenuLabel>
          </MenuItemWrapper>
        </MenuWrapper>
      </ContentWrapper>
    </Container>
  );
};

export default Component;
