import BoardRepository from '../../ports/BoardRepository';

export type updateBoardUseCase = (payload: {
  name?: string;
  xmlString?: string;
  boardCode: string;
}) => Promise<void>;

export const buildUpdateBoard = (dependencies: {
  boardRepo: BoardRepository;
}): updateBoardUseCase => {
  const { boardRepo } = dependencies;

  const updateBoard: updateBoardUseCase = async payload =>
    boardRepo.updateBoard(payload);

  return updateBoard;
};
