import {
  ContentBlock,
  ContentState,
  convertToRaw,
  DraftInlineStyle,
  EditorState,
  Modifier,
  RawDraftContentState,
  RichUtils,
  SelectionState,
} from 'draft-js';
import React, { CSSProperties, useCallback, useMemo } from 'react';
import { PropertiesPane } from '.';
import { removeUndefined } from '../../../utils/objects';

type Props = {
  color?: string;
  strokeColor?: string;
  strokeWidth?: number;
  borderWidth?: number;
  editorState: EditorState;
  setEditorState: React.Dispatch<React.SetStateAction<EditorState>>;
  style?: CSSProperties;
  onChangeColor?: (color: string) => void;
  onChangeStrokeColor?: (color: string) => void;
  onTextRealign?: (align: 'left' | 'center' | 'right') => void;
  onChangeStrokeWidth?: (width: number) => void;
  onChangeBorderWidth?: (width: number) => void;
  onClickFlip?: () => void;
  onClickEmoji?: () => void;
  onClickLink?: () => void;
  onClickCopy?: () => void;
  onClickPaste?: () => void;
  onClickDelete?: () => void;
  onChangeLabel?: (value: string) => void;
  isEmojiOpen?: boolean;
  isLinkOpen?: boolean;
};

export const CUSTOM_STYLE_PREFIX_COLOR = 'color-';
export const CUSTOM_STYLE_PREFIX_FONT_FAMILY = 'fontfamily-';
export const CUSTOM_STYLE_PREFIX_FONT_SIZE = 'fontsize-';

export const customStyleFn = (style: DraftInlineStyle): CSSProperties => {
  const styleNames = style.toJS();
  return styleNames.reduce((styles: CSSProperties, styleName: string) => {
    if (styleName.startsWith(CUSTOM_STYLE_PREFIX_COLOR)) {
      styles.color = styleName.split(CUSTOM_STYLE_PREFIX_COLOR)[1];
    }
    if (styleName.startsWith(CUSTOM_STYLE_PREFIX_FONT_FAMILY)) {
      styles.fontFamily = styleName.split(CUSTOM_STYLE_PREFIX_FONT_FAMILY)[1];
    }
    if (styleName.startsWith(CUSTOM_STYLE_PREFIX_FONT_SIZE)) {
      const fontSizeStr = styleName.split(CUSTOM_STYLE_PREFIX_FONT_SIZE)[1];
      styles.fontSize = isNaN(Number(fontSizeStr))
        ? 'inherit'
        : Number(fontSizeStr);
    }
    return styles;
  }, {});
};

export const CUSTOM_BLOCK_PREFIX_ALIGN = 'ALIGN_';

export const customBlockFn = (block: ContentBlock): string => {
  const type = block.getType();
  const blockStyles: string[] = [];

  if (type === 'unordered-list-item') {
    block.findStyleRanges(
      item => item.getStyle().toJS(),
      char => {
        if (char === 0) {
          block
            .getInlineStyleAt(char)
            .toArray()
            .forEach(styleKey => {
              blockStyles.push(styleKey);
            });
        }
      },
    );

    return blockStyles.join(' ');
  }

  if (type.includes(CUSTOM_BLOCK_PREFIX_ALIGN)) {
    return type.split(CUSTOM_BLOCK_PREFIX_ALIGN)[1];
  }
  return '';
};

// NOTE: need to re map the raw object because of this issue https://github.com/facebook/draft-js/issues/3150
const prepareRawForSaving = (
  newEditorState: EditorState,
  contentState: ContentState,
): RawDraftContentState => {
  const raw = convertToRaw(newEditorState.getCurrentContent());
  const newInlineStyleBlockMap: {
    [blockKey: string]: { characterList: { style: string[] }[] };
  } = contentState.toJS().blockMap || {};
  const transformedRaw: typeof raw = {
    ...raw,
    blocks: raw.blocks.map(currentBlock => {
      const { key } = currentBlock;
      const currentBlockMap = newInlineStyleBlockMap[key];
      const currentCharacterList = currentBlockMap?.characterList || [];
      const characterListStyles = currentCharacterList.reduce(
        (
          acc: {
            fontFamily?: string;
            fontSize?: string;
            fontColor?: string;
            isBold?: boolean;
            isItalic?: boolean;
            isUnderlined?: boolean;
            isStriked?: boolean;
          }[],
          currentCharacter: { style: string[] },
        ) => {
          const reversedStyle = [...currentCharacter.style].reverse();
          const fontFamily = reversedStyle.find(style =>
            style.startsWith(CUSTOM_STYLE_PREFIX_FONT_FAMILY),
          );
          const fontSize = reversedStyle.find(style =>
            style.startsWith(CUSTOM_STYLE_PREFIX_FONT_SIZE),
          );
          const fontColor = reversedStyle.find(style =>
            style.startsWith(CUSTOM_STYLE_PREFIX_COLOR),
          );
          const isBold = currentCharacter.style.includes('BOLD');
          const isItalic = currentCharacter.style.includes('ITALIC');
          const isUnderlined = currentCharacter.style.includes('UNDERLINE');
          const isStriked = currentCharacter.style.includes('STRIKETHROUGH');
          const retVal: {
            fontFamily?: string;
            fontSize?: string;
            fontColor?: string;
            isBold?: boolean;
            isItalic?: boolean;
            isUnderlined?: boolean;
            isStriked?: boolean;
          }[] = [
            ...acc,
            removeUndefined({
              fontFamily,
              fontSize,
              fontColor,
              isBold,
              isItalic,
              isUnderlined,
              isStriked,
            }),
          ];
          return retVal;
        },
        [],
      );
      const newInlineStyleRanges = characterListStyles.reduce(
        (
          acc: { offset: number; length: number; style: any }[],
          cur: {
            fontFamily?: string;
            fontSize?: string;
            fontColor?: string;
            isBold?: boolean;
            isItalic?: boolean;
            isUnderlined?: boolean;
            isStriked?: boolean;
          },
          index: number,
        ) => {
          const toAdd: {
            offset: number;
            length: number;
            style: string;
          }[] = [];
          const shouldAddFontFamily =
            cur.fontFamily !== undefined &&
            characterListStyles[index - 1]?.fontFamily !== cur.fontFamily;
          if (cur.fontFamily !== undefined && shouldAddFontFamily) {
            const endAtIndex = characterListStyles.findIndex((q, _index) => {
              if (_index === characterListStyles.length - 1) {
                return true;
              }
              if (_index <= index) {
                return false;
              }
              return q.fontFamily !== cur.fontFamily;
            });

            toAdd.push({
              style: cur.fontFamily,
              offset: index,
              length: endAtIndex - index + 1,
            });
          }

          const shouldAddFontSize =
            cur.fontSize !== undefined &&
            characterListStyles[index - 1]?.fontSize !== cur.fontSize;
          if (cur.fontSize !== undefined && shouldAddFontSize) {
            const endAtIndex = characterListStyles.findIndex((q, _index) => {
              if (_index === characterListStyles.length - 1) {
                return true;
              }
              if (_index <= index) {
                return false;
              }
              return q.fontSize !== cur.fontSize;
            });

            toAdd.push({
              style: cur.fontSize,
              offset: index,
              length: endAtIndex - index + 1,
            });
          }

          const shouldAddFontColor =
            cur.fontColor !== undefined &&
            characterListStyles[index - 1]?.fontColor !== cur.fontColor;
          if (cur.fontColor !== undefined && shouldAddFontColor) {
            const endAtIndex = characterListStyles.findIndex((q, _index) => {
              if (_index === characterListStyles.length - 1) {
                return true;
              }
              if (_index <= index) {
                return false;
              }
              return q.fontColor !== cur.fontColor;
            });
            toAdd.push({
              style: cur.fontColor,
              offset: index,
              length: endAtIndex - index + 1,
            });
          }

          if (cur.isBold) {
            const endAtIndex = characterListStyles.findIndex((q, _index) => {
              if (_index === characterListStyles.length - 1) {
                return true;
              }
              if (_index <= index) {
                return false;
              }
              return !q.isBold;
            });
            toAdd.push({
              style: 'BOLD',
              offset: index,
              length: endAtIndex - index + 1,
            });
          }
          if (cur.isUnderlined) {
            const endAtIndex = characterListStyles.findIndex((q, _index) => {
              if (_index === characterListStyles.length - 1) {
                return true;
              }
              if (_index <= index) {
                return false;
              }
              return !q.isUnderlined;
            });
            toAdd.push({
              style: 'UNDERLINE',
              offset: index,
              length: endAtIndex - index + 1,
            });
          }
          if (cur.isItalic) {
            const endAtIndex = characterListStyles.findIndex((q, _index) => {
              if (_index === characterListStyles.length - 1) {
                return true;
              }
              if (_index <= index) {
                return false;
              }
              return !q.isItalic;
            });
            toAdd.push({
              style: 'ITALIC',
              offset: index,
              length: endAtIndex - index + 1,
            });
          }
          if (cur.isStriked) {
            const endAtIndex = characterListStyles.findIndex((q, _index) => {
              if (_index === characterListStyles.length - 1) {
                return true;
              }
              if (_index <= index) {
                return false;
              }
              return !q.isStriked;
            });
            toAdd.push({
              style: 'STRIKETHROUGH',
              offset: index,
              length: endAtIndex - index + 1,
            });
          }
          return [...acc, ...toAdd];
        },
        [],
      );
      return { ...currentBlock, inlineStyleRanges: newInlineStyleRanges };
    }),
  };
  return transformedRaw;
};

const Component = ({
  editorState,
  setEditorState,
  onChangeColor,
  onChangeStrokeColor,
  onClickEmoji,
  onClickLink,
  onChangeStrokeWidth,
  onChangeBorderWidth,
  onClickFlip,
  style,
  color,
  strokeColor,
  strokeWidth,
  borderWidth,
  onClickCopy,
  onClickPaste,
  onClickDelete,
  onChangeLabel = () => {},
  isEmojiOpen,
  isLinkOpen,
}: Props): React.ReactElement => {
  const styles: CSSProperties = { position: 'absolute', top: -70, ...style };
  const toggleBlockType = useCallback(
    (blockType: string) => {
      const newEditorState = RichUtils.toggleBlockType(editorState, blockType);
      const transformedRaw = prepareRawForSaving(
        newEditorState,
        newEditorState.getCurrentContent(),
      );
      setEditorState(newEditorState);
      onChangeLabel(JSON.stringify(transformedRaw));
    },
    [editorState],
  );

  const toggleInlineStyle = (inlineStyle: string) => {
    const selection = editorState.getSelection();
    if (selection.isCollapsed()) {
      const currentContent = editorState.getCurrentContent();
      const firstBlock = currentContent.getBlockMap().first();
      const lastBlock = currentContent.getBlockMap().last();
      const firstBlockKey = firstBlock.getKey();
      const lastBlockKey = lastBlock.getKey();
      const lengthOfLastBlock = lastBlock.getLength();

      const newSelection = new SelectionState({
        anchorKey: firstBlockKey,
        anchorOffset: 0,
        focusKey: lastBlockKey,
        focusOffset: lengthOfLastBlock,
      });
      const removedInlineStyle = Modifier.removeInlineStyle(
        editorState.getCurrentContent(),
        newSelection,
        inlineStyle,
      );
      const newInlineStyle = Modifier.applyInlineStyle(
        removedInlineStyle,
        newSelection,
        inlineStyle,
      );
      const newEditorState = EditorState.push(
        EditorState.acceptSelection(editorState, newSelection),
        newInlineStyle,
        'change-inline-style',
      );
      const transformedRaw = prepareRawForSaving(
        newEditorState,
        newInlineStyle,
      );
      setEditorState(newEditorState);
      onChangeLabel(JSON.stringify(transformedRaw));
    } else {
      const newEditorState = RichUtils.toggleInlineStyle(
        editorState,
        inlineStyle,
      );
      const transformedRaw = prepareRawForSaving(
        newEditorState,
        newEditorState.getCurrentContent(),
      );
      setEditorState(newEditorState);
      onChangeLabel(JSON.stringify(transformedRaw));
    }
  };

  const applyInlineStyle = (prefix: string, styleValue: string) => {
    const inlineStyle = `${prefix}${styleValue}`;
    const selection = editorState.getSelection();
    if (selection.isCollapsed()) {
      const currentContent = editorState.getCurrentContent();
      const firstBlock = currentContent.getBlockMap().first();
      const lastBlock = currentContent.getBlockMap().last();
      const firstBlockKey = firstBlock.getKey();
      const lastBlockKey = lastBlock.getKey();
      const lengthOfLastBlock = lastBlock.getLength();

      const newSelection = new SelectionState({
        anchorKey: firstBlockKey,
        anchorOffset: 0,
        focusKey: lastBlockKey,
        focusOffset: lengthOfLastBlock,
      });
      const removedInlineStyle = Modifier.removeInlineStyle(
        editorState.getCurrentContent(),
        newSelection,
        inlineStyle,
      );
      const newInlineStyle = Modifier.applyInlineStyle(
        removedInlineStyle,
        newSelection,
        inlineStyle,
      );
      const newEditorState = EditorState.push(
        EditorState.acceptSelection(editorState, newSelection),
        newInlineStyle,
        'change-inline-style',
      );
      const transformedRaw = prepareRawForSaving(
        newEditorState,
        newInlineStyle,
      );
      setEditorState(newEditorState);
      onChangeLabel(JSON.stringify(transformedRaw));
    } else {
      const removedInlineStyle = Modifier.removeInlineStyle(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        inlineStyle,
      );
      const newInlineStyle = Modifier.applyInlineStyle(
        removedInlineStyle,
        editorState.getSelection(),
        inlineStyle,
      );
      const newEditorState = EditorState.push(
        editorState,
        newInlineStyle,
        'change-inline-style',
      );
      const transformedRaw = prepareRawForSaving(
        newEditorState,
        newInlineStyle,
      );
      setEditorState(newEditorState);
      onChangeLabel(JSON.stringify(transformedRaw));
    }
  };

  const currentFontColor = useMemo((): string | undefined => {
    const currentInlineStyle: string[] = editorState
      .getCurrentInlineStyle()
      .toJS();
    const colorInlineStyle = currentInlineStyle.filter(inlineStyle =>
      inlineStyle.startsWith(CUSTOM_STYLE_PREFIX_COLOR),
    );
    const last: string | undefined = colorInlineStyle.find(
      (colorStr, index) => index === colorInlineStyle.length - 1,
    );
    if (!last) {
      return undefined;
    }
    const [, color] = last?.split(CUSTOM_STYLE_PREFIX_COLOR);
    return color;
  }, [editorState]);

  const currentFontSize = useMemo((): 'inherit' | number | undefined => {
    const currentInlineStyle: string[] = editorState
      .getCurrentInlineStyle()
      .toJS();
    const sizeInlineStyles = currentInlineStyle.filter(inlineStyle =>
      inlineStyle.startsWith(CUSTOM_STYLE_PREFIX_FONT_SIZE),
    );
    const last: string | undefined = sizeInlineStyles.find(
      (sizeStr, index) => index === sizeInlineStyles.length - 1,
    );
    if (!last) {
      return undefined;
    }
    const [, size] = last?.split(CUSTOM_STYLE_PREFIX_FONT_SIZE);
    return size === 'inherit' ? 'inherit' : Number(size);
  }, [editorState]);

  const currentInlineStyle: string[] = editorState
    .getCurrentInlineStyle()
    .toJS();

  const currentBlock = useMemo((): string => {
    const currentSelection = editorState.getSelection();
    const blockKey = currentSelection.getStartKey();
    const test = editorState.getCurrentContent().getBlockForKey(blockKey);
    return test.getType();
  }, [editorState]);

  const currentFontFamily = useMemo((): string | undefined => {
    const currentFontItems = currentInlineStyle.filter(item =>
      item.includes(CUSTOM_STYLE_PREFIX_FONT_FAMILY),
    );
    return currentFontItems[currentFontItems.length - 1]?.replace(
      CUSTOM_STYLE_PREFIX_FONT_FAMILY,
      '',
    );
  }, [currentInlineStyle]);

  return (
    <>
      <PropertiesPane
        isEmoji={isEmojiOpen}
        isLink={isLinkOpen}
        style={styles}
        isBold={currentInlineStyle.includes('BOLD')}
        isItalicize={currentInlineStyle.includes('ITALIC')}
        isLineThrough={currentInlineStyle.includes('STRIKETHROUGH')}
        isUnderline={currentInlineStyle.includes('UNDERLINE')}
        onClickFlip={onClickFlip}
        color={color}
        strokeColor={strokeColor}
        strokeWidth={strokeWidth}
        borderWidth={borderWidth}
        fontColor={currentFontColor}
        fontSize={currentFontSize}
        fontFamily={currentFontFamily}
        textAlign={
          (currentBlock === `${CUSTOM_BLOCK_PREFIX_ALIGN}center` && 'center') ||
          (currentBlock === `${CUSTOM_BLOCK_PREFIX_ALIGN}left` && 'left') ||
          (currentBlock === `${CUSTOM_BLOCK_PREFIX_ALIGN}right` && 'right') ||
          undefined
        }
        onChangeColor={onChangeColor}
        onChangeStrokeColor={onChangeStrokeColor}
        onClickEmoji={onClickEmoji}
        onClickLink={onClickLink}
        onChangeStrokeWidth={onChangeStrokeWidth}
        onClickCopy={onClickCopy}
        onClickPaste={onClickPaste}
        onClickDelete={onClickDelete}
        onChangeBorderWidth={onChangeBorderWidth}
        onChangeFontFamily={fontFamily => {
          applyInlineStyle(CUSTOM_STYLE_PREFIX_FONT_FAMILY, fontFamily);
        }}
        onChangeFontColor={color => {
          applyInlineStyle(CUSTOM_STYLE_PREFIX_COLOR, color);
        }}
        onChangeFontSize={font => {
          applyInlineStyle(CUSTOM_STYLE_PREFIX_FONT_SIZE, `${font}`);
        }}
        onClickBold={ev => {
          ev.stopPropagation();
          toggleInlineStyle('BOLD');
        }}
        onClickUnderline={ev => {
          ev.stopPropagation();
          toggleInlineStyle('UNDERLINE');
        }}
        onClickItalicize={ev => {
          ev.stopPropagation();
          toggleInlineStyle('ITALIC');
        }}
        onClickLineThrough={ev => {
          ev.stopPropagation();
          toggleInlineStyle('STRIKETHROUGH');
        }}
        onBulletList={() => {
          toggleBlockType('unordered-list-item');
        }}
        onTextRealign={align => {
          toggleBlockType(`${CUSTOM_BLOCK_PREFIX_ALIGN}${align}`);
        }}
      />
    </>
  );
};

export default Component;
