import React from 'react';

type Props = {
  [key: string]: string | number | Function | object;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="23"
      height="24"
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M0.057085 14.2476L2.21885 9.25714C2.21885 9.25714 2.44697 7.27619 2.65337 6.51429C3.55501 3.10476 7.90026 0 12.6149 0C18.3506 0.00952381 23 4.09524 23 9.12381C23 11.9143 21.5335 14.5524 19.035 16.2762V24H7.95457V21.4095H6.32511C4.06558 21.4095 2.22971 19.8 2.22971 17.819V15.2667H0.850092C0.382978 15.2667 0.00276947 14.9333 0.00276947 14.5238C-0.00809479 14.4286 0.0136318 14.3429 0.057085 14.2476ZM12.6583 13.6667C15.8086 13.6667 18.3614 11.4286 18.3614 8.66667C18.3614 5.90476 15.8086 3.66667 12.6583 3.66667C9.508 3.66667 6.96603 5.90476 6.95517 8.66667C6.9443 11.419 9.48627 13.6571 12.6257 13.6667C12.6474 13.6667 12.6474 13.6667 12.6583 13.6667ZM12.1152 8.18095C12.8973 7.89524 13.2666 7.77143 13.2666 7.41905C13.2666 7.17143 12.9516 6.91429 12.3759 6.91429C11.9413 6.92381 11.5068 7.00952 11.1157 7.1619L10.8007 6.18095C11.257 5.98095 11.7675 5.88571 12.2781 5.89524V5.11429H13.0711V5.94286C14.1248 6.13333 14.7006 6.72381 14.7006 7.48571C14.7006 8.31429 14.0488 8.77143 13.0059 9.09524C12.2564 9.35238 11.9413 9.49524 11.9413 9.8381C11.9413 10.181 12.2564 10.4381 12.919 10.4381C13.4622 10.4286 13.9836 10.3048 14.4616 10.0952L14.744 11.1048C14.4073 11.2667 13.7229 11.4286 13.0494 11.4286V12.1905H12.2564V11.4095C11.1049 11.181 10.5617 10.5905 10.5617 9.8C10.5617 9.00952 11.0397 8.52381 12.1152 8.18095Z"
        fill="#FDCB09"
        fillOpacity="0.8"
      />
    </svg>
  );
};

export default Component;
