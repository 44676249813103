import theme from '../../../constants/themes';

const mentionsInputStyle = {
  control: {
    fontSize: 13,
    fontFamily: theme.fonts.secondary,
    fontWeight: 400,
    color: theme.colors.black,
    outline: 'none',
    width: 180,
    minHeight: 15,
  },
  highlighter: {
    minHeight: 15,
    paddingTop: 5,
    paddingBottom: 5,
    width: 180,
  },
  input: {
    fontSize: 13,
    fontFamily: theme.fonts.secondary,
    fontWeight: 400,
    color: theme.colors.black,
    minHeight: 15,
    border: 'none',
    paddingTop: 5,
    paddingBottom: 5,
    overflow: 'hidden',
  },
  suggestions: {
    zIndex: 1000,
    list: {
      backgroundColor: 'white',
      fontSize: 14,
      boxShadow: '1px 1px 6px rgba(0, 0, 0, 0.25)',
      maxHeight: 400,
      overflow: 'auto',
      position: 'absolute',
      bottom: 5,
      zIndex: 1000,
    },
    item: {
      padding: '5px 15px',
      '&focused': {
        backgroundColor: '#E5E5E5',
      },
      zIndex: 1000,
    },
  },
};

const mentionStyle = {};

export { mentionsInputStyle, mentionStyle };
