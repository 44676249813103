import React from 'react';
import styled from 'styled-components';
import theme from '../../../constants/themes';
import { FeatureContent } from '../TopBody/types';
import createDOMPurify from 'dompurify';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
const FeatureTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  letter-spacing: -0.02em;
  padding: 0.5em 0.2em;
`;

const FeatureCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

const FeatureCard = styled.div`
  box-shadow: 4px 4px 10px 4px rgb(0 0 0 / 5%);
  position: relative;
`;

const FeatureCardImage = styled.div<{ bgImage: string }>`
  background: ${({ bgImage }) => `url(${bgImage})`};
  background-size: cover;
  height: 330px;
  width: 100%;
`;

const FeatureCardContent = styled.div`
  padding: 0.5em 0.5em 0 0.5em;
`;

const CardContentHeader = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: ${theme.colors.text02};
`;

const CardContentDetails = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: ${theme.colors.text02};
`;

const FeatureCardFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 2em 0.5em 0.5em;

  a {
    color: ${theme.colors.notifToastBorder};
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: -0.02em;
    text-decoration: none;
  }
`;

const DOMPurify = createDOMPurify(window as any);

export type Props = {
  featureContent: FeatureContent;
  isFetching?: boolean;
};

const Component = ({
  isFetching,
  featureContent,
}: Props): React.ReactElement => {
  return (
    <Container>
      {isFetching ? null : (
        <>
          <FeatureTitle>{featureContent?.title}</FeatureTitle>
          <FeatureCardContainer>
            {featureContent?.cards?.map((content, index) => (
              <FeatureCard key={index}>
                <FeatureCardImage bgImage={content.image} />
                <FeatureCardContent>
                  <CardContentHeader
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(content.title),
                    }}
                  />
                  <CardContentDetails
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(content.description),
                    }}
                  />
                </FeatureCardContent>
                <FeatureCardFooter>
                  <div>
                    <a href={content.read_more}>さらに見る</a>
                  </div>
                </FeatureCardFooter>
              </FeatureCard>
            ))}
          </FeatureCardContainer>
        </>
      )}
    </Container>
  );
};

export default Component;
