import { ChoosePlan, PaymentHistoryData } from '../../domain/entities/payment';
import { createContext, useContext } from 'react';

export type PaymentHooks = {
  useChoosePlan: () => {
    choosePlan: (
      planId: number,
      paymentMethodId: string,
    ) => Promise<ChoosePlan>;
  };

  usePaymentHistory: () => {
    fetchPaymentHistory: (customerId: string) => Promise<PaymentHistoryData[]>;
    fetchInvoiceBlob: (invoiceCode: string) => Promise<void>;
  };
};

export const PaymentHooksContext = createContext<PaymentHooks | null>(null);

export const usePaymentHooks = (): PaymentHooks => {
  const context = useContext(PaymentHooksContext);
  if (!context) {
    throw new Error(`Context not instantiated`);
  }
  return context;
};
