import React from 'react';

type Props = {
  [key: string]: string | number | Function;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="29"
      height="27"
      viewBox="0 0 29 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.7083 3.2922C27.8014 3.38509 27.8753 3.49544 27.9257 3.61693C27.9761 3.73842 28.0021 3.86867 28.0021 4.0002C28.0021 4.13174 27.9761 4.26198 27.9257 4.38347C27.8753 4.50496 27.8014 4.61531 27.7083 4.7082L13.7083 18.7082C13.6154 18.8013 13.505 18.8752 13.3836 18.9256C13.2621 18.976 13.1318 19.002 13.0003 19.002C12.8688 19.002 12.7385 18.976 12.617 18.9256C12.4955 18.8752 12.3852 18.8013 12.2923 18.7082L6.29229 12.7082C6.19931 12.6152 6.12556 12.5048 6.07524 12.3834C6.02492 12.2619 5.99902 12.1317 5.99902 12.0002C5.99902 11.8687 6.02492 11.7385 6.07524 11.617C6.12556 11.4956 6.19931 11.3852 6.29229 11.2922C6.38526 11.1992 6.49564 11.1255 6.61712 11.0752C6.7386 11.0248 6.8688 10.9989 7.00029 10.9989C7.13177 10.9989 7.26198 11.0248 7.38345 11.0752C7.50493 11.1255 7.61531 11.1992 7.70829 11.2922L13.0003 16.5862L26.2923 3.2922C26.3852 3.19907 26.4955 3.12519 26.617 3.07478C26.7385 3.02436 26.8688 2.99841 27.0003 2.99841C27.1318 2.99841 27.2621 3.02436 27.3836 3.07478C27.505 3.12519 27.6154 3.19907 27.7083 3.2922Z"
        fill="#2DC071"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 3.0001C10.8244 3.0001 8.69769 3.64524 6.88875 4.85394C5.07981 6.06263 3.66991 7.7806 2.83734 9.79058C2.00478 11.8006 1.78694 14.0123 2.21138 16.1461C2.63582 18.2799 3.68347 20.2399 5.22184 21.7783C6.76022 23.3167 8.72023 24.3643 10.854 24.7887C12.9878 25.2132 15.1996 24.9953 17.2095 24.1628C19.2195 23.3302 20.9375 21.9203 22.1462 20.1114C23.3549 18.3024 24 16.1757 24 14.0001C24 13.7349 24.1054 13.4805 24.2929 13.293C24.4805 13.1055 24.7348 13.0001 25 13.0001C25.2652 13.0001 25.5196 13.1055 25.7071 13.293C25.8947 13.4805 26 13.7349 26 14.0001C25.9997 16.8618 25.0551 19.6434 23.3127 21.9135C21.5704 24.1836 19.1277 25.8154 16.3635 26.5558C13.5992 27.2962 10.6679 27.1038 8.02417 26.0085C5.38042 24.9131 3.17195 22.9761 1.74127 20.4977C0.310593 18.0193 -0.262343 15.1381 0.111317 12.301C0.484977 9.4638 1.78435 6.82919 3.80793 4.80575C5.8315 2.7823 8.46619 1.4831 11.3034 1.10962C14.1406 0.736143 17.0217 1.30926 19.5 2.7401C19.6195 2.80262 19.7252 2.88866 19.8105 2.99305C19.8959 3.09745 19.9593 3.21804 19.9969 3.34756C20.0345 3.47709 20.0455 3.61288 20.0292 3.74677C20.0129 3.88065 19.9698 4.00986 19.9023 4.12663C19.8348 4.24339 19.7444 4.3453 19.6365 4.42622C19.5286 4.50713 19.4054 4.56539 19.2744 4.59747C19.1434 4.62956 19.0073 4.63482 18.8742 4.61293C18.7411 4.59104 18.6138 4.54246 18.5 4.4701C16.8285 3.50348 14.9309 2.99632 13 3.0001Z"
        fill="#2DC071"
      />
    </svg>
  );
};

export default Component;
