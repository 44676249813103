import { Skeleton } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import theme from '../../../constants/themes';
import { Plan } from '../../../domain/entities/plan';
import { PlanCard } from '../PlanCard';

const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5em;
  gap: 3em;
`;

const PriceTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  letter-spacing: -0.02em;
  color: ${theme.colors.black};

  span {
    padding-bottom: 0.2em;
    border-bottom: 3px solid ${theme.colors.notifToastBorder};
  }
`;

const PriceDetails = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  gap: 2em;
`;

const StyledPlanCardWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;

  @media ${theme.breakpoints.tablet} {
    justify-content: center;
  }
`;

const StyledPlanCard = styled(PlanCard)`
  margin: 35px 25px;
  width: 325px;
`;

export type Props = {
  planContent: Plan[] | undefined;
  isFetching?: boolean;
  onSelectPlan?: (plan: string) => void;
  allowEditContent?: boolean;
};

const Component = ({
  planContent,
  isFetching,
  allowEditContent,
  onSelectPlan,
}: Props): React.ReactElement => {
  const handleSelectPlan = (plan: string) => {
    if (onSelectPlan) {
      onSelectPlan(plan);
    }
  };

  return (
    <PriceContainer id="price">
      <PriceTitle>
        <span>料金</span>
      </PriceTitle>

      <PriceDetails>
        {isFetching ? (
          <>
            <Skeleton
              sx={{
                bgcolor: '#737373',
                margin: '30px',
                borderRadius: '10px',
              }}
              variant="rectangular"
              width={325}
              height={438}
            />
            <Skeleton
              sx={{
                bgcolor: '#737373',
                margin: '30px',
                borderRadius: '10px',
              }}
              variant="rectangular"
              width={325}
              height={438}
            />
            <Skeleton
              sx={{
                bgcolor: '#737373',
                margin: '30px',
                borderRadius: '10px',
              }}
              variant="rectangular"
              width={325}
              height={438}
            />
          </>
        ) : (
          <StyledPlanCardWrapper>
            {planContent?.map(dataPlan => (
              <StyledPlanCard
                key={dataPlan.id}
                themeColor={dataPlan.themeColor}
                name={dataPlan.type}
                description={dataPlan.description}
                bodyTitle={dataPlan.pricing}
                buttonTitle={dataPlan.buttonName}
                points={dataPlan.content}
                pricing={dataPlan.price}
                isDisabled={allowEditContent}
                onPress={() => handleSelectPlan(dataPlan.type)}
              />
            ))}
          </StyledPlanCardWrapper>
        )}
      </PriceDetails>
    </PriceContainer>
  );
};

export default Component;
