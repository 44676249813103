import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { Edge, Node } from 'react-flow-renderer';
import Skeleton from '@mui/material/Skeleton';
import { useBoardHooks } from '../../../hooks/board';
import { useQuery } from 'react-query';
import { DiagramPreview } from '.';
import theme from '../../../constants/themes';
import queryKeys from '../../../constants/queryKeys';

const Wrapper = styled.div`
  width: 500px;
  height: 500px;
  .react-flow .react-flow__edges {
    /* NOTE: !important appears to be the only way to override edge inline style problem with react flow */
    z-index: -1 !important;
  }
`;

const StyledSkeleton = styled(Skeleton)`
  width: 100%;
  height: 100%;
`;

const Blank = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  color: ${theme.colors.black};
`;

export type Props = {
  diagramUrl: string;
  style?: CSSProperties;
  className?: string;
  key?: string | number;
  onClickDiagram?: (xmlStr: string) => void;
};

const Component = ({
  className,
  diagramUrl,
  style,
  onClickDiagram = () => {},
}: Props): React.ReactElement => {
  const { useBoardDiagram } = useBoardHooks();
  const { fetchBoardDiagram } = useBoardDiagram();
  const { data, isFetching } = useQuery(
    [queryKeys.FETCH_BOARD_DIAGRAM, diagramUrl],
    () => {
      return fetchBoardDiagram(diagramUrl);
    },
  );
  const nodes = data?.nodes || [];
  const edges = data?.edges || [];
  const xmlStr = data?.xmlStr || '';

  return (
    <Wrapper
      className={className}
      style={style}
      onClick={
        !isFetching
          ? () => {
              onClickDiagram(xmlStr);
            }
          : undefined
      }>
      {isFetching ? (
        <StyledSkeleton variant="rectangular" />
      ) : (
        ((nodes.length || edges.length) && (
          <DiagramPreview nodes={nodes} edges={edges} />
        )) || <Blank>空白図</Blank>
      )}
    </Wrapper>
  );
};

export default Component;
