import BoardRepository from '../../ports/BoardRepository';
import { FavoriteSuccess } from '../../../domain/entities/board';

export type setBoardAsFaveUseCase = (
  boardCode: string,
) => Promise<FavoriteSuccess>;

export const buildSetBoardAsFave = (dependencies: {
  boardRepo: BoardRepository;
}): setBoardAsFaveUseCase => {
  const { boardRepo } = dependencies;

  const setBoardAsFave: setBoardAsFaveUseCase = boardCode =>
    boardRepo.setBoardAsFave(boardCode);

  return setBoardAsFave;
};
