import React, { createElement, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import theme from '../../../constants/themes';
import { renderToStaticMarkup } from 'react-dom/server';
import {
  FaqDownArrow,
  FaqUpArrow,
  Plus,
  Trash,
  UploadCloud,
} from '../../atoms/Icons';
import createDOMPurify from 'dompurify';
import { FaqContent } from './types';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import InlineEditor from '@itsmitchyyy/ckeditor5-build-inline';
import { Toast } from '../../atoms/Toast';
import { Props as INotification } from '../../atoms/Toast/Component';
import { usePageHooks } from '../../../hooks/page';
import { useMutation } from 'react-query';
import { TextInput } from '../../atoms/TextInput';
import { useTranslation } from 'react-i18next';
import { replaceNbsp } from '../../../utils/strings';

const reactSvgComponentToMarkupString = (Component: any, props: any) =>
  `data:image/svg+xml,${encodeURIComponent(
    renderToStaticMarkup(createElement(Component, props)),
  )}`;

const FaqContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5em;
  gap: 3em;
`;

const FaqDetails = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 3em;
`;

const FaqTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 1em;
`;

const FaqTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  letter-spacing: -0.02em;
  color: ${theme.colors.black};

  span {
    padding-bottom: 0.2em;
    border-bottom: 3px solid ${theme.colors.notifToastBorder};
  }
`;

const FaqImageContainer = styled.div`
  position: relative;
`;

const FaqAccordionContainer = styled.div`
  display: flex;
  flex: 1;
`;

const AccordionContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  .CKEditorPadding {
    padding: 1em;
  }
`;

const AccordionInputContainer = styled.div<{
  isActive?: boolean;
  hasError?: boolean;
}>`
  position: relative;
  border-radius: 5px;
  background: ${theme.colors.dropdownBg};
  border: none;
  text-align: left;
  outline: none;
  font-style: normal;
  font-weight: 700;
  cursor: pointer;
  font-size: 20px;
  line-height: 29px;
  width: 100%;
  height: ${({ hasError }) => (hasError ? 'auto' : '50px')};
  border: 1px solid ${theme.colors.dropdownBorder};
  letter-spacing: -0.02em;
  color: ${theme.colors.infoToastText};
  display: flex;
  align-items: center;
  gap: 1em;

  ${({ hasError }) => (hasError ? `padding: .5em 0` : ``)};

  ::after {
    content: ${({ isActive }) =>
      isActive
        ? `url(${reactSvgComponentToMarkupString(FaqUpArrow, {})})`
        : `url(${reactSvgComponentToMarkupString(FaqDownArrow, {})})`};
    float: right;
    margin-right: 20px;
    display: flex;
    justify-content: flex-end;
  }
`;

const AccordionButton = styled.button<{ isActive?: boolean }>`
  border-radius: 5px;
  background: ${theme.colors.dropdownBg};
  border: none;
  text-align: left;
  outline: none;
  font-style: normal;
  font-weight: 700;
  cursor: pointer;
  font-size: 20px;
  line-height: 29px;
  width: 100%;
  height: 50px;
  border: 1px solid ${theme.colors.dropdownBorder};
  letter-spacing: -0.02em;
  color: ${theme.colors.infoToastText};

  ::after {
    content: ${({ isActive }) =>
      isActive
        ? `url(${reactSvgComponentToMarkupString(FaqUpArrow, {})})`
        : `url(${reactSvgComponentToMarkupString(FaqDownArrow, {})})`};
    float: right;
    margin-right: 20px;
  }
`;

const Panel = styled.div`
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  background-color: ${theme.colors.white};
  border: 1px solid ${theme.colors.dropdownBorder};
  border-top: none;
  border-bottom: none;
  border-radius: 5px;
  width: -webkit-fill-available;

  &:last-of-type {
    border-bottom: 1px solid ${theme.colors.dropdownBorder};
  }
`;

const PanelContent = styled.div`
  padding: 2em;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: ${theme.colors.textColor};
`;

const UploadFileButton = styled.div`
  background: #ffd200;
  width: 90px;
  height: 70px;
  display: flex;
  position: absolute;
  top: 10px;
  right: 10px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
`;

const StyledUploadCLoud = styled(UploadCloud)`
  height: 30px;
  width: 30px;
`;

const StyledPlus = styled(Plus)`
  height: 30px;
  width: 30px;
`;

const AddNewContent = styled.div`
  background: ${theme.colors.notifToastBorder};
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
`;

const StyledTextInput = styled(TextInput)`
  color: ${theme.colors.text02};
  margin-left: 6px;
  font-size: 16px;
  line-height: 28px;
  background-color: ${theme.colors.white};
  && {
    outline: none;
  }
`;

const StyledTrash = styled(Trash)`
  width: 20px;
  height: 20px;
  fill: ${theme.colors.errorToastBorder};
`;

const DeleteFaqIconContainer = styled.div`
  position: absolute;
  top: 5px;
  left: -45px;
  padding: 0.5em;
  border: 1px solid ${theme.colors.errorToastBorder};
  height: 20px;
  border-radius: 5px;
  cursor: pointer;

  :hover {
    background: ${theme.colors.errorToast};
    border: 1px solid ${theme.colors.errorToast};
  }
`;

const ErrorWrapper = styled.div<{ isTitle?: boolean }>`
  color: ${theme.colors.errorToastText};

  ${({ isTitle }) =>
    isTitle
      ? `
  font-family: 400;
  font-size: 16px; 
  margin-left: .5em`
      : ``};
`;

const DivContainer = styled.div<{ hasError?: boolean }>`
  .ck.ck-editor__editable.ck-rounded-corners:not(.ck-editor__nested-editable) {
    ${({ hasError }) =>
      hasError ? `border: 1px solid ${theme.colors.errorToastText}` : ''};
  }

  .editFaqTitle {
    ${({ hasError }) =>
      hasError ? `border: 1px solid ${theme.colors.errorToastText}` : ''};
  }
`;

const DOMPurify = createDOMPurify(window as any);

type ActiveAccordionType = {
  index: number;
  active: boolean;
};

export type Props = {
  faqContent: FaqContent;
  isFetching?: boolean;
  allowEditContent?: boolean;
  onChangeFaqData?: (data: FaqContent) => void;
  onAddNewElement?: () => void;
  serverErrors?: any;
};

const Component = ({
  faqContent,
  isFetching,
  allowEditContent,
  onChangeFaqData,
  onAddNewElement,
  serverErrors,
}: Props): React.ReactElement => {
  const { t } = useTranslation();
  const [activeAccordion, setActiveAccordion] = useState<ActiveAccordionType[]>(
    [],
  );
  const [notification, setNotification] = useState<INotification | null>(null);
  const { useUploadMedia } = usePageHooks();
  const { uploadMedia } = useUploadMedia();
  const [uploadedFile, setUploadedFile] = useState<string>();

  const inputFile = useRef<HTMLInputElement | null>(null);

  const handleClickInputFile = () => {
    if (inputFile) {
      inputFile?.current?.click();
    }
  };

  const toggleAccordion = (index: number, active: boolean) => {
    const newActiveAccordion = [...activeAccordion];
    newActiveAccordion[index] = { index, active };
    setActiveAccordion(newActiveAccordion);
  };

  const fileHandleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;

    if (!fileList) return;

    if (
      !['jpeg', 'jpg', 'png', 'gif', 'svg+xml'].includes(
        fileList[0]?.type.toLowerCase().replace(/(.*)\//g, ''),
      )
    ) {
      setNotification({
        isOpen: true,
        message: t('invalidImageFormat'),
        type: 'error',
        position: 'top-right',
        onClose: () => setNotification(null),
      });
      return;
    }

    uploadMediaMutation(fileList[0]);
  };

  const { mutate: uploadMediaMutation } = useMutation(
    (file: File) => {
      return uploadMedia(file);
    },
    {
      onSuccess: data => setUploadedFile(data.url),
    },
  );

  const handleDeleteFaq = (index: number) => {
    const faqData = { ...faqContent };
    faqContent.item.splice(index, 1);

    if (onChangeFaqData) onChangeFaqData(faqData);
  };

  useEffect(() => {
    if (uploadedFile && onChangeFaqData) {
      const faqData = { ...faqContent };
      faqData.image = uploadedFile;
      onChangeFaqData(faqData);
    }
  }, [uploadedFile]);

  return (
    <FaqContainer id="faq">
      {isFetching ? null : (
        <>
          <FaqTitleContainer>
            <FaqTitle>
              <span>{faqContent.title}</span>
            </FaqTitle>
            {allowEditContent && (
              <AddNewContent onClick={onAddNewElement}>
                <StyledPlus />
              </AddNewContent>
            )}
          </FaqTitleContainer>
          <FaqDetails>
            <DivContainer>
              <FaqImageContainer>
                <img
                  height={324}
                  width={451}
                  src={uploadedFile || faqContent.image}
                  alt="image"
                />
                {allowEditContent && (
                  <UploadFileButton onClick={handleClickInputFile}>
                    <StyledUploadCLoud />
                    <input
                      ref={inputFile}
                      type="file"
                      id="file-faq"
                      onChange={fileHandleChange}
                      style={{ display: 'none' }}
                    />
                  </UploadFileButton>
                )}
              </FaqImageContainer>
              {serverErrors && !!serverErrors?.[`faq.image`] && (
                <ErrorWrapper>
                  <span>{serverErrors?.[`faq.image`]}</span>
                </ErrorWrapper>
              )}
            </DivContainer>

            <FaqAccordionContainer>
              <AccordionContainer>
                {faqContent.item?.map((item, index) => (
                  <>
                    {allowEditContent ? (
                      <AccordionInputContainer
                        hasError={
                          serverErrors &&
                          !!serverErrors?.[`faq.item.${index}.title`]
                        }
                        onClick={() =>
                          toggleAccordion(
                            index,
                            !activeAccordion[index]?.active,
                          )
                        }
                        isActive={activeAccordion[index]?.active}
                        key={index}>
                        <DeleteFaqIconContainer
                          onClick={() => handleDeleteFaq(index)}>
                          <StyledTrash />
                        </DeleteFaqIconContainer>

                        <DivContainer
                          style={{ width: '100%' }}
                          hasError={
                            serverErrors &&
                            !!serverErrors?.[`faq.item.${index}.title`]
                          }>
                          <StyledTextInput
                            className="editFaqTitle"
                            value={item.title.replace(/(<([^>]+)>)/gi, '')}
                            onChangeText={text => {
                              const faqData = { ...faqContent };
                              faqData.item[index].title = text;
                              if (onChangeFaqData) {
                                onChangeFaqData(faqData);
                              }
                            }}
                            onClick={e => e.stopPropagation()}
                          />

                          {serverErrors &&
                            !!serverErrors?.[`faq.item.${index}.title`] && (
                              <ErrorWrapper isTitle>
                                <span>
                                  {serverErrors?.[`faq.item.${index}.title`]}
                                </span>
                              </ErrorWrapper>
                            )}
                        </DivContainer>
                      </AccordionInputContainer>
                    ) : (
                      <AccordionButton
                        onClick={() =>
                          toggleAccordion(
                            index,
                            !activeAccordion[index]?.active,
                          )
                        }
                        isActive={activeAccordion[index]?.active}
                        key={index}
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(replaceNbsp(item.title)),
                        }}
                      />
                    )}
                    {(activeAccordion[index]?.active &&
                      index === activeAccordion[index]?.index) ||
                    (serverErrors &&
                      !!serverErrors?.[`faq.item.${index}.description`]) ? (
                      <Panel
                        className={allowEditContent ? 'CKEditorPadding' : ''}>
                        {allowEditContent ? (
                          <DivContainer
                            hasError={
                              serverErrors &&
                              !!serverErrors?.[`faq.item.${index}.description`]
                            }>
                            <CKEditor
                              key={`accordion-${index}`}
                              editor={InlineEditor}
                              config={{
                                extraAllowedContent: 'div(*)',
                                allowedContent: true,
                              }}
                              data={item.description}
                              onChange={(event: any, editor: any) => {
                                const data = editor.getData();

                                const faqData = { ...faqContent };
                                faqData.item[index].description = data;
                                if (onChangeFaqData) {
                                  onChangeFaqData(faqData);
                                }
                              }}
                            />
                            {serverErrors &&
                              !!serverErrors?.[
                                `faq.item.${index}.description`
                              ] && (
                                <ErrorWrapper>
                                  <span>
                                    {
                                      serverErrors?.[
                                        `faq.item.${index}.description`
                                      ]
                                    }
                                  </span>
                                </ErrorWrapper>
                              )}
                          </DivContainer>
                        ) : (
                          <PanelContent
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(
                                replaceNbsp(item.description),
                              ),
                            }}
                          />
                        )}
                      </Panel>
                    ) : null}
                  </>
                ))}

                {(serverErrors && !!serverErrors?.[`faq_item`]) ||
                (faqContent.item && !faqContent.item.length) ? (
                  <ErrorWrapper>
                    <span>
                      {serverErrors?.[`faq_item`] || t('faqRequired')}
                    </span>
                  </ErrorWrapper>
                ) : null}
              </AccordionContainer>
            </FaqAccordionContainer>
          </FaqDetails>
          {notification ? <Toast {...notification} /> : null}
        </>
      )}
    </FaqContainer>
  );
};

export default Component;
