import React from 'react';
type Props = {
  color?: string;
  borderColor?: string;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="20"
      height="23"
      viewBox="0 0 20 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_1_2)">
        <path
          d="M2.47069 2.5543C2.38234 2.13202 2.8344 1.80357 3.20871 2.0181L17.586 10.2579C17.9655 10.4754 17.9025 11.041 17.4844 11.1696L10.5165 13.313C10.4044 13.3475 10.308 13.4203 10.2441 13.5186L6.76073 18.8846C6.51774 19.2589 5.94334 19.1516 5.85195 18.7148L2.47069 2.5543Z"
          fill={props?.color || '#333333'}
        />
        <path
          d="M2.96009 2.4519L17.3373 10.6917L10.3695 12.8351C10.1453 12.9041 9.95248 13.0496 9.82476 13.2464L6.34135 18.6124L2.96009 2.4519Z"
          stroke={props?.borderColor || 'white'}
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1_2"
          x="0.459493"
          y="0.950557"
          width="19.378"
          height="21.1621"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.28 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1_2"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1_2"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default Component;
