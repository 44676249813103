import React from 'react';

type Props = {
  [key: string]: string | number | Function;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="10"
      height="1"
      viewBox="0 0 10 1"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M9.46429 0H0.535714C0.239847 0 0 0.209867 0 0.46875C0 0.727633 0.239847 0.9375 0.535714 0.9375H9.46429C9.76015 0.9375 10 0.727633 10 0.46875C10 0.209867 9.76015 0 9.46429 0Z"
        fill="#3D4752"
      />
    </svg>
  );
};

export default Component;
