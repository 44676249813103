export default {
  // Login Screen
  todoApp: 'Todo App',
  employeeNumber: 'Employee number',
  password: 'Password',
  login: 'Login',
  signInTitle: 'SIGN IN',
  emailLabel: 'Email Address',
  passwordLabel: 'Password',
  rememberMe: 'Remember me?',
  thirdPartySignIn: 'Sign In using SNS',
  fieldIsRequired: 'Field is required.',
  emailDNE: 'Email does not exist.',
  invalidEmail: 'Email is invalid.',
  forgotPassword: 'Forgot Password?',
  signUpPretext: 'If you do not have an account ',
  signUp: 'Create an account',
  signUpTitle: 'SIGN UP',
  nameLabel: 'Name',
  register: 'Register',
  passwordMinLength: 'Password should have at least 8 characters.',
  signIn: 'Sign In',
  signInPretext: 'Do you already have an account?',

  // Change Password Screen
  pleaseSetNewPassword: 'Please set the new password',
  newPassword: 'New password',
  newPasswordConfirm: 'New password (confirm)',
  change: 'Change',
  changePassword: 'Change password',
  member: 'Member',
  substituteRecord: 'Substitute',

  // Home Screen
  home: 'Home',
  offDuty: 'Off-duty',
  onDuty: 'On-duty',
  constructionNumber: 'Client number construction',
  constructionSubject: 'Construction subject',
  start: 'Start',
  modify: 'Modify',
  finish: 'Finish',
  cancel: 'Cancel',
  confirm: 'Confirm',
  confirmAttendance: 'The work schedule will be marked as follows. Is it okay?',
  all: 'All',

  // Errors
  required: 'This field is required',
  requiredEmail: 'Email address is required.',
  requiredPassword: 'Password is required.',
  requiredName: 'Full name is required.',
  maxLengthEmail: 'Email address should be less than 255 characters.',
  maxLengthPassword: 'Password should be less than 255 characters.',
  maxLengthFullName: 'Full name should be less than 255 characters.',
  loginError: 'Incorrect ID or password',
  changePassNotMatchError: 'Passwords do not match.',
  changePassCaseError:
    'Use at least one lowercase letter and one half-size number each.',
  somethingWentWrong: 'Something went wrong. Please retry.',
  boardSharing: 'Board Sharing',
  upgrade: 'Upgrade',
  networkError: 'Network Error',
  passwordsMustMatch: 'Password and Re-enter password must match.',
  resetRequiredPassword: 'Be sure to specify the password.',
  resetConfirmPasswordMinLength:
    'Specify the re-entry password using at least 8 characters.',
  resetConfirmPasswordMaxLength:
    'Specify the re-entry password using 255 characters or less.',
  resetRequiredReentryPassword: 'Be sure to specify the re-entry password.',
  resetReentryPasswordMustMatch:
    'Specify the same value for the re-entry password and password.',
  maximumMessageCharacter:
    'Specify the message content with 255 characters or less.',

  // Verify Email
  verifyPrompt: 'We have sent a confirmation email.',
  verificationSent: 'We sent an email to the following address',
  verificationEmail: 'Please authenticate your IDEAMAN account to {{email}}',
  returnToHome: 'Click "Go to Home" to return to the home page screen.',
  resendPrompt: `If you don't have an email`,
  resend: 'Resend verification email',
  homeBtn: 'Home',
  resendSuccess: 'Email verification sent',

  // Email Verified
  emailVerifiedTitle: 'THANK YOU!',
  registered: 'Registration Complete',
  registeredSubtext: 'Email Address verification is complete.',
  toMyBoard: 'マイボードへ',
  participant: 'Participant',
  send: 'Send',

  // My Board List
  latestFilter: '最近作成したボード',
  latestTeamFilter: '{{teamName}}のボード',
  favoriteFilter: 'お気に入りボード',
  addTeamPlaceholder: 'Team name',
  addTeamModalTitle: 'Add Team',
  addBtn: 'Add',
  addBoard: 'New',
  delete: 'Delete',
  newChannel: 'New Board',
  personal: 'Personal',
  teams: 'Team',
  addTeam: 'Add Team',
  profile: 'Profile',
  searchPlaceholder: 'Search',
  updatedAtSort: '変更日',
  createdBySort: '変更者',
  boardNameSort: 'ボード名',
  template: 'Template',
  selectRoleBtn: 'As a {{roleName}}',
  roleSelectTitle: 'Job Title Selection',
  basicInformation: 'Basic Information',
  familyName: 'Family Name',
  mailAddress: 'Mail Address',
  plan: 'Plan',
  oldPassword: 'Old Password',
  newProfilePassword: 'New Password',
  renewedPassword: 'Renewed Password',
  accountDeletion: 'Account Deletion',
  deleteInfo:
    'If you delete your account, all data and channels will be lost.\nPlease be careful.',
  withdrawal: 'Withdrawal',
  keep: 'Keep',
  edit: 'Edit',
  uploadedImage: 'Uploaded Image',
  searchResults: 'Search Results',
  upload: 'Upload',
  inviteTeamMembers: 'Invite Team Members',
  addTeamMember: 'Add Member',
  createTeamMaxLength: 'チーム名は、255文字以下で指定してください。',
  teamNameRequired: 'Please specify team name',
  teamIdRequired: 'Please specify the team.',
  teamIdMin: 'The selected team is incorrect.',
  warningLabel:
    'すべての機能を使用できるようにメールアドレスを更新してください。',
  warningLink: 'プロフィール更新ページへ移動',
  emailInstruction: '複数のメールアドレスをカンマ（,）で区切って入力できます。',

  //PlanCards
  yen: '円',
  perMonth: '/月額',

  // Top Page
  topLogin: 'Member Login',
  topSignup: 'Free Registration',
  credits: '©2022 Flagman. All Rights Reserved.',
  pleaseChoosePlan: 'Please choose a plan',

  // deleteUser
  deleteUserPrompt: 'Are you sure you want to delete your account?',

  // Payment History
  month: '月',
  paymentHistorySettings: '設定',
  currentPlan: 'Current Plan',
  cardLabel: 'Payment Information',
  date: 'Date',
  paymentHistoryLabel: 'Payment History',
  explanation: 'Explanation',
  amount: 'Amount',
  status: 'Status',

  //Member Management
  invoice: 'Invoice',
  teamMemberManagement: 'Team member management',
  memberManagement: 'Member management',
  accountType: 'Account Type',
  editMember: 'Edit Member',
  deleteConfirmation: 'Delete Confirmation',
  deleteThisAccount: 'Are you sure you want to delete your account?',
  deleteThisAccountInTeam:
    'Are you sure you want to delete your account in team?',
  deleteThisAccountInBoard:
    'Are you sure you want to delete your account in board?',
  no: 'No',
  yes: 'Yes',
  manager: 'Manager',

  // profile screen change password
  passwordMustMatch: 'The password and the re-enter password do not match.',
  oldPasswordRequired: 'Old password is required',
  newPasswordRequired: 'New password is required',
  renewedPasswordRequired: 'Renewed password is required',

  // top page
  invalidImageFormat:
    'Please specify jpeg, jpg, svg, png, gif, type file for file upload.',
  invalidVideoFormat: 'Specify an MP4 type file for file upload.',
  carouselRequired: 'Be sure to specify a customer review.',
  faqRequired:
    'If you specify frequently asked questions, also specify the items',

  // hint
  otherHint: 'OTHER HINT',
  hint: 'HINT',
};
