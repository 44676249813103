import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import theme from '../../../constants/themes';
import createDOMPurify from 'dompurify';
import { Skeleton } from '@mui/material';
import { Flagman, WhiteRightArrow } from '../../atoms/Icons';
import { Button } from '../../atoms/Button';
import { useGlobalState } from '../../../hooks/global';

const Container = styled.div`
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  width: 100%;
  height: 80px;
  border-bottom: thin solid ${theme.colors.topHeaderBorder};
  background-color: ${theme.colors.white};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 2;

  @media ${theme.breakpoints.pc} {
    display: none;
  }
`;

const StyledFlagman = styled(Flagman)`
  width: 122px;
  height: 50px;
  margin-left: 8px;
`;

const HamburgerContainer = styled.div<{ isOpen?: boolean }>`
  width: 30px;
  height: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  cursor: pointer;
  margin-right: 15px;

  div {
    width: 100%;
    height: 2px;
    background-color: ${theme.colors.textColor};
    transition: width 0.25s ease-in-out;

    :nth-child(1) {
      width: 100%;
    }
    :nth-child(2) {
      width: ${props => (props.isOpen ? '20px' : '100%')};
    }
    :nth-child(3) {
      width: ${props => (props.isOpen ? '15px' : '100%')};
    }
  }

  &:hover {
    div {
      background-color: ${theme.colors.burgerHover};
    }
  }
`;

const MenuWrapper = styled.div<{ isOpen?: boolean }>`
  width: 100%;
  background-color: ${theme.colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 59px;
  left: 0;
  transition: all 0.5s ease-in-out;
  height: ${props => (props.isOpen ? '419px' : '0')};
  overflow: hidden;
`;

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  div:nth-child(1) {
    margin-top: 18px;
  }

  div:not(:first-of-type) {
    margin-top: 13px;
  }

  div {
    a {
      font-family: ${theme.fonts.primary};
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.2px;
      text-decoration: none;
      color: ${theme.colors.textColor};

      &:hover {
        text-decoration: underline;
      }

      &.active {
        a {
          color: #60bf91;
        }
      }
    }
  }
`;

const StyledSignInLink = styled.span`
  width: max-content;
  height: max-content;
  font-family: ${theme.fonts.primary};
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.2px;
  text-decoration: none;
  color: ${theme.colors.notifToastBorder};
  margin-top: 13px;
  cursor: pointer;
`;

const StyledRegisterBtn = styled(Button)`
  width: 179px;
  height: 58px;
  border-radius: 5px;
  margin-top: 13px;
`;

const DOMPurify = createDOMPurify(window);

export type Props = {
  links: any[];
  isFetching?: boolean;
  className?: string;
};

const Component = ({
  links,
  isFetching,
  className,
}: Props): React.ReactElement => {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [active, setActive] = useState({ index: 0, active: true });
  const { useCurrentUser } = useGlobalState();
  const { currentUser } = useCurrentUser;

  return (
    <Container className={className}>
      <div onClick={() => navigate('/')}>
        <StyledFlagman />
      </div>
      <HamburgerContainer onClick={() => setIsOpen(!isOpen)} isOpen={isOpen}>
        <div />
        <div />
        <div />
      </HamburgerContainer>
      <MenuWrapper isOpen={isOpen}>
        <LinksWrapper>
          {isFetching ? (
            <>
              <Skeleton variant="rectangular" height={20} width={90} />
              <Skeleton variant="rectangular" height={20} width={90} />
              <Skeleton variant="rectangular" height={20} width={90} />
            </>
          ) : (
            <>
              {links?.map((link, index) => (
                <div
                  className={`${
                    index === active.index && active.active ? 'active' : ''
                  }`}
                  onClick={() => setActive({ index, active: true })}
                  key={index}
                  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(link) }}
                />
              ))}
            </>
          )}
        </LinksWrapper>
        {currentUser?.user.isVerified ? (
          <StyledRegisterBtn
            title="マイボードへ"
            theme="lightSecondary"
            icon={<WhiteRightArrow />}
            iconPosition="right"
            onPress={() => navigate('/my-board')}
          />
        ) : (
          <>
            <StyledSignInLink onClick={() => navigate('/signin')}>
              会員ログイン
            </StyledSignInLink>
            <StyledRegisterBtn
              theme="lightSecondary"
              title="無料登録"
              iconPosition="right"
              iconMargin={15}
              icon={<WhiteRightArrow />}
              onPress={() => navigate('/signup')}
            />
          </>
        )}
      </MenuWrapper>
    </Container>
  );
};

export default Component;
