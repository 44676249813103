import React from 'react';

type Props = {
  [key: string]: string | number | Function | object;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g clipPath="url(#clip0_686_9929)">
        <path
          d="M5.82728 11.9612C5.1065 11.9612 4.45256 11.6519 3.97006 11.152C3.96971 11.4516 3.95881 11.884 3.87939 12.1995C3.9315 12.2314 3.97193 12.2837 3.97982 12.3487C4.08279 13.1866 4.87697 13.8183 5.82728 13.8183C6.77744 13.8183 7.57158 13.1865 7.67475 12.3487C7.68275 12.2835 7.72232 12.2314 7.77521 12.1996C7.69576 11.884 7.68486 11.4516 7.68451 11.152C7.20201 11.6519 6.54803 11.9612 5.82728 11.9612Z"
          fill="#FFB980"
        />
        <path
          d="M7.6442 11.1901C7.16576 11.6666 6.52881 11.9612 5.82728 11.9612C5.1065 11.9612 4.45256 11.6519 3.97006 11.152C3.96971 11.4516 3.95881 11.8841 3.87939 12.1995C3.9315 12.2314 3.97193 12.2837 3.97982 12.3487C4.02533 12.7191 4.20623 13.0488 4.47564 13.3019C5.71807 13.1269 7.12822 11.9001 7.6442 11.1901Z"
          fill="#F8AB6B"
        />
        <path
          d="M9.59371 12.4224C9.58543 12.4201 9.57719 12.4182 9.56871 12.417L7.92152 12.1699C7.91145 12.1684 7.88988 12.1676 7.87961 12.1676C7.77543 12.1676 7.68758 12.2453 7.67488 12.3487C7.57172 13.1866 6.77754 13.8184 5.82742 13.8184C4.87707 13.8184 4.08293 13.1866 3.97996 12.3487C3.96707 12.2423 3.87113 12.1621 3.77 12.1677C3.75789 12.167 3.74602 12.168 3.73332 12.1699L2.08613 12.4171C2.07766 12.4183 2.06941 12.4201 2.06113 12.4224C1.28246 12.6404 0.875 13.4066 0.875 14.0507V14.8501C0.875 15.1905 1.17809 15.4674 1.55066 15.4674H10.1041C10.4768 15.4674 10.7798 15.1905 10.7798 14.8501V14.0507C10.7798 13.4066 10.3723 12.6404 9.59371 12.4224Z"
          fill="#00E68C"
        />
        <path
          d="M7.68475 6.80243H4.17678C3.60791 6.80243 3.14502 7.26532 3.14502 7.83419V9.07227C3.14502 10.6652 4.34846 11.9612 5.82756 11.9612C7.30666 11.9612 8.5101 10.6652 8.5101 9.07227V7.62782C8.51014 7.17274 8.13994 6.80243 7.68475 6.80243Z"
          fill="#FDC88E"
        />
        <path
          d="M7.27198 4.53259H4.5894C4.20127 4.53259 3.87502 4.80181 3.78717 5.16322C3.07682 5.25349 2.52588 5.86166 2.52588 6.59607V7.62783C2.52588 8.15962 2.75162 8.65572 3.14494 9.0083V7.83415C3.14494 7.26529 3.60783 6.8024 4.1767 6.8024H7.68467C8.13991 6.8024 8.51006 7.17267 8.51006 7.62779V9.00806C8.90346 8.65556 9.12912 8.15947 9.12912 7.62779V6.3897C9.12912 5.36576 8.29604 4.53259 7.27198 4.53259Z"
          fill="#5C546A"
        />
        <path
          d="M15.9225 11.9612C15.2017 11.9612 14.5478 11.6519 14.0653 11.152C14.0649 11.4516 14.054 11.884 13.9746 12.1995C14.0267 12.2314 14.0671 12.2837 14.075 12.3487C14.178 13.1866 14.9722 13.8183 15.9225 13.8183C16.8727 13.8183 17.6668 13.1865 17.77 12.3487C17.778 12.2835 17.8175 12.2314 17.8704 12.1996C17.791 11.884 17.7801 11.4516 17.7797 11.152C17.2972 11.6519 16.6433 11.9612 15.9225 11.9612Z"
          fill="#FFB980"
        />
        <path
          d="M17.7394 11.1901C17.261 11.6666 16.624 11.9612 15.9225 11.9612C15.2017 11.9612 14.5478 11.6519 14.0653 11.152C14.0649 11.4516 14.054 11.8841 13.9746 12.1995C14.0267 12.2314 14.0671 12.2837 14.075 12.3487C14.1205 12.7191 14.3014 13.0488 14.5709 13.3019C15.8133 13.1269 17.2234 11.9001 17.7394 11.1901Z"
          fill="#F8AB6B"
        />
        <path
          d="M19.6889 12.4224C19.6806 12.4201 19.6724 12.4182 19.6639 12.417L18.0167 12.1699C18.0066 12.1684 17.9851 12.1676 17.9748 12.1676C17.8706 12.1676 17.7828 12.2453 17.7701 12.3487C17.6669 13.1866 16.8727 13.8184 15.9226 13.8184C14.9722 13.8184 14.1781 13.1866 14.0751 12.3487C14.0622 12.2423 13.9663 12.1621 13.8652 12.1677C13.8531 12.167 13.8412 12.168 13.8285 12.1699L12.1813 12.4171C12.1728 12.4183 12.1646 12.4201 12.1563 12.4224C11.3777 12.6405 10.9702 13.4066 10.9702 14.0507V14.8501C10.9702 15.1905 11.2733 15.4674 11.6459 15.4674H20.1994C20.572 15.4674 20.875 15.1905 20.875 14.8501V14.0507C20.875 13.4066 20.4676 12.6404 19.6889 12.4224Z"
          fill="#00ABFF"
        />
        <path
          d="M17.78 6.80243H14.272C13.7031 6.80243 13.2402 7.26532 13.2402 7.83419V9.07227C13.2402 10.6652 14.4437 11.9612 15.9228 11.9612C17.4019 11.9612 18.6053 10.6652 18.6053 9.07227V7.62782C18.6054 7.17274 18.2352 6.80243 17.78 6.80243Z"
          fill="#FDC88E"
        />
        <path
          d="M17.3671 4.53259H14.6846C14.2965 4.53259 13.9702 4.80181 13.8824 5.16322C13.172 5.25349 12.6211 5.86166 12.6211 6.59607V7.62783C12.6211 8.15962 12.8468 8.65572 13.2402 9.0083V7.83415C13.2402 7.26529 13.703 6.8024 14.2719 6.8024H17.7799C18.2351 6.8024 18.6053 7.17267 18.6053 7.62779V9.00806C18.9987 8.65556 19.2243 8.15947 19.2243 7.62779V6.3897C19.2243 5.36576 18.3913 4.53259 17.3671 4.53259Z"
          fill="#7E5C62"
        />
        <path
          d="M10.8748 12.9701C9.7832 12.9701 8.79285 12.5017 8.06215 11.7446C8.0616 12.1983 8.04512 12.8532 7.9248 13.331C8.00371 13.3792 8.06496 13.4584 8.07691 13.5569C8.23285 14.8258 9.43555 15.7826 10.8748 15.7826C12.3137 15.7826 13.5164 14.8257 13.6726 13.5569C13.6847 13.4583 13.7446 13.3792 13.8248 13.3312C13.7045 12.8533 13.6879 12.1984 13.6874 11.7446C12.9567 12.5017 11.9663 12.9701 10.8748 12.9701Z"
          fill="#FDC88E"
        />
        <path
          d="M13.6263 11.8023C12.9018 12.524 11.9372 12.9701 10.8748 12.9701C9.7832 12.9701 8.79285 12.5017 8.06215 11.7446C8.0616 12.1983 8.04512 12.8532 7.9248 13.331C8.00371 13.3792 8.06496 13.4584 8.07691 13.5569C8.14586 14.1178 8.4198 14.6172 8.82781 15.0004C10.7094 14.7355 12.8449 12.8776 13.6263 11.8023Z"
          fill="#FFB980"
        />
        <path
          d="M16.5787 13.6685C16.5662 13.665 16.5537 13.6622 16.5409 13.6604L14.0464 13.2861C14.0311 13.2838 13.9984 13.2826 13.9829 13.2826C13.8251 13.2826 13.6921 13.4002 13.6728 13.5569C13.5166 14.8257 12.3139 15.7826 10.875 15.7826C9.43574 15.7826 8.23305 14.8259 8.07711 13.5569C8.05758 13.3956 7.9123 13.2743 7.7591 13.2827C7.74078 13.2816 7.72277 13.2832 7.70355 13.2861L5.20906 13.6604C5.19625 13.6622 5.18375 13.6649 5.17121 13.6684C3.99207 13.9987 3.375 15.1589 3.375 16.1344V17.3451C3.375 17.8605 3.83398 18.2798 4.39824 18.2798H17.3517C17.916 18.2798 18.375 17.8605 18.375 17.3451V16.1344C18.375 15.1589 17.7579 13.9987 16.5787 13.6685Z"
          fill="#FF4F19"
        />
        <path
          d="M13.6875 5.15759H8.375C7.51348 5.15759 6.8125 5.85857 6.8125 6.72009V8.59509C6.8125 11.0075 8.635 12.9701 10.875 12.9701C13.115 12.9701 14.9375 11.0075 14.9375 8.59509V6.40759C14.9375 5.71837 14.3769 5.15759 13.6875 5.15759Z"
          fill="#FFE1B2"
        />
        <path
          d="M13.0625 1.72009H9C8.41223 1.72009 7.91816 2.12779 7.78508 2.67513C6.70934 2.81185 5.875 3.73287 5.875 4.84509V6.40759C5.875 7.21294 6.21687 7.96427 6.8125 8.49821V6.72009C6.8125 5.85857 7.51348 5.15759 8.375 5.15759H13.6875C14.3769 5.15759 14.9375 5.71833 14.9375 6.40759V8.49786C15.5332 7.96403 15.875 7.21275 15.875 6.40759V4.53259C15.875 2.98185 14.6134 1.72009 13.0625 1.72009Z"
          fill="#7E5449"
        />
      </g>
      <defs>
        <clipPath id="clip0_686_9929">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.875)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Component;
