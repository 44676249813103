import { buildFetchTeams, fetchTeamsUseCase } from './fetch-teams';
import { buildCreateTeam, createTeamUseCase } from './create-team';
import {
  buildFetchTeamMembers,
  fetchTeamMembersUseCase,
} from './fetch-team-members';
import {
  buildinviteTeamMember,
  inviteTeamMemberUseCase,
} from './invite-team-member';
import {
  buildDeleteTeamMember,
  deleteTeamMemberUseCase,
} from './delete-team-member';
import {
  buildUpdateTeamMember,
  updateTeamMemberUseCase,
} from './update-team-member';

import TeamRepository from '../../ports/TeamRepository';

export default (dependencies: {
  teamRepo: TeamRepository;
}): {
  fetchTeams: fetchTeamsUseCase;
  createTeam: createTeamUseCase;
  fetchTeamMembers: fetchTeamMembersUseCase;
  inviteTeamMember: inviteTeamMemberUseCase;
  deleteTeamMember: deleteTeamMemberUseCase;
  updateTeamMember: updateTeamMemberUseCase;
} => {
  const { teamRepo } = dependencies;
  const fetchTeams = buildFetchTeams({ teamRepo });
  const createTeam = buildCreateTeam({ teamRepo });
  const fetchTeamMembers = buildFetchTeamMembers({ teamRepo });
  const inviteTeamMember = buildinviteTeamMember({ teamRepo });
  const deleteTeamMember = buildDeleteTeamMember({ teamRepo });
  const updateTeamMember = buildUpdateTeamMember({ teamRepo });

  return {
    fetchTeams,
    createTeam,
    fetchTeamMembers,
    inviteTeamMember,
    deleteTeamMember,
    updateTeamMember,
  };
};
