import { useGoogleLogin } from '@react-oauth/google';
import axios, { AxiosResponse, ResponseType } from 'axios';
import { Formik, FormikHelpers } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import {
  ReactFacebookFailureResponse,
  ReactFacebookLoginInfo,
} from 'react-facebook-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

import { useTranslation } from 'react-i18next';
import MicrosoftLogin from 'react-microsoft-login';
import { useMutation, useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import signInImage from '../../../assets/images/signin-image.png';
import storageKeys from '../../../constants/localStorage';
import queryKeys from '../../../constants/queryKeys';
import theme from '../../../constants/themes';
import urls from '../../../constants/urls';
import { MicrosoftError } from '../../../domain/entities/errors';
import { useDependencies } from '../../../hooks';
import { useAuthHooks } from '../../../hooks/auth';
import { usePageHooks } from '../../../hooks/page';
import { Facebook, Flagman, Google, Microsoft } from '../../atoms/Icons';
import { Link } from '../../atoms/Link';
import { Toast } from '../../atoms/Toast';
import { Props as INotification } from '../../atoms/Toast/Component';
import { Footer } from '../../molecules/Footer';
import { MobileHeader } from '../../molecules/MobileHeader';
import { SignUpForm } from '../../organisms/SignUpForm';
import {
  FbLoginValues,
  GoogleLoginValues,
  MicrosoftValues,
} from '../../templates/Login/types';
import { blobToBase64 } from '../Login/Component';
import { SignUpValues } from './types';
import { SignUpSchema } from './validation';

const Container = styled.div`
  @media ${theme.breakpoints.pc} {
    width: 100%;
    min-width: 923px;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
  }

  @media ${theme.breakpoints.mobile} {
    width: 100%;
    min-height: 680px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin: 0;
  }

  @media ${theme.breakpoints.tablet} {
    width: 100%;
    height: calc(100vh - 119px - 58px);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 0;
  }
`;

const FlagmanWrapper = styled.div`
  position: absolute;
  top: 36px;
  left: 36px;

  @media ${theme.breakpoints.mobile} {
    display: none;
  }

  @media ${theme.breakpoints.tablet} {
    display: none;
  }
`;

const StyledSignInImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const StyledSignInImageDiv = styled.div`
  flex: 0 0 40%;
  height: 100%;
  width: 100%;

  @media ${theme.breakpoints.mobile} {
    display: none;
  }

  @media ${theme.breakpoints.tablet} {
    display: none;
  }
`;

const StyledFlagman = styled(Flagman)`
  width: max-content;
  height: fit-content;
  cursor: pointer;
`;

const FormWrapper = styled.div`
  width: max-content;
  height: max-content;
  display: flex;
  flex: 0 0 60%;
  flex-direction: column;
  align-items: center;

  @media ${theme.breakpoints.tablet} {
    padding: 50px 0px;
  }

  @media ${theme.breakpoints.mobile} {
    padding: 50px 0px;
    flex: 0 0 100%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
`;

const ThirdPartyLabel = styled.p`
  color: ${theme.colors.text01};
  font-weight: 400;
  font-size: 16px;
  line-height: 175%;
  margin: 0;
`;

const ThirdPartyWrapper = styled.div`
  height: max-content;
  width: 120px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;

  @media ${theme.breakpoints.mobile} {
    margin-bottom: 16px;
  }

  @media ${theme.breakpoints.tablet} {
    margin-bottom: 16px;
  }
`;

const StyledGoogle = styled(Google)`
  && {
    width: 16px;
    height: 16px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.5));
      transform: translateY(-0.15em);
    }
  }
`;

const StyledFB = styled(Facebook)`
  && {
    width: 24px;
    height: 24px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      filter: drop-shadow(4px 4px 4px rgba(57, 81, 150, 0.5));
      transform: translateY(-0.15em);
    }
  }
`;

const StyledMicrosoft = styled(Microsoft)`
  && {
    width: 24px;
    height: 24px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      filter: drop-shadow(4px 4px 4px rgba(234, 62, 35, 0.5));
      transform: translateY(-0.15em);
    }
  }
`;

const SignInWrapper = styled.div`
  margin: 0;
  padding: 0;
  width: max-content;
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
`;

const LoginPretext = styled.span`
  font-family: ${theme.fonts.primary};
  font-weight: 400;
  font-size: 16px;
  line-height: 175%;
  color: ${theme.colors.text01};
`;

const StyledLoginLink = styled(Link)`
  font-family: ${theme.fonts.primary};
  font-weight: 400;
  font-size: 16px;
  line-height: 175%;
  margin-top: 16px;
`;

export type Props = {};

const Component = ({}: Props): React.ReactElement => {
  const { t } = useTranslation();
  const { useSignUp, useLogin, useFetchUser } = useAuthHooks();
  const { useFetchPage } = usePageHooks();
  const { fetchGoogleUserData } = useFetchUser();
  const { fetchPage } = useFetchPage();
  const { localStorageInteractor } = useDependencies();
  const { removeItem } = localStorageInteractor;
  const navigate = useNavigate();

  const { signUpUser } = useSignUp();
  const { googleLogin, fbLogin, microsoftLogin } = useLogin();
  const signUpGoogle = useGoogleLogin({
    onSuccess: async tokenResponse => {
      const userInfo = await fetchGoogleUserData(tokenResponse.access_token);
      googleSignUpMutation({
        email: userInfo.email,
        name: userInfo.name,
        googleId: userInfo.sub,
        invitationCode: invitationCode ?? '',
        avatar: userInfo.picture,
      });
    },
  });

  const search = useLocation().search;
  const invitationCode = new URLSearchParams(search).get('invitation_code');

  const initialValues = {
    name: '',
    email: '',
    password: '',
  };

  const [notification, setNotification] = useState<INotification | null>(null);
  const [apiErrors, setApiErrors] = useState({
    apiError: '',
    multipleErrors: {},
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { data: getPageData, isFetching: isFetchingPageData } = useQuery(
    [queryKeys.FETCH_PAGE],
    () => {
      return fetchPage('TOP_PAGE');
    },
  );

  const { mutate: signUpMutation, isLoading: isSigningUp } = useMutation(
    ([name, email, password, invitationCode]: [
      string,
      string,
      string,
      string,
    ]) => {
      return signUpUser(name, email, password, invitationCode);
    },
    {
      onSuccess: () => {
        navigate('/plan-select');
      },
      onError: (response: { response: AxiosResponse }) => {
        const res: AxiosResponse = response.response;
        const data = res?.data as any;
        if (!res) {
          setApiErrors({
            apiError: String(response)
              .replace('Error:', '')
              .trim(),
            multipleErrors: {},
          });
        } else if (res.status === 422) {
          const errors = data.errors;
          setApiErrors({ apiError: '', multipleErrors: errors });
        } else if (res.status === 500) {
          setApiErrors({
            apiError: String(data.error),
            multipleErrors: {},
          });
        } else {
          setApiErrors({ apiError: String(response), multipleErrors: {} });
        }
      },
    },
  );

  const {
    mutate: googleSignUpMutation,
    isLoading: isSigningUpGoogle,
  } = useMutation(
    (params: GoogleLoginValues) => {
      return googleLogin(
        params.email,
        params.name,
        params.googleId,
        params.invitationCode,
        params.avatar,
      );
    },
    {
      onSuccess: () => {
        navigate('/my-board/personal');
      },
      onError: response => {
        setNotification({
          isOpen: true,
          message: String(response)
            .replace('Error:', '')
            .trim(),
          type: 'error',
          position: 'top-right',
          onClose: handleCloseToast,
        });
      },
    },
  );

  const { mutate: fbSignUpMutation, isLoading: isSigningUpFb } = useMutation(
    (params: FbLoginValues) => {
      return fbLogin(
        params.email,
        params.name,
        params.userID,
        params.invitationCode,
        params.avatar,
      );
    },
    {
      onSuccess: () => {
        navigate('/my-board/personal');
      },
      onError: response => {
        setNotification({
          isOpen: true,
          message: String(response)
            .replace('Error:', '')
            .trim(),
          type: 'error',
          position: 'top-right',
          onClose: handleCloseToast,
        });
      },
    },
  );

  const {
    mutate: microsoftSignUpMutation,
    isLoading: isSigningUpMicrosoft,
  } = useMutation(
    (params: MicrosoftValues) => {
      return microsoftLogin(
        params.account.userName,
        params.account.name,
        params.account.accountIdentifier,
        params.invitationCode,
        params.avatar,
      );
    },
    {
      onSuccess: () => {
        navigate('/my-board/personal');
      },
      onError: response => {
        setNotification({
          isOpen: true,
          message: String(response)
            .replace('Error:', '')
            .trim(),
          type: 'error',
          position: 'top-right',
          onClose: handleCloseToast,
        });
      },
    },
  );

  const handleMicrosoftLogin = useCallback(async (err, data) => {
    const error: MicrosoftError = err;
    const response = data as MicrosoftValues;
    setApiErrors({ apiError: '', multipleErrors: {} });

    if (response?.uniqueId) {
      // Note: This is for preventing the Microsoft Button to call the auth every time the page loads
      const sessionStorage = window.sessionStorage;
      sessionStorage.clear();

      const options = {
        headers: {
          Authorization: `Bearer ${response.accessToken}`,
          'Content-Type': 'image/jpg',
        },
        responseType: 'blob' as ResponseType,
      };
      const photo = await axios
        .get(urls.microsoft.profilePhoto, options)
        .then(async response => {
          const base64 = await blobToBase64(response.data).then(
            base64data => base64data,
          );
          return base64 as string;
        })
        .catch(function(err) {
          console.log(err);
          return '';
        });

      microsoftSignUpMutation({ ...response, avatar: photo ?? '' });
    } else if (!!error && error.errorCode !== 'user_cancelled') {
      setNotification({
        isOpen: true,
        message: `Microsoft Error: ${error.errorMessage}`,
        type: 'error',
        position: 'top-right',
        onClose: handleCloseToast,
      });
    }
  }, []);

  const submitFbLogin = useCallback((response: ReactFacebookLoginInfo) => {
    setApiErrors({ apiError: '', multipleErrors: {} });
    fbSignUpMutation({
      email: response.email || '',
      name: response.name || '',
      userID: response.userID,
      invitationCode: invitationCode || '',
      avatar: response.picture?.data.url || '',
    });
  }, []);

  const handleFailureFb = useCallback(
    (response: ReactFacebookFailureResponse) => {
      setApiErrors({ apiError: '', multipleErrors: {} });
      setNotification({
        isOpen: true,
        message: `Facebook Error: ${response.status}`,
        type: 'error',
        position: 'top-right',
        onClose: handleCloseToast,
      });
    },
    [],
  );

  const handleCloseToast = () => {
    setNotification(null);
  };

  const handleSignUp = useCallback(
    (
      values: SignUpValues,
      { resetForm }: FormikHelpers<SignUpValues>,
    ): void => {
      const { name, email, password } = values;
      setApiErrors({ apiError: '', multipleErrors: {} });

      signUpMutation([name, email, password, invitationCode ?? ''], {
        onSuccess: () => {
          resetForm();
        },
      });
    },
    [],
  );

  useEffect(() => {
    removeItem(storageKeys.UNAUTHORIZED_BOARD_STORAGE_KEY);
  }, []);

  return (
    <>
      <MobileHeader
        links={getPageData?.sections[0]?.header?.content}
        isFetching={isFetchingPageData}
      />
      <Container>
        <FlagmanWrapper>
          <a onClick={() => navigate('/top')}>
            <StyledFlagman />
          </a>
        </FlagmanWrapper>
        <FormWrapper>
          <Formik
            initialValues={initialValues}
            validationSchema={SignUpSchema}
            onSubmit={handleSignUp}>
            {({
              handleChange,
              handleSubmit,
              handleBlur,
              values,
              isValid,
              errors,
              touched,
              setFieldTouched,
            }): React.ReactElement => {
              const { name, email, password } = values;
              const hasErrors = Object.keys(errors).length > 0;

              return (
                <SignUpForm
                  name={name}
                  email={email}
                  password={password}
                  onChangeName={handleChange('name')}
                  onChangeEmail={handleChange('email')}
                  onChangePassword={handleChange('password')}
                  onPressSignUp={handleSubmit}
                  onBlurName={handleBlur}
                  onBlurEmail={handleBlur}
                  onBlurPassword={handleBlur}
                  isSignUpDisabled={
                    !isValid ||
                    hasErrors ||
                    isSigningUp ||
                    isSigningUpGoogle ||
                    isSigningUpFb ||
                    isSigningUpMicrosoft
                  }
                  errors={{
                    name: (touched.name && errors.name) || '',
                    email: (touched.email && errors.email) || '',
                    password: (touched.password && errors.password) || '',
                    apiErrors: { ...apiErrors },
                  }}
                  setFieldTouched={setFieldTouched}
                  isSigningUp={isSigningUp}
                />
              );
            }}
          </Formik>
          <SignInWrapper>
            <LoginPretext>{t('signInPretext')}</LoginPretext>
            <StyledLoginLink to="/signin" label={t('signIn')} />
          </SignInWrapper>
          <ThirdPartyLabel>{t('thirdPartySignIn')}</ThirdPartyLabel>
          <ThirdPartyWrapper>
            <StyledGoogle onClick={signUpGoogle} />
            <FacebookLogin
              appId={process.env.REACT_APP_FB_APP_ID || ''}
              callback={submitFbLogin}
              fields="name,picture,email"
              onFailure={handleFailureFb}
              render={(renderProps: {
                onClick: string | number | Function;
              }) => <StyledFB onClick={renderProps.onClick} />}
            />
            <MicrosoftLogin
              clientId={process.env.REACT_APP_MICROSOFT_CLIENT_ID || ''}
              authCallback={handleMicrosoftLogin}>
              <StyledMicrosoft />
            </MicrosoftLogin>
          </ThirdPartyWrapper>
        </FormWrapper>
        <StyledSignInImageDiv>
          <StyledSignInImage src={signInImage} alt="sign-in banner" />
        </StyledSignInImageDiv>
        {notification ? <Toast {...notification} /> : null}
      </Container>
      <Footer
        footerContents={[
          getPageData?.sections[6]?.footer?.content?.plan,
          getPageData?.sections[6]?.footer?.content?.frequently_used_links,
          getPageData?.sections[6]?.footer?.content?.company_overview,
        ]}
        addressContents={{
          address: getPageData?.sections[6]?.footer?.content?.company_address,
          telephoneNumber: getPageData?.sections[6]?.footer?.content?.tel,
          faxNumber: getPageData?.sections[6]?.footer?.content?.fax,
        }}
        faqsContents={{
          privacyPolicy:
            getPageData?.sections[6]?.footer?.content?.privacy_policy,
          termsOfService:
            getPageData?.sections[6]?.footer?.content?.terms_of_service,
          transactionLaw:
            getPageData?.sections[6]?.footer?.content?.transaction_law,
        }}
        isFetching={isFetchingPageData}
        fromSignUp
      />
    </>
  );
};

export default Component;
