import { VerifiedAdmin } from '../../../domain/entities/page';
import PageRepository from './../../ports/PageRepository';

export type verifyAdminUseCase = (token: string) => Promise<VerifiedAdmin>;

export const buildVerifyAdmin = (dependencies: {
  pageRepo: PageRepository;
}): verifyAdminUseCase => {
  const { pageRepo } = dependencies;

  const verifyAdmin: verifyAdminUseCase = async token =>
    pageRepo.verifyAdmin(token);

  return verifyAdmin;
};
