import React, { useState } from 'react';
import styled from 'styled-components';
import { TextInput } from '../../atoms/TextInput';
import { MenuItem, Select } from '@mui/material';
import theme from '../../../constants/themes';
import stripeCountries from './stripe-countries.json';
import { Button } from '../../atoms/Button';

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const CardLabel = styled.label`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 175%;
  color: ${theme.colors.textColor};
`;

const CardExpiryWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1em;
`;

const StyledForm = styled.form`
  width: 500px;
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

const InputWrapper = styled.div`
  padding: 10px 14px;
  background: ${theme.colors.white};
  border: 1px solid ${theme.colors.text02};
  border-radius: 4px;
`;

const StyledTextInput = styled(TextInput)`
  border: none;
  padding: 0;
  color: ${theme.colors.text02};
  font-weight: 400;
  font-family: 'Inter';
  font-size: 16px;
  line-height: 28px;
  background-color: ${theme.colors.white};
  height: 28px;
  && {
    outline: none;
  }
`;

const StyledSelect = styled(Select)`
  width: 100%;
  height: 28px;
  && {
    font-size: 16px;
    font-family: 'Inter';
    line-height: 28px;
    font-weight: 400;
    color: ${theme.colors.text02};
    .MuiSelect-select {
      padding: 0;
    }
  }
  fieldset {
    border: none;
  }
`;

const StyledButton = styled(Button)<{ bgColor?: string; hoverColor?: string }>`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  border: none;
  ${({ bgColor }) => (bgColor ? `background-color: ${bgColor}` : '')};

  :hover {
    border: none;
    background-color: ${({ hoverColor }) => hoverColor || ''};
  }
`;

const StyledMenuItem = styled(MenuItem)``;

export type Props = {
  themeColor?: string;
  hoverColor?: string;
  buttonTitle: string;
};

const Component = ({
  themeColor,
  buttonTitle,
  hoverColor,
}: Props): React.ReactElement => {
  const [selectedCountry, setSelectedCountry] = useState<string>('none');

  return (
    <StyledForm>
      <CardWrapper>
        <CardLabel>カード番号</CardLabel>
        <InputWrapper>
          <StyledTextInput placeholder="4242 4242 4242 4242" />
        </InputWrapper>
      </CardWrapper>
      <CardExpiryWrapper>
        <CardWrapper>
          <CardLabel>カード番号</CardLabel>
          <InputWrapper>
            <StyledTextInput placeholder="カード番号" />
          </InputWrapper>
        </CardWrapper>

        <CardWrapper>
          <CardLabel>CVC</CardLabel>
          <InputWrapper>
            <StyledTextInput placeholder="CVC" />
          </InputWrapper>
        </CardWrapper>
      </CardExpiryWrapper>
      <CardWrapper>
        <CardLabel>カード番号</CardLabel>
        <InputWrapper>
          <StyledTextInput placeholder="カード所有者名" />
        </InputWrapper>
      </CardWrapper>
      <CardWrapper>
        <CardLabel>国または地域</CardLabel>
        <InputWrapper>
          <StyledSelect
            value={selectedCountry}
            onChange={ev => {
              const value = ev.target.value as string;
              setSelectedCountry(value);
            }}>
            <StyledMenuItem value="none" disabled>
              日本
            </StyledMenuItem>
            {stripeCountries.map(country => {
              return (
                <StyledMenuItem key={country.code} value={country.code}>
                  {country.name}
                </StyledMenuItem>
              );
            })}
          </StyledSelect>
        </InputWrapper>
      </CardWrapper>
      <CardWrapper>
        <StyledButton
          title={buttonTitle}
          bgColor={themeColor}
          hoverColor={hoverColor}
          type="submit"
        />
      </CardWrapper>
    </StyledForm>
  );
};

export default Component;
