import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import { Form } from 'formik';
import { TextInput } from '../../atoms/TextInput';
import { Button } from '../../atoms/Button';
import theme from '../../../constants/themes';
import { InlineErrorToast } from '../../atoms/InlineErrorToast';

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledLabel = styled.span`
  font-family: ${theme.fonts.primary};
  font-style: normal;
  font-weight: 600;
  font-size: 33px;
  line-height: 130%;
  color: ${theme.colors.black};
`;

const StyledInput = styled(TextInput)`
  min-width: 436px;
  height: 44px;
  margin-top: 8px;

  @media ${theme.breakpoints.mobile} {
    width: 100%;
    min-width: 100%;
  }
`;

const EmailAddressLabel = styled.span`
  font-family: ${theme.fonts.primary};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 175%;
  color: ${theme.colors.text02};
`;

const ButtonWrapper = styled.div`
  width: 249px;
  height: 44px;
  border-radius: 5px;
  margin-top: 24px;

  .reissue-btn {
    &:hover {
      background-color: #edbf0e !important;
    }
    &:active {
      background-color: #dfb71c !important;
    }
    &:disabled {
      background-color: #ffde60 !important;
      border-color: #ffde60 !important;
    }
  }
`;

const InputWrapper = styled(Box)`
  margin-top: 12px;

  @media ${theme.breakpoints.mobile} {
    width: 100%;
  }
`;

const ErrorMessage = styled.span`
  width: 436px;
  font-family: ${theme.fonts.primary};
  font-weight: 400;
  font-size: 13px;
  line-height: 14px;
  color: ${theme.colors.textError};
  margin-top: 8px;
  text-align: left;

  @media ${theme.breakpoints.mobile} {
    width: 100%;
  }
`;

const ApiErrorWrapper = styled.div`
  width: 436px;
  height: min-content;
  margin-top: 16px;
`;

export type Props = {
  onPressReissue: () => void;
  onChangeEmail: (email: string) => void;
  email: string;
  onBlurEmail: (e: React.FocusEvent<HTMLInputElement>) => void;
  errors: {
    email?: string;
    apiError?: string;
  };
  isLoading?: boolean;
  setFieldTouched: (
    field: string,
    isTouched?: boolean,
    shouldValidate?: boolean,
  ) => void;
};

const Component = ({
  onPressReissue,
  onChangeEmail,
  email,
  onBlurEmail,
  errors,
  isLoading,
  setFieldTouched,
}: Props): React.ReactElement => {
  const [apiError, setApiError] = useState<string | undefined>(undefined);

  const handleOnPressReissue = () => {
    setApiError('');
    onPressReissue();
  };

  useEffect(() => {
    setApiError(errors?.apiError);
  }, [errors?.apiError]);

  return (
    <StyledForm>
      <StyledLabel>パスワード再発行</StyledLabel>
      {apiError && (
        <ApiErrorWrapper>
          <InlineErrorToast
            message={apiError}
            onClose={() => setApiError('')}
          />
        </ApiErrorWrapper>
      )}
      <InputWrapper>
        <EmailAddressLabel>メールアドレス</EmailAddressLabel>
        <StyledInput
          onChangeText={(value: string) => {
            setFieldTouched('email');
            onChangeEmail(value);
          }}
          isError={!!errors.email}
          value={email}
          onBlur={onBlurEmail}
        />
      </InputWrapper>
      {errors?.email && <ErrorMessage>{errors.email}</ErrorMessage>}
      <ButtonWrapper>
        <Button
          disabled={isLoading}
          className="reissue-btn"
          title="再発行"
          onPress={handleOnPressReissue}
          type="submit"
          style={{
            backgroundColor: theme.colors.gameCardBaseColor,
            border: `thin solid ${theme.colors.gameCardBaseColor}`,
          }}
          isLoading={isLoading}
        />
      </ButtonWrapper>
    </StyledForm>
  );
};

export default Component;
