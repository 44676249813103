import {
  buildFetchBoardCustomers,
  fetchBoardCustomersUseCase,
} from './fetch-board-customers';
import {
  buildFetchBoardCustomer,
  fetchBoardCustomerUseCase,
} from './fetch-board-customer';
import {
  buildFetchBoardByCode,
  fetchBoardByCodeUseCase,
} from './fetch-board-by-code';
import { buildUpdateBoard, updateBoardUseCase } from './update-board';
import { buildSaveBoard, saveBoardUseCase } from './save-board';
import { buildFetchBoards, fetchBoardsUseCase } from './fetch-boards';
import {
  buildInviteCustomers,
  inviteCustomersUseCase,
} from './invite-customer';
import { buildRemoveCustomer, removeCustomerUseCase } from './remove-customer';
import {
  buildUpdateCustomerRole,
  updateCustomerRoleUseCase,
} from './update-customer-role';
import { buildUploadMedia, uploadMediaUseCase } from './upload-media';
import { buildFetchMedia, fetchMediaUseCase } from './fetch-media';
import {
  buildSetBoardAsFave,
  setBoardAsFaveUseCase,
} from './set-board-as-favorite';
import {
  buildUnfavoriteBoard,
  unfavoriteBoardUseCase,
} from './unfavorite-board';
import { buildCreateBoard, createBoardUseCase } from './create-board';
import {
  buildFetchTemplateList,
  fetchTemplateListUseCase,
} from './fetch-template-list';
import { buildVoteCustomer, voteCustomerUseCase } from './vote-customer';
import {
  buildAcceptBoardInvitation,
  acceptBoardInvitationUseCase,
} from './accept-board-invitation';

import BoardRepository from '../../ports/BoardRepository';

export default (dependencies: {
  boardRepo: BoardRepository;
}): {
  fetchBoardCustomers: fetchBoardCustomersUseCase;
  fetchBoardCustomer: fetchBoardCustomerUseCase;
  fetchBoardByCode: fetchBoardByCodeUseCase;
  updateBoard: updateBoardUseCase;
  saveBoard: saveBoardUseCase;
  fetchBoards: fetchBoardsUseCase;
  inviteCustomers: inviteCustomersUseCase;
  removeCustomer: removeCustomerUseCase;
  updateCustomerRole: updateCustomerRoleUseCase;
  uploadMedia: uploadMediaUseCase;
  fetchMedia: fetchMediaUseCase;
  setBoardAsFave: setBoardAsFaveUseCase;
  unfavoriteBoard: unfavoriteBoardUseCase;
  createBoard: createBoardUseCase;
  fetchTemplateList: fetchTemplateListUseCase;
  voteCustomer: voteCustomerUseCase;
  acceptBoardInvitation: acceptBoardInvitationUseCase;
} => {
  const { boardRepo } = dependencies;
  const fetchBoardCustomers = buildFetchBoardCustomers({ boardRepo });
  const fetchBoardCustomer = buildFetchBoardCustomer({ boardRepo });
  const fetchBoardByCode = buildFetchBoardByCode({ boardRepo });
  const updateBoard = buildUpdateBoard({ boardRepo });
  const saveBoard = buildSaveBoard({ boardRepo });
  const fetchBoards = buildFetchBoards({ boardRepo });
  const inviteCustomers = buildInviteCustomers({ boardRepo });
  const removeCustomer = buildRemoveCustomer({ boardRepo });
  const updateCustomerRole = buildUpdateCustomerRole({ boardRepo });
  const fetchMedia = buildFetchMedia({ boardRepo });
  const uploadMedia = buildUploadMedia({ boardRepo });
  const setBoardAsFave = buildSetBoardAsFave({ boardRepo });
  const unfavoriteBoard = buildUnfavoriteBoard({ boardRepo });
  const createBoard = buildCreateBoard({ boardRepo });
  const fetchTemplateList = buildFetchTemplateList({ boardRepo });
  const voteCustomer = buildVoteCustomer({ boardRepo });
  const acceptBoardInvitation = buildAcceptBoardInvitation({ boardRepo });

  return {
    fetchBoardCustomers,
    fetchBoardCustomer,
    fetchBoardByCode,
    updateBoard,
    saveBoard,
    fetchBoards,
    inviteCustomers,
    removeCustomer,
    updateCustomerRole,
    fetchMedia,
    uploadMedia,
    setBoardAsFave,
    unfavoriteBoard,
    createBoard,
    fetchTemplateList,
    voteCustomer,
    acceptBoardInvitation,
  };
};
