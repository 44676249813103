import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { BoardEditorPage } from '../../components/pages/BoardEditor';
import { LoginPage } from '../../components/pages/Login';
import { SignUpPage } from '../../components/pages/SignUp';
import { ForgotPasswordPage } from '../../components/pages/ForgotPassword';
import { ResetPasswordPage } from '../../components/pages/ResetPassword';
import { MyBoardPage } from '../../components/pages/MyBoard';
import { MemberManagementPage } from '../../components/pages/MemberManagement';
import { PlanBillingPage } from '../../components/pages/PlanBilling';
import { VerifyEmailPage } from '../../components/pages/VerifyEmail';
import { EmailVerifiedPage } from '../../components/pages/EmailVerified';
import { TopPage } from '../../components/pages/Top';
import { PlanSelectPage } from '../../components/pages/PlanSelect';
import { useGlobalState } from '../../hooks/global';
import { useQuery } from '../../utils/query';
import storageKeys from '../../constants/localStorage';
import { useDependencies } from '../../hooks';
import { PaymentPage } from '../../components/pages/Payment';
import { Page } from '../../components/pages/Page';

const ProtectedRoutes = () => {
  return (
    <Routes>
      <Route path="my-board">
        <Route path="personal" element={<MyBoardPage />} />
        <Route path="team">
          <Route path="" element={<div />} />
          <Route path=":teamId" element={<MyBoardPage />} />
        </Route>
        <Route path="profile" element={<MyBoardPage />} />
        <Route path="plan-billing" element={<MyBoardPage />} />
        <Route path="member-management" element={<MemberManagementPage />}>
          <Route path="" element={<div />} />
          <Route path=":teamId" element={<MemberManagementPage />} />
        </Route>
        <Route path="" element={<MyBoardPage />} />
      </Route>
      <Route path="board">
        <Route path="" element={<Navigate to="/my-board/personal" />} />
        <Route path=":boardCode" element={<BoardEditorPage />} />
      </Route>
      <Route path="plan-select" element={<PlanSelectPage />} />
      <Route path="payment" element={<PaymentPage />} />
      <Route path="top" element={<TopPage />} />
      <Route path="commercial-rule" element={<Page />} />
      <Route path="terms" element={<Page />} />
      <Route path="privacy" element={<Page />} />
      <Route path="feature">
        <Route path="page-:pageNum" element={<Page />} />
      </Route>
      <Route path="/*" element={<Navigate to="/my-board" />} />
      <Route path="/" element={<TopPage />} />
    </Routes>
  );
};

const UnprotectedRoutes = () => (
  <Routes>
    <Route path="signin" element={<LoginPage />} />
    <Route path="signup" element={<SignUpPage />} />
    <Route path="forgot-password" element={<ForgotPasswordPage />} />
    <Route path="reset-password" element={<ResetPasswordPage />} />
    <Route path="verify-email" element={<VerifyEmailPage />} />
    <Route path="email-verify-success" element={<EmailVerifiedPage />} />
    <Route path="/" element={<TopPage />} />
    <Route path="plan-select" element={<PlanSelectPage />} />
    <Route path="plan-billing" element={<PlanBillingPage />} />
    <Route path="payment" element={<PaymentPage />} />
    <Route path="commercial-rule" element={<Page />} />
    <Route path="terms" element={<Page />} />
    <Route path="privacy" element={<Page />} />
    <Route path="feature">
      <Route path="page-:pageNum" element={<Page />} />
    </Route>
    <Route path="/*" element={<Navigate to="/" />} />
  </Routes>
);

const RootNavigator = (): React.ReactElement => {
  const {
    useCurrentUser: { currentUser, setCurrentUser },
  } = useGlobalState();
  const { localStorageInteractor } = useDependencies();
  const { setItem } = localStorageInteractor;
  const invitationCode = useQuery('invitation_code');
  const path = window.location.pathname.split('/');
  const search = window.location.search;

  if (new URLSearchParams(search).get('isNotAuth')) {
    setCurrentUser(undefined);
  } else {
    const fromBoard = window.history.state?.fromBoard;
    setItem(storageKeys.INVITATION_CODE_STORAGE_KEY, invitationCode || '');

    path[1] === 'board' &&
      path[2] &&
      !currentUser?.user?.isVerified &&
      !fromBoard &&
      setItem(storageKeys.UNAUTHORIZED_BOARD_STORAGE_KEY, 'invalid');
  }

  return (
    <React.Suspense fallback={<div>loading routes...</div>}>
      <BrowserRouter>
        {currentUser?.user?.isVerified ? (
          <ProtectedRoutes />
        ) : (
          <UnprotectedRoutes />
        )}
      </BrowserRouter>
    </React.Suspense>
  );
};

export default RootNavigator;
