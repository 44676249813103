import { BoardCustomers } from '../../../domain/entities/board';
import BoardRepository from '../../ports/BoardRepository';

export type fetchBoardCustomerUseCase = (
  customerId: number,
  boardCode: string,
) => Promise<BoardCustomers>;

export const buildFetchBoardCustomer = (dependencies: {
  boardRepo: BoardRepository;
}): fetchBoardCustomerUseCase => {
  const { boardRepo } = dependencies;

  const fetchBoardCustomer: fetchBoardCustomerUseCase = async (
    customerId,
    boardCode,
  ) => boardRepo.fetchBoardCustomer(customerId, boardCode);

  return fetchBoardCustomer;
};
