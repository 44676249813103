import { EditorState } from 'draft-js';
import { useCallback, useState } from 'react';
import { EditorRef } from './types';

export const useEditorRef = (
  ref: EditorRef,
  editorState: EditorState,
  setEditorState: (params: EditorState) => void,
): { handleFocus: () => void; handleBlur: () => void; isFocused: boolean } => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const handleFocus = useCallback(() => {
    ref?.current?.focus();
    const selection = editorState.getSelection();
    if (selection.isCollapsed()) {
      const focusedToEnd = EditorState.moveFocusToEnd(editorState);
      setEditorState(focusedToEnd);
    }
    setIsFocused(true);
  }, [editorState, setEditorState]);

  const handleBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  return { handleFocus, handleBlur, isFocused };
};

export const useEditorState = (
  editorState: EditorState,
  setEditorState: (params: EditorState) => void,
): { setFocusEnd: () => void } => {
  const setFocusEnd = useCallback(() => {
    const focusedToEnd = EditorState.moveFocusToEnd(editorState);
    setEditorState(focusedToEnd);
  }, [editorState, setEditorState]);
  return { setFocusEnd };
};
