import { createContext, useContext } from 'react';
import { GenericResponse } from '../../domain/entities/rest';
import {
  MyBoardTeam,
  CreateTeamSuccess,
  TeamMember,
  InviteTeamMemberSuccess,
  DeleteTeamMemberSuccess,
} from '../../domain/entities/team';

export type TeamHooks = {
  useTeam: () => {
    fetchMyBoardTeams: () => Promise<MyBoardTeam[]>;
    createMyBoardTeam: (teamName: string) => Promise<CreateTeamSuccess>;
    fetchTeamMembers: (
      teamId: number,
      search?: string,
    ) => Promise<TeamMember[]>;
    inviteTeamMember: (
      teamId: string,
      emails: string[],
    ) => Promise<InviteTeamMemberSuccess>;
    deleteTeamMember: (
      teamId: string,
      customerId: string,
    ) => Promise<DeleteTeamMemberSuccess>;
    updateTeamMember: (
      teamId: number,
      customerId: number,
      isOwner: number,
    ) => Promise<GenericResponse>;
  };
};

export const TeamHooksContext = createContext<TeamHooks | null>(null);

export const useTeamHooks = (): TeamHooks => {
  const context = useContext(TeamHooksContext);
  if (!context) {
    throw new Error(`Context not instantiated`);
  }
  return context;
};
