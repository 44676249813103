export const removeUndefined = (obj: {
  [key: string]: unknown;
}): { [key: string]: unknown } => {
  const newValue = Object.entries(obj).reduce((acc, cur) => {
    const [key, value] = cur;
    if (typeof value === 'undefined') {
      return { ...acc };
    }
    return {
      ...acc,
      [key]: value,
    };
  }, {});
  return newValue;
};

export const arrayToObject = (
  arr: Object[],
  key = 'id',
  mapFunction: Function = (obj: Object): Object => obj,
): Object =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  arr.reduce((accumulator: any, obj: any): Object => {
    if (obj[key]) {
      return {
        ...accumulator,
        [obj[key]]: mapFunction(obj, accumulator[obj[key]]),
      };
    }

    return accumulator;
  }, {});

export const createReactorObj = (
  reactorsPair = {},
): { [key: string]: string } => {
  const reactorObj = Object.entries(reactorsPair || {}).reduce((acc, cur) => {
    const [reactorIdPair, reactorName] = cur;
    const reactorId = reactorIdPair.split('-')?.[1];
    return {
      ...acc,
      [reactorId]: reactorName,
    };
  }, {});
  return reactorObj;
};
