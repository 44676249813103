import { useCallback } from 'react';
import {
  MyBoardTeam,
  CreateTeamSuccess,
  TeamMember,
  InviteTeamMemberSuccess,
  DeleteTeamMemberSuccess,
} from '../../domain/entities/team';
import { useDependencies } from '..';
import { TeamHooks } from '.';
import { GenericResponse } from '../../domain/entities/rest';

export const useTeam: TeamHooks['useTeam'] = (): {
  fetchMyBoardTeams: () => Promise<MyBoardTeam[]>;
  createMyBoardTeam: (teamName: string) => Promise<CreateTeamSuccess>;
  fetchTeamMembers: (teamId: number, search?: string) => Promise<TeamMember[]>;
  inviteTeamMember: (
    teamId: string,
    emails: string[],
  ) => Promise<InviteTeamMemberSuccess>;
  deleteTeamMember: (
    teamId: string,
    customerId: string,
  ) => Promise<DeleteTeamMemberSuccess>;
  updateTeamMember: (
    teamId: number,
    customerId: number,
    isOwner: number,
  ) => Promise<GenericResponse>;
} => {
  const { teamInteractor } = useDependencies();
  const { fetchTeams, createTeam } = teamInteractor;

  const fetchMyBoardTeams = useCallback(async () => {
    const response = await fetchTeams();

    return response;
  }, []);

  const createMyBoardTeam = useCallback(async (teamName: string) => {
    const response = await createTeam(teamName);

    return response;
  }, []);

  const fetchTeamMembers = useCallback(
    async (teamId: number, search?: string) => {
      const response = await teamInteractor.fetchTeamMembers(teamId, search);

      return response;
    },
    [],
  );

  const inviteTeamMember = useCallback(
    async (teamId: string, emails: string[]) => {
      const response = await teamInteractor.inviteTeamMember(teamId, emails);
      return response;
    },
    [],
  );

  const deleteTeamMember = useCallback(
    async (teamId: string, customerId: string) => {
      const response = await teamInteractor.deleteTeamMember(
        teamId,
        customerId,
      );

      return response;
    },
    [],
  );

  const updateTeamMember = useCallback(
    async (teamId: number, customerId: number, isOwner: number) => {
      const response = await teamInteractor.updateTeamMember(
        teamId,
        customerId,
        isOwner,
      );

      return response;
    },
    [],
  );

  return {
    fetchMyBoardTeams,
    createMyBoardTeam,
    fetchTeamMembers,
    inviteTeamMember,
    deleteTeamMember,
    updateTeamMember,
  };
};
