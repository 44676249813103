import { useCallback } from 'react';
import { useReactFlow } from 'react-flow-renderer';
import { useBoardHooks } from '../../../../../hooks/board';
import { generateUniqueString } from '../../../../../utils/strings';

export const useEdgesUtils = (): {
  insertNewEdges: (
    payload: {
      source: string;
      target: string;
      groupId: string;
    }[],
  ) => void;
} => {
  const { getEdges } = useReactFlow();
  const { edgeState } = useBoardHooks();
  const { setEdges } = edgeState;

  const insertNewEdges = useCallback(
    (
      payload: {
        source: string;
        target: string;
        groupId: string;
      }[],
    ) => {
      const edges = getEdges();
      setEdges(
        edges.concat(
          payload.map(p => ({
            id: `edge-${generateUniqueString()}`,
            source: p.source,
            target: p.target,
            type: 'custom',
            data: {
              groupId: p.groupId,
            },
          })),
        ),
      );
    },
    [],
  );

  return { insertNewEdges };
};
