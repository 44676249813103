import { HttpAdapter } from '../../usecases/ports/HttpAdapter';
import PlanRepository from '../../usecases/ports/PlanRepository';
import { Plan } from '../../domain/entities/plan';
import { GenericResponse } from '../../domain/entities/rest';
import { URLsType } from '../../constants/urls';

export default class PlanRepositoryImpl implements PlanRepository {
  httpAdapter: HttpAdapter;
  urls: URLsType['plans'];

  constructor(httpAdapter: HttpAdapter, urls: URLsType['plans']) {
    this.httpAdapter = httpAdapter;
    this.urls = urls;
  }

  fetchPlans = async (): Promise<Plan[]> => {
    const response = await this.httpAdapter.get(this.urls.fetchPlans, {});
    const mappedDataPlan: Plan[] = response.data.data.map(
      (data: {
        id: number;
        type: string;
        description: string;
        pricing: string;
        content: string[];
        button_name: string;
        customer_limit: number;
        channel_limit: number;
        price: number;
        theme_color: string;
      }) => {
        return {
          id: data.id,
          type: data.type,
          description: data.description,
          pricing: data.pricing,
          content: data.content,
          buttonName: data.button_name,
          customerLimit: data.customer_limit,
          channelLimit: data.channel_limit,
          price: data.price,
          themeColor: data.theme_color,
        };
      },
    );
    return mappedDataPlan;
  };

  selectFreePlan = async (
    planId: number,
    customerId: number,
  ): Promise<GenericResponse> => {
    const response = await this.httpAdapter.put(
      this.urls.selectFreePlan(planId),
      {
        customer_id: customerId,
      },
    );

    return response.data;
  };
}
