import React from 'react';

type Props = {
  [key: string]: string | number | Function;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="23"
      height="24"
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M20.1308 9.41256C20.0732 9.24274 19.967 9.09353 19.8256 8.98335C19.6841 8.87316 19.5134 8.80681 19.3347 8.79251L14.1055 8.377L11.8427 3.36798C11.7706 3.20665 11.6534 3.06963 11.5052 2.97344C11.357 2.87726 11.1841 2.82602 11.0074 2.82593C10.8307 2.82583 10.6577 2.87687 10.5094 2.9729C10.3611 3.06892 10.2437 3.20582 10.1715 3.36707L7.90866 8.377L2.67951 8.79251C2.50382 8.80642 2.33586 8.87066 2.19572 8.97754C2.05559 9.08442 1.94921 9.2294 1.88931 9.39516C1.82941 9.56091 1.81854 9.74041 1.85799 9.91218C1.89744 10.0839 1.98553 10.2407 2.11175 10.3637L5.97605 14.1308L4.60937 20.0488C4.56788 20.2279 4.58118 20.4154 4.64755 20.5869C4.71392 20.7584 4.8303 20.9059 4.98159 21.0105C5.13288 21.115 5.31208 21.1716 5.49596 21.173C5.67983 21.1744 5.85988 21.1205 6.01274 21.0183L11.0071 17.6888L16.0014 21.0183C16.1577 21.1221 16.3419 21.1755 16.5294 21.1713C16.7169 21.1672 16.8986 21.1058 17.0502 20.9953C17.2017 20.8848 17.3158 20.7306 17.3771 20.5533C17.4383 20.3761 17.4438 20.1843 17.3929 20.0039L15.7152 14.1336L19.8758 10.3894C20.1482 10.1436 20.2482 9.76019 20.1308 9.41256V9.41256Z"
        fill="#E77C40"
      />
    </svg>
  );
};

export default Component;
