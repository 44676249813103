import React from 'react';
import styled from 'styled-components';
import { Redo, Undo } from '../../atoms/Icons';
import Plus from '../../atoms/Icons/Plus';
import Minus from '../../atoms/Icons/Minus';
import theme from '../../../constants/themes';
import { useViewport } from 'react-flow-renderer';

const Wrapper = styled.span`
  border: solid ${theme.colors.primary} 4px;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.25);
  border-radius: 45px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.colors.white};
`;

const StyledUndo = styled(Undo)`
  margin-right: 5px;
`;

const UndoRedoWrapper = styled.span`
  display: inline-block;
  padding: 12px;
  svg {
    cursor: pointer;
  }
`;

const ZoomWrapper = styled.span`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  margin: 2px 6px 4px 6px;
  .plus-icon {
    cursor: pointer;
    height: 10px;
    width: 10px;
    path {
      fill: black;
    }
  }
  .minus-icon {
    cursor: pointer;
    height: 9px;
    path {
      fill: black;
    }
  }
  .percent-label {
    margin: 5px 0 0 0;
  }
`;

export type Props = {
  className?: string;
  onClickZoomIn: () => void;
  onClickZoomOut: () => void;
  onClickUndo: () => void;
  onClickRedo: () => void;
};

const Component = ({
  className,
  onClickZoomIn,
  onClickZoomOut,
  onClickRedo,
  onClickUndo,
}: Props): React.ReactElement => {
  const { zoom } = useViewport();
  const zoomLevel = Math.floor(zoom * 100);
  return (
    <Wrapper className={className}>
      <UndoRedoWrapper>
        <StyledUndo onClick={onClickUndo} />
        <Redo onClick={onClickRedo} />
      </UndoRedoWrapper>
      <ZoomWrapper>
        <Plus className="plus-icon" onClick={onClickZoomIn} />
        <span className="percent-label">{zoomLevel}%</span>
        <Minus className="minus-icon" onClick={onClickZoomOut} />
      </ZoomWrapper>
    </Wrapper>
  );
};

export default Component;
