import React, { SVGProps } from 'react';

type Props = SVGProps<SVGSVGElement>;

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="12"
      height="16"
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 5.5C1.36739 5.5 1.24021 5.55268 1.14645 5.64645C1.05268 5.74021 1 5.86739 1 6V14C1 14.1326 1.05268 14.2598 1.14645 14.3536C1.24021 14.4473 1.36739 14.5 1.5 14.5H10.5C10.6326 14.5 10.7598 14.4473 10.8536 14.3536C10.9473 14.2598 11 14.1326 11 14V6C11 5.86739 10.9473 5.74021 10.8536 5.64645C10.7598 5.55268 10.6326 5.5 10.5 5.5H8.5C8.36739 5.5 8.24021 5.44732 8.14645 5.35355C8.05268 5.25979 8 5.13261 8 5C8 4.86739 8.05268 4.74021 8.14645 4.64645C8.24021 4.55268 8.36739 4.5 8.5 4.5H10.5C10.8978 4.5 11.2794 4.65804 11.5607 4.93934C11.842 5.22064 12 5.60218 12 6V14C12 14.3978 11.842 14.7794 11.5607 15.0607C11.2794 15.342 10.8978 15.5 10.5 15.5H1.5C1.10218 15.5 0.720644 15.342 0.43934 15.0607C0.158035 14.7794 0 14.3978 0 14V6C0 5.60218 0.158035 5.22064 0.43934 4.93934C0.720644 4.65804 1.10218 4.5 1.5 4.5H3.5C3.63261 4.5 3.75979 4.55268 3.85355 4.64645C3.94732 4.74021 4 4.86739 4 5C4 5.13261 3.94732 5.25979 3.85355 5.35355C3.75979 5.44732 3.63261 5.5 3.5 5.5H1.5Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.646 11.354C5.69245 11.4006 5.74762 11.4375 5.80837 11.4627C5.86911 11.4879 5.93423 11.5009 6 11.5009C6.06577 11.5009 6.13089 11.4879 6.19163 11.4627C6.25238 11.4375 6.30755 11.4006 6.354 11.354L9.354 8.354C9.44789 8.26011 9.50063 8.13278 9.50063 8C9.50063 7.86722 9.44789 7.73989 9.354 7.646C9.26011 7.55211 9.13278 7.49937 9 7.49937C8.86722 7.49937 8.73989 7.55211 8.646 7.646L6.5 9.793V1C6.5 0.867392 6.44732 0.740215 6.35355 0.646447C6.25979 0.552678 6.13261 0.5 6 0.5C5.86739 0.5 5.74021 0.552678 5.64645 0.646447C5.55268 0.740215 5.5 0.867392 5.5 1V9.793L3.354 7.646C3.26011 7.55211 3.13278 7.49937 3 7.49937C2.86722 7.49937 2.73989 7.55211 2.646 7.646C2.55211 7.73989 2.49937 7.86722 2.49937 8C2.49937 8.13278 2.55211 8.26011 2.646 8.354L5.646 11.354Z"
        fill="white"
      />
    </svg>
  );
};

export default Component;
