import { HttpAdapter } from '../../usecases/ports/HttpAdapter';
import {
  AxiosResponse,
  AxiosError,
  AxiosStatic,
  AxiosInstance,
  AxiosRequestConfig,
} from 'axios';
import i18n from '../../constants/words';

const isRunning = {} as { [key: string]: AbortController | null };
export default class HttpAdapterAxiosImpl implements HttpAdapter {
  token?: string;
  axios: AxiosInstance;

  constructor(axios: AxiosStatic, baseURL?: string) {
    if (baseURL) {
      this.axios = axios.create({
        baseURL,
      });
    } else {
      this.axios = axios;
    }
  }

  checkAuthError = (error: AxiosError): AxiosError => {
    const data = error.response?.data as any;
    const statusCode = data?.status_code;
    if (statusCode && statusCode === 401) {
      if (window.location.pathname !== '/signin') {
        window.location.href = '/signin?isNotAuth=true';
      }
    }

    return error;
  };

  cancelOldRequest(url: string): void {
    if (isRunning[url]) {
      (isRunning[url] as AbortController).abort();
      isRunning[url] = null;
    }
  }

  get = async (
    url: string,
    options: Object,
    isS3?: boolean,
  ): Promise<AxiosResponse> => {
    try {
      // NOTE: allow making get request to same endpoint but different params. remove if we wont allow this
      const queryKey = `get_${url}?${JSON.stringify(options)}`;
      this.cancelOldRequest(queryKey);
      let config = options;
      if (!isS3) {
        config = this.token
          ? { ...options, headers: { Authorization: `Bearer ${this.token}` } }
          : options;
      }
      isRunning[queryKey] = new AbortController();

      return await this.axios.get(url, {
        ...config,
        signal: (isRunning[queryKey] as AbortController).signal,
      });
    } catch (err) {
      throw this.checkAuthError(err as AxiosError);
    }
  };

  post = async (
    url: string,
    body: Object,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse> => {
    try {
      this.cancelOldRequest(`post_${url}`);

      const newConfig = this.token
        ? { ...config, headers: { Authorization: `Bearer ${this.token}` } }
        : { ...config };

      isRunning[`post_${url}`] = new AbortController();

      return await this.axios.post(url, body, {
        ...newConfig,
        signal: (isRunning[`post_${url}`] as AbortController).signal,
      });
    } catch (err) {
      const error = err as AxiosError;

      if (!error.response) {
        throw Error(i18n.t('networkError'));
      } else {
        throw this.checkAuthError(error);
      }
    }
  };

  patch = async (url: string, body: Object): Promise<AxiosResponse> => {
    try {
      this.cancelOldRequest(`patch_${url}`);

      const config = this.token
        ? { headers: { Authorization: `Bearer ${this.token}` } }
        : {};
      isRunning[`patch_${url}`] = new AbortController();
      return await this.axios.patch(url, body, {
        ...config,
        signal: (isRunning[`patch_${url}`] as AbortController).signal,
      });
    } catch (err) {
      throw this.checkAuthError(err as AxiosError);
    }
  };

  put = async (url: string, body: Object): Promise<AxiosResponse> => {
    try {
      this.cancelOldRequest(`put_${url}`);

      const config = this.token
        ? { headers: { Authorization: `Bearer ${this.token}` } }
        : {};
      isRunning[`put_${url}`] = new AbortController();
      return await this.axios.put(url, body, {
        ...config,
        signal: (isRunning[`put_${url}`] as AbortController).signal,
      });
    } catch (err) {
      throw this.checkAuthError(err as AxiosError);
    }
  };

  delete = async (url: string, options: Object): Promise<AxiosResponse> => {
    try {
      this.cancelOldRequest(`delete_${url}`);

      const config = this.token
        ? { ...options, headers: { Authorization: `Bearer ${this.token}` } }
        : options;
      isRunning[`delete_${url}`] = new AbortController();
      return await this.axios.delete(url, {
        ...config,
        signal: (isRunning[`delete_${url}`] as AbortController).signal,
      });
    } catch (err) {
      throw this.checkAuthError(err as AxiosError);
    }
  };

  setToken = (token: string): void => {
    this.token = token;
  };
}
