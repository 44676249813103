import React from 'react';
import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';
import theme from '../../../constants/themes';
import { isWindowsOS } from '../../../utils/booleans';
import { SxProps } from '@mui/material';

const themes = {
  primary: {
    enabled: {
      backgroundColor: theme.colors.primaryBtn,
      color: theme.colors.white,
      border: `thin solid ${theme.colors.primaryBtn}`,
    },
    disabled: {
      backgroundColor: theme.colors.disabledPrimaryBtn,
      color: theme.colors.white,
      border: theme.colors.disabledPrimaryBtn,
    },
    hover: {
      backgroundColor: theme.colors.hoverPrimaryBtn,
      color: theme.colors.white,
      border: `thin solid ${theme.colors.hoverPrimaryBtn}`,
    },
    active: {
      backgroundColor: theme.colors.activePrimaryBtn,
      color: theme.colors.white,
      border: `thin solid ${theme.colors.activePrimaryBtn}`,
    },
  },
  secondary: {
    enabled: {
      backgroundColor: theme.colors.secondaryBtn,
      color: theme.colors.white,
      border: `thin solid ${theme.colors.secondaryBtn}`,
    },
    disabled: {
      backgroundColor: theme.colors.disabledSecondaryBtn,
      color: theme.colors.white,
      border: theme.colors.disabledSecondaryBtn,
    },
    hover: {
      backgroundColor: theme.colors.hoverSecondaryBtn,
      color: theme.colors.white,
      border: `thin solid ${theme.colors.hoverSecondaryBtn}`,
    },
    active: {
      backgroundColor: theme.colors.activeSecondaryBtn,
      color: theme.colors.white,
      border: `thin solid ${theme.colors.activeSecondaryBtn}`,
    },
  },
  lightSecondary: {
    enabled: {
      backgroundColor: theme.colors.notifToastBorder,
      color: theme.colors.white,
      border: `thin solid ${theme.colors.notifToastBorder}`,
    },
    disabled: {
      backgroundColor: theme.colors.disabledLightSecondaryBtn,
      color: theme.colors.white,
      border: theme.colors.disabledLightSecondaryBtn,
    },
    hover: {
      backgroundColor: theme.colors.hoverLightSecondaryBtn,
      color: theme.colors.white,
      border: `thin solid ${theme.colors.hoverLightSecondaryBtn}`,
    },
    active: {
      backgroundColor: theme.colors.activeLightSecondaryBtn,
      color: theme.colors.white,
      border: `thin solid ${theme.colors.activeLightSecondaryBtn}`,
    },
  },
  light: {
    enabled: {
      backgroundColor: theme.colors.lightBtn,
      color: theme.colors.white,
      border: `thin solid ${theme.colors.lightBtn}`,
    },
    disabled: {
      backgroundColor: theme.colors.disabledLightBtn,
      color: theme.colors.white,
      border: theme.colors.disabledLightBtn,
    },
    hover: {
      backgroundColor: theme.colors.hoverLightBtn,
      color: theme.colors.white,
      border: `thin solid ${theme.colors.hoverLightBtn}`,
    },
    active: {
      backgroundColor: theme.colors.activeLightBtn,
      color: theme.colors.white,
      border: `thin solid ${theme.colors.activeLightBtn}`,
    },
  },
  warning: {
    enabled: {
      backgroundColor: theme.colors.warningBtn,
      color: theme.colors.white,
      border: `thin solid ${theme.colors.warningBtn}`,
    },
    disabled: {
      backgroundColor: theme.colors.disabledWarningBtn,
      color: theme.colors.white,
      border: theme.colors.disabledWarningBtn,
    },
    hover: {
      backgroundColor: theme.colors.hoverWarningBtn,
      color: theme.colors.white,
      border: `thin solid ${theme.colors.hoverWarningBtn}`,
    },
    active: {
      backgroundColor: theme.colors.activeWarningBtn,
      color: theme.colors.white,
      border: `thin solid ${theme.colors.activeWarningBtn}`,
    },
  },
  blackHollow: {
    enabled: {
      backgroundColor: theme.colors.white,
      color: theme.colors.textColor,
      border: `thin solid ${theme.colors.black}`,
    },
    disabled: {
      backgroundColor: theme.colors.white,
      color: theme.colors.disabledBlackHollowText,
      border: `thin solid ${theme.colors.disabledBlackHollowBtn}`,
    },
    hover: {
      backgroundColor: theme.colors.dropdownBorder,
      color: theme.colors.textColor,
      border: `thin solid ${theme.colors.black}`,
    },
    active: {
      backgroundColor: theme.colors.dropdownOptionHover,
      color: theme.colors.textColor,
      border: `thin solid ${theme.colors.black}`,
    },
  },
  gray: {
    enabled: {
      backgroundColor: theme.colors.gray,
      color: theme.colors.white,
      border: `thin solid ${theme.colors.gray}`,
    },
    disabled: {
      backgroundColor: theme.colors.gray,
      color: theme.colors.white,
      border: theme.colors.gray,
    },
    hover: {
      backgroundColor: theme.colors.gray,
      color: theme.colors.white,
      border: `thin solid ${theme.colors.gray}`,
    },
    active: {
      backgroundColor: theme.colors.gray,
      color: theme.colors.white,
      border: `thin solid ${theme.colors.gray}`,
    },
  },
};

const StyledButton = styled.button<{
  $buttonTheme:
    | 'primary'
    | 'secondary'
    | 'lightSecondary'
    | 'light'
    | 'warning'
    | 'blackHollow'
    | 'gray';
  isDisabled: boolean;
}>`
  padding: 8px 0px;
  background: #017af5;
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: ${props => (props.isDisabled ? 'auto' : 'pointer')};
  font-family: ${theme.fonts.primary};
  font-weight: 400;
  font-size: 16px;
  line-height: ${isWindowsOS() ? 'normal' : '175%'};
  background-color: ${props =>
    props.isDisabled
      ? themes[props.$buttonTheme].disabled.backgroundColor
      : themes[props.$buttonTheme].enabled.backgroundColor};
  border: ${props =>
    props.isDisabled
      ? themes[props.$buttonTheme].disabled.border
      : themes[props.$buttonTheme].enabled.border};
  color: ${props =>
    props.isDisabled
      ? themes[props.$buttonTheme].disabled.color
      : themes[props.$buttonTheme].enabled.color};

  &:hover {
    background-color: ${props =>
      props.isDisabled
        ? themes[props.$buttonTheme].disabled.backgroundColor
        : themes[props.$buttonTheme].hover.backgroundColor};
    border: ${props =>
      props.isDisabled
        ? themes[props.$buttonTheme].disabled.border
        : themes[props.$buttonTheme].hover.border};
  }

  &:active {
    background-color: ${props =>
      props.isDisabled
        ? themes[props.$buttonTheme].disabled.backgroundColor
        : themes[props.$buttonTheme].active.backgroundColor};
    border: ${props =>
      props.isDisabled
        ? themes[props.$buttonTheme].disabled.border
        : themes[props.$buttonTheme].active.border};
  }
`;

const StyledIcon = styled.div<{ pos: 'left' | 'right'; iconmargin: number }>`
  width: max-content;
  height: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${props =>
    props.pos === 'left' ? `${props.iconmargin}px` : '0px'};
  margin-left: ${props =>
    props.pos === 'left' ? '0px' : `${props.iconmargin}px`};
  margin-bottom: ${isWindowsOS() ? 4 : 0}px;
`;

const StyledLabel = styled.span`
  padding-top: ${isWindowsOS() ? 4 : 0}px;
`;

const LoadingIcon = styled(CircularProgress)`
  width: 29px !important;
  height: 29px !important;
`;

export type Props = {
  theme?:
    | 'primary'
    | 'secondary'
    | 'lightSecondary'
    | 'light'
    | 'warning'
    | 'blackHollow';
  onPress?: React.MouseEventHandler<HTMLButtonElement>;
  title?: string;
  style?: Object;
  disabled?: boolean;
  type?: 'button' | 'reset' | 'submit';
  icon?: React.ReactElement;
  iconPosition?: 'left' | 'right';
  iconMargin?: number;
  className?: string;
  onMouseDown?: React.MouseEventHandler<HTMLButtonElement>;
  isLoading?: boolean;
  spinnerStyle?: SxProps;
  spinnerSize?: number;
};

const Component = ({
  theme = 'primary',
  disabled = false,
  title,
  style,
  onPress,
  type = 'button',
  icon,
  iconPosition = 'left',
  iconMargin = 5,
  className,
  onMouseDown,
  isLoading,
}: Props): React.ReactElement => {
  return (
    <StyledButton
      style={style}
      onClick={onPress}
      disabled={disabled}
      isDisabled={disabled}
      type={type}
      $buttonTheme={theme}
      className={className}
      onMouseDown={onMouseDown}>
      {isLoading ? (
        <>
          {/* <LoadingIcon src={loadingIcon}></LoadingIcon> */}
          <LoadingIcon color="inherit" />
        </>
      ) : icon && iconPosition === 'left' ? (
        <>
          <StyledIcon pos={iconPosition} iconmargin={iconMargin}>
            {icon}
          </StyledIcon>
          <StyledLabel>{title}</StyledLabel>
        </>
      ) : (
        <>
          <StyledLabel>{title}</StyledLabel>
          {icon && (
            <StyledIcon pos={iconPosition} iconmargin={iconMargin}>
              {icon}
            </StyledIcon>
          )}
        </>
      )}
    </StyledButton>
  );
};

export default Component;
