import React from 'react';

type Props = {
  [key: string]: string | number | Function;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.92847 2.16663C1.92847 2.03402 1.98115 1.90684 2.07491 1.81307C2.16868 1.7193 2.29586 1.66663 2.42847 1.66663H19.4285C19.5611 1.66663 19.6883 1.7193 19.782 1.81307C19.8758 1.90684 19.9285 2.03402 19.9285 2.16663V19.1666C19.9285 19.2992 19.8758 19.4264 19.782 19.5202C19.6883 19.6139 19.5611 19.6666 19.4285 19.6666H8.54847C7.95847 19.0656 7.92847 18.2416 7.92847 17.3336V13.6666H4.26147C3.35347 13.6666 2.52947 13.6366 1.92847 13.0466V2.16663ZM0.928467 10.6666V2.16663C0.928467 1.7688 1.0865 1.38727 1.36781 1.10597C1.64911 0.824661 2.03064 0.666626 2.42847 0.666626H19.4285C19.8263 0.666626 20.2078 0.824661 20.4891 1.10597C20.7704 1.38727 20.9285 1.7688 20.9285 2.16663V19.1666C20.9285 19.5645 20.7704 19.946 20.4891 20.2273C20.2078 20.5086 19.8263 20.6666 19.4285 20.6666H7.59547C4.05347 20.6666 0.928467 17.6816 0.928467 13.9996V10.6666ZM6.92847 17.3336C6.92847 18.1886 6.84247 18.9856 7.26947 19.6566C4.50647 19.4866 2.10947 17.1796 1.93847 14.3246C2.60947 14.7526 3.40647 14.6666 4.26147 14.6666H6.92847V17.3336Z"
        fill="#3D4752"
      />
    </svg>
  );
};

export default Component;
