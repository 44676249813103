import { buildFetchPlans, fetchPlansUseCase } from './fetch-plans';
import { buildSelectFreePlan, selectFreePlanUseCase } from './select-free-plan';
import PlanRepository from '../../ports/PlanRepository';

export default (dependencies: {
  planRepo: PlanRepository;
}): {
  fetchPlans: fetchPlansUseCase;
  selectFreePlan: selectFreePlanUseCase;
} => {
  const { planRepo } = dependencies;
  const fetchPlans = buildFetchPlans({ planRepo });
  const selectFreePlan = buildSelectFreePlan({ planRepo });

  return { fetchPlans, selectFreePlan };
};
