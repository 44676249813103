import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { AxiosResponse } from 'axios';
import { Formik, FormikHelpers } from 'formik';
import styled from 'styled-components';
import { InviteTeamMemberSchema } from './validation';
import { InviteTeamMemberValues, DeleteTeamMemberValues } from './types';
import { ModalWrapper } from '../../molecules/ModalWrapper';
import { AddTeamMemberForm } from '../../organisms/AddTeamMemberForm';
import { MyBoardTeam, CreateTeamSuccess } from '../../../domain/entities/team';
import {
  handleTextOverflow,
  cleanSpacesSepratedComma,
} from '../../../utils/strings';
import { useTeamHooks } from '../../../hooks/team';
import { Props as INotification } from '../../atoms/Toast/Component';
import theme from '../../../constants/themes';
import queryKeys from '../../../constants/queryKeys';
import { NoTiStackToast } from '../../atoms/Toast';

const Container = styled.div`
  width: 811px;
  display: flex;
  justify-content: center;
  padding-bottom: 65px;

  @media ${theme.breakpoints.mobile} {
    width: 100%;
  }

  @media ${theme.breakpoints.tablet} {
    width: 100%;
  }
`;

export type Props = {
  isOpen: boolean;
  teamList?: MyBoardTeam[];
  isFetchingTeams?: boolean;
  onClose: () => void;
  onCloseToast: () => void;
  onInviteTeamMember: (toast: INotification) => void;
  onDeleteTeamMember: (toast: INotification) => void;
};

const Component = ({
  isOpen,
  teamList,
  onClose,
  onCloseToast,
  onInviteTeamMember,
  onDeleteTeamMember,
}: Props): React.ReactElement => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { useTeam } = useTeamHooks();
  const { fetchTeamMembers, inviteTeamMember, deleteTeamMember } = useTeam();
  const toastRef = useRef<any>();

  const teams = !!teamList
    ? teamList.map(team => {
        return {
          id: String(team.id),
          label:
            team.name.length <= 15
              ? team.name
              : handleTextOverflow(team.name, 15),
        };
      })
    : [];

  const [selectedTeam, setSelectedTeam] = useState<string>(
    teamList && teamList.length > 0 ? String(teamList[0].id) : 'none',
  );

  useEffect(() => {
    if (teamList && teamList.length > 0) {
      setSelectedTeam(String(teamList[0].id));
    }
  }, [teamList]);

  const {
    data: teamMemberList = [],
    isFetching: isFetchingTeamMembers,
  } = useQuery(
    // NOTE: the 3rd argument is necessary because this hook is also called in templates/MemberManagement/Component.tsx
    [queryKeys.FETCH_TEAM_MEMBERS, selectedTeam, ''],
    () => {
      return fetchTeamMembers(Number(selectedTeam));
    },
    {
      enabled: Number(selectedTeam) > 0,
    },
  );

  const {
    mutate: inviteTeamMemberMutation,
    isLoading: isInvitingTeamMember,
  } = useMutation(
    (values: InviteTeamMemberValues) => {
      return inviteTeamMember(values.teamId, values.email.split(','));
    },
    {
      onSuccess: response => {
        response.data.forEach(data => {
          if (toastRef.current) {
            toastRef.current?.toggleSnackBar(
              `${data.email},  ${data.message}`,
              data.success ? 'success' : 'error',
              'top-right',
            );
          }
        });

        onClose();
        onCloseToast();
      },
      onError: (response: { response: AxiosResponse }) => {
        if (!response.response) {
          onInviteTeamMember({
            isOpen: true,
            message: String(response)
              .replace('Error:', '')
              .trim(),
            type: 'error',
            position: 'top-right',
            onClose: onCloseToast,
          });
        } else {
          const data = response.response?.data as any;
          if (response.response.status === 422) {
            const error = data.errors;
            let msg = '';
            Object.keys(error).forEach(key => {
              msg += error[key];
            });
            onInviteTeamMember({
              isOpen: true,
              message: String(msg),
              type: 'error',
              position: 'top-right',
              onClose: onCloseToast,
            });
          } else if (response.response.status === 500) {
            onInviteTeamMember({
              isOpen: true,
              message: String(data.error),
              type: 'error',
              position: 'top-right',
              onClose: onCloseToast,
            });
          } else {
            onInviteTeamMember({
              isOpen: true,
              message: String(response)
                .replace('Error:', '')
                .trim(),
              type: 'error',
              position: 'top-right',
              onClose: onCloseToast,
            });
          }
        }
      },
    },
  );

  const handleInviteTeamMember = (
    values: InviteTeamMemberValues,
    { resetForm }: FormikHelpers<InviteTeamMemberValues>,
  ) => {
    resetForm();
    inviteTeamMemberMutation({
      teamId: selectedTeam,
      email: cleanSpacesSepratedComma(values.email),
    });
  };

  const {
    mutate: deleteTeamMemberMutation,
    isLoading: isDeletingTeamMember,
  } = useMutation(
    (values: DeleteTeamMemberValues) => {
      return deleteTeamMember(values.teamId, values.customerId);
    },
    {
      onSuccess: response => {
        queryClient.invalidateQueries([
          queryKeys.FETCH_TEAM_MEMBERS,
          selectedTeam,
          '',
        ]);
        onDeleteTeamMember({
          isOpen: true,
          message: response.message,
          type: 'success',
          position: 'top-right',
          onClose: onCloseToast,
        });
      },
      onError: (response: CreateTeamSuccess) => {
        queryClient.invalidateQueries([
          queryKeys.FETCH_TEAM_MEMBERS,
          selectedTeam,
          '',
        ]);
        onDeleteTeamMember({
          isOpen: true,
          message: response.message,
          type: 'error',
          position: 'top-right',
          onClose: onCloseToast,
        });
      },
    },
  );

  const handleClickDelete = useCallback(
    (customerId: string) => {
      deleteTeamMemberMutation({
        teamId: selectedTeam,
        customerId,
      });
    },
    [selectedTeam],
  );

  return (
    <>
      <Formik
        initialValues={{
          teamId: selectedTeam,
          email: '',
        }}
        validationSchema={InviteTeamMemberSchema}
        onSubmit={handleInviteTeamMember}>
        {({
          handleChange,
          setFieldValue,
          handleBlur,
          handleSubmit,
          values,
          isValid,
          errors,
          touched,
          setFieldTouched,
          resetForm,
        }): React.ReactElement => {
          const { email } = values;
          const hasErrors = Object.keys(errors).length > 0;

          return (
            <ModalWrapper
              isOpen={isOpen}
              onClose={() => {
                resetForm();
                setSelectedTeam(
                  String(teams.length > 0 ? teams[0].id : 'none'),
                );
                onClose();
              }}
              title={t('inviteTeamMembers')}>
              <Container>
                <AddTeamMemberForm
                  email={email}
                  onChangeEmail={handleChange('email')}
                  participants={teamMemberList.map(teamMember => {
                    return {
                      id: teamMember.id,
                      name: teamMember.fullName,
                      role: teamMember.memberType,
                      email: teamMember.email,
                    };
                  })}
                  teams={teams}
                  selectedTeam={selectedTeam}
                  disabled={
                    !isValid ||
                    hasErrors ||
                    isFetchingTeamMembers ||
                    isInvitingTeamMember
                  }
                  isFetchingTeamMembers={isFetchingTeamMembers}
                  isDeletingTeamMember={isDeletingTeamMember}
                  onChangeSelectedTeam={newTeamId => {
                    setSelectedTeam(newTeamId);
                    setFieldValue('teamId', newTeamId);
                  }}
                  onClickShare={handleSubmit}
                  onClickDelete={handleClickDelete}
                  onBlurEmail={handleBlur('email')}
                  onBlurTeamId={handleBlur('team-id')}
                  errors={{
                    teamId: (touched.teamId && errors.teamId) || '',
                    email: (touched.email && errors.email) || '',
                  }}
                  setFieldTouched={setFieldTouched}
                  isLoading={isInvitingTeamMember}
                />
              </Container>
            </ModalWrapper>
          );
        }}
      </Formik>
      <NoTiStackToast ref={toastRef} />
    </>
  );
};

export default Component;
