import React, { useState } from 'react';
import styled from 'styled-components';
import theme from '../../../constants/themes';
import { isWindowsOS } from '../../../utils/booleans';

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: max-content;
  height: max-content;
`;

const Icon = styled.svg<{ isChecked: boolean }>`
  fill: none;
  stroke: ${props =>
    props.isChecked ? theme.colors.white : theme.colors.text02};
  stroke-width: ${props => (props.isChecked ? '4px' : '2px')};
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div<{ isChecked: boolean }>`
  display: inline-block;
  max-width: 16px;
  min-width: 16px;
  max-height: 16px;
  min-height: 16px;
  border: ${(props): string =>
    props.isChecked
      ? `thin solid ${theme.colors.secondaryBtn}`
      : `thin solid ${theme.colors.text02}`};
  border-radius: 3px;
  transition: all 150ms;
  cursor: pointer;
  outline: ${props =>
    props.isChecked ? `${theme.colors.secondaryBtn} auto 2px` : 'none'};
  background-color: ${(props): string =>
    props.isChecked ? theme.colors.secondaryBtn : theme.colors.white};

  ${Icon} {
    visibility: ${(props): string => (props.isChecked ? 'visible' : 'hidden')};
  }
`;

const StyledCheckboxLabel = styled.span`
  font-family: ${theme.fonts.primary};
  font-weight: 400;
  font-size: 16px;
  line-height: 175%;
  color: ${theme.colors.text02};
  margin-left: 8px;
  padding-top: ${isWindowsOS() ? 5 : 0}px;
  padding-bottom: ${isWindowsOS() ? 0 : 3}px;
`;

export type Props = {
  isCheckedValue: boolean;
  label: string;
  onChangeCheckbox: (isChecked: boolean) => void;
};

const Component = ({
  isCheckedValue = false,
  label,
  onChangeCheckbox,
}: Props): React.ReactElement => {
  const [isChecked, setIsChecked] = useState(isCheckedValue);

  const handleOnChangeCheckbox = () => {
    onChangeCheckbox(!isChecked);
    setIsChecked(!isChecked);
  };

  return (
    <CheckboxContainer>
      <HiddenCheckbox checked={isChecked} onChange={() => {}} />
      <StyledCheckbox isChecked={isChecked} onClick={handleOnChangeCheckbox}>
        <Icon viewBox="0 0 24 24" isChecked={isChecked}>
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
      {label && <StyledCheckboxLabel>{label}</StyledCheckboxLabel>}
    </CheckboxContainer>
  );
};

export default Component;
