import React, { SVGProps } from 'react';

type Props = SVGProps<SVGSVGElement>;

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="10"
      height="20"
      viewBox="0 0 10 20"
      fill="black"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <rect y="18" width="10" height="2" fill="inherit" />
      <path
        d="M5.0127 15.182C7.3657 15.182 8.7307 13.869 8.7307 10.684V5.38H6.8847V10.853C6.8847 12.842 6.1437 13.518 5.0127 13.518C3.8817 13.518 3.1797 12.842 3.1797 10.853V5.38H1.2687V10.684C1.2687 13.869 2.6597 15.182 5.0127 15.182Z"
        fill="inherit"
      />
      <path d="M0.137695 16.95H9.8617V17.6H0.137695V16.95Z" fill="inherit" />
    </svg>
  );
};

export default Component;
