import TeamRepository from '../../ports/TeamRepository';
import { MyBoardTeam } from '../../../domain/entities/team';

export type fetchTeamsUseCase = () => Promise<MyBoardTeam[]>;

export const buildFetchTeams = (dependencies: {
  teamRepo: TeamRepository;
}): fetchTeamsUseCase => {
  const { teamRepo } = dependencies;

  const fetchTeams: fetchTeamsUseCase = () => teamRepo.fetchTeams();

  return fetchTeams;
};
