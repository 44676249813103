import React from 'react';
import styled from 'styled-components';
import { useViewport } from 'react-flow-renderer';
import { DiagramCursor } from '../../../../atoms/DiagramCursor';
import { useBoardHooks } from '../../../../../hooks/board';

const DiagramViewport = styled.div`
  position: absolute;
  transform-origin: left top;
  z-index: 5;
`;

const CursorsWrapper = styled.div`
  position: relative;
`;

export type Props = {};

const Component = ({}: Props): React.ReactElement => {
  const { x, y, zoom } = useViewport();
  const { cursorState = { cursors: {} } } = useBoardHooks();
  const { cursors } = cursorState;

  const renderCursors = () => {
    return Object.values(cursors).map(item => {
      return (
        <DiagramCursor
          key={item.id}
          userId={item.id}
          posX={item.posX * zoom}
          posY={item.posY * zoom}
          name={item.name}
        />
      );
    });
  };
  return (
    <DiagramViewport
      style={{
        transform: `translate(${x}px, ${y}px)`,
      }}>
      <CursorsWrapper>{renderCursors()}</CursorsWrapper>
    </DiagramViewport>
  );
};

export default Component;
