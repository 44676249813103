import React, { ChangeEvent, KeyboardEvent } from 'react';
import styled from 'styled-components';
import theme from '../../../constants/themes';

const StyledSearchInput = styled.input.attrs(
  (props: object & { bgicon: string }) => ({ ...props }),
)`
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  border: thin solid ${theme.colors.inputBorder};
  background: ${theme.colors.white};
  border-radius: 4px;
  padding: 8px 40px;
  width: 100%;
  height: 37px;
  font-family: ${theme.fonts.primary};
  font-size: 16px;
  line-height: 175%;
  color: ${theme.colors.textColor};
  background-image: ${props => `url(${props.bgicon})`};
  background-repeat: no-repeat;
  background-position: 16px center;

  &:focus {
    outline: #5c5c5c auto 3px;
  }
`;

export type Props = {
  backgroundIcon: string;
  value?: string;
  placeholder?: string;
  className?: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onKeyDown?: (key: string) => void;
  onChangeText?: (text: string) => void;
};

const Component = ({
  backgroundIcon,
  value,
  placeholder,
  className,
  onBlur,
  onChangeText,
  onKeyDown,
}: Props): React.ReactElement => {
  const onChange = (event: ChangeEvent): void => {
    const target = event.target as HTMLInputElement;
    if (onChangeText) {
      onChangeText(target.value);
    }
  };

  const onKeyDownPress = (event: KeyboardEvent): void => {
    if (onKeyDown) {
      onKeyDown(event.key);
    }
  };

  return (
    <StyledSearchInput
      className={className}
      onChange={onChange}
      value={value}
      placeholder={placeholder}
      bgicon={backgroundIcon}
      onBlur={onBlur}
      onKeyDown={onKeyDownPress}
    />
  );
};

export default Component;
