import { useCallback } from 'react';
import { CardHooks } from '.';
import { useDependencies } from '..';
import { RandomCard } from '../../domain/entities//card';

export const useFetchRandomCard: CardHooks['useFetchRandomCard'] = (): {
  fetchRandomCard: (
    exclusionCardIds: (string | number)[],
  ) => Promise<RandomCard>;
} => {
  const { cardInteractor } = useDependencies();
  const fetchRandomCard = useCallback(
    async (exclusionCardIds: (string | number)[]) => {
      const randomCard = await cardInteractor.fetchRandomCard(exclusionCardIds);
      return randomCard;
    },
    [],
  );

  return { fetchRandomCard };
};
