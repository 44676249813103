export default {
  ACCEPT_BOARD_INVITATION: 'ACCEPT_BOARD_INVITATION',
  VERIFY_ADMIN: 'VERIFY_ADMIN',
  FETCH_PAGE: 'FETCH_PAGE',
  FETCH_RANDOM_CARD: 'FETCH_RANDOM_CARD',
  FETCH_CHAT_MESSAGES: 'FETCH_CHAT_MESSAGES',
  FETCH_MEDIA: 'FETCH_MEDIA',
  FETCH_USER_DETAILS: 'FETCH_USER_DETAILS',
  FETCH_PLAN_LIST: 'FETCH_PLAN_LIST',
  FETCH_PAYMENT_HISTORY: 'FETCH_PAYMENT_HISTORY',
  FETCH_TEAM_MEMBERS: 'FETCH_TEAM_MEMBERS',
  FETCH_ROLE_LIST: 'FETCH_ROLE_LIST',
  FETCH_MY_BOARD_LIST: 'FETCH_MY_BOARD_LIST',
  FETCH_MY_BOARD_TEAMS: 'FETCH_MY_BOARD_TEAMS',
  FETCH_TEMPLATE_LIST: 'FETCH_TEMPLATE_LIST',
  FETCH_BOARD_BY_CODE: 'FETCH_BOARD_BY_CODE',
  FETCH_BOARD_DIAGRAM: 'FETCH_BOARD_DIAGRAM',
  FETCH_BOARD_CUSTOMER: 'FETCH_BOARD_CUSTOMER',
  FETCH_BOARD_CUSTOMERS: 'FETCH_BOARD_CUSTOMERS',
};
