import { BoardMedia } from '../../../domain/entities/board';
import BoardRepository from '../../ports/BoardRepository';

export type fetchMediaUseCase = (customerId: number) => Promise<BoardMedia[]>;

export const buildFetchMedia = (dependencies: {
  boardRepo: BoardRepository;
}): fetchMediaUseCase => {
  const { boardRepo } = dependencies;

  const fetchMedia: fetchMediaUseCase = customerId =>
    boardRepo.fetchMedia(customerId);

  return fetchMedia;
};
