import React from 'react';

type Props = {
  [key: string]: string | number | Function;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M8.57143 5.71411L5 2.14268L1.42857 5.71411L0 4.99983L5 -0.000174046L10 4.99983L8.57143 5.71411Z"
        fill="#252B42"
      />
    </svg>
  );
};

export default Component;
