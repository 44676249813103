import React from 'react';

type Props = {
  [key: string]: string | number | Function;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="23"
      height="24"
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M6.03178 14.1308L4.6651 20.0488C4.62278 20.228 4.63549 20.4158 4.70156 20.5877C4.76763 20.7596 4.88399 20.9075 5.03545 21.0122C5.18691 21.1169 5.36643 21.1735 5.55056 21.1746C5.73468 21.1757 5.91486 21.1212 6.06755 21.0183L11.0619 17.6888L16.0562 21.0183C16.2125 21.1221 16.3967 21.1755 16.5842 21.1714C16.7717 21.1673 16.9535 21.1058 17.105 20.9953C17.2565 20.8849 17.3706 20.7306 17.4319 20.5534C17.4931 20.3761 17.4986 20.1844 17.4477 20.0039L15.7701 14.1336L19.9306 10.3894C20.0639 10.2695 20.159 10.113 20.2043 9.93955C20.2497 9.76605 20.2431 9.58308 20.1855 9.41327C20.1279 9.24345 20.0219 9.09424 19.8804 8.98408C19.7389 8.87392 19.5682 8.80764 19.3895 8.79345L14.1603 8.37703L11.8975 3.36801C11.8253 3.20672 11.708 3.06976 11.5598 2.97366C11.4115 2.87755 11.2386 2.82642 11.0619 2.82642C10.8852 2.82642 10.7123 2.87755 10.564 2.97366C10.4157 3.06976 10.2984 3.20672 10.2263 3.36801L7.96347 8.37703L2.73432 8.79254C2.55863 8.80645 2.39067 8.87069 2.25053 8.97757C2.11039 9.08445 2.00401 9.22943 1.94412 9.39518C1.88422 9.56094 1.87335 9.74044 1.9128 9.91221C1.95225 10.084 2.04034 10.2407 2.16656 10.3638L6.03178 14.1308ZM8.64865 10.1629C8.81237 10.15 8.96961 10.0933 9.10387 9.99871C9.23814 9.90414 9.3445 9.77519 9.41179 9.62538L11.0619 5.97387L12.712 9.62538C12.7793 9.77519 12.8856 9.90414 13.0199 9.99871C13.1542 10.0933 13.3114 10.15 13.4751 10.1629L17.1184 10.4518L14.1181 13.1521C13.8576 13.387 13.754 13.7484 13.8494 14.0859L14.9987 18.108L11.5719 15.8231C11.4215 15.7222 11.2444 15.6683 11.0633 15.6683C10.8821 15.6683 10.7051 15.7222 10.5547 15.8231L6.97378 18.2107L7.93688 14.0409C7.97219 13.8876 7.96745 13.7277 7.92311 13.5767C7.87878 13.4257 7.79634 13.2887 7.68372 13.1787L4.89716 10.4619L8.64865 10.1629Z"
        fill="#E77C40"
      />
    </svg>
  );
};

export default Component;
