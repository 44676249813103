import React, { SVGProps } from 'react';

type Props = SVGProps<SVGSVGElement> & { fontColor?: string };

const Component = ({
  fontColor = 'black',
  ...rest
}: Props): React.ReactElement => {
  return (
    <svg
      width="10"
      height="20"
      viewBox="0 0 10 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}>
      <rect y="18" width="10" height="2" fill={fontColor} />
      <path
        d="M0.783937 15H2.73394L4.11194 9.995C4.39794 8.994 4.67094 7.889 4.93094 6.849H4.98294C5.25594 7.876 5.52894 8.994 5.81494 9.995L7.19294 15H9.20794L6.12694 5.38H3.86494L0.783937 15ZM2.69494 12.53H7.27094V11.048H2.69494V12.53Z"
        fill="black"
      />
    </svg>
  );
};

export default Component;
