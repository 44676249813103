import React, { useState } from 'react';
import styled from 'styled-components';
import theme from '../../../constants/themes';
import { Plus } from '../../atoms/Icons';
import { ColorPicker } from '../../molecules/ColorPicker';

const Wrapper = styled.div`
  background-color: ${theme.colors.white};
  display: inline-flex;
  flex-direction: column;

  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.25);
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  justify-content: space-between;
  position: relative;
  :last-child {
    margin-bottom: 0;
  }
`;

const StrokeSelector = styled.div<{ $selected: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: ${({ $selected }) =>
    $selected ? `solid #3c41c2 2px` : 'solid transparent 2px'};
  width: 23px;
  height: 23px;
`;

const StrokeWidth = styled.div<{ $radius: number }>`
  width: ${({ $radius }) => $radius}px;
  height: ${({ $radius }) => $radius}px;
  border-radius: 50%;
  background-color: black;
`;
const StrokeColor = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${({ $color }: { $color: string }) => $color};
  border: solid
    ${({ $color, $isSelected }: { $color: string; $isSelected: boolean }) =>
      $isSelected ? '#3c41c2' : $color}
    2px;
`;

const StrokeWidths = styled.div`
  padding: 9px 18px 8px 18px;
  border-bottom: 1px solid rgba(220, 220, 220, 0.38);
  display: flex;
  align-items: center;
`;

const StrokeColors = styled.div`
  padding: 5px 6px 11px 10px;
  path {
    fill: #3d4752;
  }
`;

const StyledColorPicker = styled(ColorPicker)`
  position: absolute;
  top: 0;
  right: -235px;
  z-index: 1;
`;

export type Props = {
  className?: string;
  onSelectColor: (color: string) => void;
  onSelectWidth: (width: number) => void;
  color: string;
  width: number;
};

const Component = ({
  className,
  onSelectColor,
  onSelectWidth,
  color,
  width,
}: Props): React.ReactElement => {
  const [isMorePickerOpen, setIsMorePickerOpen] = useState<boolean>(false);
  return (
    <Wrapper className={className}>
      <StrokeWidths>
        <StrokeSelector
          $selected={width === 2}
          onClick={() => onSelectWidth(2)}>
          <StrokeWidth $radius={2} />
        </StrokeSelector>
        <StrokeSelector
          $selected={width === 5}
          onClick={() => onSelectWidth(5)}>
          <StrokeWidth $radius={5} />
        </StrokeSelector>
        <StrokeSelector
          $selected={width === 10}
          onClick={() => onSelectWidth(10)}>
          <StrokeWidth $radius={10} />
        </StrokeSelector>
        <StrokeSelector
          $selected={width === 15}
          onClick={() => onSelectWidth(15)}>
          <StrokeWidth $radius={15} />
        </StrokeSelector>
        <StrokeSelector
          $selected={width === 20}
          onClick={() => onSelectWidth(20)}>
          <StrokeWidth $radius={20} />
        </StrokeSelector>
      </StrokeWidths>
      <StrokeColors>
        <Row>
          <StrokeColor
            $isSelected={color === '#F8F272'}
            onClick={() => onSelectColor('#F8F272')}
            $color="#F8F272"
          />
          <StrokeColor
            $isSelected={color === '#F8BA72'}
            onClick={() => onSelectColor('#F8BA72')}
            $color="#F8BA72"
          />
          <StrokeColor
            $isSelected={color === '#F88272'}
            onClick={() => onSelectColor('#F88272')}
            $color="#F88272"
          />
          <StrokeColor
            $isSelected={color === '#54AC5D'}
            onClick={() => onSelectColor('#54AC5D')}
            $color="#54AC5D"
          />
          <StrokeColor
            $isSelected={color === '#5482AC'}
            onClick={() => onSelectColor('#5482AC')}
            $color="#5482AC"
          />
        </Row>
        <Row>
          <StrokeColor
            $isSelected={color === '#FF4545'}
            onClick={() => onSelectColor('#FF4545')}
            $color="#FF4545"
          />
          <StrokeColor
            $isSelected={color === '#F572F8'}
            onClick={() => onSelectColor('#F572F8')}
            $color="#F572F8"
          />
          <StrokeColor
            $isSelected={color === '#9D72F8'}
            onClick={() => onSelectColor('#9D72F8')}
            $color="#9D72F8"
          />
          <StrokeColor
            $isSelected={color === '#AC547E'}
            onClick={() => onSelectColor('#AC547E')}
            $color="#AC547E"
          />
          <StrokeColor
            $isSelected={color === '#939393'}
            onClick={() => onSelectColor('#939393')}
            $color="#939393"
          />
        </Row>
        <Row>
          <StrokeColor
            $isSelected={color === '#501515'}
            onClick={() => onSelectColor('#501515')}
            $color="#501515"
          />
          <StrokeColor
            $isSelected={color === '#6B196C'}
            onClick={() => onSelectColor('#6B196C')}
            $color="#6B196C"
          />
          <StrokeColor
            $isSelected={color === '#230A58'}
            onClick={() => onSelectColor('#230A58')}
            $color="#230A58"
          />
          <StrokeColor
            $isSelected={color === '#FF007A'}
            onClick={() => onSelectColor('#FF007A')}
            $color="#FF007A"
          />
          <StrokeColor
            $isSelected={color === '#C10000'}
            onClick={() => onSelectColor('#C10000')}
            $color="#C10000"
          />
        </Row>
        <Row>
          <StrokeColor
            $isSelected={color === '#FF0000'}
            onClick={() => onSelectColor('#FF0000')}
            $color="#FF0000"
          />
          <StrokeColor
            $isSelected={color === '#FA00FF'}
            onClick={() => onSelectColor('#FA00FF')}
            $color="#FA00FF"
          />
          <StrokeColor
            $isSelected={color === '#5200FF'}
            onClick={() => onSelectColor('#5200FF')}
            $color="#5200FF"
          />
          <StrokeColor
            $isSelected={color === '#C5005E'}
            onClick={() => onSelectColor('#C5005E')}
            $color="#C5005E"
          />
          <StrokeColor
            $isSelected={color === '#00FF47'}
            onClick={() => onSelectColor('#00FF47')}
            $color="#00FF47"
          />
        </Row>
        <Row>
          <StrokeColor
            $isSelected={color === '#AE88EC'}
            onClick={() => onSelectColor('#AE88EC')}
            $color="#AE88EC"
          />
          <StrokeColor
            $isSelected={color === '#79377A'}
            onClick={() => onSelectColor('#79377A')}
            $color="#79377A"
          />
          <StrokeColor
            $isSelected={color === '#4B3776'}
            onClick={() => onSelectColor('#4B3776')}
            $color="#4B3776"
          />
          <StrokeColor
            $isSelected={color === '#773A57'}
            onClick={() => onSelectColor('#773A57')}
            $color="#773A57"
          />
          <Plus
            width={20}
            height={15}
            onClick={() => {
              setIsMorePickerOpen(!isMorePickerOpen);
            }}
          />
        </Row>
      </StrokeColors>
      {isMorePickerOpen && (
        <StyledColorPicker
          onChangeComplete={params => {
            onSelectColor(
              `rgba(${params.rgb.r},${params.rgb.g},${params.rgb.b},${params.rgb.a})`,
            );
          }}
          color={color}
        />
      )}
    </Wrapper>
  );
};

export default Component;
