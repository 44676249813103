import React, { CSSProperties, useRef } from 'react';
import { Node } from 'react-flow-renderer';
import Moveable, { OnResize, OnRotate } from 'react-moveable';
import { useNodesUtils, useNodeUtils } from '../hooks/nodes';
import { extractRotateFromTransform } from '../../../../../utils/strings';
import { convertDegree } from '../../../../../utils/planes';
import { useBoardHooks } from '../../../../../hooks/board';
import { sendSocket } from '../hooks/SendSocket';
import { useGlobalState } from '../../../../../hooks/global';
import { useSocketHooks } from '../../../../../hooks/socket';

type Props = {
  parentId: string;
  target: any;
  width: number;
  height: number;
  resizable?: boolean;
  onResize?: (ev: OnResize) => void;
  keepRatio?: boolean;
  parentStyles?: CSSProperties;
};
const Component = (props: Props): React.ReactElement => {
  const {
    parentId,
    target,
    width,
    height,
    parentStyles,
    resizable = true,
    keepRatio = false,
    onResize,
  } = props;
  const { useActions, nodeState, edgeState, useBoardSocket } = useBoardHooks();
  const { forceDiagramUpdate } = useBoardSocket();
  const { setNodes } = nodeState;
  const { insertAction } = useActions;
  const baseRotateRef = useRef<number | undefined>();
  const childrenRefs = useRef<Node[] | undefined>();
  const { setNodeDraggable, handleResizeEnd } = useNodeUtils(parentId);
  const { getChildrenNodes, updateNodePositions } = useNodesUtils();
  const { useCurrentUser } = useGlobalState();
  const { currentUser } = useCurrentUser;
  const { useSocket } = useSocketHooks();
  const { socket, initializedBoardCode } = useSocket;
  return (
    <>
      <Moveable
        target={target}
        keepRatio={keepRatio}
        rotatable
        onRotateStart={() => {
          baseRotateRef.current = extractRotateFromTransform(
            parentStyles?.transform || '',
          );
          childrenRefs.current = getChildrenNodes(parentId);
          setNodeDraggable(false);
        }}
        onRotate={(ev: OnRotate) => {
          const { transform, target, rotation } = ev;
          target!.style.transform = transform;
          const childrenNodes = childrenRefs.current || [];
          const newRotation = rotation || 0;
          const positionsObj: {
            [nodeId: string]: { x: number; y: number };
          } = childrenNodes.reduce((acc, cur) => {
            const computedAngle = newRotation - (baseRotateRef.current || 0);
            return {
              ...acc,
              [cur.id]: convertDegree({
                x: cur.position.x,
                y: cur.position.y,
                angle: computedAngle * (true ? 1 : -1),
                midPointX: width / 2,
                midPointY: height / 2,
              }),
            };
          }, {});
          updateNodePositions(positionsObj, (currentNodes: Node[]) => {
            return currentNodes.map(node =>
              node.id === parentId
                ? {
                    ...node,
                    data: {
                      ...node.data,
                      style: {
                        ...node.data.style,
                        transform,
                      },
                    },
                  }
                : node,
            );
          });
        }}
        onRotateEnd={ev => {
          setNodeDraggable(true);
          forceDiagramUpdate();
          const previousRotate = Number(baseRotateRef.current || 0);
          const newRotate = Number(ev.lastEvent?.rotation || 0);
          insertAction({
            undo: () => {
              setNodes(currentNodes => {
                const updatedNodes = currentNodes.map(node => {
                  if (node.id === parentId) {
                    return {
                      ...node,
                      selected: false,
                      data: {
                        ...node.data,
                        style: {
                          ...parentStyles,
                          transform: `rotate(${previousRotate}deg)`,
                        },
                      },
                    };
                  }
                  return node;
                });
                sendSocket(
                  updatedNodes,
                  edgeState.getEdges(),
                  '',
                  currentUser?.user?.token,
                  socket,
                  initializedBoardCode,
                );
                return updatedNodes;
              });
            },
            redo: () => {
              setNodes(currentNodes => {
                const updatedNodes = currentNodes.map(node => {
                  if (node.id === parentId) {
                    return {
                      ...node,
                      selected: false,
                      data: {
                        ...node.data,
                        style: {
                          ...parentStyles,
                          transform: `rotate(${newRotate}deg)`,
                        },
                      },
                    };
                  }
                  return node;
                });
                sendSocket(
                  updatedNodes,
                  edgeState.getEdges(),
                  '',
                  currentUser?.user?.token,
                  socket,
                  initializedBoardCode,
                );
                return updatedNodes;
              });
            },
          });
        }}
        resizable={resizable}
        onResizeStart={() => {
          setNodeDraggable(false);
        }}
        onResizeEnd={({ target }) => {
          const [marginLeftStr] = target.style.marginLeft.split('px');
          const [marginTopStr] = target.style.marginTop.split('px');
          const [widthStr] = target.style.width.split('px');
          const [heightStr] = target.style.height.split('px');
          const marginLeftNum = Number(marginLeftStr);
          const marginTopNum = Number(marginTopStr);
          const widthNum = Number(widthStr);
          const heightNum = Number(heightStr);
          target!.style.marginTop = `0px`;
          target!.style.marginLeft = `0px`;
          handleResizeEnd(marginLeftNum, marginTopNum, {
            width: widthNum,
            height: heightNum,
          });
        }}
        onResize={
          onResize ||
          (({
            width: newWidth,
            height: newHeight,
            target,
            delta,
            direction,
          }: OnResize) => {
            const [x, y] = direction;
            const resizingNW = x < 0 && y < 0;
            const resizingN = x == 0 && y < 0;
            const resizingW = x < 0 && y == 0;
            const resizingNE = x > 0 && y < 0;
            const resizingSW = x < 0 && y > 0;
            if (resizingNE) {
              delta[0] && (target!.style.width = `${newWidth}px`);
              delta[1] && (target!.style.height = `${newHeight}px`);
              delta[1] && (target!.style.marginTop = `${height - newHeight}px`);
            } else if (resizingN) {
              delta[0] && (target!.style.width = `${newWidth}px`);
              delta[1] && (target!.style.height = `${newHeight}px`);
              delta[1] && (target!.style.marginTop = `${height - newHeight}px`);
            } else if (resizingNW) {
              delta[0] && (target!.style.width = `${newWidth}px`);
              delta[0] && (target!.style.marginLeft = `${width - newWidth}px`);
              delta[1] && (target!.style.height = `${newHeight}px`);
              delta[1] && (target!.style.marginTop = `${height - newHeight}px`);
            } else if (resizingW) {
              delta[0] && (target!.style.width = `${newWidth}px`);
              delta[0] && (target!.style.marginLeft = `${width - newWidth}px`);
              delta[1] && (target!.style.height = `${newHeight}px`);
            } else if (resizingSW) {
              delta[0] && (target!.style.width = `${newWidth}px`);
              delta[0] && (target!.style.marginLeft = `${width - newWidth}px`);
              delta[1] && (target!.style.height = `${newHeight}px`);
            } else {
              delta[0] && (target!.style.width = `${newWidth}px`);
              delta[1] && (target!.style.height = `${newHeight}px`);
            }
          })
        }
      />
    </>
  );
};

export default Component;
