import BoardRepository from '../../ports/BoardRepository';
import { MyBoard, SortType } from '../../../domain/entities/board';

export type fetchBoardsUseCase = (
  search: string,
  sortKey: SortType,
  isFavorite: boolean,
  teamId?: number,
) => Promise<MyBoard[]>;

export const buildFetchBoards = (dependencies: {
  boardRepo: BoardRepository;
}): fetchBoardsUseCase => {
  const { boardRepo } = dependencies;

  const fetchBoards: fetchBoardsUseCase = (
    search,
    sortKey,
    isFavorite,
    teamId,
  ) => boardRepo.fetchBoards(search, sortKey, isFavorite, teamId);

  return fetchBoards;
};
