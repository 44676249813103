import React, { useEffect, useRef, useState } from 'react';
import {
  EditorContainer as EditorContainer1,
  ShapeOutside as ShapeOutside1,
  Shape as Shape1,
} from './components/Shape1';
import {
  EditorContainer as EditorContainer2,
  ShapeOutside as ShapeOutside2,
  Shape as Shape2,
} from './components/Shape2';
import {
  EditorContainer as EditorContainer3,
  ShapeOutside as ShapeOutside3,
  Shape as Shape3,
} from './components/Shape3';
import {
  EditorContainer as EditorContainer4,
  ShapeOutside as ShapeOutside4,
  Shape as Shape4,
} from './components/Shape4';
import {
  EditorContainer as EditorContainer5,
  ShapeOutside as ShapeOutside5,
  Shape as Shape5,
} from './components/Shape5';
import {
  EditorContainer as EditorContainer6,
  ShapeOutside as ShapeOutside6,
  Shape as Shape6,
} from './components/Shape6';
import {
  EditorContainer as EditorContainer7,
  ShapeOutside as ShapeOutside7,
  Shape as Shape7,
} from './components/Shape7';
import {
  EditorContainer as EditorContainer8,
  ShapeOutside as ShapeOutside8,
  Shape as Shape8,
} from './components/Shape8';
import {
  EditorContainer as EditorContainer9,
  ShapeOutside as ShapeOutside9,
  Shape as Shape9,
} from './components/Shape9';
import {
  EditorContainer as EditorContainer10,
  ShapeOutside as ShapeOutside10,
  Shape as Shape10,
} from './components/Shape10';
import {
  EditorContainer as EditorContainer11,
  ShapeOutside as ShapeOutside11,
  Shape as Shape11,
} from './components/Shape11';
import {
  EditorContainer as EditorContainer12,
  ShapeOutside as ShapeOutside12,
  Shape as Shape12,
} from './components/Shape12';
import {
  EditorState,
  ContentState,
  convertToRaw,
  Editor,
  convertFromRaw,
} from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import { useEditorRef } from '../PropertiesPane/hooks';
import { EditorRef } from '../PropertiesPane/types';
import PropertiesPane, {
  customBlockFn,
  customStyleFn,
} from '../PropertiesPane/WYSIWYGHOC';
import styled from 'styled-components';

const shapeMapper = {
  'shape-1': Shape1,
  'shape-2': Shape2,
  'shape-3': Shape3,
  'shape-4': Shape4,
  'shape-5': Shape5,
  'shape-6': Shape6,
  'shape-7': Shape7,
  'shape-8': Shape8,
  'shape-9': Shape9,
  'shape-10': Shape10,
  'shape-11': Shape11,
  'shape-12': Shape12,
};
const editorContainerMapper = {
  'shape-1': EditorContainer1,
  'shape-2': EditorContainer2,
  'shape-3': EditorContainer3,
  'shape-4': EditorContainer4,
  'shape-5': EditorContainer5,
  'shape-6': EditorContainer6,
  'shape-7': EditorContainer7,
  'shape-8': EditorContainer8,
  'shape-9': EditorContainer9,
  'shape-10': EditorContainer10,
  'shape-11': EditorContainer11,
  'shape-12': EditorContainer12,
};
const shapeOutsideMapper = {
  'shape-1': ShapeOutside1,
  'shape-2': ShapeOutside2,
  'shape-3': ShapeOutside3,
  'shape-4': ShapeOutside4,
  'shape-5': ShapeOutside5,
  'shape-6': ShapeOutside6,
  'shape-7': ShapeOutside7,
  'shape-8': ShapeOutside8,
  'shape-9': ShapeOutside9,
  'shape-10': ShapeOutside10,
  'shape-11': ShapeOutside11,
  'shape-12': ShapeOutside12,
};

const OverflowWrapper = styled.div<{ $isFocused?: boolean }>`
  cursor: ${({ $isFocused }) => ($isFocused ? `unset` : `all-scroll`)};
  .public-DraftEditor-content {
    /* NOTE: need !important because style needed to be overwritten is inline */
    user-select: ${({ $isFocused }) =>
      $isFocused ? `text` : `none`} !important;
  }
`;

export type Props = {
  type:
    | 'shape-1'
    | 'shape-2'
    | 'shape-3'
    | 'shape-4'
    | 'shape-5'
    | 'shape-6'
    | 'shape-7'
    | 'shape-8'
    | 'shape-9'
    | 'shape-10'
    | 'shape-11'
    | 'shape-12';
  width: number;
  height: number;
  style?: React.CSSProperties;
  selected?: boolean;
  isFlipped?: boolean;
  onChangeColor?: (color: string) => void;
  onChangeStrokeColor?: (color: string) => void;
  onChangeBorderWidth?: (stroke: number) => void;
  onClickEmoji?: () => void;
  onClickLink?: () => void;
  setRef?: (ref: HTMLDivElement) => void;
  label?: string;
  onChangeLabel?: (label: string) => void;
  onClickPaste?: () => void;
  onClickCopy?: () => void;
  onClickDelete?: () => void;
  onClickFlip?: () => void;
  isEmojiOpen?: boolean;
  isLinkOpen?: boolean;
};

const Component = ({
  type,
  width,
  height,
  style,
  isFlipped,
  onChangeColor,
  onChangeStrokeColor,
  onClickEmoji,
  onClickLink,
  onChangeBorderWidth,
  selected,
  setRef,
  label,
  onChangeLabel = () => {},
  onClickCopy,
  onClickDelete,
  onClickPaste,
  onClickFlip,
  isEmojiOpen,
  isLinkOpen,
}: Props): React.ReactElement => {
  const Shape = shapeMapper[type];
  const EditorContainer = editorContainerMapper[type];
  const ShapeOutside = shapeOutsideMapper[type];

  const [editorState, setEditorState] = useState(() => {
    if (!label) {
      return EditorState.createEmpty();
    }
    try {
      const contentState = convertFromRaw(JSON.parse(label));
      return EditorState.createWithContent(contentState);
    } catch {
      // NOTE: means that 'label' is of type html and is from previous schema
      const blocksFromHTML = htmlToDraft(label);
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap,
      );
      return EditorState.createWithContent(state);
    }
  });

  const ref = useRef<EditorRef>();
  const { handleFocus, handleBlur, isFocused } = useEditorRef(
    ref,
    editorState,
    setEditorState,
  );

  useEffect(() => {
    if (label && !selected) {
      try {
        const contentState = convertFromRaw(JSON.parse(label));
        setEditorState(EditorState.createWithContent(contentState));
      } catch {}
    }
  }, [label, selected]);

  return (
    <>
      {selected && (
        <PropertiesPane
          color={style?.fill}
          strokeColor={style?.stroke}
          borderWidth={
            typeof style?.strokeWidth === 'number' ||
            typeof style?.strokeWidth === 'string'
              ? Number(style?.strokeWidth)
              : 1
          }
          onChangeBorderWidth={onChangeBorderWidth}
          editorState={editorState}
          setEditorState={setEditorState}
          onChangeColor={onChangeColor}
          onChangeStrokeColor={onChangeStrokeColor}
          onClickEmoji={onClickEmoji}
          onClickLink={onClickLink}
          onClickCopy={onClickCopy}
          onClickPaste={onClickPaste}
          onClickDelete={onClickDelete}
          style={{ top: -90 }}
          onClickFlip={type === 'shape-7' ? onClickFlip : undefined}
          isEmojiOpen={isEmojiOpen}
          isLinkOpen={isLinkOpen}
          onChangeLabel={onChangeLabel}
        />
      )}

      <EditorContainer
        ref={setRef}
        style={{
          ...style,
          width,
          height,
          minHeight: '20px',
          minWidth: '20px',
        }}>
        <div
          className="shape-wrapper"
          style={{
            width,
            height,
          }}>
          <Shape
            width={width || '100%'}
            height={height || '100%'}
            style={{
              ...style,
              transform: isFlipped ? 'scaleX(-1)' : 'none',
              overflow: 'visible',
            }}
            preserveAspectRatio="none"
          />
        </div>
        <OverflowWrapper
          className={`overflow-wrapper ${isFocused ? 'nodrag' : ''}`}
          $isFocused={isFocused}
          onDoubleClick={handleFocus}
          onBlur={handleBlur}
          style={{
            width,
            height,
          }}
          onKeyDown={ev => {
            ev.stopPropagation();
          }}>
          <ShapeOutside />
          <Editor
            editorState={editorState}
            onFocus={handleFocus}
            onChange={eState => {
              const serialized = JSON.stringify(
                convertToRaw(eState.getCurrentContent()),
              );
              const isNew = label !== serialized;
              if (isNew) {
                onChangeLabel(serialized);
              }
              setEditorState(eState);
            }}
            customStyleFn={customStyleFn}
            blockStyleFn={customBlockFn}
            ref={ref}
            readOnly={!isFocused}
          />
        </OverflowWrapper>
      </EditorContainer>
    </>
  );
};

export default Component;
