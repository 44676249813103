import React from 'react';
import { useNavigate, useParams } from 'react-router';
import styled from 'styled-components';
import theme from '../../../constants/themes';
import { ChevronLeft, FlagmanDefault } from '../../atoms/Icons';
import { PaymentCustomCardForm } from '../../organisms/PaymentCardForm';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
`;

const InformationWrapper = styled.div<{
  plan: '個人' | '法人';
}>`
  display: flex;
  flex: 1;
  background: ${({ plan }) =>
    plan === '法人' ? theme.colors.warningBtn : theme.colors.lightBtn};
  padding: 2em 4em;
  flex-direction: column;
`;

const FormWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const StyledLogo = styled(FlagmanDefault)``;

const SettlementContainer = styled.div`
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 49px;
  letter-spacing: -0.02em;
  color: ${theme.colors.white};
  display: flex;
  flex-direction: column;
  margin-top: 1.5em;

  span {
    background: ${theme.colors.white};
    height: 11px;
    width: 120px;
  }
`;

const AccountContainer = styled.div`
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  letter-spacing: -0.02em;
  color: ${theme.colors.white};
  margin-top: 2em;
`;

const MonthlyPricingContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1em;

  span {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 29px;
    display: flex;
    align-items: flex-end;
    letter-spacing: -0.02em;
    color: ${theme.colors.white};
  }
`;

const Pricing = styled.div`
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 49px;
  display: flex;
  align-items: flex-end;
  letter-spacing: -0.02em;
  color: ${theme.colors.white};
`;

const PricingContainer = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

const BottomContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-end;
  flex-direction: column;
  gap: 3em;
`;
const ChevronLeftContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex: 1;

  svg {
    cursor: pointer;
  }
`;

const BottomInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 5em;

  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  /* identical to box height */

  text-align: center;
  letter-spacing: -0.02em;
  color: ${theme.colors.white};

  a {
    :first-child {
      font-weight: 400;
      font-size: 20px;
      line-height: 29px;
      letter-spacing: -0.02em;
    }
  }
`;

export type Props = {};

const Component = ({}: Props): React.ReactElement => {
  const { planType } = useParams();
  const navigate = useNavigate();

  return (
    <Container>
      <InformationWrapper plan={planType as '個人' | '法人'}>
        <StyledLogo />
        <SettlementContainer>
          決済
          <span></span>
        </SettlementContainer>
        <PricingContainer>
          <AccountContainer>IDEAMAN 個人アカウントに申し込む</AccountContainer>
          <MonthlyPricingContainer>
            {planType === '個人' ? (
              <>
                <Pricing>￥500</Pricing>
                <span>1 カ月につき</span>
              </>
            ) : (
              <Pricing>問い合わせ</Pricing>
            )}
          </MonthlyPricingContainer>
        </PricingContainer>
        <BottomContainer>
          <ChevronLeftContainer>
            <ChevronLeft onClick={() => navigate('/plan-select')} />
          </ChevronLeftContainer>
          <BottomInfo>
            <a>Power by LOGO</a>
            <a>プライバシーポリシー</a>
            <a>利用規約</a>
          </BottomInfo>
        </BottomContainer>
      </InformationWrapper>
      <FormWrapper>
        <PaymentCustomCardForm
          buttonTitle="決済"
          themeColor={
            planType === '個人'
              ? theme.colors.lightBtn
              : theme.colors.warningBtn
          }
          hoverColor={
            planType === '個人'
              ? theme.colors.hoverLightBtn
              : theme.colors.hoverWarningBtn
          }
        />
      </FormWrapper>
    </Container>
  );
};

export default Component;
