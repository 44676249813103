import React from 'react';

type Props = {
  [key: string]: string | number | Function | object;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g clipPath="url(#clip0_7745_14953)">
        <path
          d="M30.0001 60.0001C46.5686 60.0001 60.0001 46.5686 60.0001 30.0001C60.0001 13.4315 46.5686 0 30.0001 0C13.4315 0 0 13.4315 0 30.0001C0 46.5686 13.4315 60.0001 30.0001 60.0001Z"
          fill="white"
        />
        <path
          d="M41.5784 27.703C41.5784 21.2342 36.2814 15.9842 29.8128 16.1248C23.8128 16.2186 18.7502 20.9998 18.4221 26.9998C18.2347 30.2341 19.3596 33.1873 21.3283 35.3904C24.2345 38.6716 25.8283 41.6716 25.8283 46.0779V46.5935H34.1721V46.0779C34.1721 41.6248 35.9064 38.6247 38.8127 35.2498C40.5471 33.2342 41.5784 30.6093 41.5784 27.703Z"
          fill="#FFD200"
        />
        <path
          d="M33.5347 51.8438C33.3776 51.9136 33.156 51.9861 32.8695 52.0529C32.1531 52.2201 31.1384 52.3282 30 52.3282C28.8616 52.3282 27.8468 52.2201 27.1305 52.0529C26.844 51.9861 26.6224 51.9136 26.4652 51.8438C26.6224 51.7739 26.844 51.7015 27.1305 51.6346C27.8468 51.4675 28.8616 51.3594 30 51.3594C31.1384 51.3594 32.1531 51.4675 32.8695 51.6346C33.156 51.7015 33.3776 51.7739 33.5347 51.8438Z"
          fill="white"
          stroke="#FFD200"
        />
        <path
          d="M30 35.6719C34.401 35.6719 37.9688 32.1042 37.9688 27.7031C37.9688 23.3021 34.401 19.7344 30 19.7344C25.599 19.7344 22.0312 23.3021 22.0312 27.7031C22.0312 32.1042 25.599 35.6719 30 35.6719Z"
          fill="white"
        />
        <path
          d="M15.3281 27.1875H8.90625V29.3438H15.3281V27.1875Z"
          fill="#FFD200"
        />
        <path
          d="M51.0938 27.1875H44.6719V29.3438H51.0938V27.1875Z"
          fill="#FFD200"
        />
        <path
          d="M31.0781 7.17188H28.9219V13.5938H31.0781V7.17188Z"
          fill="#FFD200"
        />
        <path
          d="M14.3395 14.1057L18.8804 18.6465L20.4051 17.1218L15.8642 12.581L14.3395 14.1057Z"
          fill="#FFD200"
        />
        <path
          d="M39.6101 39.3693L44.1509 43.9102L45.6756 42.3855L41.1347 37.8446L39.6101 39.3693Z"
          fill="#FFD200"
        />
        <path
          d="M39.6071 17.0847L41.1318 18.6094L45.6727 14.0685L44.148 12.5438L39.6071 17.0847Z"
          fill="#FFD200"
        />
        <path
          d="M14.3098 42.3884L15.8345 43.9131L20.3753 39.3722L18.8506 37.8476L14.3098 42.3884Z"
          fill="#FFD200"
        />
        <path
          d="M33.9374 51.8438H26.1094C25.4531 51.8438 24.9375 51.3282 24.9375 50.6719V43.9219H35.1562V50.6719C35.1562 51.3282 34.5936 51.8438 33.9374 51.8438Z"
          fill="#FFD200"
        />
      </g>
      <defs>
        <clipPath id="clip0_7745_14953">
          <rect width="60" height="60" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Component;
