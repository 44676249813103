import TeamRepository from '../../ports/TeamRepository';
import { InviteTeamMemberSuccess } from '../../../domain/entities/team';

export type inviteTeamMemberUseCase = (
  teamId: string,
  emails: string[],
) => Promise<InviteTeamMemberSuccess>;

export const buildinviteTeamMember = (dependencies: {
  teamRepo: TeamRepository;
}): inviteTeamMemberUseCase => {
  const { teamRepo } = dependencies;

  const inviteTeamMember: inviteTeamMemberUseCase = (teamId, emails) =>
    teamRepo.inviteTeamMember(teamId, emails);

  return inviteTeamMember;
};
