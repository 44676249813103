import React, { useState } from 'react';
import { LabelData } from '../types';
import { LinkDetails } from '../../../molecules/LinkDetails';
import { LinkForm } from '../../../molecules/LinkForm';
import { Label } from '../../../molecules/Label';
import Overlay from './common/Overlay';
import { useNodesUtils, useNodeUtils } from './hooks/nodes';
import { useBoardHooks } from '../../../../hooks/board';
import DragLine from './common/DragLine';
import Moveable from './common/Moveable';
import { extractRotateFromTransform } from '../../../../utils/strings';

const Component = (props: {
  id: string;
  data: LabelData;
  selected: boolean;
  xPos: number;
  yPos: number;
}): React.ReactElement => {
  const { data, id, xPos, yPos } = props;
  const { height, width, label, style, link } = data;
  const {
    setData,
    setLabel,
    setStyle,
    handleCopyNodes,
    isMultipleSelected,
    isSelectedByCurrentUser,
  } = useNodeUtils(id);
  const { handlePasteNodes, removeNodes } = useNodesUtils();

  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const [isSettingLink, setIsSettingLink] = useState<boolean>(false);
  const [isLinkDetailsOpen, setIsLinkDetailsOpen] = useState<boolean>(false);

  const { customersNodeIdsState } = useBoardHooks();
  const { customersSelectedNodeIds } = customersNodeIdsState;
  const selectedNodeIdsState = customersSelectedNodeIds
    ? Object.values(customersSelectedNodeIds).flat()
    : [];
  const isSelected = isSelectedByCurrentUser && props.selected;
  const isLocked = selectedNodeIdsState.includes(id);

  const onClickDelete = () => {
    removeNodes([id]);
  };

  return (
    <>
      {isSelected && !isMultipleSelected && !isLocked && (
        <DragLine
          width={width}
          height={height}
          parentId={id}
          position={{ x: xPos, y: yPos }}
          angle={
            style?.transform ? extractRotateFromTransform(style.transform) : 0
          }
        />
      )}
      <Label
        width={width}
        height={height}
        label={label}
        isSelected={isSelected && !isMultipleSelected && !isLocked}
        style={style}
        onRef={setRef}
        onChangeLabel={setLabel}
        onClickLink={() => {
          setIsSettingLink(!isSettingLink);
        }}
        onTextRealign={align => {
          setStyle({
            ...style,
            textAlign: align,
          });
        }}
        onClickCopy={handleCopyNodes}
        onClickPaste={handlePasteNodes}
        onClickDelete={onClickDelete}
      />
      {isSelected && !isMultipleSelected && !isLocked && (
        <>
          {isSettingLink && (
            <LinkForm
              onSubmit={link => {
                setData({ ...props.data, link });
                setIsSettingLink(false);
              }}
              initialLink={link}
              style={{ position: 'absolute', top: -40, left: 300, zIndex: 1 }}
            />
          )}
        </>
      )}
      {link && (
        <div style={{ position: 'absolute', top: -35, left: width / 2 - 12 }}>
          <LinkDetails
            isOpen={isLinkDetailsOpen}
            link={link}
            onClickDelete={() => {
              const newData = JSON.parse(
                JSON.stringify({ ...props.data, link: undefined }),
              );
              setData(newData);
              setIsSettingLink(false);
            }}
            onClickEdit={() => {
              setIsSettingLink(true);
            }}
            onClickLinkIcon={() => {
              !isLocked && setIsLinkDetailsOpen(!isLinkDetailsOpen);
            }}
          />
        </div>
      )}
      {isSelected && ref && !isLocked && (
        <Moveable
          target={ref}
          width={width}
          height={height}
          parentStyles={style}
          parentId={id}
        />
      )}
      {isLocked && (
        <Overlay width={width} height={height} transform={style?.transform} />
      )}
    </>
  );
};

export default Component;
