import React, { SVGProps } from 'react';

type Props = SVGProps<SVGSVGElement>;

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M11.646 3.14598C11.6925 3.09941 11.7476 3.06247 11.8084 3.03727C11.8691 3.01206 11.9343 2.99908 12 2.99908C12.0658 2.99908 12.1309 3.01206 12.1917 3.03727C12.2524 3.06247 12.3076 3.09941 12.354 3.14598L15.354 6.14598C15.4479 6.23986 15.5007 6.3672 15.5007 6.49998C15.5007 6.63275 15.4479 6.76009 15.354 6.85398C15.2601 6.94787 15.1328 7.00061 15 7.00061C14.8672 7.00061 14.7399 6.94787 14.646 6.85398L12.5 4.70698V15.502C12.5 15.6346 12.4473 15.7618 12.3536 15.8555C12.2598 15.9493 12.1326 16.002 12 16.002C11.8674 16.002 11.7402 15.9493 11.6465 15.8555C11.5527 15.7618 11.5 15.6346 11.5 15.502V4.70698L9.35402 6.85398C9.30753 6.90047 9.25234 6.93734 9.1916 6.9625C9.13087 6.98766 9.06576 7.00061 9.00002 7.00061C8.93428 7.00061 8.86918 6.98766 8.80844 6.9625C8.7477 6.93734 8.69251 6.90047 8.64602 6.85398C8.59953 6.80749 8.56266 6.7523 8.5375 6.69156C8.51234 6.63082 8.49939 6.56572 8.49939 6.49998C8.49939 6.43423 8.51234 6.36913 8.5375 6.30839C8.56266 6.24766 8.59953 6.19247 8.64602 6.14598L11.646 3.14598Z"
        fill="white"
      />
      <path
        d="M5 9.5C5 9.36739 4.94732 9.24021 4.85355 9.14645C4.75979 9.05268 4.63261 9 4.5 9C4.36739 9 4.24021 9.05268 4.14645 9.14645C4.05268 9.24021 4 9.36739 4 9.5V18.5C4 19.163 4.26339 19.7989 4.73223 20.2678C5.20107 20.7366 5.83696 21 6.5 21H17.5C18.163 21 18.7989 20.7366 19.2678 20.2678C19.7366 19.7989 20 19.163 20 18.5V9.5C20 9.36739 19.9473 9.24021 19.8536 9.14645C19.7598 9.05268 19.6326 9 19.5 9C19.3674 9 19.2402 9.05268 19.1464 9.14645C19.0527 9.24021 19 9.36739 19 9.5V18.5C19 18.8978 18.842 19.2794 18.5607 19.5607C18.2794 19.842 17.8978 20 17.5 20H6.5C6.10218 20 5.72064 19.842 5.43934 19.5607C5.15804 19.2794 5 18.8978 5 18.5V9.5Z"
        fill="white"
      />
    </svg>
  );
};

export default Component;
