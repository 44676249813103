import React, { SVGProps } from 'react';

type Props = SVGProps<SVGSVGElement>;

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M20 0C8.95498 0 0 8.95498 0 20C0 31.0463 8.95374 40 20 40C31.0463 40 40 31.0463 40 20C40 8.95498 31.0463 0 20 0ZM20 37.5387C10.35 37.5387 2.50004 29.65 2.50004 20C2.50004 10.35 10.35 2.50004 20 2.50004C29.65 2.50004 37.5 10.35 37.5 20C37.5 29.65 29.65 37.5387 20 37.5387Z"
        fill="white"
      />
      <path
        d="M24.5087 10.3777C24.0199 9.88517 23.2287 9.88517 22.7412 10.3777L14.1162 19.1264C13.6412 19.6065 13.6399 20.4315 14.1162 20.9126L22.74 29.6614C23.2287 30.1539 24.0199 30.1539 24.5075 29.6614C24.9962 29.1676 24.9962 28.3689 24.5075 27.8751L16.7637 20.019L24.5087 12.1628C24.9962 11.6701 24.9962 10.8714 24.5087 10.3777Z"
        fill="white"
      />
    </svg>
  );
};

export default Component;
