import { v4 as uuidv4 } from 'uuid';
import { Node, Edge } from 'react-flow-renderer';
import { cleanupNodes } from './cleanup';

export const withHttp = (url: string): string =>
  url.replace(/^(?:(.*:)?\/\/)?(.*)/i, (match, schemma, nonSchemmaUrl) =>
    schemma ? match : `http://${nonSchemmaUrl}`,
  );

export const validateEmailsFormat = (emails: string): boolean => {
  const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;
  const emailArray = emails.replace(/\s/g, '').split(/,/);

  if (emailArray.some(emailItem => !mailFormat.test(emailItem))) {
    return false;
  }

  return true;
};

export const validateEmailsLength = (emails: string): boolean => {
  const emailArray = emails.replace(/\s/g, '').split(/,/);

  if (emailArray.some(emailItem => emailItem.length > 255)) {
    return false;
  }

  return true;
};

export const cleanSpacesSepratedComma = (value: string): string => {
  let cleanText = '';

  value.split(',').forEach(text => {
    cleanText += `${text.trim()},`;
  });

  return cleanText.slice(0, -1);
};

export const generateUniqueString = (): string => {
  return uuidv4();
};

export const mapUniqueStrings = (arr: string[]): string[] => {
  return arr.reduce((acc: string[], cur: string) => {
    if (acc.includes(cur)) {
      return acc;
    }
    return [...acc, cur];
  }, []);
};

export const handleTextOverflow = (text: string, maxLength: number): string => {
  return text?.length <= maxLength
    ? text
    : `${text?.substring(0, maxLength)}...`;
};

export const extractRotateFromTransform = (transformStr: string): number => {
  const angleStr = transformStr
    .replace('rotate', '')
    .replace('(', '')
    .replace(')', '')
    .replace('deg', '');
  if (isNaN(Number(angleStr))) {
    return 0;
  }
  return Number(angleStr);
};

export const removeTags = (text: string): string[] => {
  const matchDatas = text.match(/(>([^<]*)<)/g);

  const replacedMatchData =
    matchDatas?.map(data => data.replace(/<|([<\/])|>/g, '')).filter(Boolean) ||
    [];

  return replacedMatchData;
};

const parseNode = (item: any): Node => {
  const style = item?.data?.style
    ? Object.entries(item.data.style).reduce((acc, cur: [string, any]) => {
        const [key, value] = cur;
        return { ...acc, [key]: value._text };
      }, {})
    : {};
  const data = item?.data
    ? Object.entries(item.data).reduce((acc, cur: [string, any]) => {
        const [key, value] = cur;
        return { ...acc, [key]: value._text };
      }, {})
    : {};
  const path = item?.data?.path
    ? Object.entries(item.data.path).reduce((acc, cur: [string, any]) => {
        const [key, value] = cur;
        if (key === 'paths') {
          const paths: { x: number; y: number }[] = Array.isArray(value)
            ? value.map(
                (p: { x: { _text: string }; y: { _text: string } }) => ({
                  x: Number(p?.x?._text || 0),
                  y: Number(p?.y?._text || 0),
                }),
              )
            : [
                {
                  x: Number(value?.x?._text || 0),
                  y: Number(value?.y?._text || 0),
                },
              ];
          return { ...acc, [key]: paths };
        }
        if (key === 'strokeWidth') {
          return { ...acc, [key]: Number(value._text) };
        }
        return { ...acc, [key]: value._text };
      }, {})
    : undefined;

  const emojis = item?.data?.emojis
    ? Object.entries(item.data.emojis).reduce((acc, cur: [string, any]) => {
        const [key, value] = cur;
        return { ...acc, [key]: value._text };
      }, {})
    : undefined;
  const emojiNames = item?.data?.emojiNames
    ? Object.entries(item.data.emojiNames).reduce((acc, cur: [string, any]) => {
        const [key, value] = cur;
        return { ...acc, [key]: value._text };
      }, {})
    : undefined;
  const reactorNames = item?.data?.reactorNames
    ? Object.entries(item.data.reactorNames).reduce(
        (acc, cur: [string, any]) => {
          const [key, value] = cur;
          return { ...acc, [key]: value._text };
        },
        {},
      )
    : undefined;
  const strokeWidthForCanvas = !isNaN(Number(item?.data?.strokeWidth?._text))
    ? Number(item?.data?.strokeWidth?._text)
    : undefined;
  return {
    id: item.id?._text,
    type: item.type?._text,
    position: {
      x: Number(item.position.x._text),
      y: Number(item.position.y._text),
    },
    data: {
      ...data,
      width: item.type._text === 'line' ? 0 : Number(item.data?.width?._text),
      height: item.type._text === 'line' ? 0 : Number(item.data?.height?._text),
      style,
      path,
      emojis,
      emojiNames,
      reactorNames,
      strokeWidth: strokeWidthForCanvas,
    },
    parentNode: item?.parentNode?._text,
    zIndex: item.zIndex?._text,
  };
};

const parseEdge = (item: any): Edge => {
  const data = item?.data
    ? Object.entries(item.data).reduce((acc, cur: [string, any]) => {
        const [key, value] = cur;
        return { ...acc, [key]: value._text };
      }, {})
    : {};
  const style = item?.style
    ? Object.entries(item.style).reduce((acc, cur: [string, any]) => {
        const [key, value] = cur;
        return { ...acc, [key]: value._text };
      }, {})
    : {};
  return {
    id: item.id._text,
    source: item.source?._text,
    target: item.target?._text,
    type: item.type?._text,
    data,
    style,
    zIndex: item.zIndex?._text,
  };
};

export const parseStrToNodesEdges = (
  xmlStr: string,
): { nodes: Node[]; edges: Edge[] } => {
  const jsonObj = JSON.parse(xmlStr);
  let nodes: Node[] = [];
  let edges: Edge[] = [];
  if (Array.isArray(jsonObj?.data?.nodes)) {
    nodes = jsonObj?.data?.nodes?.map((item: any) => {
      return parseNode(item);
    });
  } else {
    if (jsonObj?.data?.nodes?.id) {
      nodes = [parseNode(jsonObj?.data?.nodes)];
    }
  }

  if (Array.isArray(jsonObj?.data?.edges)) {
    edges = jsonObj.data.edges.map((item: any) => {
      return parseEdge(item);
    });
  } else {
    if (jsonObj?.data?.edges?.id) {
      edges = [parseEdge(jsonObj?.data?.edges)];
    }
  }

  return { nodes: cleanupNodes(nodes), edges };
};

export const replaceNbsp = (text: string): string => {
  return text?.replace(/(&nbsp;)+|\s\K\s+/, ' ');
};
