import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  useNodes,
  getSimpleBezierPath,
  Node,
  getSmoothStepPath,
  Position,
  useReactFlow,
} from 'react-flow-renderer';
import { useBoardHooks } from '../../../../hooks/board';
import { generateUniqueString } from '../../../../utils/strings';
import { DIAGRAM_CONTAINER_ID, LINE_NODE_Z_INDEX } from '../Component';
import { LineData, DiagramEdge } from '../types';
import { useNodesUtils, useProjectUtils } from './hooks/nodes';
import { useSocketHooks } from '../../../../hooks/socket';
import { useGlobalState } from '../../../../hooks/global';
import { sendSocket } from './hooks/SendSocket';
const PATH_STEP_MAPPER: {
  [key: string]:
    | Position.Top
    | Position.Bottom
    | Position.Left
    | Position.Right;
} = {
  top: Position.Top,
  bottom: Position.Bottom,
  left: Position.Left,
  right: Position.Right,
};

const PATH_BEZIER_MAPPER: {
  [key: string]: number;
} = {
  top: 270,
  bottom: 90,
  left: 180,
  right: 0,
};

const Component = (props: {
  source: string;
  target: string;
  id: string;
  sourceX: number;
  sourceY: number;
  targetX: number;
  targetY: number;
  data: {
    groupId: string;
  };
}): React.ReactElement => {
  const {
    source,
    target,
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    data,
  } = props;
  const { groupId } = data;
  const {
    useMySelectedNodes,
    useBoardSocket,
    useActions,
    nodeState,
    edgeState,
  } = useBoardHooks();
  const {
    setRecentlySelectedNodeIds,
    recentlySelectedNodeIds,
  } = useMySelectedNodes;
  const { forceDiagramUpdate } = useBoardSocket();
  const { insertAction } = useActions;
  const { getNodes, getEdges } = useReactFlow();
  const nodes: Node[] = useNodes();
  const { setNodes } = nodeState;
  const { setEdges } = edgeState;
  const targetLineNode = useRef<Node<LineData> | undefined>();
  const groupPositionOffsetsRefs = useRef<
    | {
        nodeId: string;
        xOffset: number;
        yOffset: number;
      }[]
    | undefined
  >(undefined);
  const { getTranslatedPositions } = useProjectUtils(DIAGRAM_CONTAINER_ID);
  const { updateNodePositions } = useNodesUtils();

  const { useSocket } = useSocketHooks();
  const { socket, initializedBoardCode } = useSocket;
  const { useCurrentUser } = useGlobalState();
  const { currentUser } = useCurrentUser;

  const associatedLineNodes: Node<LineData>[] = useMemo(() => {
    return nodes.filter(node => node.data.groupId === groupId);
  }, [nodes]);
  const sourceNode: Node<LineData> | undefined = useMemo(() => {
    return associatedLineNodes.find(node => source && source === node.id);
  }, [associatedLineNodes, source]);
  const targetNode: Node<LineData> | undefined = useMemo(() => {
    return associatedLineNodes.find(node => target && target === node.id);
  }, [associatedLineNodes, target]);
  const isLineBound: boolean = useMemo(() => {
    return !!associatedLineNodes.find(node => node.parentNode);
  }, [associatedLineNodes]);

  const style: CSSProperties = {
    ...sourceNode?.data.style,
    ...targetNode?.data.style,
  };

  const isLineBoundRef = useRef<boolean>(false);
  useEffect(() => {
    isLineBoundRef.current = isLineBound;
  }, [isLineBound]);

  const addNodeBetween = useCallback(
    (position: { x: number; y: number }) => {
      if (!sourceNode) {
        console.error(`source node not found for edge '${id}'`);
        return;
      }
      const currentNodes = getNodes();
      const newNode: Node<LineData> = {
        id: `line-${generateUniqueString()}`,
        type: 'line',
        connectable: false,
        position,
        zIndex: LINE_NODE_Z_INDEX,
        data: {
          ...sourceNode.data,
          source,
          target,
        },
        selected: true,
      };
      let oldSource: Node<LineData> | undefined;
      let oldTarget: Node<LineData> | undefined;
      let newSource: Node<LineData> | undefined;
      let newTarget: Node<LineData> | undefined;
      const updatedNodes = currentNodes.map(node => {
        if (node.type === 'line' && node.id === source) {
          oldSource = node;
          newSource = {
            ...node,
            data: {
              ...node.data,
              target: newNode.id,
            },
            selected: false,
          };
          return newSource;
        }
        if (node.type === 'line' && node.id === target) {
          oldTarget = node;
          newTarget = {
            ...node,
            data: {
              ...node.data,
              source: newNode.id,
            },
            selected: false,
          };
          return newTarget;
        }
        if (node.type === 'line' && node.data?.groupId === groupId) {
          return { ...node, selected: false };
        }
        return {
          ...node,
          selected: recentlySelectedNodeIds.includes(node.id)
            ? false
            : node.selected,
        };
      });

      const currentEdges = getEdges();

      const newEdge: DiagramEdge = {
        id: `edge-${generateUniqueString()}`,
        source,
        target: newNode.id,
        type: 'custom',
        data: {
          groupId: groupId,
        },
      };
      let oldEdge: DiagramEdge | undefined;
      const updatedEdges = currentEdges.map(edge => {
        if (source && edge.source === source) {
          oldEdge = edge;
          return {
            ...edge,
            source: newNode.id,
          };
        }
        return edge;
      });
      if (
        oldSource &&
        oldTarget &&
        newSource &&
        newTarget &&
        oldEdge &&
        newEdge
      ) {
        insertAction({
          undo: () => {
            setNodes(latestNodes => {
              const updatedNodes = latestNodes
                .map(n => {
                  if (n.id === oldSource?.id) {
                    return oldSource;
                  }
                  if (n.id === oldTarget?.id) {
                    return oldTarget;
                  }
                  return n;
                })
                .filter(n => n.id !== newNode.id);
              sendSocket(
                updatedNodes,
                edgeState.getEdges(),
                '',
                currentUser?.user?.token,
                socket,
                initializedBoardCode,
              );
              return updatedNodes;
            });
            setEdges(latestEdges => {
              return latestEdges
                .map(e => {
                  if (e.id === oldEdge?.id) {
                    return oldEdge;
                  }
                  return e;
                })
                .filter(e => e.id !== newEdge.id);
            });
          },
          redo: () => {
            setNodes(latestNodes => {
              const updatedNodes = latestNodes
                .map(n => {
                  if (n.id === newSource?.id) {
                    return newSource;
                  }
                  if (n.id === newTarget?.id) {
                    return newTarget;
                  }
                  return n;
                })
                .concat(newNode);
              sendSocket(
                updatedNodes,
                edgeState.getEdges(),
                '',
                currentUser?.user?.token,
                socket,
                initializedBoardCode,
              );
              return updatedNodes;
            });
            setEdges(latestEdges => {
              return latestEdges
                .map(e => {
                  if (e.target === oldTarget?.id) {
                    return {
                      ...e,
                      source: newNode.id,
                    };
                  }
                  return e;
                })
                .concat(newEdge);
            });
          },
        });
      }

      targetLineNode.current = newNode;
      setRecentlySelectedNodeIds([newNode.id]);
      setNodes(() => [...updatedNodes, newNode]);
      setEdges(() => [...updatedEdges, newEdge]);
    },
    [
      setNodes,
      setEdges,
      target,
      source,
      sourceNode,
      insertAction,
      recentlySelectedNodeIds,
    ],
  );
  const lineType: 'step' | 'straight' | 'bezier' | undefined =
    sourceNode?.data?.lineType || 'straight';

  const getPosition = useCallback((sourceX, sourceY, targetX, targetY):
    | 'left'
    | 'right'
    | 'top'
    | 'bottom' => {
    const isBottom =
      Math.abs(targetX - sourceX) <= Math.abs(targetY - sourceY) &&
      sourceY < targetY;
    if (isBottom) {
      return 'bottom';
    }
    const isRight =
      Math.abs(targetY - sourceY) <= targetX - sourceX && sourceX < targetX;

    if (isRight) {
      return 'right';
    }
    const isTop =
      Math.abs(targetX - sourceX) <= Math.abs(targetY - sourceY) &&
      sourceY > targetY;
    if (isTop) {
      return 'top';
    }
    return 'left';
  }, []);

  const getPath = (): string => {
    if (lineType === 'bezier') {
      return getSimpleBezierPath({
        sourceX,
        sourceY,
        targetX,
        targetY,
        sourcePosition:
          PATH_STEP_MAPPER[getPosition(sourceX, sourceY, targetX, targetY)],
        targetPosition:
          PATH_STEP_MAPPER[getPosition(sourceX, sourceY, targetX, targetY)],
      });
    }
    if (lineType === 'step') {
      return getSmoothStepPath({
        sourceX,
        sourceY,
        targetX,
        targetY,
        borderRadius: 0,
        sourcePosition:
          PATH_STEP_MAPPER[getPosition(sourceX, sourceY, targetX, targetY)],
        targetPosition:
          PATH_STEP_MAPPER[getPosition(sourceX, sourceY, targetX, targetY)],
      });
    }
    if (lineType === 'straight') {
      return `M${sourceX} ${sourceY} L${targetX} ${targetY}`;
    }
    return '';
  };

  const handleMouseUp = useCallback(() => {
    targetLineNode.current = undefined;
    groupPositionOffsetsRefs.current = undefined;
    removeDragListeners();
    forceDiagramUpdate();
  }, []);

  const handleMouseMove = useCallback(ev => {
    const translatedPositions = getTranslatedPositions(ev);
    if (
      targetLineNode.current?.id &&
      translatedPositions &&
      isLineBoundRef.current
    ) {
      updateNodePositions({
        [targetLineNode.current.id]: translatedPositions,
      });
    }
    if (
      groupPositionOffsetsRefs.current?.length &&
      translatedPositions &&
      !isLineBoundRef.current
    ) {
      const groupPositionOffsetsRefsObj: {
        [nodeId: string]: { x: number; y: number };
      } = groupPositionOffsetsRefs.current.reduce((acc, cur) => {
        return {
          ...acc,
          [cur.nodeId]: {
            x: translatedPositions.x - cur.xOffset,
            y: translatedPositions.y - cur.yOffset,
          },
        };
      }, {});

      updateNodePositions(groupPositionOffsetsRefsObj);
    }
    if (
      isLineBoundRef.current &&
      translatedPositions &&
      targetLineNode.current === undefined
    ) {
      addNodeBetween(translatedPositions as { x: number; y: number });
    }
  }, []);

  const removeDragListeners = () => {
    window.removeEventListener('mouseup', handleMouseUp);
    window.removeEventListener('mousemove', handleMouseMove);
  };

  const addDraglistener = () => {
    window.addEventListener('mouseup', handleMouseUp);
    window.addEventListener('mousemove', handleMouseMove);
  };
  const dPath = getPath();

  return (
    <>
      <defs>
        <marker
          id={`triangle-${props.id}-start`}
          viewBox="0 0 10 10"
          refX="1"
          refY="5"
          markerUnits="strokeWidth"
          markerWidth="4"
          markerHeight="5"
          orient={
            sourceNode?.data?.lineType === 'bezier'
              ? PATH_BEZIER_MAPPER[
                  getPosition(targetX, targetY, sourceX, sourceY)
                ]
              : 'auto-start-reverse'
          }>
          {sourceNode?.data?.startArrowType === 'filled' && (
            <path d="M 0 0 L 10 5 L 0 10 z" fill={style?.stroke || 'black'} />
          )}
          {sourceNode?.data?.startArrowType === 'hollow' && (
            <path
              d="M 0 0 L 10 5 L 0 10 z"
              stroke={style?.stroke || 'black'}
              fill="transparent"
              strokeWidth={2}
            />
          )}
          {sourceNode?.data?.startArrowType === 'arrow' && (
            <path
              d="M 3,2 L 8,5 L 3,8"
              fill="transparent"
              stroke={style?.stroke || 'black'}
              strokeWidth={3}
            />
          )}
        </marker>
        <marker
          id={`triangle-${props.id}-end`}
          viewBox="0 0 10 10"
          refX="1"
          refY="5"
          markerUnits="strokeWidth"
          markerWidth="4"
          markerHeight="5"
          orient={
            targetNode?.data?.lineType === 'bezier'
              ? PATH_BEZIER_MAPPER[
                  getPosition(sourceX, sourceY, targetX, targetY)
                ]
              : 'auto'
          }>
          {targetNode?.data?.endArrowType === 'filled' && (
            <path d="M 0 0 L 10 5 L 0 10 z" fill={style?.stroke || 'black'} />
          )}
          {targetNode?.data?.endArrowType === 'hollow' && (
            <path
              d="M 0 0 L 10 5 L 0 10 z"
              stroke={style?.stroke || 'black'}
              fill="transparent"
              strokeWidth={2}
            />
          )}
          {targetNode?.data?.endArrowType === 'arrow' && (
            <path
              d="M 3,2 L 8,5 L 3,8"
              fill="transparent"
              stroke={style?.stroke || 'black'}
              strokeWidth={3}
            />
          )}
        </marker>
      </defs>
      <path
        id={id}
        style={style}
        className="react-flow__edge-path"
        d={dPath}
        markerStart={
          !sourceNode?.data?.source
            ? `url(#${`triangle-${props.id}`}-start)`
            : ''
        }
        markerEnd={
          !targetNode?.data?.target ? `url(#${`triangle-${props.id}`}-end)` : ''
        }
      />
      <path
        id={`${id}-interactable`}
        style={{
          stroke: 'transparent',
          strokeWidth: 10,
          cursor: isLineBound ? 'crosshair' : 'move',
        }}
        className="react-flow__edge-path"
        d={dPath}
        onMouseDown={ev => {
          const position = getTranslatedPositions(ev);
          if (position) {
            addDraglistener();
            const offsets = associatedLineNodes.map(node => ({
              nodeId: node.id,
              xOffset: position.x - node.position.x,
              yOffset: position.y - node.position.y,
            }));
            groupPositionOffsetsRefs.current = offsets;
            // NOTE: have to delay a few ms because force diagram update forces a rerender
            setTimeout(() => {
              setRecentlySelectedNodeIds([source]);
              setNodes(currentNodes => {
                const updatedNodes = currentNodes.map(node => ({
                  ...node,
                  selected: source === node.id ? true : node.selected,
                }));
                return updatedNodes;
              });
            }, 200);
          }
        }}
      />
    </>
  );
};

export default Component;
