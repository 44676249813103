import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import theme from '../../../constants/themes';
import { MyBoardDropdown } from '../../atoms/MyBoardDropdown';
import {
  CardGrid,
  CardList,
  ActiveCardGrid,
  ActiveCardList,
} from '../../atoms/Icons';

const Container = styled.div`
  width: 248px;
  height: 34px;
  background-color: ${theme.colors.white};
  box-shadow: 2px 2px 9px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  padding: 3px;

  @media ${theme.breakpoints.mobile} {
    margin-top: 10px;
    justify-content: space-between;
    width: 100%;
    padding: 3px 0;
  }
`;

const CardGridBtnWrapper = styled.div`
  height: 34px;
  width: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 17px;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const CardListBtnWrapper = styled.div`
  height: 34px;
  width: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 17px;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const DropDownWrapper = styled(MyBoardDropdown)`
  @media ${theme.breakpoints.mobile} {
    margin-left: 3px;
  }
`;

export type Props = {
  selectedSort: string;
  layoutFilter: 'card' | 'list';
  onChangeSort: (selected: string) => void;
  onChangeLayout: (layoutFilter: string) => void;
};

const Component = ({
  selectedSort,
  layoutFilter = 'card',
  onChangeSort,
  onChangeLayout,
}: Props): React.ReactElement => {
  const { t } = useTranslation();

  const handleChangeLayout = (layoutClicked: string) => {
    if (layoutFilter !== layoutClicked) {
      onChangeLayout(layoutClicked);
    }
  };

  return (
    <Container>
      <DropDownWrapper
        values={[
          {
            id: t('updatedAtSort'),
            label: t('updatedAtSort'),
          },
          {
            id: t('createdBySort'),
            label: t('createdBySort'),
          },
          {
            id: t('boardNameSort'),
            label: t('boardNameSort'),
          },
        ]}
        selected={selectedSort}
        onChangeSelected={onChangeSort}
      />
      <CardGridBtnWrapper
        onClick={() => {
          handleChangeLayout('card');
        }}>
        {layoutFilter === 'card' ? <ActiveCardGrid /> : <CardGrid />}
      </CardGridBtnWrapper>
      <CardListBtnWrapper
        onClick={() => {
          handleChangeLayout('list');
        }}>
        {layoutFilter === 'list' ? <ActiveCardList /> : <CardList />}
      </CardListBtnWrapper>
    </Container>
  );
};

export default Component;
