import React, { useRef, useEffect, useCallback, useState } from 'react';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { useInView } from 'react-intersection-observer';
import theme from '../../../constants/themes';
import { WhiteClose } from '../../atoms/Icons';
import { MessageItem } from '../../atoms/MessageItem';
import { ChatMessages } from '../../../hooks/chat';
import { handleTextOverflow } from '../../../utils/strings';
import { Tooltip } from '../../atoms/Tooltip';

const StyledContainer = styled(Box)`
  background-color: ${theme.colors.bgPaleGray};
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.05);
  width: 320px;
  border-radius: 25px 25px 0px 0px;
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const StyledHeader = styled(Box)`
  display: flex;
  align-items: center;
  padding: 5px 15px;
  border-radius: 25px 25px 0px 0px;
`;
const StyledHeaderLabel = styled.span`
  font-family: ${theme.fonts.primary};
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: ${theme.colors.white};
  padding-top: 2px;
  flex: 1;
`;
const StyledCloseBtn = styled(Box)`
  display: flex;
  cursor: pointer;
  &:active {
    background-color: #e1b50a;
  }
  border-radius: 50px;
  padding: 5px;
`;
const StyledBody = styled(Box)`
  padding: 12px 33px 0;
  overflow: auto;
  height: 70vh;
`;
const HiddenDiv = styled.div`
  height: 12px;
`;

const MessageItemWrapper = styled.div`
  .read-mention {
    animation-name: readMention;
    animation-duration: 2s;
    animation-iteration-count: 2;
    animation-direction: alternate;
    animation-timing-function: linear;
    @keyframes readMention {
      from {
        background-color: white;
      }
      to {
        background-color: #ffe066;
      }
    }
  }
`;

const LoaderContainer = styled(Box)`
  display: flex;
  justify-content: center;
  height: 50%;
  align-items: end;
`;

const ErrorWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 5px 0 2px;
`;

const ErrorMessage = styled.span`
  font-family: ${theme.fonts.primary};
  font-weight: 400;
  font-size: 13px;
  line-height: 14px;
  color: ${theme.colors.textError};
`;

type Props = {
  messages: ChatMessages;
  onClose: () => void;
  template: string;
  isLoading?: boolean;
  isScrollMention?: boolean;
  error?: string;
  onMouseEnter?: (value: boolean) => void;
};

const Component = ({
  messages = [],
  onClose,
  template,
  isLoading = false,
  isScrollMention = false,
  error,
  onMouseEnter = () => {},
}: Props): React.ReactElement => {
  const [inViewRef, inView] = useInView();
  const [chatMessages, setChatMessages] = useState<ChatMessages>([]);

  const _bottomItemRef = useRef<HTMLDivElement>(null);
  const mentionRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setChatMessages(messages);
  }, [messages]);

  useEffect(() => {
    scrollToBottom();
  }, [chatMessages]);

  useEffect(() => {
    if (isScrollMention) {
      mentionRef &&
        mentionRef?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
    }
  }, [isScrollMention]);

  const scrollToBottom = () => {
    _bottomItemRef &&
      _bottomItemRef?.current?.scrollIntoView({
        block: 'nearest',
        inline: 'nearest',
      });
  };

  const setRefs = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (node: any) => {
      mentionRef.current = node;
      inViewRef(node);
    },
    [chatMessages],
  );

  useEffect(() => {
    if (inView) {
      setTimeout(() => {
        setChatMessages(prev => {
          for (let i = 0; i < prev.length; i++) {
            if (prev[i].scrollToUnreadMention) {
              prev[i] = {
                ...prev[i],
                scrollToUnreadMention: false,
              };
            }
          }
          return prev;
        });
        mentionRef.current = null;
      }, 500);
    }
  }, [inView]);

  const handleOnMouseEnter = () => {
    onMouseEnter(true);
  };

  const handleOnMouseLeave = () => {
    onMouseEnter(false);
  };

  return (
    <StyledContainer
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}>
      <StyledHeader bgcolor={theme.colors.primary}>
        <StyledHeaderLabel>
          {template && template.length <= 35 ? (
            `Chat (${template})`
          ) : (
            <Tooltip label={template || ''}>
              <span>{`Chat (${handleTextOverflow(template || '', 35)})`}</span>
            </Tooltip>
          )}
        </StyledHeaderLabel>
        <StyledCloseBtn onClick={onClose}>
          <WhiteClose />
        </StyledCloseBtn>
      </StyledHeader>
      <StyledBody>
        {isLoading ? (
          <LoaderContainer>
            <CircularProgress sx={{ color: '#b7b2b2' }} />
          </LoaderContainer>
        ) : (
          chatMessages.map((item, index) => (
            <MessageItemWrapper
              key={item.id}
              ref={item.scrollToUnreadMention ? setRefs : null}>
              <MessageItem
                boxProps={{
                  marginBottom: chatMessages.length === index + 1 ? 0 : 3,
                  className: item.scrollToUnreadMention ? 'read-mention' : '',
                }}
                key={item.id}
                role={item.role}
                name={item.name}
                displayPhoto={item.displayPhoto}
                displayTime={item.displayTime}
                message={item.message}
              />
            </MessageItemWrapper>
          ))
        )}
        <HiddenDiv ref={_bottomItemRef} />
      </StyledBody>
      {error && (
        <ErrorWrapper>
          <ErrorMessage>{error}</ErrorMessage>
        </ErrorWrapper>
      )}
    </StyledContainer>
  );
};

export default Component;
