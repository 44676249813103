import { Form } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import theme from '../../../constants/themes';
import { Button } from '../../atoms/Button';
import { Checkbox } from '../../atoms/Checkbox';
import { InlineErrorToast } from '../../atoms/InlineErrorToast';
import { Link } from '../../atoms/Link';
import { TextInput } from '../../atoms/TextInput';

const FormWrapper = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 452px;
  height: max-content;

  @media ${theme.breakpoints.mobile} {
    width: 100%;
  }
`;

const SignInLabel = styled.p`
  font-family: ${theme.fonts.primary};
  font-weight: 600;
  font-size: 33px;
  line-height: 130%;
  color: ${theme.colors.black};
  padding: 0;
  margin: 0;

  @media ${theme.breakpoints.mobile} {
    padding-top: 50px;
  }
`;

const ApiErrorWrapper = styled.div`
  width: 100%;
  height: min-content;
  margin-top: 16px;
`;

const InputLabel = styled.p`
  font-family: ${theme.fonts.primary};
  font-weight: 400;
  font-size: 16px;
  line-height: 175%;
  color: ${theme.colors.text02};
  width: 100%;
  text-align: left;
  padding: 0;
  margin: 0;
  margin-top: 16px;
`;

const StyledInput = styled(TextInput)`
  width: 100%;
  height: 44px;
  margin-top: 8px;
`;

const ForgotPasswordContainer = styled.div`
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 22px;
`;

const StyledForgotPassLink = styled(Link)``;

const ButtonWrapper = styled.div`
  && {
    width: 188px;
    height: 44px;
    border-radius: 5px;
    margin-top: 24px;
  }
`;

const ErrorMessage = styled.span`
  width: 100%;
  font-family: ${theme.fonts.primary};
  font-weight: 400;
  font-size: 13px;
  line-height: 14px;
  color: ${theme.colors.textError};
  margin-top: 8px;
  text-align: left;
`;

export type Props = {
  email: string;
  password: string;
  isRememberMeToggled: boolean;
  isSignInDisabled: boolean;
  errors: {
    email?: string;
    password?: string;
    apiError?: string;
  };
  onChangeEmail: (email: string) => void;
  onChangePassword: (password: string) => void;
  onToggleRememberMe: (isRemember: boolean) => void;
  onPressSignIn: () => void;
  onBlurEmail: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlurPassword: (e: React.FocusEvent<HTMLInputElement>) => void;
  setFieldTouched: (
    field: string,
    isTouched?: boolean | undefined,
    shouldValidate?: boolean | undefined,
  ) => void;
  isLoggingIn: boolean;
};

const Component = ({
  email,
  password,
  isRememberMeToggled,
  isSignInDisabled,
  errors,
  onChangeEmail,
  onChangePassword,
  onToggleRememberMe,
  onPressSignIn,
  onBlurEmail,
  setFieldTouched,
  onBlurPassword,
  isLoggingIn,
}: Props): React.ReactElement => {
  const { t } = useTranslation();
  const [apiError, setApiError] = useState(errors.apiError);

  const handleOnPressSignIn = () => {
    setApiError('');
    onPressSignIn();
  };

  useEffect(() => {
    setApiError(errors.apiError);
  }, [errors.apiError]);

  return (
    <FormWrapper>
      <SignInLabel>{t('signInTitle')}</SignInLabel>
      {apiError && (
        <ApiErrorWrapper>
          <InlineErrorToast
            message={apiError}
            onClose={() => setApiError('')}
          />
        </ApiErrorWrapper>
      )}
      <InputLabel>{t('emailLabel')}</InputLabel>
      <StyledInput
        onChangeText={(value: string) => {
          setFieldTouched('email');
          onChangeEmail(value);
        }}
        value={email}
        isError={!!errors.email}
        onBlur={onBlurEmail}
      />
      {errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}
      <InputLabel>{t('passwordLabel')}</InputLabel>
      <StyledInput
        type="password"
        value={password}
        onChangeText={(value: string) => {
          setFieldTouched('password');
          onChangePassword(value);
        }}
        isError={!!errors.password}
        onBlur={onBlurPassword}
      />
      {errors.password && <ErrorMessage>{errors.password}</ErrorMessage>}
      <ForgotPasswordContainer>
        <Checkbox
          isCheckedValue={isRememberMeToggled}
          label={t('rememberMe')}
          onChangeCheckbox={(value: boolean) => {
            setFieldTouched('isRemember');
            onToggleRememberMe(value);
          }}
        />
        <StyledForgotPassLink
          to="/forgot-password"
          label={t('forgotPassword')}
        />
      </ForgotPasswordContainer>
      <ButtonWrapper>
        <Button
          theme="secondary"
          title={t('login')}
          onPress={handleOnPressSignIn}
          disabled={isSignInDisabled}
          type="submit"
          isLoading={isLoggingIn}
        />
      </ButtonWrapper>
    </FormWrapper>
  );
};

export default Component;
