import React from 'react';

type Props = {
  [key: string]: string | number | Function;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 22L23.9975 21.9988V2.06114L15.4338 0L0.0386693 4.8219L0 4.82817L0.00189894 19.2445L5.25549 17.5294V5.79995L15.4338 3.77006L15.4323 21.0154L0.0105305 19.2445L15.4323 23.9947V24L23.9975 22.0227V22H24Z"
        fill="#EA3E23"
      />
    </svg>
  );
};

export default Component;
