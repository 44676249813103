import AuthService from '../../usecases/ports/AuthService';
import { HttpAdapter } from '../../usecases/ports/HttpAdapter';
import { Customer, User } from '../../domain/entities/user';
import {
  GenericError,
  LoginValidationError,
} from '../../domain/entities/errors';
import { AxiosError } from 'axios';
import { URLsType } from '../../constants/urls';
import { GenericResponse } from '../../domain/entities/rest';
export default class AuthServiceRestImpl implements AuthService {
  httpAdapter: HttpAdapter;

  urls: URLsType['auth'];

  constructor(httpAdapter: HttpAdapter, urls: URLsType['auth']) {
    this.httpAdapter = httpAdapter;
    this.urls = urls;
  }

  async signIn(
    email: string,
    password: string,
    remember: boolean,
    invitationCode: string,
  ): Promise<{
    user: object | null;
    invitation: {
      status: boolean;
      message: string;
    };
  }> {
    const params = {
      email,
      password,
      remember: remember ? 1 : 0,
      invitation_code: invitationCode !== '' ? invitationCode : undefined,
      sns: 0,
    };

    const response = await this.httpAdapter.post(this.urls.login, params);

    if (response.data.status_code === 200) {
      const data: {
        data: User;
        status_code: number;
        invitation: { status: boolean; message: string };
      } = response.data;

      const planSnakeCase = response.data?.data?.customer?.plan;
      if (planSnakeCase) {
        const plan = {
          id: planSnakeCase?.id,
          type: planSnakeCase?.type,
          description: planSnakeCase?.description,
          pricing: planSnakeCase?.pricing,
          content: planSnakeCase?.content,
          buttonName: planSnakeCase?.button_name,
          customerLimit: planSnakeCase?.customer_limit,
          channelLimit: planSnakeCase?.channel_limit,
          price: planSnakeCase?.price,
          themeColor: planSnakeCase?.theme_color,
        };

        if (plan) {
          response.data.data.customer.plan = plan;
        }
      }

      return {
        user: {
          ...data.data,
          isVerified: !!data.data.customer.email_verified_at,
        },
        invitation: data.invitation,
      };
    } else {
      if (response.data.status_code !== 400) {
        const error: GenericError = response.data;

        throw new Error(error.message);
      } else {
        const error: LoginValidationError = response.data;
        const message = '';
        message.concat(error.errors.email || '');
        message.concat(error.errors.password || '');
        message.concat(error.errors.remember || '');
        message.concat(error.errors.sns || '');

        throw new Error(message);
      }
    }
  }

  async signInUsingGoogle(
    email: string,
    name: string,
    googleId: string,
    invitationCode: string,
    avatar?: string,
  ): Promise<{
    user: object | null;
  }> {
    const params = {
      email,
      remember: 0,
      sns: 2,
      invitation_code: invitationCode,
      ...(!!name && { fullname: name }),
      ...(!!avatar && { avatar }),
      sns_id: googleId,
    };

    const response = await this.httpAdapter.post(this.urls.login, params);

    if (response.data.status_code === 200) {
      const data: { data: User; status_code: number } = response.data;

      return {
        user: {
          ...data.data,
          isVerified: !!data.data.customer.email_verified_at,
        },
      };
    } else {
      if (response.data.status_code !== 400) {
        const error: GenericError = response.data;

        throw new Error(error.message);
      } else {
        const error: LoginValidationError = response.data;
        const message = '';
        message.concat(error.errors.email || '');
        message.concat(error.errors.password || '');
        message.concat(error.errors.remember || '');
        message.concat(error.errors.sns || '');

        throw new Error(message);
      }
    }
  }

  async signInUsingFb(
    email: string,
    name: string,
    userID: string,
    invitationCode: string,
    avatar: string,
  ): Promise<{
    user: object | null;
  }> {
    const params = {
      ...(!!email && { email }),
      remember: 0,
      sns: 1,
      invitation_code: invitationCode,
      ...(!!name && { fullname: name }),
      sns_id: userID,
      ...(!!avatar && { avatar: avatar }),
    };

    const response = await this.httpAdapter.post(this.urls.login, params);

    if (response.data.status_code === 200) {
      const data: { data: User; status_code: number } = response.data;

      return {
        user: {
          ...data.data,
          isVerified: !!data.data.customer.email_verified_at,
        },
      };
    } else {
      if (response.data.status_code !== 400) {
        const error: GenericError = response.data;

        throw new Error(error.message);
      } else {
        const error: LoginValidationError = response.data;
        const message = '';
        message.concat(error.errors.email || '');
        message.concat(error.errors.password || '');
        message.concat(error.errors.remember || '');
        message.concat(error.errors.sns || '');

        throw new Error(message);
      }
    }
  }

  async signInUsingMicrosoft(
    email: string,
    name: string,
    accountId: string,
    invitationCode: string,
    avatar: string,
  ): Promise<{
    user: object | null;
  }> {
    const params = {
      email,
      remember: 0,
      sns: 3,
      invitation_code: invitationCode,
      ...(!!name && { fullname: name }),
      sns_id: accountId,
      ...(!!avatar && { avatar: avatar }),
    };

    const response = await this.httpAdapter.post(this.urls.login, params);

    if (response.data.status_code === 200) {
      const data: { data: User; status_code: number } = response.data;

      return {
        user: {
          ...data.data,
          isVerified: !!data.data.customer.email_verified_at,
        },
      };
    } else {
      if (response.data.status_code !== 400) {
        const error: GenericError = response.data;

        throw new Error(error.message);
      } else {
        const error: LoginValidationError = response.data;
        const message = '';
        message.concat(error.errors.email || '');
        message.concat(error.errors.password || '');
        message.concat(error.errors.remember || '');
        message.concat(error.errors.sns || '');

        throw new Error(message);
      }
    }
  }

  signOut = async (): Promise<void> => {
    await this.httpAdapter.get(this.urls.logout, {});
  };

  signUp = async (
    name: string,
    email: string,
    password: string,
    invitationCode: string,
  ): Promise<object | null> => {
    const params = {
      email,
      password,
      fullname: name,
      invitation_code: invitationCode,
      remember: 0,
      sns: 0,
    };

    const response = await this.httpAdapter.post(this.urls.signup, params);

    if (response.data.status_code === 200) {
      const data: { data: User; status_code: number } = response.data;

      return {
        user: {
          ...data.data,
          isVerified: false,
        },
      };
    } else {
      if (response.data.status_code !== 400) {
        const error: GenericError = response.data;

        throw new Error(error.message);
      } else {
        const error: LoginValidationError = response.data;
        const message = '';
        message.concat(error.errors.email || '');
        message.concat(error.errors.password || '');
        message.concat(error.errors.remember || '');
        message.concat(error.errors.sns || '');

        throw new Error(message);
      }
    }
  };

  async signUpUsingGoogle(
    email: string,
    name: string,
    googleId: string,
    invitationCode: string,
  ): Promise<{
    user: object | null;
  }> {
    const params = {
      email,
      remember: 0,
      sns: 2,
      invitation_code: invitationCode,
      fullname: name,
      sns_id: googleId,
    };

    const response = await this.httpAdapter.post(this.urls.signup, params);

    if (response.data.status_code === 200) {
      const data: { data: User; status_code: number } = response.data;

      return {
        user: {
          ...data.data,
          isVerified: !!data.data.customer.email_verified_at,
        },
      };
    } else {
      if (response.data.status_code !== 400) {
        const error: GenericError = response.data;

        throw new Error(error.message);
      } else {
        const error: LoginValidationError = response.data;
        const message = '';
        message.concat(error.errors.email || '');
        message.concat(error.errors.password || '');
        message.concat(error.errors.remember || '');
        message.concat(error.errors.sns || '');

        throw new Error(message);
      }
    }
  }

  async signUpUsingFb(
    email: string,
    name: string,
    userID: string,
    invitationCode: string,
  ): Promise<{
    user: object | null;
  }> {
    let params = {};
    if (!!email) {
      params = {
        email,
        remember: 0,
        sns: 1,
        invitation_code: invitationCode,
        fullname: name,
        sns_id: userID,
      };
    } else {
      params = {
        remember: 0,
        sns: 1,
        invitation_code: invitationCode,
        fullname: name,
        sns_id: userID,
      };
    }

    const response = await this.httpAdapter.post(this.urls.signup, params);

    if (response.data.status_code === 200) {
      const data: { data: User; status_code: number } = response.data;

      return {
        user: {
          ...data.data,
          isVerified: !!data.data.customer.email_verified_at,
        },
      };
    } else {
      if (response.data.status_code !== 400) {
        const error: GenericError = response.data;

        throw new Error(error.message);
      } else {
        const error: LoginValidationError = response.data;
        const message = '';
        message.concat(error.errors.email || '');
        message.concat(error.errors.password || '');
        message.concat(error.errors.remember || '');
        message.concat(error.errors.sns || '');

        throw new Error(message);
      }
    }
  }

  async signUpUsingMicrosoft(
    email: string,
    name: string,
    accountId: string,
    invitationCode: string,
  ): Promise<{
    user: object | null;
  }> {
    const params = {
      email,
      remember: 0,
      sns: 3,
      invitation_code: invitationCode,
      ...(!!name && { fullname: name }),
      sns_id: accountId,
    };

    const response = await this.httpAdapter.post(this.urls.signup, params);

    if (response.data.status_code === 200) {
      const data: { data: User; status_code: number } = response.data;

      return {
        user: {
          ...data.data,
          isVerified: !!data.data.customer.email_verified_at,
        },
      };
    } else {
      if (response.data.status_code !== 400) {
        const error: GenericError = response.data;

        throw new Error(error.message);
      } else {
        const error: LoginValidationError = response.data;
        const message = '';
        message.concat(error.errors.email || '');
        message.concat(error.errors.password || '');
        message.concat(error.errors.remember || '');
        message.concat(error.errors.sns || '');

        throw new Error(message);
      }
    }
  }

  resendEmailVerification = async (token: string): Promise<object> => {
    const options = {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await this.httpAdapter.get(
        this.urls.resendVerification,
        options,
      );

      return response;
    } catch (error) {
      const err = error as AxiosError;
      const data = err.response?.data as any;
      if (data.errors) {
        throw new Error(data.errors.messages);
      } else {
        throw new Error(data);
      }
    }
  };

  fetchUserDetails = async (): Promise<Customer> => {
    const response = await this.httpAdapter.get(this.urls.fetchUser, {});

    const planSnakeCase = response.data?.data?.plan;
    if (planSnakeCase) {
      const plan = {
        id: planSnakeCase?.id,
        type: planSnakeCase?.type,
        description: planSnakeCase?.description,
        pricing: planSnakeCase?.pricing,
        content: planSnakeCase?.content,
        buttonName: planSnakeCase?.button_name,
        customerLimit: planSnakeCase?.customer_limit,
        channelLimit: planSnakeCase?.channel_limit,
        price: planSnakeCase?.price,
        themeColor: planSnakeCase?.theme_color,
      };

      if (plan) {
        response.data.data.plan = plan;
      }
    }

    return response.data.data;
  };

  updateUser = async (payload: {
    id: number;
    name?: string;
    displayPhoto?: File;
    oldPassword?: string;
    newPassword?: string;
    confirmPassword?: string;
    email?: string;
  }): Promise<GenericResponse> => {
    const formData = new FormData();
    formData.append('customer_id', `${payload.id}`);
    formData.append('_method', 'PATCH');
    if (payload.name) {
      formData.append('fullname', payload.name);
    }
    if (payload.email) {
      formData.append('email', payload.email);
    }
    if (payload.displayPhoto) {
      formData.append('file_name', payload.displayPhoto.name);
      formData.append('avatar', payload.displayPhoto);
    }
    if (payload.oldPassword) {
      formData.append('old_password', payload.oldPassword);
    }
    if (payload.newPassword) {
      formData.append('password', payload.newPassword);
    }
    if (payload.confirmPassword) {
      formData.append('password_confirmation', payload.confirmPassword);
    }
    const response = await this.httpAdapter.post(
      this.urls.updateUser(payload.id),
      formData,
    );

    return { ...response.data, statusCode: response.data.status_code };
  };

  deleteUser = async (payload: { id: number }): Promise<GenericResponse> => {
    const response = await this.httpAdapter.delete(
      this.urls.deleteUser(payload.id),
      { customer_id: payload.id },
    );
    return response.data;
  };

  forgotPassword = async (email: string): Promise<GenericResponse> => {
    const params = { email };

    const response = await this.httpAdapter
      .post(this.urls.forgotPassword, params)
      .catch(error => {
        const err = error as AxiosError;
        const data = err.response?.data as any;
        if (data?.status_code === 422) {
          const errors = data?.errors;
          if (errors?.email) {
            throw new Error(errors.email);
          }
          if (errors?.message) {
            throw new Error(errors.message);
          }
        }
        throw new Error(err.message);
      });

    return { ...response.data, message: response.data.message };
  };

  resetPassword = async (
    token: string,
    password: string,
    passwordConfirmation: string,
  ): Promise<GenericResponse> => {
    const params = {
      token,
      password,
      password_confirmation: passwordConfirmation,
    };

    const response = await this.httpAdapter
      .post(this.urls.resetPassword, params)
      .catch(error => {
        const err = error as AxiosError;
        const data = err.response?.data as any;
        if (data?.status_code === 422) {
          const errors = data?.errors || {};
          const errorKeys = Object.keys(errors);
          const mappedErrorMsgs = errorKeys.map(key => {
            const msgsArr = errors?.[key].join('\n') || '';
            return msgsArr;
          });
          const errorMsgs = mappedErrorMsgs.join('\n');
          throw new Error(errorMsgs);
        }
        throw new Error(err.message);
      });

    return { ...response.data, message: response.data.message };
  };
}
