import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: inline-flex;
  flex-direction: row;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.25);
  background-color: #f9f8f8;
  width: 100px;
  height: 35px;
  font-size: 13px;
`;

const Value = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 2;
  border-left: 1px solid rgba(220, 220, 220, 0.38);
  border-right: 1px solid rgba(220, 220, 220, 0.38);
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  cursor: pointer;
`;

export type Props = {
  className?: string;
  onIncrement: () => void;
  onDecrement: () => void;
  border?: number;
};

const Component = ({
  className,
  onIncrement,
  onDecrement,
  border = 1,
}: Props): React.ReactElement => {
  return (
    <Wrapper className={className}>
      <Buttons onClick={onDecrement}>-</Buttons>
      <Value>{`${border} px`}</Value>
      <Buttons onClick={onIncrement}>+</Buttons>
    </Wrapper>
  );
};

export default Component;
