import React, { useState, useRef, useCallback } from 'react';
import socketIOClient, { Socket } from 'socket.io-client';
import { SocketHooks, SocketHooksContext } from '.';

type Props = {
  value?: SocketHooks;
  children?: React.ReactElement;
};

const SOCKET_URL = process.env.REACT_APP_SOCKET_SERVER_URL || '';

const Provider = (props: Props): React.ReactElement => {
  const socketRef = useRef<Socket>();

  const [socket, setSocket] = useState<Socket | null>(null);

  const initializedBoardCodeRef = useRef<string | undefined>();

  const initializedSocket = useCallback((boardCode?: string) => {
    socketRef.current = socketIOClient(SOCKET_URL, {
      transports: ['websocket', 'polling'],
      forceNew: true,
    });
    initializedBoardCodeRef.current = boardCode;
    setSocket(socketRef.current);
  }, []);

  const disconnectSocket = () => {
    socketRef.current?.disconnect();
  };

  const useSocket: SocketHooks['useSocket'] = React.useMemo(
    () => ({
      socketInit: initializedSocket,
      disconnectSocket,
      socket,
      initializedBoardCode: initializedBoardCodeRef.current,
    }),
    [socket],
  );

  return (
    <SocketHooksContext.Provider
      value={{
        useSocket,
      }}
      {...props}
    />
  );
};

export default Provider;
