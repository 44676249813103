import { Box, Modal } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import theme from '../../../constants/themes';
import { Close } from '../../atoms/Icons';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import { ToastData } from '../../templates/DiagramEditor/types';
import { Tooltip } from '../../atoms/Tooltip';
import { handleTextOverflow } from '../../../utils/strings';

export type Props = {
  children: React.ReactElement;
  isOpen: boolean;
  onClose?: () => void;
  title?: string;
  showTitle?: boolean;
  className?: string;
  toastTemplate?: ToastData | null;
};

const Row = styled.div`
  display: flex;
  flex: 1;
`;
const Title = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  margin: 18px 0;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  letter-spacing: -0.02em;
  cursor: default;
`;
const StyledClose = styled(Close)`
  right: 7px;
  position: absolute;
  cursor: pointer;
`;

const StackWrapper = styled(Stack)`
  margin-bottom: 18px;
`;

const CustomizeAlert = styled(Alert)`
  justify-content: center;
`;

const CustomizeBox = styled(Box)`
  @media ${theme.breakpoints.mobile} {
    width: 90%;
  }

  @media ${theme.breakpoints.tablet} {
    width: 90%;
  }
`;

const Component = ({
  children,
  isOpen,
  onClose,
  title = '',
  showTitle = true,
  className,
  toastTemplate,
}: Props): React.ReactElement => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <CustomizeBox
        className={className}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          outline: 'none',
          backgroundColor: theme.colors.white,
          display: 'flex',
          flexDirection: 'column',
          padding: 1,
        }}>
        {showTitle && (
          <Row>
            <Title>
              {title && title.length <= 40 ? (
                `${title}`
              ) : (
                <Tooltip label={title || ''}>
                  <span>{handleTextOverflow(title || '', 40)}</span>
                </Tooltip>
              )}
            </Title>
            {onClose && <StyledClose onClick={onClose} />}
          </Row>
        )}
        {toastTemplate ? (
          <StackWrapper sx={{ width: '100%' }} spacing={2}>
            <CustomizeAlert severity={toastTemplate.type}>
              {toastTemplate.message}
            </CustomizeAlert>
          </StackWrapper>
        ) : (
          ''
        )}
        {children}
      </CustomizeBox>
    </Modal>
  );
};

export default Component;
