import React from 'react';

type Props = {
  [key: string]: string | number | Function;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M6.75429 11.5177L6.75348 11.5171C4.48792 9.97909 3.5268 8.93751 3.00954 8.30834C1.89944 6.95569 1.39121 5.60011 1.40621 4.14828L1.40621 4.14811C1.42175 2.5909 2.66949 1.34253 4.15994 1.34253C5.16618 1.34253 5.89854 1.86387 6.37788 2.37903L6.37787 2.37904L6.3806 2.38192C6.46018 2.46611 6.55611 2.53317 6.66251 2.579C6.76892 2.62483 6.88355 2.64847 6.9994 2.64847C7.11525 2.64847 7.22988 2.62483 7.33628 2.579C7.44268 2.53317 7.53861 2.46611 7.6182 2.38192L7.61821 2.38193L7.62073 2.37923C8.10089 1.86376 8.83272 1.34253 9.83885 1.34253C11.3293 1.34253 12.577 2.5909 12.5926 4.14811L12.5926 4.14828C12.6076 5.60002 12.0988 6.95565 10.9892 8.30843L10.9891 8.30851C10.4723 8.93877 9.51157 9.97861 7.24532 11.5171L7.2445 11.5177C7.17231 11.5669 7.08704 11.5934 6.99968 11.5937C6.91212 11.5935 6.82664 11.567 6.75429 11.5177Z"
        fill="#E74040"
        stroke="#E74040"
      />
    </svg>
  );
};

export default Component;
