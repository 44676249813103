import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import themes from '../../../constants/themes';
import { Plus } from '../../atoms/Icons';
import theme from '../../../constants/themes';

const Card = styled(Box)`
  width: 250px;
  height: 195px;
  background: ${themes.colors.white};
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  &:hover {
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.2);

    #plus-wrapper {
      background-color: ${themes.colors.hoverSecondaryBtn};
    }
  }

  @media ${theme.breakpoints.mobile} {
    width: 100%;
  }
`;

const PlusWrapper = styled.div`
  width: 250px;
  height: 150px;
  background-color: ${themes.colors.notifToastBorder};
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: ${themes.colors.hoverSecondaryBtn};
  }

  @media ${theme.breakpoints.mobile} {
    width: 100%;
  }
`;

const StyledPlusIcon = styled(Plus)`
  width: 50px;
  height: 50px;
`;

const FooterText = styled.p`
  font-family: ${themes.fonts.primary};
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: ${themes.colors.textColor};
  margin: 0;
  padding: 0;
  margin: 4.5px 7px;
`;

export type Props = {
  onPressAddBoard: () => void;
  className?: string;
};

const Component = ({
  onPressAddBoard,
  className,
}: Props): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <Card onClick={onPressAddBoard} className={className}>
      <PlusWrapper id="plus-wrapper">
        <StyledPlusIcon />
      </PlusWrapper>
      <FooterText>{t('addBoard')}</FooterText>
    </Card>
  );
};

export default Component;
