import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import theme from '../../../constants/themes';
import {
  WhiteClose,
  DollarMind,
  Board as BoardIcon,
  YellowStar,
  Team,
  YellowTrophy,
} from '../../atoms/Icons';
import { BoardCard } from '../../molecules/BoardCard';
import defaultImage from '../../../assets/images/role-detail-male.png';
import { BoardCustomers } from '../../../domain/entities/board';

const StyledContainer = styled(Box)`
  background-color: ${theme.colors.primary};
  width: 572px;
  min-height: 583px;
  left: 184px;
  filter: drop-shadow(2px 2px 12px rgba(0, 0, 0, 0.25));
  height: auto;
  margin-left: 15px;
`;

const StyledHeader = styled(Box)<{ shouldHide?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 18px;
  height: 94px;
  visibility: ${props => (props.shouldHide ? 'hidden' : 'visible')};
`;

const StyledBody = styled(Box)`
  display: flex;
  padding: 0 32px;
  flex-direction: column;
`;

const StyledHeaderName = styled.span<{ fontSize: number }>`
  font-family: ${theme.fonts.primary};
  font-style: normal;
  font-weight: 700;
  font-size: ${props => `${props.fontSize}px`};
  line-height: 58px;
  letter-spacing: 0.1em;
  color: ${theme.colors.white};
  text-shadow: -1px 1px 0 #000, 1px 1px 0 #000, 1px -1px 0 #000,
    -1px -1px 0 #000, 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

const StyledCustomerWrapper = styled(Box)`
  display: flex;
  margin-bottom: 22px;
`;

const CustomerAvatarWrapper = styled(Box)`
  flex: 1;
  margin-right: 27px;
  width: 240px;
  height: 264px;
  overflow: hidden;
  position: relative;
`;

const CustomerAvatar = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const CustomerRoleDetailWrapper = styled(Box)`
  flex: 1;
`;

const CustomerRoleDetailHeader = styled(Box)`
  display: flex;
  background-color: ${theme.colors.white};
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 14px;
  margin-bottom: 10px;
`;
const CustomerRoleDetailBody = styled(Box)`
  background-color: ${theme.colors.white};
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  height: 177px;
  padding: 14px 12px 10px;

  font-family: ${theme.fonts.primary};
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: ${theme.colors.textColor};
`;

const StyledCloseButton = styled(Box)`
  position: absolute;
  width: 15px;
  height: 15px;
  right: 15px;
  top: 15px;
  cursor: pointer;
`;

const StyledLabel = styled.span`
  font-family: ${theme.fonts.primary};
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: ${theme.colors.textColor};
  flex: 1;
`;

const StyledRoleLabel = styled.span`
  font-family: ${theme.fonts.primary};
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: ${theme.colors.textColor};
  flex: 1;
  text-align: center;
`;

const StyledResultsWrapper = styled(Box)`
  height: 50px;
  background-color: ${theme.colors.white};
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  margin: 5px 0 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
`;

const ResultItemWrapper = styled(Box)`
  display: flex;
  align-items: center;
`;

const ResultItemLabel = styled.div`
  font-family: ${theme.fonts.primary};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: -0.02em;
  color: #6f6f6f;
  margin-left: 5px;
  display: flex;
  height: 20px;
`;

const BoardCardWrapper = styled(Box)<{ isBetween: boolean }>`
  display: flex;
  justify-content: ${props =>
    props.isBetween ? 'space-between' : 'space-around'};
  margin: 5px 0 15px;
`;

const StyledBoardIcon = styled(BoardIcon)`
  width: 20px;
  height: 20px;

  path {
    fill: ${theme.colors.primary};
  }
`;

const StyledTrophyIcon = styled(YellowTrophy)`
  width: 20px;
  height: 20px;
`;

const IconWrapper = styled.div`
  display: flex;
`;

export type Props = {
  customer: BoardCustomers | undefined;
  onClose: () => void;
};

const Component = ({ customer, onClose }: Props): React.ReactElement => {
  const textRef = useRef<HTMLSpanElement>(null);
  const [fontSize, setFontSize] = useState(40);
  const [isSettingFontSize, setIsSettingFontSize] = useState(true);

  useEffect(() => {
    if (textRef.current && textRef.current.clientWidth > 525) {
      setIsSettingFontSize(true);
      if (fontSize > 1) {
        setFontSize(fontSize - 1);
      } else {
        setFontSize(1);
      }
    } else {
      setIsSettingFontSize(false);
    }
  }, [customer?.name, fontSize]);

  const renderResultsItem = (data: {
    boards: number;
    votes: number;
    mvps: number;
    team: number;
  }) => {
    const resultsData = [
      {
        icon: <StyledBoardIcon />,
        value: data.boards,
        key: 1,
      },
      {
        icon: <YellowStar />,
        value: data.votes,
        key: 2,
      },
      {
        icon: <StyledTrophyIcon />,
        value: data.mvps,
        key: 3,
      },
      {
        icon: <Team />,
        value: data.team,
        key: 4,
      },
    ];

    return (
      <>
        {resultsData.map(
          (item: {
            icon: React.ReactElement;
            value: string | number;
            key: number;
          }) => (
            <ResultItemWrapper key={item.key}>
              <IconWrapper>{item.icon}</IconWrapper>
              <ResultItemLabel>{item.value}</ResultItemLabel>
            </ResultItemWrapper>
          ),
        )}
      </>
    );
  };

  return (
    <StyledContainer>
      <StyledHeader shouldHide={isSettingFontSize}>
        <StyledHeaderName fontSize={fontSize} ref={textRef}>
          {customer?.name}
        </StyledHeaderName>
        <StyledCloseButton onClick={onClose}>
          <WhiteClose />
        </StyledCloseButton>
      </StyledHeader>
      <StyledBody>
        <StyledCustomerWrapper>
          <CustomerAvatarWrapper>
            <CustomerAvatar
              src={customer?.roleImage || defaultImage}
              alt="Img"
            />
          </CustomerAvatarWrapper>
          <CustomerRoleDetailWrapper>
            <CustomerRoleDetailHeader>
              <StyledRoleLabel>{customer?.role}</StyledRoleLabel>
              <DollarMind />
            </CustomerRoleDetailHeader>
            <CustomerRoleDetailBody>
              {customer?.roleContent}
            </CustomerRoleDetailBody>
          </CustomerRoleDetailWrapper>
        </StyledCustomerWrapper>
        <StyledLabel>実績</StyledLabel>
        <StyledResultsWrapper>
          {renderResultsItem({
            boards: customer?.boards || 0,
            votes: customer?.stars || 0,
            mvps: customer?.trophies || 0,
            team: customer?.team || 0,
          })}
        </StyledResultsWrapper>
        <StyledLabel>最新ボード</StyledLabel>
        <BoardCardWrapper isBetween={(customer?.latestBoards?.length || 0) > 2}>
          {(customer?.latestBoards || []).slice(0, 3).map(board => (
            <BoardCard
              key={board.code}
              isRoleDetail={true}
              boardImg={board.diagramFile}
              boardName={board.name}
              createdBy={''}
              createdAt={''}
              users={board.members.map(member => member.avatar)}
              boardId={0}
              isFavorite={false}
              onPressBoardCard={() => {}}
              onPressFavorite={() => {}}
            />
          ))}
        </BoardCardWrapper>
      </StyledBody>
    </StyledContainer>
  );
};

export default Component;
