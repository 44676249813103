import React, { SVGProps } from 'react';

type Props = SVGProps<SVGSVGElement>;

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M9.33735 3C9.19683 3.00032 9.05816 3.05117 8.93158 3.1488C8.805 3.24642 8.69376 3.38832 8.60608 3.564L2.88717 15H11.2499C11.4985 15 11.737 15.158 11.9128 15.4393C12.0886 15.7206 12.1874 16.1022 12.1874 16.5C12.1874 17.6935 12.4837 18.8381 13.0112 19.682C13.5387 20.5259 14.2541 21 15 21C15.7459 21 16.4613 20.5259 16.9888 19.682C17.5163 18.8381 17.8126 17.6935 17.8126 16.5C17.8126 16.1022 17.9114 15.7206 18.0872 15.4393C18.263 15.158 18.5015 15 18.7501 15H27.1128L21.3939 3.564C21.3062 3.38832 21.195 3.24642 21.0684 3.1488C20.9418 3.05117 20.8032 3.00032 20.6627 3H9.33735ZM7.14354 1.689C7.40677 1.16251 7.74058 0.737384 8.1203 0.445039C8.50002 0.152694 8.91595 0.000601288 9.33735 0H20.6627C21.0841 0.000601288 21.5 0.152694 21.8797 0.445039C22.2594 0.737384 22.5932 1.16251 22.8565 1.689L29.7942 15.564C29.8724 15.7203 29.9303 15.9001 29.9645 16.0928C29.9986 16.2855 30.0083 16.4872 29.9929 16.686L29.2616 26.058C29.1766 27.1467 28.8459 28.1481 28.3318 28.8741C27.8176 29.6 27.1554 30.0004 26.4697 30H3.53031C2.84459 30.0004 2.18238 29.6 1.66823 28.8741C1.15408 28.1481 0.823392 27.1467 0.738361 26.058L0.00709161 16.686C-0.00827678 16.4872 0.00139225 16.2855 0.0355283 16.0928C0.0696644 15.9001 0.127576 15.7203 0.205847 15.564L7.14354 1.689V1.689Z"
        fill="white"
      />
    </svg>
  );
};

export default Component;
