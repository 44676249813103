import RoleRepository from '../../usecases/ports/RoleRepository';
import { HttpAdapter } from '../../usecases/ports/HttpAdapter';
import { RoleSelectItem } from '../../domain/entities/role';

export default class RoleRepositoryImpl implements RoleRepository {
  httpAdapter: HttpAdapter;
  urls: { [key: string]: string };

  constructor(httpAdapter: HttpAdapter, urls: { [key: string]: string }) {
    this.httpAdapter = httpAdapter;
    this.urls = urls;
  }

  fetchRoles = async (): Promise<RoleSelectItem[]> => {
    const response = await this.httpAdapter.get(this.urls.fetchRoles, {});

    return response.data.data;
  };
}
