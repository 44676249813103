import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import theme from '../../../constants/themes';
import { VerifyEmail } from '../../atoms/Icons';
import { Button } from '../../atoms/Button';
import { useGlobalState } from '../../../hooks/global';

const VerifyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 488px;
  height: max-content;

  @media ${theme.breakpoints.mobile} {
    width: 100%;
  }

  @media ${theme.breakpoints.tablet} {
    width: 100%;
  }
`;

const VerifyTitle = styled.p`
  font-family: ${theme.fonts.primary};
  font-weight: 700;
  font-size: 34px;
  line-height: 49px;
  color: ${theme.colors.black};
  padding: 0;
  margin: 0;
`;

const StyledVerifyIcon = styled(VerifyEmail)`
  margin-top: 23px;
`;

const VerifyPrompt = styled.p`
  font-family: ${theme.fonts.primary};
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  color: ${theme.colors.black};
  padding: 0;
  margin: 0;
  margin-top: 23px;
`;

const VerifyLabels = styled.span`
  font-family: ${theme.fonts.primary};
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  color: ${theme.colors.black};
  padding: 0;
  margin: 0;
  margin-top: 11px;
`;

const StyledResendLink = styled.span<{ isDisabled?: boolean }>`
  font-family: ${theme.fonts.primary};
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  color: ${(props): string =>
    props.isDisabled
      ? theme.colors.primaryDisabled
      : theme.colors.notifToastBorder};
  padding: 0;
  margin: 0;
  margin-top: 11px;
  cursor: pointer;
  text-decoration: none;

  :hover {
    text-decoration: ${props => (props.isDisabled ? 'none' : 'underline')};
  }
`;

const HomeButtonWrapper = styled.div`
  width: 150px;
  height: 35px;
  margin-top: 20px;
`;

export type Props = {
  isResending: boolean;
  onClickResend: () => void;
  onPressHomeBtn: () => void;
};

const Component = ({
  isResending,
  onClickResend,
  onPressHomeBtn,
}: Props): React.ReactElement => {
  const { t } = useTranslation();
  const {
    useCurrentUser: { currentUser },
  } = useGlobalState();

  return (
    <VerifyWrapper>
      <VerifyTitle>{t('signUp')}</VerifyTitle>
      <StyledVerifyIcon />
      <VerifyPrompt>{t('verifyPrompt')}</VerifyPrompt>
      <VerifyLabels>{t('verificationSent')}</VerifyLabels>
      <VerifyLabels>
        {t('verificationEmail', { email: currentUser?.user.customer.email })}
      </VerifyLabels>
      <VerifyLabels>{t('returnToHome')}</VerifyLabels>
      <div>
        <VerifyLabels>{t('resendPrompt')}</VerifyLabels>
        <StyledResendLink onClick={onClickResend} isDisabled={isResending}>
          {t('resend')}
        </StyledResendLink>
      </div>
      <HomeButtonWrapper>
        <Button
          theme="secondary"
          title={t('homeBtn')}
          onPress={onPressHomeBtn}
        />
      </HomeButtonWrapper>
    </VerifyWrapper>
  );
};

export default Component;
