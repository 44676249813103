import { buildFetchRoles, fetchRolesUseCase } from './fetch-roles';

import RoleRepository from '../../ports/RoleRepository';

export default (dependencies: {
  roleRepo: RoleRepository;
}): {
  fetchRoles: fetchRolesUseCase;
} => {
  const { roleRepo } = dependencies;
  const fetchRoles = buildFetchRoles({ roleRepo });

  return { fetchRoles };
};
