import React, { useCallback, useEffect, useRef } from 'react';
import { Node, useReactFlow } from 'react-flow-renderer';
import { useBoardHooks } from '../../../../hooks/board';
import { generateUniqueString } from '../../../../utils/strings';
import { DIAGRAM_CONTAINER_ID, LINE_NODE_Z_INDEX } from '../Component';
import { DropAreaData, LineData } from '../types';
import { useEdgesUtils } from './hooks/edges';
import {
  useAreaNode,
  useAreaNodes,
  useNodesUtils,
  useProjectUtils,
} from './hooks/nodes';
import { DEFAULT_LINE_STYLES } from './LineNode';

import { useSocketHooks } from '../../../../hooks/socket';
import { useGlobalState } from '../../../../hooks/global';
import { sendSocket } from './hooks/SendSocket';

// NOTE: needed for checking whether area is valid drop area or not
export const VALID_DROP_AREA_NODE_CLASS_NAME = 'drop-area-node';

export const DROP_AREA_NODE_PREFIX_ID = 'DROP_AREA_NODE_ID-';

export const DROP_AREA_NODE_Z_INDEX = 15;

const Component = (props: {
  id: string;
  data: DropAreaData;
  selected: boolean;
}): React.ReactElement => {
  const { id, data } = props;
  const { nodeState, edgeState, useActions, useBoardSocket } = useBoardHooks();
  const { forceDiagramUpdate } = useBoardSocket();
  const { shouldRender } = useAreaNode(id);

  const { getZoom, getNodes } = useReactFlow();
  const { setNodes } = nodeState;
  const { insertAction } = useActions;
  const {
    updateNodePositions,
    getOverlappingNode,
    setParentNode,
    addNewNode,
  } = useNodesUtils();

  const { removeMyDropAreaNode, myDropAreaId } = useAreaNodes();
  const { insertNewEdges } = useEdgesUtils();
  const { getTranslatedPositions } = useProjectUtils(DIAGRAM_CONTAINER_ID);
  const zoom = getZoom();
  const {
    width,
    height,
    objectType,
    shapeType,
    stickyColor,
  }: DropAreaData = data;

  const { useSocket } = useSocketHooks();
  const { socket, initializedBoardCode } = useSocket;
  const { useCurrentUser } = useGlobalState();
  const { currentUser } = useCurrentUser;

  const targetLineNode = useRef<Node<LineData> | undefined>();
  const firstPoint = useRef<{ x: number; y: number }>();

  const insertLineNodes = useCallback(
    (source: { x: number; y: number }, target: { x: number; y: number }) => {
      const groupId = `${generateUniqueString()}`;
      const currentNodes = getNodes();
      const updatedNodes: Node[] = currentNodes;
      const node1 = {
        id: `line-${generateUniqueString()}`,
        type: 'line',
        connectable: false,
        position: source,
        zIndex: LINE_NODE_Z_INDEX,
        selected: false,
      };
      const node2 = {
        id: `line-${generateUniqueString()}`,
        type: 'line',
        connectable: false,
        position: target,
        zIndex: LINE_NODE_Z_INDEX,
        selected: false,
      };
      const newLineNodes: [Node<LineData>, Node<LineData>] = [
        {
          ...node1,
          data: {
            index: 0,
            groupId: groupId,
            lineType: 'straight',
            startArrowType: 'arrow',
            endArrowType: 'arrow',
            target: node2.id,
            style: DEFAULT_LINE_STYLES,
          },
        },
        {
          ...node2,
          data: {
            index: 1,
            groupId: groupId,
            lineType: 'straight',
            startArrowType: 'arrow',
            endArrowType: 'arrow',
            source: node1.id,
            style: DEFAULT_LINE_STYLES,
          },
        },
      ];
      const overlap = getOverlappingNode(newLineNodes[0]);
      if (overlap) {
        newLineNodes[0].parentNode = overlap.id;
        newLineNodes[0].position = {
          x: newLineNodes[0].position.x - overlap.position.x,
          y: newLineNodes[0].position.y - overlap.position.y,
        };
      }
      targetLineNode.current = newLineNodes[1];
      insertNewEdges([
        {
          source: node1.id,
          target: node2.id,
          groupId: groupId,
        },
      ]);

      setNodes([...updatedNodes, ...newLineNodes]);
    },
    [insertAction],
  );

  const handleDrawShape = useCallback(
    (
      dimensions: { width: number; height: number },
      position: { x: number; y: number },
    ) => {
      if (shapeType) {
        addNewNode(
          'shape',
          position,
          {
            type: shapeType,
            width: dimensions.width,
            height: dimensions.height,
            style: {
              fill: 'white',
              strokeWidth: 4,
            },
          },
          latestNodes => {
            return latestNodes.filter(node => node.id !== myDropAreaId);
          },
        );
      }
    },
    [shapeType],
  );

  const handleDrawSticky = useCallback(
    (
      dimensions: { width: number; height: number },
      position: { x: number; y: number },
    ) => {
      if (stickyColor) {
        addNewNode(
          'sticky',
          position,
          {
            width: dimensions.width,
            height: dimensions.height,
            style: {
              backgroundColor: stickyColor,
            },
          },
          latestNodes => {
            return latestNodes.filter(node => node.id !== myDropAreaId);
          },
        );
      }
    },
    [stickyColor],
  );

  const handleDrawLabel = useCallback(
    (
      dimensions: { width: number; height: number },
      position: { x: number; y: number },
    ) => {
      addNewNode(
        'label',
        position,
        {
          label: '',
          width: dimensions.width,
          height: dimensions.height,
        },
        latestNodes => {
          return latestNodes.filter(node => node.id !== myDropAreaId);
        },
      );
    },
    [],
  );

  const handleMouseDown = (ev: any) => {
    ev.preventDefault();
    const translatedPositions = getTranslatedPositions(ev);
    if (translatedPositions) {
      if (
        objectType === 'line' &&
        ev?.srcElement?.classList.contains(VALID_DROP_AREA_NODE_CLASS_NAME)
      ) {
        insertLineNodes(translatedPositions, translatedPositions);
      } else {
        firstPoint.current = translatedPositions;
      }
    }
  };

  const handleMouseMove = (ev: any) => {
    ev.preventDefault();
    const translatedPositions = getTranslatedPositions(ev);

    if (
      objectType === 'line' &&
      targetLineNode.current?.id &&
      translatedPositions
    ) {
      updateNodePositions({
        [targetLineNode.current.id]: translatedPositions,
      });
    }
  };

  const handleMouseUp = (ev: any) => {
    ev.preventDefault();
    if (objectType === 'sticky') {
      const translatedPositions = getTranslatedPositions(ev);

      if (firstPoint.current && translatedPositions) {
        const posX = Math.min(...[firstPoint.current.x, translatedPositions.x]);
        const posY = Math.min(...[firstPoint.current.y, translatedPositions.y]);
        handleDrawSticky(
          {
            width: Math.abs(firstPoint.current.x - translatedPositions.x),
            height: Math.abs(firstPoint.current.y - translatedPositions.y),
          },
          { x: posX, y: posY },
        );
      } else {
        removeMyDropAreaNode();
      }
    }
    if (objectType === 'shape') {
      const translatedPositions = getTranslatedPositions(ev);

      if (firstPoint.current && translatedPositions) {
        const posX = Math.min(...[firstPoint.current.x, translatedPositions.x]);
        const posY = Math.min(...[firstPoint.current.y, translatedPositions.y]);

        handleDrawShape(
          {
            width: Math.abs(firstPoint.current.x - translatedPositions.x),
            height: Math.abs(firstPoint.current.y - translatedPositions.y),
          },
          { x: posX, y: posY },
        );
      } else {
        removeMyDropAreaNode();
      }
    }
    if (objectType === 'line') {
      if (targetLineNode.current) {
        const nodes = getNodes();
        const lineEnd = nodes.find(
          node => node.id === targetLineNode.current?.id,
        );
        const lineStart = nodes.find(
          node =>
            lineEnd?.data?.groupId &&
            lineEnd?.data?.groupId === node.data?.groupId,
        );
        const overlap = lineEnd ? getOverlappingNode(lineEnd) : undefined;
        if (overlap && lineStart && lineEnd) {
          setParentNode(lineEnd, overlap, latestNodes => {
            return latestNodes.filter(node => node.id !== myDropAreaId);
          });
        } else {
          removeMyDropAreaNode();
        }
        if (lineStart && lineEnd) {
          insertAction({
            undo: () => {
              setNodes(latestNodes => {
                const updatedNodes = latestNodes.filter(
                  node =>
                    !node.data.groupId ||
                    node.data.groupId !== lineEnd.data.groupId,
                );
                sendSocket(
                  updatedNodes,
                  edgeState.getEdges(),
                  '',
                  currentUser?.user?.token,
                  socket,
                  initializedBoardCode,
                );
                return updatedNodes;
              });
            },
            redo: () => {
              setNodes(latestNodes => {
                const nodesWLines = latestNodes.concat([lineStart, lineEnd]);
                const updatedNodes = overlap
                  ? nodesWLines.map(currentNode => {
                      if (currentNode.id === lineEnd.id) {
                        const offsetX = lineEnd.position.x - overlap.position.x;
                        const offsetY = lineEnd.position.y - overlap.position.y;
                        return {
                          ...currentNode,
                          position: { x: offsetX, y: offsetY },
                          parentNode: overlap.id,
                        };
                      }
                      return currentNode;
                    })
                  : nodesWLines;
                sendSocket(
                  updatedNodes,
                  edgeState.getEdges(),
                  '',
                  currentUser?.user?.token,
                  socket,
                  initializedBoardCode,
                );
                return updatedNodes;
              });
            },
          });
        }
        targetLineNode.current = undefined;
        setTimeout(() => {
          forceDiagramUpdate();
        }, 500);
      } else {
        targetLineNode.current = undefined;
        removeMyDropAreaNode();
      }
    }
    if (objectType === 'label') {
      const translatedPositions = getTranslatedPositions(ev);
      if (
        translatedPositions &&
        ev?.srcElement?.classList.contains(VALID_DROP_AREA_NODE_CLASS_NAME)
      ) {
        handleDrawLabel({ height: 100, width: 200 }, translatedPositions);
      } else {
        removeMyDropAreaNode();
      }
    }
  };

  useEffect(() => {
    if (shouldRender) {
      window.addEventListener('mousedown', handleMouseDown);
      window.addEventListener('mouseup', handleMouseUp);
      window.addEventListener('mousemove', handleMouseMove);
    }
    return () => {
      window.removeEventListener('mousedown', handleMouseDown);
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [data?.objectType]);

  if (!shouldRender) {
    return <></>;
  }

  return (
    <>
      <div
        className={VALID_DROP_AREA_NODE_CLASS_NAME}
        style={{
          border: 'none',
          backgroundColor: 'transparent',
          width: width,
          height: height,
          transform: `scale(${1 / zoom})`,
          transformOrigin: 'left top',
          cursor: 'crosshair',
        }}
      />
    </>
  );
};

export default Component;
