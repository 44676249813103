import { createContext, useContext } from 'react';
import { RoleSelectItem } from '../../domain/entities/role';

export type RoleHooks = {
  useRole: () => {
    fetchRoles: () => Promise<RoleSelectItem[]>;
  };
};

export const RoleHooksContext = createContext<RoleHooks | null>(null);

export const useRoleHooks = (): RoleHooks => {
  const context = useContext(RoleHooksContext);
  if (!context) {
    throw new Error(`Context not instantiated`);
  }
  return context;
};
