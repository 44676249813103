import React from 'react';

type Props = {
  [key: string]: string | number | Function;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M7.5 9.61261e-08C7.61155 -6.3395e-05 7.72034 0.0313264 7.81119 0.0897898C7.90204 0.148253 7.97042 0.230873 8.00679 0.326123L8.62071 1.93545H13.9286C14.2127 1.93545 14.4853 2.0374 14.6862 2.21889C14.8871 2.40037 15 2.64651 15 2.90317V9.67723C15 9.93389 14.8871 10.18 14.6862 10.3615C14.4853 10.543 14.2127 10.645 13.9286 10.645H11.9464L13.3639 14.3581C13.4102 14.4795 13.4013 14.6126 13.339 14.728C13.2767 14.8434 13.1662 14.9317 13.0318 14.9736C12.8974 15.0154 12.7501 15.0073 12.6223 14.9511C12.4945 14.8948 12.3967 14.795 12.3504 14.6736L10.8129 10.645H8.03571V13.5481C8.03571 13.6765 7.97927 13.7995 7.87881 13.8903C7.77834 13.981 7.64208 14.032 7.5 14.032C7.35792 14.032 7.22166 13.981 7.12119 13.8903C7.02073 13.7995 6.96429 13.6765 6.96429 13.5481V10.645H4.18714L2.64964 14.6736C2.60332 14.795 2.50551 14.8948 2.37772 14.9511C2.24992 15.0073 2.10262 15.0154 1.96821 14.9736C1.83381 14.9317 1.7233 14.8434 1.66101 14.728C1.59873 14.6126 1.58975 14.4795 1.63607 14.3581L3.05357 10.645H1.07143C0.787268 10.645 0.514746 10.543 0.313814 10.3615C0.112882 10.18 0 9.93389 0 9.67723V2.90317C0 2.64651 0.112882 2.40037 0.313814 2.21889C0.514746 2.0374 0.787268 1.93545 1.07143 1.93545H6.37929L6.99321 0.326123C7.02958 0.230873 7.09796 0.148253 7.18881 0.0897898C7.27966 0.0313264 7.38845 -6.3395e-05 7.5 9.61261e-08ZM1.07143 2.90317V9.67723H13.9286V2.90317H1.07143Z"
        fill="white"
      />
    </svg>
  );
};

export default Component;
