import { HttpAdapter } from '../../usecases/ports/HttpAdapter';
import PageRepository from '../../usecases/ports/PageRepository';
import {
  Page,
  PageUploadMedia,
  UpdatePageContent,
  UpdatePageSection,
  VerifiedAdmin,
} from '../../domain/entities/page';
import { URLsType } from '../../constants/urls';
import { GenericResponse } from '../../domain/entities/rest';

export default class PageRepositoryImpl implements PageRepository {
  httpAdapter: HttpAdapter;
  urls: URLsType['page'];

  constructor(httpAdapter: HttpAdapter, urls: URLsType['page']) {
    this.httpAdapter = httpAdapter;
    this.urls = urls;
  }

  fetchPage = async (pageName: string): Promise<Page> => {
    const response = await this.httpAdapter.get(this.urls.fetchPage, {
      params: { page_name: pageName },
    });

    const data = response.data.data;

    return {
      id: data.id,
      pageName: data.page_name,
      pageDescription: data.page_description,
      pageKeyword: data.page_keyword,
      pageContent: data.page_content,
      pageTitle: data.page_title,
      url: data.url,
      sections: data.sections,
    };
  };

  verifyAdmin = async (token: string): Promise<VerifiedAdmin> => {
    const response = await this.httpAdapter.post(this.urls.verifyAdmin, {
      token,
    });

    const data = response.data.data;
    return { isAdmin: data.is_admin };
  };

  uploadMedia = async (file: File): Promise<PageUploadMedia> => {
    const formData = new FormData();
    formData.append('upload_file', file);

    const response = await this.httpAdapter.post(
      this.urls.uploadMedia,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    );

    return response.data.data;
  };

  updatePageSection = async (
    pageId: number,
    data: UpdatePageSection | UpdatePageContent,
  ): Promise<GenericResponse> => {
    const response = await this.httpAdapter.put(
      this.urls.updatePageSection(pageId),
      data,
    );

    return response;
  };
}
