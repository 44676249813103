import { PageUploadMedia } from '../../../domain/entities/page';
import PageRepository from './../../ports/PageRepository';

export type uploadMediaUseCase = (file: File) => Promise<PageUploadMedia>;

export const buildUploadMedia = (dependencies: {
  pageRepo: PageRepository;
}): uploadMediaUseCase => {
  const { pageRepo } = dependencies;

  const uploadMedia: uploadMediaUseCase = async file =>
    pageRepo.uploadMedia(file);

  return uploadMedia;
};
