import AuthService from '../../ports/AuthService';

export type googleSignInUseCase = (
  email: string,
  name: string,
  googleId: string,
  invitationCode: string,
  avatar?: string,
) => Promise<{ user: object | null }>;

export const buildGoogleSignIn = (dependencies: {
  authService: AuthService;
}): googleSignInUseCase => {
  const { authService } = dependencies;

  const googleSignIn: googleSignInUseCase = (
    email,
    name,
    googleId,
    invitationCode,
    avatar,
  ) =>
    authService.signInUsingGoogle(
      email,
      name,
      googleId,
      invitationCode,
      avatar,
    );

  return googleSignIn;
};
