import React from 'react';
import styled from 'styled-components';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import theme from '../../../constants/themes';
import { DropdownArrow } from '../Icons';
import { isWindowsOS } from '../../../utils/booleans';
import { SxProps } from '@mui/material';

const StyledSelect = styled(Select)<{ $isPlaceholder?: boolean }>`
  && {
    width: 143px;
    height: 34px;
    background-color: ${theme.colors.dropdownBg};
    font-family: ${theme.fonts.primary};
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: ${props =>
      props.$isPlaceholder
        ? theme.colors.textColor
        : theme.colors.dropdownText};
    border-radius: 0;
    box-shadow: none;
    outline: none;
    align-items: ${isWindowsOS() ? 'baseline' : 'center'};

    .MuiSelect-icon {
      right: 20px;
      top: calc(50% - 3px);
    }
    .MuiInputBase-input {
      padding: 6px 14px;
    }
  }
`;

export type Props = {
  values: { id: string; label: string }[];
  selected: string;
  className?: string;
  placeholder?: string;
  onChangeSelected: (selected: string) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  menuListStyle?: SxProps;
};

const Component = ({
  values,
  selected,
  className,
  placeholder,
  onChangeSelected,
  onBlur,
  menuListStyle,
}: Props): React.ReactElement => {
  const handleChange = (event: SelectChangeEvent<unknown>) => {
    onChangeSelected(event.target.value as string);
  };

  return (
    <StyledSelect
      className={className}
      variant="outlined"
      value={selected}
      onChange={handleChange}
      IconComponent={DropdownArrow}
      $isPlaceholder={selected === 'none'}
      onBlur={onBlur}
      MenuProps={{
        MenuListProps: {
          sx: {
            ...menuListStyle,
            padding: 0,
            borderRadius: 0,
            '& .MuiMenuItem-root': {
              backgroundColor: theme.colors.white,
              alignContent: 'center',
              justifyContent: 'center',
              fontFamily: theme.fonts.primary,
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '24px',
              color: theme.colors.textColor,
              paddingTop: isWindowsOS() ? '15px' : '6px',

              '&:hover': {
                backgroundColor: theme.colors.dropdownOptionHover,
              },
              '&.Mui-selected': {
                backgroundColor: theme.colors.dropdownOptionSelected,

                '&:hover': {
                  backgroundColor: theme.colors.dropdownOptionSelectedHover,
                },
              },
            },
          },
        },
      }}>
      <MenuItem value="none" disabled sx={{ display: 'none' }}>
        {placeholder}
      </MenuItem>
      {values.map(value => {
        return (
          <MenuItem key={value.id} value={value.id}>
            {value.label}
          </MenuItem>
        );
      })}
    </StyledSelect>
  );
};

export default Component;
