import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Skeleton from '@mui/material/Skeleton';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import defaultImage from '../../../assets/images/default-user.png';
import theme from '../../../constants/themes';
import { TeamMember } from '../../../domain/entities/team';

const StyledTable = styled(Table)`
  .MuiTableCell-head {
    font-family: ${theme.fonts.primary};
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 19px;

    letter-spacing: -0.02em;
    color: ${theme.colors.textColor};
  }

  .MuiTableCell-body {
    font-family: ${theme.fonts.primary};
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;

    letter-spacing: -0.02em;
    color: ${theme.colors.textColor};
  }
`;

const StyledCellLabelWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledCellLabel = styled.span`
  width: 192px;
  word-break: break-word;
`;

const StyledAccountTypeLabel = styled.span`
  width: 128px;
  word-break: break-word;
`;

const CustomerAvatarWrapper = styled.div`
  width: 40px;
  height: 40px;
  overflow: hidden;
  position: relative;
  margin-right: 5px;
`;

const StyledCustomerAvatar = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const StyledOperationWrapper = styled.div`
  display: flex;
  width: 60px;
  justify-content: space-between;
  margin-left: 8px;
`;

const StyledOperationLabel = styled.span<{ isDelete?: boolean }>`
  font-family: ${theme.fonts.primary};
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  text-decoration-line: underline;
  color: ${props =>
    props.isDelete ? theme.colors.textError : theme.colors.notifToastBorder};
  cursor: pointer;
`;

export type Props = {
  teamMembers: TeamMember[];
  onEdit: (member: TeamMember) => void;
  onDelete: (member: TeamMember) => void;
  isLoading?: boolean;
  currenUserId?: number;
};

type TableOperationProps = {
  member: TeamMember;
  onEdit: (member: TeamMember) => void;
  onDelete: (member: TeamMember) => void;
};

const TableOperation = ({
  member,
  onEdit,
  onDelete,
}: TableOperationProps): React.ReactElement => {
  const { t } = useTranslation();

  const handleEdit = () => {
    onEdit(member);
  };
  const handleDelete = () => {
    onDelete(member);
  };
  return (
    <StyledOperationWrapper>
      <StyledOperationLabel onClick={handleEdit}>
        {t('edit')}
      </StyledOperationLabel>
      <StyledOperationLabel onClick={handleDelete} isDelete>
        {t('delete')}
      </StyledOperationLabel>
    </StyledOperationWrapper>
  );
};

const TableLoader = (): React.ReactElement => {
  return (
    <>
      {Array(5)
        .fill(null)
        .map((_, index) => (
          <TableRow
            key={index}
            sx={{
              '& .MuiTableCell-root': {
                paddingTop: '5px',
                paddingBottom: 0,
              },
            }}>
            <TableCell colSpan={3}>
              <Skeleton height={50} />
            </TableCell>
          </TableRow>
        ))}
    </>
  );
};

const Component = ({
  teamMembers = [],
  onEdit,
  onDelete,
  isLoading,
  currenUserId,
}: Props): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <StyledTable
      stickyHeader
      aria-label="sticky header"
      sx={{ maxWidth: '850px' }}>
      <TableHead
        sx={{
          [`& .${tableCellClasses.root}`]: {
            borderBottom: '1px solid #AAAAAA',
            paddingX: 0,
            paddingY: '4px',
          },
          position: 'sticky',
          top: '193px',
          zIndex: 998,
        }}>
        <TableRow>
          <TableCell width={260}>{t('familyName')}</TableCell>
          <TableCell width={260}>{t('emailLabel')}</TableCell>
          <TableCell>{t('accountType')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody
        sx={{
          [`& .${tableCellClasses.root}`]: {
            borderBottom: 'none',
            paddingX: 0,
            paddingY: '8px',
          },
        }}>
        {isLoading ? (
          <TableLoader />
        ) : (
          teamMembers.map(row => (
            <TableRow
              key={row.id}
              sx={{
                '&:first-of-type td, &:first-of-type th': {
                  paddingTop: '16px',
                },
              }}>
              <TableCell>
                <StyledCellLabelWrapper>
                  <CustomerAvatarWrapper>
                    <StyledCustomerAvatar
                      src={row.avatar || defaultImage}
                      alt="Img"
                    />
                  </CustomerAvatarWrapper>
                  <StyledCellLabel>{row.fullName}</StyledCellLabel>
                </StyledCellLabelWrapper>
              </TableCell>
              <TableCell>
                <StyledCellLabelWrapper>
                  <StyledCellLabel
                    style={{
                      textDecorationLine: 'underline',
                    }}>
                    {row.email}
                  </StyledCellLabel>
                </StyledCellLabelWrapper>
              </TableCell>
              <TableCell>
                <StyledCellLabelWrapper>
                  <StyledAccountTypeLabel>
                    {row.memberType}
                  </StyledAccountTypeLabel>
                  {currenUserId !== row.id && (
                    <TableOperation
                      member={{
                        id: row.id,
                        fullName: row.fullName,
                        email: row.email,
                        avatar: row.avatar,
                        memberType: row.memberType,
                      }}
                      onEdit={onEdit}
                      onDelete={onDelete}
                    />
                  )}
                </StyledCellLabelWrapper>
              </TableCell>
            </TableRow>
          ))
        )}
      </TableBody>
    </StyledTable>
  );
};

export default Component;
