import React from 'react';
import { PlanList } from '../../templates/PlanList';

type Props = {};

const Component = ({}: Props): React.ReactElement => {
  return <PlanList />;
};

export default Component;
