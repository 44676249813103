import { AxiosError } from 'axios';
import TeamRepository from '../../usecases/ports/TeamRepository';
import { HttpAdapter } from '../../usecases/ports/HttpAdapter';
import {
  MyBoardTeam,
  CreateTeamSuccess,
  TeamMember,
  InviteTeamMemberSuccess,
  DeleteTeamMemberSuccess,
} from '../../domain/entities/team';
import { URLsType } from '../../constants/urls';
import { GenericResponse } from '../../domain/entities/rest';

export default class TeamRepositoryImpl implements TeamRepository {
  httpAdapter: HttpAdapter;
  urls: URLsType['team'];

  constructor(httpAdapter: HttpAdapter, urls: URLsType['team']) {
    this.httpAdapter = httpAdapter;
    this.urls = urls;
  }

  fetchTeams = async (): Promise<MyBoardTeam[]> => {
    const options = {
      headers: {
        Accept: 'application/json',
      },
    };

    const response = await this.httpAdapter.get(this.urls.fetchTeams, options);

    return response.data.data;
  };

  createTeam = async (teamName: string): Promise<CreateTeamSuccess> => {
    const params = {
      name: teamName,
    };

    const response = await this.httpAdapter.post(this.urls.createTeam, params);

    return response.data;
  };

  fetchTeamMembers = async (
    teamId: number,
    search?: string,
  ): Promise<TeamMember[]> => {
    const params = search ? { search } : {};
    const response = await this.httpAdapter.get(
      this.urls.fetchTeamMembers(teamId),
      { params },
    );

    const data = response.data.data;

    return data.map(
      (
        value: TeamMember & {
          fullname: string;
          member_type: string;
        },
      ) => {
        return {
          ...value,
          fullName: value.fullname,
          memberType: value.member_type,
        };
      },
    );
  };

  inviteTeamMember = async (
    teamId: string,
    emails: string[],
  ): Promise<InviteTeamMemberSuccess> => {
    const params = {
      team_id: teamId,
      emails,
    };

    const response = await this.httpAdapter.post(
      this.urls.inviteTeamMember,
      params,
    );

    return {
      data: response.data.data[0],
    };
  };

  deleteTeamMember = async (
    teamId: string,
    customerId: string,
  ): Promise<DeleteTeamMemberSuccess> => {
    const response = await this.httpAdapter.delete(
      this.urls.deleteTeamMember(teamId, customerId),
      {},
    );

    const data = response.data;

    return {
      statusCode: data.status_code,
      message: data.message,
      data: {
        teamId: data.data.team_id,
        customerId: data.data.customer_id,
      },
    };
  };

  updateTeamMember = async (
    teamId: number,
    customerId: number,
    isOwner: number,
  ): Promise<GenericResponse> => {
    const params = {
      team_id: teamId,
      customer_id: customerId,
      is_owner: isOwner,
    };

    const response = await this.httpAdapter
      .patch(this.urls.updateTeamMember, params)
      .catch(error => {
        const err = error as AxiosError;
        const data = err.response?.data as any;
        if (data.status_code === 422) {
          const errors = data?.errors || {};
          const errorKeys = Object.keys(errors);
          const mappedErrorMsgs = errorKeys.map(key => {
            const msgsArr = errors?.[key].join('\n') || '';
            return msgsArr;
          });
          const errorMsgs = mappedErrorMsgs.join('\n');
          throw new Error(errorMsgs);
        }
        throw new Error(err.message);
      });

    return { ...response.data, message: response.data.message };
  };
}
