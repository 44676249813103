import React from 'react';

type Props = {
  [key: string]: string | number | Function;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="28"
      height="26"
      viewBox="0 0 28 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g clipPath="url(#clip0_2450_15110)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.615 1.62616C13.5959 1.63722 13.5804 1.65312 13.57 1.67213L7.85583 11.081C7.84159 11.1031 7.83391 11.1285 7.83362 11.1546C7.83332 11.1806 7.84043 11.2062 7.85416 11.2286C7.8675 11.2528 7.885 11.2689 7.89916 11.277C7.91166 11.285 7.9275 11.2907 7.95416 11.2907H19.3817C19.401 11.2913 19.4201 11.2866 19.4367 11.277C19.4559 11.2648 19.4717 11.2482 19.4825 11.2286C19.4961 11.2061 19.5031 11.1805 19.5026 11.1545C19.5022 11.1284 19.4944 11.103 19.48 11.081L13.7667 1.67213C13.7563 1.65312 13.7407 1.63722 13.7217 1.62616C13.7053 1.61749 13.687 1.61305 13.6683 1.61326C13.6497 1.61305 13.6313 1.61749 13.615 1.62616ZM14.485 1.26326C14.4025 1.1242 14.2836 1.00866 14.1402 0.928317C13.9968 0.847972 13.834 0.805664 13.6683 0.805664C13.5026 0.805664 13.3399 0.847972 13.1965 0.928317C13.0531 1.00866 12.9342 1.1242 12.8517 1.26326L7.1375 10.6721C6.75666 11.2995 7.21333 12.0971 7.95416 12.0971H19.3817C20.1225 12.0971 20.58 11.2987 20.1983 10.6721L14.485 1.26326Z"
          fill="black"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.6667 13.8065H15.6667C15.4457 13.8065 15.2337 13.8914 15.0774 14.0427C14.9211 14.1939 14.8333 14.399 14.8333 14.6129V24.2903C14.8333 24.5042 14.9211 24.7093 15.0774 24.8606C15.2337 25.0118 15.4457 25.0968 15.6667 25.0968H25.6667C25.8877 25.0968 26.0996 25.0118 26.2559 24.8606C26.4122 24.7093 26.5 24.5042 26.5 24.2903V14.6129C26.5 14.399 26.4122 14.1939 26.2559 14.0427C26.0996 13.8914 25.8877 13.8065 25.6667 13.8065ZM15.6667 13C15.2246 13 14.8007 13.1699 14.4882 13.4724C14.1756 13.7749 14 14.1851 14 14.6129V24.2903C14 24.7181 14.1756 25.1283 14.4882 25.4308C14.8007 25.7333 15.2246 25.9032 15.6667 25.9032H25.6667C26.1087 25.9032 26.5326 25.7333 26.8452 25.4308C27.1577 25.1283 27.3333 24.7181 27.3333 24.2903V14.6129C27.3333 14.1851 27.1577 13.7749 26.8452 13.4724C26.5326 13.1699 26.1087 13 25.6667 13H15.6667Z"
        fill="black"
      />
      <g clipPath="url(#clip1_2450_15110)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.66667 25.0968C8.21376 25.0968 9.69749 24.502 10.7915 23.4433C11.8854 22.3847 12.5 20.9488 12.5 19.4516C12.5 17.9544 11.8854 16.5186 10.7915 15.4599C9.69749 14.4012 8.21376 13.8065 6.66667 13.8065C5.11957 13.8065 3.63584 14.4012 2.54188 15.4599C1.44791 16.5186 0.833333 17.9544 0.833333 19.4516C0.833333 20.9488 1.44791 22.3847 2.54188 23.4433C3.63584 24.502 5.11957 25.0968 6.66667 25.0968ZM6.66667 25.9032C8.43478 25.9032 10.1305 25.2235 11.3807 24.0136C12.631 22.8037 13.3333 21.1627 13.3333 19.4516C13.3333 17.7405 12.631 16.0995 11.3807 14.8896C10.1305 13.6797 8.43478 13 6.66667 13C4.89856 13 3.20286 13.6797 1.95262 14.8896C0.702379 16.0995 0 17.7405 0 19.4516C0 21.1627 0.702379 22.8037 1.95262 24.0136C3.20286 25.2235 4.89856 25.9032 6.66667 25.9032Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_2450_15110">
          <rect
            width="13.3333"
            height="12.9032"
            fill="white"
            transform="translate(7)"
          />
        </clipPath>
        <clipPath id="clip1_2450_15110">
          <rect
            width="13.3333"
            height="12.9032"
            fill="white"
            transform="translate(0 13)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Component;
