import React from 'react';

type Props = {
  [key: string]: string | number | Function;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="10"
      height="11"
      viewBox="0 0 10 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M5.63159 0.00256348V10.6666H4.23999V0.00256348H5.63159ZM9.05933 0.00256348V1.15979H0.81958V0.00256348H9.05933Z"
        fill="black"
      />
    </svg>
  );
};

export default Component;
