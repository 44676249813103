import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { AxiosError } from 'axios';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { Props as INotification } from '../../atoms/Toast/Component';
import { Toast } from '../../atoms/Toast';
import theme from '../../../constants/themes';
import {
  ArrowUpRight,
  Cursor,
  Frame,
  Grab,
  Pen,
  Shape,
  Sticky,
  Text,
} from '../../atoms/Icons';
import { StickyPicker } from '../../organisms/StickyPicker';
import { ShapePicker } from '../../organisms/ShapePicker';
import { StrokePicker } from '../../organisms/StrokePicker';
import { MediaPicker } from '../../organisms/MediaPicker';

const Container = styled.span`
  background-color: ${theme.colors.white};
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.25);
  border-radius: 45px;
  display: inline-flex;
`;

const Wrapper = styled.span`
  background-color: ${theme.colors.primary};
  border-radius: 45px;
  display: inline-flex;
  padding: 10px;
  align-items: center;
`;

const Label = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: -0.02em;
  color: ${theme.colors.black};
`;

const IconWrapper = styled.span<{ $selected?: boolean }>`
  background-color: ${({ $selected }) =>
    $selected ? '#cfcfcf' : theme.colors.white};
  border-radius: 50%;
  width: 37.71px;
  height: 40px;
  margin-left: 6.29px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'transparent',
    padding: 0,
    borderRadius: 0,
  },
}));

export type Props = {
  className?: string;
  cursorState?: 'pointer' | 'grab';
  selectedObjects?: {
    cursor?: boolean;
    shape?: boolean;
    sticky?: boolean;
    text?: boolean;
    arrow?: boolean;
    stroke?: boolean;
    media?: boolean;
  };
  onClickCursor?: () => void;
  onClickShape?: () => void;
  onSelectShape?: (
    type:
      | 'shape-1'
      | 'shape-2'
      | 'shape-3'
      | 'shape-4'
      | 'shape-5'
      | 'shape-6'
      | 'shape-7'
      | 'shape-8'
      | 'shape-9'
      | 'shape-10'
      | 'shape-11'
      | 'shape-12',
  ) => void;
  onClickPen?: () => void;
  onClickSticky?: () => void;
  onSelectSticky?: (color: string) => void;
  onClickText?: () => void;
  onClickArrow?: () => void;
  onSelectPen?: (strokeWidth: number, strokeColor: string) => void;
  onClickFrame?: () => void;
  onSelectFrame?: (mediaUrl: string) => void;
  setIsMediaPickerOpen?: (isOpen: boolean) => void;
};

const DEFAULT_TOOLTIP_STATE = {
  shape: false,
  sticky: false,
  stroke: false,
  frame: false,
};

const Component = ({
  className,
  cursorState = 'pointer',
  onClickCursor = () => {},
  onSelectShape = () => {},
  onSelectSticky = () => {},
  onSelectFrame = () => {},
  onClickText = () => {},
  onClickArrow = () => {},
  onSelectPen = () => {},
  onClickFrame = () => {},
  onClickShape = () => {},
  onClickSticky = () => {},
  onClickPen = () => {},
  setIsMediaPickerOpen = () => {},
  selectedObjects,
}: Props): React.ReactElement => {
  const [notification, setNotification] = useState<INotification | null>(null);

  const [strokeWidth, setStrokeWidth] = useState<number>(10);
  const [strokeColor, setStrokeColor] = useState<string>('black');
  const [tooltipsState, setTooltipsState] = useState<{
    shape: boolean;
    sticky: boolean;
    stroke: boolean;
    frame: boolean;
  }>(DEFAULT_TOOLTIP_STATE);
  const handleTooltipUpdate = useCallback(
    (key: 'shape' | 'sticky' | 'stroke' | 'frame', state: boolean) => {
      setTooltipsState({ ...DEFAULT_TOOLTIP_STATE, [key]: state });
      if (key === 'frame') {
        setIsMediaPickerOpen(state);
      }
    },
    [tooltipsState],
  );
  return (
    <>
      <ClickAwayListener
        onClickAway={() => {
          setIsMediaPickerOpen(false);
          setTooltipsState({ ...DEFAULT_TOOLTIP_STATE });
        }}>
        <Container className={className}>
          <Wrapper>
            <Label>TOOL</Label>
            <IconWrapper
              $selected={selectedObjects?.cursor}
              onClick={() => {
                setTooltipsState({ ...DEFAULT_TOOLTIP_STATE });
                onClickCursor();
              }}>
              {cursorState === 'pointer' ? <Cursor /> : <Grab />}
            </IconWrapper>
            <StyledTooltip
              open={tooltipsState.shape}
              title={<ShapePicker onClickShape={onSelectShape} />}>
              <IconWrapper
                $selected={selectedObjects?.shape}
                onClick={() => {
                  onClickShape();
                  handleTooltipUpdate('shape', !tooltipsState.shape);
                }}>
                <Shape />
              </IconWrapper>
            </StyledTooltip>
            <StyledTooltip
              open={tooltipsState.sticky}
              title={<StickyPicker onClickSticky={onSelectSticky} />}>
              <IconWrapper
                $selected={selectedObjects?.sticky}
                onClick={() => {
                  onClickSticky();
                  handleTooltipUpdate('sticky', !tooltipsState.sticky);
                }}>
                <Sticky />
              </IconWrapper>
            </StyledTooltip>
            <IconWrapper
              $selected={selectedObjects?.text}
              onClick={onClickText}>
              <Text />
            </IconWrapper>
            <IconWrapper
              $selected={selectedObjects?.arrow}
              onClick={onClickArrow}>
              <ArrowUpRight />
            </IconWrapper>
            <StyledTooltip
              open={tooltipsState.stroke}
              title={
                <StrokePicker
                  width={strokeWidth}
                  color={strokeColor}
                  onSelectColor={newColor => {
                    setStrokeColor(newColor);
                    onSelectPen(strokeWidth, newColor);
                  }}
                  onSelectWidth={newWidth => {
                    setStrokeWidth(newWidth);
                    onSelectPen(newWidth, strokeColor);
                  }}
                />
              }>
              <IconWrapper
                $selected={selectedObjects?.stroke}
                onClick={() => {
                  onClickPen();
                  handleTooltipUpdate('stroke', !tooltipsState.stroke);
                }}>
                <Pen />
              </IconWrapper>
            </StyledTooltip>
            <StyledTooltip
              open={tooltipsState.frame}
              title={
                <MediaPicker
                  onClickMedia={onSelectFrame}
                  onErrorUpload={err => {
                    const typedError = err as AxiosError;
                    const data = typedError?.response?.data as any;
                    const errorMessage = data?.errors?.upload_file || '';
                    setNotification({
                      isOpen: true,
                      message: errorMessage,
                      type: 'error',
                      position: 'top-right',
                      onClose: () => {
                        setNotification(null);
                      },
                    });
                  }}
                />
              }>
              <IconWrapper
                $selected={selectedObjects?.media}
                onClick={() => {
                  onClickFrame();
                  handleTooltipUpdate('frame', !tooltipsState.frame);
                }}>
                <Frame />
              </IconWrapper>
            </StyledTooltip>
          </Wrapper>
        </Container>
      </ClickAwayListener>
      {notification ? <Toast {...notification} /> : null}
    </>
  );
};

export default Component;
