import BoardRepository from '../../ports/BoardRepository';
import { UpdateRoleSuccess } from '../../../domain/entities/role';

export type updateCustomerRoleUseCase = (
  boardCode: string,
  roleId: number,
) => Promise<UpdateRoleSuccess>;

export const buildUpdateCustomerRole = (dependencies: {
  boardRepo: BoardRepository;
}): updateCustomerRoleUseCase => {
  const { boardRepo } = dependencies;

  const updateCustomerRole: updateCustomerRoleUseCase = async (
    boardCode,
    roleId,
  ) => boardRepo.updateCustomerRole(boardCode, roleId);

  return updateCustomerRole;
};
