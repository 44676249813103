import AuthService from '../../ports/AuthService';

export type signInUserUseCase = (
  email: string,
  password: string,
  remember: boolean,
  invitationCode: string,
) => Promise<{ user: object | null }>;

export const buildSignInUser = (dependencies: {
  authService: AuthService;
}): signInUserUseCase => {
  const { authService } = dependencies;

  const signInUser: signInUserUseCase = (
    email,
    password,
    remember,
    invitationCode,
  ) => authService.signIn(email, password, remember, invitationCode);

  return signInUser;
};
