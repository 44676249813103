import React, { useState } from 'react';
import styled from 'styled-components';
import theme from '../../../constants/themes';
import shareImg from '../../../assets/images/share.png';
import { Form } from 'formik';
import { Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Share } from '../../atoms/Icons';
import { Button } from '../../atoms/Button';
import { InputWithIcon } from '../../atoms/InputWithIcon';
import { MyBoardDropdown } from '../../atoms/MyBoardDropdown';
import { Tooltip } from '../../atoms/Tooltip';
import { isWindowsOS } from '../../../utils/booleans';
import {
  handleTextOverflow,
  validateEmailsFormat,
  validateEmailsLength,
} from '../../../utils/strings';
import { useGlobalState } from '../../../hooks/global';
import { MemberDeleteModal } from '../MemberDeleteModal';

const FormWrapper = styled(Form)`
  background: ${theme.colors.white};
  display: flex;
  flex-direction: column;
  width: 663px;

  @media ${theme.breakpoints.mobile} {
    width: 100%;
  }

  @media ${theme.breakpoints.tablet} {
    width: 100%;
  }
`;

const StyledInput = styled(InputWithIcon)<{ haserror?: boolean }>`
  flex: 1;
  height: 50px;
  border: ${props =>
    props.haserror
      ? `thin solid ${theme.colors.textError}`
      : `thin solid ${theme.colors.addTeamMemberDropdownBorder}`};
  border-radius: 5px;
  padding: ${isWindowsOS() ? '12px 50px 8px' : '8px 50px'};

  &:focus {
    outline: ${props =>
      props.haserror
        ? `thin solid ${theme.colors.textError}`
        : `thin solid ${theme.colors.addTeamMemberDropdownBorder}`};
  }

  @media ${theme.breakpoints.mobile} {
    margin-top: 10px;
    width: 100%;
  }

  @media ${theme.breakpoints.tablet} {
    margin-top: 10px;
    width: 100%;
  }
`;

const InputRow = styled.div`
  margin-bottom: 10px;
  display: flex;

  @media ${theme.breakpoints.mobile} {
    width: 100%;
    display: block;
  }

  @media ${theme.breakpoints.tablet} {
    width: 100%;
    display: block;
  }
`;

const ShareButton = styled(Button)`
  margin-left: 7px;
  height: 50px;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 0 20px;
  color: ${theme.colors.white};
  cursor: pointer;
  svg {
    margin-right: 5px;
  }
  :hover {
    filter: brightness(0.8);
  }

  @media ${theme.breakpoints.mobile} {
    width: 100%;
    margin-left: 0;
    margin-top: 10px;
  }

  @media ${theme.breakpoints.tablet} {
    width: 100%;
    margin-left: 0;
    margin-top: 10px;
  }
`;

const StyledDropdown = styled(MyBoardDropdown)`
  &&& {
    width: 200px;
    height: 50px;
    margin-right: 7px;
    border-radius: 5px;
    background-color: ${theme.colors.white};
    font-family: ${theme.fonts.primary};
    font-weight: 400;
    font-size: 14px;
    line-height: ${isWindowsOS() ? 24 : 28}px;

    .MuiInputBase-input {
      padding: ${isWindowsOS() ? 15 : 6}px 14px 6px;
    }
  }

  @media ${theme.breakpoints.mobile} {
    &&& {
      width: 100%;
    }
  }

  @media ${theme.breakpoints.tablet} {
    &&& {
      width: 100%;
    }
  }
`;

const ParticipantLabel = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: ${theme.colors.textColor};
  margin-bottom: 11px;
`;

const ParticipantWrapper = styled.div`
  min-height: 46px;
  max-height: 230px;
  overflow-y: auto;
`;

const Participant = styled.span``;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
`;

const DeletingSkeleton = styled(Skeleton)`
  display: flex;
  padding: 0px 10px;
`;

const ParticipantDetails = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;

  letter-spacing: -0.02em;
`;

const RoleLabel = styled.span`
  margin-right: 5px;
`;

const DeleteButton = styled.span`
  text-decoration-line: underline;
  color: ${theme.colors.textError};
  cursor: pointer;
`;

const TeamErrorMessage = styled.span`
  width: 200px;
  font-family: ${theme.fonts.primary};
  font-weight: 400;
  font-size: 13px;
  line-height: 14px;
  color: ${theme.colors.textError};
  margin-top: 5px;
  text-align: left;
`;

const EmailErrorMessage = styled.span`
  flex: 1;
  font-family: ${theme.fonts.primary};
  font-weight: 400;
  font-size: 13px;
  line-height: 14px;
  color: ${theme.colors.textError};
  margin-left: 9px;
  text-align: left;
`;

const EmailInstruction = styled.span`
  font-family: ${theme.fonts.primary};
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: ${theme.colors.emailInstruction};
  margin-left: 9px;
  text-align: left;
`;

export type Props = {
  email: string;
  participants: { id: number; name: string; role: string; email?: string }[];
  teams: { id: string; label: string }[];
  selectedTeam: string;
  disabled?: boolean;
  isFetchingTeamMembers?: boolean;
  isDeletingTeamMember?: boolean;
  errors: {
    email?: string;
    teamId?: string;
  };
  onChangeEmail: (email: string) => void;
  onClickDelete?: (customerId: string) => void;
  onClickShare: () => void;
  onChangeSelectedTeam: (selected: string) => void;
  onBlurEmail: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlurTeamId: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  setFieldTouched: (
    field: string,
    isTouched?: boolean | undefined,
    shouldValidate?: boolean | undefined,
  ) => void;
  isLoading: boolean;
};

const Component = ({
  email,
  participants,
  teams,
  selectedTeam,
  disabled,
  isFetchingTeamMembers,
  isDeletingTeamMember,
  errors,
  onChangeEmail,
  onClickShare,
  onClickDelete = () => {},
  onChangeSelectedTeam,
  onBlurEmail,
  onBlurTeamId,
  setFieldTouched,
  isLoading,
}: Props): React.ReactElement => {
  const { t } = useTranslation();
  const {
    useCurrentUser: { currentUser },
  } = useGlobalState();
  const [deletingIndex, setDeletingIndex] = useState(-1);
  const [selectedDeleteMember, setSelectedDeleteMember] = useState<
    number | null
  >(null);
  const [hasInvalidEmail, setHasInvalidEmail] = useState(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState('');
  const onCloseDeleteMemberModal = () => setSelectedDeleteMember(null);

  return (
    <FormWrapper>
      <InputRow>
        <StyledDropdown
          values={teams}
          selected={selectedTeam}
          placeholder="Team Name"
          onChangeSelected={selectedValue => {
            setFieldTouched('teamId');
            onChangeSelectedTeam(selectedValue);
          }}
          onBlur={onBlurTeamId}
        />
        <StyledInput
          value={email}
          onChangeText={(text: string) => {
            setFieldTouched('email');
            if (text.length > 0) {
              if (!validateEmailsFormat(text)) {
                setHasInvalidEmail(true);
                setEmailErrorMsg(t('invalidEmail'));
              } else if (!validateEmailsLength(text)) {
                setHasInvalidEmail(true);
                setEmailErrorMsg(t('maxLengthEmail'));
              } else {
                setHasInvalidEmail(false);
                setEmailErrorMsg('');
              }
            } else {
              setHasInvalidEmail(false);
              setEmailErrorMsg('');
            }

            onChangeEmail(text);
          }}
          placeholder={t('emailLabel')}
          backgroundIcon={shareImg}
          onBlur={onBlurEmail}
          haserror={!!errors.email || hasInvalidEmail}
        />
        <ShareButton
          onPress={onClickShare}
          disabled={disabled || hasInvalidEmail || !email}
          title={t('send')}
          theme="lightSecondary"
          icon={<Share />}
          type="submit"
          isLoading={isLoading}
        />
      </InputRow>
      <InputRow>
        <TeamErrorMessage />
        <EmailInstruction>{t('emailInstruction')}</EmailInstruction>
      </InputRow>
      <InputRow>
        {(errors.teamId || errors.email || hasInvalidEmail) && (
          <TeamErrorMessage>{errors.teamId}</TeamErrorMessage>
        )}
        {(errors.email || hasInvalidEmail) && (
          <EmailErrorMessage>
            {hasInvalidEmail ? emailErrorMsg : errors.email}
          </EmailErrorMessage>
        )}
      </InputRow>
      <ParticipantLabel>{t('participant')}</ParticipantLabel>
      {isFetchingTeamMembers ? (
        <>
          <Row>
            <Skeleton variant="text" width="100%" height={50} />
          </Row>
        </>
      ) : (
        <ParticipantWrapper>
          {participants.map(({ role, name, id }, index) => {
            const overflowName =
              name.length <= 15 ? name : handleTextOverflow(name, 15);
            const selfName =
              currentUser?.user.customer.id === id
                ? `${overflowName}(自分)`
                : overflowName;
            return (
              <>
                {isDeletingTeamMember && deletingIndex === index ? (
                  <DeletingSkeleton key={index} variant="rectangular" />
                ) : (
                  <Row key={`participant-${role}-${name}-${index}`}>
                    <Tooltip position="right" label={name}>
                      <Participant>{selfName}</Participant>
                    </Tooltip>
                    <ParticipantDetails>
                      <RoleLabel>{role}</RoleLabel>
                      {currentUser?.user.customer.id === id ? (
                        <span style={{ visibility: 'hidden' }}>
                          {t('delete')}
                        </span>
                      ) : (
                        <DeleteButton
                          onClick={() => {
                            setDeletingIndex(index);
                            setSelectedDeleteMember(id);
                          }}>
                          {t('delete')}
                        </DeleteButton>
                      )}
                    </ParticipantDetails>
                  </Row>
                )}
              </>
            );
          })}

          <MemberDeleteModal
            confirmationText={t('deleteThisAccountInTeam')}
            isOpen={!!selectedDeleteMember}
            onClose={onCloseDeleteMemberModal}
            onDelete={() => {
              onClickDelete(String(selectedDeleteMember));
              setSelectedDeleteMember(null);
            }}
            isLoading={isDeletingTeamMember}
          />
        </ParticipantWrapper>
      )}
    </FormWrapper>
  );
};

export default Component;
