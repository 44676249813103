import React from 'react';

type Props = {
  [key: string]: string | number | Function;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <rect
        x="1"
        y="1"
        width="9"
        height="9"
        rx="2"
        stroke="#252B42"
        strokeWidth="2"
      />
      <rect
        x="14"
        y="1"
        width="9"
        height="9"
        rx="2"
        stroke="#252B42"
        strokeWidth="2"
      />
      <rect
        x="1"
        y="14"
        width="9"
        height="9"
        rx="2"
        stroke="#252B42"
        strokeWidth="2"
      />
      <rect
        x="14"
        y="14"
        width="9"
        height="9"
        rx="2"
        stroke="#252B42"
        strokeWidth="2"
      />
    </svg>
  );
};

export default Component;
