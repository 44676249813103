import React, { useEffect } from 'react';
import styled from 'styled-components';
import { motion, useMotionValue } from 'framer-motion';
import { DiagramCursor } from '../Icons';
import theme from '../../../constants/themes';
import { CURSOR_COLOR_OPTIONS } from '../../../constants/data/cursor';
import { handleTextOverflow } from '../../../utils/strings';

const StyledLabel = styled.span<{ bgColor: string; fontColor: string }>`
  font-family: ${theme.fonts.primary};
  font-size: 13px;
  line-height: 19px;
  background-color: ${props => props.bgColor};
  color: ${props => props.fontColor};
  height: 13px;
  padding: 2px;
  margin-top: 20px;
  padding-top: 7px;
`;

export type Props = {
  name?: string;
  userId: number;
  posY: number;
  posX: number;
};

const Component = ({ name, userId, posY, posX }: Props): React.ReactElement => {
  const posXMotion = useMotionValue(0);
  const posYMotion = useMotionValue(0);

  const index = (userId || 0) % CURSOR_COLOR_OPTIONS.length;
  const options = CURSOR_COLOR_OPTIONS[index];

  useEffect(() => {
    posXMotion.set(posX);
  }, [posX]);

  useEffect(() => {
    posYMotion.set(posY);
  }, [posY]);
  const username = name ? name : '';
  const tooltipName = handleTextOverflow(username, 31);

  return (
    <motion.div
      style={{
        y: posYMotion,
        x: posXMotion,
        userSelect: 'none',
        width: 'fit-content',
        zIndex: 100,
      }}>
      <DiagramCursor
        color={name ? options.cursorColor : theme.colors.black}
        borderColor={theme.colors.white}
      />
      {name && (
        <StyledLabel
          fontColor={options.fontColor}
          bgColor={options.cursorColor}>
          {tooltipName}
        </StyledLabel>
      )}
    </motion.div>
  );
};

export default Component;
