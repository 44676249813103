import { Skeleton } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { Plan } from '../../../domain/entities/plan';
import { PlanCard } from '../PlanCard';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const PriceTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  letter-spacing: -0.02em;
  padding: 0.5em 0.2em;
`;

const PriceDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 0 0.2em;
`;

export type Props = {
  planContent: Plan[] | undefined;
  isFetching?: boolean;
  onSelectPlan?: (plan: string) => void;
};

const Component = ({
  planContent,
  onSelectPlan,
  isFetching,
}: Props): React.ReactElement => {
  const handleSelectPlan = (plan: string) => {
    if (onSelectPlan) {
      onSelectPlan(plan);
    }
  };

  return (
    <Container>
      <PriceTitle>料金</PriceTitle>
      <PriceDetails>
        {isFetching ? (
          <>
            <Skeleton
              sx={{
                bgcolor: '#737373',
                margin: '30px',
                borderRadius: '10px',
              }}
              variant="rectangular"
              width={325}
              height={438}
            />
            <Skeleton
              sx={{
                bgcolor: '#737373',
                margin: '30px',
                borderRadius: '10px',
              }}
              variant="rectangular"
              width={325}
              height={438}
            />
            <Skeleton
              sx={{
                bgcolor: '#737373',
                margin: '30px',
                borderRadius: '10px',
              }}
              variant="rectangular"
              width={325}
              height={438}
            />
          </>
        ) : (
          <>
            {planContent?.map(dataPlan => (
              <PlanCard
                isMobile
                key={dataPlan.id}
                themeColor={dataPlan.themeColor}
                name={dataPlan.type}
                description={dataPlan.description}
                bodyTitle={dataPlan.pricing}
                buttonTitle={dataPlan.buttonName}
                points={dataPlan.content}
                pricing={dataPlan.price}
                onPress={() => handleSelectPlan(dataPlan.type)}
              />
            ))}
          </>
        )}
      </PriceDetails>
    </Container>
  );
};

export default Component;
