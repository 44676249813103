import React, { useState } from 'react';
import styled from 'styled-components';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import theme from '../../../constants/themes';

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.colors.white,
    color: theme.colors.baseColor,
    border: `1px solid ${theme.colors.strokeLight}`,
    padding: 0,
    borderRadius: 0,
  },
  [`& .${tooltipClasses.arrow}::before`]: {
    border: `1px solid ${theme.colors.strokeLight}`,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.colors.white,
  },
  [`&&& .${tooltipClasses.tooltipPlacementRight}`]: {
    marginLeft: 18,
  },
}));

const Wrapper = styled.span``;

const StyledMenuItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 167px;
  margin: 3px 3px 9px 3px;
  border-bottom: 1px solid ${theme.colors.border};
  min-height: 34px;
  width: 100%;
  margin: 0;
  cursor: pointer;
  :last-child {
    border-bottom: none;
  }

  :hover {
    background-color: ${theme.colors.dropdownOptionHover};
  }
`;

const Label = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.2px;

  color: ${theme.colors.textColor};
`;

export type Props = {
  children: React.ReactElement;
  menu: {
    label: string;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
  }[];
  className?: string;
};

const Component = ({
  children,
  menu,
  className,
}: Props): React.ReactElement => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <ClickAwayListener
      onClickAway={() => {
        setIsOpen(false);
      }}>
      <span className="tooltip-wrapper">
        <StyledTooltip
          open={isOpen}
          className={className}
          title={
            <>
              {menu.map((menuItem, menuItemIndex) => (
                <StyledMenuItem
                  key={`menu-item-${menuItemIndex}`}
                  onClick={menuItem.onClick}>
                  <Label>{menuItem.label}</Label>
                </StyledMenuItem>
              ))}
            </>
          }>
          <Wrapper
            onClick={() => {
              setIsOpen(!isOpen);
            }}
            className={className}>
            {children}
          </Wrapper>
        </StyledTooltip>
      </span>
    </ClickAwayListener>
  );
};

export default Component;
