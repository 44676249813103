import TeamRepository from '../../ports/TeamRepository';
import { TeamMember } from '../../../domain/entities/team';

export type fetchTeamMembersUseCase = (
  teamId: number,
  search?: string,
) => Promise<TeamMember[]>;

export const buildFetchTeamMembers = (dependencies: {
  teamRepo: TeamRepository;
}): fetchTeamMembersUseCase => {
  const { teamRepo } = dependencies;

  const fetchTeams: fetchTeamMembersUseCase = (teamId, search) =>
    teamRepo.fetchTeamMembers(teamId, search);

  return fetchTeams;
};
