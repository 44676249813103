import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Shape as Shape1 } from '../../molecules/Shape/components/Shape1';
import { Shape as Shape2 } from '../../molecules/Shape/components/Shape2';
import { Shape as Shape3 } from '../../molecules/Shape/components/Shape3';
import { Shape as Shape4 } from '../../molecules/Shape/components/Shape4';
import { Shape as Shape5 } from '../../molecules/Shape/components/Shape5';
import { Shape as Shape6 } from '../../molecules/Shape/components/Shape6';
import { Shape as Shape7 } from '../../molecules/Shape/components/Shape7';
import { Shape as Shape8 } from '../../molecules/Shape/components/Shape8';
import { Shape as Shape9 } from '../../molecules/Shape/components/Shape9';
import { Shape as Shape10 } from '../../molecules/Shape/components/Shape10';
import { Shape as Shape11 } from '../../molecules/Shape/components/Shape11';
import { Shape as Shape12 } from '../../molecules/Shape/components/Shape12';
import theme from '../../../constants/themes';

const Wrapper = styled.div`
  background-color: ${theme.colors.white};
  display: inline-flex;
  flex-direction: column;

  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.25);
  padding: 6px 13px 16px 13px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  span {
    transform: translate(0, 0);
    margin-right: 7px;
    :last-child {
      margin-right: 0px;
    }
  }
  svg {
    transition: fill 0.2s, brightness 0.2s ease;
    fill: ${theme.colors.white};
    :hover {
      fill: ${theme.colors.dropdownOptionHover};
      filter: brightness(0.8);
    }
  }
  :last-child {
    margin-bottom: 0px;
  }
`;

export type Props = {
  className?: string;
  onClickShape: (
    type:
      | 'shape-1'
      | 'shape-2'
      | 'shape-3'
      | 'shape-4'
      | 'shape-5'
      | 'shape-6'
      | 'shape-7'
      | 'shape-8'
      | 'shape-9'
      | 'shape-10'
      | 'shape-11'
      | 'shape-12',
  ) => void;
};

const Component = ({ className, onClickShape }: Props): React.ReactElement => {
  const onDragStart = useCallback(
    (event: React.DragEvent<HTMLSpanElement>, shapeType: string) => {
      event.dataTransfer.setData('node-type', 'shape');
      event.dataTransfer.setData('node-data', shapeType);
      event.dataTransfer.effectAllowed = 'move';
    },
    [],
  );
  return (
    <Wrapper className={className}>
      <Row>
        <span draggable onDragStart={ev => onDragStart(ev, 'shape-1')}>
          <Shape1 onClick={() => onClickShape('shape-1')} stroke="black" />
        </span>
        <span draggable onDragStart={ev => onDragStart(ev, 'shape-2')}>
          <Shape2 onClick={() => onClickShape('shape-2')} stroke="black" />
        </span>
        <span draggable onDragStart={ev => onDragStart(ev, 'shape-3')}>
          <Shape3 onClick={() => onClickShape('shape-3')} stroke="black" />
        </span>
        <span draggable onDragStart={ev => onDragStart(ev, 'shape-4')}>
          <Shape4 onClick={() => onClickShape('shape-4')} stroke="black" />
        </span>
      </Row>
      <Row>
        <span draggable onDragStart={ev => onDragStart(ev, 'shape-5')}>
          <Shape5 onClick={() => onClickShape('shape-5')} stroke="black" />
        </span>
        <span draggable onDragStart={ev => onDragStart(ev, 'shape-6')}>
          <Shape6 onClick={() => onClickShape('shape-6')} stroke="black" />
        </span>
        <span draggable onDragStart={ev => onDragStart(ev, 'shape-7')}>
          <Shape7 onClick={() => onClickShape('shape-7')} stroke="black" />
        </span>
        <span draggable onDragStart={ev => onDragStart(ev, 'shape-8')}>
          <Shape8 onClick={() => onClickShape('shape-8')} stroke="black" />
        </span>
      </Row>
      <Row>
        <span draggable onDragStart={ev => onDragStart(ev, 'shape-9')}>
          <Shape9 onClick={() => onClickShape('shape-9')} stroke="black" />
        </span>
        <span draggable onDragStart={ev => onDragStart(ev, 'shape-10')}>
          <Shape10 onClick={() => onClickShape('shape-10')} stroke="black" />
        </span>
        <span draggable onDragStart={ev => onDragStart(ev, 'shape-11')}>
          <Shape11 onClick={() => onClickShape('shape-11')} stroke="black" />
        </span>
        <span draggable onDragStart={ev => onDragStart(ev, 'shape-12')}>
          <Shape12 onClick={() => onClickShape('shape-12')} stroke="black" />
        </span>
      </Row>
    </Wrapper>
  );
};

export default Component;
