import React from 'react';
import { Login } from '../../templates/Login';

type Props = {};

const Component = ({}: Props): React.ReactElement => {
  return <Login />;
};

export default Component;
