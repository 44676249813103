import React from 'react';

type Props = {
  [key: string]: string | number | Function | object;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M6.49808 7.88811C6.21225 7.88811 5.92631 7.86352 5.64823 7.81496C4.81219 7.66939 4.05267 7.31344 3.45194 6.78561C2.85051 6.25717 2.45186 5.59461 2.29906 4.86958C2.25322 4.65257 2.22998 4.42975 2.22998 4.20745C2.22998 4.04705 2.38076 3.91702 2.56677 3.91702C2.75277 3.91702 2.90355 4.04705 2.90355 4.20745C2.90355 4.39495 2.92312 4.58272 2.96175 4.7656C3.09034 5.37586 3.42608 5.93363 3.93267 6.37872C4.43866 6.82331 5.07805 7.12306 5.7817 7.24559C6.25229 7.32773 6.75053 7.32723 7.21718 7.24513C7.92016 7.12216 8.55864 6.82241 9.06395 6.37822C9.56987 5.93348 9.90535 5.37635 10.0341 4.76703C10.0729 4.58394 10.0926 4.39574 10.0926 4.20742C10.0926 4.04702 10.2434 3.91699 10.4294 3.91699C10.6154 3.91699 10.7662 4.04702 10.7662 4.20742C10.7662 4.43071 10.7428 4.65408 10.6967 4.87135C10.5438 5.59519 10.1455 6.257 9.54482 6.78497C8.9449 7.31231 8.18646 7.66823 7.35146 7.81429C7.07334 7.86328 6.7861 7.88811 6.49808 7.88811Z"
        fill="white"
      />
      <path
        d="M10.4291 4.4953C10.2431 4.4953 10.0923 4.36527 10.0923 4.20487V0.580863H2.89623V4.20487C2.89623 4.36527 2.74545 4.4953 2.55944 4.4953C2.37344 4.4953 2.22266 4.36527 2.22266 4.20487V0.290432C2.22266 0.130026 2.37344 0 2.55944 0H10.4291C10.6151 0 10.7659 0.130026 10.7659 0.290432V4.20487C10.7659 4.36527 10.6151 4.4953 10.4291 4.4953Z"
        fill="white"
      />
      <path
        d="M8.75647 10.9672C8.57046 10.9672 8.41968 10.8372 8.41968 10.6768V9.80547H4.58031V10.6768C4.58031 10.8372 4.42953 10.9672 4.24352 10.9672C4.05752 10.9672 3.90674 10.8372 3.90674 10.6768V9.51504C3.90674 9.35464 4.05752 9.22461 4.24352 9.22461H8.75579C8.9418 9.22461 9.09325 9.35464 9.09325 9.51504V10.6768C9.09325 10.8372 8.94247 10.9672 8.75647 10.9672Z"
        fill="white"
      />
      <path
        d="M7.544 11.9832H5.45593C5.26992 11.9832 5.11914 11.8532 5.11914 11.6928C5.11914 11.5324 5.26992 11.4023 5.45593 11.4023H7.544C7.73001 11.4023 7.88079 11.5324 7.88079 11.6928C7.88079 11.8532 7.73001 11.9832 7.544 11.9832Z"
        fill="white"
      />
      <path
        d="M10.0363 12.9996H2.96374C2.77773 12.9996 2.62695 12.8696 2.62695 12.7092V10.6762C2.62695 10.5158 2.77773 10.3857 2.96374 10.3857H10.0363C10.2223 10.3857 10.373 10.5158 10.373 10.6762V12.7092C10.373 12.8696 10.2223 12.9996 10.0363 12.9996ZM3.30053 12.4188H9.69947V10.9666H3.30053V12.4188Z"
        fill="white"
      />
      <path
        d="M12.6631 2.28301H10.474C10.288 2.28301 10.1372 2.15299 10.1372 1.99258C10.1372 1.83217 10.288 1.70215 10.474 1.70215H12.6631C12.8491 1.70215 12.9999 1.83217 12.9999 1.99258C12.9999 2.15299 12.8491 2.28301 12.6631 2.28301Z"
        fill="white"
      />
      <path
        d="M10.3654 5.10987C10.2277 5.10987 10.0984 5.03645 10.0488 4.9183C9.98545 4.76751 10.0758 4.60094 10.2507 4.54629C11.4923 4.15827 12.3265 3.132 12.3265 1.99258C12.3265 1.83217 12.4773 1.70215 12.6633 1.70215C12.8493 1.70215 13.0001 1.83217 13.0001 1.99258C13.0001 3.37564 11.9874 4.62136 10.4802 5.09241C10.4423 5.10423 10.4036 5.10987 10.3654 5.10987Z"
        fill="white"
      />
      <path
        d="M2.5259 2.28301H0.336787C0.150779 2.28301 0 2.15299 0 1.99258C0 1.83217 0.150779 1.70215 0.336787 1.70215H2.5259C2.71191 1.70215 2.86269 1.83217 2.86269 1.99258C2.86269 2.15299 2.71191 2.28301 2.5259 2.28301Z"
        fill="white"
      />
      <path
        d="M2.63445 5.10984C2.59366 5.10987 2.5524 5.10345 2.51263 5.09006C2.51179 5.08977 2.51091 5.08951 2.51007 5.08922C1.00857 4.61602 0 3.37216 0 1.99258C0 1.83217 0.150779 1.70215 0.336787 1.70215C0.522794 1.70215 0.673573 1.83217 0.673573 1.99258C0.673573 3.13046 1.50621 4.15618 2.74545 4.54495C2.7471 4.54547 2.74875 4.546 2.75037 4.54652C2.76246 4.55038 2.77398 4.55468 2.78529 4.55956C2.95167 4.63129 3.01909 4.80576 2.9359 4.94923C2.87693 5.051 2.75798 5.10984 2.63445 5.10984Z"
        fill="white"
      />
      <path
        d="M7.55202 5.27861C7.49844 5.27861 7.44465 5.2676 7.39538 5.24527L6.49993 4.83942L5.60448 5.24527C5.49102 5.2967 5.35351 5.28813 5.24982 5.22314C5.14612 5.15814 5.09419 5.048 5.11587 4.93906L5.287 4.07921L4.56247 3.4705C4.47063 3.39336 4.43759 3.27794 4.47716 3.17278C4.51677 3.06761 4.62215 2.99096 4.74901 2.97505L5.75021 2.8495L6.19794 2.06716C6.25469 1.96804 6.37175 1.90527 6.49993 1.90527C6.62815 1.90527 6.74522 1.96804 6.80193 2.06716L7.24965 2.8495L8.25085 2.97505C8.37772 2.99096 8.4831 3.06761 8.52271 3.17278C8.56231 3.27794 8.52924 3.39336 8.4374 3.4705L7.71287 4.07921L7.88399 4.93906C7.90568 5.048 7.85375 5.15814 7.75005 5.22314C7.69135 5.25988 7.62187 5.27861 7.55202 5.27861ZM6.49993 4.22086C6.55375 4.22086 6.60757 4.23198 6.65661 4.2542L7.1047 4.4573L7.01906 4.02688C7.0003 3.93263 7.03657 3.83647 7.11595 3.76973L7.4785 3.46512L6.9775 3.40227C6.86784 3.38851 6.77303 3.32909 6.72396 3.24341L6.4999 2.85191L6.27584 3.24341C6.22677 3.32911 6.13199 3.38854 6.0223 3.40227L5.5213 3.46512L5.88385 3.76973C5.96326 3.83647 5.9995 3.93263 5.98074 4.02688L5.8951 4.4573L6.34319 4.2542C6.3923 4.23198 6.44611 4.22086 6.49993 4.22086Z"
        fill="white"
      />
      <path
        d="M8.75621 9.80552C8.73169 9.80552 8.70683 9.8032 8.68195 9.79838C7.46938 9.56301 6.69487 8.51888 6.95551 7.47077C6.99451 7.31394 7.17351 7.21418 7.35541 7.24769C7.53728 7.28132 7.6531 7.43575 7.6141 7.59255C7.43055 8.33062 7.97594 9.06594 8.82983 9.23169C9.01129 9.26689 9.12529 9.42233 9.08444 9.57881C9.04921 9.71384 8.91022 9.80552 8.75621 9.80552Z"
        fill="white"
      />
      <path
        d="M4.2433 9.80584C4.08925 9.80584 3.9503 9.71415 3.91503 9.57913C3.87418 9.42265 3.98818 9.26724 4.16964 9.23201C5.0237 9.06626 5.56923 8.3308 5.38571 7.59258C5.34671 7.43574 5.46257 7.28135 5.64443 7.24772C5.82633 7.21412 6.00533 7.31397 6.04433 7.47083C6.3049 8.51903 5.53029 9.5633 4.31756 9.7987C4.29267 9.80352 4.26778 9.80584 4.2433 9.80584Z"
        fill="white"
      />
    </svg>
  );
};

export default Component;
