import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import { Form } from 'formik';
import { TextInput } from '../../atoms/TextInput';
import { Button } from '../../atoms/Button';
import theme from '../../../constants/themes';
import { InlineErrorToast } from '../../atoms/InlineErrorToast';

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledLabel = styled.span`
  font-family: ${theme.fonts.primary};
  font-style: normal;
  font-weight: 600;
  font-size: 33px;
  line-height: 130%;
  color: ${theme.colors.black};
`;

const StyledInput = styled(TextInput)`
  min-width: 436px;
  height: 44px;
  margin-top: 8px;

  @media ${theme.breakpoints.mobile} {
    width: 100%;
    min-width: 100%;
  }
`;

const StyledInputLabel = styled.span`
  font-family: ${theme.fonts.primary};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 175%;
  color: ${theme.colors.text02};
`;

const ButtonWrapper = styled.div`
  width: 249px;
  height: 44px;
  border-radius: 5px;
  margin-top: 24px;

  .reset-btn {
    &:hover {
      background-color: #2eaf69 !important;
    }
    &:active {
      background-color: #25a55f !important;
    }
    &:disabled {
      background-color: #7ccda0 !important;
      border-color: #7ccda0 !important;
    }
  }
`;

const InputWrapper = styled(Box)`
  margin-top: 12px;

  @media ${theme.breakpoints.mobile} {
    width: 100%;
  }
`;

const ErrorMessage = styled.span`
  width: 436px;
  font-family: ${theme.fonts.primary};
  font-weight: 400;
  font-size: 13px;
  line-height: 14px;
  color: ${theme.colors.textError};
  margin-top: 8px;
  text-align: left;
`;

const ApiErrorWrapper = styled.div`
  width: 436px;
  height: min-content;
  margin-top: 16px;
`;

export type Props = {
  onPressReset: () => void;
  onChangePassword: (password: string) => void;
  onChangeRepassword: (password: string) => void;
  password: string;
  repassword: string;
  onBlurPassword: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlurRepassword: (e: React.FocusEvent<HTMLInputElement>) => void;
  errors: {
    password?: string;
    repassword?: string;
    apiError?: string;
  };
  isLoading?: boolean;
};

const Component = ({
  onPressReset,
  onChangePassword,
  onChangeRepassword,
  password,
  repassword,
  onBlurPassword,
  onBlurRepassword,
  errors,
  isLoading,
}: Props): React.ReactElement => {
  const [apiError, setApiError] = useState<string | undefined>(undefined);

  const handleOnPressReset = () => {
    setApiError('');
    onPressReset();
  };

  useEffect(() => {
    setApiError(errors?.apiError);
  }, [errors?.apiError]);

  return (
    <StyledForm>
      <StyledLabel>パスワードリセット</StyledLabel>
      {apiError && (
        <ApiErrorWrapper>
          <InlineErrorToast
            message={apiError}
            onClose={() => setApiError('')}
          />
        </ApiErrorWrapper>
      )}
      <InputWrapper>
        <StyledInputLabel>パスワード</StyledInputLabel>
        <StyledInput
          type="password"
          onChangeText={onChangePassword}
          isError={!!errors.password}
          value={password}
          onBlur={onBlurPassword}
        />
      </InputWrapper>
      {errors?.password && <ErrorMessage>{errors.password}</ErrorMessage>}
      <InputWrapper marginTop={3.2}>
        <StyledInputLabel>再入力パスワード</StyledInputLabel>
        <StyledInput
          type="password"
          onChangeText={onChangeRepassword}
          isError={!!errors.repassword}
          value={repassword}
          onBlur={onBlurRepassword}
        />
      </InputWrapper>
      {errors?.repassword && <ErrorMessage>{errors.repassword}</ErrorMessage>}
      <ButtonWrapper>
        <Button
          disabled={isLoading}
          className="reset-btn"
          title="リセット"
          onPress={handleOnPressReset}
          type="submit"
          style={{
            backgroundColor: theme.colors.successToastText,
            border: `thin solid ${theme.colors.successToastText}`,
          }}
        />
      </ButtonWrapper>
    </StyledForm>
  );
};

export default Component;
