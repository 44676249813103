import { Plan } from '../../../domain/entities/plan';
import PlanRepository from '../../ports/PlanRepository';

export type fetchPlansUseCase = () => Promise<Plan[]>;

export const buildFetchPlans = (dependencies: {
  planRepo: PlanRepository;
}): fetchPlansUseCase => {
  const { planRepo } = dependencies;

  const fetchPlans: fetchPlansUseCase = () => planRepo.fetchPlans();

  return fetchPlans;
};
