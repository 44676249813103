import PaymentRepository from '../../ports/PaymentRepository';
import { buildChoosePlan, choosePlanUseCase } from './choose-plan';
import {
  buildFetchPaymentHistory,
  fetchPaymentHistoryUseCase,
} from './fetch-payment-history';
import {
  buildFetchInvoiceBlob,
  fetchInvoiceBlobUseCase,
} from './fetch-invoice-blob';

export default function paymentUseCases(dependencies: {
  paymentRepo: PaymentRepository;
}): {
  choosePlan: choosePlanUseCase;
  fetchPaymentHistory: fetchPaymentHistoryUseCase;
  fetchInvoiceBlob: fetchInvoiceBlobUseCase;
} {
  const { paymentRepo } = dependencies;
  const choosePlan = buildChoosePlan({ paymentRepo });
  const fetchPaymentHistory = buildFetchPaymentHistory({ paymentRepo });
  const fetchInvoiceBlob = buildFetchInvoiceBlob({ paymentRepo });
  return {
    choosePlan,
    fetchPaymentHistory,
    fetchInvoiceBlob,
  };
}
