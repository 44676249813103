import AuthService from '../../ports/AuthService';

export type resendVerificationUseCase = (token: string) => Promise<object>;

export const buildResendVerification = (dependencies: {
  authService: AuthService;
}): resendVerificationUseCase => {
  const { authService } = dependencies;

  const resendVerification: resendVerificationUseCase = token =>
    authService.resendEmailVerification(token);

  return resendVerification;
};
