import React from 'react';
import styled from 'styled-components';
import theme from '../../../constants/themes';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { Button } from '../../atoms/Button';
import { MyBoardDropdown } from '../../atoms/MyBoardDropdown';
import { ModalWrapper } from '../../molecules/ModalWrapper';
import { isWindowsOS } from '../../../utils/booleans';
import { MemberEditSchema } from './validation';
import { TeamMember } from '../../../domain/entities/team';

const Container = styled(Box)`
  width: 683px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 45px;
`;

const Wrapper = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${theme.colors.white};
`;

const StyledBtn = styled(Button)`
  width: 107px;
  height: 50px;
  margin-top: 20px;
  background-color: ${theme.colors.notifToastBorder};
  border: thin solid ${theme.colors.notifToastBorder};
  :hover {
    background-color: #56ab82;
    border-color: #56ab82;
  }
  :active {
    background-color: #4c9572;
    border-color: #4c9572;
  }
  :disabled {
    background-color: #9adfbe;
    border-color: #9adfbe;
  }
`;

const StyledDropdown = styled(MyBoardDropdown)`
  &&& {
    width: 440px;
    height: 50px;
    border: 1px solid #dadada;
    border-radius: 5px;
    background-color: ${theme.colors.white};
    font-family: ${theme.fonts.primary};
    font-weight: 400;
    font-size: 14px;
    line-height: ${isWindowsOS() ? 24 : 28}px;
    color: ${theme.colors.inputBorder};

    .MuiInputBase-input {
      padding: ${isWindowsOS() ? 13 : 6}px 30px 6px;
    }

    .MuiOutlinedInput-notchedOutline {
      border: none;
    }

    &.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border: 1px solid #5c5c5c;
      }
    }
  }
`;

export type Props = {
  isOpen?: boolean;
  isLoading?: boolean;
  onClose: () => void;
  onSubmit: (values: { role: string }) => void;
  member: TeamMember | null;
};

const Component = ({
  isOpen = false,
  isLoading = false,
  onClose,
  member,
  onSubmit,
}: Props): React.ReactElement => {
  const { t } = useTranslation();

  const initialValues = {
    role: member?.memberType === 'メンバー' ? '0' : '1',
  };

  const roles = [
    {
      id: '0',
      label: t('member'),
    },
    {
      id: '1',
      label: t('manager'),
    },
  ];

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={MemberEditSchema}>
      {({
        handleChange,
        handleSubmit,
        handleBlur,
        values,
        resetForm,
      }): React.ReactElement => {
        const { role } = values;
        return (
          <ModalWrapper
            isOpen={isOpen}
            title={t('editMember')}
            onClose={() => {
              resetForm();
              onClose();
            }}>
            <Container>
              <Wrapper>
                <StyledDropdown
                  values={roles}
                  selected={role}
                  onChangeSelected={handleChange('role')}
                  onBlur={handleBlur('role')}
                  menuListStyle={{
                    width: '440px !important',
                  }}
                />
                <StyledBtn
                  title={t('keep')}
                  onPress={() => handleSubmit()}
                  type="submit"
                  disabled={isLoading}
                  isLoading={isLoading}
                />
              </Wrapper>
            </Container>
          </ModalWrapper>
        );
      }}
    </Formik>
  );
};

export default Component;
