import React, {
  useState,
  useMemo,
  useCallback,
  useRef,
  useEffect,
} from 'react';
import { useNodesState, Node, useEdgesState, Edge } from 'react-flow-renderer';
import xmljs from 'xml-js';
import { BoardHooks, BoardHooksContext } from '.';
import {
  DiagramEdge,
  DiagramNode,
} from '../../components/templates/DiagramEditor/types';
import * as boardHooks from './hooks';
import { useGlobalState } from '../global';
import { useSocketHooks } from '../socket';
import events from '../../constants/socket';
import { parseStrToNodesEdges } from '../../utils/strings';
import {
  includeWhitelistedNodes,
  removeWhitelistedNodes,
} from '../../utils/arrays';

type Props = {
  value?: BoardHooks;
  children?: React.ReactElement;
};

const Provider = (props: Props): React.ReactElement => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const nodesRef = useRef<Node[]>([]);
  const edgesRef = useRef<Edge[]>([]);
  const recentlySelectedNodeIdsRef = useRef<string[]>([]);
  const [actions, setActions] = useState<
    { undo: () => void; redo: () => void }[]
  >([]);
  const [cursors, setCursors] = useState<{
    [id: string]: { id: number; name: string; posX: number; posY: number };
  }>({});
  const [activeCustomerIds, setActiveCustomerIds] = useState<number[]>([]);
  const [boardVote, setBoardVote] = useState<string>('');
  const [requestDiagramSocketId, setRequestDiagramSocketId] = useState<string>(
    '',
  );

  const [currentIndex, setCurrentIndex] = useState<number>(-1);
  const [copiedNodes, setCopiedNodes] = useState<DiagramNode[]>([]);
  const [recentlySelectedNodeIds, setRecentlySelectedNodeIds] = useState<
    string[]
  >([]);
  const [customersSelectedNodeIds, setCustomersSelectedNodeIds] = useState<{
    [key: number]: string[];
  } | null>(null);
  const { useCurrentUser } = useGlobalState();
  const { currentUser } = useCurrentUser;
  const { useSocket } = useSocketHooks();
  const { socket, initializedBoardCode } = useSocket;

  useEffect(() => {
    nodesRef.current = nodes;
    edgesRef.current = edges;
  }, [nodes, edges]);

  useEffect(() => {
    recentlySelectedNodeIdsRef.current = recentlySelectedNodeIds;
  }, [recentlySelectedNodeIds]);

  const sendDiagramUpdate = useCallback(
    (nodes: DiagramNode[], edges: DiagramEdge[], name: string) => {
      if (socket) {
        const jsonData = JSON.stringify({
          _declaration: { _attributes: { version: '1.0' } },
          data: { nodes: removeWhitelistedNodes(nodes), edges },
        });

        const xmlStr = xmljs.json2xml(jsonData, { compact: true });
        const eventData = {
          customers_selected_node_ids: '',
          diagram_content: xmlStr,
          board_code: initializedBoardCode,
          username: name,
          token: currentUser?.user.token,
        };
        socket.emit(events.CLIENT.SEND_DIAGRAM_FILE_CONTENT, eventData);
      }
    },
    [socket],
  );

  const nodeState = useMemo(
    () => ({
      nodes,
      getNodes: () => nodesRef.current,
      // NOTE: be careful when calling this as this will persist the node state via socket
      setNodes: (args: Node[] | ((latestNodes: Node[]) => Node[])) => {
        const isArray = Array.isArray(args);
        if (isArray) {
          nodesRef.current = args;
          sendDiagramUpdate(args, edgesRef.current, '');
        }
        setNodes(args);
      },
      setNodesFromSocket: (
        args: Node[] | ((latestNodes: Node[]) => Node[]),
      ) => {
        setNodes(args);
      },
      onNodesChange,
      clearLocalNodes: () => {
        setNodes([]);
      },
    }),
    [sendDiagramUpdate, socket, nodes],
  );

  const edgeState = useMemo(
    () => ({
      edges,
      getEdges: () => edgesRef.current,
      setEdges: (args: Edge[] | ((latestNodes: Edge[]) => Edge[])) => {
        if (Array.isArray(args)) {
          edgesRef.current = args;
        }
        setEdges(args);
      },
      setEdgesFromSocket: (
        args: Edge[] | ((latestNodes: Edge[]) => Edge[]),
      ) => {
        setEdges(args);
      },
      onEdgesChange,
      clearLocalEdges: () => {
        setEdges([]);
      },
    }),
    [edges, nodes],
  );

  const cursorState = useMemo(
    () => ({
      cursors,
      setCursors,
    }),
    [cursors, setCursors],
  );
  const activeCustomerIdsState = useMemo(
    () => ({
      activeCustomerIds,
      setActiveCustomerIds,
    }),
    [activeCustomerIds, setActiveCustomerIds],
  );
  const boardVoteState = useMemo(
    () => ({
      boardVote,
      setBoardVote,
    }),
    [boardVote, setBoardVote],
  );
  const customersNodeIdsState = useMemo(
    () => ({
      customersSelectedNodeIds,
      setCustomersSelectedNodeIds,
    }),
    [customersSelectedNodeIds, setCustomersSelectedNodeIds],
  );

  const useActions = useMemo(
    () => ({
      insertAction: (callbacks: { undo: () => void; redo: () => void }) => {
        const newCurrentIndex = currentIndex + 1;
        const filteredActions = [...actions, callbacks].filter(
          (callback, index) => index <= newCurrentIndex,
        );
        const newActions = filteredActions.map((cb, index) =>
          index === newCurrentIndex ? callbacks : cb,
        );
        setCurrentIndex(newCurrentIndex);
        setActions(newActions);
      },
      clearActions: () => {
        setActions([]);
        setCurrentIndex(-1);
      },
      undo: () => {
        const callback = actions.find((action, index) => index === currentIndex)
          ?.undo;
        if (callback) {
          callback();
          setCurrentIndex(currentIndex - 1);
        }
      },
      redo: () => {
        const callback = actions.find(
          (action, index) => index === currentIndex + 1,
        )?.redo;
        if (callback) {
          callback();
          setCurrentIndex(currentIndex + 1);
        }
      },
    }),
    [actions, currentIndex],
  );
  const useClipboard = useMemo(
    () => ({
      copiedNodes,
      setCopiedNodes,
    }),
    [copiedNodes, setCopiedNodes],
  );
  const useMySelectedNodes = useMemo(
    () => ({
      recentlySelectedNodeIds,
      setRecentlySelectedNodeIds: (nodeIds: string[]) => {
        const oldNodeIds = [...recentlySelectedNodeIds];
        const newNodeIds = nodeIds;
        if (nodeIds.length || newNodeIds.length) {
          setNodes(nodes => {
            return nodes.map(node => {
              let newSelectedValue = node.selected;
              if (oldNodeIds.includes(node.id)) {
                newSelectedValue = false;
              }
              if (newNodeIds.includes(node.id)) {
                newSelectedValue = true;
              }
              return {
                ...node,
                selected: newSelectedValue,
              };
            });
          });
        }
        setRecentlySelectedNodeIds(nodeIds);
      },
    }),
    [recentlySelectedNodeIds],
  );

  useEffect(() => {
    if (socket) {
      socket.on('disconnect', () => {
        setBoardVote('');
        setActiveCustomerIds([]);
        setCustomersSelectedNodeIds(null);
        setCursors({});
      });
    }
  }, [socket]);

  useEffect(() => {
    if (socket) {
      // For new user joining the room
      socket.on(events.SERVER.JOINED_ROOM, data => {
        setActiveCustomerIds(prev => {
          const uniqueCustomerIds = Array.from(
            new Set([...prev, data.user_id]),
          );
          return uniqueCustomerIds;
        });
      });

      // For getting active users in the room
      socket.on(events.SERVER.MEMBER_IN_ROOM, data => {
        if (data.members.length) {
          const members = data.members
            .split(',')
            .map((item: string) => Number(item));

          setActiveCustomerIds(prev => {
            const uniqueCustomerIds = Array.from(
              new Set([...prev, ...members]),
            );
            return uniqueCustomerIds;
          });
        }
      });

      // For multiple user edit board
      socket.on(events.SERVER.DIAGRAM_FILE_CONTENT, data => {
        const jsonStr = xmljs.xml2json(data.diagram_content, {
          compact: true,
        });
        const { nodes, edges } = parseStrToNodesEdges(jsonStr);
        setNodes(
          includeWhitelistedNodes(
            nodesRef.current,
            nodes,
            recentlySelectedNodeIdsRef.current,
          ),
        );
        setEdges(edges);
      });

      // For receiving user who voted/unvoted
      socket.on(events.SERVER.VOTED, data => {
        setBoardVote(data.voted_id);
      });

      // For tracking users cursor position
      socket.on(events.SERVER.POSITION_OF_CURSOR, data => {
        setCursors(prev => {
          return {
            ...prev,
            [data.customer_id + data.board_code]: {
              posX: data.x,
              posY: data.y,
              name: data.username,
              id: data.customer_id,
            },
          };
        });
      });

      // For user leaving board
      socket.on(events.SERVER.DISCONNECT, data => {
        setActiveCustomerIds(prev =>
          prev.filter(item => item !== Number(data.userId)),
        );
        setCursors(prev => {
          const { [data.userId + data.board_code]: _, ...newCursors } = prev;
          return newCursors;
        });
        setCustomersSelectedNodeIds(prev => {
          return {
            ...prev,
            [data.userId]: [],
          };
        });
      });

      // For receiving custmers selected node ids
      socket.on(events.SERVER.LOCKED_OBJECT, data => {
        setCustomersSelectedNodeIds(prev => {
          return {
            ...prev,
            [data.user_id]: data.node_id,
          };
        });
      });

      // Accept user request for latest diagram
      socket.on(events.SERVER.GET_DIAGRAM_FILE_CONTENT, data => {
        setRequestDiagramSocketId(data.request_connection_id);
      });

      // Send latest diagram for approved user request
      socket.on(events.SERVER.RESPONSE_NEW_DIAGRAM_FILE_CONTENT, data => {
        const jsonStr = xmljs.xml2json(data.content, {
          compact: true,
        });
        const { nodes: parsedNodes, edges: parsedEdges } = parseStrToNodesEdges(
          jsonStr,
        );
        setNodes(
          includeWhitelistedNodes(
            nodesRef.current,
            parsedNodes,
            recentlySelectedNodeIdsRef.current,
          ),
        );
        setEdges(parsedEdges);
      });

      // For deleted user in board
      socket.on(events.SERVER.OUTED_ROOM, data => {
        const user = JSON.parse(data.user_id);
        setActiveCustomerIds(prev =>
          prev.filter(item => item !== Number(user.userId)),
        );
        setCursors(prev => {
          const { [user.userId + user.boardCode]: _, ...newCursors } = prev;
          return newCursors;
        });
        setCustomersSelectedNodeIds(prev => {
          return {
            ...prev,
            [user.userId]: [],
          };
        });
      });
    }
  }, [socket]);

  useEffect(() => {
    if (socket && requestDiagramSocketId) {
      const jsonData = JSON.stringify({
        _declaration: { _attributes: { version: '1.0' } },
        data: {
          nodes: removeWhitelistedNodes(nodes),
          edges: edges,
        },
      });
      const xmlStr = xmljs.json2xml(jsonData, { compact: true });
      const eventData = {
        content: xmlStr,
        request_connection_id: requestDiagramSocketId,
      };
      setRequestDiagramSocketId('');
      socket.emit(events.CLIENT.NEW_DIAGRAM_FILE_CONTENT, eventData);
    }
  }, [socket, requestDiagramSocketId, nodes, edges]);

  return (
    <BoardHooksContext.Provider
      value={{
        nodeState,
        edgeState,
        cursorState,
        activeCustomerIdsState,
        boardVoteState,
        customersNodeIdsState,
        useActions,
        useClipboard,
        useMySelectedNodes,
        ...boardHooks,
      }}
      {...props}
    />
  );
};

export default Provider;
