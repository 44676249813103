import { FormControlLabel, MenuItem, Select } from '@mui/material';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import {
  StripeCardNumberElement,
  StripeCardNumberElementOptions,
} from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import theme from '../../../constants/themes';
import { useGlobalState } from '../../../hooks/global';
import { usePaymentHooks } from '../../../hooks/payment';
import { Button } from '../../atoms/Button';
import {
  AmericanExpress,
  Check,
  Discover,
  MasterCard,
  UnknownCard,
  Visa,
  UnionPay,
  Jcb,
  DinersClub,
} from '../../atoms/Icons';
import { RadioButton } from '../../atoms/RadioButton';
import { TextInput } from '../../atoms/TextInput';
import { ModalWrapper } from '../../molecules/ModalWrapper';
import { cardTypes } from '../../templates/PaymentHistory/types';
import stripeCountries from './stripe-countries.json';
import { InlineErrorToast } from '../../atoms/InlineErrorToast';
import { paymentRepo } from '../../../hooks/dependencies';

const Container = styled.div``;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledCardNumberElement = styled(CardNumberElement)`
  background: ${theme.colors.white};
  border: 1px solid ${theme.colors.text02};
  border-radius: 4px;
  padding: 10px 14px;
`;

const StyledCardExpiryElement = styled(CardExpiryElement)`
  background: ${theme.colors.white};
  border: 1px solid ${theme.colors.text02};
  border-radius: 4px;
  padding: 10px 14px;
`;

const StyledCardCVCElement = styled(CardCvcElement)`
  background: ${theme.colors.white};
  border: 1px solid ${theme.colors.text02};
  border-radius: 4px;
  padding: 10px 14px;
`;

const CardLabel = styled.label`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 175%;
  color: ${theme.colors.textColor};
`;

const CardExpiryWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1em;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

const InputWrapper = styled.div`
  padding: 10px 14px;
  background: ${theme.colors.white};
  border: 1px solid ${theme.colors.text02};
  border-radius: 4px;
`;

const StyledTextInput = styled(TextInput)`
  border: none;
  padding: 0;
  color: ${theme.colors.text02};
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  background-color: ${theme.colors.white};
  height: 28px;
  && {
    outline: none;
  }

  :disabled {
    background-color: ${theme.colors.white};
    ::placeholder {
      color: #cfd7df;
      opacity: 1;
    }

    :-ms-input-placeholder {
      color: #cfd7df;
    }

    ::-ms-input-placeholder {
      color: #cfd7df;
    }
  }
`;

const StyledSelect = styled(Select)`
  width: 100%;
  height: 28px;
  && {
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    color: ${theme.colors.text02};

    & .MuiOutlinedInput-input.Mui-disabled {
      -webkit-text-fill-color: #cfd7df;
    }

    .MuiSelect-select {
      padding: 0;
    }
  }

  fieldset {
    border: none;
  }
`;

const StyledButton = styled(Button)<{ bgColor?: string }>`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  border: none;
  background-color: ${({ bgColor }) => bgColor || theme.colors.lightBtn};

  :hover {
    border: none;
    background-color: ${({ bgColor }) => bgColor || theme.colors.hoverLightBtn};
  }

  :disabled {
    background-color: ${theme.colors.gray};
  }
`;

const StyledMenuItem = styled(MenuItem)``;

const SavedCardTypeLabel = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: ${theme.colors.black};
`;

const SavedCardInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SavedCardNumber = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: -0.02em;

  color: ${theme.colors.black};

  span {
    vertical-align: sub;
  }
`;

const StyledAmex = styled(AmericanExpress)`
  width: 20px;
  height: 20px;
  margin-left: 5px;
`;

const StyledDiscover = styled(Discover)`
  width: 20px;
  height: 20px;
  margin-left: 5px;
`;

const StyledMaster = styled(MasterCard)`
  width: 20px;
  height: 20px;
  margin-left: 5px;
`;

const StyledVisa = styled(Visa)`
  width: 20px;
  height: 20px;
  margin-left: 5px;
`;

const StyledUnionPay = styled(UnionPay)`
  width: 20px;
  height: 18px;
  margin-left: 5px;
`;

const StyledJcb = styled(Jcb)`
  width: 20px;
  height: 18px;
  margin-left: 5px;
`;

const StyledDinersClub = styled(DinersClub)`
  width: 20px;
  height: 18px;
  margin-left: 5px;
`;

const StyledUnknown = styled(UnknownCard)`
  width: 20px;
  height: 20px;
  margin-left: 5px;
`;

const StyledRadioLabel = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 29px;
    /* identical to box height */

    letter-spacing: -0.02em;

    /* text-color */

    color: ${theme.colors.textColor};
  }
`;

const SavedInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

const SuccessModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5em;
  gap: 1em;
`;

const SuccessIndicator = styled.div`
  background: #25ae88;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin-bottom: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SuccessText = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${theme.colors.text02};

  p {
    margin: 0;
  }
`;

const SuccessTextHelper = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${theme.colors.text02};
`;

const StyledModalWrapper = styled(ModalWrapper)`
  width: 440px;
`;

const StyledCheck = styled(Check)`
  path {
    opacity: 0;
    animation: 3s opacity;
  }

  @keyframes opacity {
    15% {
      opacity: 0;
    }
    35% {
      opacity: 0;
    }
    65% {
      opacity: 0;
    }
    85% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
`;

export type Props = {
  themeColor?: string;
  planId: number;
  className?: string;
  edit?: boolean;
  onClose?: Function;
};

const Component = ({
  themeColor,
  planId,
  className,
  edit,
  onClose,
}: Props): React.ReactElement => {
  const [cardUse, setCardUse] = useState<'no' | 'yes'>('no');
  const {
    useCurrentUser: { currentUser, setCurrentUser },
  } = useGlobalState();
  const [isSuccessCheckout, setIsSuccessCheckout] = useState<boolean>(false);
  const [isProcessingPayment, setIsProcessingPayment] = useState<boolean>(
    false,
  );
  const [navigateCounter, setNavigateCounter] = useState<number>(3);
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const { useChoosePlan } = usePaymentHooks();
  const { choosePlan } = useChoosePlan();
  // eslint-disable-next-line
  const [apiError, setApiError] = useState<any>();

  const cardElementOption: StripeCardNumberElementOptions = {
    disabled: cardUse === 'no',
    style: {
      base: {
        color: theme.colors.text02,
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '28px',
        backgroundColor: theme.colors.white,
      },
    },
  };

  const [additionalInfo, setAdditionalInfo] = useState<{
    name: string;
    address: { country: string };
  }>({
    name: '',
    address: { country: 'JP' },
  });

  useEffect(() => {
    if (currentUser?.user.customer.last_card_number == null || edit) {
      setCardUse('yes');
    }
  }, []);

  useEffect(() => {
    // eslint-disable-next-line
    let navigateInterval: any;

    if (isSuccessCheckout) {
      navigateInterval = setInterval(() => {
        if (navigateCounter > 0) {
          setNavigateCounter(prev => prev - 1);
        }
      }, 1000);
    }

    return () => {
      clearInterval(navigateInterval);
    };
  }, [isSuccessCheckout, navigateCounter]);

  useEffect(() => {
    if (navigateCounter === 0) {
      if (currentUser?.user.isVerified) {
        if (onClose) {
          closeForm();
        } else {
          navigate('/my-board/plan-billing');
        }
      } else {
        navigate('/verify-email');
      }
    }
  }, [navigateCounter]);

  const freshCustomer = async (last4: string, type?: cardTypes) => {
    const updateCustomer = currentUser?.user.customer;
    const updateUser = currentUser?.user;
    if (updateCustomer !== undefined) {
      updateCustomer.last_card_number = last4;

      if (type) {
        updateCustomer.card_type = type;
      }

      if (updateUser !== undefined) {
        updateUser.customer = updateCustomer;
      }
    }

    setCurrentUser({
      user: {
        ...updateUser,
      },
    });
  };

  const getBrandStripe = (brand: string) => {
    switch (brand) {
      case 'amex':
        return 'amex';
      case 'cartes_bancaires':
        return 'cartes_bancaires';
      case 'diners':
        return 'diners';
      case 'discover':
        return 'discover';
      case 'jcb':
        return 'jcb';
      case 'mastercard':
        return 'mastercard';
      case 'visa':
        return 'visa';
      case 'unionpay':
        return 'unionpay';
      default:
        return 'unknown';
    }
  };

  const choosePlanAPI = async (planId: number) => {
    let resPaymentMethodTmp = { id: '' };
    let newLast4;
    let cardType: cardTypes = 'unknown';

    if (cardUse === 'yes') {
      // Use new card and old user want to add new card
      // eslint-disable-next-line
      const resPaymentMethod: any = await stripe?.createPaymentMethod({
        type: 'card',
        card: elements?.getElement(
          CardNumberElement,
        ) as StripeCardNumberElement,
        billing_details: additionalInfo,
      });

      if (resPaymentMethod?.error) {
        setApiError(resPaymentMethod?.error?.message);
        setIsProcessingPayment(false);
        return;
      }
      if (currentUser?.user.isVerified) {
        newLast4 = resPaymentMethod?.paymentMethod?.card?.last4;
        cardType = getBrandStripe(resPaymentMethod?.paymentMethod?.card?.brand);

        // eslint-disable-next-line
        const res: any = await paymentRepo.attacthCardPayment(
          resPaymentMethod.paymentMethod.id,
        );
        if (res?.data?.status_code == 400) {
          setApiError(res?.data.message);
          setIsProcessingPayment(false);
          return;
        }
      }
      resPaymentMethodTmp = resPaymentMethod.paymentMethod;
    }

    //Edit card
    if (edit) {
      if (cardUse === 'yes' && newLast4 && cardType) {
        freshCustomer(newLast4.toString(), cardType);
      }

      setIsSuccessCheckout(true);
      return;
    }

    const plan = await choosePlan(planId, resPaymentMethodTmp.id);
    if (!plan) return; //TODO: error check
    if (cardUse === 'yes') {
      // Use new card
      if (plan?.clientSecret) {
        // on Creating new Customer, need to confirm a card payment intent
        const confirmedCardPayment = await stripe?.confirmCardPayment(
          plan.clientSecret,
          { payment_method: plan?.paymentMethod?.id },
        );
        if (confirmedCardPayment?.error) {
          setApiError(confirmedCardPayment?.error?.message);
          await paymentRepo.cancelSub(plan?.subscriptionId);
          setIsProcessingPayment(false);
          return;
        }
        if (confirmedCardPayment?.paymentIntent?.status === 'succeeded') {
          freshCustomer(
            plan?.paymentMethod.card.last4,
            getBrandStripe(plan?.paymentMethod?.card?.brand),
          );
          setIsSuccessCheckout(true);
        }
      } else {
        // on existing customer, need to attach card payment method to customer on stripe
        freshCustomer(
          plan?.paymentMethod.card.last4,
          getBrandStripe(plan?.paymentMethod?.card?.brand),
        );
        setIsSuccessCheckout(true);
      }
    } else {
      setIsSuccessCheckout(true);
    }

    setIsProcessingPayment(false);
  };

  const closeForm = () => {
    if (onClose) {
      onClose();
    }
  };

  // eslint-disable-next-line
  const handleSubmit = async (event: any) => {
    event.preventDefault();

    setIsProcessingPayment(true);
    setApiError('');

    if (elements === null) {
      setIsProcessingPayment(false);
      return;
    }
    choosePlanAPI(planId);
  };

  const renderCardIcon = (cardType?: cardTypes): React.ReactElement => {
    switch (cardType) {
      case 'amex':
        return <StyledAmex />;
      case 'cartes_bancaires':
        return <StyledVisa />;
      case 'diners':
        return <DinersClub />;
      case 'discover':
        return <StyledDiscover />;
      case 'jcb':
        return <StyledJcb />;
      case 'mastercard':
        return <StyledMaster />;
      case 'visa':
        return <StyledVisa />;
      case 'unionpay':
        return <StyledUnionPay />;
      default:
        return <StyledUnknown />;
    }
  };
  const ApiErrorWrapper = styled.div`
    width: 100%;
    height: min-content;
    margin-top: 16px;
  `;

  const getBrandLabel = (cardType?: cardTypes): string => {
    switch (cardType) {
      case 'amex':
        return 'American Express';
      case 'cartes_bancaires':
        return 'Cartes Bancaires';
      case 'diners':
        return 'Diners Club';
      case 'discover':
        return 'Discover';
      case 'jcb':
        return 'Japan Credit Bureau (JCB)';
      case 'mastercard':
        return 'Mastercard';
      case 'visa':
        return 'Visa';
      case 'unionpay':
        return 'China UnionPay';
      default:
        return 'UNKNOWN';
    }
  };

  // https://stripe.com/docs/payments/accept-a-payment-synchronously?html-or-react=react

  return (
    <Container className={className}>
      <StyledModalWrapper showTitle={false} isOpen={isSuccessCheckout}>
        <SuccessModalWrapper>
          <SuccessIndicator>
            <StyledCheck />
          </SuccessIndicator>
          <SuccessText>
            <p>ありがとうございました。</p>
            <p>
              {currentUser?.user.isVerified
                ? 'アップグレードが完了しました。'
                : '決済が完了しました。'}
            </p>
          </SuccessText>
          <SuccessTextHelper>
            {navigateCounter}{' '}
            {currentUser?.user.isVerified
              ? '秒後に自動的に請求ページへ移動します。'
              : '秒後に自動的にアカウント認証ページへ移動します。'}
          </SuccessTextHelper>
        </SuccessModalWrapper>
      </StyledModalWrapper>
      <StyledForm>
        {!edit && currentUser?.user.customer.last_card_number != null ? (
          <SavedInfoWrapper>
            <div>
              <StyledRadioLabel
                control={
                  <RadioButton
                    value="no"
                    checked={cardUse === 'no'}
                    onChange={event => {
                      const value = (event.target as HTMLInputElement).value;
                      setCardUse(value as 'no' | 'yes');
                    }}
                  />
                }
                label="既存カード"
              />
            </div>

            <div>
              <SavedCardTypeLabel>{getBrandLabel('visa')}</SavedCardTypeLabel>
              <SavedCardInfoWrapper>
                <SavedCardNumber>
                  <span>＊＊＊＊＊＊＊＊＊</span>
                  {currentUser?.user.customer.last_card_number}
                </SavedCardNumber>
                {renderCardIcon('visa')}
              </SavedCardInfoWrapper>
            </div>

            <div>
              <StyledRadioLabel
                control={
                  <RadioButton
                    value="yes"
                    checked={cardUse === 'yes'}
                    onChange={event => {
                      const value = (event.target as HTMLInputElement).value;
                      setCardUse(value as 'no' | 'yes');
                    }}
                  />
                }
                label="新規カード"
              />
            </div>
          </SavedInfoWrapper>
        ) : (
          ''
        )}
        {apiError && (
          <ApiErrorWrapper>
            <InlineErrorToast
              message={apiError}
              onClose={() => setApiError('')}
            />
          </ApiErrorWrapper>
        )}
        <CardWrapper>
          <CardLabel>カード番号</CardLabel>
          <StyledCardNumberElement
            options={{
              ...cardElementOption,
              showIcon: true,
              iconStyle: 'solid',
            }}
          />
        </CardWrapper>
        <CardExpiryWrapper>
          <CardWrapper>
            <CardLabel>有効期限</CardLabel>
            <StyledCardExpiryElement
              options={{
                ...cardElementOption,
                placeholder: '月/年',
              }}
            />
          </CardWrapper>

          <CardWrapper>
            <CardLabel>CVC</CardLabel>
            <StyledCardCVCElement options={cardElementOption} />
          </CardWrapper>
        </CardExpiryWrapper>
        <CardWrapper>
          <CardLabel>カード所有者氏名</CardLabel>
          <InputWrapper>
            <StyledTextInput
              value={additionalInfo.name}
              disabled={cardUse === 'no'}
              onChangeText={(value: string) =>
                setAdditionalInfo({ ...additionalInfo, name: value })
              }
              placeholder="カード所有者氏名"
            />
          </InputWrapper>
        </CardWrapper>
        <CardWrapper>
          <CardLabel>国または地域</CardLabel>
          <InputWrapper>
            <StyledSelect
              disabled={cardUse === 'no'}
              name="country"
              value={additionalInfo.address.country}
              onChange={ev => {
                const value = ev.target.value as string;
                setAdditionalInfo({
                  ...additionalInfo,
                  address: { country: value },
                });
              }}>
              {/* <StyledMenuItem value="JP" disabled>
                日本
              </StyledMenuItem> */}
              {stripeCountries.map(country => {
                return (
                  <StyledMenuItem key={country.code} value={country.code}>
                    {country.name}
                  </StyledMenuItem>
                );
              })}
            </StyledSelect>
          </InputWrapper>
        </CardWrapper>
        <CardWrapper>
          <StyledButton
            title={cardUse === 'yes' ? '決済' : '次へ'}
            bgColor={themeColor}
            isLoading={isProcessingPayment}
            onPress={handleSubmit}
            disabled={!stripe || !elements || isProcessingPayment}
          />
        </CardWrapper>
      </StyledForm>
    </Container>
  );
};

export default Component;
