import React, { SVGProps } from 'react';
import styled from 'styled-components';

export const EditorContainer = styled.div`
  cursor: auto;
  position: relative;
  .shape-wrapper {
    position: absolute;
    top: 0;
  }
  .overflow-wrapper {
    position: absolute;
    top: 0;
    padding: 15px;
    box-sizing: border-box;
  }
`;

export const Shape = (props: SVGProps<SVGSVGElement>): React.ReactElement => {
  return (
    <svg
      width="30"
      height="22"
      viewBox="0 0 30 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M1 1H19.3448L29 11L19.3448 21H1V11V1Z"
        fill="inherit"
        stroke="inherit"
        strokeMiterlimit="10"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};

export const ShapeOutside = (): React.ReactElement => {
  return (
    <>
      <div
        style={{
          shapeOutside:
            'polygon(0 0, 100% 0, 100% 10%, 10% 10%, 10% 90%, 100% 90%,  100% 100%, 0 100%)',
          width: '65%',
          height: '100%',
          float: 'left',
          pointerEvents: 'none',
        }}
      />
      <div
        style={{
          shapeOutside:
            'polygon(0 0, 0 10%, 100% 50%, 0 90%, 0 100%, 100% 100%, 100% 0)',
          width: '35%',
          height: '100%',
          float: 'right',
          pointerEvents: 'none',
        }}
      />
    </>
  );
};
