import React from 'react';

type Props = {
  [key: string]: string | number | Function;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M9.5 0C8.20455 0 7.19247 0.473214 6.46378 1.41964C5.73509 2.36607 5.37074 3.57589 5.37074 5.04911C5.36174 6.87946 5.95549 8.33036 7.15199 9.40179C7.30492 9.54464 7.3589 9.72768 7.31392 9.95089L7.00355 10.5938C6.90459 10.808 6.7584 10.9754 6.56499 11.096C6.37157 11.2165 5.96449 11.3884 5.34375 11.6116C5.31676 11.6205 4.74775 11.8036 3.63672 12.1607C2.52569 12.5179 1.93419 12.7143 1.86222 12.75C1.10653 13.0625 0.611742 13.3884 0.377841 13.7277C0.125947 14.2902 0 15.7143 0 18H19C19 15.7143 18.8741 14.2902 18.6222 13.7277C18.3883 13.3884 17.8935 13.0625 17.1378 12.75C17.0658 12.7143 16.4743 12.5179 15.3633 12.1607C14.2522 11.8036 13.6832 11.6205 13.6562 11.6116C13.0355 11.3884 12.6284 11.2165 12.435 11.096C12.2416 10.9754 12.0954 10.808 11.9964 10.5938L11.6861 9.95089C11.6411 9.72768 11.6951 9.54464 11.848 9.40179C13.0445 8.33036 13.6383 6.87946 13.6293 5.04911C13.6293 3.57589 13.2649 2.36607 12.5362 1.41964C11.8075 0.473214 10.7955 0 9.5 0Z"
        fill="#737373"
      />
    </svg>
  );
};

export default Component;
