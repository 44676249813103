import React, { useCallback, useRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import { useBoardHooks } from '../../../hooks/board';
import { ModalWrapper } from '../../molecules/ModalWrapper';
import { ShareForm } from '../../organisms/ShareForm';
import { Props as INotification } from '../../atoms/Toast/Component';
import { NoTiStackToast, Toast } from '../../atoms/Toast';
import styled from 'styled-components';
import { BOARD_CODE_IF_NEW } from '../../pages/BoardEditor/Component';
import { MemberDeleteModal } from '../../organisms/MemberDeleteModal';
import { cleanSpacesSepratedComma } from '../../../utils/strings';
import queryKeys from '../../../constants/queryKeys';

const Container = styled.div`
  width: 797px;
  display: flex;
  justify-content: center;
  padding-bottom: 65px;
`;

export type Props = {
  isOpen: boolean;
  boardName?: string;
  boardCode?: string;
  onClose: () => void;
};

const Component = ({
  boardName,
  boardCode,
  isOpen,
  onClose,
}: Props): React.ReactElement => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [keyword, setKeyword] = useState<string>('');
  const [notification, setNotification] = useState<INotification | null>(null);
  const handleCloseToast = () => {
    setNotification(null);
  };
  const {
    useBoardAPI,
    useBoardSocket,
    activeCustomerIdsState,
    cursorState,
    customersNodeIdsState,
  } = useBoardHooks();
  const { inviteCustomer, fetchBoardCustomers, deleteCustomer } = useBoardAPI();
  const { setActiveCustomerIds } = activeCustomerIdsState;
  const { setCustomersSelectedNodeIds } = customersNodeIdsState;
  const { removeActiveUser } = useBoardSocket();
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [customerID, setCustomerID] = useState<string>();
  const toastRef = useRef<any>();

  const {
    data: participants = [],
    isFetching: isFetchingParticipants,
  } = useQuery([queryKeys.FETCH_BOARD_CUSTOMERS, boardCode], () => {
    if (!boardCode || boardCode === BOARD_CODE_IF_NEW) {
      throw new Error('invalid value for board code');
    }
    return fetchBoardCustomers(boardCode);
  });

  const { mutate: submitEmails, isLoading: isSubmittingEmails } = useMutation(
    async (emails: string[]) => {
      if (!boardCode) {
        throw new Error('invalid field for board id');
      }
      return inviteCustomer(boardCode, emails);
    },
    {
      onSuccess: response => {
        response.data.forEach(data => {
          if (toastRef.current) {
            toastRef.current?.toggleSnackBar(
              `${data.email},  ${data.message}`,
              data.success ? 'success' : 'error',
              'top-right',
            );
          }
        });

        onClose();
        setKeyword('');
      },
      onError: (response: { response: AxiosResponse }) => {
        if (!response.response) {
          setNotification({
            isOpen: true,
            message: String(response)
              .replace('Error:', '')
              .trim(),
            type: 'error',
            position: 'top-right',
            onClose: handleCloseToast,
          });
        } else {
          const data = response.response?.data as any;
          if (response.response.status === 422) {
            const error = data.errors;
            let msg = '';
            Object.keys(error).forEach(key => {
              msg += error[key];
            });
            setNotification({
              isOpen: true,
              message: String(msg),
              type: 'error',
              position: 'top-right',
              onClose: handleCloseToast,
            });
          } else if (response.response.status === 500) {
            setNotification({
              isOpen: true,
              message: String(data.error),
              type: 'error',
              position: 'top-right',
              onClose: handleCloseToast,
            });
          } else {
            setNotification({
              isOpen: true,
              message: String(response)
                .replace('Error:', '')
                .trim(),
              type: 'error',
              position: 'top-right',
              onClose: handleCloseToast,
            });
          }
        }
      },
    },
  );

  const {
    mutate: submitDeleteCustomer,
    isLoading: isDeletingCustomer,
  } = useMutation(
    async (customerId: string) => {
      if (!boardCode) {
        throw new Error('invalid field for board id');
      }
      return deleteCustomer(boardCode, customerId);
    },
    {
      onSuccess: response => {
        queryClient.invalidateQueries([
          queryKeys.FETCH_BOARD_CUSTOMERS,
          boardCode,
        ]);
        setIsLoading(false);
        setNotification({
          isOpen: true,
          message: response.message || '',
          type: 'success',
          position: 'top-right',
          onClose: handleCloseToast,
        });
        setIsOpenModal(false);

        if (boardCode && customerID) {
          removeActiveUser(boardCode, Number(customerID));
          setActiveCustomerIds(prev =>
            prev.filter(item => item !== Number(customerID)),
          );
          setCustomersSelectedNodeIds(prev => {
            return {
              ...prev,
              [customerID]: [],
            };
          });
          if (cursorState) {
            cursorState.setCursors(prev => {
              const { [customerID + boardCode]: _, ...newCursors } = prev;
              return newCursors;
            });
          }
        }
      },
      onError: (response: { response: AxiosResponse }) => {
        const data = response.response?.data as any;
        if (response.response.status === 422) {
          setNotification({
            isOpen: true,
            message: data.errors.message,
            type: 'error',
            position: 'top-right',
            onClose: handleCloseToast,
          });
          setIsLoading(false);
          setIsOpenModal(false);
        } else {
          setNotification({
            isOpen: true,
            message: String(data),
            type: 'error',
            position: 'top-right',
            onClose: handleCloseToast,
          });
          setIsLoading(false);
          setIsOpenModal(false);
        }
        onClose();
      },
    },
  );

  const handleClickSubmit = useCallback(() => {
    const emails = cleanSpacesSepratedComma(keyword).split(',');
    submitEmails(emails);
  }, [keyword]);

  const handleDeleteMember = (id: string) => {
    setCustomerID(id);
    setIsOpenModal(true);
  };

  return (
    <>
      <ModalWrapper
        isOpen={isOpen}
        onClose={() => {
          setKeyword('');
          onClose();
        }}
        title={boardName}>
        <Container>
          <ShareForm
            onClickDelete={e => {
              handleDeleteMember(e);
            }}
            keyword={keyword}
            onChangeKeyWord={setKeyword}
            onClickShare={handleClickSubmit}
            participants={participants}
            disabled={
              isFetchingParticipants || isSubmittingEmails || isDeletingCustomer
            }
            isSubmittingEmails={isSubmittingEmails}
          />
        </Container>
      </ModalWrapper>
      {notification ? <Toast {...notification} /> : null}
      <NoTiStackToast ref={toastRef} />

      <MemberDeleteModal
        confirmationText={t('deleteThisAccountInBoard')}
        isOpen={isOpenModal}
        onClose={() => {
          setIsOpenModal(!isOpen);
        }}
        onDelete={() => {
          if (customerID) {
            submitDeleteCustomer(customerID);
            setIsLoading(true);
          }
        }}
        isLoading={isLoading}
      />
    </>
  );
};

export default Component;
