import React from 'react';
import styled from 'styled-components';
import theme from '../../../constants/themes';
import { Heart, ActiveHeart } from '../Icons';

const HeartWrapper = styled.div`
  width: 22.5px;
  height: 22.5px;
  border: thin solid ${theme.colors.heartBorder};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${theme.colors.white};
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: scale(1.25);
    background-repeat: no-repeat;
    background-position: center;
  }

  &:active {
    transform: scale(1);
  }
`;

export type Props = {
  className?: string;
  isActive: boolean;
  onPress: () => void;
};

const Component = ({
  className,
  isActive = false,
  onPress,
}: Props): React.ReactElement => {
  return isActive ? (
    <HeartWrapper
      className={className}
      onClick={event => {
        event.stopPropagation();
        onPress();
      }}>
      <ActiveHeart />
    </HeartWrapper>
  ) : (
    <HeartWrapper
      className={className}
      onClick={event => {
        event.stopPropagation();
        onPress();
      }}>
      <Heart />
    </HeartWrapper>
  );
};

export default Component;
