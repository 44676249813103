import { useCallback } from 'react';
import { LocalStorageHooks } from '.';
import { useDependencies } from '..';

export const useLocalStorage: LocalStorageHooks['useLocalStorage'] = (): {
  clearSessionStorage: () => void;
  getItem: (key: string) => string | null;
} => {
  const { localStorageInteractor } = useDependencies();
  const { clearSession } = localStorageInteractor;

  const clearSessionStorage = useCallback(() => {
    clearSession();
  }, []);

  const getItem = useCallback(key => {
    return localStorageInteractor.getItem(key);
  }, []);

  return { clearSessionStorage, getItem };
};
