import React, { useCallback } from 'react';
import styled from 'styled-components';
import theme from '../../../constants/themes';

const Wrapper = styled.div`
  background-color: ${theme.colors.white};
  display: inline-flex;
  flex-direction: column;

  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.25);
  padding: 6px 13px 13px 13px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  div {
    margin-right: 7px;
    :last-child {
      margin-right: 0px;
    }
  }
  :last-child {
    margin-bottom: 0px;
  }
`;

const Sticky = styled.div`
  width: 28px;
  height: 28px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: ${({ $color }: { $color: string }) => $color};
`;

export type Props = {
  className?: string;
  onClickSticky: (color: string) => void;
};

const Component = ({ className, onClickSticky }: Props): React.ReactElement => {
  const onDragStart = useCallback(
    (event: React.DragEvent<HTMLDivElement>, color: string) => {
      event.dataTransfer.setData('node-type', 'sticky');
      event.dataTransfer.setData('node-data', color);
      event.dataTransfer.effectAllowed = 'move';
    },
    [],
  );
  return (
    <Wrapper className={className}>
      <Row>
        <Sticky
          draggable
          onDragStart={ev => onDragStart(ev, '#FFF1AA')}
          onClick={() => onClickSticky('#FFF1AA')}
          $color="#FFF1AA"
        />
        <Sticky
          draggable
          onDragStart={ev => onDragStart(ev, '#FBE88A')}
          onClick={() => onClickSticky('#FBE88A')}
          $color="#FBE88A"
        />
        <Sticky
          draggable
          onDragStart={ev => onDragStart(ev, '#A1E67F')}
          onClick={() => onClickSticky('#A1E67F')}
          $color="#A1E67F"
        />
        <Sticky
          draggable
          onDragStart={ev => onDragStart(ev, '#7FAEE6')}
          onClick={() => onClickSticky('#7FAEE6')}
          $color="#7FAEE6"
        />
      </Row>
      <Row>
        <Sticky
          draggable
          onDragStart={ev => onDragStart(ev, '#FFCEAA')}
          onClick={() => onClickSticky('#FFCEAA')}
          $color="#FFCEAA"
        />
        <Sticky
          draggable
          onDragStart={ev => onDragStart(ev, '#FBB98A')}
          onClick={() => onClickSticky('#FBB98A')}
          $color="#FBB98A"
        />
        <Sticky
          draggable
          onDragStart={ev => onDragStart(ev, '#E6C37F')}
          onClick={() => onClickSticky('#E6C37F')}
          $color="#E6C37F"
        />
        <Sticky
          draggable
          onDragStart={ev => onDragStart(ev, '#E6857F')}
          onClick={() => onClickSticky('#E6857F')}
          $color="#E6857F"
        />
      </Row>
      <Row>
        <Sticky
          draggable
          onDragStart={ev => onDragStart(ev, '#B1AAFF')}
          onClick={() => onClickSticky('#B1AAFF')}
          $color="#B1AAFF"
        />
        <Sticky
          draggable
          onDragStart={ev => onDragStart(ev, '#AE8AFB')}
          onClick={() => onClickSticky('#AE8AFB')}
          $color="#AE8AFB"
        />
        <Sticky
          draggable
          onDragStart={ev => onDragStart(ev, '#E67FB0')}
          onClick={() => onClickSticky('#E67FB0')}
          $color="#E67FB0"
        />
        <Sticky
          draggable
          onDragStart={ev => onDragStart(ev, '#F36060')}
          onClick={() => onClickSticky('#F36060')}
          $color="#F36060"
        />
      </Row>
      <Row>
        <Sticky
          draggable
          onDragStart={ev => onDragStart(ev, '#ABFCDF')}
          onClick={() => onClickSticky('#ABFCDF')}
          $color="#ABFCDF"
        />
        <Sticky
          draggable
          onDragStart={ev => onDragStart(ev, '#8AEDFB')}
          onClick={() => onClickSticky('#8AEDFB')}
          $color="#8AEDFB"
        />
        <Sticky
          draggable
          onDragStart={ev => onDragStart(ev, '#7FCDE6')}
          onClick={() => onClickSticky('#7FCDE6')}
          $color="#7FCDE6"
        />
        <Sticky
          draggable
          onDragStart={ev => onDragStart(ev, '#2F8EAC')}
          onClick={() => onClickSticky('#2F8EAC')}
          $color="#2F8EAC"
        />
      </Row>
    </Wrapper>
  );
};

export default Component;
