import React, { SVGProps } from 'react';
import styled from 'styled-components';

export const EditorContainer = styled.div`
  cursor: auto;
  position: relative;
  .shape-wrapper {
    position: absolute;
    top: 0;
  }
  .overflow-wrapper {
    position: absolute;
    top: 0;
    padding: 15px;
    box-sizing: border-box;
  }
`;

export const Shape = (props: SVGProps<SVGSVGElement>): React.ReactElement => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M13 2L10.1786 10.1167L1.58728 10.2918L8.43489 15.4833L5.94654 23.7082L13 18.8L20.0534 23.7082L17.565 15.4833L24.4126 10.2918L15.8213 10.1167L13 2Z"
        fill="inherit"
        stroke="inherit"
        strokeWidth="inherit"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};

export const ShapeOutside = (): React.ReactElement => {
  return (
    <>
      <div
        style={{
          shapeOutside:
            'polygon(0 0, 100% 0, 100% 10%, 80% 37%, 5% 37%, 65% 60%, 10% 90%, 100% 75%, 100% 100%, 0 100%)',
          width: '50%',
          height: '100%',
          float: 'left',
          pointerEvents: 'none',
        }}
      />
      <div
        style={{
          shapeOutside:
            'polygon(0 0, 0 10%, 20% 37%, 95% 37%, 35% 60%, 90% 90%, 0 75%, 0 100%, 100% 100%, 100% 0)',
          width: '50%',
          height: '100%',
          float: 'right',
          pointerEvents: 'none',
        }}
      />
    </>
  );
};
