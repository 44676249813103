import { createContext, useContext } from 'react';
import { Messages } from '../../domain/entities/chat';

export type ChatMessages = {
  id: number;
  boardCode: string;
  message: string;
  displayPhoto: string;
  displayTime: string;
  name: string;
  role: string;
  mentionId: string;
  isMentioned: boolean;
  isRead: boolean;
  scrollToUnreadMention?: boolean;
}[];

export type ChatHooks = {
  useChat: (
    boardCode: string,
  ) => {
    scrollToMention: boolean;
    setScrollToMention: (value: boolean) => void;
    mentionCount: number;
    setMentionCount: (value: number) => void;
    isChatBoxOpen: boolean;
    setChatBoxOpen: (value: boolean) => void;
    messages: ChatMessages;
    setMessages: (messages: ChatMessages) => void;
    sendMessage: (message: {
      customerId: number;
      avatar: string;
      boardCode: string;
      message: string;
      username: string;
      role: string;
      token: string;
      mentionId: string;
    }) => void;
    fetchNewMessages: (boardCode: string) => Promise<Messages | undefined>;
  };
};

export const ChatHooksContext = createContext<ChatHooks | null>(null);

export const useChatHooks = (): ChatHooks => {
  const context = useContext(ChatHooksContext);
  if (!context) {
    throw new Error(`Context not instantiated`);
  }
  return context;
};
