import React, { SVGProps } from 'react';

type Props = SVGProps<SVGSVGElement>;

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 476.491 476.491"
      width="20"
      height="20"
      {...props}>
      <polygon points="476.491,238.246 339.952,101.706 339.952,223.246 0,223.246 0,253.246 339.952,253.246 339.952,374.786 " />
    </svg>
  );
};

export default Component;
