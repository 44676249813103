import React from 'react';
import styled, { css } from 'styled-components';
import Box from '@mui/material/Box';
import theme from '../../../constants/themes';
import { FavBtn } from '../../atoms/Favorite';
import { Tooltip } from '../../atoms/Tooltip';
import DiagramPreview from '../../organisms/DiagramPreview/DiagramFetchHOC';
import { handleTextOverflow } from '../../../utils/strings';

const Card = styled(Box)<{ $isRoleDetail?: boolean }>`
  height: ${props => `${props.$isRoleDetail ? '105px' : '195px'}`};
  width: ${props => `${props.$isRoleDetail ? '150px' : '250px'}`};
  background: ${theme.colors.white};
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  position: relative;

  ${props =>
    !props.$isRoleDetail &&
    css`
      cursor: pointer;
      &:hover {
        box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.2);

        #plus-wrapper {
          background-color: ${theme.colors.hoverSecondaryBtn};
        }
      }
    `}

  @media ${theme.breakpoints.mobile} {
    width: 100%;
  }
`;

const CardFooter = styled.div<{ $isRoleDetail?: boolean }>`
  width: 100%;
  height: ${props => `${props.$isRoleDetail ? '18px' : '45px'}`};
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-top-color: ${props =>
    props.$isRoleDetail
      ? theme.colors.paleYellow
      : theme.colors.dropdownBorder};
  border-top-width: 1px;
  border-top-style: solid;
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const BoardTitle = styled.span<{ $isRoleDetail?: boolean }>`
  font-family: ${theme.fonts.primary};
  font-weight: 400;
  color: ${theme.colors.black};
  margin-left: 8px;
  font-size: ${props => (props.$isRoleDetail ? '9px' : '13px')};
  line-height: ${props => (props.$isRoleDetail ? '13px' : '19px')};
`;

const BottomTextWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 3px;
`;

const CreatedByText = styled.span`
  font-family: ${theme.fonts.primary};
  font-weight: 400;
  font-size: 10px;
  color: ${theme.colors.textColor};
  line-height: 14px;
  margin-left: 8px;
`;

const CreatedAtText = styled.span`
  font-family: ${theme.fonts.primary};
  font-weight: 400;
  font-size: 10px;
  color: ${theme.colors.textColor};
  line-height: 14px;
  margin-right: 8px;
`;

const UsersWrapper = styled.div<{ right: number; $isRoleDetail?: boolean }>`
  position: relative;
  height: 19px;
  margin-right: 10px;
  right: ${props => `${props.right + 8}px`};

  ${props =>
    props.$isRoleDetail &&
    css`
      margin-top: 6px;
    `}
`;

const UserImage = styled.img<{
  zIndex: number;
  left: number;
  $isRoleDetail?: boolean;
}>`
  width: ${props => `${props.$isRoleDetail ? '12px' : '19px'}`};
  height: ${props => `${props.$isRoleDetail ? '12px' : '19px'}`};
  border-radius: ${props => (props.$isRoleDetail ? '0' : '50%')};
  object-fit: cover;
  position: absolute;
  z-index: ${props => props.zIndex};
  top: 0;
  left: ${props => `${props.left}px`};
`;

const FavoriteWrapper = styled.div`
  position: absolute;
  top: 7px;
  left: 7px;
  z-index: 99;
`;

export type Props = {
  boardImg: string;
  boardName: string;
  createdBy: string;
  createdAt: string;
  users: string[];
  className?: string;
  isFavorite: boolean;
  boardId: number;
  onPressBoardCard: (boardId: number) => void;
  onPressFavorite: (boardId: number, isFavorite: boolean) => void;
  isRoleDetail?: boolean;
};

const Component = ({
  boardImg,
  boardName,
  createdBy,
  createdAt,
  users,
  boardId,
  isFavorite,
  onPressBoardCard,
  onPressFavorite,
  isRoleDetail = false,
  className,
}: Props): React.ReactElement => {
  const renderUsers = () => {
    return users.map((userImg, index) => {
      return (
        <UserImage
          $isRoleDetail={isRoleDetail}
          key={index}
          src={userImg}
          zIndex={index}
          left={index * (isRoleDetail ? 6 : 10)}
        />
      );
    });
  };

  return (
    <Card
      className={className}
      $isRoleDetail={isRoleDetail}
      onClick={() => onPressBoardCard(boardId)}>
      <DiagramPreview
        diagramUrl={boardImg}
        style={
          isRoleDetail
            ? { width: 150, height: 86 }
            : { width: 250, height: 150, margin: 'auto' }
        }
      />
      <CardFooter $isRoleDetail={isRoleDetail}>
        <TitleWrapper>
          {boardName.length <= 15 ? (
            <BoardTitle $isRoleDetail={isRoleDetail}>{boardName}</BoardTitle>
          ) : (
            <Tooltip label={boardName}>
              <BoardTitle $isRoleDetail={isRoleDetail}>
                {handleTextOverflow(boardName, 15)}
              </BoardTitle>
            </Tooltip>
          )}
          <UsersWrapper $isRoleDetail={isRoleDetail} right={users.length * 9.5}>
            {renderUsers()}
          </UsersWrapper>
        </TitleWrapper>
        {!isRoleDetail && (
          <BottomTextWrapper>
            {createdBy?.length <= 15 ? (
              <CreatedByText>{createdBy}</CreatedByText>
            ) : (
              <Tooltip label={createdBy}>
                <CreatedByText>
                  {handleTextOverflow(createdBy, 15)}
                </CreatedByText>
              </Tooltip>
            )}
            <CreatedAtText>{createdAt}</CreatedAtText>
          </BottomTextWrapper>
        )}
      </CardFooter>
      {!isRoleDetail && (
        <FavoriteWrapper>
          <FavBtn
            isActive={isFavorite}
            onPress={() => onPressFavorite(boardId, isFavorite)}
          />
        </FavoriteWrapper>
      )}
    </Card>
  );
};

export default Component;
