import PaymentRepository from '../../ports/PaymentRepository';

export type fetchInvoiceBlobUseCase = (invoiceCode: string) => Promise<void>;

export const buildFetchInvoiceBlob = (dependencies: {
  paymentRepo: PaymentRepository;
}): fetchInvoiceBlobUseCase => {
  const { paymentRepo } = dependencies;

  const fetchInvoiceBlob: fetchInvoiceBlobUseCase = async invoiceCode =>
    paymentRepo.fetchInvoicePdf(invoiceCode);

  return fetchInvoiceBlob;
};
