import React from 'react';

type Props = {
  [key: string]: string | number | Function;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="120"
      height="76"
      viewBox="0 0 120 76"
      {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#227FBB"
          d="M0,8.00758637 C0,3.58906554 3.58236644,0 8.0014394,0 L111.998561,0 C116.408638,0 120,3.58511853 120,8.00758637 L120,15 L0,15 L0,8.00758637 Z"
        />
        <path
          fill="#E87E04"
          d="M0,67.9924136 C0,72.4148815 3.59136164,76 8.0014394,76 L111.998561,76 C116.417634,76 120,72.4109345 120,67.9924136 L120,61 L0,61 L0,67.9924136 Z"
        />
        <rect width="120" height="46" y="15" fill="#F4F4F4" />
        <path
          fill="#227FBB"
          d="M83.7346974 22.3365813C82.0523103 21.6918621 79.4163797 21 76.1243308 21 67.7340381 21 61.8238818 25.316335 61.7734356 31.5021281 61.7261722 36.0748227 65.9927707 38.6255764 69.2136857 40.1479655 72.5189429 41.7077438 73.6300313 42.7026207 73.6141176 44.0954483 73.5932708 46.2285813 70.974686 47.2031379 68.5341745 47.2031379 65.1355044 47.2031379 63.329946 46.7208177 60.5412447 45.5326552L59.4470247 45.0269261 58.2552543 52.1508621C60.2384086 53.0390985 63.9060188 53.8088276 67.7139869 53.8486552 76.6397727 53.8486552 82.434237 49.5819015 82.5001194 42.9762118 82.5321057 39.3559655 80.2696675 36.6010345 75.3709804 34.3298867 72.4029327 32.8577291 70.5852799 31.8753695 70.6045354 30.3846798 70.6045354 29.0619163 72.1430634 27.647468 75.4672577 27.647468 78.2440239 27.6034138 80.2553453 28.2219606 81.8226769 28.8666798L82.5835067 29.2339064 83.7346974 22.3365813M105.508815 21.5834335L98.9474737 21.5834335C96.9149872 21.5834335 95.3938051 22.1501232 94.5012106 24.2224581L81.8903097 53.3864926 90.8068656 53.3864926C90.8068656 53.3864926 92.2647116 49.4651823 92.5944416 48.6042562 93.5688321 48.6042562 102.230929 48.6179113 103.469167 48.6179113 103.723308 49.7319458 104.502279 53.3864926 104.502279 53.3864926L112.381427 53.3864926 105.508815 21.5834335 105.508815 21.5834335zM95.0381358 42.0951281C95.7404035 40.2615961 98.4212106 33.1991084 98.4212106 33.1991084 98.3710827 33.283803 99.1182268 31.3566355 99.5470986 30.1618079L100.120943 32.9055222C100.120943 32.9055222 101.746837 40.5018621 102.086752 42.094803L95.0381358 42.0951281 95.0381358 42.0951281 95.0381358 42.0951281 95.0381358 42.0951281z"
        />
        <polygon
          fill="#227FBB"
          points="44.45 53.376 49.759 21.553 58.25 21.553 52.938 53.376"
        />
        <path
          fill="#227FBB"
          d="M37.3282182,21.5762808 L29.0149474,43.2774384 L28.1291958,38.8673054 C26.581597,33.7835172 21.7597727,28.2754433 16.3691958,25.5179113 L23.9706508,53.3489409 L32.9546803,53.3385369 L46.3227508,21.5762808 L37.3282182,21.5762808"
        />
        <path
          fill="#E87E04"
          d="M21.3027337,21.5566108 L7.61050298,21.5566108 L7.50213129,22.2187241 C18.1545439,24.8528719 25.20316,31.2186207 28.1290367,38.8673054 L25.151759,24.243266 C24.6377494,22.2281527 23.1469622,21.6268374 21.3027337,21.5566108"
        />
      </g>
    </svg>
  );
};

export default Component;
