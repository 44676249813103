type Position = { x: number; y: number };
type Coordinates = { x: number; y: number }[];

export const getSlope = (coor1: Position, coor2: Position): number =>
  (coor2.y - coor1.y) / (coor2.x - coor1.x);

export const getDistance = (pointA: Position, pointB: Position): number => {
  return Math.sqrt(
    Math.pow(pointA.x - pointB.x, 2) + Math.pow(pointA.y - pointB.y, 2),
  );
};

export const getAcuteAngle = (
  pointA: Position,
  pointB: Position,
  centerPoint: Position,
): number => {
  const distanceA = getDistance(pointA, centerPoint);
  const distanceB = getDistance(pointB, centerPoint);
  const distanceOpposite = getDistance(pointA, pointB);
  return (
    (Math.acos(
      (Math.pow(distanceA, 2) +
        Math.pow(distanceB, 2) -
        Math.pow(distanceOpposite, 2)) /
        (2 * distanceA * distanceB),
    ) *
      180) /
    Math.PI
  );
};

export const areCollinear = (
  pointA: Position,
  center: Position,
  pointB: Position,
  variance = 0,
): boolean => {
  const acuteAngle = Math.ceil(getAcuteAngle(pointA, pointB, center));
  return acuteAngle >= 180 - variance;
};

export const convertDegree = ({
  x,
  y,
  angle,
  midPointX,
  midPointY,
}: {
  x: number;
  y: number;
  angle: number;
  midPointX: number;
  midPointY: number;
}): { x: number; y: number } => {
  const angleRad = (angle * Math.PI) / 180;
  return {
    x:
      (x - midPointX) * Math.cos(angleRad) -
      (y - midPointY) * Math.sin(angleRad) +
      midPointX,
    y:
      (x - midPointX) * Math.sin(angleRad) +
      (y - midPointY) * Math.cos(angleRad) +
      midPointY,
  };
};

export const getBoundingBoxHeightWidth = (
  coordinates: Coordinates,
): { h: number; w: number } => {
  const yCoords = coordinates.map(c => c.y);
  const xCoords = coordinates.map(c => c.x);
  const topY = Math.max(...yCoords);
  const bottomY = Math.min(...yCoords);
  const rightX = Math.max(...xCoords);
  const leftX = Math.min(...xCoords);

  const h = topY - bottomY;
  const w = rightX - leftX;
  return { h, w };
};
