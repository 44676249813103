import PageRepository from '../../ports/PageRepository';
import { buildFetchPage, fetchPageUseCase } from './fetch-page';
import {
  buildUpdatePageSection,
  updatePageSectionUseCase,
} from './update-page-section';
import { buildUploadMedia, uploadMediaUseCase } from './upload-media';
import { buildVerifyAdmin, verifyAdminUseCase } from './verify-admin';

export default (dependencies: {
  pageRepo: PageRepository;
}): {
  fetchPage: fetchPageUseCase;
  verifyAdmin: verifyAdminUseCase;
  uploadMedia: uploadMediaUseCase;
  updatePageSection: updatePageSectionUseCase;
} => {
  const { pageRepo } = dependencies;
  const fetchPage = buildFetchPage({ pageRepo });
  const verifyAdmin = buildVerifyAdmin({ pageRepo });
  const uploadMedia = buildUploadMedia({ pageRepo });
  const updatePageSection = buildUpdatePageSection({ pageRepo });

  return { fetchPage, verifyAdmin, uploadMedia, updatePageSection };
};
