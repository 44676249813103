import React, { CSSProperties, useState } from 'react';
import styled from 'styled-components';
import theme from '../../../constants/themes';
import {
  AddEmoji,
  Bold,
  BulletList,
  Font,
  Italicize,
  LineThrough,
  Link,
  BentArrow,
  StepArrow,
  StraightArrow,
  Underline,
  KebabMenu,
  Flip,
  Line,
  Filled,
  Hollow,
  Arrow,
} from '../../atoms/Icons';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { ColorPicker } from '../ColorPicker';
import { Paper, MenuList } from '@mui/material';
import { BorderPicker } from '../BorderPicker';

const Wrapper = styled.div`
  background: ${theme.colors.notifToastBorder};
  min-height: 50px;
  z-index: 10;
  display: inline-flex;
  background: #f9f8f8;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.25);
`;

const Group = styled.div`
  border-right: 1px solid rgba(220, 220, 220, 0.38);
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  svg {
    margin: 0 3px;
    cursor: pointer;
    :last-child {
      padding-right: 10px;
    }
  }
`;

const ColorPickerWrapper = styled.div`
  position: relative;
  display: inline-flex;
`;

const ColorPickerPreview = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: ${({ $color }: { $color?: string }) => $color};
  cursor: pointer;
  margin-right: 10px;
`;

const StrokeColorPickerPreview = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: ${theme.colors.white};
  border: solid ${({ $color }: { $color?: string }) => $color} 5px;
  cursor: pointer;
  margin-right: 10px;
`;

const StyledColorPicker = styled(ColorPicker)`
  position: absolute;
  top: 30px;
  left: -5px;
  z-index: 1;
`;

const StyledFontColorPicker = styled(ColorPicker)`
  position: absolute;
  top: 30px;
  left: -100px;
  z-index: 1;
`;

const StyledSelect = styled(Select)`
  height: 24px;
  && {
    font-size: 13px;
    font-family: ${theme.fonts.primary};
    .MuiSelect-select {
      padding: 0;
    }
  }
  fieldset {
    border: none;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  && {
    font-size: 13px;
  }
`;

const OtherOptionsWrapper = styled.div`
  position: relative;
  display: inline-flex;
  padding-right: 10px;
`;

const OtherOptions = styled.div`
  position: absolute;
  top: 0;
  left: 30px;
  && li {
    font-size: 13px;
  }
`;

const StyledKebabMenu = styled(KebabMenu)`
  width: 20px;
  height: 20px;
`;

const BorderValue = styled.div`
  width: 35px;
  font-size: 13px;
  padding-right: 10px;
`;

const StyledBorderPicker = styled(BorderPicker)`
  position: absolute;
  top: 50px;
  left: -25px;
`;

export type Props = {
  color?: string;
  strokeColor?: string;
  style?: CSSProperties;
  fontColor?: string;
  fontSize?: number | 'inherit';
  fontFamily?: string;
  textAlign?: 'left' | 'center' | 'right';
  dashType?: 'straight' | 'broken1' | 'broken2';
  strokeWidth?: number;
  borderWidth?: number;
  lineType?: 'step' | 'straight' | 'bezier';
  startNode?: 'none' | 'filled' | 'hollow' | 'arrow';
  endNode?: 'none' | 'filled' | 'hollow' | 'arrow';
  onChangeColor?: (color: string) => void;
  onChangeStrokeColor?: (color: string) => void;
  onChangeDashType?: (color: 'straight' | 'broken1' | 'broken2') => void;
  onChangeStrokeWidth?: (width: number) => void;
  onChangeLineType?: (type: 'step' | 'straight' | 'bezier') => void;
  onChangeStartNode?: (type: 'none' | 'filled' | 'hollow' | 'arrow') => void;
  onChangeEndNode?: (type: 'none' | 'filled' | 'hollow' | 'arrow') => void;
  onChangeFontSize?: (size: number | 'inherit') => void;
  onChangeFontFamily?: (family: string) => void;
  onChangeFontColor?: (color: string) => void;
  onClickBold?: React.MouseEventHandler<SVGSVGElement>;
  onClickItalicize?: React.MouseEventHandler<SVGSVGElement>;
  onClickUnderline?: React.MouseEventHandler<SVGSVGElement>;
  onClickLineThrough?: React.MouseEventHandler<SVGSVGElement>;
  onTextRealign?: (align: 'left' | 'center' | 'right') => void;
  onClickEmoji?: (open?: boolean) => void;
  onBulletList?: () => void;
  onClickLink?: (open?: boolean) => void;
  onClickCopy?: () => void;
  onClickPaste?: () => void;
  onClickDelete?: () => void;
  onChangeBorderWidth?: (width: number) => void;
  onClickFlip?: () => void;
  isBold?: boolean;
  isItalicize?: boolean;
  isUnderline?: boolean;
  isLineThrough?: boolean;
  isEmoji?: boolean;
  isLink?: boolean;
};

const BORDER_WIDTHS = [0, 0.4, 0.8, 1.2, 1.6, 2.0, 2.4, 2.8, 3.2, 3.6, 4.0];

const Component = ({
  style,
  color = 'black',
  strokeColor = 'black',
  fontColor = 'black',
  fontSize = 'inherit',
  fontFamily = 'コーポレートロゴ・M',
  textAlign = 'left',
  dashType = 'straight',
  strokeWidth = 1,
  borderWidth = 1,
  lineType = 'step',
  startNode = 'none',
  endNode = 'none',
  isBold = false,
  isItalicize = false,
  isUnderline = false,
  isLineThrough = false,
  isEmoji,
  isLink,
  onChangeColor,
  onChangeStrokeColor,
  onChangeDashType,
  onChangeBorderWidth,
  onChangeStrokeWidth,
  onChangeFontSize,
  onChangeLineType,
  onChangeStartNode,
  onChangeEndNode,
  onChangeFontFamily,
  onChangeFontColor,
  onClickBold,
  onClickItalicize,
  onClickUnderline,
  onClickLineThrough,
  onTextRealign,
  onClickEmoji,
  onClickLink,
  onBulletList,
  onClickCopy,
  onClickDelete,
  onClickPaste,
  onClickFlip,
}: Props): React.ReactElement => {
  const [isSelectingColor, setIsSelectingColor] = useState<boolean>(false);
  const [isSelectingStrokeColor, setIsSelectingStrokeColor] = useState<boolean>(
    false,
  );
  const [previousColor, setPrevColor] = useState<string>(color);
  const [previousStrokeColor, setPrevStrokeColor] = useState<string>(
    strokeColor,
  );
  const [previousFontColor, setPreviousFontColor] = useState<string>(fontColor);
  const [isSelectingFontColor, setIsSelectingFontColor] = useState<boolean>(
    false,
  );
  const [isSelectingBorderWidth, setIsSelectingBorderWidth] = useState<boolean>(
    false,
  );
  const [isOthersMenuOpen, setIsOthersMenuOpen] = useState<boolean>(false);
  const borderWidthIndex = BORDER_WIDTHS.findIndex(bw => bw === borderWidth);

  return (
    <Wrapper style={style}>
      {onChangeColor && (
        <Group>
          <ColorPickerWrapper className="nodrag">
            {isSelectingColor && (
              <StyledColorPicker
                onChangeComplete={col => {
                  onChangeColor(
                    `rgba(${col.rgb.r},${col.rgb.g},${col.rgb.b},${col.rgb.a})`,
                  );
                }}
                onClickCancel={() => {
                  onChangeColor(previousColor);
                  setIsSelectingColor(false);
                }}
                onClickDone={() => {
                  setIsSelectingColor(false);
                  setPrevColor(color);
                }}
                color={color}
              />
            )}
            <ColorPickerPreview
              $color={color}
              onClick={() => {
                setIsSelectingColor(!isSelectingColor);
                setIsSelectingFontColor(false);
                setIsOthersMenuOpen(false);
                setIsSelectingBorderWidth(false);
                setIsSelectingStrokeColor(false);
                if (isEmoji && onClickEmoji) {
                  onClickEmoji(false);
                }
                if (isLink && onClickLink) {
                  onClickLink(false);
                }
              }}
            />
          </ColorPickerWrapper>
        </Group>
      )}
      {onChangeStrokeColor && (
        <Group>
          <ColorPickerWrapper className="nodrag">
            {isSelectingStrokeColor && (
              <StyledColorPicker
                onChangeComplete={col => {
                  onChangeStrokeColor(
                    `rgba(${col.rgb.r},${col.rgb.g},${col.rgb.b},${col.rgb.a})`,
                  );
                }}
                onClickCancel={() => {
                  onChangeStrokeColor(previousStrokeColor);
                  setIsSelectingStrokeColor(false);
                }}
                onClickDone={() => {
                  setIsSelectingStrokeColor(false);
                  setPrevStrokeColor(strokeColor);
                }}
                color={strokeColor}
              />
            )}
            <StrokeColorPickerPreview
              $color={strokeColor}
              onClick={() => {
                setIsSelectingStrokeColor(!isSelectingStrokeColor);
                setIsSelectingColor(false);
                setIsSelectingFontColor(false);
                setIsOthersMenuOpen(false);
                setIsSelectingBorderWidth(false);
                if (isEmoji && onClickEmoji) {
                  onClickEmoji(false);
                }
                if (isLink && onClickLink) {
                  onClickLink(false);
                }
              }}
            />
          </ColorPickerWrapper>
        </Group>
      )}
      {onChangeBorderWidth && (
        <Group>
          <BorderValue
            onClick={() => {
              setIsSelectingBorderWidth(!isSelectingBorderWidth);
              setIsSelectingColor(false);
              setIsSelectingFontColor(false);
              setIsOthersMenuOpen(false);
              setIsSelectingStrokeColor(false);
              if (isEmoji && onClickEmoji) {
                onClickEmoji(false);
              }
              if (isLink && onClickLink) {
                onClickLink(false);
              }
            }}>
            {`${borderWidthIndex} px`}
          </BorderValue>
          {isSelectingBorderWidth && (
            <StyledBorderPicker
              onIncrement={() => {
                const nextBorder = BORDER_WIDTHS[borderWidthIndex + 1];
                if (typeof nextBorder === 'number') {
                  onChangeBorderWidth(nextBorder);
                }
              }}
              onDecrement={() => {
                const nextBorder = BORDER_WIDTHS[borderWidthIndex - 1];
                if (typeof nextBorder === 'number') {
                  onChangeBorderWidth(nextBorder);
                }
              }}
              border={borderWidthIndex >= 0 ? borderWidthIndex : undefined}
            />
          )}
        </Group>
      )}
      {onChangeDashType && onChangeStrokeWidth && (
        <Group>
          <StyledSelect
            onOpen={() => {
              setIsSelectingColor(false);
              setIsSelectingFontColor(false);
              setIsOthersMenuOpen(false);
              setIsSelectingBorderWidth(false);
              setIsSelectingStrokeColor(false);
              if (isEmoji && onClickEmoji) {
                onClickEmoji(false);
              }
              if (isLink && onClickLink) {
                onClickLink(false);
              }
            }}
            value={dashType}
            onChange={ev => {
              const value = ev.target.value as string;
              if (
                onChangeDashType &&
                (value === 'straight' ||
                  value === 'broken1' ||
                  value === 'broken2')
              ) {
                onChangeDashType(value);
              }
            }}
            autoWidth>
            <StyledMenuItem value="straight">
              <Line style={{ strokeDasharray: 0 }} />
            </StyledMenuItem>
            <StyledMenuItem value="broken1">
              <Line style={{ strokeDasharray: 1.8 }} />
            </StyledMenuItem>
            <StyledMenuItem value="broken2">
              <Line style={{ strokeDasharray: 4 }} />
            </StyledMenuItem>
          </StyledSelect>
          <StyledSelect
            onOpen={() => {
              setIsSelectingColor(false);
              setIsSelectingFontColor(false);
              setIsOthersMenuOpen(false);
              setIsSelectingBorderWidth(false);
              setIsSelectingStrokeColor(false);
              if (isEmoji && onClickEmoji) {
                onClickEmoji(false);
              }
              if (isLink && onClickLink) {
                onClickLink(false);
              }
            }}
            value={strokeWidth}
            onChange={ev => {
              const value = ev.target.value as string;
              if (onChangeStrokeWidth) {
                onChangeStrokeWidth(Number(value));
              }
            }}
            autoWidth>
            <StyledMenuItem value={1}>1px</StyledMenuItem>
            <StyledMenuItem value={2}>2px</StyledMenuItem>
            <StyledMenuItem value={3}>3px</StyledMenuItem>
            <StyledMenuItem value={4}>4px</StyledMenuItem>
            <StyledMenuItem value={5}>5px</StyledMenuItem>
            <StyledMenuItem value={6}>6px</StyledMenuItem>
            <StyledMenuItem value={7}>7px</StyledMenuItem>
            <StyledMenuItem value={8}>8px</StyledMenuItem>
          </StyledSelect>
        </Group>
      )}
      {onChangeLineType && (
        <Group>
          <StyledSelect
            value={lineType}
            onOpen={() => {
              setIsSelectingColor(false);
              setIsSelectingFontColor(false);
              setIsOthersMenuOpen(false);
              setIsSelectingBorderWidth(false);
              setIsSelectingStrokeColor(false);
              if (isEmoji && onClickEmoji) {
                onClickEmoji(false);
              }
              if (isLink && onClickLink) {
                onClickLink(false);
              }
            }}
            onChange={ev => {
              const value = ev.target.value as string;
              if (
                onChangeLineType &&
                (value === 'step' || value === 'straight' || value === 'bezier')
              ) {
                onChangeLineType(value);
              }
            }}
            autoWidth>
            <StyledMenuItem value="straight">
              <StraightArrow />
            </StyledMenuItem>
            <StyledMenuItem value="step">
              <StepArrow />
            </StyledMenuItem>
            <StyledMenuItem value="bezier">
              <BentArrow />
            </StyledMenuItem>
          </StyledSelect>
        </Group>
      )}

      {onChangeStartNode && (
        <Group>
          <StyledSelect
            value={startNode}
            onOpen={() => {
              setIsSelectingColor(false);
              setIsSelectingFontColor(false);
              setIsOthersMenuOpen(false);
              setIsSelectingBorderWidth(false);
              setIsSelectingStrokeColor(false);
              if (isEmoji && onClickEmoji) {
                onClickEmoji(false);
              }
              if (isLink && onClickLink) {
                onClickLink(false);
              }
            }}
            onChange={ev => {
              const value = ev.target.value as string;
              if (
                onChangeStartNode &&
                (value === 'none' ||
                  value === 'filled' ||
                  value === 'hollow' ||
                  value === 'arrow')
              ) {
                onChangeStartNode(value);
              }
            }}
            autoWidth>
            <StyledMenuItem value="none">None</StyledMenuItem>
            <StyledMenuItem value="filled">
              <Filled style={{ transform: 'scaleX(-1)' }} />
            </StyledMenuItem>
            <StyledMenuItem value="hollow">
              <Hollow style={{ transform: 'scaleX(-1)' }} />
            </StyledMenuItem>
            <StyledMenuItem value="arrow">
              <Arrow style={{ transform: 'scaleX(-1)' }} />
            </StyledMenuItem>
          </StyledSelect>
        </Group>
      )}
      {onChangeEndNode && (
        <Group>
          <StyledSelect
            onOpen={() => {
              setIsSelectingColor(false);
              setIsSelectingFontColor(false);
              setIsOthersMenuOpen(false);
              setIsSelectingBorderWidth(false);
              setIsSelectingStrokeColor(false);
              if (isEmoji && onClickEmoji) {
                onClickEmoji(false);
              }
              if (isLink && onClickLink) {
                onClickLink(false);
              }
            }}
            value={endNode}
            onChange={ev => {
              const value = ev.target.value as string;
              if (
                onChangeEndNode &&
                (value === 'none' ||
                  value === 'filled' ||
                  value === 'hollow' ||
                  value === 'arrow')
              ) {
                onChangeEndNode(value);
              }
            }}
            autoWidth>
            <StyledMenuItem value="none">None</StyledMenuItem>
            <StyledMenuItem value="filled">
              <Filled />
            </StyledMenuItem>
            <StyledMenuItem value="hollow">
              <Hollow />
            </StyledMenuItem>
            <StyledMenuItem value="arrow">
              <Arrow />
            </StyledMenuItem>
          </StyledSelect>
        </Group>
      )}
      {onChangeFontFamily && onChangeFontSize && onChangeFontColor && (
        <Group>
          <StyledSelect
            value={fontFamily}
            onOpen={() => {
              setIsSelectingColor(false);
              setIsSelectingFontColor(false);
              setIsOthersMenuOpen(false);
              setIsSelectingBorderWidth(false);
              setIsSelectingStrokeColor(false);
              if (isEmoji && onClickEmoji) {
                onClickEmoji(false);
              }
              if (isLink && onClickLink) {
                onClickLink(false);
              }
            }}
            onChange={ev => {
              if (onChangeFontFamily) {
                onChangeFontFamily(ev.target?.value as string);
              }
            }}
            autoWidth>
            <StyledMenuItem
              style={{ fontFamily: 'Brain Storming Arial Rounded MT Bold' }}
              value="Brain Storming Arial Rounded MT Bold">
              Brain Storming Arial Rounded MT Bold
            </StyledMenuItem>
            <StyledMenuItem
              style={{ fontFamily: 'コーポレートロゴ・M' }}
              value="コーポレートロゴ・M">
              コーポレートロゴ・M
            </StyledMenuItem>
            <StyledMenuItem
              style={{ fontFamily: 'BergamoStd' }}
              value="BergamoStd">
              Bergamo Std
            </StyledMenuItem>
            <StyledMenuItem style={{ fontFamily: 'Cousine' }} value="Cousine">
              Cousine
            </StyledMenuItem>
            <StyledMenuItem style={{ fontFamily: 'Dustismo' }} value="Dustismo">
              Dustismo
            </StyledMenuItem>
            <StyledMenuItem
              style={{ fontFamily: 'GentiumBasic' }}
              value="GentiumBasic">
              Gentium Basic
            </StyledMenuItem>
            <StyledMenuItem style={{ fontFamily: 'Kirsty' }} value="Kirsty">
              Kirsty
            </StyledMenuItem>
            <StyledMenuItem
              style={{ fontFamily: 'KomikaHand' }}
              value="KomikaHand">
              Komika Hand
            </StyledMenuItem>
            <StyledMenuItem
              style={{ fontFamily: 'LiberationMono' }}
              value="LiberationMono">
              Liberation Mono
            </StyledMenuItem>
            <StyledMenuItem
              style={{ fontFamily: 'LiberationSans' }}
              value="LiberationSans">
              Liberation Sans
            </StyledMenuItem>
            <StyledMenuItem
              style={{ fontFamily: 'LiberationSerif' }}
              value="LiberationSerif">
              Liberation Serif
            </StyledMenuItem>
            <StyledMenuItem style={{ fontFamily: 'Orbitron' }} value="Orbitron">
              Orbitron
            </StyledMenuItem>
            <StyledMenuItem
              style={{ fontFamily: 'SFArchRival' }}
              value="SFArchRival">
              SF Arch Rival
            </StyledMenuItem>
            <StyledMenuItem
              style={{ fontFamily: 'SFCartoonistHand' }}
              value="SFCartoonistHand">
              SF Cartoonist Hand
            </StyledMenuItem>
            <StyledMenuItem
              style={{ fontFamily: 'SourceSansPro' }}
              value="SourceSansPro">
              Source Sans Pro
            </StyledMenuItem>
          </StyledSelect>
          <StyledSelect
            value={fontSize}
            onOpen={() => {
              setIsSelectingColor(false);
              setIsSelectingFontColor(false);
              setIsOthersMenuOpen(false);
              setIsSelectingBorderWidth(false);
              setIsSelectingStrokeColor(false);
              if (isEmoji && onClickEmoji) {
                onClickEmoji(false);
              }
              if (isLink && onClickLink) {
                onClickLink(false);
              }
            }}
            onChange={ev => {
              const value = ev.target.value;
              if (onChangeFontSize) {
                onChangeFontSize(
                  value === 'inherit' ? 'inherit' : Number(ev.target.value),
                );
              }
            }}
            autoWidth>
            <StyledMenuItem value="inherit">Auto</StyledMenuItem>
            <StyledMenuItem value={6}>6px</StyledMenuItem>
            <StyledMenuItem value={7}>7px</StyledMenuItem>
            <StyledMenuItem value={8}>8px</StyledMenuItem>
            <StyledMenuItem value={9}>9px</StyledMenuItem>
            <StyledMenuItem value={10}>10px</StyledMenuItem>
            <StyledMenuItem value={11}>11px</StyledMenuItem>
            <StyledMenuItem value={12}>12px</StyledMenuItem>
            <StyledMenuItem value={14}>14px</StyledMenuItem>
            <StyledMenuItem value={18}>18px</StyledMenuItem>
            <StyledMenuItem value={24}>24px</StyledMenuItem>
            <StyledMenuItem value={30}>30px</StyledMenuItem>
            <StyledMenuItem value={36}>36px</StyledMenuItem>
            <StyledMenuItem value={48}>48px</StyledMenuItem>
            <StyledMenuItem value={60}>60px</StyledMenuItem>
            <StyledMenuItem value={72}>72px</StyledMenuItem>
            <StyledMenuItem value={96}>96px</StyledMenuItem>
          </StyledSelect>
          <ColorPickerWrapper className="nodrag">
            {isSelectingFontColor && (
              <StyledFontColorPicker
                onChangeComplete={col => {
                  onChangeFontColor(
                    `rgba(${col.rgb.r},${col.rgb.g},${col.rgb.b},${col.rgb.a})`,
                  );
                }}
                onClickCancel={ev => {
                  ev.preventDefault();
                  onChangeFontColor(previousFontColor);
                  setIsSelectingFontColor(false);
                }}
                onClickDone={ev => {
                  ev.preventDefault();
                  setIsSelectingFontColor(false);
                  setPreviousFontColor(fontColor);
                }}
                color={fontColor}
              />
            )}
            <Font
              fontColor={fontColor}
              onClick={() => {
                setIsSelectingFontColor(!isSelectingFontColor);
                setIsSelectingColor(false);
                setIsSelectingBorderWidth(false);
                setIsSelectingStrokeColor(false);
                setIsOthersMenuOpen(false);
                if (isEmoji && onClickEmoji) {
                  onClickEmoji(false);
                }
                if (isLink && onClickLink) {
                  onClickLink(false);
                }
              }}
            />
          </ColorPickerWrapper>
        </Group>
      )}
      {onClickBold &&
        onClickItalicize &&
        onClickUnderline &&
        onClickLineThrough && (
          <Group>
            <Bold
              onMouseDown={event => {
                onClickBold(event);
                setIsSelectingColor(false);
                setIsSelectingFontColor(false);
                setIsOthersMenuOpen(false);
                setIsSelectingBorderWidth(false);
                setIsSelectingStrokeColor(false);
                if (isEmoji && onClickEmoji) {
                  onClickEmoji(false);
                }
                if (isLink && onClickLink) {
                  onClickLink(false);
                }
              }}
              fill={(isBold && 'black') || 'silver'}
            />
            <Italicize
              onMouseDown={event => {
                onClickItalicize(event);
                setIsSelectingColor(false);
                setIsSelectingFontColor(false);
                setIsOthersMenuOpen(false);
                setIsSelectingBorderWidth(false);
                setIsSelectingStrokeColor(false);
                if (isEmoji && onClickEmoji) {
                  onClickEmoji(false);
                }
                if (isLink && onClickLink) {
                  onClickLink(false);
                }
              }}
              fill={(isItalicize && 'black') || 'silver'}
            />
            <Underline
              onMouseDown={event => {
                onClickUnderline(event);
                setIsSelectingColor(false);
                setIsSelectingFontColor(false);
                setIsOthersMenuOpen(false);
                setIsSelectingBorderWidth(false);
                setIsSelectingStrokeColor(false);
                if (isEmoji && onClickEmoji) {
                  onClickEmoji(false);
                }
                if (isLink && onClickLink) {
                  onClickLink(false);
                }
              }}
              fill={(isUnderline && 'black') || 'silver'}
            />
            <LineThrough
              onMouseDown={event => {
                onClickLineThrough(event);
                setIsSelectingColor(false);
                setIsSelectingFontColor(false);
                setIsOthersMenuOpen(false);
                setIsSelectingBorderWidth(false);
                setIsSelectingStrokeColor(false);
                if (isEmoji && onClickEmoji) {
                  onClickEmoji(false);
                }
                if (isLink && onClickLink) {
                  onClickLink(false);
                }
              }}
              fill={(isLineThrough && 'black') || 'silver'}
            />
          </Group>
        )}

      {onTextRealign && (
        <Group>
          <StyledSelect
            value={textAlign}
            onOpen={() => {
              setIsSelectingColor(false);
              setIsSelectingFontColor(false);
              setIsOthersMenuOpen(false);
              setIsSelectingBorderWidth(false);
              setIsSelectingStrokeColor(false);
              if (isEmoji && onClickEmoji) {
                onClickEmoji(false);
              }
              if (isLink && onClickLink) {
                onClickLink(false);
              }
            }}
            onChange={ev => {
              const value = ev.target.value as string;
              if (
                onTextRealign &&
                (value === 'left' || value === 'center' || value === 'right')
              ) {
                onTextRealign(value);
              }
            }}
            autoWidth>
            <StyledMenuItem value="left">Left</StyledMenuItem>
            <StyledMenuItem value="center">Center</StyledMenuItem>
            <StyledMenuItem value="right">Right</StyledMenuItem>
          </StyledSelect>
        </Group>
      )}

      {onClickEmoji && (
        <Group>
          <AddEmoji
            onClick={() => {
              onClickEmoji();
              setIsSelectingColor(false);
              setIsSelectingFontColor(false);
              setIsOthersMenuOpen(false);
              setIsSelectingBorderWidth(false);
              setIsSelectingStrokeColor(false);
              if (isLink && onClickLink) {
                onClickLink(false);
              }
            }}
          />
        </Group>
      )}

      {onBulletList && (
        <Group>
          <BulletList
            onClick={() => {
              onBulletList();
              setIsSelectingColor(false);
              setIsSelectingFontColor(false);
              setIsOthersMenuOpen(false);
              setIsSelectingBorderWidth(false);
              setIsSelectingStrokeColor(false);
              if (isEmoji && onClickEmoji) {
                onClickEmoji(false);
              }
              if (isLink && onClickLink) {
                onClickLink(false);
              }
            }}
          />
        </Group>
      )}

      {onClickLink && (
        <Group>
          <Link
            onClick={() => {
              onClickLink();
              setIsSelectingColor(false);
              setIsSelectingFontColor(false);
              setIsOthersMenuOpen(false);
              setIsSelectingBorderWidth(false);
              setIsSelectingStrokeColor(false);
              if (isEmoji && onClickEmoji) {
                onClickEmoji(false);
              }
            }}
          />
        </Group>
      )}
      {onClickFlip && (
        <Group>
          <Flip
            onClick={() => {
              onClickFlip();
              setIsSelectingColor(false);
              setIsSelectingFontColor(false);
              setIsOthersMenuOpen(false);
              setIsSelectingBorderWidth(false);
              setIsSelectingStrokeColor(false);
              if (isEmoji && onClickEmoji) {
                onClickEmoji(false);
              }
              if (isLink && onClickLink) {
                onClickLink(false);
              }
            }}
          />
        </Group>
      )}

      <Group>
        <OtherOptionsWrapper>
          <StyledKebabMenu
            onClick={() => {
              setIsOthersMenuOpen(!isOthersMenuOpen);
              setIsSelectingColor(false);
              setIsSelectingFontColor(false);
              setIsSelectingBorderWidth(false);
              setIsSelectingStrokeColor(false);
              if (isEmoji && onClickEmoji) {
                onClickEmoji(false);
              }
              if (isLink && onClickLink) {
                onClickLink(false);
              }
            }}
          />
          <OtherOptions
            style={{ display: isOthersMenuOpen ? 'inline-flex' : 'none' }}>
            <Paper>
              <MenuList>
                <MenuItem onClick={onClickCopy}>Copy</MenuItem>
                <MenuItem onClick={onClickPaste}>Paste</MenuItem>
                <MenuItem onClick={onClickDelete}>Delete</MenuItem>
              </MenuList>
            </Paper>
          </OtherOptions>
        </OtherOptionsWrapper>
      </Group>
    </Wrapper>
  );
};

export default Component;
