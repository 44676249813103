import React from 'react';

type Props = {
  [key: string]: string | number | Function;
} & { themecolor?: string };

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="83"
      height="100"
      viewBox="0 0 83 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M0.916016 30.8203H37.8339V61.4062H0.916016V30.8203ZM0.916016 68.9453H37.8339V99.5312H0.916016V68.9453ZM45.2252 30.8203H82.143V61.4062H45.2252V30.8203ZM45.2252 68.9453H82.143V99.5312H45.2252V68.9453ZM70.1562 13.125L57.0588 0.859375L43.3869 25.1562L70.1562 13.125ZM39.6721 25.1562L26.0386 0.859375L12.9411 13.125L39.6721 25.1562Z"
        fill={props.themecolor ? props.themecolor : '#23A6F0'}
      />
    </svg>
  );
};

export default Component;
