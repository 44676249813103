import React from 'react';
import logo from '../../../assets/images/Ideaman_logo.png';
import whiteLogo from '../../../assets/images/Ideaman_logo_white.png';

export type Props = {
  className?: string;
  isWhite?: boolean;
};

const Component = ({
  className,
  isWhite = false,
}: Props): React.ReactElement => {
  return (
    <img className={className} src={isWhite ? whiteLogo : logo} width="150px" />
  );
};

export default Component;
