import React from 'react';

type Props = {
  [key: string]: string | number | Function;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M0 19.9993C0 31.0463 8.95629 40 20 40C31.0466 40 40 31.0463 40 19.9993C40 8.95661 31.0466 0 20 0C8.95629 0 0 8.95661 0 19.9993ZM20.3462 7.27789L34.9422 25.789C35.0455 25.9214 35.0673 26.0988 34.9945 26.2501C34.9218 26.4014 34.7705 26.4916 34.6032 26.4916H27.858L20.3448 16.9633C20.3462 13 20.1746 16.7553 20.3448 16.9633L12.1391 26.4916H5.40112C5.23384 26.4916 5.0811 26.4014 5.00836 26.2501C4.93563 26.0988 4.956 25.9199 5.05928 25.789L19.6582 7.27789C19.8269 7.06986 20.176 7.06986 20.3462 7.27789Z"
        fill="#60BF91"
        fillOpacity="0.8"
      />
    </svg>
  );
};

export default Component;
