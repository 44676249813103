import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import theme from '../../../constants/themes';
import { UploadCloud } from '../../atoms/Icons';
import { FeatureContent } from './types';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import InlineEditor from '@itsmitchyyy/ckeditor5-build-inline';
import { Toast } from '../../atoms/Toast';
import { Props as INotification } from '../../atoms/Toast/Component';
import { useMutation } from 'react-query';
import { usePageHooks } from '../../../hooks/page';
import createDOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import { replaceNbsp } from '../../../utils/strings';

const FeatureContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2em;
`;

const FeatureTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  letter-spacing: -0.02em;
  color: ${theme.colors.black};
`;

const FeatureCardContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-top: 4em;
  gap: 2em;

  .ckEditorStyles {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
  }
`;

const FeatureCard = styled.div`
  width: 300px;
  box-shadow: 4px 4px 10px 4px rgb(0 0 0 / 5%);
  position: relative;
`;

const FeatureCardImage = styled.div`
  width: 300px;
  height: 300px;
  position: relative;
`;

const FeatureCardContent = styled.div`
  padding: 0.5em 0.5em 0 0.5em;
`;

const CardContentHeader = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: ${theme.colors.textColor};

  p:first-of-type {
    margin: 0;
  }
`;
const CardContentDetails = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: ${theme.colors.textColor};

  p:first-of-type {
    margin: 0;
  }
`;

const FeatureCardFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 0.5em 0.5em 0.5em;

  a {
    color: ${theme.colors.notifToastBorder};
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: -0.02em;
    text-decoration: none;
  }
`;

const CardAction = styled.div``;

const UploadFileButton = styled.div`
  background: #ffd200;
  width: 90px;
  height: 70px;
  display: flex;
  position: absolute;
  top: 10px;
  right: 10px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
`;

const StyledUploadCLoud = styled(UploadCloud)`
  height: 30px;
  width: 30px;
`;

const ErrorWrapper = styled.div`
  color: ${theme.colors.errorToastText};
`;

const DivContainer = styled.div<{ hasError?: boolean }>`
  .ck.ck-editor__editable.ck-rounded-corners:not(.ck-editor__nested-editable) {
    ${({ hasError }) =>
      hasError ? `border: 1px solid ${theme.colors.errorToastText}` : ''};
  }
`;

const DOMPurify = createDOMPurify(window as any);

export type Props = {
  featureContent: FeatureContent;
  isFetching?: boolean;
  allowEditContent?: boolean;
  onChangeFeatureData?: (
    type: 'description' | 'title' | 'image',
    text: string,
    index: number,
  ) => void;
  serverErrors?: any;
};

const Component = ({
  featureContent,
  isFetching,
  allowEditContent,
  onChangeFeatureData,
  serverErrors,
}: Props): React.ReactElement => {
  const { t } = useTranslation();
  const { useUploadMedia } = usePageHooks();
  const { uploadMedia } = useUploadMedia();
  const inputFile = useRef<any>([]);
  const [notification, setNotification] = useState<INotification | null>(null);
  const [uploadedImageFiles, setUploadedImageFiles] = useState<string[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [featuredData, setFeaturedData] = useState<FeatureContent>();

  const handleClickInputFile = (index: number) => {
    if (inputFile?.current) {
      inputFile?.current[index]?.click();
    }
  };

  const fileHandleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;

    if (!fileList) return;

    if (
      !['jpeg', 'jpg', 'png', 'gif', 'svg+xml'].includes(
        fileList[0]?.type.toLowerCase().replace(/(.*)\//g, ''),
      )
    ) {
      setNotification({
        isOpen: true,
        message: t('invalidImageFormat'),
        type: 'error',
        position: 'top-right',
        onClose: () => setNotification(null),
      });
      return;
    }

    uploadMediaMutation(fileList[0]);
  };

  const { mutate: uploadMediaMutation } = useMutation(
    (file: File) => {
      return uploadMedia(file);
    },
    {
      onSuccess: data => {
        const newData = [...uploadedImageFiles];
        newData[selectedIndex] = data.url;
        setUploadedImageFiles(newData);
      },
    },
  );

  useEffect(() => {
    if (uploadedImageFiles.length && onChangeFeatureData) {
      onChangeFeatureData(
        'image',
        uploadedImageFiles[selectedIndex],
        selectedIndex,
      );
    }
  }, [uploadedImageFiles]);

  useEffect(() => {
    if (!isFetching && !featuredData) {
      setFeaturedData(featureContent);
    }
  }, [isFetching]);

  return (
    <FeatureContainer id="feature">
      {isFetching ? null : (
        <>
          <FeatureTitle>
            <span>{featureContent?.title}</span>
          </FeatureTitle>
          <FeatureCardContainer>
            {featureContent?.cards?.map((content, index) => (
              <FeatureCard key={index}>
                <FeatureCardImage>
                  <img
                    height={300}
                    width={300}
                    src={uploadedImageFiles?.[index] || content.image}
                    alt="image"
                  />
                  {allowEditContent && (
                    <UploadFileButton
                      onClick={() => {
                        setSelectedIndex(index);
                        handleClickInputFile(index);
                      }}>
                      <StyledUploadCLoud />
                      <input
                        ref={el => (inputFile.current[index] = el)}
                        type="file"
                        id={`file_${index}`}
                        onChange={fileHandleChange}
                        style={{ display: 'none' }}
                      />
                    </UploadFileButton>
                  )}
                </FeatureCardImage>
                <FeatureCardContent
                  className={allowEditContent ? 'ckEditorStyles' : ''}>
                  {allowEditContent ? (
                    <>
                      <DivContainer
                        hasError={
                          serverErrors &&
                          !!serverErrors?.[`feature.${index}.title`]
                        }>
                        <CKEditor
                          id={`feature_editor--title_${index}`}
                          key={`feature_editor--title_${index}`}
                          editor={InlineEditor}
                          config={{
                            extraAllowedContent: 'div(*)',
                            allowedContent: true,
                          }}
                          data={content?.title}
                          onChange={(event: any, editor: any) => {
                            const data = editor.getData();

                            if (onChangeFeatureData) {
                              onChangeFeatureData('title', data, index);
                            }
                          }}
                        />
                        {serverErrors &&
                          !!serverErrors?.[`feature.${index}.title`] && (
                            <ErrorWrapper>
                              <span>
                                {serverErrors?.[`feature.${index}.title`]}
                              </span>
                            </ErrorWrapper>
                          )}
                      </DivContainer>
                      <DivContainer
                        hasError={
                          serverErrors &&
                          !!serverErrors?.[`feature.${index}.description`]
                        }>
                        <CKEditor
                          id={`feature_editor--description_${index}`}
                          key={`feature_editor--description_${index}`}
                          style={{ marginTop: '.5em' }}
                          editor={InlineEditor}
                          config={{
                            extraAllowedContent: 'div(*)',
                            allowedContent: true,
                          }}
                          data={content.description}
                          onChange={(event: any, editor: any) => {
                            const data = editor.getData();

                            if (onChangeFeatureData) {
                              onChangeFeatureData('description', data, index);
                            }
                          }}
                        />
                        {serverErrors &&
                          !!serverErrors?.[`feature.${index}.description`] && (
                            <ErrorWrapper>
                              <span>
                                {serverErrors?.[`feature.${index}.description`]}
                              </span>
                            </ErrorWrapper>
                          )}
                      </DivContainer>
                    </>
                  ) : (
                    <>
                      <CardContentHeader
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(
                            replaceNbsp(content.title),
                          ),
                        }}
                      />
                      <CardContentDetails
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(
                            replaceNbsp(content.description),
                          ),
                        }}
                      />
                    </>
                  )}
                </FeatureCardContent>
                <FeatureCardFooter>
                  <CardAction>
                    <a href={content.read_more}>さらに見る</a>
                  </CardAction>
                </FeatureCardFooter>
              </FeatureCard>
            ))}
          </FeatureCardContainer>
        </>
      )}
      {notification ? <Toast {...notification} /> : null}
    </FeatureContainer>
  );
};

export default Component;
