import React from 'react';
import styled from 'styled-components';
import theme from '../../../constants/themes';
import { useTranslation } from 'react-i18next';
import { Button } from '../../atoms/Button';
import { ModalWrapper } from '../../molecules/ModalWrapper';

const Container = styled.div`
  width: 620px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 45px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${theme.colors.white};
`;

const ConfirmationLabel = styled.span`
  font-family: ${theme.fonts.primary};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.2px;
  color: ${theme.colors.black};
  margin: 25px 0 30px;
`;

const StyledButton = styled(Button)`
  margin-top: 24px;
  width: 120px;

  @media ${theme.breakpoints.mobile} {
    width: 100%;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 300px;
`;

export type Props = {
  isOpen?: boolean;
  onClose: () => void;
  onDelete: () => void;
  isLoading?: boolean;
  confirmationText?: string;
};

const Component = ({
  isOpen = false,
  onClose,
  onDelete,
  isLoading,
  confirmationText,
}: Props): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <ModalWrapper
      isOpen={isOpen}
      title={t('deleteConfirmation')}
      onClose={onClose}>
      <Container>
        <Wrapper>
          <ConfirmationLabel>{confirmationText}</ConfirmationLabel>
          <ButtonWrapper>
            <StyledButton
              theme={'gray'}
              disabled={isLoading}
              title={t('no')}
              onPress={onClose}
            />
            <StyledButton
              disabled={isLoading}
              theme={isLoading ? 'gray' : 'lightSecondary'}
              title={t('yes')}
              onPress={onDelete}
              isLoading={isLoading}
            />
          </ButtonWrapper>
        </Wrapper>
      </Container>
    </ModalWrapper>
  );
};

export default Component;
