import React from 'react';

type Props = {
  [key: string]: string | number | Function;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="14"
      height="5"
      viewBox="0 0 14 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.27946 0.0529833C6.34899 0.0181418 6.42569 0 6.50346 0C6.58123 0 6.65793 0.0181418 6.72746 0.0529833L12.7275 3.05298C12.7867 3.08209 12.8396 3.12263 12.883 3.17226C12.9265 3.2219 12.9597 3.27965 12.9808 3.34219C13.0018 3.40473 13.0103 3.47082 13.0057 3.53664C13.0011 3.60247 12.9835 3.66673 12.9539 3.72573C12.9244 3.78472 12.8834 3.83728 12.8335 3.88037C12.7835 3.92347 12.7255 3.95624 12.6628 3.97681C12.6001 3.99738 12.5339 4.00533 12.4681 4.00021C12.4023 3.99509 12.3382 3.977 12.2795 3.94698L6.50346 1.05998L0.727463 3.94698C0.668699 3.977 0.604575 3.99509 0.538787 4.00021C0.472999 4.00533 0.406849 3.99738 0.344149 3.97681C0.28145 3.95624 0.223442 3.92347 0.17347 3.88037C0.123498 3.83728 0.0825497 3.78472 0.0529867 3.72573C0.0234237 3.66673 0.00583041 3.60247 0.00122126 3.53664C-0.00338789 3.47082 0.00507824 3.40473 0.0261315 3.34219C0.0471847 3.27965 0.0804087 3.2219 0.123888 3.17226C0.167367 3.12263 0.220241 3.08209 0.279463 3.05298L6.27946 0.0529833Z"
        fill="#737373"
      />
    </svg>
  );
};

export default Component;
