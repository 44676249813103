import React from 'react';

type Props = {
  [key: string]: string | number | Function;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M19.7583 18.2652C19.7279 18.2652 19.6997 18.2676 19.6692 18.2725L14.3314 19.0415C14.0577 19.0802 13.8665 18.7464 14.0142 18.4853C14.0946 18.3474 14.1728 18.2072 14.251 18.0669C14.4574 17.6945 14.1815 17.1987 13.7991 17.1987C13.7731 17.1987 13.747 17.2012 13.7187 17.206L6.72332 18.3764L5.88691 18.5167L3.60579 18.8988C3.34726 18.9423 3.17346 19.1866 3.19519 19.4792C3.20388 19.6098 3.25167 19.7331 3.32771 19.825C3.41678 19.9338 3.54062 19.9943 3.67531 19.9943C3.70138 19.9943 3.72745 19.9919 3.75569 19.987L12.0937 18.5917C12.3783 18.5433 12.5739 18.9012 12.4066 19.1624C12.3522 19.2446 12.2979 19.3293 12.2436 19.4115C12.0937 19.6388 12.1285 20.0185 12.3023 20.2144C12.3914 20.3159 12.5043 20.3691 12.6238 20.3691C12.6368 20.3691 12.6521 20.3691 12.6651 20.3667H12.6673H12.6694L19.8669 19.3462H19.8691H19.8713C19.9951 19.3244 20.1037 19.2446 20.1754 19.1213C20.2536 18.9858 20.2819 18.819 20.2471 18.6691C20.1906 18.4248 19.9994 18.2652 19.7583 18.2652Z"
        fill="black"
      />
      <path
        d="M15.4438 4.35448L11.1879 0.369141L9.74536 2.27959L14.0013 6.26493L15.4438 4.35448Z"
        fill="black"
      />
      <mask id="path-3-inside-1_1248_2522" fill="transparent">
        <path d="M0.262447 19.3237C0.251585 19.4882 0.388451 19.6163 0.531836 19.5752L1.60722 19.2729L0.342829 18.088L0.262447 19.3237Z" />
      </mask>
      <path
        d="M0.262447 19.3237C0.251585 19.4882 0.388451 19.6163 0.531836 19.5752L1.60722 19.2729L0.342829 18.088L0.262447 19.3237Z"
        fill="black"
      />
      <path
        d="M0.262447 19.3237L1.26027 19.3897L1.26034 19.3887L0.262447 19.3237ZM0.531836 19.5752L0.261232 18.6125L0.256081 18.614L0.531836 19.5752ZM1.60722 19.2729L1.87782 20.2356L3.58487 19.7558L2.29102 18.5433L1.60722 19.2729ZM0.342829 18.088L1.02663 17.3583L-0.517679 15.9111L-0.655062 18.0231L0.342829 18.088ZM-0.735378 19.2578C-0.78048 19.9406 -0.159492 20.8139 0.80759 20.5364L0.256081 18.614C0.559433 18.527 0.845667 18.6311 1.02206 18.7959C1.19317 18.9558 1.27437 19.1763 1.26027 19.3897L-0.735378 19.2578ZM0.802436 20.5379L1.87782 20.2356L1.33662 18.3102L0.261236 18.6125L0.802436 20.5379ZM2.29102 18.5433L1.02663 17.3583L-0.340976 18.8177L0.923413 20.0026L2.29102 18.5433ZM-0.655062 18.0231L-0.735444 19.2588L1.26034 19.3887L1.34072 18.1529L-0.655062 18.0231Z"
        fill="black"
        mask="url(#path-3-inside-1_1248_2522)"
      />
      <path
        d="M1.39341 14.3622L1.39356 14.3598C1.40298 14.2024 1.45822 14.0554 1.54547 13.9396C1.54554 13.9395 1.54561 13.9394 1.54568 13.9393L9.19841 3.82215L12.7204 7.12023L5.00497 17.3226C5.00476 17.3229 5.00455 17.3231 5.00434 17.3234C4.91796 17.4362 4.80991 17.5076 4.69951 17.539C4.6993 17.5391 4.6991 17.5391 4.69889 17.5392L2.42346 18.1762L1.21965 17.0479L1.39341 14.3622Z"
        fill="transparent"
        stroke="black"
      />
    </svg>
  );
};

export default Component;
