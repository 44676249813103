import { BoardCustomers } from '../../../domain/entities/board';
import BoardRepository from '../../ports/BoardRepository';

export type fetchBoardCustomersUseCase = (
  boardCode: string,
) => Promise<BoardCustomers[]>;

export const buildFetchBoardCustomers = (dependencies: {
  boardRepo: BoardRepository;
}): fetchBoardCustomersUseCase => {
  const { boardRepo } = dependencies;

  const fetchBoardCustomers: fetchBoardCustomersUseCase = async boardCode =>
    boardRepo.fetchBoardCustomers(boardCode);

  return fetchBoardCustomers;
};
