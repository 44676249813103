import { PaymentHistoryData } from '../../../domain/entities/payment';
import PaymentRepository from '../../ports/PaymentRepository';

export type fetchPaymentHistoryUseCase = (
  customerId: string,
) => Promise<PaymentHistoryData[]>;

export const buildFetchPaymentHistory = (dependencies: {
  paymentRepo: PaymentRepository;
}): fetchPaymentHistoryUseCase => {
  const { paymentRepo } = dependencies;

  const choosePlan: fetchPaymentHistoryUseCase = async customerId =>
    paymentRepo.fetchPaymentHistory(customerId);

  return choosePlan;
};
