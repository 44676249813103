import { GenericResponse } from '../../../domain/entities/rest';
import AuthService from '../../ports/AuthService';

export type updateUserUseCase = (payload: {
  id: number;
  name?: string;
  displayPhoto?: File;
  oldPassword?: string;
  newPassword?: string;
  confirmPassword?: string;
  email?: string;
}) => Promise<GenericResponse>;

export const buildUpdateUser = (dependencies: {
  authService: AuthService;
}): updateUserUseCase => {
  const { authService } = dependencies;

  const updateUser: updateUserUseCase = payload =>
    authService.updateUser(payload);

  return updateUser;
};
