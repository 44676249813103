import { useCallback } from 'react';
import { PlanHooks } from '.';
import { useDependencies } from '..';
import { Plan } from '../../domain/entities/plan';
import { GenericResponse } from '../../domain/entities/rest';

export const useFetchPlans: PlanHooks['useFetchPlans'] = (): {
  fetchPlans: () => Promise<Plan[]>;
  selectFreePlan: (
    planId: number,
    customerId: number,
  ) => Promise<GenericResponse>;
} => {
  const { planInteractor } = useDependencies();

  const fetchPlans = useCallback(async () => {
    return planInteractor.fetchPlans();
  }, []);

  const selectFreePlan = useCallback(async (planId, customerId) => {
    return planInteractor.selectFreePlan(planId, customerId);
  }, []);

  return { fetchPlans, selectFreePlan };
};
