import React, { useCallback, useRef } from 'react';
import styled from 'styled-components';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Box from '@mui/material/Box';
import theme from '../../../constants/themes';
import { InputWithIcon } from '../../atoms/InputWithIcon';
import { Button } from '../../atoms/Button';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { Upload } from '../../atoms/Icons';
import { useTranslation } from 'react-i18next';
import { useBoardHooks } from '../../../hooks/board';
import { useGlobalState } from '../../../hooks/global';
import { isWindowsOS } from '../../../utils/booleans';
import searchImg from '../../../assets/images/search.png';
import queryKeys from '../../../constants/queryKeys';

const Wrapper = styled.div`
  background-color: ${theme.colors.white};
  color: ${theme.colors.textColor};
  display: inline-flex;
  flex-direction: column;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.25);
  padding: 9px 10px 8px 10px;
`;

const StyledSearchInput = styled(InputWithIcon)`
  padding-top: ${isWindowsOS() ? 13 : 8}px;
`;

const StyledLabelRow = styled.div`
  display: flex;
  margin-bottom: 13px;
`;

const HighligtedLabel = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: -0.02em;
  border-bottom: solid ${theme.colors.notifToastBorder} 3px;
  margin-right: 8px;
`;

const Label = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: -0.02em;
`;

const StyledButton = styled(Button)`
  margin: 8px 0 13px 0;
`;

export type Props = {
  className?: string;
  onClickMedia: (url: string) => void;
  onErrorUpload?: (err: unknown) => void;
};

const Component = ({
  className,
  onClickMedia,
  onErrorUpload = () => {},
}: Props): React.ReactElement => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const queryClient = useQueryClient();
  const { useBoardAPI } = useBoardHooks();
  const { useCurrentUser } = useGlobalState();
  const { currentUser } = useCurrentUser;
  const customerId = currentUser?.user?.customer?.id;
  const { fetchMedia, uploadMedia } = useBoardAPI();
  const { t } = useTranslation();

  const { data = [] } = useQuery(
    [queryKeys.FETCH_MEDIA, customerId],
    async () => {
      if (typeof customerId === 'undefined') {
        throw new Error('invalid value for customer id');
      }
      const response = await fetchMedia(customerId);
      return response.map(item => item.path);
    },
  );

  const { mutate: submitMedia } = useMutation(
    ({ file, type }: { file: File; type: number }) => {
      return uploadMedia(file, type);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.FETCH_MEDIA);
      },
      onError: onErrorUpload,
    },
  );
  const onDragStart = useCallback(
    (event: React.DragEvent<HTMLLIElement>, url: string) => {
      event.dataTransfer.setData('node-type', 'media');
      event.dataTransfer.setData('node-data', url);
      event.dataTransfer.effectAllowed = 'move';
    },
    [],
  );

  return (
    <Wrapper className={className}>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        accept="image/png, image/gif, image/jpeg"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const file = event.target.files?.[0];
          if (!file) {
            throw new Error('file not found');
          }
          submitMedia({ file, type: 0 });
        }}
      />
      <StyledSearchInput placeholder="検索" backgroundIcon={searchImg} />
      <StyledButton
        onPress={() => {
          if (fileInputRef) {
            fileInputRef.current?.click();
          }
        }}
        title={t('upload')}
        theme="lightSecondary"
        icon={<Upload />}
      />
      <StyledLabelRow>
        <HighligtedLabel>{t('uploadedImage')}</HighligtedLabel>
        <Label>{t('searchResults')}</Label>
      </StyledLabelRow>
      <Box
        sx={{
          width: 264,
          height: 291,
          overflowY: 'auto',
        }}>
        <ImageList
          variant="masonry"
          cols={2}
          style={{
            marginBlock: 0,
          }}>
          {data.map((img, index) => (
            <ImageListItem
              draggable
              onDragStart={ev => onDragStart(ev, img)}
              key={`${img}-${index}`}
              onClick={() => {
                onClickMedia(img);
              }}>
              <img src={img} loading="lazy" />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
    </Wrapper>
  );
};

export default Component;
