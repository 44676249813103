import React from 'react';
import { SignUp } from '../../templates/SignUp';

export type Props = {};

const Component = ({}: Props): React.ReactElement => {
  return <SignUp />;
};

export default Component;
