import React, { SVGProps } from 'react';
import styled from 'styled-components';

export const EditorContainer = styled.div`
  cursor: auto;
  position: relative;
  .shape-wrapper {
    position: absolute;
    top: 0;
  }
  .overflow-wrapper {
    position: absolute;
    top: 0;
    box-sizing: border-box;
  }
`;

export const Shape = (props: SVGProps<SVGSVGElement>): React.ReactElement => {
  return (
    <svg
      width="26"
      height="22"
      viewBox="0 0 26 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M1 4C1 2.34315 2.34315 1 4 1H22C23.6569 1 25 2.34315 25 4V14C25 15.6569 23.6569 17 22 17H18.4L18.0785 20.9962L12.4 17H4C2.34315 17 1 15.6569 1 14V4Z"
        fill="inherit"
        stroke="inherit"
        strokeWidth="inherit"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};

export const ShapeOutside = (): React.ReactElement => {
  return (
    <>
      <div
        style={{
          shapeOutside:
            'polygon(0px 0px, 100% 0, 100% 10%, 14% 10%, 14% 76%, 100% 76%, 100% 100%, 0 100%)',
          width: '50%',
          height: '100%',
          float: 'left',
          pointerEvents: 'none',
        }}
      />
      <div
        style={{
          shapeOutside:
            'polygon(0 0, 0 10%, 86% 10%, 86% 76%, 0 76%, 0 100%, 100% 100%, 100% 0)',
          width: '50%',
          height: '100%',
          float: 'right',
          pointerEvents: 'none',
        }}
      />
    </>
  );
};
