import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  EditorState,
  Editor,
  ContentState,
  convertToRaw,
  convertFromRaw,
} from 'draft-js';
import PropertiesPane, {
  customBlockFn,
  customStyleFn,
} from '../PropertiesPane/WYSIWYGHOC';
import { EditorRef } from '../PropertiesPane/types';
import htmlToDraft from 'html-to-draftjs';
import { useEditorRef } from '../PropertiesPane/hooks';
import theme from '../../../constants/themes';

const EditorContainer = styled.div`
  cursor: auto;
  min-width: 100px;
  min-height: 100px;
`;

const OverflowWrapper = styled.div<{ $isFocused?: boolean }>`
  margin: 5px;
  cursor: ${({ $isFocused }) => ($isFocused ? `unset` : `all-scroll`)};
  .public-DraftEditor-content {
    /* NOTE: need !important because style needed to be overwritten is inline */
    user-select: ${({ $isFocused }) =>
      $isFocused ? `text` : `none`} !important;
  }
  &&&& {
    overflow: visible;
  }
`;

const PlaceholderSpan = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  color: ${theme.colors.gray};
`;

export type Props = {
  id?: string;
  width: number;
  height: number;
  label?: string;
  style?: React.CSSProperties;
  isSelected?: boolean;
  onRef?: (ref: HTMLDivElement | null) => void;
  onChangeLabel?: (value: string) => void;
  onChangeColor?: (color: string) => void;
  onTextRealign?: (align: 'left' | 'center' | 'right') => void;
  onClickEmoji?: () => void;
  onClickLink?: () => void;
  onClickPaste?: () => void;
  onClickCopy?: () => void;
  onClickDelete?: () => void;
};

const Component = ({
  width,
  height,
  label,
  onRef = () => {},
  onChangeLabel = () => {},
  style,
  onChangeColor,
  onClickEmoji,
  onTextRealign,
  onClickLink,
  isSelected,
  onClickCopy,
  onClickPaste,
  onClickDelete,
}: Props): React.ReactElement => {
  const [editorState, setEditorState] = useState(() => {
    if (!label) {
      return EditorState.createEmpty();
    }
    try {
      const contentState = convertFromRaw(JSON.parse(label));
      return EditorState.createWithContent(contentState);
    } catch {
      // NOTE: means that 'label' is of type html and is from previous schema
      const blocksFromHTML = htmlToDraft(label);
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap,
      );
      return EditorState.createWithContent(state);
    }
  });

  const ref = useRef<EditorRef>();
  const { handleFocus, handleBlur, isFocused } = useEditorRef(
    ref,
    editorState,
    setEditorState,
  );

  useEffect(() => {
    if (label && !isSelected) {
      try {
        const contentState = convertFromRaw(JSON.parse(label));
        setEditorState(EditorState.createWithContent(contentState));
      } catch {}
    }
  }, [label, isSelected]);

  const rawState = convertToRaw(editorState.getCurrentContent());
  return (
    <>
      {isSelected && (
        <PropertiesPane
          style={{ top: -90 }}
          editorState={editorState}
          setEditorState={setEditorState}
          onChangeColor={onChangeColor}
          onClickEmoji={onClickEmoji}
          onTextRealign={onTextRealign}
          onClickLink={onClickLink}
          onClickCopy={onClickCopy}
          onClickPaste={onClickPaste}
          onClickDelete={onClickDelete}
          onChangeLabel={onChangeLabel}
        />
      )}
      <EditorContainer
        ref={onRef}
        style={{
          ...style,
          width,
          height,
        }}>
        <OverflowWrapper
          className={`overflow-wrapper ${isFocused ? 'nodrag' : ''}`}
          onDoubleClick={handleFocus}
          $isFocused={isFocused}
          onBlur={handleBlur}
          style={{
            width: 'auto',
            height,
          }}
          onKeyDown={ev => {
            ev.stopPropagation();
          }}>
          <Editor
            editorState={editorState}
            onFocus={handleFocus}
            onChange={eState => {
              const serialized = JSON.stringify(
                convertToRaw(eState.getCurrentContent()),
              );
              const isNew = label !== serialized;
              if (isNew) {
                onChangeLabel(serialized);
              }
              setEditorState(eState);
            }}
            customStyleFn={customStyleFn}
            blockStyleFn={customBlockFn}
            ref={ref}
            readOnly={!isFocused}
          />
        </OverflowWrapper>
      </EditorContainer>
      {rawState?.blocks[0]?.text === '' &&
      rawState?.blocks?.length === 1 &&
      !isSelected ? (
        <PlaceholderSpan>Type Something</PlaceholderSpan>
      ) : (
        undefined
      )}
    </>
  );
};

export default Component;
