import React from 'react';
import styled from 'styled-components';
import theme from '../../../constants/themes';
import { HowToUseContent } from '../TopBody/types';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
const HowToUseTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  letter-spacing: -0.02em;
  padding: 0.5em 0.2em;
`;

const StyledVideo = styled.video`
  width: 100%;
  height: 100%;
`;

const StyledIframe = styled.iframe`
  width: 100%;
  height: 100%;
`;

const HowToUseVideo = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  height: 350px;
`;

const HowToUseSteps = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  padding: 1.5em 0.5em;
`;

const StepsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1em;
  align-items: center;
`;

const StepCircle = styled.div`
  border-radius: 50%;
  border: 5px solid #60bf91;
  height: 20px;
  width: 20px;
`;

const StepText = styled.div`
  height: 20px;
  font-family: ${theme.fonts.primary};
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: ${theme.colors.textColor};
`;

export type Props = {
  howToUseContent: HowToUseContent;
  isFetching?: boolean;
};

const Component = ({
  isFetching,
  howToUseContent,
}: Props): React.ReactElement => {
  return (
    <Container>
      {isFetching ? null : (
        <>
          <HowToUseTitle>{howToUseContent.title}</HowToUseTitle>
          <HowToUseVideo>
            {howToUseContent.media?.includes('.mp4') ? (
              <StyledVideo src={howToUseContent.media} controls>
                Your browser does not support the video tag.
              </StyledVideo>
            ) : (
              <StyledIframe src={howToUseContent.media} />
            )}
          </HowToUseVideo>
          <HowToUseSteps>
            {howToUseContent.item?.map((item, index) => (
              <StepsContainer key={index}>
                <StepCircle />
                <StepText>{item}</StepText>
              </StepsContainer>
            ))}
          </HowToUseSteps>
        </>
      )}
    </Container>
  );
};

export default Component;
