import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router';
import styled, { createGlobalStyle } from 'styled-components';
import theme from '../../../constants/themes';
import { usePageHooks } from '../../../hooks/page';
import { usePlanHooks } from '../../../hooks/plan';
import { Button } from '../../atoms/Button';
import { BackToTop, Pencil, Save } from '../../atoms/Icons';
import { TopBody } from '../../molecules/TopBody';
import {
  BannerContent,
  CarouselContent,
  FaqContent,
  FeatureContent,
  HowToUseContent,
} from '../../molecules/TopBody/types';

import {
  FaqContent as FooterFaqContent,
  FooterContent,
} from '../../molecules/TopFooter/types';
import { TopFooter } from '../../molecules/TopFooter';
import { AddressContent } from '../../molecules/TopFooter/types';
import { TopHeader } from '../../molecules/TopHeader';
import { useQuery as getQuery } from './../../../utils/query';
import { useEffect } from 'react';
import { UpdatePageSection } from '../../../domain/entities/page';
import { Toast } from '../../atoms/Toast';
import { Props as INotification } from '../../atoms/Toast/Component';
import { MobileHeader } from '../../molecules/MobileHeader';
import { TopMobileBody } from '../../molecules/TopMobileBody';
import { TopMobileFooter } from '../../molecules/TopMobileFooter';
import { useGlobalState } from '../../../hooks/global';
import { Helmet } from 'react-helmet-async';
import queryKeys from '../../../constants/queryKeys';

const GlobalStyle = createGlobalStyle`
 div
 {
  &.ck.ck-editor__editable.ck-rounded-corners:not(.ck-editor__nested-editable) {
    border-radius: 5px;
  }
  &.ck.ck-editor__editable_inline {
    border: 1px solid #929292;
  }
}

.ck.ck-editor__editable_inline>:last-child {
  margin: 0;
  padding: .5em;
}
`;

const Container = styled.div``;

const ButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  padding: 0.5em;
  background: rgba(0, 0, 0, 0.17);
`;

const EditButton = styled(Button)<{ themeColor?: string; hoverColor?: string }>`
  width: 150px;
  font-style: normal;
  font-size: 20px;
  line-height: 29px;
  text-align: center;
  letter-spacing: -0.02em;
  border: none;
  background-color: ${({ themeColor }) => themeColor || theme.colors.lightBtn};

  :disabled {
    background-color: ${theme.colors.disabledLightSecondaryBtn};
    cursor: not-allowed;
  }

  :hover {
    border: none;
    background-color: ${({ hoverColor }) =>
      hoverColor || theme.colors.hoverLightBtn};
  }
`;

const StyledPencil = styled(Pencil)`
  height: 20px;
  width: 20px;
`;

const StyledKeep = styled(Save)`
  height: 20px;
  width: 20px;
`;

const BackToTopArrow = styled.div<{ isBottomOfPage?: boolean }>`
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: 5px;
  right: 5px;
  display: none;
  border-radius: 50%;
  justify-content: center;
  align-items: center;

  ${({ isBottomOfPage }) =>
    isBottomOfPage
      ? `bottom: 60px;  transition: bottom 0.25s ease-in`
      : 'transition: bottom 0.15s ease-out'};

  @media ${theme.breakpoints.mobile} {
    display: flex;
  }
`;

type TopPageSections =
  | 'Header'
  | 'Banner'
  | 'Feature'
  | 'How To Use'
  | 'Client'
  | 'Faq'
  | 'Footer';

export type Props = {};

const Component = ({}: Props): React.ReactElement => {
  const token = getQuery('token') as string;
  const { useFetchPlans } = usePlanHooks();
  const { fetchPlans } = useFetchPlans();
  const { useFetchPage, useVerifyAdmin, useUpdatePageSection } = usePageHooks();
  const { fetchPage } = useFetchPage();
  const { verifyAdmin } = useVerifyAdmin();
  const { updatePageSection } = useUpdatePageSection();
  const navigate = useNavigate();
  const [notification, setNotification] = useState<INotification | null>(null);
  const [isUploadingMedia, setIsUploadingMedia] = useState<boolean>(false);
  const [topPagePayload, setTopPagePayload] = useState<
    | {
        pageId: number;
        data: UpdatePageSection;
      }
    | Object
  >({});

  const [serverErrors, setServerErrors] = useState<any>({});
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isBottomOfPage, setIsBottomOfPage] = useState<boolean>(false);

  const { useCurrentUser } = useGlobalState();
  const { currentUser } = useCurrentUser;

  const { data: getDataPlans, isFetching: isFetchingDetails } = useQuery(
    [queryKeys.FETCH_PLAN_LIST],
    () => {
      return fetchPlans();
    },
  );

  const {
    data: getPageData,
    isFetching: isFetchingPageDetails,
    refetch: refetchTopPage,
  } = useQuery([queryKeys.FETCH_PAGE], () => {
    return fetchPage('TOP_PAGE');
  });

  const { data: verifiedAdmin } = useQuery(
    [queryKeys.VERIFY_ADMIN, token],
    () => {
      return verifyAdmin(token);
    },
    {
      onError: (res: any) => {
        setNotification({
          isOpen: true,
          message: String(res.response.data.message),
          type: 'error',
          position: 'top-right',
          onClose: () => setNotification(null),
        });
      },
      enabled: !!token,
    },
  );

  const {
    mutate: updatePageSectionMutation,
    isLoading: isUpdatingPageSection,
  } = useMutation(
    async ({ pageId, data }: { pageId: number; data: UpdatePageSection }) => {
      return updatePageSection(pageId, data);
    },
    {
      onSuccess: response => {
        refetchTopPage();
        setNotification({
          isOpen: true,
          message: String((response as any)?.data.message),
          type: 'success',
          position: 'top-right',
          onClose: () => setNotification(null),
        });

        setServerErrors({});
        setIsEditing(false);
      },
      onError: (response: any) => {
        setIsEditing(true);
        const res = response.response.data;

        if (res.status_code === 422) {
          setServerErrors(res.errors);
        }

        if (res.status_code === 500) {
          setNotification({
            isOpen: true,
            message: String(response)
              .replace('Error:', '')
              .trim(),
            type: 'error',
            position: 'top-right',
            onClose: () => setNotification(null),
          });
        }
      },
    },
  );

  useEffect(() => {
    if (!isFetchingPageDetails && !Object.keys(topPagePayload).length) {
      setTopPagePayload({
        pageId: getPageData?.id,
        data: {
          header: getPageData?.sections[0]?.header?.content,
          banner: getPageData?.sections[1]?.banner?.content,
          feature: getPageData?.sections[2]?.feature?.content,
          how_to_use: {
            item: getPageData?.sections[3]?.how_to_use?.content?.item,
            media: getPageData?.sections[3]?.how_to_use?.content?.media,
          },
          client_review: getPageData?.sections[4]?.client_review?.content,
          faq: {
            image: getPageData?.sections[5]?.faq?.content?.image,
            item: getPageData?.sections[5]?.faq?.content?.item,
          },
          footer: {
            plan: getPageData?.sections[6]?.footer?.content?.plan,
            frequently_used_links:
              getPageData?.sections[6]?.footer?.content?.frequently_used_links,
            company_overview:
              getPageData?.sections[6]?.footer?.content?.company_overview,
            privacy_policy:
              getPageData?.sections[6]?.footer?.content?.privacy_policy,
            terms_of_service:
              getPageData?.sections[6]?.footer?.content?.terms_of_service,
            transaction_law:
              getPageData?.sections[6]?.footer?.content?.transaction_law,
            company_address:
              getPageData?.sections[6]?.footer?.content?.company_address,
            tel: getPageData?.sections[6]?.footer?.content?.tel,
            fax: getPageData?.sections[6]?.footer?.content?.fax,
          },
        },
      });
    }
  }, [isFetchingPageDetails]);

  const handleChangeData = (data?: any, sections?: TopPageSections) => {
    let payload =
      (topPagePayload as {
        id: number;
        data: UpdatePageSection;
      })?.data || {};

    switch (sections) {
      case 'Header':
        payload = { ...payload, header: data };
        break;
      case 'Banner':
        payload = { ...payload, banner: data };
        break;
      case 'Feature':
        payload = { ...payload, feature: data.cards };
        break;
      case 'How To Use':
        payload = {
          ...payload,
          how_to_use: { item: data.item, media: data.media },
        };
        break;
      case 'Client':
        payload = {
          ...payload,
          client_review: data.contents,
        };
        break;
      case 'Faq':
        payload = {
          ...payload,
          faq: { image: data.image, item: data.item },
        };
        break;
      case 'Footer':
        if (data?.footerContent) {
          payload = {
            ...payload,
            footer: {
              ...payload.footer,
              plan: {
                title: data.footerContent[0].title,
                item: data.footerContent[0].item,
              },
              frequently_used_links: {
                title: data.footerContent[1].title,
                item: data.footerContent[1].item,
              },
              company_overview: {
                title: data.footerContent[2].title,
                item: data.footerContent[2].item,
              },
            },
          };
        }

        if (data?.footerFaqContent) {
          payload = {
            ...payload,
            footer: {
              ...payload.footer,
              privacy_policy: data.footerFaqContent.privacyPolicy,
              terms_of_service: data.footerFaqContent.termsOfService,
              transaction_law: data.footerFaqContent.transactionLaw,
            },
          };
        }

        if (data?.addressContent) {
          payload = {
            ...payload,
            footer: {
              ...(payload as any)?.footer,
              company_address: data.addressContent.address,
              tel: data.addressContent.telephoneNumber,
              fax: data.addressContent.faxNumber,
            },
          };
        }
        break;
    }

    setTopPagePayload({ ...topPagePayload, data: payload });
  };

  const onPressPlan = (plan: string) => {
    if (currentUser?.user.isVerified) {
      navigate(`/my-board/plan-billing`);
    } else {
      navigate(`/signup`);
    }
  };

  const handleScroll = () => {
    setIsBottomOfPage(false);

    const bottom =
      Math.ceil(window.innerHeight + window.scrollY) >=
      document.documentElement.scrollHeight;

    if (bottom) {
      setIsBottomOfPage(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Container>
      <GlobalStyle />
      <Helmet defer={false} prioritizeSeoTags>
        <title>{getPageData?.pageTitle || 'IDEAMAN/アイデアマン'}</title>
        <meta
          name="description"
          content={getPageData?.pageDescription || 'IDEAMAN/アイデアマン'}
        />
        <meta
          name="keywords"
          content={getPageData?.pageKeyword || 'IDEAMAN/アイデアマン'}
        />
        <link rel="canonical" href="/" />
      </Helmet>
      {verifiedAdmin?.isAdmin && (
        <>
          <ButtonWrapper>
            <EditButton
              disabled={isUploadingMedia || isUpdatingPageSection}
              onPress={() => {
                if (!isEditing) {
                  setIsEditing(true);
                }

                if (isEditing) {
                  const payload = topPagePayload as {
                    pageId: number;
                    data: UpdatePageSection;
                  };
                  updatePageSectionMutation(payload);
                }
              }}
              iconMargin={10}
              icon={isEditing ? <StyledKeep /> : <StyledPencil />}
              title={isEditing ? '保存' : '編集'}
              themeColor={
                isEditing
                  ? theme.colors.notifToastBorder
                  : theme.colors.lightBtn
              }
              hoverColor={
                isEditing
                  ? theme.colors.hoverLightSecondaryBtn
                  : theme.colors.hoverLightBtn
              }
            />
          </ButtonWrapper>
        </>
      )}
      <MobileHeader
        links={getPageData?.sections[0]?.header?.content}
        isFetching={isFetchingPageDetails}
      />
      <TopHeader
        serverErrors={serverErrors}
        isFetching={isFetchingPageDetails}
        links={(topPagePayload as { data: UpdatePageSection })?.data?.header}
        onPressMemberLogin={() => {
          if (!isEditing) navigate('/signin');
        }}
        onPressRegistration={() => {
          if (!isEditing) navigate('/signup');
        }}
        allowEditContent={isEditing}
        onChangeData={(links: any[]) => handleChangeData(links, 'Header')}
      />
      <TopMobileBody
        isFetching={isFetchingPageDetails}
        bannerContent={getPageData?.sections[1]?.banner?.content}
        featureContent={{
          title: getPageData?.sections[2]?.title,
          cards: getPageData?.sections[2]?.feature?.content,
        }}
        howToUseContent={{
          title: getPageData?.sections[3]?.title,
          item: getPageData?.sections[3]?.how_to_use?.content?.item,
          media: getPageData?.sections[3]?.how_to_use?.content?.media,
        }}
        carouselContent={{
          title: getPageData?.sections[4]?.title,
          contents: getPageData?.sections[4]?.client_review?.content,
        }}
        faqContent={{
          title: getPageData?.sections[5]?.title,
          image: getPageData?.sections[5]?.faq?.content?.image,
          item: getPageData?.sections[5]?.faq.content?.item,
        }}
        planContent={getDataPlans}
        onPressPlan={onPressPlan}
        onPressRegistration={() => navigate('/signup')}
      />
      <TopBody
        serverErrors={serverErrors}
        onUploadMedia={uploading => setIsUploadingMedia(uploading)}
        onChangeBannerData={(data: BannerContent) =>
          handleChangeData(data, 'Banner')
        }
        onChangeFeatureData={(data: FeatureContent) =>
          handleChangeData(data, 'Feature')
        }
        onChangeHowToUseData={(data: HowToUseContent) =>
          handleChangeData(data, 'How To Use')
        }
        onChangeCarouselData={(data: CarouselContent) =>
          handleChangeData(data, 'Client')
        }
        onChangeFaqData={(data: FaqContent) => handleChangeData(data, 'Faq')}
        allowEditContent={isEditing}
        onPressPlan={onPressPlan}
        isFetching={isFetchingDetails}
        planContent={getDataPlans}
        faqContent={{
          title: getPageData?.sections[5]?.title,
          image: (topPagePayload as { data: UpdatePageSection })?.data?.faq
            .image,
          item: (topPagePayload as { data: UpdatePageSection })?.data?.faq.item,
        }}
        carouselContent={{
          title: getPageData?.sections[4]?.title,
          contents: (topPagePayload as { data: UpdatePageSection })?.data
            ?.client_review as any,
        }}
        howToUseContent={{
          title: getPageData?.sections[3]?.title,
          item: (topPagePayload as { data: UpdatePageSection })?.data
            ?.how_to_use.item,
          media: (topPagePayload as { data: UpdatePageSection })?.data
            ?.how_to_use.media,
        }}
        featureContent={{
          title: getPageData?.sections[2]?.title,
          cards: getPageData?.sections[2]?.feature?.content,
        }}
        bannerContent={
          (topPagePayload as { data: UpdatePageSection })?.data?.banner as any
        }
        onPressRegistration={() => {
          if (!isEditing) navigate('/signup');
        }}
      />
      <TopMobileFooter
        onPressRegistration={() => navigate('/signup')}
        faqsContents={{
          privacyPolicy:
            getPageData?.sections[6]?.footer?.content?.privacy_policy,
          termsOfService:
            getPageData?.sections[6]?.footer?.content?.terms_of_service,
          transactionLaw:
            getPageData?.sections[6]?.footer?.content?.transaction_law,
        }}
        addressContents={{
          address: getPageData?.sections[6]?.footer?.content?.company_address,
          telephoneNumber: getPageData?.sections[6]?.footer?.content?.tel,
          faxNumber: getPageData?.sections[6]?.footer?.content?.fax,
        }}
        footerContents={[
          getPageData?.sections[6]?.footer?.content?.plan,
          getPageData?.sections[6]?.footer?.content?.frequently_used_links,
          getPageData?.sections[6]?.footer?.content?.company_overview,
        ]}
        isFetching={isFetchingPageDetails}
      />
      <TopFooter
        serverErrors={serverErrors}
        onChangeAddressContent={(data: AddressContent) =>
          handleChangeData({ addressContent: data }, 'Footer')
        }
        onChangeFaqContent={(data: FooterFaqContent) =>
          handleChangeData({ footerFaqContent: data }, 'Footer')
        }
        onChangeFooterContent={(data: FooterContent) =>
          handleChangeData({ footerContent: data }, 'Footer')
        }
        allowEditContent={isEditing}
        onPressRegistration={() => {
          if (!isEditing) navigate('/signup');
        }}
        faqsContents={{
          privacyPolicy:
            getPageData?.sections[6]?.footer?.content?.privacy_policy,
          termsOfService:
            getPageData?.sections[6]?.footer?.content?.terms_of_service,
          transactionLaw:
            getPageData?.sections[6]?.footer?.content?.transaction_law,
        }}
        addressContents={{
          address: getPageData?.sections[6]?.footer?.content?.company_address,
          telephoneNumber: getPageData?.sections[6]?.footer?.content?.tel,
          faxNumber: getPageData?.sections[6]?.footer?.content?.fax,
        }}
        footerContents={[
          getPageData?.sections[6]?.footer?.content?.plan,
          getPageData?.sections[6]?.footer?.content?.frequently_used_links,
          getPageData?.sections[6]?.footer?.content?.company_overview,
        ]}
        isFetching={isFetchingPageDetails}
      />
      <BackToTopArrow
        isBottomOfPage={isBottomOfPage}
        onClick={() => window.scrollTo(0, 0)}>
        <BackToTop />
      </BackToTopArrow>
      {notification ? <Toast {...notification} /> : null}
    </Container>
  );
};

export default Component;
