import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import { MentionsInput, Mention as ReactMention } from 'react-mentions';
import theme from '../../../constants/themes';
import { mentionStyle, mentionsInputStyle } from './styles';
import { PaperPlane, Mention } from '../Icons';
import { BoardCustomers } from '../../../domain/entities/board';
import { handleTextOverflow } from '../../../utils/strings';
import { Tooltip } from '../Tooltip';
const StyledContainer = styled(Box)`
  background-color: ${theme.colors.primary};
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.25);
  padding: 5px;
  width: 310px;
  border-radius: ${(props: { $isActive: boolean }) =>
    props.$isActive ? '0px 0px 32px 32px' : '32px'};
`;

const StyledWrapper = styled(Box)`
  display: flex;
  background-color: ${theme.colors.white};
  align-items: center;
  border-radius: ${(props: { $isActive: boolean }) =>
    props.$isActive ? '0px 0px 32px 32px' : '32px'};
`;

const TextAreaWrapper = styled(Box)`
  flex: 1;
  padding: 15px 0px;
  display: flex;
  justify-content: center;
`;

const IconWrapper = styled(Box)`
  display: flex;
  align-self: center;
  margin-right: 10px;
  border-radius: 100px;
  cursor: pointer;
  &:active {
    box-shadow: 1px 2px 3px rgb(133 131 131 / 25%);
  }
`;

const MentionWrapper = styled(Box)`
  margin-left: 15px;
  display: flex;
  position: relative;
  height: 30px;
  width: 30px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50px;
`;

const MentionCount = styled.span`
  font-family: ${theme.fonts.primary};
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 19px;
  color: ${theme.colors.notifToastBorder};
`;

const StyledMentionsInput = styled(MentionsInput)`
  textarea {
    &:focus-visible {
      outline: none;
    }
    &::placeholder {
      color: ${theme.colors.black};
      text-align: center;
    }
  }
`;

const MentionInputWrapper = styled.div`
  max-height: 100px;
  min-height: 15px;
  overflow: auto;
`;

const SuggestionBox = styled.div`
  width: 200px;
  overflow: hidden;
`;

type SuggestionDataItem = {
  id: string | number;
  display?: string;
};

type Props = {
  onFocus: (focus: boolean) => void;
  onSubmit: (text: string, ids: (string | number)[]) => void;
  onClickMention?: () => void;
  mentionCount?: number;
  isActive?: boolean;
  isLoading?: boolean;
  customers: BoardCustomers[];
  message: string;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
};

const Component = ({
  onFocus,
  onSubmit,
  onClickMention = () => {},
  mentionCount = 0,
  isActive = false,
  isLoading = false,
  customers,
  message,
  setMessage,
}: Props): React.ReactElement => {
  const [isFocus, setFocus] = useState<boolean>(false);
  const suggestionsPortalRef = useRef<HTMLDivElement>(null);

  const handleOnSubmit = () => {
    if (!isLoading && message.length <= 255) {
      const mentionMarkUps = message.match(/@__(\d+)_@@_(.*?)__@/g);
      const customerIds =
        mentionMarkUps?.map(item => {
          const markUp = item.split('_@@_')[0];
          const customerId = markUp.split('@__')[1];
          return customerId;
        }) || [];

      onSubmit(message, customerIds);
      setTimeout(() => {
        setMessage('');
      }, 5);
    }
  };

  const handleOnFocus = () => {
    onFocus(!isFocus);
    setFocus(!isFocus);
  };

  const handleOnChange = (text: { target: { value: string } }) => {
    setMessage(text.target.value);
  };

  const handleKeyPress = (e: KeyboardEvent) => {
    e.code === 'Enter' && e.shiftKey == false && !isLoading && handleOnSubmit();
  };

  const displayTransform = (_: string, display: string) => `@${display} `;

  const mappedCustomers =
    customers?.length > 0
      ? customers.map((customer: BoardCustomers) => ({
          id: customer.id,
          display: customer.name.split(' ').join(''),
        }))
      : [];

  return (
    <StyledContainer $isActive={isActive}>
      <StyledWrapper $isActive={isActive}>
        <MentionWrapper onClick={onClickMention}>
          <Mention
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
            }}
          />
          <MentionCount>{mentionCount}</MentionCount>
        </MentionWrapper>
        <TextAreaWrapper>
          <MentionInputWrapper ref={suggestionsPortalRef}>
            <StyledMentionsInput
              onKeyDown={handleKeyPress}
              onFocus={handleOnFocus}
              onBlur={handleOnFocus}
              value={message}
              style={mentionsInputStyle}
              onChange={handleOnChange}
              forceSuggestionsAboveCursor={true}
              suggestionsPortalHost={suggestionsPortalRef.current}
              placeholder={isFocus ? '' : 'MESSAGE'}>
              <ReactMention
                markup="@____id___@@___display____@ "
                trigger="@"
                data={mappedCustomers}
                displayTransform={displayTransform}
                style={mentionStyle}
                renderSuggestion={(suggestion: SuggestionDataItem) => {
                  return suggestion.display &&
                    suggestion.display?.length <= 23 ? (
                    <SuggestionBox>{suggestion.display}</SuggestionBox>
                  ) : (
                    <Tooltip label={String(suggestion.display)}>
                      <SuggestionBox>
                        {handleTextOverflow(String(suggestion.display), 23)}
                      </SuggestionBox>
                    </Tooltip>
                  );
                }}
              />
            </StyledMentionsInput>
          </MentionInputWrapper>
        </TextAreaWrapper>
        <IconWrapper onClick={handleOnSubmit}>
          <PaperPlane />
        </IconWrapper>
      </StyledWrapper>
    </StyledContainer>
  );
};

export default Component;
