import { AxiosResponse } from 'axios';
import { Formik, FormikProps } from 'formik';
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import styled from 'styled-components';
import DefaultUser from '../../../assets/images/default-user.png';
import queryKeys from '../../../constants/queryKeys';
import theme from '../../../constants/themes';
import { useAuthHooks } from '../../../hooks/auth';
import { useGlobalState } from '../../../hooks/global';
import { removeUndefined } from '../../../utils/objects';
import { Toast } from '../../atoms/Toast';
import { Props as INotification } from '../../atoms/Toast/Component';
import { ProfileForm } from '../../organisms/ProfileForm';
import {
  DefaultProfileSchema,
  EditingPasswordProfileSchema,
} from './validation';

const Container = styled.div``;

const TabHeader = styled.div`
  display: inline-flex;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  letter-spacing: -0.02em;
  color: ${theme.colors.textColor};
  border-bottom: solid ${theme.colors.notifToastBorder} 3px;
  margin-bottom: 11px;
`;
export type Props = {
  className?: string;
};

const SNS_MAPPER: { [key: string]: 'facebook' | 'google' | 'microsoft' } = {
  '1': 'facebook',
  '2': 'google',
  '3': 'microsoft',
};

const Component = ({ className }: Props): React.ReactElement => {
  const formikRef = useRef<FormikProps<any>>(null);
  const { t } = useTranslation();
  const { useCurrentUser } = useGlobalState();
  const queryClient = useQueryClient();
  const {
    useUpdateUser,
    useFetchUser,
    useLogin,
    useDeleteUser,
  } = useAuthHooks();
  const { login } = useLogin();
  const { updateUserDetails } = useUpdateUser();
  const { fetchUserDetails } = useFetchUser();
  const { deleteUserDetails } = useDeleteUser();
  const [notification, setNotification] = useState<INotification | null>(null);
  const { currentUser, setCurrentUser } = useCurrentUser;
  const customerId = currentUser?.user?.customer?.id;
  const accountLink = currentUser?.user?.customer?.account_link;
  const { data: userDetails, isFetching: isFetchingDetails } = useQuery(
    [queryKeys.FETCH_USER_DETAILS, customerId],
    () => {
      return fetchUserDetails();
    },
    {
      onSuccess: customerData => {
        if (customerData) {
          setCurrentUser({
            ...currentUser,
            user: {
              ...currentUser?.user,
              customer: {
                ...currentUser?.user.customer,
                ...customerData,
              },
            },
          });
        }
      },
    },
  );
  const initialValues = {
    email: userDetails?.email || '',
    name: userDetails?.fullname || userDetails?.full_name,
    plan: userDetails?.plan?.type,
    oldPassword: '',
    newPassword: '',
    renewedPassword: '',
    image: currentUser?.user?.customer?.avatar || DefaultUser,
  };
  const [serverErrors, setServerErrors] = useState<{
    name?: string;
    oldPassword?: string;
    newPassword?: string;
    renewedPassword?: string;
  }>({});
  const [isEditingPassword, setIsEditingPassword] = useState<boolean>(false);

  const handleIsEditingPassword = (editing: boolean) => {
    if (!editing) {
      formikRef.current?.resetForm();
    }
    setIsEditingPassword(editing);
  };

  const { mutate: reauthenticate } = useMutation(
    (payload: { email: string; password: string; remember: boolean }) =>
      login(payload.email, payload.password, payload.remember, ''),
    {
      onSuccess: res => {
        if (res) {
          setCurrentUser(res);
        }
        queryClient.invalidateQueries([
          queryKeys.FETCH_USER_DETAILS,
          customerId,
        ]);
      },
    },
  );

  const { mutate: updateUser, isLoading: isUpdatingUser } = useMutation(
    (payload: {
      id: number;
      name?: string | undefined;
      displayPhoto?: File | undefined;
      oldPassword?: string;
      newPassword?: string;
      confirmPassword?: string;
      email?: string;
    }) => updateUserDetails(payload),
    {
      onSuccess: (response, request) => {
        const shouldReauth =
          request.oldPassword && request.newPassword && request.oldPassword;
        if (shouldReauth && request.newPassword && initialValues.email) {
          reauthenticate({
            email: initialValues.email,
            password: request.newPassword,
            remember: true,
          });
        } else {
          queryClient.invalidateQueries([
            queryKeys.FETCH_USER_DETAILS,
            customerId,
          ]);
        }
        setNotification({
          isOpen: true,
          message: response.message || '',
          type: 'success',
          position: 'top-right',
          onClose: () => setNotification(null),
        });
        setIsEditingPassword(false);
        formikRef.current?.resetForm();
      },
      onError: (error: { response: AxiosResponse }) => {
        const res: AxiosResponse = error.response;

        let errorMessage = '';
        const data = res.data as any;
        Object.keys(data.errors).forEach(key => {
          errorMessage += data.errors[key];
        });

        setNotification({
          isOpen: true,
          message: String(errorMessage),
          type: 'error',
          position: 'top-right',
          onClose: () => setNotification(null),
        });
        setServerErrors({
          ...serverErrors,
          ...removeUndefined({
            name: data?.errors?.fullname,
            oldPassword: data?.errors?.old_password,
            newPassword: data?.errors?.password,
            renewedPassword: data?.errors?.password_confirmation,
          }),
        });
      },
    },
  );

  const handleSubmitProfile = useCallback(
    (items: typeof initialValues) => {
      if (customerId) {
        const shouldUpdatePassword =
          items.oldPassword && items.newPassword && items.renewedPassword;
        updateUser({
          id: customerId,
          name: items.name,
          displayPhoto:
            typeof items.image === 'string' ? undefined : items.image,
          oldPassword: shouldUpdatePassword ? items.oldPassword : undefined,
          newPassword: shouldUpdatePassword ? items.newPassword : undefined,
          confirmPassword: shouldUpdatePassword
            ? items.renewedPassword
            : undefined,
          email: !currentUser.user.customer.email ? items.email : undefined,
        });
      }
    },
    [customerId],
  );

  const { mutate: deleteUser } = useMutation(
    (customer_id: number) => deleteUserDetails({ id: customer_id }),
    {
      onSuccess: res => {
        setNotification({
          isOpen: true,
          message: res.message || '',
          type: 'success',
          position: 'top-right',
          onClose: () => setNotification(null),
        });
        setTimeout(() => {
          localStorage.clear();
          setCurrentUser(undefined);
        }, 1000);
      },
    },
  );

  const onCustomerDelete = () => {
    deleteUser(customerId as number);
  };

  const handleChangeImageAvatar = (
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean,
    ) => void,
    file: File,
  ) => {
    if (!file) return;

    const url = window.URL || window.webkitURL;
    const image = new Image();
    image.src = url.createObjectURL(file);

    image.onload = function() {
      setFieldValue('image', file);
    };
    image.onerror = () => {
      setNotification({
        isOpen: true,
        message: ' アバターには画像ファイルを指定してください。',
        type: 'error',
        position: 'top-right',
        onClose: () => setNotification(null),
      });
    };
  };

  return (
    <Container className={className}>
      <TabHeader>{t('profile')}</TabHeader>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={
          isEditingPassword
            ? EditingPasswordProfileSchema
            : DefaultProfileSchema
        }
        onSubmit={handleSubmitProfile}
        enableReinitialize>
        {({
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
          values,
          isValid,
          errors,
          touched,
          setFieldTouched,
        }): React.ReactElement => {
          const combinedErrors = { ...serverErrors, ...errors };

          return (
            <ProfileForm
              errors={combinedErrors}
              touched={touched}
              name={values.name}
              email={values.email}
              plan={values.plan}
              oldPassword={values.oldPassword}
              newPassword={values.newPassword}
              renewedPassword={values.renewedPassword}
              onChangeName={handleChange('name')}
              onChangeEmail={handleChange('email')}
              onChangeOldPassword={(value: string) => {
                setFieldValue('oldPassword', value);
                setServerErrors({ ...serverErrors, oldPassword: '' });
              }}
              onChangeNewPassword={(value: string) => {
                setFieldValue('newPassword', value);
                setServerErrors({ ...serverErrors, newPassword: '' });
              }}
              onChangeRenewedPassword={(value: string) => {
                setFieldValue('renewedPassword', value);
                setServerErrors({ ...serverErrors, renewedPassword: '' });
              }}
              onChangeNameBlur={handleBlur('name')}
              onChangeEmailBlur={handleBlur('email')}
              onOldPasswordBlur={handleBlur('oldPassword')}
              onNewPasswordBlur={handleBlur('newPassword')}
              onRenewedPasswordBlur={handleBlur('renewedPassword')}
              image={values.image}
              onChangeImage={file =>
                handleChangeImageAvatar(setFieldValue, file)
              }
              onPressSubmit={handleSubmit}
              onPressDelete={onCustomerDelete}
              editingPasswordState={[
                isEditingPassword,
                handleIsEditingPassword,
              ]}
              disabled={!isValid || isFetchingDetails || isUpdatingUser}
              isFetchingDetails={isFetchingDetails}
              sns={
                typeof accountLink !== 'undefined'
                  ? SNS_MAPPER[accountLink]
                  : undefined
              }
              hasInitialEmail={!userDetails?.email}
              setFieldTouched={setFieldTouched}
              isUpdating={isUpdatingUser}
            />
          );
        }}
      </Formik>
      {notification ? <Toast {...notification} /> : null}
    </Container>
  );
};

export default Component;
