import TeamRepository from '../../ports/TeamRepository';
import { CreateTeamSuccess } from '../../../domain/entities/team';

export type createTeamUseCase = (
  teamName: string,
) => Promise<CreateTeamSuccess>;

export const buildCreateTeam = (dependencies: {
  teamRepo: TeamRepository;
}): createTeamUseCase => {
  const { teamRepo } = dependencies;

  const createTeam: createTeamUseCase = teamName =>
    teamRepo.createTeam(teamName);

  return createTeam;
};
