import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider, setLogger } from 'react-query';
import { GoogleOAuthProvider } from '@react-oauth/google';
import RootNavigator from './navigators/root/RootNavigator';
import * as serviceWorker from './serviceWorker';
import Hooks from './hooks';
import './constants/words';
import './index.css';
import './assets/fonts/ARLRDBD.ttf';
import './assets/fonts/Corporate-Logo-Medium-ver2.ttf';
import './assets/fonts/BergamoStd/BergamoStd.otf';
import './assets/fonts/Cousine/Cousine.ttf';
import './assets/fonts/Dustismo/Dustismo.ttf';
import './assets/fonts/GentiumBasic/GentiumBasic.ttf';
import './assets/fonts/Kirsty/Kirsty.ttf';
import './assets/fonts/KomikaHand/KomikaHand.ttf';
import './assets/fonts/LiberationMono/LiberationMono.ttf';
import './assets/fonts/LiberationSans/LiberationSans.ttf';
import './assets/fonts/LiberationSerif/LiberationSerif.ttf';
import './assets/fonts/Orbitron/Orbitron.ttf';
import './assets/fonts/SFArchRival/SFArchRival.ttf';
import './assets/fonts/SFCartoonistHand/SFCartoonistHand.ttf';
import './assets/fonts/SourceSansPro/SourceSansPro.ttf';

if (process.env.NODE_ENV !== 'development') {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
  console.warn = () => {};
}

setLogger({
  log: console.log,
  warn: console.log,
  error: () => {},
});

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider
      client={
        new QueryClient({
          defaultOptions: {
            queries: {
              retry: 0,
              suspense: false,
              refetchOnMount: false,
              refetchOnWindowFocus: false,
              refetchOnReconnect: false,
            },
          },
        })
      }>
      <GoogleOAuthProvider
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}>
        <Hooks>
          <RootNavigator />
        </Hooks>
      </GoogleOAuthProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
