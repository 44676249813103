import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Header } from '../../molecules/Header';
import { MyBoardListTemplate } from '../../templates/MyBoardList';

export type Props = {};

const Component = ({}: Props): React.ReactElement => {
  const location = useLocation();
  const { teamId } = useParams();
  const navigate = useNavigate();

  const getActiveItem = () => {
    if (location.pathname.includes('/my-board/team')) {
      return 'team';
    } else if (
      location.pathname === '/my-board' ||
      location.pathname === '/my-board/personal'
    ) {
      return 'home';
    } else if (location.pathname.includes('/profile')) {
      return 'profile';
    }

    return 'plan-billing';
  };

  return (
    <div>
      <Header />
      <MyBoardListTemplate
        activeItem={getActiveItem()}
        teamId={teamId}
        onPressBoardCard={(boardId, boardCode) => {
          navigate(`/board/${boardCode}`, { state: { teamId } });
        }}
      />
    </div>
  );
};

export default Component;
