const API_URL = process.env.REACT_APP_API_URL || '';

const urls = {
  todo: { getTodos: `${API_URL}todos` },
  board: {
    createBoard: `${API_URL}board`,
    updateBoard: (boardCode: string): string => `${API_URL}board/${boardCode}`,
    saveBoard: `${API_URL}board/save`,
    getCustomer: `${API_URL}board/customer`,
    getCustomers: `${API_URL}customers_in_board`,
    getBoardByCode: `${API_URL}board`,
    fetchBoards: `${API_URL}boards`,
    inviteCustomers: `${API_URL}board/invite`,
    removeCustomer: (customerId: string): string =>
      `${API_URL}board/customer/${customerId}`,
    updateCustomerRole: (boardCode: string): string =>
      `${API_URL}board/customer/${boardCode}`,
    fetchMedia: (customerId: string): string =>
      `${API_URL}upload/${customerId}`,
    uploadMedia: `${API_URL}upload`,
    setBoardFavorite: `${API_URL}board/favorite`,
    removeBoardFavorite: (code: string): string =>
      `${API_URL}board/favorite/${code}`,
    fetchTemplateList: `${API_URL}template`,
    voteCustomer: `${API_URL}board/customer/vote`,
    acceptBoardInvitation: `${API_URL}customer/invited`,
  },
  chat: {
    getMessages: `${API_URL}message`,
    postMessage: `${API_URL}message`,
  },
  card: { getRandomCard: `${API_URL}random_card` },
  auth: {
    login: `${API_URL}signin`,
    logout: `${API_URL}logout`,
    resendVerification: `${API_URL}verify`,
    signup: `${API_URL}signup`,
    fetchUser: `${API_URL}customer`,
    updateUser: (customerId: number): string =>
      `${API_URL}customer/${customerId}`,
    deleteUser: (customerId: number): string =>
      `${API_URL}customer/${customerId}`,
    forgotPassword: `${API_URL}customer/forgot-password`,
    resetPassword: `${API_URL}customer/reset-password`,
  },
  team: {
    fetchTeams: `${API_URL}team`,
    createTeam: `${API_URL}team`,
    fetchTeamMembers: (teamId: number): string =>
      `${API_URL}teams/${teamId}/members`,
    inviteTeamMember: `${API_URL}team/invite`,
    deleteTeamMember: (teamId: string, customerId: string): string =>
      `${API_URL}teams/${teamId}/members/${customerId}`,
    updateTeamMember: `${API_URL}team/member`,
  },
  role: {
    fetchRoles: `${API_URL}role`,
  },
  plans: {
    fetchPlans: `${API_URL}plan`,
    selectFreePlan: (planId: number): string => `${API_URL}plan/${planId}`,
  },
  page: {
    fetchPage: `${API_URL}page`,
    verifyAdmin: `${API_URL}verify/admin`,
    uploadMedia: `${API_URL}page/upload`,
    updatePageSection: (pageId: number): string =>
      `${API_URL}page/${pageId}/section`,
  },
  payment: {
    choosePlan: `${API_URL}stripe/choose-plan`,
    fetchPaymentHistory: `${API_URL}payment-history`,
    fetchInvoicePdf: `${API_URL}invoice/export-pdf`,
    attachPayment: `${API_URL}stripe/attach-payment`,
    cancelSub: `${API_URL}stripe/cancel-subscription`,
  },
  microsoft: {
    profilePhoto: 'https://graph.microsoft.com/v1.0/me/photos/48x48/$value',
  },
};

export type URLsType = typeof urls;

export default urls;
