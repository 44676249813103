import AuthService from '../../ports/AuthService';

export type microsoftSignUpUseCase = (
  email: string,
  name: string,
  accountId: string,
  invitationCode: string,
) => Promise<{ user: object | null }>;

export const buildMicrosoftSignUp = (dependencies: {
  authService: AuthService;
}): microsoftSignUpUseCase => {
  const { authService } = dependencies;

  const microsoftSignUp: microsoftSignUpUseCase = (
    email,
    name,
    accountId,
    invitationCode,
  ) => authService.signUpUsingMicrosoft(email, name, accountId, invitationCode);

  return microsoftSignUp;
};
