import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { Props as INotification } from '../../atoms/Toast/Component';
import { Toast } from '../../atoms/Toast';
import { Header } from '../../molecules/Header';
import { useTeamHooks } from '../../../hooks/team';
import { MemberManagement } from '../../templates/MemberManagement';
import queryKeys from '../../../constants/queryKeys';

const Component = (): React.ReactElement => {
  const location = useLocation();
  const { useTeam } = useTeamHooks();
  const { fetchMyBoardTeams } = useTeam();
  const [notification, setNotification] = useState<INotification | null>(null);

  const handleCloseToast = () => {
    setNotification(null);
  };

  const getActiveItem = () => {
    if (location.pathname.includes('/my-board/member-management')) {
      return 'team';
    }

    return 'invoice';
  };

  const { data: teamList = [], isFetching: isFetchingTeams } = useQuery(
    [queryKeys.FETCH_MY_BOARD_TEAMS],
    () => {
      return fetchMyBoardTeams();
    },
    {
      onError: (response: { response: AxiosResponse }) => {
        const res: AxiosResponse = response.response;
        const data = res.data as any;
        if (data?.status_code === 422) {
          let messages = '';
          Object.keys(data.errors).forEach(key => {
            messages += data.errors[key][0];
          });
          setNotification({
            isOpen: true,
            message: messages,
            type: 'error',
            position: 'top-right',
            onClose: handleCloseToast,
          });
        } else {
          setNotification({
            isOpen: true,
            message: data?.errors?.messages,
            type: 'error',
            position: 'top-right',
            onClose: handleCloseToast,
          });
        }
      },
    },
  );

  return (
    <>
      <Header />
      <MemberManagement
        teamList={teamList}
        isFetchingTeams={isFetchingTeams}
        activeItem={getActiveItem()}
      />
      {notification ? <Toast {...notification} /> : null}
    </>
  );
};

export default Component;
