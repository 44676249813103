import React from 'react';

type Props = {
  [key: string]: string | number | Function | object;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 20 18"
      {...props}>
      <defs>
        <radialGradient
          id="radial-gradient"
          cx="4.09"
          cy="-477.5"
          r="1.46"
          gradientTransform="translate(6.42 486.08)"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.01" stopColor="#fef0df" />
          <stop offset="0.1" stopColor="#feead6" />
          <stop offset="0.25" stopColor="#fedbbe" />
          <stop offset="0.44" stopColor="#fec396" />
          <stop offset="0.67" stopColor="#ffa05f" />
          <stop offset="0.92" stopColor="#ff7519" />
          <stop offset="1" stopColor="#ff6601" />
        </radialGradient>
      </defs>
      <rect fill="#ffffff" y="0.08" width="19.85" height="17.83" />
      <path d="M19.93,0H0V18H20V0Zm-.08.17c0,.16,0,17.5,0,17.66H.15c0-.16,0-17.5,0-17.66Z" />
      <path fill="#ff6601" d="M19.85,11.59S14.43,15.9,4.49,17.84H19.85Z" />
      <path
        fill="url(#radial-gradient)"
        d="M10.51,7.14A1.45,1.45,0,1,0,12,8.58,1.45,1.45,0,0,0,10.51,7.14Z"
      />
      <path d="M2.1,7.19H1.3V10h.79a1.44,1.44,0,0,0,1-.32,1.41,1.41,0,0,0,.51-1.07A1.38,1.38,0,0,0,2.1,7.19Zm.63,2.09A1,1,0,0,1,2,9.5H1.84V7.66H2a1,1,0,0,1,.74.22.94.94,0,0,1,.3.69A1,1,0,0,1,2.73,9.28Z" />
      <rect x="3.84" y="7.19" width="0.54" height="2.78" />
      <path d="M5.71,8.25c-.33-.12-.42-.2-.42-.35s.17-.3.4-.3a.56.56,0,0,1,.44.22l.28-.37a1.23,1.23,0,0,0-.82-.31.81.81,0,0,0-.86.8c0,.38.17.58.68.76a2.45,2.45,0,0,1,.38.16A.35.35,0,0,1,6,9.15a.4.4,0,0,1-.42.39.63.63,0,0,1-.59-.37l-.35.34a1.07,1.07,0,0,0,1,.52.9.9,0,0,0,1-.91C6.51,8.68,6.32,8.48,5.71,8.25Z" />
      <path d="M6.68,8.58A1.44,1.44,0,0,0,8.15,10a1.53,1.53,0,0,0,.67-.16V9.23a.83.83,0,0,1-.65.3.9.9,0,0,1-.93-1,.92.92,0,0,1,.91-1,.89.89,0,0,1,.67.31V7.3a1.27,1.27,0,0,0-.66-.18A1.47,1.47,0,0,0,6.68,8.58Z" />
      <polygon points="13.22 9.05 12.48 7.19 11.88 7.19 13.06 10.04 13.36 10.04 14.56 7.19 13.97 7.19 13.22 9.05" />
      <polygon points="14.8 9.97 16.34 9.97 16.34 9.5 15.35 9.5 15.35 8.75 16.3 8.75 16.3 8.28 15.35 8.28 15.35 7.66 16.34 7.66 16.34 7.19 14.8 7.19 14.8 9.97" />
      <path d="M18.49,8c0-.52-.36-.82-1-.82h-.8V10h.54V8.85h.07L18.06,10h.67L17.85,8.8A.75.75,0,0,0,18.49,8Zm-1.09.46h-.16V7.62h.17c.34,0,.52.15.52.42S17.75,8.47,17.4,8.47Z" />
      <path d="M18.87,7.39s0-.07-.09-.07H18.7v.24h.06V7.47l.06.09h.08l-.08-.1A.07.07,0,0,0,18.87,7.39Zm-.1,0h0V7.36h0s0,0,0,0S18.8,7.43,18.77,7.43Z" />
      <path d="M18.79,7.23a.21.21,0,0,0-.21.21.21.21,0,1,0,.42,0A.21.21,0,0,0,18.79,7.23Zm0,.39a.17.17,0,0,1-.17-.18.16.16,0,0,1,.17-.17.18.18,0,0,1,.17.17A.18.18,0,0,1,18.79,7.62Z" />
      <path d="M6.16,10.7l.72.76v-.72H7v1L6.26,11v.72h-.1Z" />
      <path d="M6.15,10.7v1h.12V11l.73.75v-1H6.87v.7l-.72-.76Zm0,0,.72.76v-.73H7v.94l-.73-.76v.73H6.17Z" />
      <path d="M7.49,10.74H8v.09H7.59v.28H8v.1H7.59v.36H8v.1H7.49Z" />
      <path d="M8,10.73H7.48v.95H8v-.12H7.6v-.34H8V11.1H7.6v-.26H8v-.11Zm0,0v.07H7.58v.3H8v.08H7.58v.38H8v.08H7.5v-.91Z" />
      <path d="M8.7,11.67H8.6v-.84H8.38v-.09h.55v.09H8.7Z" />
      <path d="M8.93,10.73H8.37v.11h.22v.84h.12v-.84h.23v-.11Zm0,0v.07H8.69v.84H8.61v-.84H8.39v-.07Z" />
      <path d="M9.62,11.43l.31-.74.3.74.24-.69h.11l-.35,1L9.93,11l-.31.75-.35-1h.11Z" />
      <path d="M9.92,10.69l-.3.72c0-.05-.23-.68-.23-.68H9.25l.37,1L9.93,11s.3.75.3.75l.37-1h-.14s-.22.63-.23.68l-.3-.75Zm-.29.75.3-.72s.3.74.3.74.24-.7.25-.71h.09s-.33.89-.34.94l-.3-.75-.31.75s-.33-.92-.34-.94h.09s.25.71.25.71Z" />
      <path d="M11.92,11.2a.49.49,0,0,1-1,0,.49.49,0,0,1,1,0Zm-.86,0a.38.38,0,1,0,.76,0,.38.38,0,0,0-.38-.38A.37.37,0,0,0,11.06,11.2Z" />
      <path d="M10.94,11.2a.5.5,0,0,0,.5.49.49.49,0,0,0,0-1A.5.5,0,0,0,10.94,11.2Zm0,0a.48.48,0,0,1,.48-.47.47.47,0,1,1,0,.94A.48.48,0,0,1,11,11.2Z" />
      <path d="M11.05,11.2a.39.39,0,0,0,.39.4.4.4,0,0,0,0-.79A.39.39,0,0,0,11.05,11.2Zm0,0a.37.37,0,0,1,.37-.37.38.38,0,0,1,0,.75A.37.37,0,0,1,11.07,11.2Z" />
      <path d="M12.48,11.67h-.11v-.93h.14c.17,0,.34,0,.34.25a.24.24,0,0,1-.24.26l.3.42h-.13l-.28-.41h0Zm0-.5h0c.11,0,.23,0,.23-.17s-.11-.17-.23-.17h0Z" />
      <path d="M12.37,10.73h0v.95h.13v-.41h0l.27.4h.16l-.3-.42a.26.26,0,0,0,.23-.27c0-.18-.12-.26-.35-.26Zm.14,0q.33,0,.33.24a.24.24,0,0,1-.23.25h0l.29.42h-.11l-.27-.41h0v.41h-.09v-.91Z" />
      <path d="M12.48,10.82h0v.36h0c.11,0,.24,0,.24-.18s-.12-.18-.24-.18Zm0,0c.12,0,.22,0,.22.16s-.11.16-.22.16h0v-.32Z" />
      <path d="M13.85,10.74H14l-.44.43.45.5h-.14l-.38-.43,0,0v.4h-.1v-.93h.1v.4Z" />
      <path d="M14,10.73h-.15l-.38.38v-.38h-.12v.95h.12v-.41l0,0,.37.42H14s-.44-.5-.46-.51l.45-.44Zm0,0-.42.42.44.49h-.12l-.37-.43h0l0,0s0,.38,0,.4h-.08v-.91h.08v.41l.41-.41Z" />
    </svg>
  );
};

export default Component;
