import React, { SVGProps } from 'react';

type Props = SVGProps<SVGSVGElement>;

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M10.5 6L13.5 0L16.5 6H13.973C13.797 9.195 12.763 12.074 10.739 14.218C8.498 16.59 5.104 18 0.5 18C0.367392 18 0.240215 17.9473 0.146447 17.8536C0.0526785 17.7598 0 17.6326 0 17.5C0 17.3674 0.0526785 17.2402 0.146447 17.1464C0.240215 17.0527 0.367392 17 0.5 17C4.895 17 8.001 15.66 10.011 13.532C11.827 11.609 12.797 8.992 12.971 6H10.5Z"
        fill="#282C33"
      />
    </svg>
  );
};

export default Component;
