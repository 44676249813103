import { Container } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import createDOMPurify from 'dompurify';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import InlineEditor from '@itsmitchyyy/ckeditor5-build-inline';
import UploadAdapter from '../../../utils/UploadAdapter';
import urls from '../../../constants/urls';

const StyledContainer = styled(Container)`
  margin-top: 5em;
  margin-bottom: 8em;
`;

const DOMPurify = createDOMPurify(window as any);

export type Props = {
  bodyContent: any;
  isFetching?: boolean;
  allowEditContent?: boolean;
  onChangeBodyContent?: (data: string) => void;
};

const Component = ({
  bodyContent,
  isFetching,
  allowEditContent,
  onChangeBodyContent,
}: Props): React.ReactElement => {
  return (
    <StyledContainer>
      {isFetching ? null : (
        <>
          {allowEditContent ? (
            <CKEditor
              id="body-content"
              editor={InlineEditor}
              config={{
                extraAllowedContent: 'div(*)',
                allowedContent: true,
              }}
              data={bodyContent}
              onReady={(editor: any) => {
                editor.plugins.get('FileRepository').createUploadAdapter = (
                  loader: any,
                ) => {
                  return new UploadAdapter(loader, urls.page.uploadMedia);
                };
              }}
              onChange={(event: any, editor: any) => {
                const data = editor.getData();

                if (onChangeBodyContent) {
                  onChangeBodyContent(data);
                }
              }}
            />
          ) : (
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(bodyContent),
              }}
            />
          )}
        </>
      )}
    </StyledContainer>
  );
};

export default Component;
