import { useCallback } from 'react';
import { useGlobalState } from '../global';
import { AuthHooks } from '.';
import { useDependencies } from '..';
import { Customer } from '../../domain/entities/user';
import { GenericResponse } from '../../domain/entities/rest';
import storageKeys from '../../constants/localStorage';
import { useSocketHooks } from '../../hooks/socket';
import { useQueryClient } from 'react-query';

export const useLogin: AuthHooks['useLogin'] = (): {
  login: (
    email: string,
    password: string,
    remember: boolean,
    invitationCode: string,
  ) => Promise<Object | null>;
  googleLogin: (
    email: string,
    name: string,
    googleId: string,
    invitationCode: string,
    avatar?: string,
  ) => Promise<Object | null>;
  fbLogin: (
    email: string,
    name: string,
    userID: string,
    invitationCode: string,
    avatar: string,
  ) => Promise<Object | null>;
  microsoftLogin: (
    email: string,
    name: string,
    accountId: string,
    invitationCode: string,
    avatar: string,
  ) => Promise<Object | null>;
  reauthenticate: (email: string, password: string) => Promise<void>;
} => {
  const { authInteractor, localStorageInteractor } = useDependencies();
  const { signIn, googleSignIn, fbSignIn, microsoftSignIn } = authInteractor;
  const { setItem } = localStorageInteractor;
  const {
    useCurrentUser: { setCurrentUser },
  } = useGlobalState();

  const login = useCallback(
    async (
      email: string,
      password: string,
      remember: boolean,
      invitationCode: string,
    ) => {
      const response = await signIn(email, password, remember, invitationCode);
      const saveToStorage = () => {
        setItem(storageKeys.USER_STORAGE_KEY, JSON.stringify(response));
        setCurrentUser(response);
      };

      remember ? saveToStorage() : setCurrentUser(response);

      return response;
    },
    [signIn],
  );

  const googleLogin = useCallback(
    async (
      email: string,
      name: string,
      googleId: string,
      invitationCode: string,
      avatar?: string,
    ) => {
      const response = await googleSignIn(
        email,
        name,
        googleId,
        invitationCode,
        avatar,
      );
      setCurrentUser(response);

      return response;
    },
    [],
  );

  const fbLogin = useCallback(
    async (
      email: string,
      name: string,
      userID: string,
      invitationCode: string,
      avatar: string,
    ) => {
      const response = await fbSignIn(
        email,
        name,
        userID,
        invitationCode,
        avatar,
      );
      setCurrentUser(response);

      return response;
    },
    [],
  );

  const microsoftLogin = useCallback(
    async (
      email: string,
      name: string,
      accountId: string,
      invitationCode: string,
      avatar: string,
    ) => {
      const response = await microsoftSignIn(
        email,
        name,
        accountId,
        invitationCode,
        avatar,
      );
      setCurrentUser(response);

      return response;
    },
    [],
  );

  const reauthenticate = useCallback(async () => {
    // TODO implement if need to refetch data upon login
    throw new Error();
  }, []);

  return { login, googleLogin, fbLogin, microsoftLogin, reauthenticate };
};

export const useLogout: AuthHooks['useLogout'] = (): {
  logout: () => Promise<void>;
} => {
  const { useSocket } = useSocketHooks();
  const queryClient = useQueryClient();

  const { authInteractor, localStorageInteractor } = useDependencies();
  const { signOut } = authInteractor;
  const { getItem, removeItem } = localStorageInteractor;
  const {
    useCurrentUser: { setCurrentUser },
  } = useGlobalState();
  const { disconnectSocket } = useSocket;

  const logout = useCallback(async () => {
    queryClient.clear();
    await signOut();
    setCurrentUser();
    if (!!getItem('user')) {
      removeItem('user');
    }
    if (!!getItem('cards')) {
      removeItem('cards');
    }
    if (!!getItem('cardIDs')) {
      removeItem('cardIDs');
    }
    if (!!getItem('active_card')) {
      removeItem('active_card');
    }
    disconnectSocket();
  }, [signOut]);

  return { logout };
};

export const useSignUp: AuthHooks['useSignUp'] = (): {
  signUpUser: (
    name: string,
    email: string,
    password: string,
    invitationCode: string,
  ) => Promise<Object | null>;
  signUpUsingGoogle: (
    email: string,
    name: string,
    googleId: string,
    invitationCode: string,
  ) => Promise<Object | null>;
  signUpUsingFb: (
    email: string,
    name: string,
    userID: string,
    invitationCode: string,
  ) => Promise<Object | null>;
  signUpUsingMicrosoft: (
    email: string,
    name: string,
    accountId: string,
    invitationCode: string,
  ) => Promise<Object | null>;
} => {
  const { authInteractor } = useDependencies();
  const { signUp, googleSignUp, fbSignUp, microsoftSignUp } = authInteractor;
  const {
    useCurrentUser: { setCurrentUser },
  } = useGlobalState();
  const signUpUser = useCallback(
    async (
      name: string,
      email: string,
      password: string,
      invitationCode: string,
    ) => {
      const response = await signUp(name, email, password, invitationCode);
      setCurrentUser({ ...response });

      return response;
    },
    [],
  );
  const signUpUsingGoogle = useCallback(
    async (
      email: string,
      name: string,
      googleId: string,
      invitationCode: string,
    ) => {
      const response = await googleSignUp(
        email,
        name,
        googleId,
        invitationCode,
      );
      setCurrentUser(response);

      return response;
    },
    [],
  );
  const signUpUsingFb = useCallback(
    async (email: string, name: string, userID: string, invitationCode) => {
      const response = await fbSignUp(email, name, userID, invitationCode);
      setCurrentUser(response);

      return response;
    },
    [],
  );
  const signUpUsingMicrosoft = useCallback(
    async (email: string, name: string, accountId: string, invitationCode) => {
      const response = await microsoftSignUp(
        email,
        name,
        accountId,
        invitationCode,
      );
      setCurrentUser(response);

      return response;
    },
    [],
  );

  return { signUpUser, signUpUsingGoogle, signUpUsingFb, signUpUsingMicrosoft };
};

export const useResendVerification: AuthHooks['useResendVerification'] = (): {
  resendVerification: (token: string) => Promise<object>;
} => {
  const { authInteractor } = useDependencies();
  const { resendVerification } = authInteractor;

  const resendEmailVerification = useCallback(async (token: string) => {
    return await resendVerification(token);
  }, []);

  return { resendVerification: resendEmailVerification };
};

export const useUpdateUser: AuthHooks['useUpdateUser'] = (): {
  updateUserDetails: (payload: {
    id: number;
    name?: string;
    displayPhoto?: File;
  }) => Promise<GenericResponse>;
} => {
  const { authInteractor } = useDependencies();
  const { updateUser } = authInteractor;

  const updateUserDetails = useCallback(
    async (payload: {
      id: number;
      name?: string;
      displayPhoto?: File;
      email?: string;
    }) => {
      const response = await updateUser(payload);
      return response;
    },
    [],
  );

  return { updateUserDetails };
};

export const useDeleteUser: AuthHooks['useDeleteUser'] = (): {
  deleteUserDetails: (payload: { id: number }) => Promise<GenericResponse>;
} => {
  const { authInteractor } = useDependencies();
  const { deleteUser } = authInteractor;

  const deleteUserDetails = useCallback(async (payload: { id: number }) => {
    const response = await deleteUser(payload);
    return response;
  }, []);

  return { deleteUserDetails };
};

export const useFetchUser: AuthHooks['useFetchUser'] = (): {
  fetchUserDetails: () => Promise<Customer | undefined>;
  fetchGoogleUserData: (
    accessToken: string,
  ) => Promise<{
    sub: string;
    name: string;
    picture: string;
    email: string;
  }>;
} => {
  const { authInteractor, httpAdapter } = useDependencies();

  const fetchUserDetails = useCallback(async () => {
    return authInteractor.fetchUserDetails();
  }, []);
  const fetchGoogleUserData = useCallback(async (accessToken: string) => {
    const userInfo: {
      sub: string;
      name: string;
      picture: string;
      email: string;
    } = await httpAdapter
      .get('https://www.googleapis.com/oauth2/v3/userinfo', {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then(res => res.data);
    return userInfo;
  }, []);

  return { fetchUserDetails, fetchGoogleUserData };
};

export const useUpdatePassword: AuthHooks['useUpdatePassword'] = (): {
  forgotPassword: (email: string) => Promise<GenericResponse>;
  resetPassword: (
    token: string,
    password: string,
    passwordConfirmation: string,
  ) => Promise<GenericResponse>;
} => {
  const { authInteractor } = useDependencies();

  const forgotPassword = useCallback(async (email: string) => {
    return authInteractor.forgotPassword(email);
  }, []);

  const resetPassword = useCallback(
    async (token: string, password: string, passwordConfirmation: string) => {
      return authInteractor.resetPassword(
        token,
        password,
        passwordConfirmation,
      );
    },
    [],
  );

  return { forgotPassword, resetPassword };
};
