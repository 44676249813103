import React from 'react';

type Props = {
  [key: string]: string | number | Function;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="200"
      height="153"
      viewBox="0 0 200 153"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M194.118 34.5811H5.88215C2.63367 34.5811 0 37.2147 0 40.4632V146.345C0 149.594 2.63367 152.227 5.88215 152.227H194.117C197.366 152.227 199.999 149.594 199.999 146.345V40.4639C200 37.2147 197.366 34.5811 194.118 34.5811Z"
        fill="#BFF796"
      />
      <path
        d="M194.118 34.5811H99.0776V152.228H194.118C197.367 152.228 200 149.594 200 146.346V40.4639C200 37.2147 197.367 34.5811 194.118 34.5811Z"
        fill="#AFE286"
      />
      <path
        d="M136.287 82.2269L190.124 48.5785C192.723 46.9536 193.514 43.5286 191.889 40.9293C190.265 38.3293 186.838 37.5408 184.24 39.1643L100 0L15.7603 39.1643C13.163 37.5394 9.7367 38.33 8.11111 40.9293C6.48619 43.5286 7.27677 46.9536 9.87609 48.5785L63.7131 82.2269L9.95151 135.989C7.78384 138.156 7.78384 141.671 9.95151 143.839C11.0357 144.923 12.4559 145.465 13.8768 145.465C15.297 145.465 16.7178 144.923 17.802 143.839L73.3757 88.266L97.0579 103.067C97.9576 103.63 98.9791 103.911 100 103.911C101.021 103.911 102.042 103.63 102.942 103.067L126.624 88.266L182.198 143.839C183.282 144.923 184.702 145.465 186.123 145.465C187.544 145.465 188.964 144.923 190.048 143.839C192.216 141.671 192.216 138.156 190.048 135.989L136.287 82.2269Z"
        fill="#86C867"
      />
      <path
        d="M136.287 82.227L190.124 48.5785C192.723 46.9536 193.514 43.5287 191.889 40.9294C190.265 38.3294 186.839 37.5408 184.24 39.1644L100 91.8149L99.0776 91.2385V103.826C99.3834 103.878 99.6911 103.912 100 103.912C101.021 103.912 102.042 103.631 102.942 103.068L126.624 88.2667L182.198 143.84C183.282 144.923 184.703 145.465 186.123 145.465C187.544 145.465 188.964 144.923 190.049 143.84C192.216 141.671 192.216 138.157 190.049 135.989L136.287 82.227Z"
        fill="#6CAA4B"
      />
    </svg>
  );
};

export default Component;
