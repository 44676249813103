import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import theme from '../../../constants/themes';
import { Button } from '../../atoms/Button';
import { isWindowsOS } from '../../../utils/booleans';
import { adjustShade } from '../../../utils/colors';

const Container = styled.div<{ islast?: boolean }>`
  width: 313px;
  height: 540px;
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: ${props =>
    props.islast ? 'none' : `thin solid ${theme.colors.roleItemBorder}`};
`;

const RoleImgWrapper = styled.img`
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 45px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
`;

const RoleDescriptionWrapper = styled.div`
  width: 290px;
  height: 190px;
  padding: 0px 5px;
  overflow-y: hidden;
  line-break: strict;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`;

const RoleName = styled.span`
  margin-bottom: 3px;
  font-family: ${theme.fonts.primary};
  font-weight: 700;
  font-size: 17px;
  line-height: 29.75px;
  color: ${theme.colors.text02};
`;

const RoleDesc = styled.p`
  margin: 0;
  padding: 0px 5px;
  font-family: ${theme.fonts.primary};
  font-weight: 400;
  font-size: 12px;
  line-height: 29.75px;
  color: ${theme.colors.text02};
  text-overflow: ellipsis;
  text-align: center;
`;

const StyledButton = styled(Button)<{ themecolor: string }>`
  width: 300px;
  height: 70px;
  border-radius: 30px;
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  margin-top: 40px;
  padding-top: ${isWindowsOS() ? 14 : 8}px;
  background-color: ${props => props.themecolor};
  border-color: ${props => props.themecolor};

  &:hover {
    background-color: ${props => adjustShade(props.themecolor, -10)};
    border-color: ${props => adjustShade(props.themecolor, -10)};
  }

  &:active {
    background-color: ${props => adjustShade(props.themecolor, -20)};
    border-color: ${props => adjustShade(props.themecolor, -20)};
  }
`;

export type Props = {
  roleId: number;
  roleImg: string;
  roleTitle: string;
  roleDesc: string;
  theme: string;
  isLast?: boolean;
  className?: string;
  isDisabled?: boolean;
  onPressSelectRole: (id: number) => void;
};

const Component = ({
  roleId,
  roleImg,
  roleTitle,
  roleDesc,
  theme,
  isLast = false,
  className,
  isDisabled,
  onPressSelectRole,
}: Props): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <Container className={className} islast={isLast}>
      <RoleImgWrapper src={roleImg} />
      <RoleDescriptionWrapper>
        <RoleName>{roleTitle}</RoleName>
        <RoleDesc>{roleDesc}</RoleDesc>
      </RoleDescriptionWrapper>
      <StyledButton
        title={t('selectRoleBtn', { roleName: roleTitle })}
        onPress={() => onPressSelectRole(roleId)}
        disabled={isDisabled}
        themecolor={theme}
      />
    </Container>
  );
};

export default Component;
