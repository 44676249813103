import React from 'react';

type Props = {
  [key: string]: string | number | Function;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="13"
      height="10"
      viewBox="0 0 13 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.972046 5C0.972046 4.81059 1.05105 4.62895 1.19169 4.49502C1.33233 4.36108 1.52307 4.28584 1.72196 4.28584H10.4105L7.19035 1.22068C7.04954 1.08658 6.97043 0.904705 6.97043 0.715059C6.97043 0.525414 7.04954 0.343536 7.19035 0.209436C7.33117 0.0753365 7.52215 0 7.72129 0C7.92043 0 8.11142 0.0753365 8.25223 0.209436L12.7517 4.49438C12.8216 4.56072 12.877 4.63952 12.9148 4.72629C12.9526 4.81305 12.972 4.90606 12.972 5C12.972 5.09394 12.9526 5.18695 12.9148 5.27371C12.877 5.36048 12.8216 5.43928 12.7517 5.50562L8.25223 9.79056C8.11142 9.92466 7.92043 10 7.72129 10C7.52215 10 7.33117 9.92466 7.19035 9.79056C7.04954 9.65646 6.97043 9.47459 6.97043 9.28494C6.97043 9.0953 7.04954 8.91342 7.19035 8.77932L10.4105 5.71416H1.72196C1.52307 5.71416 1.33233 5.63892 1.19169 5.50499C1.05105 5.37106 0.972046 5.18941 0.972046 5Z"
        fill="white"
      />
    </svg>
  );
};

export default Component;
