import React, { SVGProps } from 'react';
import styled from 'styled-components';

export const EditorContainer = styled.div`
  cursor: auto;
  position: relative;
  .shape-wrapper {
    position: absolute;
    top: 0;
  }
  .overflow-wrapper {
    position: absolute;
    top: 0;
    padding: 15px;
    box-sizing: border-box;
  }
  svg {
    /* NOTE: to prevent width from reisizing via react-moveable */
    width: 15% !important;
  }
`;

const BaseShape = (props: SVGProps<SVGSVGElement>): React.ReactElement => {
  return (
    <svg
      width="5"
      height="30"
      viewBox="0 0 5 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      // eslint-disable-next-line react/prop-types
      style={{ ...props.style, fill: 'transparent' }}>
      <path
        d="M4.21445 1H3.06445C2.29778 1 1.91445 1.76667 1.91445 3.3V12.5C1.91445 14.0333 1.53112 14.8 0.764451 14.8H0.194451C-0.192216 14.8 -0.00221533 14.8 0.764451 14.8H1.33445C1.72112 14.8 1.91445 15.5667 1.91445 17.1V26.3C1.91445 27.8333 2.29778 28.6 3.06445 28.6H4.21445"
        stroke="inherit"
        strokeMiterlimit="10"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};

export const ShapeOutside = (): React.ReactElement => {
  return (
    <>
      <div
        style={{
          shapeOutside: 'polygon(0 0,100% 0,100% 100%, 0 100%)',
          width: '15%',
          height: '100%',
          float: 'left',
          pointerEvents: 'none',
        }}
      />
    </>
  );
};

export const Shape = styled(BaseShape)``;
