import { createContext, useContext } from 'react';

export type LocalStorageHooks = {
  useLocalStorage: () => {
    clearSessionStorage: () => void;
    getItem: (key: string) => string | null;
  };
};

export const LocalStorageHooksContext = createContext<LocalStorageHooks | null>(
  null,
);

export const useLocalStorageHooks = (): LocalStorageHooks => {
  const context = useContext(LocalStorageHooksContext);
  if (!context) {
    throw new Error(`Context not instantiated`);
  }
  return context;
};
