import React, { useState } from 'react';
import { GlobalState, GlobalStateContext, CurrentUser } from '.';
import { useDependencies } from '..';
import { Card } from '../../domain/entities/card';
import { Props as INotification } from '../../components/atoms/Toast/Component';
import { Toast } from '../../components/atoms/Toast';
import { Customer } from '../../domain/entities/user';
import storageKeys from '../../constants/localStorage';

type Props = {
  value?: GlobalState;
  children?: React.ReactElement;
};

const INITIAL_USER = undefined;

const Provider = (props: Props): React.ReactElement => {
  const { localStorageInteractor, httpAdapter } = useDependencies();
  const { getItem, setItem } = localStorageInteractor;
  const [notification, setNotification] = useState<INotification | null>(null);

  const [currentUser, setCurrentUser] = useState<CurrentUser>(() => {
    try {
      const item = getItem(storageKeys.USER_STORAGE_KEY);
      const user = item ? JSON.parse(item) : INITIAL_USER;
      const token = user?.user?.token;
      httpAdapter.setToken(token);
      return user;
    } catch (error) {
      console.error(error);
      return INITIAL_USER;
    }
  });
  window.addEventListener('storage', () => {
    const user = getItem(storageKeys.USER_STORAGE_KEY);
    if (currentUser && !user) {
      setNotification({
        isOpen: true,
        message: 'サインアウト',
        type: 'error',
        position: 'top-right',
        onClose: () => setNotification(null),
      });
      setCurrentUser(undefined);
    }
  });

  const [activeCard, setActiveCard] = useState<Card | undefined>(() => {
    try {
      const card = getItem(storageKeys.ACTIVE_CARD_STORAGE_KEY);
      const parsedData = card ? JSON.parse(card) : undefined;
      return parsedData;
    } catch (error) {
      return undefined;
    }
  });
  const [socketCard, setSocketCard] = useState<Card | undefined>(() => {
    try {
      const card = getItem(storageKeys.ACTIVE_CARD_STORAGE_KEY);
      const parsedData = card ? JSON.parse(card) : undefined;
      return parsedData;
    } catch (error) {
      return undefined;
    }
  });
  const [currentFlippedCards, setCurrentFlippedCards] = useState<Card[]>(() => {
    try {
      const cards = getItem(storageKeys.CARDS_STORAGE_KEY);
      const parsedData = cards ? JSON.parse(cards) : [];
      return parsedData;
    } catch (error) {
      return [];
    }
  });
  const [currentCardIds, setCurrentCardIds] = useState<(string | number)[]>(
    () => {
      try {
        const cardIDs = getItem(storageKeys.CARD_IDS_STORAGE_KEY);
        const parsedData = cardIDs ? JSON.parse(cardIDs) : [];
        return parsedData;
      } catch (error) {
        return [];
      }
    },
  );

  const useToast = React.useMemo(
    () => ({
      notification,
      setNotification: (notification?: object): void => {
        const typedNotification = notification as INotification | null;
        setNotification(typedNotification);
      },
    }),
    [notification],
  );

  const useCurrentUser = React.useMemo(
    () => ({
      currentUser,
      setCurrentUser: (user?: object): void => {
        const typedUser = user as CurrentUser | undefined;
        httpAdapter.setToken(typedUser?.user.token || '');
        setCurrentUser(typedUser);
        setItem(storageKeys.USER_STORAGE_KEY, JSON.stringify(typedUser));
      },
      updateUserCustomerFields: (customer: Partial<Customer>) => {
        if (currentUser?.user) {
          setCurrentUser({
            ...currentUser,
            user: {
              ...currentUser.user,
              customer: {
                ...currentUser.user.customer,
                ...customer,
              },
            },
          });
        }
      },
    }),
    [currentUser],
  );

  const useCurrentCards = React.useMemo(
    () => ({
      activeCard,
      socketCard,
      currentFlippedCards,
      currentCardIds,
      setActiveCard: (card: Card | undefined) => {
        setItem(storageKeys.ACTIVE_CARD_STORAGE_KEY, JSON.stringify(card));
        setActiveCard(card);
      },
      setSocketCard: (card: Card | undefined) => {
        setItem(storageKeys.ACTIVE_CARD_STORAGE_KEY, JSON.stringify(card));
        setSocketCard(card);
      },
      setCurrentFlippedCards: (cards: Card[]) => {
        setItem(storageKeys.CARDS_STORAGE_KEY, JSON.stringify(cards));
        setCurrentFlippedCards(cards);
      },
      setCurrentCardIds: (ids: (string | number)[]) => {
        setItem(storageKeys.CARD_IDS_STORAGE_KEY, JSON.stringify(ids));
        setCurrentCardIds(ids);
      },
    }),
    [activeCard, socketCard, currentFlippedCards, currentCardIds],
  );

  return (
    <>
      <GlobalStateContext.Provider
        value={{
          useCurrentUser,
          useCurrentCards,
          useToast,
        }}
        {...props}
      />
      {notification ? <Toast {...notification} /> : null}
    </>
  );
};

export default Provider;
