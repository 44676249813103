import React, { ChangeEvent, CSSProperties, MouseEvent } from 'react';
import styled from 'styled-components';
import theme from '../../../constants/themes';
import { isWindowsOS } from '../../../utils/booleans';

const StyledTextInput = styled.input.attrs(
  (props: object & { iserror?: boolean }) => ({ ...props }),
)`
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  border: ${props =>
    props.iserror
      ? `thin solid ${theme.colors.textError}`
      : `thin solid ${theme.colors.inputBorder}`};
  background: ${theme.colors.white};
  border-radius: 4px;
  padding: 8px 16px;
  display: block;
  width: 100%;
  height: 37px;
  font-family: ${theme.fonts.primary};
  font-size: 16px;
  line-height: ${isWindowsOS() ? '30px' : '175%'};
  color: ${theme.colors.inputBorder};

  &:focus {
    outline: ${props =>
      props.iserror
        ? `${theme.colors.textError} auto 3px`
        : `#5c5c5c auto 3px`};
  }
  &:disabled {
    background-color: ${theme.colors.dropdownBorder};
  }
`;

export type Props = {
  onChangeText?: (text: string) => void;
  value?: string;
  placeholder?: string;
  type?: 'text' | 'password';
  className?: string;
  onKeyUp?: React.KeyboardEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  disabled?: boolean;
  style?: CSSProperties;
  isError?: boolean;
  autoFocus?: boolean;
  onClick?: (e: MouseEvent<HTMLInputElement>) => void;
  name?: string;
};

const Component = ({
  value,
  placeholder,
  type = 'text',
  onChangeText,
  className,
  onKeyUp,
  name,
  onBlur,
  disabled,
  style,
  isError = false,
  autoFocus = false,
  onClick,
}: Props): React.ReactElement => {
  const onChange = (event: ChangeEvent): void => {
    const target = event.target as HTMLInputElement;
    if (onChangeText) {
      onChangeText(target.value);
    }
  };
  return (
    <StyledTextInput
      className={className}
      onChange={onChange}
      value={value}
      placeholder={placeholder}
      type={type}
      onKeyUp={onKeyUp}
      onBlur={onBlur}
      disabled={disabled}
      style={style}
      iserror={isError}
      autoFocus={autoFocus}
      onClick={onClick}
      name={name}
    />
  );
};

export default Component;
