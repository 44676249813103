export const adjustShade = (color: string, amount: number): string => {
  let red = parseInt(color.substring(1, 3), 16);
  let green = parseInt(color.substring(3, 5), 16);
  let blue = parseInt(color.substring(5, 7), 16);

  red = parseInt(String((red * (100 + amount)) / 100));
  green = parseInt(String((green * (100 + amount)) / 100));
  blue = parseInt(String((blue * (100 + amount)) / 100));

  const redValue =
    red.toString(16).length == 1 ? '0' + red.toString(16) : red.toString(16);
  const greenValue =
    green.toString(16).length == 1
      ? '0' + green.toString(16)
      : green.toString(16);
  const blueValue =
    blue.toString(16).length == 1 ? '0' + blue.toString(16) : blue.toString(16);

  return `#${redValue}${greenValue}${blueValue}`;
};

export const setOpacity = (hex: string, alpha: number): string =>
  `${hex}${Math.floor(alpha * 255)
    .toString(16)
    .padStart(2, '0')}`;
