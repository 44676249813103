import { CSSProperties, useCallback, useMemo, useRef } from 'react';
import { useReactFlow, Node } from 'react-flow-renderer';
import { debounce } from 'lodash';
import {
  CanvasData,
  DiagramEdge,
  DiagramNode,
  DropAreaData,
  LabelData,
  LineData,
  MediaData,
  ShapeData,
  StickyData,
  StrokeData,
} from '../../types';
import { useBoardHooks } from '../../../../../hooks/board';
import { removeUndefined } from '../../../../../utils/objects';
import {
  generateUniqueString,
  mapUniqueStrings,
} from '../../../../../utils/strings';
import {
  DROP_AREA_NODE_PREFIX_ID,
  DROP_AREA_NODE_Z_INDEX,
} from '../DropAreaNode';
import { useGlobalState } from '../../../../../hooks/global';
import { CANVAS_NODE_PREFIX_ID, CANVAS_NODE_Z_INDEX } from '../CanvasNode';
import { useEdgesUtils } from './edges';
import { removeWhitelistedNodes } from '../../../../../utils/arrays';
import xmljs from 'xml-js';
import { useSocketHooks } from '../../../../../hooks/socket';
import events from '../../../../../constants/socket';
import { sendSocket } from './SendSocket';

const findOverlappingNode = (
  baseNode: Node,
  currentNodes: Node[],
): Node | undefined => {
  const bindThreshold = 50;
  const nearestNode = currentNodes.find(node => {
    if (baseNode.type !== 'line') {
      if (
        node.type === 'line' &&
        !node.parentNode &&
        node.positionAbsolute &&
        !(node.data.source && node.data.target) &&
        baseNode.id !== node.id
      ) {
        const minX = baseNode.position.x - bindThreshold;
        const minY = baseNode.position.y - bindThreshold;
        const maxX =
          baseNode.position.x + (baseNode.data?.width || 0) + bindThreshold;
        const maxY =
          baseNode.position.y + (baseNode.data?.height || 0) + bindThreshold;

        const position =
          node.positionAbsolute !== undefined
            ? node.positionAbsolute
            : node.position;
        return (
          position.x <= maxX &&
          position.x >= minX &&
          position.y <= maxY &&
          position.y >= minY
        );
      }
    }
    if (
      node.type === 'line' ||
      node.type === 'dropArea' ||
      baseNode.id === node.id
    ) {
      return false;
    }
    const minX = node.position.x - bindThreshold;
    const minY = node.position.y - bindThreshold;
    const maxX = node.position.x + (node.data?.width || 0) + bindThreshold;
    const maxY = node.position.y + (node.data?.height || 0) + bindThreshold;
    return (
      baseNode.position.x <= maxX &&
      baseNode.position.x >= minX &&
      baseNode.position.y <= maxY &&
      baseNode.position.y >= minY
    );
  });
  return nearestNode;
};

// NOTE: can only use this hook inside react-flow-renderer context
export const useNodeUtils = (
  nodeId: string,
): {
  setNodeDraggable: (isDraggable: boolean) => void;
  isSelectedByCurrentUser: boolean;
  isMultipleSelected: boolean;
  setStyle: (style: CSSProperties) => void;
  setLabel: (label: string) => void;
  handleResizeEnd: (
    xOffset: number,
    pageYOffset: number,
    dimensions: { width: number; height: number },
  ) => void;
  setData: (
    newData: StickyData | LabelData | MediaData | StrokeData | ShapeData,
  ) => void;
  handlePasteNodes: () => void;
  handleCopyNodes: () => void;
  handleRemoveNode: () => void;
} => {
  const { getNodes } = useReactFlow();
  const {
    useClipboard,
    useActions,
    useMySelectedNodes,
    nodeState,
    edgeState,
  } = useBoardHooks();
  const { insertAction } = useActions;
  const { setNodes, nodes } = nodeState;
  const { copiedNodes, setCopiedNodes } = useClipboard;
  const { recentlySelectedNodeIds } = useMySelectedNodes;

  const { useSocket } = useSocketHooks();
  const { socket, initializedBoardCode } = useSocket;
  const { useCurrentUser } = useGlobalState();
  const { currentUser } = useCurrentUser;

  const isSelectedByCurrentUser = useMemo(() => {
    return recentlySelectedNodeIds.includes(nodeId);
  }, [recentlySelectedNodeIds]);

  const isMultipleSelected = useMemo(() => {
    return (
      recentlySelectedNodeIds.reduce((acc: string[], cur: string) => {
        if (acc.includes(cur)) return acc;
        return [...acc, cur];
      }, []).length > 1
    );
  }, [recentlySelectedNodeIds]);

  const setNodeDraggable = useCallback(
    (isDraggable: boolean) => {
      setNodes(currentNodes => {
        const updatedNodes = currentNodes.map(node => ({
          ...node,
          draggable: node.id === nodeId ? isDraggable : node.draggable,
        }));
        return updatedNodes;
      });
    },
    [setNodes],
  );

  const setStyle = useCallback(
    debounce((style: CSSProperties) => {
      const currentNodes = getNodes();
      let oldStyle: CSSProperties | undefined;
      const updatedNodes = currentNodes.map(node => {
        if (node.id === nodeId) {
          oldStyle = node.data.style;
          return {
            ...node,
            data: {
              ...node.data,
              style,
            },
          };
        }
        return node;
      });
      if (oldStyle) {
        insertAction({
          undo: () => {
            setNodes(latestNodes => {
              const item = latestNodes.map(node => {
                if (node.id === nodeId) {
                  return {
                    ...node,
                    data: {
                      ...node.data,
                      style: oldStyle,
                    },
                  };
                }
                return node;
              });
              sendSocket(
                item,
                edgeState.getEdges(),
                '',
                currentUser?.user?.token,
                socket,
                initializedBoardCode,
              );
              return item;
            });
          },
          redo: () => {
            setNodes(latestNodes => {
              const item = latestNodes.map(node => {
                if (node.id === nodeId) {
                  return {
                    ...node,
                    data: {
                      ...node.data,
                      style,
                    },
                  };
                }
                return node;
              });
              sendSocket(
                item,
                edgeState.getEdges(),
                '',
                currentUser?.user?.token,
                socket,
                initializedBoardCode,
              );
              return item;
            });
          },
        });
      }
      setNodes(updatedNodes);
    }, 100),
    [insertAction],
  );

  const setLabel = useCallback(
    debounce((label: string) => {
      const currentNodes = getNodes();
      let oldLabel: string | undefined;
      const updatedNodes = currentNodes.map(node => {
        if (node.id === nodeId) {
          oldLabel = node.data.label;
          return {
            ...node,
            data: {
              ...node.data,
              label,
            },
          };
        }
        return node;
      });
      insertAction({
        undo: () => {
          setNodes(latestNodes => {
            const item = latestNodes.map(node => {
              if (node.id === nodeId) {
                return {
                  ...node,
                  data: {
                    ...node.data,
                    label: oldLabel,
                  },
                  selected: false,
                };
              }
              return node;
            });
            sendSocket(
              item,
              edgeState.getEdges(),
              '',
              currentUser?.user?.token,
              socket,
              initializedBoardCode,
            );
            return item;
          });
        },
        redo: () => {
          setNodes(latestNodes => {
            const item = latestNodes.map(node => {
              if (node.id === nodeId) {
                return {
                  ...node,
                  data: {
                    ...node.data,
                    label,
                  },
                  selected: false,
                };
              }
              return node;
            });
            sendSocket(
              item,
              edgeState.getEdges(),
              '',
              currentUser?.user?.token,
              socket,
              initializedBoardCode,
            );
            return item;
          });
        },
      });
      setNodes(updatedNodes);
    }, 500),
    [insertAction],
  );

  const handleResizeEnd = useCallback(
    (
      xOffset: number,
      yOffset: number,
      dimensions: { width: number; height: number },
    ) => {
      const currentNodes = getNodes();
      let oldPosition: { x: number; y: number } | undefined;
      let newPosition: { x: number; y: number } | undefined;
      let oldData: unknown | undefined;
      let newData: unknown | undefined;
      let updatedNodes: Node[] = [];
      let scaledWidth = 1;
      let scaledHeight = 1;
      const childrenOldPositions: {
        [key: string]: { x: number; y: number };
      } = {};
      const childrenNewPositions: {
        [key: string]: { x: number; y: number };
      } = {};
      updatedNodes = currentNodes.map(node => {
        if (node.id === nodeId) {
          oldPosition = node.position;
          newPosition = {
            x: node.position.x + xOffset,
            y: node.position.y + yOffset,
          };
          oldData = node.data;
          newData = {
            ...node.data,
            width: dimensions.width,
            height: dimensions.height,
          };
          scaledWidth = dimensions.width / (node.data.width || 1);
          scaledHeight = dimensions.height / (node.data.height || 1);
          return {
            ...node,
            position: newPosition,
            data: newData,
            draggable: true,
          };
        }
        return node;
      });
      // NOTE: update children nodes also
      if (oldPosition !== undefined && newPosition !== undefined) {
        updatedNodes = updatedNodes.map(node => {
          if (
            node.parentNode === nodeId &&
            node.position.x > 0 &&
            node.position.y > 0
          ) {
            const newPosX = node.position.x * scaledWidth;
            const newPosY = node.position.y * scaledHeight;
            childrenOldPositions[node.id] = node.position;
            childrenNewPositions[node.id] = { x: newPosX, y: newPosY };
            return {
              ...node,
              position: {
                x: newPosX,
                y: newPosY,
              },
            };
          }
          return node;
        });
      }

      if (oldData && newData && oldPosition && newPosition) {
        insertAction({
          undo: () => {
            setNodes(latestNodes => {
              const item = latestNodes.map(node => {
                if (node.id === nodeId && oldPosition) {
                  return {
                    ...node,
                    position: oldPosition,
                    data: oldData,
                    selected: false,
                  };
                }
                if (childrenOldPositions[node.id]) {
                  return {
                    ...node,
                    position: childrenOldPositions[node.id],
                    selected: false,
                  };
                }
                return node;
              });
              sendSocket(
                item,
                edgeState.getEdges(),
                '',
                currentUser?.user?.token,
                socket,
                initializedBoardCode,
              );
              return item;
            });
          },
          redo: () => {
            setNodes(latestNodes => {
              const item = latestNodes.map(node => {
                if (node.id === nodeId && newPosition) {
                  return {
                    ...node,
                    position: newPosition,
                    data: newData,
                    selected: false,
                  };
                }
                if (childrenNewPositions[node.id]) {
                  return {
                    ...node,
                    position: childrenNewPositions[node.id],
                    selected: false,
                  };
                }
                return node;
              });
              sendSocket(
                item,
                edgeState.getEdges(),
                '',
                currentUser?.user?.token,
                socket,
                initializedBoardCode,
              );
              return item;
            });
          },
        });
      }
      setNodes(updatedNodes);
    },
    [insertAction],
  );

  const setData = useCallback(
    (newData: StickyData | LabelData | MediaData) => {
      const currentNodes = getNodes();
      let oldData: typeof newData | undefined;
      const updatedNodes = currentNodes.map(node => {
        if (node.id === nodeId) {
          oldData = node.data;
          return {
            ...node,
            data: newData,
          };
        }
        return node;
      });
      if (oldData) {
        insertAction({
          undo: () => {
            setNodes(latestNodes => {
              const item = latestNodes.map(node => {
                if (node.id === nodeId) {
                  return {
                    ...node,
                    data: oldData,
                  };
                }
                return node;
              });
              sendSocket(
                item,
                edgeState.getEdges(),
                '',
                currentUser?.user?.token,
                socket,
                initializedBoardCode,
              );
              return item;
            });
          },
          redo: () => {
            setNodes(latestNodes => {
              const item = latestNodes.map(node => {
                if (node.id === nodeId) {
                  return {
                    ...node,
                    data: newData,
                  };
                }
                return node;
              });
              sendSocket(
                item,
                edgeState.getEdges(),
                '',
                currentUser?.user?.token,
                socket,
                initializedBoardCode,
              );
              return item;
            });
          },
        });
      }
      setNodes(updatedNodes);
    },
    [insertAction],
  );

  const handleCopyNodes = useCallback(() => {
    const nodes = getNodes();
    setCopiedNodes(nodes.filter(node => node.selected));
  }, [setCopiedNodes]);

  const handlePasteNodes = useCallback(() => {
    if (copiedNodes?.length) {
      setNodes(currentNodes => {
        const xOffset = 100;
        const yOffset = 100;
        const lineNodes = copiedNodes.filter(
          node => node.type === 'line',
        ) as Node<LineData>[];
        const lineNodeIds = lineNodes.map(lineNode => lineNode.id);
        const uniqueLineGroupIds = mapUniqueStrings(
          lineNodes.map(node => node.data.groupId),
        );
        const lineGroupMap: {
          [key: string]: Node<LineData>[];
        } = uniqueLineGroupIds.reduce(
          (
            acc: {
              [key: string]: Node<LineData>[];
            },
            cur: string,
          ) => {
            const associatedNodes = currentNodes.filter(
              node => node.data.groupId === cur,
            );
            return { [cur]: associatedNodes };
          },
          {},
        );

        const newLineGroupList: Node<LineData>[] = uniqueLineGroupIds.reduce(
          (acc: Node<LineData>[], cur: string) => {
            const newGroupId = generateUniqueString();
            const node = lineGroupMap[cur] || [];
            const withSource = node.map((newNode, index, nodeArr) => ({
              ...newNode,
              id: `line-${generateUniqueString()}`,
              data: {
                ...newNode.data,
                groupId: newGroupId,
                index,
                source: nodeArr[index - 1]?.id,
              },
              position: {
                x: newNode.position.x,
                y: newNode.position.y,
              },
            }));
            const withSourceAndTarget = withSource.map(
              (newNode, index, nodeArr) => ({
                ...newNode,
                data: {
                  ...newNode.data,
                  groupId: newGroupId,
                  target: nodeArr[index + 1]?.id,
                },
                position: {
                  x: newNode.position.x + xOffset,
                  y: newNode.position.y + yOffset,
                },
              }),
            );
            return [...acc, ...withSourceAndTarget];
          },
          [],
        );
        const newNodes: Node[] = copiedNodes
          .filter(node => !lineNodeIds.includes(node.id))
          .map(node => ({
            ...node,
            id: `${node.type}-${generateUniqueString()}`,
            data: removeUndefined({ ...node.data, lineNodes: undefined }),
            position: {
              x: node.position.x + xOffset,
              y: node.position.y + yOffset,
            },
          }));

        const newlyCopiedNodes = [...newNodes, ...newLineGroupList];

        const updatedNodes = [...currentNodes, ...newlyCopiedNodes].map(
          node => ({
            ...node,
            selected: false,
          }),
        );
        setCopiedNodes(newlyCopiedNodes);

        return updatedNodes;
      });
    }
  }, [copiedNodes, setNodes]);

  const handleRemoveNode = useCallback(() => {
    const updatedNodes = nodes
      .filter(node => node.id !== nodeId)
      .map(node =>
        node.parentNode === nodeId
          ? {
              ...node,
              parentNode: undefined,
              position: {
                x: node?.positionAbsolute?.x || node.position.x,
                y: node?.positionAbsolute?.y || node.position.y,
              },
            }
          : node,
      );

    setNodes(updatedNodes);
  }, [setCopiedNodes, setNodes]);

  return {
    isSelectedByCurrentUser,
    isMultipleSelected,
    setNodeDraggable,
    setStyle,
    setLabel,
    handleResizeEnd,
    setData,
    handleCopyNodes,
    handlePasteNodes,
    handleRemoveNode,
  };
};

// NOTE: can only use this hook inside react-flow-renderer context
export const useNodesUtils = (): {
  findNode: (nodeId: string) => Node | undefined;
  addNewNode: (
    type: 'sticky' | 'label' | 'media' | 'shape' | 'stroke',
    position: { x: number; y: number },
    data: StickyData | LabelData | MediaData | ShapeData | StrokeData,
    callback?: (latestNodes: Node[]) => Node[],
  ) => void;
  getMySelectedNodes: () => Node[];
  getOverlappingNode: (draggedNode: Node) => Node | undefined;
  getChildrenNodes: (parentNodeId: string) => Node[];
  setParentNode: (
    childNode: Node,
    parentNode: Node,
    callback?: (latestNodes: Node[]) => Node[],
  ) => void;
  removeNode: (nodeId: string) => void;
  removeNodes: (nodeIds: string[]) => void;
  removeParentNodes: (parentNodes: Node[]) => void;
  removeLineNodeGroup: (groupId: string[]) => void;
  removeLineNodePoint: (lineNodeId: string) => void;
  updateNodePositions: (
    nodePositions: {
      [nodeId: string]: { x: number; y: number };
    },
    callback?: (latestNodes: Node[]) => Node[],
  ) => void;
  updateLinePoints: (
    source: { id: string; target: string },
    target: { id: string; source: string },
  ) => void;
  handlePasteNodes: () => void;
} => {
  const { getNodes } = useReactFlow();
  const { insertNewEdges } = useEdgesUtils();
  const {
    useMySelectedNodes,
    useActions,
    nodeState,
    useClipboard,
    edgeState,
  } = useBoardHooks();
  const { copiedNodes, setCopiedNodes } = useClipboard;
  const { setNodes } = nodeState;
  const { setEdges } = edgeState;
  const { insertAction } = useActions;
  const { recentlySelectedNodeIds } = useMySelectedNodes;
  const { useSocket } = useSocketHooks();
  const { socket, initializedBoardCode } = useSocket;
  const { useCurrentUser } = useGlobalState();
  const { currentUser } = useCurrentUser;

  const findNode = useCallback((nodeId: string) => {
    return getNodes().find(node => node.id === nodeId);
  }, []);

  const addNewNode = useCallback(
    (
      type: 'sticky' | 'label' | 'media' | 'shape' | 'stroke',
      position: { x: number; y: number },
      data: StickyData | LabelData | MediaData | ShapeData | StrokeData,
      callback?: (latestNodes: Node[]) => Node[],
    ) => {
      if (
        (typeof data.width === 'number' && data.width < 1) ||
        (typeof data.height === 'number' && data.height < 1)
      ) {
        console.error(`invalid width and height for new node`);
        return;
      }

      const currentNodes = getNodes();
      const newNode = {
        id: `${type}-${generateUniqueString()}`,
        type,
        data,
        position,
      };
      let updatedNodes = currentNodes.concat(newNode);
      insertAction({
        undo: () => {
          setNodes(latestNodes => {
            const item = latestNodes.filter(node => node.id !== newNode.id);
            sendSocket(
              item,
              edgeState.getEdges(),
              '',
              currentUser?.user?.token,
              socket,
              initializedBoardCode,
            );

            return item;
          });
        },
        redo: () => {
          setNodes(latestNodes => {
            const arrLatestNodes = latestNodes.concat(newNode);
            sendSocket(
              arrLatestNodes,
              edgeState.getEdges(),
              '',
              currentUser?.user?.token,
              socket,
              initializedBoardCode,
            );
            return arrLatestNodes;
          });
        },
      });
      if (callback) {
        updatedNodes = callback(updatedNodes);
      }
      setNodes(updatedNodes);
    },
    [insertAction, setNodes],
  );
  const getMySelectedNodes = useCallback(() => {
    return getNodes().filter(node => recentlySelectedNodeIds.includes(node.id));
  }, [recentlySelectedNodeIds]);

  const getOverlappingNode = useCallback((baseNode: Node) => {
    const currentNodes = getNodes();
    const bindThreshold = 50;
    const nearestNode = currentNodes.find(node => {
      if (baseNode.type !== 'line') {
        if (
          node.type === 'line' &&
          !node.parentNode &&
          node.positionAbsolute &&
          !(node.data.source && node.data.target) &&
          baseNode.id !== node.id
        ) {
          const minX = baseNode.position.x - bindThreshold;
          const minY = baseNode.position.y - bindThreshold;
          const maxX =
            baseNode.position.x + (baseNode.data?.width || 0) + bindThreshold;
          const maxY =
            baseNode.position.y + (baseNode.data?.height || 0) + bindThreshold;
          return (
            node.positionAbsolute.x <= maxX &&
            node.positionAbsolute.x >= minX &&
            node.positionAbsolute.y <= maxY &&
            node.positionAbsolute.y >= minY
          );
        }
      }
      if (
        node.type === 'line' ||
        node.type === 'dropArea' ||
        baseNode.id === node.id
      ) {
        return false;
      }
      const minX = node.position.x - bindThreshold;
      const minY = node.position.y - bindThreshold;
      const maxX = node.position.x + (node.data?.width || 0) + bindThreshold;
      const maxY = node.position.y + (node.data?.height || 0) + bindThreshold;
      return (
        baseNode.position.x <= maxX &&
        baseNode.position.x >= minX &&
        baseNode.position.y <= maxY &&
        baseNode.position.y >= minY
      );
    });
    return nearestNode;
  }, []);

  const getChildrenNodes = useCallback((parentNodeId: string) => {
    const nodes = getNodes();
    return nodes.filter(node => node.parentNode === parentNodeId);
  }, []);

  const setParentNode = useCallback(
    (
      childNode: Node,
      parentNode: Node,
      callback?: (latestNodes: Node[]) => Node[],
    ) => {
      setNodes(currentNodes => {
        const updatedNodes = currentNodes.map(currentNode => {
          if (currentNode.id === childNode.id) {
            const offsetX = childNode.position.x - parentNode.position.x;
            const offsetY = childNode.position.y - parentNode.position.y;
            return {
              ...currentNode,
              position: { x: offsetX, y: offsetY },
              parentNode: parentNode.id,
            };
          }
          return currentNode;
        });

        if (callback) {
          return callback(updatedNodes);
        }
        return updatedNodes;
      });
    },
    [setNodes],
  );

  const removeLineNodeGroup = useCallback(
    (groupIds: string[]) => {
      if (!groupIds.length) {
        return;
      }
      setNodes(currentNodes => {
        const updatedNodes = currentNodes.filter(
          node => !(node.data.groupId && groupIds.includes(node.data.groupId)),
        );
        return updatedNodes;
      });
    },
    [setNodes],
  );

  const removeNode = useCallback(
    (nodeId: string) => {
      setNodes(currentNodes => {
        const updatedNodes = currentNodes.filter(node => node.id !== nodeId);
        return updatedNodes;
      });
    },
    [setNodes],
  );

  const removeNodes = useCallback(
    (nodeIds: string[]) => {
      if (!nodeIds.length) {
        return;
      }
      const currentNodes = getNodes();
      const removedParentNodes = currentNodes.filter(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        node => node.isParent && nodeIds.includes(node.id),
      );
      const removedParentNodesObj: {
        [key: string]: Node;
      } = removedParentNodes.reduce(
        (acc, cur) => ({
          ...acc,
          [cur.id]: cur,
        }),
        {},
      );
      const lineGroupsToRemove: string[] = [];
      const updatedNodes = currentNodes.map(node => {
        if (
          node.type === 'line' &&
          nodeIds.includes(node.id) &&
          node.data.groupId
        ) {
          lineGroupsToRemove.push(node.data.groupId);
        }
        if (node.parentNode && removedParentNodesObj[node.parentNode]) {
          const parentNode = removedParentNodesObj[node.parentNode];
          const newX = (parentNode?.position?.x || 0) + node.position.x;
          const newY = (parentNode?.position?.y || 0) + node.position.y;
          return {
            ...node,
            parentNode: undefined,
            position: {
              x: newX,
              y: newY,
            },
          };
        }
        return node;
      });
      const removedNodes: Node[] = [];
      const filteredNodes = updatedNodes.filter(node => {
        const shouldRemove =
          nodeIds.includes(node.id) ||
          lineGroupsToRemove.includes(node.data.groupId);
        if (shouldRemove) {
          removedNodes.push(node);
        }
        return !shouldRemove;
      });

      if (removedNodes.length) {
        insertAction({
          undo: () => {
            setNodes(latestNodes => {
              const item = latestNodes.concat(removedNodes);
              sendSocket(
                item,
                edgeState.getEdges(),
                '',
                currentUser?.user?.token,
                socket,
                initializedBoardCode,
              );
              return item;
            });
          },
          redo: () => {
            setNodes(latestNodes => {
              const removedNodeIds = removedNodes.map(node => node.id);
              const item = latestNodes.filter(
                node => !removedNodeIds.includes(node.id),
              );
              sendSocket(
                item,
                edgeState.getEdges(),
                '',
                currentUser?.user?.token,
                socket,
                initializedBoardCode,
              );
              return item;
            });
          },
        });
      }

      setNodes(filteredNodes);
    },
    [insertAction, setNodes],
  );

  const removeParentNodes = useCallback(
    (removedParentNodes: Node[]) => {
      if (!removedParentNodes.length) {
        return;
      }
      const removedParentNodesObj: {
        [key: string]: Node;
      } = removedParentNodes.reduce(
        (acc, cur) => ({
          ...acc,
          [cur.id]: cur,
        }),
        {},
      );
      setNodes(currentNodes => {
        const updatedNodes = currentNodes.map(node => {
          if (node.parentNode && removedParentNodesObj[node.parentNode]) {
            const parentNode = removedParentNodesObj[node.parentNode];
            const newX = (parentNode?.position?.x || 0) + node.position.x;
            const newY = (parentNode?.position?.y || 0) + node.position.y;
            return {
              ...node,
              parentNode: undefined,
              position: {
                x: newX,
                y: newY,
              },
            };
          }
          return node;
        });
        return updatedNodes;
      });
    },
    [setNodes],
  );

  const removeLineNodePoint = useCallback(
    (lineNodeId: string) => {
      setNodes(currentNodes => {
        const lineNode = currentNodes.find(node => node.id === lineNodeId);
        if (!lineNode) {
          return currentNodes;
        }
        const updatedNodes = currentNodes
          .filter(node => node.id !== lineNodeId)
          .map(node => {
            if (lineNode.data.source === node.id) {
              return {
                ...node,
                data: {
                  ...node.data,
                  target: lineNode.data.target,
                },
              };
            }
            if (lineNode.data.target === node.id) {
              return {
                ...node,
                data: {
                  ...node.data,
                  source: lineNode.data.source,
                },
              };
            }
            return node;
          });
        setEdges(currentEdges => {
          const updatedEdges = currentEdges.filter(
            edge => edge.target !== lineNode.id || edge.source !== lineNode.id,
          );
          return updatedEdges.concat({
            id: `edge-${generateUniqueString()}`,
            source: lineNode.data.source,
            target: lineNode.data.target,
            type: 'custom',
            data: {
              groupId: lineNode.data.groupId,
            },
          });
        });
        return updatedNodes;
      });
    },
    [setNodes, setEdges],
  );

  const updateNodePositions = useCallback(
    (
      nodePositions: { [nodeId: string]: { x: number; y: number } },
      callback?: (latestNodes: Node[]) => Node[],
    ) => {
      setNodes(currentNodes => {
        const updatedNodes = currentNodes.map(node =>
          nodePositions[node.id]
            ? {
                ...node,
                position: nodePositions[node.id],
                selected: true,
              }
            : node,
        );
        if (callback) {
          return callback(updatedNodes);
        }
        return updatedNodes;
      });
    },
    [setNodes],
  );

  const updateLinePoints = useCallback(
    (
      source: { id: string; target: string },
      target: { id: string; source: string },
    ) => {
      setNodes(currentNodes => {
        const updatedNodes = currentNodes.map(node => {
          if (node.id === source.id && node.type === 'line') {
            return {
              ...node,
              data: {
                ...node.data,
                target: source.target,
              },
            };
          }
          if (node.id === target.id && node.type === 'line') {
            return {
              ...node,
              data: {
                ...node.data,
                source: target.source,
              },
            };
          }
          return node;
        });
        return updatedNodes;
      });
    },
    [setNodes],
  );

  const handlePasteNodes = useCallback(() => {
    if (copiedNodes?.length) {
      const currentNodes = getNodes();
      const xOffset = 100;
      const yOffset = 100;
      const lineNodes = copiedNodes.filter(
        node => node.type === 'line',
      ) as Node<LineData>[];
      const lineNodeIds = lineNodes.map(lineNode => lineNode.id);
      const uniqueLineGroupIds = mapUniqueStrings(
        lineNodes.map(node => node.data.groupId),
      );
      const lineGroupMap: {
        [key: string]: Node<LineData>[];
      } = uniqueLineGroupIds.reduce(
        (
          acc: {
            [key: string]: Node<LineData>[];
          },
          cur: string,
        ) => {
          const associatedNodes = currentNodes.filter(
            node => node.data.groupId === cur,
          );
          return { [cur]: associatedNodes };
        },
        {},
      );

      const newEdges: {
        groupId: string;
        target: string;
        source: string;
      }[] = [];
      const newLineGroupList: Node<LineData>[] = uniqueLineGroupIds.reduce(
        (acc: Node<LineData>[], cur: string) => {
          const newGroupId = generateUniqueString();
          const lineGroupNodes = lineGroupMap[cur] || [];
          const newLineGroupNodes = lineGroupNodes.map(newNode => {
            let posX = newNode.position.x;
            let posY = newNode.position.y;
            if (newNode.parentNode) {
              const parent = currentNodes.find(
                n => n.id === newNode.parentNode,
              );
              if (parent) {
                posX = posX + parent.position.x;
                posY = posY + parent.position.y;
              }
            }
            return {
              ...newNode,
              id: `line-${generateUniqueString()}`,
              data: {
                ...newNode.data,
                groupId: newGroupId,
              },
              position: {
                x: posX + xOffset,
                y: posY + yOffset,
              },
              parentNode: undefined,
            };
          });
          const withSourceAndTarget = newLineGroupNodes.map(
            (newNode, index) => {
              const origLineRef = lineGroupNodes[index];
              const origLineRefSourceIndex = lineGroupNodes.findIndex(
                n => n.id === origLineRef.data.source,
              );
              const origLineRefTargetIndex = lineGroupNodes.findIndex(
                n => n.id === origLineRef.data.target,
              );
              return {
                ...newNode,
                data: {
                  ...newNode.data,
                  source: newLineGroupNodes[origLineRefSourceIndex]?.id,
                  target: newLineGroupNodes[origLineRefTargetIndex]?.id,
                },
              };
            },
          );
          withSourceAndTarget.forEach(item => {
            if (item.data.target) {
              newEdges.push({
                groupId: newGroupId,
                target: item.data.target,
                source: item.id,
              });
            }
          });
          return [...acc, ...withSourceAndTarget];
        },
        [],
      );
      insertNewEdges(newEdges);
      const newNodes: Node[] = copiedNodes
        .filter(node => !lineNodeIds.includes(node.id))
        .map(node => ({
          ...node,
          id: `${node.type}-${generateUniqueString()}`,
          data: removeUndefined({ ...node.data, lineNodes: undefined }),
          position: {
            x: node.position.x + xOffset,
            y: node.position.y + yOffset,
          },
        }));

      const newlyCopiedNodes = [...newNodes, ...newLineGroupList].map(node => ({
        ...node,
        selected: false,
      }));

      const copiedNodeIds = copiedNodes.map(node => node.id);
      const updatedNodes: Node[] = [...currentNodes, ...newlyCopiedNodes].map(
        node => ({
          ...node,
          selected: copiedNodeIds.includes(node.id) ? false : node.selected,
        }),
      );
      if (newlyCopiedNodes.length) {
        insertAction({
          undo: () => {
            setNodes(latestNodes => {
              const removedNodeIds = newlyCopiedNodes.map(node => node.id);
              const updatedList = latestNodes.filter(
                node => !removedNodeIds.includes(node.id),
              );
              sendSocket(
                updatedList,
                edgeState.getEdges(),
                '',
                currentUser?.user?.token,
                socket,
                initializedBoardCode,
              );
              return updatedList;
            });
          },
          redo: () => {
            setNodes(latestNodes => {
              const updatedList = latestNodes.concat(newlyCopiedNodes);
              sendSocket(
                updatedList,
                edgeState.getEdges(),
                '',
                currentUser?.user?.token,
                socket,
                initializedBoardCode,
              );
              return updatedList;
            });
          },
        });
      }
      setCopiedNodes(newlyCopiedNodes);
      setNodes(updatedNodes);
    }
  }, [copiedNodes, insertAction, setNodes]);

  return {
    findNode,
    addNewNode,
    getMySelectedNodes,
    getOverlappingNode,
    getChildrenNodes,
    setParentNode,
    removeNode,
    removeParentNodes,
    removeNodes,
    removeLineNodePoint,
    removeLineNodeGroup,
    updateNodePositions,
    updateLinePoints,
    handlePasteNodes,
  };
};

export const useProjectUtils = (
  containerId: string,
): {
  getTranslatedPositions: (
    ev: React.MouseEvent<
      HTMLDivElement | SVGPathElement | HTMLButtonElement,
      MouseEvent
    >,
  ) => { x: number; y: number } | undefined;
} => {
  const { project } = useReactFlow();

  const getTranslatedPositions = useCallback(
    (
      ev: React.MouseEvent<
        HTMLDivElement | SVGPathElement | HTMLButtonElement,
        MouseEvent
      >,
    ): { x: number; y: number } | undefined => {
      const container = document.getElementById(
        containerId,
      ) as HTMLDivElement | null;
      if (container) {
        const containerBounds = container?.getBoundingClientRect();
        const position = project({
          x: ev.clientX - (containerBounds?.left || 0),
          y: ev.clientY - (containerBounds?.top || 0),
        });
        return position;
      }
    },
    [],
  );
  return { getTranslatedPositions };
};

// NOTE: can only use this hook inside react-flow-renderer context
export const useAreaNode = (
  nodeId: string,
): {
  shouldRender: boolean;
  isMyCanvasOpen: boolean;
  isMyDropAreaOpen: boolean;
} => {
  const { useCurrentUser } = useGlobalState();
  const { currentUser } = useCurrentUser;
  const customerId = currentUser?.user?.customer?.id;
  const shouldRender = useMemo(() => {
    if (!customerId) {
      return false;
    }
    if (
      nodeId.includes(CANVAS_NODE_PREFIX_ID) ||
      nodeId.includes(DROP_AREA_NODE_PREFIX_ID)
    ) {
      const [, extractedCustomerId] = nodeId.split('-');
      return extractedCustomerId === `${customerId}`;
    }
    return false;
  }, [customerId]);

  const isMyCanvasOpen = useMemo(() => {
    if (!customerId) {
      return false;
    }
    if (nodeId.includes(CANVAS_NODE_PREFIX_ID)) {
      const [, extractedCustomerId] = nodeId.split('-');
      return extractedCustomerId === `${customerId}`;
    }
    return false;
  }, [customerId]);

  const isMyDropAreaOpen = useMemo(() => {
    if (!customerId) {
      return false;
    }
    if (nodeId.includes(DROP_AREA_NODE_PREFIX_ID)) {
      const [, extractedCustomerId] = nodeId.split('-');
      return extractedCustomerId === `${customerId}`;
    }
    return false;
  }, [customerId]);

  return {
    shouldRender,
    isMyCanvasOpen,
    isMyDropAreaOpen,
  };
};

// NOTE: can only use this hook inside react-flow-renderer context
export const useAreaNodes = (): {
  myDropAreaId?: string;
  myCanvasId?: string;
  setMyDropAreaNode: (
    position: { x: number; y: number },
    data?: DropAreaData,
    callback?: (latestNodes: Node[]) => Node[],
  ) => void;
  removeMyDropAreaNode: () => void;
  setMyCanvasNode: (
    dimensions: { width: number; height: number },
    position: { x: number; y: number },
    strokeProperties?: { strokeWidth: number; strokeColor: string },
  ) => void;
  removeMyCanvasNode: () => void;
  removeMyAreaNodes: () => void;
} => {
  const { useCurrentUser } = useGlobalState();
  const { nodeState } = useBoardHooks();
  const { setNodes } = nodeState;
  const { currentUser } = useCurrentUser;
  const customerId = currentUser?.user?.customer?.id;

  const myDropAreaId = useMemo(() => {
    if (!customerId) {
      return undefined;
    }
    return `${DROP_AREA_NODE_PREFIX_ID}${customerId}`;
  }, [customerId]);

  const myCanvasId = useMemo(() => {
    if (!customerId) {
      return undefined;
    }
    return `${CANVAS_NODE_PREFIX_ID}${customerId}`;
  }, [customerId]);

  const setMyDropAreaNode = useCallback(
    (
      position: { x: number; y: number },
      data?: DropAreaData,
      callback?: (latestNodes: Node[]) => Node[],
    ) => {
      if (!myDropAreaId) {
        return;
      }
      setNodes(currentNodes => {
        const dropAreaId = myDropAreaId;

        const newCanvasDetails: Node<DropAreaData> = {
          id: dropAreaId,
          type: 'dropArea',
          position,
          data: {
            width: data?.width || 0,
            height: data?.height || 0,
            ...data,
          },
          draggable: false,
          zIndex: DROP_AREA_NODE_Z_INDEX,
        };
        const isDropAreaAlreadyAdded = !!currentNodes.find(
          node => node.id === dropAreaId,
        );
        const updatedNodes = isDropAreaAlreadyAdded
          ? currentNodes.map(node =>
              node.id === dropAreaId
                ? {
                    ...newCanvasDetails,
                    data: {
                      ...removeUndefined({
                        ...node.data,
                        ...data,
                      }),
                    },
                    zIndex: DROP_AREA_NODE_Z_INDEX,
                  }
                : node,
            )
          : currentNodes.concat(newCanvasDetails);

        // NOTE: remove canvas node in 1 state dispatch
        const removedStrokeCanvasNode = updatedNodes.filter(
          node => node.id !== myCanvasId,
        );

        if (callback) {
          return callback(removedStrokeCanvasNode);
        }
        return removedStrokeCanvasNode;
      });
    },
    [myDropAreaId, myCanvasId, setNodes],
  );

  const removeMyDropAreaNode = useCallback(() => {
    if (!myDropAreaId) {
      return;
    }
    setNodes(currentNodes => {
      const canvasId = myDropAreaId;
      const updatedNodes = currentNodes.filter(node => node.id !== canvasId);
      return updatedNodes;
    });
  }, [myDropAreaId, setNodes]);

  const setMyCanvasNode = useCallback(
    (
      dimensions: { width: number; height: number },
      position: { x: number; y: number },
      strokeProperties?: { strokeWidth: number; strokeColor: string },
    ) => {
      if (!myCanvasId) {
        return;
      }
      setNodes(currentNodes => {
        const canvasId = myCanvasId;
        const newCanvasDetails: Node<Partial<CanvasData>> = {
          id: canvasId,
          type: 'canvas',
          position,
          data: {
            width: dimensions.width,
            height: dimensions.height,
            strokeWidth: strokeProperties?.strokeWidth,
            strokeColor: strokeProperties?.strokeColor,
          },
          draggable: false,
          zIndex: CANVAS_NODE_Z_INDEX,
        };
        const isCanvasAlreadyAdded = !!currentNodes.find(
          node => node.id === canvasId,
        );
        const updatedNodes = isCanvasAlreadyAdded
          ? currentNodes.map(node =>
              node.id === canvasId
                ? {
                    ...newCanvasDetails,
                    data: {
                      ...removeUndefined({
                        ...node.data,
                        strokeWidth:
                          strokeProperties?.strokeWidth ||
                          node.data.strokeWidth,
                        strokeColor:
                          strokeProperties?.strokeColor ||
                          node.data.strokeColor,
                      }),
                    },
                    zIndex: CANVAS_NODE_Z_INDEX,
                  }
                : node,
            )
          : currentNodes.concat(newCanvasDetails);
        // NOTE: remove drop area node in 1 state dispatch
        const removedDropAreaNode = updatedNodes.filter(
          node => node.id !== myDropAreaId,
        );

        return removedDropAreaNode;
      });
    },
    [myCanvasId, myDropAreaId, setNodes],
  );
  const removeMyCanvasNode = useCallback(() => {
    if (!myCanvasId) {
      return;
    }
    setNodes(currentNodes => {
      const canvasId = myCanvasId;
      const updatedNodes = currentNodes.filter(node => node.id !== canvasId);
      return updatedNodes;
    });
  }, [myCanvasId, setNodes]);

  const removeMyAreaNodes = useCallback(() => {
    setNodes(currentNodes => {
      const canvasId = myCanvasId;
      const dropAreaId = myDropAreaId;
      const updatedNodes = currentNodes.filter(
        node => node.id !== canvasId && node.id !== dropAreaId,
      );
      return updatedNodes;
    });
  }, [myCanvasId, myDropAreaId]);

  return {
    myDropAreaId,
    myCanvasId,
    setMyDropAreaNode,
    removeMyDropAreaNode,
    setMyCanvasNode,
    removeMyCanvasNode,
    removeMyAreaNodes,
  };
};

// NOTE: can only use this hook inside react-flow-renderer context
export const useGroupNodes = (): {
  onDragNodesStart: (nodeIds: string[]) => void;
  onDragNodesEnd: (nodeIds: string[]) => void;
} => {
  const {
    useMySelectedNodes,
    nodeState,
    useActions,
    edgeState,
  } = useBoardHooks();
  const { getNodes } = useReactFlow();
  const {
    recentlySelectedNodeIds,
    setRecentlySelectedNodeIds,
  } = useMySelectedNodes;
  const { setNodes } = nodeState;
  const { insertAction } = useActions;
  const { useSocket } = useSocketHooks();
  const { socket, initializedBoardCode } = useSocket;
  const { useCurrentUser } = useGlobalState();
  const { currentUser } = useCurrentUser;

  const nodePositions = useRef<{
    [nodeId: string]: { x: number; y: number };
  }>();
  const onDragNodesStart = useCallback(
    (nodeIds: string[]) => {
      const nodes = getNodes().filter(node => nodeIds.includes(node.id));
      const preDragPositions: {
        [nodeId: string]: { x: number; y: number };
      } = nodes.reduce((acc, cur) => {
        return {
          ...acc,
          [cur.id]: {
            x: cur.positionAbsolute ? cur.positionAbsolute.x : cur.position.x,
            y: cur.positionAbsolute ? cur.positionAbsolute.y : cur.position.y,
          },
        };
      }, {});
      nodePositions.current = preDragPositions;
    },
    [recentlySelectedNodeIds],
  );

  const onDragNodesEnd = useCallback(
    (nodeIds: string[]) => {
      const nodes = getNodes().filter(node => nodeIds.includes(node.id));
      const preDragPositions: {
        [nodeId: string]: { x: number; y: number };
      } = Object.keys(nodePositions.current || {}).reduce((acc, cur) => {
        const position = nodePositions.current?.[cur];
        if (position) {
          return {
            ...acc,
            [cur]: {
              x: Number(position.x),
              y: Number(position.y),
            },
          };
        }
        return acc;
      }, {});
      const postDragPositions: {
        [nodeId: string]: { x: number; y: number };
      } = nodes.reduce((acc, cur) => {
        return {
          ...acc,
          [cur.id]: {
            x: cur.positionAbsolute ? cur.positionAbsolute.x : cur.position.x,
            y: cur.positionAbsolute ? cur.positionAbsolute.y : cur.position.y,
          },
        };
      }, {});
      insertAction({
        undo: () => {
          setRecentlySelectedNodeIds([]);
          setNodes(currentNodes => {
            const updatedNodes = currentNodes.map(node => {
              if (preDragPositions[node.id]) {
                const newPosition = preDragPositions[node.id];
                const overlappingNode = findOverlappingNode(
                  { ...node, position: newPosition },
                  currentNodes,
                );
                if (overlappingNode) {
                  return {
                    ...node,
                    parentNode: overlappingNode.id,
                    position: {
                      x: newPosition.x - overlappingNode.position.x,
                      y: newPosition.y - overlappingNode.position.y,
                    },
                    selected: false,
                  };
                }
                return {
                  ...node,
                  position: newPosition,
                  parentNode: undefined,
                  selected: false,
                };
              }
              return node;
            });
            sendSocket(
              updatedNodes,
              edgeState.getEdges(),
              '',
              currentUser?.user?.token,
              socket,
              initializedBoardCode,
            );
            return updatedNodes;
          });
        },
        redo: () => {
          setRecentlySelectedNodeIds([]);
          setNodes(currentNodes => {
            const updatedNodes = currentNodes.map(node => {
              if (postDragPositions[node.id]) {
                const newPosition = postDragPositions[node.id];
                const overlappingNode = findOverlappingNode(
                  { ...node, position: newPosition },
                  currentNodes,
                );
                if (overlappingNode) {
                  return {
                    ...node,
                    parentNode: overlappingNode.id,
                    position: {
                      x: newPosition.x - overlappingNode.position.x,
                      y: newPosition.y - overlappingNode.position.y,
                    },
                    selected: false,
                  };
                }
                return {
                  ...node,
                  position: newPosition,
                  parentNode: undefined,
                  selected: false,
                };
              }
              return node;
            });
            sendSocket(
              updatedNodes,
              edgeState.getEdges(),
              '',
              currentUser?.user?.token,
              socket,
              initializedBoardCode,
            );
            return updatedNodes;
          });
        },
      });

      nodePositions.current = undefined;
    },
    [recentlySelectedNodeIds, setNodes],
  );

  return {
    onDragNodesStart,
    onDragNodesEnd,
  };
};
