import React from 'react';
import GlobalStateProvider from './global/Provider';
import BoardStateProvider from './board/Provider';
import SocketStateProvider from './socket/Provider';
import { AuthHooksContext } from './auth';
import { TodoHooksContext } from './todo';
import { ChatHooksContext } from './chat';
import { LocalStorageHooksContext } from './localStorage';
import { CardHooksContext } from './card';
import { TeamHooksContext } from './team';
import { RoleHooksContext } from './role';
import { PlanHooksContext } from './plan';
import * as authHooks from './auth/hooks';
import * as todoHooks from './todo/hooks';
import * as chatHooks from './chat/hooks';
import * as localStorageHooks from './localStorage/hooks';
import * as cardHooks from './card/hooks';
import * as teamHooks from './team/hooks';
import * as roleHooks from './role/hooks';
import * as planHooks from './plan/hooks';
import * as pageHooks from './page/hooks';
import * as paymentHooks from './payment/hooks';
import { dependencies, Dependencies } from './dependencies';
import { PageHooksContext } from './page';
import { PaymentHooksContext } from './payment';
import { SnackbarProvider } from 'notistack';
import { HelmetProvider } from 'react-helmet-async';

export const DependenciesContext = React.createContext<Dependencies | null>(
  null,
);

export const useDependencies = (): Dependencies => {
  const context = React.useContext(DependenciesContext);
  if (!context) {
    throw new Error(`Context not instantiated`);
  }
  return context;
};

type Props = {
  children?: React.ReactElement | React.ReactElement[];
};

const Provider = ({ children }: Props): React.ReactElement => {
  return (
    <DependenciesContext.Provider value={dependencies}>
      <HelmetProvider>
        <SnackbarProvider
          maxSnack={10}
          classes={{ containerRoot: 'SnackbarProviderMarginTop' }}>
          <GlobalStateProvider>
            <SocketStateProvider>
              <BoardStateProvider>
                {/* put all contexts with hooks starting here */}
                <AuthHooksContext.Provider value={authHooks}>
                  <TodoHooksContext.Provider value={todoHooks}>
                    <ChatHooksContext.Provider value={chatHooks}>
                      <CardHooksContext.Provider value={cardHooks}>
                        <LocalStorageHooksContext.Provider
                          value={localStorageHooks}>
                          <TeamHooksContext.Provider value={teamHooks}>
                            <RoleHooksContext.Provider value={roleHooks}>
                              <PlanHooksContext.Provider value={planHooks}>
                                <PageHooksContext.Provider value={pageHooks}>
                                  <PaymentHooksContext.Provider
                                    value={paymentHooks}>
                                    {children}
                                  </PaymentHooksContext.Provider>
                                </PageHooksContext.Provider>
                              </PlanHooksContext.Provider>
                            </RoleHooksContext.Provider>
                          </TeamHooksContext.Provider>
                        </LocalStorageHooksContext.Provider>
                      </CardHooksContext.Provider>
                    </ChatHooksContext.Provider>
                  </TodoHooksContext.Provider>
                </AuthHooksContext.Provider>
              </BoardStateProvider>
            </SocketStateProvider>
          </GlobalStateProvider>
        </SnackbarProvider>
      </HelmetProvider>
    </DependenciesContext.Provider>
  );
};

export default Provider;
