import React, { SVGProps } from 'react';

type Props = SVGProps<SVGSVGElement>;

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M14.5935 2.70645L12.2936 0.406462C11.7517 -0.135443 10.8745 -0.135531 10.3326 0.406462C9.94682 0.792274 1.4662 9.27295 1.30445 9.43464C1.24052 9.49857 1.19532 9.57872 1.17378 9.66652L0.0147111 14.3868C-0.0742343 14.7491 0.254535 15.0735 0.613276 14.9854C0.858227 14.9252 5.11673 13.8795 5.3335 13.8263C5.42128 13.8047 5.50149 13.7595 5.56539 13.6956C5.73578 13.5252 14.1692 5.09178 14.5935 4.66744C15.1354 4.12553 15.1356 3.24841 14.5935 2.70645ZM1.17062 13.8294L1.21785 13.6372L1.3629 13.7822L1.17062 13.8294ZM2.48655 13.5063L1.49376 12.5135L1.92548 10.7554L4.24462 13.0746L2.48655 13.5063ZM5.21549 12.6459L2.35413 9.78456L9.74295 2.3957L12.6043 5.25707L5.21549 12.6459ZM13.8938 3.96765L13.3222 4.53915L10.4609 1.67777L11.0324 1.10628C11.1875 0.95121 11.4386 0.951063 11.5938 1.10628L13.8938 3.40629C14.0489 3.56142 14.0489 3.81247 13.8938 3.96765Z"
        fill="white"
      />
    </svg>
  );
};

export default Component;
