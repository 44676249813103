import React from 'react';

type Props = {
  [key: string]: string | number | Function;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M24 11.9992C24 5.37225 18.6278 0 11.9999 0C5.3723 0 -0.00012207 5.37225 -0.00012207 11.9992C-0.00012207 18.6262 5.3723 24 11.9999 24C18.628 24 24 18.6262 24 11.9992Z"
        fill="#395196"
      />
      <path
        d="M13.0572 9.15752V8.02084C13.0572 7.4672 13.4266 7.33786 13.6853 7.33786C13.9449 7.33786 15.2807 7.33786 15.2807 7.33786V4.90374L13.0842 4.89404C10.6463 4.89404 10.0926 6.71053 10.0926 7.87345V9.15752H8.68323V12.0009H10.1048C10.1048 15.2228 10.1048 19.1077 10.1048 19.1077H12.9474C12.9474 19.1077 12.9474 15.1832 12.9474 12.0009H15.0571L15.3167 9.15752H13.0572Z"
        fill="white"
      />
    </svg>
  );
};

export default Component;
