import React from 'react';
import styled from 'styled-components';
import theme from '../../../constants/themes';
import { useTranslation } from 'react-i18next';
import { handleTextOverflow } from '../../../utils/strings';
import { Tooltip } from '../../atoms/Tooltip';

const Container = styled.div`
  width: max-content;
  height: max-content;
  display: flex;
  flex-direction: row;

  @media ${theme.breakpoints.mobile} {
    width: 100%;
  }
`;

const TabText = styled.span<{ isActive?: boolean }>`
  font-family: ${theme.fonts.primary};
  font-weight: ${props => (props.isActive ? 700 : 400)};
  font-size: 13px;
  line-height: 19px;
  color: ${theme.colors.textColor};
  cursor: pointer;
  margin-right: 30px;
  border-bottom: ${props =>
    props.isActive ? `3px solid ${theme.colors.notifToastBorder}` : 'none'};

  &:hover {
    font-weight: 700;
  }
`;

export type Props = {
  selected: 'latest' | 'favorite';
  activeItem: 'personal' | 'team';
  teamName?: string;
  onChangeFilter: (selected: 'latest' | 'favorite') => void;
};

const Component = ({
  selected = 'latest',
  activeItem = 'personal',
  teamName = '',
  onChangeFilter,
}: Props): React.ReactElement => {
  const { t } = useTranslation();

  const handleOnClick = (value: 'latest' | 'favorite') => {
    if (value !== selected) {
      onChangeFilter(value);
    }
  };

  const teamFilter = handleTextOverflow(teamName, 20);
  const latestFilterLabel =
    activeItem === 'personal'
      ? t('latestFilter')
      : t('latestTeamFilter', { teamName: teamFilter });

  return (
    <Container>
      {activeItem === 'personal' ? (
        <TabText
          isActive={selected === 'latest'}
          onClick={() => handleOnClick('latest')}>
          {latestFilterLabel}
        </TabText>
      ) : (
        <>
          {teamName.length <= 20 ? (
            <TabText
              isActive={selected === 'latest'}
              onClick={() => handleOnClick('latest')}>
              {latestFilterLabel}
            </TabText>
          ) : (
            <Tooltip label={teamName}>
              <TabText
                isActive={selected === 'latest'}
                onClick={() => handleOnClick('latest')}>
                {latestFilterLabel}
              </TabText>
            </Tooltip>
          )}
        </>
      )}
      <TabText
        isActive={selected === 'favorite'}
        onClick={() => handleOnClick('favorite')}>
        {t('favoriteFilter')}
      </TabText>
    </Container>
  );
};

export default Component;
