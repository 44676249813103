import { GenericResponse } from '../../../domain/entities/rest';
import AuthService from '../../ports/AuthService';

export type deleteUserUseCase = (payload: {
  id: number;
}) => Promise<GenericResponse>;

export const buildDeleteUser = (dependencies: {
  authService: AuthService;
}): deleteUserUseCase => {
  const { authService } = dependencies;

  const deleteUser: deleteUserUseCase = payload =>
    authService.deleteUser(payload);

  return deleteUser;
};
