import React, { CSSProperties, useCallback, useEffect, useRef } from 'react';
import { useReactFlow, Node } from 'react-flow-renderer';
import styled from 'styled-components';
import { generateUniqueString } from '../../../../../utils/strings';
import { DIAGRAM_CONTAINER_ID, LINE_NODE_Z_INDEX } from '../../Component';
import { LineData } from '../../types';
import { DEFAULT_LINE_STYLES, LINE_NODE_DIAMETER } from '../LineNode';
import { useEdgesUtils } from '../hooks/edges';
import { useProjectUtils, useNodesUtils } from '../hooks/nodes';
import { DragLine } from '../../../../atoms/Icons';
import { convertDegree, getSlope } from '../../../../../utils/planes';
import { useBoardHooks } from '../../../../../hooks/board';
import theme from '../../../../../constants/themes';
import { useSocketHooks } from '../../../../../hooks/socket';
import { useGlobalState } from '../../../../../hooks/global';
import { sendSocket } from '../hooks/SendSocket';

const OVERLAP_ADJUST_POINTS = 10;

const Wrapper = styled.div`
  display: inline-flex;
  position: absolute;
`;

const IconWrapper = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.25);
  padding: 5px;
  cursor: crosshair;
  background-color: ${theme.colors.white};
`;

export type Props = {
  style?: CSSProperties;
  width?: number;
  height?: number;
  angle?: number;
  position?: { x: number; y: number };
  parentId: string;
};

const Component = ({
  style,
  width = 0,
  height = 0,
  parentId,
  position,
  angle = 0,
}: Props): React.ReactElement => {
  const { getNodes } = useReactFlow();
  const { insertNewEdges } = useEdgesUtils();
  const { getTranslatedPositions } = useProjectUtils(DIAGRAM_CONTAINER_ID);
  const {
    useActions,
    nodeState,
    edgeState,
    useMySelectedNodes,
    useBoardSocket,
  } = useBoardHooks();
  const { forceDiagramUpdate } = useBoardSocket();
  const onMouseUpMs = useRef<number | undefined>(undefined);

  const { setRecentlySelectedNodeIds } = useMySelectedNodes;
  const { setNodes } = nodeState;
  const { insertAction } = useActions;
  const {
    updateNodePositions,
    getOverlappingNode,
    setParentNode,
    findNode,
  } = useNodesUtils();
  const { useSocket } = useSocketHooks();
  const { socket, initializedBoardCode } = useSocket;
  const { useCurrentUser } = useGlobalState();
  const { currentUser } = useCurrentUser;

  const sourceLineNodeRef = useRef<Node<LineData> | undefined>();
  const targetLineNodeRef = useRef<Node<LineData> | undefined>();
  const parentPositionRef = useRef<{ x: number; y: number } | undefined>();
  const parentAngleRef = useRef<number | undefined>();

  useEffect(() => {
    parentPositionRef.current = position;
  }, [position]);

  useEffect(() => {
    parentAngleRef.current = angle;
  }, [angle]);

  const handleMouseDown = useCallback(() => {
    removeListeners();
    if (targetLineNodeRef.current) {
      const targetNode = findNode(targetLineNodeRef.current.id);
      if (targetNode) {
        const overlappingNode = getOverlappingNode(targetNode);
        if (overlappingNode) {
          setParentNode(targetNode, overlappingNode);
          forceDiagramUpdate();
        }
      }
    }

    sourceLineNodeRef.current = undefined;
    targetLineNodeRef.current = undefined;
  }, []);

  const handleMouseUp = useCallback(() => {
    removeListeners();
    if (targetLineNodeRef.current) {
      const targetNode = findNode(targetLineNodeRef.current.id);
      if (targetNode) {
        const overlappingNode = getOverlappingNode(targetNode);
        if (overlappingNode) {
          setParentNode(targetNode, overlappingNode);
          forceDiagramUpdate();
        }
      }
    }

    sourceLineNodeRef.current = undefined;
    targetLineNodeRef.current = undefined;
  }, []);

  const handleMouseMove = useCallback(ev => {
    ev.preventDefault();
    const translatedPositions = getTranslatedPositions(ev);
    if (
      targetLineNodeRef.current?.id &&
      sourceLineNodeRef.current?.id &&
      parentPositionRef.current &&
      translatedPositions
    ) {
      const sourcePosition = sourceLineNodeRef.current.position;
      const slope = getSlope(
        {
          x: parentPositionRef.current.x + sourceLineNodeRef.current.position.x,
          y: parentPositionRef.current.y + sourceLineNodeRef.current.position.y,
        },
        translatedPositions,
      );

      const sourceX =
        parentPositionRef.current.x + sourceLineNodeRef.current.position.x;
      const sourceY =
        parentPositionRef.current.y + sourceLineNodeRef.current.position.y;
      const targetX = translatedPositions.x;
      const targetY = translatedPositions.y;

      const isRight = slope >= -1 && slope <= 1 && sourceX < targetX;
      const isBottom = (slope <= -1 || slope >= 1) && sourceY < targetY;
      const isLeft = slope >= -1 && slope <= 1 && sourceX > targetX;
      const isTop = (slope <= -1 || slope >= 1) && sourceY > targetY;

      let newSourceX = sourcePosition.x;
      let newSourceY = sourcePosition.y;
      const angle = (parentAngleRef.current || 0) % 90;
      if (isTop) {
        const { x, y } = convertDegree({
          x: 0 + sourceLineNodeRef.current.position.x,
          y: 0,
          angle: angle,
          midPointX: sourceLineNodeRef.current.position.x,
          midPointY: sourceLineNodeRef.current.position.y,
        });
        newSourceX = x - LINE_NODE_DIAMETER / 2;
        newSourceY = y - LINE_NODE_DIAMETER - OVERLAP_ADJUST_POINTS;
      }
      if (isLeft) {
        const { x, y } = convertDegree({
          x: 0,
          y: sourceLineNodeRef.current.position.y,
          angle: angle,
          midPointX: sourceLineNodeRef.current.position.x,
          midPointY: sourceLineNodeRef.current.position.y,
        });
        newSourceX = x - LINE_NODE_DIAMETER - OVERLAP_ADJUST_POINTS;
        newSourceY = y - LINE_NODE_DIAMETER / 2;
      }
      if (isBottom) {
        const { x, y } = convertDegree({
          x: sourceLineNodeRef.current.position.x,
          y: sourceLineNodeRef.current.position.y * 2,
          angle: angle,
          midPointX: sourceLineNodeRef.current.position.x,
          midPointY: sourceLineNodeRef.current.position.y,
        });
        newSourceX = x - LINE_NODE_DIAMETER / 2;
        newSourceY = y + OVERLAP_ADJUST_POINTS;
      }
      if (isRight) {
        const { x, y } = convertDegree({
          x: sourceLineNodeRef.current.position.x * 2,
          y: sourceLineNodeRef.current.position.y,
          angle: angle,
          midPointX: sourceLineNodeRef.current.position.x,
          midPointY: sourceLineNodeRef.current.position.y,
        });
        newSourceX = x + OVERLAP_ADJUST_POINTS;
        newSourceY = y - LINE_NODE_DIAMETER / 2;
      }

      const newSourcePosition = {
        x: newSourceX,
        y: newSourceY,
      };
      updateNodePositions({
        [sourceLineNodeRef.current.id]: newSourcePosition,
        [targetLineNodeRef.current.id]: translatedPositions,
      });
    }
  }, []);

  const removeListeners = () => {
    window.removeEventListener('mousedown', handleMouseDown);
    window.removeEventListener('mousemove', handleMouseMove);
    window.removeEventListener('mouseup', handleMouseUp);
  };

  const addListenersLongPressAction = () => {
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
  };

  const addListenersTapAction = () => {
    window.addEventListener('mousedown', handleMouseDown);
    window.addEventListener('mousemove', handleMouseMove);
  };

  const insertLineNodes = useCallback(
    (source: { x: number; y: number }, target: { x: number; y: number }) => {
      const groupId = `${generateUniqueString()}`;
      const currentNodes = getNodes();
      const updatedNodes: Node[] = currentNodes.map(node =>
        node.id === parentId ? { ...node, selected: false } : node,
      );
      const node1 = {
        id: `line-${generateUniqueString()}`,
        type: 'line',
        connectable: false,
        position: source,
        zIndex: LINE_NODE_Z_INDEX,
        selected: false,
        parentNode: parentId,
      };
      const node2 = {
        id: `line-${generateUniqueString()}`,
        type: 'line',
        connectable: false,
        position: target,
        zIndex: LINE_NODE_Z_INDEX,
        selected: false,
      };
      const newLineNodes: [Node<LineData>, Node<LineData>] = [
        {
          ...node1,
          data: {
            index: 0,
            groupId: groupId,
            lineType: 'straight',
            startArrowType: 'arrow',
            endArrowType: 'arrow',
            target: node2.id,
            style: DEFAULT_LINE_STYLES,
          },
        },
        {
          ...node2,
          data: {
            index: 1,
            groupId: groupId,
            lineType: 'straight',
            startArrowType: 'arrow',
            endArrowType: 'arrow',
            source: node1.id,
            style: DEFAULT_LINE_STYLES,
          },
        },
      ];
      sourceLineNodeRef.current = newLineNodes[0];
      targetLineNodeRef.current = newLineNodes[1];
      setRecentlySelectedNodeIds([node1.id, node2.id]);
      insertNewEdges([
        {
          source: node1.id,
          target: node2.id,
          groupId: groupId,
        },
      ]);
      insertAction({
        undo: () => {
          setNodes(latestNodes => {
            const updatedNodes = latestNodes.filter(
              node =>
                node.id !== newLineNodes[0].id &&
                node.id !== newLineNodes[1].id,
            );
            sendSocket(
              updatedNodes,
              edgeState.getEdges(),
              '',
              currentUser?.user?.token,
              socket,
              initializedBoardCode,
            );
            return updatedNodes;
          });
        },
        redo: () => {
          setNodes(latestNodes => {
            const updatedNodes = latestNodes.concat(newLineNodes);
            sendSocket(
              updatedNodes,
              edgeState.getEdges(),
              '',
              currentUser?.user?.token,
              socket,
              initializedBoardCode,
            );
            return updatedNodes;
          });
        },
      });

      setNodes(updatedNodes.concat(newLineNodes));
      const didMouseUp = onMouseUpMs.current !== undefined;
      if (didMouseUp) {
        addListenersTapAction();
      } else {
        addListenersLongPressAction();
      }

      onMouseUpMs.current = undefined;
    },
    [insertAction],
  );
  return (
    <Wrapper style={style}>
      <IconWrapper
        style={{
          position: 'absolute',
          left: width + 10,
        }}
        className="nodrag"
        onMouseDown={ev => {
          // NOTE: make sure have enough time to check for onMouseUp
          setTimeout(() => {
            const mousePositions = getTranslatedPositions(ev);
            if (mousePositions) {
              insertLineNodes(
                {
                  x: width / 2,
                  y: height / 2,
                },
                mousePositions,
              );
            }
          }, 300);
        }}
        onMouseUp={() => {
          onMouseUpMs.current = new Date().getTime();
        }}>
        <DragLine />
      </IconWrapper>
    </Wrapper>
  );
};

export default Component;
