import { Node } from 'react-flow-renderer';

export const cleanupNodes = (nodes: Node[]): Node[] => {
  const cleanedUpNodes = nodes.map(node => {
    if (node.parentNode) {
      const doesParentStillExist = !!nodes.find(n => n.id === node.parentNode);
      return {
        ...node,
        parentNode: doesParentStillExist ? node.parentNode : undefined,
      };
    }
    return node;
  });
  return cleanedUpNodes;
};
