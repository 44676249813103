import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import theme from '../../../constants/themes';
import { EmailVerified } from '../../atoms/Icons';
import { Button } from '../../atoms/Button';

const VerifiedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 488px;
  height: max-content;

  @media ${theme.breakpoints.mobile} {
    width: 100%;
  }

  @media ${theme.breakpoints.tablet} {
    width: 100%;
  }
`;

const VerifiedTitle = styled.p`
  font-family: ${theme.fonts.secondary};
  font-weight: 700;
  font-size: 34px;
  line-height: 49px;
  color: ${theme.colors.black};
  padding: 0;
  margin: 0;
`;

const StyledVerifiedIcon = styled(EmailVerified)`
  margin-top: 64px;
`;

const RegisteredPrompt = styled.p`
  font-family: ${theme.fonts.primary};
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  color: ${theme.colors.black};
  padding: 0;
  margin: 0;
  margin-top: 26px;
`;

const RegisteredLabels = styled.span`
  font-family: ${theme.fonts.primary};
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  color: ${theme.colors.black};
  padding: 0;
  margin: 0;
  margin-top: 11px;
`;

const MyBoardBtnWrapper = styled.div`
  width: 150px;
  height: 35px;
  margin-top: 20px;
`;

export type Props = {
  onPressMyBoardBtn: () => void;
};

const Component = ({ onPressMyBoardBtn }: Props): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <VerifiedWrapper>
      <VerifiedTitle>{t('emailVerifiedTitle')}</VerifiedTitle>
      <StyledVerifiedIcon />
      <RegisteredPrompt>{t('registered')}</RegisteredPrompt>
      <RegisteredLabels>{t('registeredSubtext')}</RegisteredLabels>
      <MyBoardBtnWrapper>
        <Button
          theme="secondary"
          title={t('toMyBoard')}
          onPress={onPressMyBoardBtn}
        />
      </MyBoardBtnWrapper>
    </VerifiedWrapper>
  );
};

export default Component;
