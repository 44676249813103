import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import theme from '../../../constants/themes';
import { UploadCloud } from '../../atoms/Icons';
import { HowToUseContent } from './types';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import InlineEditor from '@itsmitchyyy/ckeditor5-build-inline';
import { Toast } from '../../atoms/Toast';
import { Props as INotification } from '../../atoms/Toast/Component';
import { usePageHooks } from '../../../hooks/page';
import { useMutation } from 'react-query';
import { removeTags, replaceNbsp } from '../../../utils/strings';
import { useTranslation } from 'react-i18next';

const HowToUseContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5em;
`;

const HowToUseTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  letter-spacing: -0.02em;
  color: ${theme.colors.black};

  span {
    padding-bottom: 0.2em;
    border-bottom: 3px solid ${theme.colors.notifToastBorder};
  }
`;

const HowToUseDetails = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10em;
`;

const HowToUseSteps = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3em;
  gap: 2em;
`;

const StepsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1em;
  align-items: center;
`;

const StepCircle = styled.div`
  border-radius: 50%;
  border: 5px solid #60bf91;
  height: 20px;
  width: 20px;
`;

const StepText = styled.div`
  height: 20px;
  font-family: ${theme.fonts.primary};
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: ${theme.colors.textColor};
`;

const StyledVideo = styled.video`
  width: 100%;
  height: 600px;
`;

const StyledIframe = styled.iframe`
  width: 100%;
  height: 600px;
`;

const HowToUseVideo = styled.div<{ isUploading?: boolean }>`
  display: flex;
  flex: 1;
  position: relative;

  ${StyledVideo} {
    width: 100%;
    height: 450px;
  }

  ${StyledIframe} {
    width: 100%;
    height: 450px;
  }

  ${({ isUploading }) =>
    isUploading
      ? `
  ::after {
    content: 'Uploading Media...';
    background: rgba(255, 255, 255, 0.5);
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${theme.colors.white}
  }`
      : ''}
`;

const UploadFileButton = styled.div`
  background: #ffd200;
  width: 90px;
  height: 70px;
  display: flex;
  position: absolute;
  top: 10px;
  right: 10px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
`;

const StyledUploadCLoud = styled(UploadCloud)`
  height: 30px;
  width: 30px;
`;

const ErrorWrapper = styled.div`
  color: ${theme.colors.errorToastText};
`;

const DivContainer = styled.div<{ hasError?: boolean }>`
  .ck.ck-editor__editable.ck-rounded-corners:not(.ck-editor__nested-editable) {
    ${({ hasError }) =>
      hasError ? `border: 1px solid ${theme.colors.errorToastText}` : ''};
  }
`;

export type Props = {
  howToUseContent: HowToUseContent;
  isFetching?: boolean;
  allowEditContent?: boolean;
  onChangeHowToUseData?: (
    type: 'text' | 'image',
    items: string | string[],
  ) => void;
  onUploadVideo?: (uploading: boolean) => void;
  serverErrors?: any;
};

const Component = ({
  howToUseContent,
  isFetching,
  allowEditContent,
  onChangeHowToUseData,
  onUploadVideo,
  serverErrors,
}: Props): React.ReactElement => {
  const { t } = useTranslation();
  const inputFile = useRef<HTMLInputElement | null>(null);
  const [notification, setNotification] = useState<INotification | null>(null);
  const [uploadedVideoFile, setUploadedVideoFile] = useState<
    string | undefined
  >();
  const { useUploadMedia } = usePageHooks();
  const { uploadMedia } = useUploadMedia();

  const handleClickInputFile = () => {
    if (inputFile) {
      inputFile?.current?.click();
    }
  };

  const fileHandleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;

    if (!fileList) return;

    if (
      !['mp4'].includes(fileList[0]?.type.toLowerCase().replace(/(.*)\//g, ''))
    ) {
      setNotification({
        isOpen: true,
        message: t('invalidVideoFormat'),
        type: 'error',
        position: 'top-right',
        onClose: () => setNotification(null),
      });
      return;
    }

    if (onUploadVideo) {
      onUploadVideo(true);
    }
    uploadMediaMutation(fileList[0]);
  };

  const { mutate: uploadMediaMutation, isLoading } = useMutation(
    (file: File) => {
      return uploadMedia(file);
    },
    {
      onSuccess: data => {
        setUploadedVideoFile(data.url);
        if (onUploadVideo) {
          onUploadVideo(false);
        }
      },
      onError: error => {
        if (onUploadVideo) {
          onUploadVideo(false);
        }
        setNotification({
          isOpen: true,
          message: String(error)
            .replace('Error:', '')
            .trim(),
          type: 'error',
          position: 'top-right',
          onClose: () => setNotification(null),
        });
      },
    },
  );

  useEffect(() => {
    if (uploadedVideoFile && onChangeHowToUseData) {
      onChangeHowToUseData('image', uploadedVideoFile);
    }
  }, [uploadedVideoFile]);

  return (
    <HowToUseContainer id="how_to_use">
      {isFetching ? null : (
        <>
          <HowToUseTitle>
            <span>{howToUseContent.title}</span>
          </HowToUseTitle>
          <HowToUseDetails>
            <HowToUseSteps>
              {allowEditContent ? (
                <DivContainer
                  hasError={
                    serverErrors && !!serverErrors?.[`how_to_use.item`]
                  }>
                  <CKEditor
                    editor={InlineEditor}
                    config={{
                      extraAllowedContent: 'div(*)',
                      allowedContent: true,
                    }}
                    data={`<p>${
                      howToUseContent.item.length
                        ? howToUseContent?.item?.join('</p><p>')
                        : ''
                    }</p>`}
                    onChange={(event: any, editor: any) => {
                      const data = editor.getData();

                      const formattedData = removeTags(data);
                      const newData = formattedData.length
                        ? formattedData
                        : data;

                      if (onChangeHowToUseData) {
                        onChangeHowToUseData('text', newData);
                      }
                    }}
                  />
                  {serverErrors && !!serverErrors?.[`how_to_use.item`] && (
                    <ErrorWrapper>
                      <span>{serverErrors?.[`how_to_use.item`]}</span>
                    </ErrorWrapper>
                  )}
                </DivContainer>
              ) : (
                howToUseContent?.item?.map((item, index) => (
                  <StepsContainer key={index}>
                    <StepCircle />
                    <StepText>{replaceNbsp(item)}</StepText>
                  </StepsContainer>
                ))
              )}
            </HowToUseSteps>
            <DivContainer>
              <HowToUseVideo isUploading={isLoading}>
                {howToUseContent.media?.includes('.mp4') ? (
                  <StyledVideo
                    src={uploadedVideoFile || howToUseContent.media}
                    controls>
                    Your browser does not support the video tag.
                  </StyledVideo>
                ) : (
                  <StyledIframe
                    src={uploadedVideoFile || howToUseContent.media}
                  />
                )}
                {allowEditContent && (
                  <UploadFileButton onClick={handleClickInputFile}>
                    <StyledUploadCLoud />
                    <input
                      ref={inputFile}
                      type="file"
                      id="file"
                      onChange={fileHandleChange}
                      style={{ display: 'none' }}
                    />
                  </UploadFileButton>
                )}
              </HowToUseVideo>
              {serverErrors && !!serverErrors?.[`how_to_use.media`] && (
                <ErrorWrapper>
                  <span>{serverErrors?.[`how_to_use.media`]}</span>
                </ErrorWrapper>
              )}
            </DivContainer>
          </HowToUseDetails>
        </>
      )}
      {notification ? <Toast {...notification} /> : null}
    </HowToUseContainer>
  );
};

export default Component;
