import { GenericResponse } from '../../../domain/entities/rest';
import BoardRepository from '../../ports/BoardRepository';

export type saveBoardUseCase = (payload: {
  xmlString?: string;
  boardCode: string;
}) => Promise<GenericResponse>;

export const buildSaveBoard = (dependencies: {
  boardRepo: BoardRepository;
}): saveBoardUseCase => {
  const { boardRepo } = dependencies;

  const saveBoard: saveBoardUseCase = async payload =>
    boardRepo.saveBoard(payload);

  return saveBoard;
};
