import React, { useCallback, useMemo, CSSProperties } from 'react';
import styled from 'styled-components';
import { Handle, Position } from 'react-flow-renderer';
import { PropertiesPane } from '../../../molecules/PropertiesPane';
import { LineData } from '../types';
import { useNodesUtils, useNodeUtils } from './hooks/nodes';
import { useBoardHooks } from '../../../../hooks/board';
import Overlay from './common/Overlay';
import { getBoundingBoxHeightWidth } from '../../../../utils/planes';
import { useSocketHooks } from '../../../../hooks/socket';
import { useGlobalState } from '../../../../hooks/global';
import { sendSocket } from './hooks/SendSocket';

const DASH_TYPE_TO_DASH_ARRAY_MAPPER: {
  [key: string]: number;
} = {
  straight: 0,
  broken1: 3,
  broken2: 10,
};
export const DEFAULT_LINE_STYLES: CSSProperties = {
  stroke: 'black',
  strokeWidth: 3,
};
export const LINE_NODE_DIAMETER = 12;

const StyledHandle = styled(Handle)`
  opacity: ${({ $selected }: { $selected?: boolean }) =>
    $selected ? '100%' : '0%'};
`;

const Component = (props: {
  id: string;
  data: LineData;
  selected: boolean;
}): React.ReactElement => {
  const { data, selected, id } = props;
  const {
    groupId,
    lineType,
    startArrowType,
    endArrowType,
    style,
  }: LineData = data;
  const {
    nodeState,
    edgeState,
    useMySelectedNodes,
    customersNodeIdsState,
  } = useBoardHooks();

  const { useSocket } = useSocketHooks();
  const { socket, initializedBoardCode } = useSocket;
  const { useCurrentUser } = useGlobalState();
  const { currentUser } = useCurrentUser;

  const { recentlySelectedNodeIds } = useMySelectedNodes;
  const { setNodes, getNodes, nodes } = nodeState;
  const {
    handleCopyNodes,
    isMultipleSelected,
    isSelectedByCurrentUser,
  } = useNodeUtils(id);
  const { handlePasteNodes, removeNodes } = useNodesUtils();
  const isSelected = isSelectedByCurrentUser && selected;
  const isGroupSelected = useMemo(() => {
    const associatedGroupNode = getNodes()?.find(
      node => node.selected && node.data.groupId === groupId,
    );
    return (
      associatedGroupNode &&
      recentlySelectedNodeIds.includes(associatedGroupNode.id)
    );
  }, [recentlySelectedNodeIds]);

  const { customersSelectedNodeIds } = customersNodeIdsState;
  const selectedNodeIdsState = customersSelectedNodeIds
    ? Object.values(customersSelectedNodeIds).flat()
    : [];
  const isLocked = useMemo(() => {
    const associatedGroupNode = nodes?.some(
      node =>
        node.data.groupId === groupId && selectedNodeIdsState.includes(node.id),
    );
    return associatedGroupNode;
  }, [nodes, selectedNodeIdsState]);

  const boundingBox = useMemo(() => {
    let maxYIndex = -1;
    let maxXIndex = -1;
    let minYIndex = -1;
    let minXIndex = -1;
    let mainSourceXY = undefined;

    const associatedGroupNode = nodes?.filter(
      node => node.data.groupId === groupId,
    );
    const mappedGroupNodes = associatedGroupNode.map(item => {
      const parentNode = nodes.find(node => node.id === item.parentNode);
      if (item.parentNode && parentNode) {
        const newPos = {
          x:
            (item.positionAbsolute
              ? item.positionAbsolute.x
              : item.position.x) +
            (parentNode.positionAbsolute
              ? parentNode.positionAbsolute.x
              : parentNode.position.x),
          y:
            (item.positionAbsolute
              ? item.positionAbsolute.y
              : item.position.y) +
            (parentNode.positionAbsolute
              ? parentNode.positionAbsolute.y
              : parentNode.position.y),
        };
        return {
          pos: newPos,
          id: item.id,
        };
      } else {
        return {
          pos: item.position,
          id: item.id,
        };
      }
    });

    const coordinates = mappedGroupNodes.map(item => item.pos);
    const yCoords = coordinates.map(c => c.y);
    const xCoords = coordinates.map(c => c.x);

    const maxY = Math.max(...yCoords);
    const minY = Math.min(...yCoords);
    const maxX = Math.max(...xCoords);
    const minX = Math.min(...xCoords);

    if (data?.source && !data.target) {
      maxYIndex = mappedGroupNodes.findIndex(
        item => item.id === id && item.pos.y === maxY,
      );
      minYIndex = mappedGroupNodes.findIndex(
        item => item.id === id && item.pos.y === minY,
      );
      minXIndex = mappedGroupNodes.findIndex(
        item => item.id === id && item.pos.x === minX,
      );
      maxXIndex = mappedGroupNodes.findIndex(
        item => item.id === id && item.pos.x === maxX,
      );
      mainSourceXY = mappedGroupNodes.find(item => item.id === id)?.pos;
    }

    return {
      ...getBoundingBoxHeightWidth(coordinates),
      isMaxYMinX: maxYIndex >= 0 && minXIndex >= 0 && maxYIndex === minXIndex,
      isMinYMaxX: minYIndex >= 0 && maxXIndex >= 0 && minYIndex === maxXIndex,
      isMaxCoordinates:
        maxYIndex >= 0 && maxXIndex >= 0 && maxYIndex === maxXIndex,
      isOnlyMinX: minXIndex >= 0 && minYIndex === -1,
      isOnlyMaxX: maxXIndex >= 0 && minYIndex === -1,
      isOnlyMinY: minXIndex === -1 && minYIndex >= 0,
      isNotMinMaxXY: maxXIndex === -1 && minYIndex === -1,
      maxY,
      minY,
      maxX,
      minX,
      mainSourceXY,
    };
  }, [nodes, data]);

  const overlayStyle = useMemo(
    () => ({
      top:
        boundingBox.isMaxYMinX || boundingBox.isMaxCoordinates
          ? -(boundingBox.h + 10)
          : (boundingBox.isOnlyMinX ||
              boundingBox.isOnlyMaxX ||
              boundingBox.isNotMinMaxXY) &&
            boundingBox.mainSourceXY
          ? -(boundingBox.mainSourceXY.y - boundingBox.minY + 10)
          : -10,
      left:
        boundingBox.isMaxCoordinates || boundingBox.isMinYMaxX
          ? -(boundingBox.w + 5)
          : (boundingBox.isOnlyMaxX ||
              boundingBox.isNotMinMaxXY ||
              boundingBox.isOnlyMinY) &&
            boundingBox.mainSourceXY
          ? -(boundingBox.mainSourceXY.x - boundingBox.minX + 5)
          : -10,
    }),
    [boundingBox],
  );

  const handleUpdateStyle = useCallback(
    (style: CSSProperties) => {
      setNodes(currentNodes => {
        const updatedNodes = currentNodes.map(node => {
          if (node.type === 'line' && node.data?.groupId === groupId) {
            return {
              ...node,
              data: {
                ...node.data,
                style,
              },
            };
          }
          return node;
        });
        sendSocket(
          updatedNodes,
          edgeState.getEdges(),
          '',
          currentUser?.user?.token,
          socket,
          initializedBoardCode,
        );
        return updatedNodes;
      });
    },
    [setNodes],
  );

  const onClickDelete = () => {
    removeNodes([id]);
  };

  return (
    <>
      {isSelected && !isMultipleSelected && !isLocked && (
        <div style={{ position: 'absolute', top: -100 }}>
          <PropertiesPane
            color={style?.stroke}
            strokeWidth={
              typeof style?.strokeWidth === 'number' ? style?.strokeWidth : 1
            }
            dashType={
              (style?.strokeDasharray ===
                DASH_TYPE_TO_DASH_ARRAY_MAPPER.straight &&
                'straight') ||
              (style?.strokeDasharray ===
                DASH_TYPE_TO_DASH_ARRAY_MAPPER.broken1 &&
                'broken1') ||
              (style?.strokeDasharray ===
                DASH_TYPE_TO_DASH_ARRAY_MAPPER.broken2 &&
                'broken2') ||
              undefined
            }
            lineType={lineType}
            startNode={startArrowType}
            endNode={endArrowType}
            onChangeColor={color => {
              handleUpdateStyle({ ...style, stroke: color });
            }}
            onChangeDashType={dashType => {
              handleUpdateStyle({
                ...style,
                strokeDasharray: DASH_TYPE_TO_DASH_ARRAY_MAPPER[dashType],
              });
            }}
            onChangeStrokeWidth={strokeWidth => {
              handleUpdateStyle({
                ...style,
                strokeWidth: strokeWidth,
              });
            }}
            onChangeLineType={type => {
              setNodes(currentNodes => {
                const lineGroupListIds: string[] = currentNodes
                  .filter(
                    currentNode =>
                      groupId && currentNode.data?.groupId === groupId,
                  )
                  .map(currentNode => currentNode.id);
                const updatedList = currentNodes.map(node => {
                  if (lineGroupListIds.includes(node.id)) {
                    return {
                      ...node,
                      data: {
                        ...node.data,
                        lineType: type,
                      },
                    };
                  }
                  return node;
                });
                sendSocket(
                  updatedList,
                  edgeState.getEdges(),
                  '',
                  currentUser?.user?.token,
                  socket,
                  initializedBoardCode,
                );
                return updatedList;
              });
            }}
            onChangeStartNode={startArrowType => {
              setNodes(currentNodes => {
                const lineGroupListIds: string[] = currentNodes
                  .filter(
                    currentNode =>
                      groupId && currentNode.data?.groupId === groupId,
                  )
                  .map(currentNode => currentNode.id);
                const updatedList = currentNodes.map(node => {
                  if (lineGroupListIds.includes(node.id)) {
                    return {
                      ...node,
                      data: {
                        ...node.data,
                        startArrowType,
                      },
                    };
                  }
                  return node;
                });
                sendSocket(
                  updatedList,
                  edgeState.getEdges(),
                  '',
                  currentUser?.user?.token,
                  socket,
                  initializedBoardCode,
                );
                return updatedList;
              });
            }}
            onChangeEndNode={endArrowType => {
              setNodes(currentNodes => {
                const lineGroupListIds: string[] = currentNodes
                  .filter(
                    currentNode =>
                      groupId && currentNode.data?.groupId === groupId,
                  )
                  .map(currentNode => currentNode.id);
                const updatedList = currentNodes.map(node => {
                  if (lineGroupListIds.includes(node.id)) {
                    return {
                      ...node,
                      data: {
                        ...node.data,
                        endArrowType,
                      },
                    };
                  }
                  return node;
                });
                sendSocket(
                  updatedList,
                  edgeState.getEdges(),
                  '',
                  currentUser?.user?.token,
                  socket,
                  initializedBoardCode,
                );
                return updatedList;
              });
            }}
            onClickCopy={handleCopyNodes}
            onClickPaste={handlePasteNodes}
            onClickDelete={onClickDelete}
          />
        </div>
      )}
      {isLocked && boundingBox && data?.source && !data.target && (
        <Overlay
          width={boundingBox.w + 10}
          height={boundingBox.h + 10}
          style={overlayStyle}
        />
      )}
      <div
        style={{
          height: LINE_NODE_DIAMETER,
          width: LINE_NODE_DIAMETER,
          opacity:
            (isSelected || isGroupSelected || isSelectedByCurrentUser) &&
            !isLocked
              ? 100
              : 0,
          borderRadius: '50%',
          background: 'white',
          border: 'solid silver 1px',
        }}
      />
      <StyledHandle
        type="source"
        $selected={false}
        isConnectable={false}
        position={Position.Top}
        style={{ height: 0, width: 0, top: 6 }}
      />
    </>
  );
};

export default Component;
