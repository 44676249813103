import { useCallback, useEffect, useState, useRef } from 'react';
import { ChatHooks } from '.';
import { useSocketHooks } from '../socket';
import { useGlobalState } from '../global';
import { useDependencies } from '..';
import { Messages } from '../../domain/entities/chat';
import events from '../../constants/socket';
import { ChatMessages } from '.';

export const useChat: ChatHooks['useChat'] = (
  boardCode: string,
): {
  messages: ChatMessages;
  setMessages: (messages: ChatMessages) => void;
  sendMessage: (message: {
    customerId: number;
    avatar: string;
    boardCode: string;
    message: string;
    username: string;
    role: string;
    token: string;
    mentionId: string;
  }) => void;
  isChatBoxOpen: boolean;
  setChatBoxOpen: (value: boolean) => void;
  mentionCount: number;
  setMentionCount: (value: number) => void;
  scrollToMention: boolean;
  setScrollToMention: (value: boolean) => void;
  fetchNewMessages: (boardCode: string) => Promise<Messages | undefined>;
} => {
  const { chatInteractor } = useDependencies();
  const { useCurrentUser } = useGlobalState();
  const { useSocket } = useSocketHooks();
  const { socket } = useSocket;
  const { currentUser } = useCurrentUser;
  const [messages, setMessages] = useState<ChatMessages>([]);
  const [isChatBoxOpen, setChatBoxOpen] = useState<boolean>(false);
  const [scrollToMention, setScrollToMention] = useState<boolean>(false);
  const [mentionCount, setMentionCount] = useState<number>(0);
  const messagesRef = useRef<ChatMessages>([]);
  const chatBoxOpenRef = useRef<boolean>(false);
  const scrollToMentionRef = useRef<boolean>(false);

  const fetchNewMessages = useCallback(async (boardCode: string) => {
    const messages = await chatInteractor.fetchMessagesOnBoard(boardCode);
    return messages;
  }, []);

  useEffect(() => {
    if (messagesRef.current) {
      messagesRef.current = messages;
    }
  }, [messages, messagesRef]);

  useEffect(() => {
    if (socket) {
      socket.on(events.SERVER.ROOM_MESSAGE, data => {
        const mentionIds = data.mentionId.split(',');
        const currentUserId = currentUser?.user.customer.id;
        const isMentioned = mentionIds.includes(currentUserId?.toString());
        let scrollToUnreadMention = false;
        if (isMentioned && !chatBoxOpenRef.current) {
          setMentionCount(preValue => preValue + 1);
        }
        if (
          isMentioned &&
          !chatBoxOpenRef.current &&
          !scrollToMentionRef.current
        ) {
          setScrollToMention(true);
          scrollToUnreadMention = true;
        }
        setMessages([
          ...messagesRef?.current,
          {
            message: data.message,
            displayPhoto: data.avatar,
            displayTime: data.time,
            name: data.username,
            role: data.role,
            mentionId: data.mentionId,
            isMentioned,
            isRead: isChatBoxOpen,
            scrollToUnreadMention,
            id: Math.random(),
            boardCode,
          },
        ]);
      });
    }
  }, [boardCode, socket]);

  useEffect(() => {
    if (isChatBoxOpen) {
      setScrollToMention(false);
      setMentionCount(0);
    }
    chatBoxOpenRef.current = isChatBoxOpen;
  }, [isChatBoxOpen]);

  useEffect(() => {
    scrollToMentionRef.current = scrollToMention;
  }, [scrollToMention]);

  useEffect(() => {
    return () => {
      setChatBoxOpen(false);
      chatBoxOpenRef.current = false;
      messagesRef.current = [];
      setMessages([]);
    };
  }, []);

  const sendMessage = (messageBody: {
    customerId: number;
    avatar: string;
    message: string;
    username: string;
    role: string;
    token: string;
    mentionId: string;
  }) => {
    const date = new Date();
    if (socket) {
      setMessages([
        ...messages,
        {
          message: messageBody.message,
          displayPhoto: messageBody.avatar,
          name: messageBody.username,
          displayTime: `${date.getHours()}:${(date.getMinutes() < 10
            ? '0'
            : '') + date.getMinutes()}`,
          role: messageBody.role,
          mentionId: messageBody.mentionId,
          boardCode,
          // TODO: Will update value once api is updated
          id: Math.random(),
          isMentioned: false,
          isRead: true,
        },
      ]);

      const eventData = {
        ...messageBody,
        board_code: boardCode,
      };

      socket.emit(events.CLIENT.SEND_ROOM_MESSAGE, eventData);
    }
  };

  return {
    messages,
    setMessages,
    sendMessage,
    fetchNewMessages,
    isChatBoxOpen,
    setChatBoxOpen,
    mentionCount,
    setMentionCount,
    scrollToMention,
    setScrollToMention,
  };
};
