import React from 'react';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import styled from 'styled-components';
import theme from '../../../constants/themes';

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: '5px',
    borderRadius: 0,
    fontFamily: theme.fonts.primary,
    maxWidth: '300px',
    color: theme.colors.white,
  },
}));

export type Props = {
  label: string;
  children: JSX.Element;
  position?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
  className?: string;
};

const Component = ({
  label,
  children,
  position = 'bottom',
  className,
}: Props): React.ReactElement => {
  return (
    <StyledTooltip
      className={className}
      title={label}
      placement={position}
      arrow
      disableFocusListener
      disableTouchListener>
      {children}
    </StyledTooltip>
  );
};

export default Component;
