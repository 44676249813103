import React, { useState } from 'react';
import styled from 'styled-components';
import theme from '../../../constants/themes';
import invoiceTab from '../../../assets/images/invoice-tab.svg';
import activeInvoiceTab from '../../../assets/images/active-invoice-tab.svg';
import { useTranslation } from 'react-i18next';
import { MyBoardTeam } from '../../../domain/entities/team';
import { Button } from '../../atoms/Button';
import {
  Plus,
  GrayPlus,
  AccordionArrow,
  ProfileTab,
  ActiveProfileTab,
} from '../../atoms/Icons';
import { MyBoardItem } from '../../atoms/MyBoardItem';
import { MyBoardSubItem } from '../../atoms/MyBoardSubItem';
import { isWindowsOS } from '../../../utils/booleans';

const Container = styled.div`
  width: 300px;
  height: calc(100vh - 50px);
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 50px;
  z-index: 998;
  background-color: ${theme.colors.myboardMenuBg};
`;

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const NewBoardBtn = styled(Button)`
  width: 200px;
  height: 44px;
  margin-top: 21px;
  margin-bottom: 7px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  align-self: center;
`;

const StyledAccordionArrow = styled(AccordionArrow)`
  position: absolute;
  right: 20px;
  align-self: center;
`;

const TeamsLabelWrapper = styled.div<{ isActive?: boolean; isopen?: boolean }>`
  width: 279px;
  height: 66px;
  padding-left: ${props => (props?.isActive ? 18 : 21)}px;

  display: flex;
  align-items: center;
  font-family: ${theme.fonts.primary};
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: ${props =>
    props.isActive ? theme.colors.notifToastBorder : theme.colors.textColor};
  background-color: ${theme.colors.myboardMenuBg};
  position: relative;
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.myBoardMenuBgHover};
  }

  border-left: ${props =>
    props?.isActive ? '3px solid ' + theme.colors.notifToastBorder : 'none'};

  ${StyledAccordionArrow} {
    transform: ${props => (props.isopen ? `rotate(180deg)` : `rotate(0deg)`)};
  }
`;

const TeamLabel = styled.span<{ isActive: boolean }>`
  padding-top: ${isWindowsOS() ? 6 : 0}px;
  margin-left: 21px;
  color: ${props =>
    props.isActive ? theme.colors.notifToastBorder : theme.colors.textColor};
`;

const TeamsWrapper = styled.div<{ isopen?: boolean }>`
  display: ${props => (props.isopen ? 'block' : 'none')};
  width: 300px;
  height: max-content;
  max-height: calc(100vh - 50px - 276px);
  overflow-y: auto;
  overflow-x: hidden;
`;

const AddTeamBtn = styled(Button)`
  width: 133px;
  height: 44px;
  border-radius: 5px;
  margin: 11px 0px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  align-self: center;
`;

export type Props = {
  teams: MyBoardTeam[];
  activeItem: 'team' | 'invoice';
  onPressAddTeam: () => void;
  onPressAddTeamMember: () => void;
  teamId?: number;
};

const Component = ({
  teams,
  activeItem,
  teamId,
  onPressAddTeam,
  onPressAddTeamMember,
}: Props): React.ReactElement => {
  const { t } = useTranslation();
  const [isTeamListOpen, setIsTeamListOpen] = useState(true);

  const renderProfileIcon = () => {
    switch (activeItem) {
      case 'team':
        return <ActiveProfileTab />;
      case 'invoice':
        return <ProfileTab />;
      default:
        return <ActiveProfileTab />;
    }
  };

  return (
    <Container>
      <ContentWrapper>
        <NewBoardBtn
          title={t('addTeamMember')}
          theme="lightSecondary"
          onPress={onPressAddTeamMember}
          icon={<Plus />}
        />
        <TeamsLabelWrapper
          isActive={activeItem === 'team'}
          isopen={isTeamListOpen}
          onClick={() => setIsTeamListOpen(!isTeamListOpen)}>
          {renderProfileIcon()}
          <TeamLabel isActive={activeItem === 'team'}>
            {t('teamMemberManagement')}
          </TeamLabel>
          <StyledAccordionArrow />
        </TeamsLabelWrapper>
        <TeamsWrapper isopen={isTeamListOpen}>
          {teams.length > 0 &&
            teams.map(team => {
              return (
                <MyBoardSubItem
                  className={teamId === team.id ? 'active' : ''}
                  isManagement
                  key={team.id}
                  to={`/my-board/member-management/${team.id}`}
                  label={team.name}
                />
              );
            })}
        </TeamsWrapper>
        <AddTeamBtn
          theme="blackHollow"
          title={t('addTeam')}
          icon={<GrayPlus />}
          onPress={onPressAddTeam}
        />
        <MyBoardItem
          to="/my-board/plan-billing"
          label={t('invoice')}
          icon={invoiceTab}
          activeIcon={activeInvoiceTab}
        />
      </ContentWrapper>
    </Container>
  );
};

export default Component;
