import React from 'react';

type Props = {
  [key: string]: string | number | Function | object;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="22"
      height="21"
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M21.5438 10.2683L12.1673 0.854432L11.5388 0.2231C11.3956 0.0802005 11.2019 0 11 0C10.7982 0 10.6045 0.0802005 10.4613 0.2231L0.456308 10.2683C0.309572 10.4152 0.193604 10.59 0.11525 10.7826C0.036895 10.9752 -0.0022583 11.1816 0.000100583 11.3896C0.00980713 12.2477 0.720812 12.9326 1.57499 12.9326H2.60631V20.8718H19.3938V12.9326H20.4469C20.8619 12.9326 21.2526 12.7693 21.5462 12.4744C21.6908 12.3296 21.8053 12.1575 21.8832 11.9679C21.9611 11.7784 22.0008 11.5752 22 11.3701C22 10.9557 21.8374 10.5633 21.5438 10.2683ZM12.359 19.1167H9.64113V14.1441H12.359V19.1167ZM17.6466 11.1776V19.1167H13.912V13.5591C13.912 13.0204 13.4776 12.584 12.9414 12.584H9.05873C8.52245 12.584 8.08808 13.0204 8.08808 13.5591V19.1167H4.35349V11.1776H2.02392L11.0025 2.16585L11.563 2.72893L19.9786 11.1776H17.6466Z"
        fill="#60BF91"
      />
    </svg>
  );
};

export default Component;
