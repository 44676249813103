import { useGoogleLogin } from '@react-oauth/google';
import axios, { ResponseType } from 'axios';
import createDOMPurify from 'dompurify';
import React, { useCallback, useState } from 'react';
import {
  ReactFacebookFailureResponse,
  ReactFacebookLoginInfo,
} from 'react-facebook-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { useTranslation } from 'react-i18next';
import MicrosoftLogin from 'react-microsoft-login';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import theme from '../../../constants/themes';
import urls from '../../../constants/urls';
import { MicrosoftError } from '../../../domain/entities/errors';
import { useAuthHooks } from '../../../hooks/auth';
import { useLocalStorageHooks } from '../../../hooks/localStorage';
import { Button } from '../../atoms/Button';
import {
  Facebook,
  Flagman,
  Google,
  Microsoft,
  RightArrow,
} from '../../atoms/Icons';
import { Toast } from '../../atoms/Toast';
import { Props as INotification } from '../../atoms/Toast/Component';
import { blobToBase64 } from '../../templates/Login/Component';
import {
  FbLoginValues,
  GoogleLoginValues,
  MicrosoftValues,
} from '../../templates/Login/types';
import { AddressContent, FaqContent, FooterContent } from '../TopFooter/types';

const Container = styled.div`
  background-color: ${theme.colors.topFooterBg};
  display: flex;
  flex-direction: column;

  @media ${theme.breakpoints.pc} {
    display: none;
  }
`;

const LinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1em 0.5em;

  @media ${theme.breakpoints.tablet} {
    justify-content: center;
  }
`;

const LinkTitle = styled.div`
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: ${theme.colors.black};
  margin-bottom: 7px;
`;

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;

  :not(:last-of-type) {
    margin-right: 4em;
  }

  div {
    a {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 19px;
      letter-spacing: -0.02em;
      text-decoration: none;
      color: ${theme.colors.black};
      margin-bottom: 15px;

      :hover {
        text-decoration: underline;
      }
    }
  }
`;

const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 3em;
  text-align: center;
`;

const StyledFlagman = styled(Flagman)`
  width: 177px;
  height: 80px;
  margin-bottom: 7px;
  margin-top: 16px;
`;

const StyledButton = styled(Button)`
  width: 130px;
  height: 58px;
`;

const SocialMediaContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2em;
  margin-top: 16px;
`;

const StyledGoogle = styled(Google)`
  && {
    width: 16px;
    height: 16px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.5));
      transform: translateY(-0.15em);
    }
  }
`;

const StyledFacebook = styled(Facebook)`
  && {
    width: 24px;
    height: 24px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      filter: drop-shadow(4px 4px 4px rgba(57, 81, 150, 0.5));
      transform: translateY(-0.15em);
    }
  }
`;

const StyledMicrosoft = styled(Microsoft)`
  && {
    width: 24px;
    height: 24px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      filter: drop-shadow(4px 4px 4px rgba(234, 62, 35, 0.5));
      transform: translateY(-0.15em);
    }
  }
`;

const AddressContentText = styled.div`
  :not(:first-of-type) {
    margin-top: 0.5em;
  }
`;

const FaqContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1.5em;
`;

const FaqContentContainer = styled.div`
  display: flex;
  flex: 1;
  padding: 0.5em;
  align-items: center;

  :nth-of-type(2) {
    justify-content: center;
  }

  :last-of-type {
    justify-content: flex-end;
  }

  a {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
    text-align: center;
    letter-spacing: -0.02em;
    color: ${theme.colors.black};
    text-decoration: none;
  }
`;

const DOMPurify = createDOMPurify(window as any);

export type Props = {
  footerContents: FooterContent;
  addressContents: AddressContent;
  faqsContents: FaqContent;
  isFetching?: boolean;
  onPressRegistration: () => void;
};

const Component = ({
  isFetching,
  footerContents,
  faqsContents,
  addressContents,
  onPressRegistration,
}: Props): React.ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { useLogin, useFetchUser } = useAuthHooks();
  const { fetchGoogleUserData } = useFetchUser();
  const { googleLogin, fbLogin, microsoftLogin } = useLogin();
  const { useLocalStorage } = useLocalStorageHooks();
  const { clearSessionStorage } = useLocalStorage();
  const loginGoogle = useGoogleLogin({
    onSuccess: async tokenResponse => {
      const userInfo = await fetchGoogleUserData(tokenResponse.access_token);

      googleLoginMutation({
        email: userInfo.email,
        name: userInfo.name,
        googleId: userInfo.sub,
        invitationCode: '',
        avatar: userInfo.picture,
      });
    },
  });
  const [notification, setNotification] = useState<INotification | null>(null);

  const handleCloseToast = () => {
    setNotification(null);
  };

  const { mutate: googleLoginMutation } = useMutation(
    (params: GoogleLoginValues) => {
      return googleLogin(
        params.email,
        params.name,
        params.googleId,
        '',
        params.avatar,
      );
    },
    {
      onSuccess: () => {
        navigate('/my-board/personal');
      },
      onError: response => {
        setNotification({
          isOpen: true,
          message: String(response)
            .replace('Error:', '')
            .trim(),
          type: 'error',
          position: 'top-right',
          onClose: handleCloseToast,
        });
      },
    },
  );

  const { mutate: fbLoginMutation } = useMutation(
    (params: FbLoginValues) => {
      return fbLogin(
        params.email,
        params.name,
        params.userID,
        '',
        params.avatar,
      );
    },
    {
      onSuccess: () => {
        navigate('/my-board/personal');
      },
      onError: response => {
        setNotification({
          isOpen: true,
          message: String(response)
            .replace('Error:', '')
            .trim(),
          type: 'error',
          position: 'top-right',
          onClose: handleCloseToast,
        });
      },
    },
  );

  const { mutate: microsoftLoginMutation } = useMutation(
    (params: MicrosoftValues) => {
      return microsoftLogin(
        params.account.userName,
        params.account.name,
        params.account.accountIdentifier,
        '',
        params.avatar,
      );
    },
    {
      onSuccess: () => {
        navigate('/my-board/personal');
      },
      onError: response => {
        setNotification({
          isOpen: true,
          message: String(response)
            .replace('Error:', '')
            .trim(),
          type: 'error',
          position: 'top-right',
          onClose: handleCloseToast,
        });
      },
    },
  );

  const handleMicrosoftLogin = useCallback(async (err, data) => {
    const error: MicrosoftError = err;
    const response = data as MicrosoftValues;

    if (response?.uniqueId) {
      // Note: This is for preventing the Microsoft Button to call the auth every time the page loads
      clearSessionStorage();
      const options = {
        headers: {
          Authorization: `Bearer ${response.accessToken}`,
          'Content-Type': 'image/jpg',
        },
        responseType: 'blob' as ResponseType,
      };
      const photo = await axios
        .get(urls.microsoft.profilePhoto, options)
        .then(async response => {
          const base64 = await blobToBase64(response.data).then(
            base64data => base64data,
          );
          return base64 as string;
        })
        .catch(function(err) {
          console.log(err);
          return '';
        });

      microsoftLoginMutation({ ...response, avatar: photo ?? '' });
    } else if (!!error && error.errorCode !== 'user_cancelled') {
      setNotification({
        isOpen: true,
        message: `Microsoft Error: ${error.errorMessage}`,
        type: 'error',
        position: 'top-right',
        onClose: handleCloseToast,
      });
    }
  }, []);

  const submitFbLogin = useCallback((response: ReactFacebookLoginInfo) => {
    fbLoginMutation({
      email: response.email || '',
      name: response.name || response.userID,
      userID: response.userID,
      invitationCode: '',
      avatar: response.picture?.data.url || '',
    });
  }, []);

  const handleFailureFb = useCallback(
    (response: ReactFacebookFailureResponse) => {
      if (response.status !== 'unknown') {
        setNotification({
          isOpen: true,
          message: `Facebook Error: ${response.status}`,
          type: 'error',
          position: 'top-right',
          onClose: handleCloseToast,
        });
      }
    },
    [],
  );

  return (
    <Container>
      <LinksContainer>
        {isFetching ? null : (
          <>
            {footerContents?.map((content, index) => (
              <LinksWrapper key={index}>
                <LinkTitle
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(content.title),
                  }}
                />
                {content.item.map((link, linkIndex) => (
                  <div
                    key={linkIndex}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(link),
                    }}
                  />
                ))}
              </LinksWrapper>
            ))}
          </>
        )}
      </LinksContainer>
      <AddressContainer>
        <StyledButton
          theme="lightSecondary"
          title={t('topSignup')}
          icon={<RightArrow />}
          iconPosition="right"
          iconMargin={15}
          onPress={onPressRegistration}
        />
        <SocialMediaContainer>
          <StyledGoogle onClick={loginGoogle} />
          <FacebookLogin
            appId={process.env.REACT_APP_FB_APP_ID || ''}
            callback={submitFbLogin}
            fields="name,picture,email"
            onFailure={handleFailureFb}
            render={(renderProps: { onClick: string | number | Function }) => (
              <StyledFacebook onClick={renderProps.onClick} />
            )}
          />
          <MicrosoftLogin
            clientId={process.env.REACT_APP_MICROSOFT_CLIENT_ID || ''}
            authCallback={handleMicrosoftLogin}>
            <StyledMicrosoft />
          </MicrosoftLogin>
        </SocialMediaContainer>
        <StyledFlagman />
        <AddressContentText
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(addressContents.address),
          }}
        />
        <AddressContentText
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(addressContents.telephoneNumber),
          }}
        />
        <AddressContentText
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(addressContents.faxNumber),
          }}
        />
      </AddressContainer>
      <FaqContainer>
        <FaqContentContainer
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(faqsContents.privacyPolicy),
          }}
        />
        <FaqContentContainer
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(faqsContents.termsOfService),
          }}
        />
        <FaqContentContainer
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(faqsContents.transactionLaw),
          }}
        />
      </FaqContainer>

      {notification ? <Toast {...notification} /> : null}
    </Container>
  );
};

export default Component;
