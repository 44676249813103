import { Tooltip } from '@mui/material';
import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import theme from '../../../../../constants/themes';

const Wrapper = styled.div`
  display: inline-flex;
`;

const Emoji = styled.div`
  display: inline-flex;

  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  text-align: center;

  color: ${theme.colors.black};

  background: #b5cdf1;
  border-radius: 30px;
  padding: 5px;
  margin-right: 3px;
  .emoji-span {
    margin-right: 3px;
  }
`;

export type Props = {
  customers?: { [customerId: string]: string };
  emojis: { emoji: string; userId: string; name?: string }[];
  style?: CSSProperties;
  onClickEmoji: (emoji: string) => void;
};

const Component = ({
  customers = {},
  emojis,
  style,
  onClickEmoji,
}: Props): React.ReactElement => {
  const uniqueEmojis = emojis
    .map(emoji => emoji.emoji)
    .reduce((acc: string[], cur: string) => {
      if (acc.includes(cur)) {
        return acc;
      }
      return [...acc, cur];
    }, []);
  const emojisWithCount = uniqueEmojis.map(emoji => {
    const associatedEmojis = emojis.filter(
      emojiCount => emojiCount.emoji === emoji,
    );
    const emojiName = emojis.find(emojiName => emojiName.emoji === emoji)?.name;
    const reactors = associatedEmojis.map(
      emojiName => customers[emojiName.userId],
    );
    return {
      emoji: emoji,
      count: associatedEmojis.length,
      emojiName,
      reactorNames: reactors.join(','),
    };
  });
  return (
    <Wrapper style={style}>
      {emojisWithCount.map((emoji, index) => (
        <Tooltip
          title={`${emoji.reactorNames} reacted with :${emoji.emojiName}:`}
          key={`emoji-key-${index}`}>
          <Emoji
            onClick={() => {
              onClickEmoji(emoji.emoji);
            }}>
            <span className="emoji-span">
              {String.fromCodePoint(Number('0x' + emoji.emoji))}
            </span>{' '}
            <span>{emoji.count}</span>
          </Emoji>
        </Tooltip>
      ))}
    </Wrapper>
  );
};

export default Component;
