import { Skeleton } from '@mui/material';
import { AxiosResponse } from 'axios';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { Plan } from '../../../domain/entities/plan';
import { useGlobalState } from '../../../hooks/global';
import { usePaymentHooks } from '../../../hooks/payment';
import { usePlanHooks } from '../../../hooks/plan';
import { Flagman } from '../../atoms/Icons';
import { Toast } from '../../atoms/Toast';
import { Props as INotification } from '../../atoms/Toast/Component';
import { PlanCard } from '../../molecules/PlanCard';
import { MobileHeader } from '../../molecules/MobileHeader';
import { usePageHooks } from '../../../hooks/page';
import theme from '../../../constants/themes';
import queryKeys from '../../../constants/queryKeys';

const Container = styled.div`
  padding: 36px;
  box-sizing: border-box;

  @media ${theme.breakpoints.mobile} {
    padding: 15px;
  }

  @media ${theme.breakpoints.tablet} {
    padding: 15px;
  }
`;

const Header = styled.div`
  border-bottom: 1px solid #e6e6e6;

  @media ${theme.breakpoints.mobile} {
    display: none;
  }

  @media ${theme.breakpoints.tablet} {
    display: none;
  }
`;

const StyledFlagman = styled(Flagman)`
  margin-bottom: 18px;
  path {
    fill: #18cf77;
    :nth-child(1) {
      fill: black;
    }
    :nth-child(2) {
      fill: black;
    }
    :nth-child(3) {
      fill: black;
    }
  }
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 34px;
  line-height: 49px;
  letter-spacing: -0.02em;
  color: #000000;
  margin-top: 20px;
  width: fit-content;
`;

const Divider = styled.div`
  height: 11px;
  background-color: #18cf77;
`;

const CardsWrapper = styled.div`
  display: flex;
  width: 100%;
  overflow: auto;
  justify-content: center;
`;

const StyledPlanCard = styled(PlanCard)`
  margin: 35px;
  width: 400px;
`;

const SkeletonItem = styled(Skeleton)`
  margin: 35px 0;
  width: 100%;
`;

const SkeletonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;

  @media ${theme.breakpoints.tablet} {
    justify-content: center;
  }
`;

const StyledPlanCardWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;

  @media ${theme.breakpoints.tablet} {
    justify-content: center;
  }
`;

export type Props = {
  onSelectPlan?: (plan: string) => void;
};

const Component = ({}: Props): React.ReactElement => {
  const navigate = useNavigate();
  const {
    useCurrentUser: { currentUser },
  } = useGlobalState();
  const { useChoosePlan } = usePaymentHooks();
  const { choosePlan } = useChoosePlan();
  const { useFetchPlans } = usePlanHooks();
  const { useFetchPage } = usePageHooks();
  const { fetchPage } = useFetchPage();
  const { fetchPlans } = useFetchPlans();
  const { t } = useTranslation();
  const [notification, setNotification] = useState<INotification | null>(null);
  const [getDataPlans, setDataPlans] = useState<Plan[]>();
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const { isFetching: isFetchingDetails } = useQuery(
    [queryKeys.FETCH_PLAN_LIST],
    () => {
      return fetchPlans();
    },
    {
      onSuccess: dataPlan => {
        if (dataPlan) {
          setDataPlans(dataPlan);
        }
      },
      refetchOnMount: true,
    },
  );

  const { data: getPageData, isFetching: isFetchingPageData } = useQuery(
    [queryKeys.FETCH_PAGE],
    () => {
      return fetchPage('TOP_PAGE');
    },
  );

  const handleCloseToast = () => {
    setNotification(null);
  };

  const { mutate: choosePlanMutation } = useMutation(
    (planId: number) => {
      return choosePlan(planId, '');
    },
    {
      onSuccess: () => {
        if (currentUser?.user.isVerified) {
          navigate('/my-board/plan-billing');
        } else {
          navigate('/verify-email');
        }
      },
      onError: (response: { response: AxiosResponse }) => {
        if (!response.response) {
          setNotification({
            isOpen: true,
            message: String(response)
              .replace('Error:', '')
              .trim(),
            type: 'error',
            position: 'top-right',
            onClose: handleCloseToast,
          });
        } else {
          const data = response.response?.data as any;
          if (response.response.status === 422) {
            const error = data.errors;
            let msg = '';
            Object.keys(error).forEach(key => {
              msg += error[key];
            });
            setNotification({
              isOpen: true,
              message: msg,
              type: 'error',
              position: 'top-right',
              onClose: handleCloseToast,
            });
          } else if (response.response.status === 500) {
            setNotification({
              isOpen: true,
              message: String(data.error),
              type: 'error',
              position: 'top-right',
              onClose: handleCloseToast,
            });
          } else {
            setNotification({
              isOpen: true,
              message: String(response)
                .replace('Error:', '')
                .trim(),
              type: 'error',
              position: 'top-right',
              onClose: handleCloseToast,
            });
          }
        }
      },
    },
  );

  const onSelectPlan = (selectedPlan: Plan) => {
    if (selectedPlan.type === 'FREE') {
      setIsDisabled(true);
      choosePlanMutation(selectedPlan.id);
    } else {
      navigate('/payment', { state: { selectedPlan } });
    }
  };

  return (
    <>
      <MobileHeader
        links={getPageData?.sections[0]?.header?.content}
        isFetching={isFetchingPageData}
      />
      <Container>
        <Header>
          <StyledFlagman />
        </Header>
        <Title>
          {t('pleaseChoosePlan')}
          <Divider />
        </Title>
        <CardsWrapper>
          {isFetchingDetails ? (
            <SkeletonWrapper>
              <SkeletonItem
                sx={{
                  bgcolor: '#737373',
                  borderRadius: '10px',
                }}
                variant="rectangular"
                height={506}
              />
            </SkeletonWrapper>
          ) : (
            <StyledPlanCardWrapper>
              {getDataPlans?.map(dataPlan => (
                <StyledPlanCard
                  key={dataPlan.id}
                  themeColor={dataPlan.themeColor}
                  name={dataPlan.type}
                  description={dataPlan.description}
                  bodyTitle={dataPlan.pricing}
                  buttonTitle={dataPlan.buttonName}
                  points={dataPlan.content}
                  pricing={dataPlan.price}
                  onPress={() => onSelectPlan(dataPlan)}
                  isDisabled={
                    dataPlan.id == currentUser?.user.customer.plan_id ||
                    isDisabled
                  }
                />
              ))}
            </StyledPlanCardWrapper>
          )}
        </CardsWrapper>
        {notification ? <Toast {...notification} /> : null}
      </Container>
    </>
  );
};

export default Component;
