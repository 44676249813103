import React, { useEffect, useRef, useState, useMemo } from 'react';
import { ReactSketchCanvas, ReactSketchCanvasRef } from 'react-sketch-canvas';
import styled from 'styled-components';
import { extractRotateFromTransform } from '../../../../utils/strings';
import { PropertiesPane } from '../../../molecules/PropertiesPane';
import { StrokeData } from '../types';
import DragLine from './common/DragLine';
import Moveable from './common/Moveable';
import Overlay from './common/Overlay';
import { useNodesUtils, useNodeUtils } from './hooks/nodes';
import { useBoardHooks } from '../../../../hooks/board';

const CanvasRenderer = styled(ReactSketchCanvas)`
  background: transparent;
  svg {
    overflow: visible;
  }
  rect {
    fill: transparent;
  }
`;

const Component = (props: {
  id: string;
  data: StrokeData;
  selected: boolean;
  xPos: number;
  yPos: number;
}): React.ReactElement => {
  const { id, data, xPos, yPos } = props;
  const {
    setData,
    handleCopyNodes,
    isMultipleSelected,
    isSelectedByCurrentUser,
  } = useNodeUtils(id);
  const { handlePasteNodes, removeNodes } = useNodesUtils();
  const canvasRef = useRef<ReactSketchCanvasRef>(null);
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const {
    path = { strokeColor: 'black', strokeWidth: 1, paths: [] },
    width,
    style,
    height,
  }: StrokeData = data;
  const { paths, strokeColor, strokeWidth } = path;

  const { customersNodeIdsState } = useBoardHooks();
  const { customersSelectedNodeIds } = customersNodeIdsState;
  const selectedNodeIdsState = customersSelectedNodeIds
    ? Object.values(customersSelectedNodeIds).flat()
    : [];
  const isSelected = isSelectedByCurrentUser && props.selected;
  const isLocked = selectedNodeIdsState.includes(id);

  const viewBox = useMemo(() => {
    const xArray = paths.map(path => path.x);
    const yArray = paths.map(path => path.y);
    const minX = Math.min(...xArray);
    const maxX = Math.max(...xArray);
    const minY = Math.min(...yArray);
    const maxY = Math.max(...yArray);
    return `0 0 ${maxX + minX} ${maxY + minY}`;
  }, [paths]);

  const onClickDelete = () => {
    removeNodes([id]);
  };

  useEffect(() => {
    if (canvasRef?.current && paths) {
      canvasRef?.current?.loadPaths([
        { strokeWidth, strokeColor, paths, drawMode: true },
      ]);
    }
  }, [canvasRef, strokeColor]);

  useEffect(() => {
    const svgElement = document.querySelector(
      `svg[id="${props.id}"]`,
    ) as SVGSVGElement | null;
    if (svgElement) {
      svgElement.setAttribute('viewBox', viewBox);
    }
  }, []);

  return (
    <>
      {isSelected && !isMultipleSelected && !isLocked && (
        <DragLine
          width={width}
          height={height}
          parentId={id}
          position={{ x: xPos, y: yPos }}
          angle={
            style?.transform ? extractRotateFromTransform(style.transform) : 0
          }
        />
      )}
      <div
        style={{
          ...style,
          display: 'inline-flex',
          minHeight: '20px',
          minWidth: '20px',
        }}
        ref={setRef}>
        <CanvasRenderer
          style={{
            border: 'none',
            background: 'transparent',
            width: width,
            height: height,
          }}
          strokeColor="transparent"
          strokeWidth={0}
          ref={canvasRef}
          id={props.id}
        />
      </div>
      {isSelected && !isLocked && (
        <Moveable
          target={isSelected ? ref : undefined}
          keepRatio
          width={width}
          height={height}
          parentStyles={style}
          parentId={id}
        />
      )}
      {isSelected && !isMultipleSelected && !isLocked && (
        <>
          <div style={{ position: 'absolute', top: -100 }}>
            <PropertiesPane
              color={strokeColor}
              onClickCopy={handleCopyNodes}
              onClickPaste={handlePasteNodes}
              onClickDelete={onClickDelete}
              onChangeColor={newStrokeColor => {
                setData({
                  ...data,
                  path: { ...path, strokeColor: newStrokeColor },
                });
              }}
            />
          </div>
        </>
      )}
      {isLocked && (
        <Overlay
          width={width}
          height={height}
          transform={style?.transform}
          zIndex={10}
        />
      )}
    </>
  );
};

export default Component;
