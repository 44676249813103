import { useCallback } from 'react';
import { PageHooks } from '.';
import { useDependencies } from '..';
import {
  Page,
  PageUploadMedia,
  UpdatePageContent,
  UpdatePageSection,
  VerifiedAdmin,
} from '../../domain/entities/page';
import { GenericResponse } from '../../domain/entities/rest';

export const useFetchPage: PageHooks['useFetchPage'] = (): {
  fetchPage: (pageName: string) => Promise<Page>;
} => {
  const { pageInteractor } = useDependencies();

  const fetchPage = useCallback(async (pageName: string) => {
    return pageInteractor.fetchPage(pageName);
  }, []);

  return { fetchPage };
};

export const useVerifyAdmin: PageHooks['useVerifyAdmin'] = (): {
  verifyAdmin: (token: string) => Promise<VerifiedAdmin>;
} => {
  const { pageInteractor } = useDependencies();

  const verifyAdmin = useCallback(async (token: string) => {
    return pageInteractor.verifyAdmin(token);
  }, []);

  return { verifyAdmin };
};

export const useUploadMedia: PageHooks['useUploadMedia'] = (): {
  uploadMedia: (file: File) => Promise<PageUploadMedia>;
} => {
  const { pageInteractor } = useDependencies();

  const uploadMedia = useCallback(async (file: File) => {
    return pageInteractor.uploadMedia(file);
  }, []);

  return { uploadMedia };
};

export const useUpdatePageSection: PageHooks['useUpdatePageSection'] = (): {
  updatePageSection: (
    pageId: number,
    data: UpdatePageSection | UpdatePageContent,
  ) => Promise<GenericResponse>;
} => {
  const { pageInteractor } = useDependencies();

  const updatePageSection = useCallback(
    async (pageId: number, data: UpdatePageSection | UpdatePageContent) => {
      return pageInteractor.updatePageSection(pageId, data);
    },
    [],
  );

  return { updatePageSection };
};
