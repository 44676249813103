import axios, { AxiosResponse, ResponseType } from 'axios';
import { Formik, FormikHelpers } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import {
  ReactFacebookFailureResponse,
  ReactFacebookLoginInfo,
} from 'react-facebook-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { useGoogleLogin } from '@react-oauth/google';
import { useTranslation } from 'react-i18next';
import MicrosoftLogin from 'react-microsoft-login';
import { useMutation, useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import signInImage from '../../../assets/images/signin-image.png';
import storageKeys from '../../../constants/localStorage';
import queryKeys from '../../../constants/queryKeys';
import theme from '../../../constants/themes';
import { MicrosoftError } from '../../../domain/entities/errors';
import { User } from '../../../domain/entities/user';
import { useAuthHooks } from '../../../hooks/auth';
import { useLocalStorageHooks } from '../../../hooks/localStorage';
import { usePageHooks } from '../../../hooks/page';
import { isWindowsOS } from '../../../utils/booleans';
import { Facebook, Flagman, Google, Microsoft } from '../../atoms/Icons';
import { Link } from '../../atoms/Link';
import { Toast } from '../../atoms/Toast';
import { Props as INotification } from '../../atoms/Toast/Component';
import { Footer } from '../../molecules/Footer';
import { MobileHeader } from '../../molecules/MobileHeader';
import { LoginForm } from '../../organisms/LoginForm';
import {
  FbLoginValues,
  GoogleLoginValues,
  LoginFormType,
  LoginValues,
  MicrosoftValues,
} from './types';
import { LoginSchema } from './validation';
import urls from '../../../constants/urls';

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;

  @media ${theme.breakpoints.pc} {
    min-width: 923px;
  }

  @media ${theme.breakpoints.mobile} {
    width: 100%;
    min-height: 680px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin: 0;
  }

  @media ${theme.breakpoints.tablet} {
    width: 100%;
    height: calc(100vh - 119px - 58px);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 0;
  }
`;

const FlagmanWrapper = styled.div`
  position: absolute;
  top: 36px;
  left: 36px;
  cursor: pointer;

  @media ${theme.breakpoints.mobile} {
    display: none;
  }

  @media ${theme.breakpoints.tablet} {
    display: none;
  }
`;

const StyledSignInImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const StyledSignInImageDiv = styled.div`
  flex: 0 0 40%;
  height: 100%;

  @media ${theme.breakpoints.mobile} {
    display: none;
  }

  @media ${theme.breakpoints.tablet} {
    display: none;
  }
`;

const StyledFlagman = styled(Flagman)`
  width: max-content;
  height: fit-content;
  cursor: pointer;
`;

const FormWrapper = styled.div`
  width: max-content;
  height: max-content;
  display: flex;
  flex: 0 0 60%;
  flex-direction: column;
  align-items: center;

  @media ${theme.breakpoints.tablet} {
    padding: 50px 0px;
  }

  @media ${theme.breakpoints.mobile} {
    padding: 50px 0px;
    flex: 0 0 100%;
    width: 90%;
  }
`;

const ThirdPartyLabel = styled.p`
  color: ${theme.colors.text01};
  font-weight: 400;
  font-size: 16px;
  line-height: 175%;
  margin-top: 16px;
`;

const ThirdPartyWrapper = styled.div`
  height: max-content;
  width: 120px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
`;

const StyledGoogle = styled(Google)`
  && {
    width: 16px;
    height: 16px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.5));
      transform: translateY(-0.15em);
    }
  }
`;

const StyledFB = styled(Facebook)`
  && {
    width: 24px;
    height: 24px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      filter: drop-shadow(4px 4px 4px rgba(57, 81, 150, 0.5));
      transform: translateY(-0.15em);
    }
  }
`;

const StyledMicrosoft = styled(Microsoft)`
  && {
    width: 24px;
    height: 24px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      filter: drop-shadow(4px 4px 4px rgba(234, 62, 35, 0.5));
      transform: translateY(-0.15em);
    }
  }
`;

const SignUpWrapper = styled.div`
  margin: 0;
  padding: 0;
  width: max-content;
  height: max-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: ${isWindowsOS() ? 'baseline' : 'center'};
  margin-top: 16px;
`;

const StyledPretext = styled.pre`
  font-family: ${theme.fonts.primary};
  font-weight: 400;
  font-size: 16px;
  line-height: 175%;
  color: ${theme.colors.text01};
`;

const StyledSignupLink = styled(Link)`
  font-family: ${theme.fonts.primary};
  font-weight: 400;
  font-size: 16px;
  line-height: 175%;
`;

export type Props = {};

export const blobToBase64 = (blob: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

const Component = ({}: Props): React.ReactElement => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { useLogin, useFetchUser } = useAuthHooks();
  const { fetchGoogleUserData } = useFetchUser();
  const { login, googleLogin, fbLogin, microsoftLogin } = useLogin();
  const { useLocalStorage } = useLocalStorageHooks();
  const { clearSessionStorage, getItem } = useLocalStorage();
  const { useFetchPage } = usePageHooks();
  const { fetchPage } = useFetchPage();
  const [notification, setNotification] = useState<INotification | null>(null);
  const [apiError, setApiError] = useState('');
  const [validationErrors, setValidationErrors] = useState<{
    email: string;
    password: string;
  }>({ email: '', password: '' });

  const loginGoogle = useGoogleLogin({
    onSuccess: async tokenResponse => {
      const userInfo = await fetchGoogleUserData(tokenResponse.access_token);

      googleLoginMutation({
        email: userInfo.email,
        name: userInfo.name,
        googleId: userInfo.sub,
        invitationCode: invitationCode ?? '',
        avatar: userInfo.picture,
      });
    },
  });

  const search = useLocation().search;
  const invitationCode = new URLSearchParams(search).get('invitation_code');

  const invalidBoard =
    getItem(storageKeys.UNAUTHORIZED_BOARD_STORAGE_KEY) || '';

  const initialValues = {
    email: '',
    password: '',
    isRemember: false,
  };

  useEffect(() => {
    if (invalidBoard === 'invalid') {
      navigate('/signup');
    }
  }, [invalidBoard]);

  useEffect(() => {
    // Note: This is for preventing the Microsoft Button to call the auth every time the page loads
    clearSessionStorage();
  }, []);

  const handleCloseToast = () => {
    setNotification(null);
  };

  const { data: getPageData, isFetching: isFetchingPageData } = useQuery(
    [queryKeys.FETCH_PAGE],
    () => {
      return fetchPage('TOP_PAGE');
    },
  );

  const { mutate: loginMutation, isLoading: isLoggingIn } = useMutation(
    (params: LoginValues) => {
      return login(
        params.email,
        params.password,
        params.isRememberMeToggled,
        params.invitationCode,
      );
    },
    {
      onSuccess: response => {
        const res = response as {
          user: User;
        };

        if (res.user.isVerified) {
          navigate('/my-board/personal');
        } else {
          navigate('verify-email');
        }
      },
      onError: response => {
        const res: { response: AxiosResponse } = response as {
          response: AxiosResponse;
        };

        if (!res.response) {
          setApiError(
            String(response)
              .replace('Error:', '')
              .trim(),
          );
        } else {
          const data = res.response?.data as any;
          if (res.response.status === 422) {
            const error = data.errors;
            Object.keys(error).forEach(key => {
              if (key === 'email') {
                setValidationErrors({
                  ...validationErrors,
                  email: error[key],
                });
              } else if (key === 'password') {
                setValidationErrors({
                  ...validationErrors,
                  password: error[key],
                });
              }
            });
          } else if (res.response.status === 500) {
            setApiError(String(data.error));
          } else if (!!data.message) {
            setApiError(String(data.message));
          } else {
            setApiError(
              String(response)
                .replace('Error:', '')
                .trim(),
            );
          }
        }
      },
    },
  );

  const {
    mutate: googleLoginMutation,
    isLoading: isGoogleLoggingIn,
  } = useMutation(
    (params: GoogleLoginValues) => {
      return googleLogin(
        params.email,
        params.name,
        params.googleId,
        params.invitationCode,
        params.avatar,
      );
    },
    {
      onSuccess: () => {
        navigate('/my-board/personal');
      },
      onError: response => {
        setNotification({
          isOpen: true,
          message: String(response)
            .replace('Error:', '')
            .trim(),
          type: 'error',
          position: 'top-right',
          onClose: handleCloseToast,
        });
      },
    },
  );

  const { mutate: fbLoginMutation, isLoading: isFbLoggingIn } = useMutation(
    (params: FbLoginValues) => {
      return fbLogin(
        params.email,
        params.name,
        params.userID,
        params.invitationCode,
        params.avatar,
      );
    },
    {
      onSuccess: () => {
        navigate('/my-board/personal');
      },
      onError: response => {
        setNotification({
          isOpen: true,
          message: String(response)
            .replace('Error:', '')
            .trim(),
          type: 'error',
          position: 'top-right',
          onClose: handleCloseToast,
        });
      },
    },
  );

  const {
    mutate: microsoftLoginMutation,
    isLoading: isMicrosoftLoggingIn,
  } = useMutation(
    (params: MicrosoftValues) => {
      return microsoftLogin(
        params.account.userName,
        params.account.name,
        params.account.accountIdentifier,
        params.invitationCode,
        params.avatar,
      );
    },
    {
      onSuccess: () => {
        navigate('/my-board/personal');
      },
      onError: response => {
        setNotification({
          isOpen: true,
          message: String(response)
            .replace('Error:', '')
            .trim(),
          type: 'error',
          position: 'top-right',
          onClose: handleCloseToast,
        });
      },
    },
  );

  const submitLogin = useCallback(
    (values: LoginFormType, { resetForm }: FormikHelpers<LoginFormType>) => {
      setApiError('');
      loginMutation(
        {
          email: values.email,
          password: values.password,
          isRememberMeToggled: values.isRemember,
          invitationCode: invitationCode ?? '',
        },
        {
          onSuccess: () => {
            resetForm();
          },
        },
      );
    },
    [],
  );

  const handleMicrosoftLogin = useCallback(async (err, data) => {
    const error: MicrosoftError = err;
    const response = data as MicrosoftValues;
    setApiError('');

    if (response?.uniqueId) {
      // Note: This is for preventing the Microsoft Button to call the auth every time the page loads
      clearSessionStorage();

      const options = {
        headers: {
          Authorization: `Bearer ${response.accessToken}`,
          'Content-Type': 'image/jpg',
        },
        responseType: 'blob' as ResponseType,
      };
      const photo = await axios
        .get(urls.microsoft.profilePhoto, options)
        .then(async response => {
          const base64 = await blobToBase64(response.data).then(
            base64data => base64data,
          );
          return base64 as string;
        })
        .catch(function(err) {
          console.log(err);
          return '';
        });

      microsoftLoginMutation({
        ...response,
        avatar: photo ?? '',
      });
    } else if (!!error && error.errorCode !== 'user_cancelled') {
      setNotification({
        isOpen: true,
        message: `Microsoft Error: ${error.errorMessage}`,
        type: 'error',
        position: 'top-right',
        onClose: handleCloseToast,
      });
    }
  }, []);

  const submitFbLogin = async (response: ReactFacebookLoginInfo) => {
    setApiError('');
    fbLoginMutation({
      email: response.email || '',
      name: response.name || '',
      userID: response.userID,
      invitationCode: invitationCode || '',
      avatar: response.picture?.data.url || '',
    });
  };

  const handleFailureFb = useCallback(
    (response: ReactFacebookFailureResponse) => {
      setApiError('');
      if (response.status !== 'unknown') {
        setNotification({
          isOpen: true,
          message: `Facebook Error: ${response.status}`,
          type: 'error',
          position: 'top-right',
          onClose: handleCloseToast,
        });
      }
    },
    [],
  );

  return (
    <>
      <MobileHeader
        links={getPageData?.sections[0]?.header?.content}
        isFetching={isFetchingPageData}
      />
      <Container>
        <FlagmanWrapper>
          <a onClick={() => navigate('/top')}>
            <StyledFlagman />
          </a>
        </FlagmanWrapper>
        <FormWrapper>
          <Formik
            initialValues={initialValues}
            validationSchema={LoginSchema}
            onSubmit={submitLogin}>
            {({
              handleSubmit,
              handleBlur,
              setFieldValue,
              values,
              isValid,
              errors,
              touched,
              setFieldTouched,
            }): React.ReactElement => {
              const { email, password, isRemember } = values;
              const hasErrors = Object.keys(errors).length > 0;

              return (
                <LoginForm
                  email={email}
                  password={password}
                  isRememberMeToggled={isRemember}
                  onChangeEmail={newEmail => {
                    if (!!validationErrors.email) {
                      setValidationErrors({
                        ...validationErrors,
                        email: '',
                      });
                    }
                    setFieldValue('email', newEmail);
                  }}
                  onChangePassword={newPassword => {
                    if (!!validationErrors.password) {
                      setValidationErrors({
                        ...validationErrors,
                        password: '',
                      });
                    }
                    setFieldValue('password', newPassword);
                  }}
                  onToggleRememberMe={isRemember => {
                    setFieldValue('isRemember', isRemember);
                  }}
                  onPressSignIn={handleSubmit}
                  onBlurEmail={handleBlur}
                  onBlurPassword={handleBlur}
                  isSignInDisabled={
                    !isValid ||
                    hasErrors ||
                    isLoggingIn ||
                    isGoogleLoggingIn ||
                    isFbLoggingIn ||
                    isMicrosoftLoggingIn
                  }
                  errors={{
                    email:
                      (touched.email && errors.email) || validationErrors.email,
                    password:
                      (touched.password && errors.password) ||
                      validationErrors.password,
                    apiError: apiError,
                  }}
                  setFieldTouched={setFieldTouched}
                  isLoggingIn={isLoggingIn}
                />
              );
            }}
          </Formik>
          <ThirdPartyLabel>{t('thirdPartySignIn')}</ThirdPartyLabel>
          <ThirdPartyWrapper>
            <StyledGoogle onClick={loginGoogle} />
            <FacebookLogin
              appId={process.env.REACT_APP_FB_APP_ID || ''}
              fields="name,picture,email"
              callback={submitFbLogin}
              onFailure={handleFailureFb}
              render={(renderProps: {
                onClick: string | number | Function;
              }) => <StyledFB onClick={renderProps.onClick} />}
            />
            <MicrosoftLogin
              clientId={process.env.REACT_APP_MICROSOFT_CLIENT_ID || ''}
              authCallback={handleMicrosoftLogin}>
              <StyledMicrosoft />
            </MicrosoftLogin>
          </ThirdPartyWrapper>
          <SignUpWrapper>
            <StyledPretext>{t('signUpPretext')}</StyledPretext>
            <StyledSignupLink to="/signup" label={t('signUp')} />
          </SignUpWrapper>
        </FormWrapper>
        <StyledSignInImageDiv>
          <StyledSignInImage src={signInImage} alt="sign-in banner" />
        </StyledSignInImageDiv>
        {notification ? <Toast {...notification} /> : null}
      </Container>
      <Footer
        footerContents={[
          getPageData?.sections[6]?.footer?.content?.plan,
          getPageData?.sections[6]?.footer?.content?.frequently_used_links,
          getPageData?.sections[6]?.footer?.content?.company_overview,
        ]}
        addressContents={{
          address: getPageData?.sections[6]?.footer?.content?.company_address,
          telephoneNumber: getPageData?.sections[6]?.footer?.content?.tel,
          faxNumber: getPageData?.sections[6]?.footer?.content?.fax,
        }}
        faqsContents={{
          privacyPolicy:
            getPageData?.sections[6]?.footer?.content?.privacy_policy,
          termsOfService:
            getPageData?.sections[6]?.footer?.content?.terms_of_service,
          transactionLaw:
            getPageData?.sections[6]?.footer?.content?.transaction_law,
        }}
        isFetching={isFetchingPageData}
      />
    </>
  );
};

export default Component;
