import React from 'react';

type Props = {
  [key: string]: string | number | Function | object;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M11.8401 0.627855L14.0958 5.40727C14.2527 5.73969 14.556 5.97002 14.9069 6.02324L19.9511 6.78971C20.8347 6.92407 21.1872 8.05916 20.5481 8.71046L16.8981 12.4307C16.6445 12.6894 16.5285 13.0624 16.5886 13.4275L17.4501 18.6807C17.6011 19.6007 16.6774 20.3022 15.8873 19.8681L11.3758 17.3881C11.062 17.2158 10.687 17.2158 10.3733 17.3881L5.86176 19.8681C5.07161 20.3026 4.14795 19.6007 4.29897 18.6807L5.16047 13.4275C5.22054 13.0624 5.10456 12.6894 4.85091 12.4307L1.20092 8.71046C0.561782 8.05872 0.914308 6.92363 1.79792 6.78971L6.84216 6.02324C7.19302 5.97002 7.49632 5.73969 7.65318 5.40727L9.90893 0.627855C10.3036 -0.209285 11.445 -0.209285 11.8401 0.627855Z"
        fill="#FFD200"
      />
    </svg>
  );
};

export default Component;
