import React from 'react';

type Props = {
  [key: string]: string | number | Function;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.26125 2.684C10.1353 0.96027 12.5255 0.00827703 15 0C20.0438 0 24.2306 4 24.6863 9.158C27.6713 9.608 30 12.274 30 15.546C30 19.138 27.1913 22 23.7881 22H18.75C18.5014 22 18.2629 21.8946 18.0871 21.7071C17.9113 21.5196 17.8125 21.2652 17.8125 21C17.8125 20.7348 17.9113 20.4804 18.0871 20.2929C18.2629 20.1054 18.5014 20 18.75 20H23.79C26.2106 20 28.125 17.976 28.125 15.546C28.125 13.114 26.2125 11.09 23.7881 11.09H22.8506V10.09C22.8525 5.65 19.365 2 15 2C12.9748 2.00863 11.0191 2.78887 9.48563 4.2C8.06625 5.504 7.32375 7.076 7.32375 8.31V9.206L6.48937 9.304C3.87 9.61 1.875 11.904 1.875 14.636C1.875 17.57 4.18125 20 7.08937 20H11.25C11.4986 20 11.7371 20.1054 11.9129 20.2929C12.0887 20.4804 12.1875 20.7348 12.1875 21C12.1875 21.2652 12.0887 21.5196 11.9129 21.7071C11.7371 21.8946 11.4986 22 11.25 22H7.08937C3.2025 22 0 18.732 0 14.636C0 11.11 2.37375 8.19 5.51625 7.45C5.78438 5.724 6.825 4.004 8.26125 2.684Z"
        fill="#FEFEFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3362 8.292C14.4233 8.19887 14.5268 8.12499 14.6407 8.07457C14.7546 8.02416 14.8767 7.99821 15 7.99821C15.1233 7.99821 15.2454 8.02416 15.3593 8.07457C15.4732 8.12499 15.5767 8.19887 15.6638 8.292L21.2888 14.292C21.4648 14.4798 21.5637 14.7344 21.5637 15C21.5637 15.2656 21.4648 15.5202 21.2888 15.708C21.1127 15.8958 20.874 16.0013 20.625 16.0013C20.376 16.0013 20.1373 15.8958 19.9612 15.708L15.9375 11.414V29C15.9375 29.2652 15.8387 29.5196 15.6629 29.7071C15.4871 29.8946 15.2486 30 15 30C14.7514 30 14.5129 29.8946 14.3371 29.7071C14.1613 29.5196 14.0625 29.2652 14.0625 29V11.414L10.0388 15.708C9.86271 15.8958 9.62395 16.0013 9.375 16.0013C9.12605 16.0013 8.88729 15.8958 8.71125 15.708C8.53521 15.5202 8.43632 15.2656 8.43632 15C8.43632 14.7344 8.53521 14.4798 8.71125 14.292L14.3362 8.292Z"
        fill="#FEFEFF"
      />
    </svg>
  );
};

export default Component;
