import AuthService from '../../ports/AuthService';

export type googleSignUpUseCase = (
  email: string,
  name: string,
  googleId: string,
  invitationCode: string,
) => Promise<{ user: object | null }>;

export const buildGoogleSignUp = (dependencies: {
  authService: AuthService;
}): googleSignUpUseCase => {
  const { authService } = dependencies;

  const googleSignUp: googleSignUpUseCase = (
    email,
    name,
    googleId,
    invitationCode,
  ) => authService.signUpUsingGoogle(email, name, googleId, invitationCode);

  return googleSignUp;
};
