import React, { CSSProperties, useState } from 'react';
import styled from 'styled-components';
import theme from '../../../constants/themes';
import { withHttp } from '../../../utils/strings';
import { Button } from '../../atoms/Button';
import { TextInput } from '../../atoms/TextInput';

const Wrapper = styled.div`
  display: inline-flex;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.25);
  background-color: ${theme.colors.white};
  border-radius: 3px;
  align-items: center;
  justify-content: center;
  padding: 7px;
  button {
    margin-left: 7px;
  }
`;

const StyledTextInput = styled(TextInput)`
  height: 27px;
  width: 150px;
  font-size: 12px;
`;

const StyledButton = styled(Button)`
  &&&& {
    height: 27px;
    font-size: 12px;
    min-width: 70px;
  }
`;

export type Props = {
  initialLink?: string;
  onSubmit: (link: string) => void;
  style?: CSSProperties;
};

const Component = ({
  initialLink,
  onSubmit,
  style,
}: Props): React.ReactElement => {
  const [link, setLink] = useState(initialLink || '');
  return (
    <Wrapper style={style}>
      <StyledTextInput value={link} onChangeText={setLink} placeholder="Link" />
      <StyledButton
        onPress={() => {
          onSubmit(withHttp(link));
        }}
        title="Apply"
      />
    </Wrapper>
  );
};

export default Component;
