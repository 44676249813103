import BoardRepository from '../../ports/BoardRepository';
import { Template } from '../../../domain/entities/board';

export type fetchTemplateListUseCase = () => Promise<Template[]>;

export const buildFetchTemplateList = (dependencies: {
  boardRepo: BoardRepository;
}): fetchTemplateListUseCase => {
  const { boardRepo } = dependencies;

  const fetchTemplateList: fetchTemplateListUseCase = () =>
    boardRepo.fetchTemplateList();

  return fetchTemplateList;
};
