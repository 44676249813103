import React, { useCallback, useEffect, useRef } from 'react';
import { Node, useReactFlow } from 'react-flow-renderer';
import { ReactSketchCanvas, ReactSketchCanvasRef } from 'react-sketch-canvas';
import styled from 'styled-components';
import { generateUniqueString } from '../../../../utils/strings';
import { CanvasData, StrokeData } from '../types';
import { useAreaNode, useNodesUtils } from './hooks/nodes';

export const CANVAS_NODE_PREFIX_ID = 'CANVAS_NODE_ID-';

export const CANVAS_NODE_Z_INDEX = 15;

const CanvasRenderer = styled(ReactSketchCanvas)`
  background: transparent;
  z-index: 4;
  rect {
    fill: transparent;
  }
`;

const Component = (props: {
  id: string;
  data: CanvasData;
  selected: boolean;
}): React.ReactElement => {
  const { id, data } = props;
  const { shouldRender } = useAreaNode(id);

  const { width, height, strokeColor, strokeWidth }: CanvasData = data;
  const { project, getZoom } = useReactFlow();
  const { addNewNode } = useNodesUtils();
  const newNodeRef = useRef<Node<StrokeData> | undefined>();
  const zoom = getZoom();
  const canvasRef = useRef<ReactSketchCanvasRef>(null);

  const handleAddNewStroke = useCallback(() => {
    if (newNodeRef.current) {
      const newNode = newNodeRef.current;
      addNewNode('stroke', newNode.position, newNode.data);
      canvasRef?.current?.clearCanvas();
      newNodeRef.current = undefined;
    }
  }, [addNewNode]);
  useEffect(() => {
    if (shouldRender) {
      window.addEventListener('mouseup', handleAddNewStroke);
    }
    return () => {
      window.removeEventListener('mouseup', handleAddNewStroke);
    };
  }, [addNewNode]);
  if (!shouldRender) {
    return <></>;
  }

  return (
    <>
      <CanvasRenderer
        style={{
          border: 'none',
          backgroundColor: 'transparent',
          cursor: `url(${process.env.PUBLIC_URL}/pen-cursor.png) 0 24, crosshair`,
          width: width,
          height: height,
          transform: `scale(${1 / zoom})`,
          transformOrigin: 'left top',
        }}
        strokeColor={strokeColor}
        strokeWidth={strokeWidth * zoom}
        ref={canvasRef}
        id={props.id}
        onStroke={path => {
          const position = project({
            x: 0,
            y: 0,
          });
          if (!position) {
            console.error('unable to compute position');
            return;
          }
          const zoomLevels = zoom;
          let smallestX: number | undefined = undefined;
          let smallestY: number | undefined = undefined;
          let largestX: number | undefined = undefined;
          let largestY: number | undefined = undefined;
          const paths = path.paths.map(({ x, y }: { x: number; y: number }) => {
            const newX = x / zoomLevels;
            const newY = y / zoomLevels;
            if (typeof smallestX === 'undefined' || smallestX >= newX) {
              smallestX = newX;
            }
            if (typeof smallestY === 'undefined' || smallestY >= newY) {
              smallestY = newY;
            }
            if (typeof largestX === 'undefined' || largestX <= newX) {
              largestX = newX;
            }
            if (typeof largestY === 'undefined' || largestY <= newY) {
              largestY = newY;
            }
            return {
              x: newX,
              y: newY,
            };
          });
          const smallestXNum = smallestX || 0;
          const smallestYNum = smallestY || 0;
          const largestXNum = largestX || 0;
          const largestYNum = largestY || 0;
          const adjustedPaths = paths.map(path => ({
            x: path.x - smallestXNum + strokeWidth,
            y: path.y - smallestYNum + strokeWidth,
          }));
          const newNode: Node<StrokeData> = {
            id: `stroke-${generateUniqueString()}`,
            type: 'stroke',
            position: {
              x: position.x + smallestXNum - strokeWidth,
              y: position.y + smallestYNum - strokeWidth,
            },
            data: {
              width: largestXNum - smallestXNum + strokeWidth * 2,
              height: largestYNum - smallestYNum + strokeWidth * 2,
              path: {
                strokeColor,
                strokeWidth: strokeWidth,
                paths: adjustedPaths,
              },
            },
          };
          newNodeRef.current = newNode;
        }}
      />
    </>
  );
};

export default Component;
