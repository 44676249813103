import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Flagman } from '../../atoms/Icons';
import { Toast } from '../../atoms/Toast';
import { Props as INotification } from '../../atoms/Toast/Component';
import { VerifyEmail } from '../../molecules/VerifyEmail';
import { useGlobalState } from '../../../hooks/global';
import { useAuthHooks } from '../../../hooks/auth';
import theme from '../../../constants/themes';
import { Footer } from '../../molecules/Footer';
import { usePageHooks } from '../../../hooks/page';
import { MobileHeader } from '../../molecules/MobileHeader';
import signInImage from '../../../assets/images/signin-image.png';
import queryKeys from '../../../constants/queryKeys';

const Container = styled.div`
  width: 100%;
  min-width: 923px;
  min-height: 800px;
  height: calc(100vh - 119px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;

  @media ${theme.breakpoints.mobile} {
    width: 100%;
    min-width: 100%;
    height: calc(100vh - 80px);
    min-height: 400px;
    margin: 0;
  }

  @media ${theme.breakpoints.tablet} {
    width: 100%;
    min-width: 100%;
    height: calc(100vh - 119px - 58px);
    justify-content: center;
    margin: 0;
  }
`;

const FlagmanWrapper = styled.div`
  position: absolute;
  top: 36px;
  left: 36px;
  cursor: pointer;

  @media ${theme.breakpoints.mobile} {
    display: none;
  }

  @media ${theme.breakpoints.tablet} {
    display: none;
  }
`;

const StyledSignInImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const FormWrapper = styled.div`
  width: max-content;
  height: max-content;
  display: flex;
  flex: 0 0 60%;
  flex-direction: column;
  align-items: center;

  @media ${theme.breakpoints.tablet} {
    padding: 50px 0px;
  }

  @media ${theme.breakpoints.mobile} {
    padding: 50px 0px;
    flex: 0 0 100%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
`;

const StyledImageDiv = styled.div`
  flex: 0 0 40%;
  height: 100%;

  @media ${theme.breakpoints.mobile} {
    display: none;
  }

  @media ${theme.breakpoints.tablet} {
    display: none;
  }
`;

export type Props = {};

const Component = ({}: Props): React.ReactElement => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    useCurrentUser: { currentUser },
  } = useGlobalState();
  const { useResendVerification } = useAuthHooks();
  const { resendVerification } = useResendVerification();
  const [notification, setNotification] = useState<INotification | null>(null);
  const { useFetchPage } = usePageHooks();
  const { fetchPage } = useFetchPage();

  const handleCloseToast = () => {
    setNotification(null);
  };

  const { data: getPageData, isFetching: isFetchingPageData } = useQuery(
    [queryKeys.FETCH_PAGE],
    () => {
      return fetchPage('TOP_PAGE');
    },
  );

  const {
    mutate: resendVerificationMutation,
    isLoading: isResending,
  } = useMutation(
    () => {
      return resendVerification(currentUser?.user.token || '');
    },
    {
      onSuccess: () => {
        setNotification({
          isOpen: true,
          message: t('resendSuccess'),
          type: 'success',
          position: 'top-right',
          onClose: handleCloseToast,
        });
      },
      onError: response => {
        setNotification({
          isOpen: true,
          message: `${response}`,
          type: 'error',
          position: 'top-right',
          onClose: handleCloseToast,
        });
      },
    },
  );

  const onPressHome = () => {
    navigate('/signin');
  };

  const handleResend = () => {
    if (!isResending) {
      resendVerificationMutation();
    }
  };

  return (
    <>
      <MobileHeader
        links={getPageData?.sections[0]?.header?.content}
        isFetching={isFetchingPageData}
      />
      <Container>
        <FlagmanWrapper>
          <a onClick={() => navigate('/top')}>
            <Flagman />
          </a>
        </FlagmanWrapper>
        <FormWrapper>
          <VerifyEmail
            isResending={isResending}
            onClickResend={handleResend}
            onPressHomeBtn={onPressHome}
          />
        </FormWrapper>
        <StyledImageDiv>
          <StyledSignInImage src={signInImage} alt="sign-in banner" />
        </StyledImageDiv>
        {notification ? <Toast {...notification} /> : null}
      </Container>
      <Footer
        footerContents={[
          getPageData?.sections[6]?.footer?.content?.plan,
          getPageData?.sections[6]?.footer?.content?.frequently_used_links,
          getPageData?.sections[6]?.footer?.content?.company_overview,
        ]}
        addressContents={{
          address: getPageData?.sections[6]?.footer?.content?.company_address,
          telephoneNumber: getPageData?.sections[6]?.footer?.content?.tel,
          faxNumber: getPageData?.sections[6]?.footer?.content?.fax,
        }}
        faqsContents={{
          privacyPolicy:
            getPageData?.sections[6]?.footer?.content?.privacy_policy,
          termsOfService:
            getPageData?.sections[6]?.footer?.content?.terms_of_service,
          transactionLaw:
            getPageData?.sections[6]?.footer?.content?.transaction_law,
        }}
        isFetching={isFetchingPageData}
      />
    </>
  );
};

export default Component;
