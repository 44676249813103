import React from 'react';

type Props = {
  [key: string]: string | number | Function;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M14.1808 19.2387L13.6388 17.9302C11.5206 18.8076 9.18744 18.8076 7.06918 17.9302C4.95093 17.0528 3.30114 15.403 2.42375 13.2847C0.612406 8.91187 2.69643 3.88089 7.06918 2.06969C11.1016 0.399371 15.6939 2.0416 17.8047 5.7366L14.2643 5.7366V7.15289L19.646 7.15289V1.77123L18.2297 1.77123V3.83689C15.531 0.390591 10.764 -0.993735 6.52712 0.761186C2.68 2.3547 0.353601 6.08671 0.353886 10.0129C0.353979 11.2857 0.598619 12.5796 1.11514 13.8267C3.22532 18.921 9.08655 21.3488 14.1808 19.2387Z"
        fill="black"
      />
    </svg>
  );
};

export default Component;
