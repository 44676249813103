import {
  buildFetchMessagesOnBoard,
  fetchMessagesOnBoardUseCase,
} from './fetch-messages-on-board';

import ChatRepository from '../../ports/ChatRepository';

export default (dependencies: {
  chatRepo: ChatRepository;
}): {
  fetchMessagesOnBoard: fetchMessagesOnBoardUseCase;
} => {
  const { chatRepo } = dependencies;
  const fetchMessagesOnBoard = buildFetchMessagesOnBoard({ chatRepo });

  return { fetchMessagesOnBoard };
};
