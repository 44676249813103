import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router';
import styled, { createGlobalStyle } from 'styled-components';
import { usePageHooks } from '../../../hooks/page';
import { Body } from '../../molecules/Body';
import { TopFooter } from '../../molecules/TopFooter';
import { TopHeader } from '../../molecules/TopHeader';
import { useQuery as getQuery } from './../../../utils/query';
import { Toast } from '../../atoms/Toast';
import { Props as INotification } from '../../atoms/Toast/Component';
import { UpdatePageContent } from '../../../domain/entities/page';
import theme from '../../../constants/themes';
import { Button } from '../../atoms/Button';
import { Pencil, Save } from '../../atoms/Icons';
import {
  AddressContent,
  FooterContent,
  FaqContent as FooterFaqContent,
} from '../../molecules/TopFooter/types';
import { AxiosResponse } from 'axios';
import { Helmet } from 'react-helmet-async';
import queryKeys from '../../../constants/queryKeys';

const GlobalStyle = createGlobalStyle`
 div
 {
  &.ck.ck-editor__editable.ck-rounded-corners:not(.ck-editor__nested-editable) {
    border-radius: 5px;
  }
  &.ck.ck-editor__editable_inline {
    border: 1px solid #929292;
  }
}

.ck.ck-editor__editable_inline>:last-child {
  margin: 0;
  padding: .5em;
}
`;

const Container = styled.div``;

const ButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  padding: 0.5em;
  background: rgba(0, 0, 0, 0.17);
`;

const EditButton = styled(Button)<{ themeColor?: string; hoverColor?: string }>`
  width: 150px;
  font-style: normal;
  font-size: 20px;
  line-height: 29px;
  text-align: center;
  letter-spacing: -0.02em;
  border: none;
  background-color: ${({ themeColor }) => themeColor || theme.colors.lightBtn};

  :disabled {
    background-color: ${theme.colors.disabledLightSecondaryBtn};
    cursor: not-allowed;
  }

  :hover {
    border: none;
    background-color: ${({ hoverColor }) =>
      hoverColor || theme.colors.hoverLightBtn};
  }
`;

const StyledPencil = styled(Pencil)`
  height: 20px;
  width: 20px;
`;

const StyledKeep = styled(Save)`
  height: 20px;
  width: 20px;
`;

type TopPageSections = 'Header' | 'Body' | 'Footer';

export type Props = {};

const Component = ({}: Props): React.ReactElement => {
  const token = getQuery('token') as string;
  const { useFetchPage, useVerifyAdmin, useUpdatePageSection } = usePageHooks();
  const { fetchPage } = useFetchPage();
  const { verifyAdmin } = useVerifyAdmin();
  const { updatePageSection } = useUpdatePageSection();
  const navigate = useNavigate();
  const location = useLocation();

  const [pagePayload, setPagePayload] = useState<
    | {
        pageId: number;
        data: UpdatePageContent;
      }
    | Object
  >({});
  const [page, setPage] = useState<string>('');
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [notification, setNotification] = useState<INotification | null>(null);

  const { data: verifiedAdmin } = useQuery(
    [queryKeys.VERIFY_ADMIN, token],
    () => {
      return verifyAdmin(token);
    },
    {
      enabled: !!token,
    },
  );

  const {
    data: getPageData,
    isFetching: isFetchingPageDetails,
    refetch: refetchPageContent,
  } = useQuery(
    [queryKeys.FETCH_PAGE, page],
    async () => {
      return await fetchPage(page);
    },
    {
      onSuccess: res => {
        setPagePayload({
          pageId: res?.id,
          data: {
            header: res?.sections[0]?.header?.content,
            body: { content: res?.sections[1]?.body.content },
            footer: {
              plan: res?.sections[2]?.footer?.content?.plan,
              frequently_used_links:
                res?.sections[2]?.footer?.content?.frequently_used_links,
              company_overview:
                res?.sections[2]?.footer?.content?.company_overview,
              privacy_policy: res?.sections[2]?.footer?.content?.privacy_policy,
              terms_of_service:
                res?.sections[2]?.footer?.content?.terms_of_service,
              transaction_law:
                res?.sections[2]?.footer?.content?.transaction_law,
              company_address:
                res?.sections[2]?.footer?.content?.company_address,
              tel: res?.sections[2]?.footer?.content?.tel,
              fax: res?.sections[2]?.footer?.content?.fax,
            },
          },
        });
      },
      onError: (res: { response: AxiosResponse }) => {
        let errorMessage = '';
        const data = res.response?.data as any;
        Object.keys(data?.errors).forEach(key => {
          errorMessage += data?.errors[key];
        });

        setNotification({
          isOpen: true,
          message: String(errorMessage),
          type: 'error',
          position: 'top-right',
          onClose: () => setNotification(null),
        });
      },
      enabled: page !== '',
    },
  );

  const { mutate: updatePageSectionMutation } = useMutation(
    async ({ pageId, data }: { pageId: number; data: UpdatePageContent }) => {
      return updatePageSection(pageId, data);
    },
    {
      onSuccess: response => {
        refetchPageContent();
        setNotification({
          isOpen: true,
          message: String((response as any)?.data.message),
          type: 'success',
          position: 'top-right',
          onClose: () => setNotification(null),
        });
      },
      onError: (response: any) => {
        const errorMessage = Object.values(response.response.data.errors)[0];
        setNotification({
          isOpen: true,
          message: String(errorMessage),
          type: 'error',
          position: 'top-right',
          onClose: () => setNotification(null),
        });
      },
    },
  );

  const handleChangeData = (data?: any, sections?: TopPageSections) => {
    let payload =
      (pagePayload as {
        id: number;
        data: UpdatePageContent;
      })?.data || {};

    switch (sections) {
      case 'Header':
        payload = { ...payload, header: data };
        break;
      case 'Body':
        payload = { ...payload, body: data };
        break;
      case 'Footer':
        if (data?.footerContent) {
          payload = {
            ...payload,
            footer: {
              ...payload.footer,
              plan: {
                title: data.footerContent[0].title,
                item: data.footerContent[0].item,
              },
              frequently_used_links: {
                title: data.footerContent[1].title,
                item: data.footerContent[1].item,
              },
              company_overview: {
                title: data.footerContent[2].title,
                item: data.footerContent[2].item,
              },
            },
          };
        }

        if (data?.footerFaqContent) {
          payload = {
            ...payload,
            footer: {
              ...payload.footer,
              privacy_policy: data.footerFaqContent.privacyPolicy,
              terms_of_service: data.footerFaqContent.termsOfService,
              transaction_law: data.footerFaqContent.transactionLaw,
            },
          };
        }

        if (data?.addressContent) {
          payload = {
            ...payload,
            footer: {
              ...(payload as any)?.footer,
              company_address: data.address,
              tel: data.telephoneNumber,
              fax: data.faxNumber,
            },
          };
        }
        break;
    }

    setPagePayload({ ...pagePayload, data: payload });
  };

  useEffect(() => {
    const formattedLocation = location.pathname
      .replace(/^\/|\/$/g, '')
      .replace(/\/|\-/g, '_')
      .toUpperCase();

    setPage(formattedLocation);
  }, [location]);

  return (
    <Container>
      <GlobalStyle />
      <Helmet defer={false} prioritizeSeoTags>
        <title>{getPageData?.pageTitle || 'IDEAMAN/アイデアマン'}</title>
        <meta
          name="description"
          content={getPageData?.pageDescription || 'IDEAMAN/アイデアマン'}
        />
        <meta
          name="keywords"
          content={
            getPageData?.pageKeyword ||
            getPageData?.pageDescription ||
            'IDEAMAN/アイデアマン'
          }
        />
        <link rel="canonical" href={location.pathname} />
      </Helmet>
      {verifiedAdmin?.isAdmin && (
        <>
          <ButtonWrapper>
            <EditButton
              onPress={() => {
                setIsEditing(!isEditing);

                if (!!isEditing) {
                  const payload = pagePayload as {
                    pageId: number;
                    data: UpdatePageContent;
                  };
                  updatePageSectionMutation(payload);
                }
              }}
              iconMargin={10}
              icon={isEditing ? <StyledKeep /> : <StyledPencil />}
              title={isEditing ? '保存' : '編集'}
              themeColor={
                isEditing
                  ? theme.colors.notifToastBorder
                  : theme.colors.lightBtn
              }
              hoverColor={
                isEditing
                  ? theme.colors.hoverLightSecondaryBtn
                  : theme.colors.hoverLightBtn
              }
            />
          </ButtonWrapper>
        </>
      )}
      <TopHeader
        allowEditContent={isEditing}
        isFetching={isFetchingPageDetails}
        links={getPageData?.sections[0]?.header?.content}
        onPressMemberLogin={() => navigate('/signin')}
        onPressRegistration={() => navigate('/signup')}
        onChangeData={(links: any[]) => handleChangeData(links, 'Header')}
      />
      <Body
        onChangeBodyContent={(data: string) => handleChangeData(data, 'Body')}
        allowEditContent={isEditing}
        bodyContent={getPageData?.sections[1]?.body?.content}
        isFetching={isFetchingPageDetails}
      />
      <TopFooter
        onChangeAddressContent={(data: AddressContent) =>
          handleChangeData({ addressContent: data }, 'Footer')
        }
        onChangeFaqContent={(data: FooterFaqContent) =>
          handleChangeData({ footerFaqContent: data }, 'Footer')
        }
        onChangeFooterContent={(data: FooterContent) =>
          handleChangeData({ footerContent: data }, 'Footer')
        }
        allowEditContent={isEditing}
        onPressRegistration={() => navigate('/signup')}
        faqsContents={{
          privacyPolicy:
            getPageData?.sections[2]?.footer?.content?.privacy_policy,
          termsOfService:
            getPageData?.sections[2]?.footer?.content?.terms_of_service,
          transactionLaw:
            getPageData?.sections[2]?.footer?.content?.transaction_law,
        }}
        addressContents={{
          address: getPageData?.sections[2]?.footer?.content?.company_address,
          telephoneNumber: getPageData?.sections[2]?.footer?.content?.tel,
          faxNumber: getPageData?.sections[2]?.footer?.content?.fax,
        }}
        footerContents={[
          getPageData?.sections[2]?.footer?.content?.plan,
          getPageData?.sections[2]?.footer?.content?.frequently_used_links,
          getPageData?.sections[2]?.footer?.content?.company_overview,
        ]}
        isFetching={isFetchingPageDetails}
      />
      {notification ? <Toast {...notification} /> : null}
    </Container>
  );
};

export default Component;
