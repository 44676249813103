import React from 'react';

type Props = {
  [key: string]: string | number | Function;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 24.375C16.0168 24.375 18.9101 23.1766 21.0433 21.0433C23.1766 18.9101 24.375 16.0168 24.375 13C24.375 9.98316 23.1766 7.08989 21.0433 4.95666C18.9101 2.82343 16.0168 1.625 13 1.625C9.98316 1.625 7.08989 2.82343 4.95666 4.95666C2.82343 7.08989 1.625 9.98316 1.625 13C1.625 16.0168 2.82343 18.9101 4.95666 21.0433C7.08989 23.1766 9.98316 24.375 13 24.375ZM13 26C16.4478 26 19.7544 24.6304 22.1924 22.1924C24.6304 19.7544 26 16.4478 26 13C26 9.55219 24.6304 6.24558 22.1924 3.80761C19.7544 1.36964 16.4478 0 13 0C9.55219 0 6.24558 1.36964 3.80761 3.80761C1.36964 6.24558 0 9.55219 0 13C0 16.4478 1.36964 19.7544 3.80761 22.1924C6.24558 24.6304 9.55219 26 13 26Z"
        fill="black"
      />
      <path
        d="M14.5112 10.7054L10.7899 11.1718L10.6567 11.7893L11.3879 11.9242C11.8657 12.0379 11.9599 12.2102 11.8559 12.6863L10.6567 18.3218C10.3414 19.7794 10.8273 20.4652 11.9697 20.4652C12.8553 20.4652 13.8839 20.0557 14.3503 19.4934L14.4933 18.8174C14.1683 19.1034 13.6938 19.2172 13.3785 19.2172C12.9317 19.2172 12.7692 18.9036 12.8845 18.3511L14.5112 10.7054Z"
        fill="black"
      />
      <path
        d="M13 8.9375C13.8975 8.9375 14.625 8.20996 14.625 7.3125C14.625 6.41504 13.8975 5.6875 13 5.6875C12.1025 5.6875 11.375 6.41504 11.375 7.3125C11.375 8.20996 12.1025 8.9375 13 8.9375Z"
        fill="black"
      />
    </svg>
  );
};

export default Component;
