import React from 'react';

type Props = {
  [key: string]: string | number | Function;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="10"
      height="13"
      viewBox="0 0 10 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M9.495 7.3712L1.54125 11.9862C0.86625 12.3774 0 11.9037 0 11.1149V1.88495C0 1.09745 0.865 0.622447 1.54125 1.01495L9.495 5.62995C9.64855 5.7176 9.77618 5.84431 9.86496 5.99721C9.95373 6.15011 10.0005 6.32377 10.0005 6.50057C10.0005 6.67738 9.95373 6.85104 9.86496 7.00394C9.77618 7.15684 9.64855 7.28354 9.495 7.3712Z"
        fill="#60BF91"
      />
    </svg>
  );
};

export default Component;
