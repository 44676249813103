import TeamRepository from '../../ports/TeamRepository';
import { GenericResponse } from '../../../domain/entities/rest';

export type updateTeamMemberUseCase = (
  teamId: number,
  customerId: number,
  isOwner: number,
) => Promise<GenericResponse>;

export const buildUpdateTeamMember = (dependencies: {
  teamRepo: TeamRepository;
}): updateTeamMemberUseCase => {
  const { teamRepo } = dependencies;

  const updateTeamMember: updateTeamMemberUseCase = (
    teamId,
    customerId,
    isOwner,
  ) => teamRepo.updateTeamMember(teamId, customerId, isOwner);

  return updateTeamMember;
};
