import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import theme from '../../../constants/themes';
import { Plus } from '../../atoms/Icons';

const Container = styled.div`
  width: 100%;
  height: 60px;
  background-color: ${theme.colors.white};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
`;

const PlusWrapper = styled.div`
  width: 100px;
  height: 60px;
  background-color: ${theme.colors.notifToastBorder};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.hoverSecondaryBtn};
  }
`;

const StyledPlusIcon = styled(Plus)`
  width: 20px;
  height: 20px;
`;

const FooterText = styled.p`
  font-family: ${theme.fonts.primary};
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.colors.textColor};
  margin: 0;
  padding: 0;
  margin-left: 20px;
`;

export type Props = {
  className?: string;
  onPressAddBoard: () => void;
};

const Component = ({
  className,
  onPressAddBoard,
}: Props): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <Container className={className} onClick={onPressAddBoard}>
      <PlusWrapper>
        <StyledPlusIcon />
      </PlusWrapper>
      <FooterText>{t('addBoard')}</FooterText>
    </Container>
  );
};

export default Component;
