import React from 'react';
import styled from 'styled-components';
import theme from '../../../constants/themes';
import { FavBtn } from '../../atoms/Favorite';
import { Tooltip } from '../../atoms/Tooltip';
import DiagramPreview from '../../organisms/DiagramPreview/DiagramFetchHOC';
import { handleTextOverflow } from '../../../utils/strings';

const Container = styled.div`
  width: 100%;
  height: 69px;
  background-color: ${theme.colors.white};
  display: flex;
  flex-direction: row;
  cursor: pointer;
  border-bottom: thin solid ${theme.colors.cardListBorder};
  position: relative;
  padding-top: 10px;

  &:hover {
    background-color: ${theme.colors.dropdownOptionHover};
  }
`;

const FooterWrapper = styled.div`
  width: calc(100% - 100px);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const LeftWrapper = styled.div`
  height: 58px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 20px;
`;

const RightWrapper = styled.div`
  height: 58px;
  display: flex;
  flex-direction: column;
  justify-content: end;
`;

const BoardTitle = styled.span`
  font-family: ${theme.fonts.primary};
  font-weight: 400;
  font-size: 13px;
  color: ${theme.colors.black};
  line-height: 19px;
`;

const CreatedByText = styled.span`
  font-family: ${theme.fonts.primary};
  font-weight: 400;
  font-size: 10px;
  color: ${theme.colors.textColor};
  line-height: 14px;
`;

const CreatedAtText = styled.span`
  font-family: ${theme.fonts.primary};
  font-weight: 400;
  font-size: 10px;
  color: ${theme.colors.textColor};
  line-height: 14px;
`;

const UsersWrapper = styled.div<{ right: number }>`
  position: absolute;
  height: 19px;
  top: 0;
  margin-right: 10px;
  right: ${props => `${props.right + 8}px`};
`;

const UserImage = styled.img<{ zIndex: number; left: number }>`
  width: 19px;
  height: 19px;
  border-radius: 50%;
  object-fit: cover;
  position: absolute;
  z-index: ${props => props.zIndex};
  top: 10px;
  left: ${props => `${props.left}px`};
`;

const FavoriteWrapper = styled.div`
  position: absolute;
  top: 17px;
  left: 7px;
  z-index: 99;
`;

export type Props = {
  className?: string;
  boardImg: string;
  boardName: string;
  createdBy: string;
  createdAt: string;
  users: string[];
  isFavorite: boolean;
  boardId: number;
  onPressBoardCard: (boardId: number) => void;
  onPressFavorite: (boardId: number, isFavorite: boolean) => void;
};

const Component = ({
  className,
  boardImg,
  boardName,
  createdBy,
  createdAt,
  users,
  isFavorite,
  boardId,
  onPressBoardCard,
  onPressFavorite,
}: Props): React.ReactElement => {
  const renderUsers = () => {
    return users.map((userImg, index) => {
      return (
        <UserImage key={index} src={userImg} zIndex={index} left={index * 10} />
      );
    });
  };

  return (
    <Container className={className} onClick={() => onPressBoardCard(boardId)}>
      <DiagramPreview
        diagramUrl={boardImg}
        style={{ width: 100, height: 58 }}
      />
      <FooterWrapper>
        <LeftWrapper>
          <BoardTitle>{boardName}</BoardTitle>
          {createdBy.length <= 15 ? (
            <CreatedByText>{createdBy}</CreatedByText>
          ) : (
            <Tooltip label={createdBy}>
              <CreatedByText>{handleTextOverflow(createdBy, 15)}</CreatedByText>
            </Tooltip>
          )}
        </LeftWrapper>
        <RightWrapper>
          <CreatedAtText>{createdAt}</CreatedAtText>
        </RightWrapper>
      </FooterWrapper>
      <UsersWrapper right={users.length * 9.5}>{renderUsers()}</UsersWrapper>
      <FavoriteWrapper>
        <FavBtn
          isActive={isFavorite}
          onPress={() => onPressFavorite(boardId, isFavorite)}
        />
      </FavoriteWrapper>
    </Container>
  );
};

export default Component;
