import { useCallback } from 'react';
import xmljs from 'xml-js';
import { Node, Edge, OnNodesChange, OnEdgesChange } from 'react-flow-renderer';
import { BoardHooks, useBoardHooks } from '.';
import { useDependencies } from '..';
import { useGlobalState } from '../global';
import { useSocketHooks } from '../socket';
import {
  DiagramEdge,
  DiagramNode,
} from '../../components/templates/DiagramEditor/types';
import {
  Board,
  BoardCustomers,
  BoardMedia,
  MyBoard,
  SortType,
  FavoriteSuccess,
  UnfavoriteSuccess,
  Template,
  InviteCustomerToBoardSuccess,
} from '../../domain/entities/board';
import events from '../../constants/socket';
import { UpdateRoleSuccess } from '../../domain/entities/role';
import { GenericResponse } from '../../domain/entities/rest';
import { parseStrToNodesEdges } from '../../utils/strings';
import { removeWhitelistedNodes } from '../../utils/arrays';

export const useBoardAPI: BoardHooks['useBoardAPI'] = (): {
  fetchBoardCustomers: (boardCode: string) => Promise<BoardCustomers[]>;
  fetchBoardCustomer: (
    customerId: number,
    boardCode: string,
  ) => Promise<BoardCustomers>;
  fetchBoardByCode: (boardCode: string) => Promise<Board | undefined>;
  updateBoard: (payload: {
    name?: string;
    xmlString?: string;
    boardCode: string;
  }) => Promise<void>;
  saveBoard: (payload: {
    nodes: Node[];
    edges: Edge[];
    boardCode: string;
  }) => Promise<GenericResponse>;
  fetchMyBoards: (
    search: string,
    sortKey: SortType,
    isFavorite: boolean,
    teamId?: number,
  ) => Promise<MyBoard[]>;
  inviteCustomer: (
    boardCode: string,
    emails: string[],
  ) => Promise<InviteCustomerToBoardSuccess>;
  deleteCustomer: (
    boardCode: string,
    customerId: string,
  ) => Promise<GenericResponse>;
  updateCustomerRole: (
    boardCode: string,
    roleId: number,
  ) => Promise<UpdateRoleSuccess>;
  uploadMedia: (file: File, type: number) => Promise<void>;
  fetchMedia: (customerId: number) => Promise<BoardMedia[]>;
  setBoardAsFavorite: (boardCode: string) => Promise<FavoriteSuccess>;
  unfavoriteBoard: (boardCode: string) => Promise<UnfavoriteSuccess>;
  createBoardFromTemplate: (payload: {
    name: string;
    teamId?: number;
    diagramUrl?: string;
    xmlStr?: string;
  }) => Promise<string>;
  fetchTemplateList: () => Promise<Template[]>;
  voteCustomer: (
    boardCode: string,
    customerId: number,
  ) => Promise<GenericResponse>;
  acceptBoardInvitation: (invitationCode: string) => Promise<GenericResponse>;
} => {
  const { boardInteractor, httpAdapter } = useDependencies();

  const fetchBoardCustomers = useCallback(async (boardCode: string) => {
    const customers = await boardInteractor.fetchBoardCustomers(boardCode);

    return customers;
  }, []);

  const fetchBoardCustomer = useCallback(
    async (customerId: number, boardCode: string) => {
      const customer = await boardInteractor.fetchBoardCustomer(
        customerId,
        boardCode,
      );
      return customer;
    },
    [],
  );

  const fetchBoardByCode = useCallback(async (boardCode: string) => {
    const board = await boardInteractor.fetchBoardByCode(boardCode);

    return board;
  }, []);

  const updateBoard = useCallback(
    async (payload: {
      name?: string;
      xmlString?: string;
      boardCode: string;
    }) => {
      return boardInteractor.updateBoard(payload);
    },
    [],
  );

  const saveBoard = useCallback(
    async (payload: { nodes: Node[]; edges: Edge[]; boardCode: string }) => {
      const nodes = removeWhitelistedNodes(payload.nodes);
      const edges = payload.edges;
      const xmlStr = xmljs.json2xml(
        JSON.stringify({
          data: {
            nodes,
            edges,
          },
        }),
        { compact: true },
      );
      return boardInteractor.saveBoard({
        xmlString: `<?xml version="1.0"?>${xmlStr}`,
        ...payload,
      });
    },
    [],
  );

  const fetchMyBoards = useCallback(
    async (
      search: string,
      sortKey: SortType,
      isFavorite: boolean,
      teamId?: number,
    ) => {
      const response = await boardInteractor.fetchBoards(
        search,
        sortKey,
        isFavorite,
        teamId,
      );

      return response;
    },
    [],
  );

  const inviteCustomer = useCallback(
    async (boardCode: string, emails: string[]) => {
      const response = await boardInteractor.inviteCustomers(boardCode, emails);

      return response;
    },
    [],
  );

  const deleteCustomer = useCallback(
    async (boardCode: string, customerId: string) => {
      return await boardInteractor.removeCustomer(boardCode, customerId);
    },
    [],
  );

  const updateCustomerRole = useCallback(
    async (boardCode: string, roleId: number) => {
      const response = await boardInteractor.updateCustomerRole(
        boardCode,
        roleId,
      );

      return response;
    },
    [],
  );

  const uploadMedia = useCallback(async (file: File, type: number) => {
    await boardInteractor.uploadMedia(file, type);
  }, []);

  const fetchMedia = useCallback(async (customerId: number) => {
    const response = await boardInteractor.fetchMedia(customerId);

    return response;
  }, []);

  const setBoardAsFavorite = useCallback(async (boardCode: string) => {
    const response = await boardInteractor.setBoardAsFave(boardCode);

    return response;
  }, []);

  const unfavoriteBoard = useCallback(async (boardCode: string) => {
    const response = await boardInteractor.unfavoriteBoard(boardCode);

    return response;
  }, []);
  const createBoardFromTemplate = useCallback(
    async (payload: {
      name: string;
      teamId?: number;
      diagramUrl?: string;
      xmlStr?: string;
    }) => {
      if (payload.xmlStr) {
        const code = await boardInteractor.createBoard({
          name: payload.name,
          teamId: payload.teamId,
          diagram: payload.xmlStr,
        });
        return code;
      } else {
        if (payload.diagramUrl) {
          const response = await httpAdapter.get(payload.diagramUrl, {});
          const xmlStr = response.data;

          const code = await boardInteractor.createBoard({
            name: payload.name,
            teamId: payload.teamId,
            diagram: xmlStr,
          });
          return code;
        }
        throw new Error();
      }
    },
    [],
  );
  const fetchTemplateList = useCallback(async () => {
    return boardInteractor.fetchTemplateList();
  }, []);

  const voteCustomer = useCallback(
    async (boardCode: string, customerId: number) => {
      return boardInteractor.voteCustomer(boardCode, customerId);
    },
    [],
  );

  const acceptBoardInvitation = useCallback(async (invitationCode: string) => {
    return boardInteractor.acceptBoardInvitation(invitationCode);
  }, []);

  return {
    fetchBoardCustomers,
    fetchBoardCustomer,
    fetchBoardByCode,
    updateBoard,
    saveBoard,
    fetchMyBoards,
    inviteCustomer,
    deleteCustomer,
    updateCustomerRole,
    uploadMedia,
    fetchMedia,
    setBoardAsFavorite,
    unfavoriteBoard,
    createBoardFromTemplate,
    fetchTemplateList,
    voteCustomer,
    acceptBoardInvitation,
  };
};

export const useBoardDiagram: BoardHooks['useBoardDiagram'] = (): {
  fetchBoardDiagram: (
    diagramUrl: string,
  ) => Promise<{
    edges: DiagramEdge[];
    nodes: DiagramNode[];
    xmlStr: string;
  }>;
} => {
  const { httpAdapter } = useDependencies();
  const fetchBoardDiagram = useCallback(async diagramUrl => {
    const response = await httpAdapter.get(diagramUrl, {}, true);
    const xmlStr = response.data;
    const jsonStr = xmljs.xml2json(xmlStr, {
      compact: true,
    });
    const { nodes, edges } = parseStrToNodesEdges(jsonStr);

    return { edges, nodes, xmlStr };
  }, []);

  return { fetchBoardDiagram };
};

export const useBoardSocket: BoardHooks['useBoardSocket'] = (): {
  forceDiagramUpdate: () => void;
  nodes: Node[];
  setNodes: React.Dispatch<React.SetStateAction<Node[]>>;
  onNodesChange: OnNodesChange;
  edges: Edge[];
  setEdges: React.Dispatch<React.SetStateAction<Edge[]>>;
  onEdgesChange: OnEdgesChange;
  sendCursorPosition: (
    posX: number,
    posY: number,
    customerId: number,
    customerName: string,
  ) => void;
  joinBoard: () => void;
  sendVote: (
    boardCode: string,
    voteData: {
      badges: number;
      customerId: number;
      totalVote: number;
      time: number;
    },
  ) => void;
  sendSelectedNodeIds: (userId: number, nodeIds: string[]) => void;
  sendLatestDiagramRequest: (boardCode: string) => void;
  removeActiveUser: (boardCode: string, userId: number) => void;
} => {
  const { nodeState, edgeState, useMySelectedNodes } = useBoardHooks();
  const { nodes, getNodes, setNodes, onNodesChange } = nodeState;
  const { edges, getEdges, setEdges, onEdgesChange } = edgeState;

  const { useCurrentUser } = useGlobalState();
  const { useSocket } = useSocketHooks();
  const { socket, initializedBoardCode: boardCode } = useSocket;
  const { currentUser } = useCurrentUser;

  const { recentlySelectedNodeIds } = useMySelectedNodes;

  const forceDiagramUpdate = useCallback(() => {
    if (socket) {
      const nodes = getNodes();
      const edges = getEdges();
      const jsonData = JSON.stringify({
        _declaration: { _attributes: { version: '1.0' } },
        data: { nodes: removeWhitelistedNodes(nodes), edges },
      });
      // NOTE add short timeout for scenarios like setNodes and setEdges are called at the same time
      const xmlStr = xmljs.json2xml(jsonData, { compact: true });
      const eventData = {
        customers_selected_node_ids: '',
        diagram_content: xmlStr,
        board_code: boardCode,
        username: '',
        token: currentUser?.user.token,
      };
      socket.emit(events.CLIENT.SEND_DIAGRAM_FILE_CONTENT, eventData);
    }
  }, [socket, recentlySelectedNodeIds]);

  const sendCursorPosition = useCallback(
    (posX: number, posY: number, customerId: number, customerName: string) => {
      if (socket) {
        const eventData = {
          x: posX,
          y: posY,
          customer_id: customerId,
          username: customerName,
          board_code: boardCode,
        };
        socket.emit(events.CLIENT.SEND_POSITION_OF_CURSOR, eventData);
      }
    },
    [boardCode, socket],
  );

  const joinBoard = useCallback(() => {
    if (socket && currentUser?.user.customer.id) {
      const eventData = {
        board_code: boardCode,
        user_id: currentUser.user.customer.id,
      };
      socket.emit(events.CLIENT.JOIN_ROOM, eventData);
    }
  }, [boardCode, socket, currentUser]);

  const sendVote = useCallback(
    (
      boardCode: string,
      voteData: {
        badges: number;
        customerId: number;
        totalVote: number;
        time: number;
      },
    ) => {
      if (socket) {
        const eventData = {
          board_code: boardCode,
          voted_id: JSON.stringify(voteData),
        };

        socket.emit(events.CLIENT.VOTE, eventData);
      }
    },
    [boardCode, socket],
  );

  const sendSelectedNodeIds = useCallback(
    (userId: number, nodeIds: string[]) => {
      if (socket) {
        const eventData = {
          board_code: boardCode,
          user_id: userId,
          node_id: nodeIds,
        };
        socket.emit(events.CLIENT.LOCK_OBJECT, eventData);
      }
    },
    [boardCode, socket],
  );

  const sendLatestDiagramRequest = useCallback(
    (boardCode: string) => {
      if (socket) {
        const eventData = {
          board_code: boardCode,
        };
        socket.emit(events.CLIENT.REQUEST_NEW_DIAGRAM_FILE_CONTENT, eventData);
      }
    },
    [boardCode, socket],
  );

  const removeActiveUser = useCallback(
    (boardCode: string, userId: number) => {
      if (socket) {
        const eventData = {
          board_code: boardCode,
          user_id: JSON.stringify({ userId, boardCode }),
        };
        socket.emit(events.CLIENT.OUT_ROOM, eventData);
      }
    },
    [boardCode, socket],
  );

  return {
    forceDiagramUpdate,
    nodes,
    setNodes,
    onNodesChange,
    edges,
    setEdges,
    onEdgesChange,
    sendCursorPosition,
    joinBoard,
    sendVote,
    sendSelectedNodeIds,
    sendLatestDiagramRequest,
    removeActiveUser,
  };
};
