import React, { useCallback, useState } from 'react';
import Picker from 'emoji-picker-react';
import { OnResize } from 'react-moveable';
import styled from 'styled-components';
import { ShapeData } from '../types';
import { LinkDetails } from '../../../molecules/LinkDetails';
import { LinkForm } from '../../../molecules/LinkForm';
import { Shape } from '../../../molecules/Shape';
import { useNodesUtils, useNodeUtils } from './hooks/nodes';
import { createReactorObj, removeUndefined } from '../../../../utils/objects';
import Emojis from './common/Emojis';
import Moveable from './common/Moveable';
import { useGlobalState } from '../../../../hooks/global';
import { useBoardHooks } from '../../../../hooks/board';
import DragLine from './common/DragLine';
import Overlay from './common/Overlay';
import { extractRotateFromTransform } from '../../../../utils/strings';

const EmojiPickerWrapper = styled.div`
  z-index: 1;
  position: absolute;
  left: 535px;
  top: -50px;
  z-index: 1;
`;

const Component = (props: {
  id: string;
  data: ShapeData;
  selected: boolean;
  xPos: number;
  yPos: number;
}): React.ReactElement => {
  const { id, data, xPos, yPos } = props;
  const {
    height,
    width,
    type,
    style,
    link,
    label,
    isFlipped,
    emojis,
    emojiNames,
    reactorNames,
  } = data;
  const { useCurrentUser } = useGlobalState();
  const { currentUser } = useCurrentUser;
  const currentCustomerId = currentUser?.user?.customer?.id;
  const {
    setData,
    setLabel,
    setStyle,
    handleCopyNodes,
    isMultipleSelected,
    isSelectedByCurrentUser,
  } = useNodeUtils(id);
  const { handlePasteNodes, removeNodes } = useNodesUtils();

  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const [isSettingLink, setIsSettingLink] = useState<boolean>(false);
  const [isSelectingEmoji, setIsSelectingEmoji] = useState<boolean>(false);
  const [isLinkDetailsOpen, setIsLinkDetailsOpen] = useState<boolean>(false);
  const { customersNodeIdsState } = useBoardHooks();
  const { customersSelectedNodeIds } = customersNodeIdsState;
  const selectedNodeIdsState = customersSelectedNodeIds
    ? Object.values(customersSelectedNodeIds).flat()
    : [];
  const isSelected = isSelectedByCurrentUser && props.selected;
  const isLocked = selectedNodeIdsState.includes(id);

  const handleSubmitLink = useCallback(
    link => {
      setData({ ...props.data, link });
      setIsSettingLink(false);
    },
    [props.data],
  );

  const onClickDelete = () => {
    removeNodes([id]);
  };

  return (
    <div>
      {isSelected && !isMultipleSelected && !isLocked && (
        <DragLine
          width={width}
          height={height}
          parentId={id}
          position={{ x: xPos, y: yPos }}
          angle={
            style?.transform ? extractRotateFromTransform(style.transform) : 0
          }
        />
      )}
      {isSelected && !isMultipleSelected && !isLocked && (
        <>
          <div style={{ position: 'absolute', top: -10, zIndex: 2, left: 450 }}>
            {isSettingLink && (
              <LinkForm onSubmit={handleSubmitLink} initialLink={link} />
            )}
          </div>
        </>
      )}
      {link && (
        <div style={{ position: 'absolute', top: -35, left: width / 2 - 12 }}>
          <LinkDetails
            isOpen={isLinkDetailsOpen}
            link={link}
            onClickDelete={() => {
              const newData = JSON.parse(
                JSON.stringify({ ...props.data, link: undefined }),
              );
              setData(newData);
              setIsSettingLink(false);
            }}
            onClickEdit={() => {
              setIsSettingLink(true);
            }}
            onClickLinkIcon={() => {
              !isLocked && setIsLinkDetailsOpen(!isLinkDetailsOpen);
            }}
          />
        </div>
      )}
      <Shape
        setRef={setRef}
        label={label}
        type={type}
        width={width}
        height={height}
        selected={isSelected && !isMultipleSelected && !isLocked}
        isFlipped={isFlipped}
        style={{
          ...style,
          stroke: style?.stroke || 'black',
          width,
          height,
        }}
        onChangeColor={fill => {
          setStyle({ ...style, fill });
        }}
        onChangeStrokeColor={stroke => {
          setStyle({ ...style, stroke });
        }}
        onClickLink={(open?: boolean) => {
          if (open) {
            setIsSettingLink(open);
          } else {
            setIsSettingLink(!isSettingLink);
          }
        }}
        onChangeBorderWidth={strokeWidth => {
          setStyle({ ...style, strokeWidth });
        }}
        onChangeLabel={setLabel}
        onClickCopy={handleCopyNodes}
        onClickPaste={handlePasteNodes}
        onClickDelete={onClickDelete}
        onClickFlip={
          type === 'shape-7'
            ? () => {
                setData({ ...data, isFlipped: !isFlipped });
              }
            : undefined
        }
        onClickEmoji={(open?: boolean) => {
          if (open) {
            setIsSelectingEmoji(open);
          } else {
            setIsSelectingEmoji(!isSelectingEmoji);
          }
        }}
        isEmojiOpen={isSelectingEmoji}
        isLinkOpen={isSettingLink}
      />
      {emojis && (
        <Emojis
          style={{ position: 'absolute', left: 0, bottom: -10, zIndex: 1 }}
          onClickEmoji={emoji => {
            if (!isLocked && currentCustomerId) {
              const newEmojiId = `hex-${currentCustomerId}-${emoji}`;
              if (emojis?.[newEmojiId]) {
                setData({
                  ...props.data,
                  emojis: removeUndefined({
                    ...props.data.emojis,
                    [newEmojiId]: undefined,
                  }) as { [key: string]: string },
                });
              } else {
                setData({
                  ...props.data,
                  emojis: {
                    ...props.data.emojis,
                    [newEmojiId]: emoji,
                  },
                });
              }
              setIsSelectingEmoji(false);
            }
          }}
          emojis={Object.entries(emojis || {}).map(([key, value]) => {
            const emojiHex = key.split('-')[2];
            return {
              emoji: value,
              userId: key.split('-')[1],
              name: emojiNames?.[`hex-${emojiHex}`],
            };
          })}
          customers={{
            ...createReactorObj(reactorNames),
            [`${currentCustomerId}`]: 'You',
          }}
        />
      )}
      {isSelected && isSelectingEmoji && !isLocked && (
        <EmojiPickerWrapper className="nowheel">
          <Picker
            groupVisibility={{
              smileys_people: true,
              animals_nature: false,
              food_drink: false,
              travel_places: false,
              activities: false,
              objects: false,
              symbols: false,
              flags: false,
              recently_used: false,
            }}
            onEmojiClick={(event, emojiObj) => {
              const emojiHex = emojiObj?.emoji?.codePointAt(0)?.toString(16);
              if (currentCustomerId && emojiHex) {
                const newEmojiId = `hex-${currentCustomerId}-${emojiHex}`;
                const readableEmojiHex = `hex-${emojiHex}`;
                const newEmojiName = emojiObj.names[emojiObj.names.length - 1];
                const reactorId = `reactor-${currentCustomerId}`;

                if (emojis?.[newEmojiId]) {
                  setData({
                    ...props.data,
                    emojis: removeUndefined({
                      ...props.data.emojis,
                      [newEmojiId]: undefined,
                    }) as { [key: string]: string },
                  });
                } else {
                  setData({
                    ...props.data,
                    emojis: {
                      ...props.data.emojis,
                      [newEmojiId]: emojiHex,
                    },
                    emojiNames: removeUndefined({
                      ...emojiNames,
                      [readableEmojiHex]: newEmojiName,
                    }) as { [key: string]: string },
                    reactorNames: {
                      ...reactorNames,
                      [reactorId]:
                        currentUser?.user?.customer?.fullname ||
                        currentUser?.user?.customer?.fullname,
                    },
                  });
                }
                setIsSelectingEmoji(false);
              }
            }}
          />
        </EmojiPickerWrapper>
      )}
      {isSelected && ref && !isLocked && (
        <Moveable
          target={ref}
          width={width}
          height={height}
          parentStyles={style}
          parentId={id}
          onResize={({
            width: newWidth,
            height: newHeight,
            target,
            direction,
          }: OnResize) => {
            const [x, y] = direction;
            const svg: HTMLElement | null = target.querySelector(
              '.shape-wrapper svg',
            );
            const resizingNW = x < 0 && y < 0;
            const resizingN = x == 0 && y < 0;
            const resizingW = x < 0 && y == 0;
            const resizingNE = x > 0 && y < 0;
            const resizingSW = x < 0 && y > 0;
            if (resizingNE) {
              target!.style.width = `${newWidth}px`;
              target!.style.height = `${newHeight}px`;
              target!.style.marginTop = `${(height || 0) - newHeight}px`;
              if (svg) {
                svg!.style.width = `${newWidth}px`;
                svg!.style.height = `${newHeight}px`;
              }
            } else if (resizingN) {
              target!.style.width = `${newWidth}px`;
              target!.style.height = `${newHeight}px`;
              target!.style.marginTop = `${(height || 0) - newHeight}px`;
              if (svg) {
                svg!.style.width = `${newWidth}px`;
                svg!.style.height = `${newHeight}px`;
              }
            } else if (resizingNW) {
              target!.style.width = `${newWidth}px`;
              target!.style.marginLeft = `${(width || 0) - newWidth}px`;
              target!.style.height = `${newHeight}px`;
              target!.style.marginTop = `${(height || 0) - newHeight}px`;
              if (svg) {
                svg!.style.width = `${newWidth}px`;
                svg!.style.height = `${newHeight}px`;
              }
            } else if (resizingW) {
              target!.style.width = `${newWidth}px`;
              target!.style.marginLeft = `${(width || 0) - newWidth}px`;
              target!.style.height = `${newHeight}px`;
              if (svg) {
                svg!.style.width = `${newWidth}px`;
                svg!.style.height = `${newHeight}px`;
              }
            } else if (resizingSW) {
              target!.style.width = `${newWidth}px`;
              target!.style.marginLeft = `${(width || 0) - newWidth}px`;
              target!.style.height = `${newHeight}px`;
              if (svg) {
                svg!.style.width = `${newWidth}px`;
                svg!.style.height = `${newHeight}px`;
              }
            } else {
              target!.style.width = `${newWidth}px`;
              target!.style.height = `${newHeight}px`;
              if (svg) {
                svg!.style.width = `${newWidth}px`;
                svg!.style.height = `${newHeight}px`;
              }
            }
          }}
        />
      )}
      {isLocked && (
        <Overlay width={width} height={height} transform={style?.transform} />
      )}
    </div>
  );
};

export default Component;
