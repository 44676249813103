import { createContext, useContext } from 'react';
import { GenericResponse } from '../../domain/entities/rest';
import { Customer } from '../../domain/entities/user';

export type AuthHooks = {
  useLogin: () => {
    login: (
      email: string,
      password: string,
      remember: boolean,
      invitationCode: string,
    ) => Promise<object | null>;
    googleLogin: (
      email: string,
      name: string,
      googleId: string,
      invitationCode: string,
      avatar?: string,
    ) => Promise<object | null>;
    fbLogin: (
      email: string,
      name: string,
      userID: string,
      invitationCode: string,
      avatar: string,
    ) => Promise<object | null>;
    microsoftLogin: (
      email: string,
      name: string,
      accountId: string,
      invitationCode: string,
      avatar: string,
    ) => Promise<object | null>;
    reauthenticate: (email: string, password: string) => Promise<void>;
  };
  useLogout: () => {
    logout: () => Promise<void>;
  };
  useSignUp: () => {
    signUpUser: (
      name: string,
      email: string,
      password: string,
      invitationCode: string,
    ) => Promise<Object | null>;
    signUpUsingGoogle: (
      email: string,
      name: string,
      googleId: string,
      invitationCode: string,
    ) => Promise<object | null>;
    signUpUsingFb: (
      email: string,
      name: string,
      userID: string,
      invitationCode: string,
    ) => Promise<object | null>;
    signUpUsingMicrosoft: (
      email: string,
      name: string,
      accountId: string,
      invitationCode: string,
    ) => Promise<object | null>;
  };
  useResendVerification: () => {
    resendVerification: (token: string) => Promise<object>;
  };
  useUpdateUser: () => {
    updateUserDetails: (payload: {
      id: number;
      name?: string;
      displayPhoto?: File;
      oldPassword?: string;
      newPassword?: string;
      confirmPassword?: string;
      email?: string;
    }) => Promise<GenericResponse>;
  };
  useFetchUser: () => {
    fetchGoogleUserData: (
      accessToken: string,
    ) => Promise<{
      sub: string;
      name: string;
      picture: string;
      email: string;
    }>;
    fetchUserDetails: () => Promise<Customer | undefined>;
  };
  useDeleteUser: () => {
    deleteUserDetails: (payload: { id: number }) => Promise<GenericResponse>;
  };
  useUpdatePassword: () => {
    forgotPassword: (email: string) => Promise<GenericResponse>;
    resetPassword: (
      token: string,
      password: string,
      passwordConfirmation: string,
    ) => Promise<GenericResponse>;
  };
};

export const AuthHooksContext = createContext<AuthHooks | null>(null);

export const useAuthHooks = (): AuthHooks => {
  const context = useContext(AuthHooksContext);
  if (!context) {
    throw new Error(`Context not instantiated`);
  }
  return context;
};
