import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import theme from '../../../constants/themes';
import { handleTextOverflow } from '../../../utils/strings';
import { Tooltip } from '../Tooltip';

const StyledNavLink = styled(NavLink)<{ $isManagement?: boolean }>`
  width: 219px;
  height: 66px;
  padding-left: 81px;
  display: flex;
  align-items: center;
  font-family: ${theme.fonts.primary};
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: ${theme.colors.textColor};
  cursor: pointer;
  background-color: ${theme.colors.myboardMenuBg};
  text-decoration: none;

  &:hover {
    background-color: ${theme.colors.myBoardMenuBgHover};
  }
  &.active {
    color: ${theme.colors.notifToastBorder};
    padding-left: ${props => (props?.$isManagement ? 81 : 78)}px;
    border-left: ${props =>
      props?.$isManagement
        ? 'none'
        : '3px solid ' + theme.colors.notifToastBorder};
  }
`;

const Label = styled.span``;

export type Props = {
  to: string;
  label: string;
  isManagement?: boolean;
  className?: string;
};

const Component = ({
  to,
  label,
  isManagement,
  className,
}: Props): React.ReactElement => {
  return (
    <StyledNavLink className={className} $isManagement={isManagement} to={to}>
      {label.length <= 15 ? (
        <Label>{label}</Label>
      ) : (
        <Tooltip label={label}>
          <Label>{handleTextOverflow(label, 15)}</Label>
        </Tooltip>
      )}
    </StyledNavLink>
  );
};

export default Component;
