import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import theme from '../../../constants/themes';
import { Button } from '../../atoms/Button';
import { isWindowsOS } from '../../../utils/booleans';
import { setOpacity } from '../../../utils/colors';
import { t } from 'i18next';
import { Tooltip } from '../../atoms/Tooltip';
import { handleTextOverflow } from '../../../utils/strings';
import { Check } from '../../atoms/Icons';

const Container = styled.div`
  display: inline-flex;
  flex-direction: column;
  border: 1px solid #60bf91;
  align-items: center;
  border-radius: 10px;
  padding: 20px;
  color: ${theme.colors.white};
  box-sizing: border-box;
`;

const Name = styled.span<{ isMobile?: boolean }>`
  font-style: normal;
  font-weight: 700;
  font-size: ${({ isMobile }) => (isMobile ? '24px' : '34px')};
  line-height: ${({ isMobile }) => (isMobile ? '32px' : '49px')};
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: ${({ isMobile }) => (isMobile ? '0.1px' : '-0.02em')};

  ${({ isMobile }) => (isMobile ? `color: ${theme.colors.black}` : '')};
`;

const Description = styled.span<{ isMobile?: boolean }>`
  font-style: normal;
  font-weight: 700;
  font-size: ${({ isMobile }) => (isMobile ? '16px' : '20px')};
  line-height: ${({ isMobile }) => (isMobile ? '24px' : '29px')};
  text-align: center;
  letter-spacing: ${({ isMobile }) => (isMobile ? '0.1px' : '-0.02em')};
  margin: ${({ isMobile }) => (isMobile ? '0' : '20px 0')};

  ${({ isMobile }) => (isMobile ? `color: ${theme.colors.text02}` : '')};
`;

const BodyTitle = styled.div<{ isMobile?: boolean }>`
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 57px;
  letter-spacing: 0.2px;
  margin-bottom: ${({ isMobile }) => (isMobile ? '0' : '35px')};
`;

const SelectButton = styled(Button)`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  margin-top: 35px;
  border: none;
  padding: 15px 0px;

  &:active {
    border: unset;
  }
  &:hover {
    border: unset;
  }
`;

const Body = styled.div`
  background: ${theme.colors.white};
  border-radius: 10px;
  padding: 20px;
  width: calc(100% - 40px);
  text-align: center;
`;

const ListItem = styled.div<{ isMobile?: boolean }>`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: ${theme.colors.textColor};
  order: 1;
  flex-grow: 0;
  margin-bottom: 15px;
  display: flex;
  align-items: ${isWindowsOS() ? 'baseline' : 'center'};
  width: fit-content;

  ${({ isMobile }) => (isMobile ? 'gap: 0.5em' : '')};
`;

const ListItemDiv = styled.div`
  height: 117px;
`;

const BulletIcon = styled.div`
  background-color: ${theme.colors.textColor};
  width: 15px;
  height: 15px;
  margin-right: 10px;
  position: relative;
  top: 2px;
`;

const PointContainer = styled.div`
  text-align: left;
`;

const CurrencyContainer = styled.span`
  font-size: 24px;
`;

const PerMonthContainer = styled.span`
  font-size: 16px;
  margin-left: 10px;
`;

// mobile
const MobileContainer = styled.div<{ themeColor?: string }>`
  display: inline-flex;
  flex-direction: column;
  border: 1px solid ${({ themeColor }) => themeColor || '#60bf91'};
  align-items: center;
  border-radius: 10px;
  padding: 40px;
  color: ${theme.colors.white};
  box-sizing: border-box;
  background-color: ${theme.colors.white};
`;

const CheckIconContainer = styled.div`
  background: #2dc071;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledCheck = styled(Check)`
  width: 20px;
  height: 32px;
`;

export type Props = {
  name: string;
  description: string;
  bodyTitle: string;
  buttonTitle: string;
  style?: CSSProperties;
  themeColor?: string;
  points?: string[];
  className?: string;
  pricing: number;
  isDisabled?: boolean;
  onPress?: () => void;
  isMobile?: boolean;
};

const Component = ({
  name,
  description,
  themeColor,
  buttonTitle,
  points = [],
  pricing,
  className,
  isDisabled = false,
  isMobile,
  onPress,
}: Props): React.ReactElement => {
  return isMobile ? (
    <MobileContainer themeColor={themeColor}>
      <Name isMobile>{name}</Name>
      <Description isMobile>{description}</Description>
      <BodyTitle isMobile style={{ color: themeColor }}>
        {pricing} <CurrencyContainer>{t('yen')}</CurrencyContainer>
        <PerMonthContainer>{t('perMonth')}</PerMonthContainer>
      </BodyTitle>
      <ListItemDiv>
        {points.map((point, index) => (
          <ListItem isMobile key={`${point}-${index}`}>
            <CheckIconContainer>
              <StyledCheck />
            </CheckIconContainer>
            {point.length <= 13 ? (
              <PointContainer>{point}</PointContainer>
            ) : (
              <Tooltip label={point}>
                <PointContainer>{handleTextOverflow(point, 13)}</PointContainer>
              </Tooltip>
            )}
          </ListItem>
        ))}
      </ListItemDiv>
      <SelectButton
        title={buttonTitle}
        style={{
          backgroundColor: isDisabled
            ? setOpacity(themeColor || '', 0.2)
            : themeColor,
        }}
        onPress={onPress}
        disabled={isDisabled}
      />
    </MobileContainer>
  ) : (
    <Container style={{ backgroundColor: themeColor }} className={className}>
      <Name>{name}</Name>
      <Description>{description}</Description>
      <Body>
        <BodyTitle style={{ color: themeColor }}>
          {pricing} <CurrencyContainer>{t('yen')}</CurrencyContainer>
          <PerMonthContainer>{t('perMonth')}</PerMonthContainer>
        </BodyTitle>
        <ListItemDiv>
          {points.map((point, index) => (
            <ListItem key={`${point}-${index}`}>
              <BulletIcon />
              {point.length <= 13 ? (
                <PointContainer>{point}</PointContainer>
              ) : (
                <Tooltip label={point}>
                  <PointContainer>
                    {handleTextOverflow(point, 13)}
                  </PointContainer>
                </Tooltip>
              )}
            </ListItem>
          ))}
        </ListItemDiv>
        <SelectButton
          title={buttonTitle}
          style={{
            backgroundColor: isDisabled
              ? setOpacity(themeColor || '', 0.2)
              : themeColor,
          }}
          onPress={onPress}
          disabled={isDisabled}
        />
      </Body>
    </Container>
  );
};

export default Component;
