import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import theme from '../../../constants/themes';
import { isWindowsOS } from '../../../utils/booleans';
import { Link, Pencil, Trash } from '../../atoms/Icons';

const Wrapper = styled.div`
  display: inline-flex;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.25);
  background-color: ${theme.colors.white};
  border-radius: 3px;
  align-items: center;
  justify-content: center;
  height: 23px;
  font-size: 12px;
  padding: 4px 0;
  svg,
  a {
    white-space: nowrap;
    margin-left: 6px;
    :last-child {
      margin-right: 6px;
    }
  }
  path {
    fill: black;
  }
`;

const ClickableLink = styled.a`
  padding-top: ${isWindowsOS() ? 4 : 0}px;
`;

export type Props = {
  isOpen: boolean;
  link?: string;
  onClickEdit?: () => void;
  onClickDelete?: () => void;
  onClickLinkIcon: () => void;
  style?: CSSProperties;
};

const Component = ({
  isOpen,
  link,
  onClickLinkIcon,
  onClickEdit,
  onClickDelete,
  style,
}: Props): React.ReactElement => {
  return (
    <Wrapper style={style}>
      <Link onClick={onClickLinkIcon} />
      {isOpen && (
        <>
          <ClickableLink href={link} target="_blank" rel="noreferrer">
            {link}
          </ClickableLink>
          <Pencil onClick={onClickEdit} />
          <Trash onClick={onClickDelete} />
        </>
      )}
    </Wrapper>
  );
};

export default Component;
