import { Customer } from '../../../domain/entities/user';
import AuthService from '../../ports/AuthService';

export type fetchUserDetailsUseCase = () => Promise<Customer>;

export const buildFetchUserDetails = (dependencies: {
  authService: AuthService;
}): fetchUserDetailsUseCase => {
  const { authService } = dependencies;

  const fetchUserDetails: fetchUserDetailsUseCase = () =>
    authService.fetchUserDetails();

  return fetchUserDetails;
};
