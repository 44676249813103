import React, { SVGProps } from 'react';

type Props = SVGProps<SVGSVGElement>;

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M11.5 0C11.8812 0 12.2469 0.151451 12.5165 0.421034C12.7861 0.690618 12.9375 1.05625 12.9375 1.4375V10.0625H21.5625C21.9437 10.0625 22.3094 10.214 22.579 10.4835C22.8486 10.7531 23 11.1188 23 11.5C23 11.8812 22.8486 12.2469 22.579 12.5165C22.3094 12.7861 21.9437 12.9375 21.5625 12.9375H12.9375V21.5625C12.9375 21.9437 12.7861 22.3094 12.5165 22.579C12.2469 22.8486 11.8812 23 11.5 23C11.1188 23 10.7531 22.8486 10.4835 22.579C10.214 22.3094 10.0625 21.9437 10.0625 21.5625V12.9375H1.4375C1.05625 12.9375 0.690618 12.7861 0.421034 12.5165C0.151451 12.2469 0 11.8812 0 11.5C0 11.1188 0.151451 10.7531 0.421034 10.4835C0.690618 10.214 1.05625 10.0625 1.4375 10.0625H10.0625V1.4375C10.0625 1.05625 10.214 0.690618 10.4835 0.421034C10.7531 0.151451 11.1188 0 11.5 0Z"
        fill="#737373"
      />
    </svg>
  );
};

export default Component;
