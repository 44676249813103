import React, { SVGProps } from 'react';

type Props = SVGProps<SVGSVGElement>;

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="10"
      height="20"
      viewBox="0 0 10 20"
      fill="black"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M1.75868 15H5.19068C7.29668 15 8.90868 14.103 8.90868 12.166C8.90868 10.879 8.14168 10.138 7.11468 9.891V9.839C7.93368 9.54 8.41468 8.643 8.41468 7.759C8.41468 5.965 6.90668 5.38 4.93068 5.38H1.75868V15ZM3.68268 9.293V6.862H4.82668C5.98368 6.862 6.54268 7.187 6.54268 8.032C6.54268 8.812 6.02268 9.293 4.80068 9.293H3.68268ZM3.68268 13.518V10.723H5.02168C6.34768 10.723 7.03668 11.126 7.03668 12.062C7.03668 13.05 6.32168 13.518 5.02168 13.518H3.68268Z"
        fill="inherit"
      />
    </svg>
  );
};

export default Component;
