import { GenericResponse } from '../../../domain/entities/rest';
import PlanRepository from '../../ports/PlanRepository';

export type selectFreePlanUseCase = (
  planId: number,
  customerId: number,
) => Promise<GenericResponse>;

export const buildSelectFreePlan = (dependencies: {
  planRepo: PlanRepository;
}): selectFreePlanUseCase => {
  const { planRepo } = dependencies;

  const selectFreePlan: selectFreePlanUseCase = (planId, customerId) =>
    planRepo.selectFreePlan(planId, customerId);

  return selectFreePlan;
};
