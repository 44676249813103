import InlineEditor from '@itsmitchyyy/ckeditor5-build-inline';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Container, Skeleton } from '@mui/material';
import createDOMPurify from 'dompurify';
import React, { useCallback, useState } from 'react';
import {
  ReactFacebookFailureResponse,
  ReactFacebookLoginInfo,
} from 'react-facebook-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { useTranslation } from 'react-i18next';
import MicrosoftLogin from 'react-microsoft-login';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { useGoogleLogin } from '@react-oauth/google';
import axios, { ResponseType } from 'axios';
import theme from '../../../constants/themes';
import { MicrosoftError } from '../../../domain/entities/errors';
import { useAuthHooks } from '../../../hooks/auth';
import { useLocalStorageHooks } from '../../../hooks/localStorage';
import { Button } from '../../atoms/Button';
import {
  Facebook,
  Flagman,
  Google,
  Microsoft,
  RightArrow,
} from '../../atoms/Icons';
import { Toast } from '../../atoms/Toast';
import { Props as INotification } from '../../atoms/Toast/Component';
import {
  FbLoginValues,
  GoogleLoginValues,
  MicrosoftValues,
} from '../../templates/Login/types';
import { AddressContent, FaqContent, FooterContent } from './types';
import { blobToBase64 } from '../../templates/Login/Component';
import urls from '../../../constants/urls';

const Wrapper = styled.div`
  background-color: ${theme.colors.topFooterBg};

  .ckEditorTopWrapper {
    height: auto;
  }

  .ckEditorParagraph {
    padding: 0.3em;
    margin: 0;
  }

  @media ${theme.breakpoints.tablet} {
    display: none;
  }
  @media ${theme.breakpoints.mobile} {
    display: none;
  }
`;

const TopWrapper = styled.div`
  width: calc(100% - 190.5);
  min-height: 170px;
  border-bottom: thin solid ${theme.colors.topHeaderBorder};
  padding: 28px 0em 41px 0em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const LinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
`;

const LinksWrapper = styled.div`
  width: max-content;
  display: flex;
  flex-direction: column;
  gap: 0.5em;

  :not(:last-of-type) {
    margin-right: 5em;
  }

  div {
    a {
      font-family: ${theme.fonts.primary};
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      letter-spacing: -0.02em;
      color: ${theme.colors.black};
      margin-bottom: 10px;
      text-decoration: none;

      :hover {
        text-decoration: underline;
      }
    }
  }
`;

const LinkTitle = styled.span`
  font-family: ${theme.fonts.primary};
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: ${theme.colors.black};
  margin-bottom: 7px;

  p {
    margin: 0;

    a {
      text-decoration: none;
      color: ${theme.colors.black};
    }
  }
`;

const LinkContent = styled.div`
  p {
    margin: 0;

    a {
      text-decoration: none;
    }
  }
`;

const RightContent = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  justify-content: flex-end;

  .ckAddressEditContent {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
  }
`;

const AddressContainer = styled.div`
  width: 272px;
  text-align: center;
  margin-top: 84px;
  margin-right: 1em;

  p {
    font-family: ${theme.fonts.primary};
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: ${theme.colors.black};
    margin: 0;
    padding: 0;

    :not(:last-of-type) {
      margin-bottom: 0.5em;
    }
  }
`;

const SignupContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 227px;
  align-items: center;
`;

const StyledFlagman = styled(Flagman)`
  width: 177px;
  height: 80px;
  margin-bottom: 7px;
  margin-top: 16px;
`;

const StyledButton = styled(Button)`
  width: 227px;
  height: 58px;
`;

const SocialMediaContainer = styled.div<{ isEditing?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  gap: 2em;
  margin-top: ${({ isEditing }) => (isEditing ? '30px' : '16px')};
`;

const StyledGoogle = styled(Google)`
  && {
    width: 16px;
    height: 16px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.5));
      transform: translateY(-0.15em);
    }
  }
`;

const StyledFacebook = styled(Facebook)`
  && {
    width: 24px;
    height: 24px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      filter: drop-shadow(4px 4px 4px rgba(57, 81, 150, 0.5));
      transform: translateY(-0.15em);
    }
  }
`;

const StyledMicrosoft = styled(Microsoft)`
  && {
    width: 24px;
    height: 24px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      filter: drop-shadow(4px 4px 4px rgba(234, 62, 35, 0.5));
      transform: translateY(-0.15em);
    }
  }
`;

const BottomWrapper = styled.div<{ hasError?: boolean }>`
  width: calc(100% - 190.5);
  height: ${({ hasError }) => (hasError ? 'auto' : '80px')};
  display: flex;
  align-items: center;
`;

const FaqsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
`;

const Helpers = styled.div<{ hasError?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6em;
  font-size: 13px;
  line-height: 19px;
  color: ${theme.colors.black};
  font-weight: 400;

  ${({ hasError }) =>
    hasError
      ? `align-items: flex-start;
  padding-top: 1em`
      : ``};

  div {
    a {
      text-decoration: none;
      color: #000000;
      display: flex;
      align-items: center;

      :hover {
        text-decoration: underline;
      }
    }
  }
`;

const Copyright = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  font-family: ${theme.fonts.primary};
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  color: ${theme.colors.black};
`;

const ErrorWrapper = styled.div`
  color: ${theme.colors.errorToastText};
`;

const DivContainer = styled.div<{ hasError?: boolean }>`
  .ck.ck-editor__editable.ck-rounded-corners:not(.ck-editor__nested-editable) {
    ${({ hasError }) =>
      hasError ? `border: 1px solid ${theme.colors.errorToastText}` : ''};
  }
`;

const DOMPurify = createDOMPurify(window as any);

export type Props = {
  footerContents: FooterContent;
  addressContents: AddressContent;
  faqsContents: FaqContent;
  isFetching?: boolean;
  allowEditContent?: boolean;
  onChangeFooterContent?: (data: FooterContent) => void;
  onChangeAddressContent?: (data: AddressContent) => void;
  onChangeFaqContent?: (data: FaqContent) => void;
  onPressRegistration: () => void;
  serverErrors?: any;
};

const Component = ({
  footerContents,
  addressContents,
  faqsContents,
  isFetching,
  allowEditContent,
  serverErrors,
  onPressRegistration,
  onChangeFooterContent,
  onChangeAddressContent,
  onChangeFaqContent,
}: Props): React.ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { useLogin, useFetchUser } = useAuthHooks();
  const { fetchGoogleUserData } = useFetchUser();
  const { googleLogin, fbLogin, microsoftLogin } = useLogin();
  const { useLocalStorage } = useLocalStorageHooks();
  const { clearSessionStorage } = useLocalStorage();
  const loginGoogle = useGoogleLogin({
    onSuccess: async tokenResponse => {
      const userInfo = await fetchGoogleUserData(tokenResponse.access_token);

      googleLoginMutation({
        email: userInfo.email,
        name: userInfo.name,
        googleId: userInfo.sub,
        invitationCode: '',
        avatar: userInfo.picture,
      });
    },
  });
  const [notification, setNotification] = useState<INotification | null>(null);

  const handleCloseToast = () => {
    setNotification(null);
  };

  const { mutate: googleLoginMutation } = useMutation(
    (params: GoogleLoginValues) => {
      return googleLogin(
        params.email,
        params.name,
        params.googleId,
        '',
        params.avatar,
      );
    },
    {
      onSuccess: () => {
        navigate('/my-board/personal');
      },
      onError: response => {
        setNotification({
          isOpen: true,
          message: String(response)
            .replace('Error:', '')
            .trim(),
          type: 'error',
          position: 'top-right',
          onClose: handleCloseToast,
        });
      },
    },
  );

  const { mutate: fbLoginMutation } = useMutation(
    (params: FbLoginValues) => {
      return fbLogin(
        params.email,
        params.name,
        params.userID,
        '',
        params.avatar,
      );
    },
    {
      onSuccess: () => {
        navigate('/my-board/personal');
      },
      onError: response => {
        setNotification({
          isOpen: true,
          message: String(response)
            .replace('Error:', '')
            .trim(),
          type: 'error',
          position: 'top-right',
          onClose: handleCloseToast,
        });
      },
    },
  );

  const { mutate: microsoftLoginMutation } = useMutation(
    (params: MicrosoftValues) => {
      return microsoftLogin(
        params.account.userName,
        params.account.name,
        params.account.accountIdentifier,
        '',
        '',
      );
    },
    {
      onSuccess: () => {
        navigate('/my-board/personal');
      },
      onError: response => {
        setNotification({
          isOpen: true,
          message: String(response)
            .replace('Error:', '')
            .trim(),
          type: 'error',
          position: 'top-right',
          onClose: handleCloseToast,
        });
      },
    },
  );

  const handleMicrosoftLogin = useCallback(async (err, data) => {
    const error: MicrosoftError = err;
    const response = data as MicrosoftValues;

    if (response?.uniqueId) {
      // Note: This is for preventing the Microsoft Button to call the auth every time the page loads
      clearSessionStorage();
      const options = {
        headers: {
          Authorization: `Bearer ${response.accessToken}`,
          'Content-Type': 'image/jpg',
        },
        responseType: 'blob' as ResponseType,
      };
      const photo = await axios
        .get(urls.microsoft.profilePhoto, options)
        .then(async response => {
          const base64 = await blobToBase64(response.data).then(
            base64data => base64data,
          );
          return base64 as string;
        })
        .catch(function(err) {
          console.log(err);
          return '';
        });

      microsoftLoginMutation({ ...response, avatar: photo ?? '' });
    } else if (!!error && error.errorCode !== 'user_cancelled') {
      setNotification({
        isOpen: true,
        message: `Microsoft Error: ${error.errorMessage}`,
        type: 'error',
        position: 'top-right',
        onClose: handleCloseToast,
      });
    }
  }, []);

  const submitFbLogin = useCallback((response: ReactFacebookLoginInfo) => {
    fbLoginMutation({
      email: response.email || '',
      name: response.name || response.userID,
      userID: response.userID,
      invitationCode: '',
      avatar: response.picture?.data.url || '',
    });
  }, []);

  const handleFailureFb = useCallback(
    (response: ReactFacebookFailureResponse) => {
      if (response.status !== 'unknown') {
        setNotification({
          isOpen: true,
          message: `Facebook Error: ${response.status}`,
          type: 'error',
          position: 'top-right',
          onClose: handleCloseToast,
        });
      }
    },
    [],
  );

  return (
    <Wrapper>
      <Container>
        <TopWrapper className={allowEditContent ? 'ckEditorTopWrapper' : ''}>
          <LinksContainer>
            {isFetching ? (
              <>
                <LinksWrapper>
                  <Skeleton
                    sx={{ marginTop: '10px' }}
                    variant="rectangular"
                    height={20}
                    width={90}
                  />
                  <Skeleton
                    sx={{ marginTop: '10px' }}
                    variant="rectangular"
                    height={20}
                    width={90}
                  />
                  <Skeleton
                    sx={{ marginTop: '10px' }}
                    variant="rectangular"
                    height={20}
                    width={90}
                  />
                </LinksWrapper>
                <LinksWrapper>
                  <Skeleton
                    sx={{ marginTop: '10px' }}
                    variant="rectangular"
                    height={20}
                    width={90}
                  />
                  <Skeleton
                    sx={{ marginTop: '10px' }}
                    variant="rectangular"
                    height={20}
                    width={90}
                  />
                  <Skeleton
                    sx={{ marginTop: '10px' }}
                    variant="rectangular"
                    height={20}
                    width={90}
                  />
                </LinksWrapper>
                <LinksWrapper>
                  <Skeleton
                    sx={{ marginTop: '10px' }}
                    variant="rectangular"
                    height={20}
                    width={90}
                  />
                  <Skeleton
                    sx={{ marginTop: '10px' }}
                    variant="rectangular"
                    height={20}
                    width={90}
                  />
                  <Skeleton
                    sx={{ marginTop: '10px' }}
                    variant="rectangular"
                    height={20}
                    width={90}
                  />
                </LinksWrapper>
              </>
            ) : (
              <>
                {footerContents?.map((content, index) => (
                  <LinksWrapper key={index}>
                    {allowEditContent ? (
                      <DivContainer
                        key={index}
                        hasError={
                          serverErrors &&
                          !!serverErrors?.[
                            `footer.${
                              index === 0
                                ? 'plan'
                                : index === 1
                                ? 'frequently_used_links'
                                : 'company_overview'
                            }.title`
                          ]
                        }>
                        <CKEditor
                          key={index}
                          config={{
                            extraAllowedContent: 'div(*)',
                            allowedContent: true,
                            toolbar: {
                              items: [
                                'Heading',
                                'Bold',
                                'Italic',
                                'Link',
                                'Undo',
                                'Redo',
                              ],
                            },
                          }}
                          editor={InlineEditor}
                          data={content.title}
                          onChange={(event: any, editor: any) => {
                            const data = editor.getData();

                            const footerData = { ...footerContents };
                            footerData[index].title = data;

                            if (onChangeFooterContent) {
                              onChangeFooterContent(footerData);
                            }
                          }}
                        />
                        {serverErrors &&
                          !!serverErrors?.[
                            `footer.${
                              index === 0
                                ? 'plan'
                                : index === 1
                                ? 'frequently_used_links'
                                : 'company_overview'
                            }.title`
                          ] && (
                            <ErrorWrapper style={{ width: '8em' }}>
                              <span>
                                {
                                  serverErrors?.[
                                    `footer.${
                                      index === 0
                                        ? 'plan'
                                        : index === 1
                                        ? 'frequently_used_links'
                                        : 'company_overview'
                                    }.title`
                                  ]
                                }
                              </span>
                            </ErrorWrapper>
                          )}
                      </DivContainer>
                    ) : (
                      <LinkTitle
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(content?.title),
                        }}
                      />
                    )}
                    {content?.item?.map((link, itemIndex) => {
                      return allowEditContent ? (
                        <DivContainer
                          key={itemIndex}
                          hasError={
                            serverErrors &&
                            !!serverErrors?.[
                              `footer.${
                                index === 0
                                  ? 'plan'
                                  : index === 1
                                  ? 'frequently_used_links'
                                  : 'company_overview'
                              }.item.${itemIndex}`
                            ]
                          }>
                          <CKEditor
                            key={itemIndex}
                            config={{
                              extraAllowedContent: 'div(*)',
                              allowedContent: true,
                              toolbar: {
                                items: [
                                  'Heading',
                                  'Bold',
                                  'Italic',
                                  'Link',
                                  'Undo',
                                  'Redo',
                                ],
                              },
                            }}
                            editor={InlineEditor}
                            data={link}
                            onChange={(event: any, editor: any) => {
                              const data = editor.getData();

                              const footerData = { ...footerContents };
                              footerData[index].item[itemIndex] = data;

                              if (onChangeFooterContent) {
                                onChangeFooterContent(footerData);
                              }
                            }}
                          />

                          {serverErrors &&
                            !!serverErrors?.[
                              `footer.${
                                index === 0
                                  ? 'plan'
                                  : index === 1
                                  ? 'frequently_used_links'
                                  : 'company_overview'
                              }.item.${itemIndex}`
                            ] && (
                              <ErrorWrapper style={{ width: '8em' }}>
                                <span>
                                  {
                                    serverErrors?.[
                                      `footer.${
                                        index === 0
                                          ? 'plan'
                                          : index === 1
                                          ? 'frequently_used_links'
                                          : 'company_overview'
                                      }.item.${itemIndex}`
                                    ]
                                  }
                                </span>
                              </ErrorWrapper>
                            )}
                        </DivContainer>
                      ) : (
                        <LinkContent
                          key={itemIndex}
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(link),
                          }}
                        />
                      );
                    })}
                  </LinksWrapper>
                ))}
              </>
            )}
          </LinksContainer>
          <RightContent>
            <AddressContainer
              className={allowEditContent ? 'ckAddressEditContent' : ''}>
              {allowEditContent ? (
                <>
                  <DivContainer
                    hasError={
                      serverErrors && !!serverErrors?.[`footer.company_address`]
                    }>
                    <CKEditor
                      config={{
                        extraAllowedContent: 'div(*)',
                        allowedContent: true,
                        toolbar: {
                          items: [
                            'Heading',
                            'Bold',
                            'Italic',
                            'Link',
                            'Undo',
                            'Redo',
                          ],
                        },
                      }}
                      editor={InlineEditor}
                      data={addressContents.address}
                      onChange={(event: any, editor: any) => {
                        const data = editor.getData();

                        const addressData = { ...addressContents };
                        addressData.address = data;

                        if (onChangeAddressContent) {
                          onChangeAddressContent(addressData);
                        }
                      }}
                    />

                    {serverErrors &&
                      !!serverErrors?.[`footer.company_address`] && (
                        <ErrorWrapper>
                          <span>
                            {serverErrors?.[`footer.company_address`]}
                          </span>
                        </ErrorWrapper>
                      )}
                  </DivContainer>
                  <DivContainer
                    hasError={serverErrors && !!serverErrors?.[`footer.tel`]}>
                    <CKEditor
                      config={{
                        extraAllowedContent: 'div(*)',
                        allowedContent: true,
                        toolbar: {
                          items: [
                            'Heading',
                            'Bold',
                            'Italic',
                            'Link',
                            'Undo',
                            'Redo',
                          ],
                        },
                      }}
                      editor={InlineEditor}
                      data={addressContents.telephoneNumber}
                      onChange={(event: any, editor: any) => {
                        const data = editor.getData();

                        const addressData = { ...addressContents };
                        addressData.telephoneNumber = data;

                        if (onChangeAddressContent) {
                          onChangeAddressContent(addressData);
                        }
                      }}
                    />

                    {serverErrors && !!serverErrors?.[`footer.tel`] && (
                      <ErrorWrapper>
                        <span>{serverErrors?.[`footer.tel`]}</span>
                      </ErrorWrapper>
                    )}
                  </DivContainer>
                  <DivContainer
                    hasError={serverErrors && !!serverErrors?.[`footer.fax`]}>
                    <CKEditor
                      config={{
                        extraAllowedContent: 'div(*)',
                        allowedContent: true,
                        toolbar: {
                          items: [
                            'Heading',
                            'Bold',
                            'Italic',
                            'Link',
                            'Undo',
                            'Redo',
                          ],
                        },
                      }}
                      editor={InlineEditor}
                      data={addressContents.faxNumber}
                      onChange={(event: any, editor: any) => {
                        const data = editor.getData();

                        const addressData = { ...addressContents };
                        addressData.faxNumber = data;

                        if (onChangeAddressContent) {
                          onChangeAddressContent(addressData);
                        }
                      }}
                    />

                    {serverErrors && !!serverErrors?.[`footer.fax`] && (
                      <ErrorWrapper>
                        <span>{serverErrors?.[`footer.fax`]}</span>
                      </ErrorWrapper>
                    )}
                  </DivContainer>
                </>
              ) : (
                <>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(addressContents.address),
                    }}
                  />
                  <p
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(
                        addressContents.telephoneNumber,
                      ),
                    }}
                  />
                  <p
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(addressContents.faxNumber),
                    }}
                  />
                </>
              )}
            </AddressContainer>
            <SignupContainer>
              <StyledFlagman />
              <StyledButton
                theme="lightSecondary"
                title={t('topSignup')}
                icon={<RightArrow />}
                iconPosition="right"
                iconMargin={15}
                onPress={onPressRegistration}
              />
              <SocialMediaContainer isEditing={allowEditContent}>
                <StyledGoogle
                  onClick={() => {
                    if (!allowEditContent) {
                      loginGoogle();
                    }
                  }}
                />
                <FacebookLogin
                  appId={process.env.REACT_APP_FB_APP_ID || ''}
                  callback={submitFbLogin}
                  onFailure={handleFailureFb}
                  fields="name,picture,email"
                  render={(renderProps: {
                    onClick: string | number | Function;
                  }) => {
                    if (!allowEditContent)
                      return <StyledFacebook onClick={renderProps.onClick} />;

                    return <StyledFacebook />;
                  }}
                />
                {allowEditContent ? (
                  <StyledMicrosoft />
                ) : (
                  <MicrosoftLogin
                    clientId={process.env.REACT_APP_MICROSOFT_CLIENT_ID || ''}
                    authCallback={handleMicrosoftLogin}>
                    <StyledMicrosoft />
                  </MicrosoftLogin>
                )}
              </SocialMediaContainer>
            </SignupContainer>
          </RightContent>
        </TopWrapper>
        <BottomWrapper
          hasError={
            serverErrors &&
            (!!serverErrors?.[`footer.privacy_policy`] ||
              !!serverErrors?.[`footer.terms_of_service`] ||
              !!serverErrors?.[`footer.transaction_law`])
          }>
          <FaqsWrapper>
            <Helpers
              hasError={
                serverErrors &&
                (!!serverErrors?.[`footer.privacy_policy`] ||
                  !!serverErrors?.[`footer.terms_of_service`] ||
                  !!serverErrors?.[`footer.transaction_law`])
              }>
              {allowEditContent ? (
                <>
                  <DivContainer
                    hasError={
                      serverErrors && !!serverErrors?.[`footer.privacy_policy`]
                    }>
                    <CKEditor
                      config={{
                        extraAllowedContent: 'div(*)',
                        allowedContent: true,
                        toolbar: {
                          items: [
                            'Heading',
                            'Bold',
                            'Italic',
                            'Link',
                            'Undo',
                            'Redo',
                          ],
                        },
                      }}
                      editor={InlineEditor}
                      data={faqsContents.privacyPolicy}
                      onChange={(event: any, editor: any) => {
                        const data = editor.getData();

                        const faqData = { ...faqsContents };
                        faqData.privacyPolicy = data;

                        if (onChangeFaqContent) {
                          onChangeFaqContent(faqData);
                        }
                      }}
                    />
                    {serverErrors && !!serverErrors?.[`footer.privacy_policy`] && (
                      <ErrorWrapper
                        style={{ width: '10em', marginTop: '.5em' }}>
                        <span>{serverErrors?.[`footer.privacy_policy`]}</span>
                      </ErrorWrapper>
                    )}
                  </DivContainer>
                  <DivContainer
                    hasError={
                      serverErrors &&
                      !!serverErrors?.[`footer.terms_of_service`]
                    }>
                    <CKEditor
                      config={{
                        extraAllowedContent: 'div(*)',
                        allowedContent: true,
                        toolbar: {
                          items: [
                            'Heading',
                            'Bold',
                            'Italic',
                            'Link',
                            'Undo',
                            'Redo',
                          ],
                        },
                      }}
                      editor={InlineEditor}
                      data={faqsContents.termsOfService}
                      onChange={(event: any, editor: any) => {
                        const data = editor.getData();

                        const faqData = { ...faqsContents };
                        faqData.termsOfService = data;

                        if (onChangeFaqContent) {
                          onChangeFaqContent(faqData);
                        }
                      }}
                    />
                    {serverErrors &&
                      !!serverErrors?.[`footer.terms_of_service`] && (
                        <ErrorWrapper
                          style={{ width: '10em', marginTop: '.5em' }}>
                          <span>
                            {serverErrors?.[`footer.terms_of_service`]}
                          </span>
                        </ErrorWrapper>
                      )}
                  </DivContainer>
                  <DivContainer
                    hasError={
                      serverErrors && !!serverErrors?.[`footer.transaction_law`]
                    }>
                    <CKEditor
                      config={{
                        extraAllowedContent: 'div(*)',
                        allowedContent: true,
                        toolbar: {
                          items: [
                            'Heading',
                            'Bold',
                            'Italic',
                            'Link',
                            'Undo',
                            'Redo',
                          ],
                        },
                      }}
                      editor={InlineEditor}
                      data={faqsContents.transactionLaw}
                      onChange={(event: any, editor: any) => {
                        const data = editor.getData();

                        const faqData = { ...faqsContents };
                        faqData.transactionLaw = data;

                        if (onChangeFaqContent) {
                          onChangeFaqContent(faqData);
                        }
                      }}
                    />
                    {serverErrors &&
                      !!serverErrors?.[`footer.transaction_law`] && (
                        <ErrorWrapper
                          style={{ width: '10em', marginTop: '.5em' }}>
                          <span>
                            {serverErrors?.[`footer.transaction_law`]}
                          </span>
                        </ErrorWrapper>
                      )}
                  </DivContainer>
                </>
              ) : (
                <>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(faqsContents.privacyPolicy),
                    }}
                  />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(faqsContents.termsOfService),
                    }}
                  />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(faqsContents.transactionLaw),
                    }}
                  />
                </>
              )}
            </Helpers>
            <Copyright>
              <p>&copy;2022 Flagman. All Rights Reserved.</p>
            </Copyright>
          </FaqsWrapper>
        </BottomWrapper>
      </Container>
      {notification ? <Toast {...notification} /> : null}
    </Wrapper>
  );
};

export default Component;
