import BoardRepository from '../../ports/BoardRepository';
import { InviteCustomerToBoardSuccess } from '../../../domain/entities/board';

export type inviteCustomersUseCase = (
  boardCode: string,
  emails: string[],
) => Promise<InviteCustomerToBoardSuccess>;

export const buildInviteCustomers = (dependencies: {
  boardRepo: BoardRepository;
}): inviteCustomersUseCase => {
  const { boardRepo } = dependencies;

  const inviteCustomers: inviteCustomersUseCase = (boardCode, emails) =>
    boardRepo.inviteCustomers(boardCode, emails);

  return inviteCustomers;
};
