import React from 'react';
import styled from 'styled-components';
import theme from '../../../constants/themes';
import { Formik, Form, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { TextInput } from '../../atoms/TextInput';
import { Button } from '../../atoms/Button';
import { ModalWrapper } from '../../molecules/ModalWrapper';
import { CreateTeamValues } from './types';
import { CreateTeamSchema } from './validation';

const Container = styled.div`
  width: 683px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 37px;

  @media ${theme.breakpoints.mobile} {
    width: 100%;
  }

  @media ${theme.breakpoints.tablet} {
    width: 100%;
  }
`;

const Wrapper = styled(Form)`
  width: 683px;
  height: max-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: ${theme.colors.white};

  @media ${theme.breakpoints.mobile} {
    display: block;
    width: 100%;
  }

  @media ${theme.breakpoints.tablet} {
    width: 100%;
    display: block;
  }
`;

const StyledInput = styled(TextInput)`
  width: 440px;
  height: 50px;
  margin-right: 8px;

  @media ${theme.breakpoints.mobile} {
    width: 100%;
  }

  @media ${theme.breakpoints.tablet} {
    width: 100%;
  }
`;

const StyledBtn = styled(Button)`
  width: 107px;
  height: 50px;

  @media ${theme.breakpoints.mobile} {
    margin-top: 10px;
    width: 100%;
  }

  @media ${theme.breakpoints.tablet} {
    margin-top: 10px;
    width: 100%;
  }
`;

const ErrorMessage = styled.span`
  font-family: ${theme.fonts.primary};
  font-weight: 400;
  font-size: 13px;
  line-height: 14px;
  color: ${theme.colors.textError};
  margin-top: 8px;
  margin-left: 85px;
`;

export type Props = {
  isOpen?: boolean;
  isCreatingTeam?: boolean;
  onClose: () => void;
  onPressAdd: (
    values: CreateTeamValues,
    { resetForm }: FormikHelpers<CreateTeamValues>,
  ) => void;
};

const Component = ({
  isOpen = false,
  isCreatingTeam,
  onClose,
  onPressAdd,
}: Props): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{ teamName: '' }}
      onSubmit={onPressAdd}
      validationSchema={CreateTeamSchema}>
      {({
        handleChange,
        handleSubmit,
        values,
        isValid,
        errors,
        resetForm,
      }): React.ReactElement => {
        const { teamName } = values;
        const hasErrors = Object.keys(errors).length > 0;
        return (
          <ModalWrapper
            isOpen={isOpen}
            title={t('addTeamModalTitle')}
            onClose={() => {
              resetForm();
              onClose();
            }}>
            <Container>
              <Wrapper>
                <StyledInput
                  onChangeText={handleChange('teamName')}
                  value={teamName}
                  placeholder={t('addTeamPlaceholder')}
                  isError={!!errors.teamName}
                />
                <StyledBtn
                  theme="secondary"
                  title={t('addBtn')}
                  onPress={() => handleSubmit()}
                  disabled={isCreatingTeam || !isValid || hasErrors}
                  type="submit"
                  isLoading={isCreatingTeam}
                />
              </Wrapper>
              {errors.teamName && (
                <ErrorMessage>{errors.teamName}</ErrorMessage>
              )}
            </Container>
          </ModalWrapper>
        );
      }}
    </Formik>
  );
};

export default Component;
