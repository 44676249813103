import React from 'react';

type Props = {
  [key: string]: string | number | Function | object;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M15 0.734863C6.729 0.734863 0 6.24616 0 13.0205C0 17.0124 2.31996 20.6961 6.24517 23.0021C5.70058 25.548 4.48012 27.3345 4.46676 27.3538L3.1325 29.2651L5.44668 28.9684C5.71326 28.9341 11.9218 28.1113 16.3469 25.2568C19.9809 24.9923 23.356 23.6591 25.8777 21.487C28.536 19.1969 30 16.1902 30 13.0206C30 6.24616 23.271 0.734863 15 0.734863ZM15.948 23.2119L15.6657 23.228L15.4309 23.3858C12.8576 25.1145 9.41266 26.0734 7.24548 26.5424C7.7001 25.5261 8.17787 24.175 8.41634 22.6153L8.5278 21.8862L7.87465 21.5439C4.23779 19.638 2.06648 16.4519 2.06648 13.0205C2.06648 7.38575 7.86845 2.80134 15 2.80134C22.1315 2.80134 27.9335 7.38561 27.9335 13.0205C27.9335 18.3495 22.6688 22.826 15.948 23.2119Z"
        fill="#60BF91"
      />
    </svg>
  );
};

export default Component;
