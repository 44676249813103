import React from 'react';
import { Payment } from '../../templates/Payment';

export type Props = {};

const Component = ({}: Props): React.ReactElement => {
  return (
    <>
      <Payment />
    </>
  );
};

export default Component;
