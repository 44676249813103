import React from 'react';
import styled from 'styled-components';
import theme from '../../../constants/themes';
import DiagramPreview from '../DiagramPreview/DiagramFetchHOC';

const Wrapper = styled.div`
  background: ${theme.colors.white};
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const BoardWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const BoardPreview = styled(DiagramPreview)`
  :hover {
    box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.1);
  }
  cursor: pointer;
`;

const BoardName = styled.span`
  margin-top: 12px;

  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #707070;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 50px;
  :last-child {
    margin-bottom: 0;
  }
`;

export type SelectableTemplate = {
  id: number;
  diagramUrl: string;
  name: string;
};

export type Props = {
  templates: SelectableTemplate[];
  disabled?: boolean;
  onClickDiagram?: (payload: {
    id: number;
    xmlStr?: string;
    name: string;
  }) => void;
};

const Component = ({
  templates,
  disabled = false,
  onClickDiagram = () => {},
}: Props): React.ReactElement => {
  const inPairs: SelectableTemplate[][] = new Array(
    Math.ceil(templates.length / 2),
  )
    .fill(null)
    .reduce((acc, cur, index) => {
      const toBePushed = [
        templates[index * 2],
        templates[index * 2 + 1],
      ].filter(item => !!item);
      return [...acc, toBePushed];
    }, []);
  return (
    <Wrapper>
      {inPairs.map((pair, index) => (
        <Row key={`template-pair-${index}`}>
          {pair.map(template => (
            <BoardWrapper key={`template-${template.id}`}>
              <BoardPreview
                diagramUrl={template.diagramUrl}
                style={{ width: 500, height: 290 }}
                onClickDiagram={
                  !disabled
                    ? (xmlStr: string) => {
                        if (template.name) {
                          onClickDiagram({
                            id: template.id,
                            name: template.name,
                            xmlStr,
                          });
                        }
                      }
                    : undefined
                }
              />
              <BoardName>{template.name}</BoardName>
            </BoardWrapper>
          ))}
        </Row>
      ))}
    </Wrapper>
  );
};

export default Component;
