import React from 'react';

type Props = {
  [key: string]: string | number | Function;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.05347 5.33337C9.05347 5.16761 9.11931 5.00864 9.23653 4.89143C9.35374 4.77422 9.51271 4.70837 9.67847 4.70837H15.9285C16.0942 4.70837 16.2532 4.77422 16.3704 4.89143C16.4876 5.00864 16.5535 5.16761 16.5535 5.33337V11.5834C16.5535 11.7491 16.4876 11.9081 16.3704 12.0253C16.2532 12.1425 16.0942 12.2084 15.9285 12.2084C15.7627 12.2084 15.6037 12.1425 15.4865 12.0253C15.3693 11.9081 15.3035 11.7491 15.3035 11.5834V5.95837H9.67847C9.51271 5.95837 9.35374 5.89253 9.23653 5.77532C9.11931 5.65811 9.05347 5.49913 9.05347 5.33337Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.371 4.89089C16.4292 4.94895 16.4754 5.01792 16.5069 5.09385C16.5384 5.16978 16.5546 5.25118 16.5546 5.33339C16.5546 5.4156 16.5384 5.497 16.5069 5.57293C16.4754 5.64887 16.4292 5.71784 16.371 5.77589L5.12102 17.0259C5.00367 17.1432 4.84449 17.2092 4.67852 17.2092C4.51255 17.2092 4.35338 17.1432 4.23602 17.0259C4.11867 16.9085 4.05273 16.7494 4.05273 16.5834C4.05273 16.4174 4.11867 16.2582 4.23602 16.1409L15.486 4.89089C15.5441 4.83269 15.613 4.78651 15.689 4.755C15.7649 4.72349 15.8463 4.70728 15.9285 4.70728C16.0107 4.70728 16.0921 4.72349 16.1681 4.755C16.244 4.78651 16.313 4.83269 16.371 4.89089Z"
        fill="black"
      />
    </svg>
  );
};

export default Component;
