import { createContext, useContext } from 'react';
import { RandomCard } from '../../domain/entities/card';

export type CardHooks = {
  useFetchRandomCard: () => {
    fetchRandomCard: (
      exclusionCardIds: (string | number)[],
    ) => Promise<RandomCard>;
  };
};

export const CardHooksContext = createContext<CardHooks | null>(null);

export const useCardHooks = (): CardHooks => {
  const context = useContext(CardHooksContext);
  if (!context) {
    throw new Error(`Context not instantiated`);
  }
  return context;
};
