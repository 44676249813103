import React from 'react';
import { EmailVerifiedTemplate } from '../../templates/EmailVerified';

type Props = {};

const Component = ({}: Props): React.ReactElement => {
  return <EmailVerifiedTemplate />;
};

export default Component;
