import AuthService from '../../ports/AuthService';

export type fbSignInUseCase = (
  email: string,
  name: string,
  userID: string,
  invitationCode: string,
  avatar: string,
) => Promise<{ user: object | null }>;

export const buildFbSignIn = (dependencies: {
  authService: AuthService;
}): fbSignInUseCase => {
  const { authService } = dependencies;

  const fbSignIn: fbSignInUseCase = (
    email,
    name,
    userID,
    invitationCode,
    avatar,
  ) => authService.signInUsingFb(email, name, userID, invitationCode, avatar);

  return fbSignIn;
};
