import React, { SVGProps } from 'react';

type Props = SVGProps<SVGSVGElement>;

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      stroke="black"
      {...props}>
      <path d="M0 10 L20 10" stroke="inherit" />
    </svg>
  );
};

export default Component;
