import BoardRepository from '../../ports/BoardRepository';
import { GenericResponse } from '../../../domain/entities/rest';

export type acceptBoardInvitationUseCase = (
  invitationCode: string,
) => Promise<GenericResponse>;

export const buildAcceptBoardInvitation = (dependencies: {
  boardRepo: BoardRepository;
}): acceptBoardInvitationUseCase => {
  const { boardRepo } = dependencies;

  const acceptBoardInvitation: acceptBoardInvitationUseCase = async invitationCode =>
    boardRepo.acceptBoardInvitation(invitationCode);

  return acceptBoardInvitation;
};
