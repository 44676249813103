import { createContext, useContext } from 'react';
import {
  Page,
  PageUploadMedia,
  VerifiedAdmin,
  UpdatePageSection,
  UpdatePageContent,
} from '../../domain/entities/page';
import { GenericResponse } from '../../domain/entities/rest';

export type PageHooks = {
  useFetchPage: () => {
    fetchPage: (pageName: string) => Promise<Page>;
  };
  useVerifyAdmin: () => {
    verifyAdmin: (token: string) => Promise<VerifiedAdmin>;
  };
  useUploadMedia: () => {
    uploadMedia: (file: File) => Promise<PageUploadMedia>;
  };
  useUpdatePageSection: () => {
    updatePageSection: (
      pageId: number,
      data: UpdatePageSection | UpdatePageContent,
    ) => Promise<GenericResponse>;
  };
};

export const PageHooksContext = createContext<PageHooks | null>(null);

export const usePageHooks = (): PageHooks => {
  const context = useContext(PageHooksContext);
  if (!context) {
    throw new Error(`Context not instantiated`);
  }
  return context;
};
