import React, { useEffect } from 'react';
import { ReactFlowProvider } from 'react-flow-renderer';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import queryKeys from '../../../constants/queryKeys';
import { useBoardHooks } from '../../../hooks/board';
import { useGlobalState } from '../../../hooks/global';
import { DiagramEditor } from '../../templates/DiagramEditor';

const StyledDiagramEditor = styled(DiagramEditor)`
  height: calc(100vh - 50px);
`;

const Container = styled.div`
  position: relative;
`;

export const BOARD_CODE_IF_NEW = 'new';

type Props = {};

const Component = ({}: Props): React.ReactElement => {
  const { boardCode } = useParams();
  const navigate = useNavigate();
  const { useBoardAPI } = useBoardHooks();
  const { fetchBoardByCode, fetchBoardCustomer } = useBoardAPI();
  const {
    useCurrentUser: { currentUser },
  } = useGlobalState();
  const customerId = currentUser?.user?.customer?.id;

  const { data: boardData } = useQuery(
    [queryKeys.FETCH_BOARD_BY_CODE, boardCode],
    () => {
      if (!boardCode || boardCode === BOARD_CODE_IF_NEW) {
        throw new Error('invalid value for bord code');
      }
      return fetchBoardByCode(boardCode);
    },
  );

  useEffect(() => {
    window.history.pushState(null, '', document.URL);
    window.addEventListener('popstate', directToMyBoard);

    return () => {
      window.removeEventListener('popstate', directToMyBoard);
    };
  }, []);

  function directToMyBoard() {
    navigate('/my-board/personal');
  }

  const {
    data: currentBoardCustomer,
    isFetching: isFetchingCurrentBoardCustomer,
  } = useQuery(
    [queryKeys.FETCH_BOARD_CUSTOMER, boardCode, customerId],
    () => {
      if (!boardCode) {
        throw new Error('missing value for field board code');
      }
      if (typeof customerId === 'undefined') {
        throw new Error('invalid value for customer id');
      }
      return fetchBoardCustomer(customerId, boardCode);
    },
    {
      enabled: boardCode !== 'new',
    },
  );

  return (
    <Container>
      <ReactFlowProvider>
        <StyledDiagramEditor
          boardData={boardData}
          boardCode={boardCode}
          currentBoardCustomer={currentBoardCustomer}
          isFetchingCurrentBoardCustomer={isFetchingCurrentBoardCustomer}
        />
      </ReactFlowProvider>
    </Container>
  );
};

export default Component;
