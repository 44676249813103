import { createContext, useContext } from 'react';
import { Socket } from 'socket.io-client';

export type SocketHooks = {
  useSocket: {
    socket: Socket | null;
    socketInit: (boardCode?: string) => void;
    disconnectSocket: () => void;
    initializedBoardCode?: string;
  };
};

export const SocketHooksContext = createContext<SocketHooks | null>(null);

export const useSocketHooks = (): SocketHooks => {
  const context = useContext(SocketHooksContext);
  if (!context) {
    throw new Error(`Context not instantiated`);
  }
  return context;
};
