import { Page } from '../../../domain/entities/page';
import PageRepository from '../../ports/PageRepository';

export type fetchPageUseCase = (pageName: string) => Promise<Page>;

export const buildFetchPage = (dependencies: {
  pageRepo: PageRepository;
}): fetchPageUseCase => {
  const { pageRepo } = dependencies;

  const fetchPage: fetchPageUseCase = async pageName =>
    pageRepo.fetchPage(pageName);

  return fetchPage;
};
