import { PaymentHooks } from '.';
import { useDependencies } from '..';
import { ChoosePlan, PaymentHistoryData } from '../../domain/entities/payment';
import { useCallback } from 'react';

export const useChoosePlan: PaymentHooks['useChoosePlan'] = (): {
  choosePlan: (planId: number, paymentMethodId: string) => Promise<ChoosePlan>;
} => {
  const { paymentInteractor } = useDependencies();

  const choosePlan = useCallback(
    async (planId: number, paymentMethodId: string) => {
      return paymentInteractor.choosePlan(planId, paymentMethodId);
    },
    [],
  );

  return { choosePlan };
};

export const usePaymentHistory: PaymentHooks['usePaymentHistory'] = (): {
  fetchPaymentHistory: (customerId: string) => Promise<PaymentHistoryData[]>;
  fetchInvoiceBlob: (invoiceCode: string) => Promise<void>;
} => {
  const { paymentInteractor } = useDependencies();

  const fetchPaymentHistory = useCallback(async (customerId: string) => {
    return paymentInteractor.fetchPaymentHistory(customerId);
  }, []);

  const fetchInvoiceBlob = useCallback(async (invoiceCode: string) => {
    return paymentInteractor.fetchInvoiceBlob(invoiceCode);
  }, []);

  return { fetchPaymentHistory, fetchInvoiceBlob };
};
