import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import theme from '../../../constants/themes';
import { isWindowsOS } from '../../../utils/booleans';

const StyledNavLink = styled(NavLink)`
  height: 66px;
  padding-left: 66px;
  display: flex;
  align-items: center;
  font-family: ${theme.fonts.primary};
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: ${theme.colors.textColor};
  cursor: pointer;
  background-color: ${theme.colors.myboardMenuBg};
  text-decoration: none;

  &:hover {
    background-color: ${theme.colors.myBoardMenuBgHover};
  }
  &.active {
    color: ${theme.colors.notifToastBorder};
    padding-left: 63px;
    border-left: 3px solid ${theme.colors.notifToastBorder};
  }
`;

const StyledIconNavLink = styled(NavLink)<{
  inactiveicon: string;
  activeicon: string;
}>`
  height: 66px;
  padding-left: 66px;
  display: flex;
  align-items: center;
  font-family: ${theme.fonts.primary};
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: ${theme.colors.textColor};
  cursor: pointer;
  background-color: ${theme.colors.myboardMenuBg};
  text-decoration: none;
  background-image: ${props => `url(${props.inactiveicon})`};
  background-repeat: no-repeat;
  background-position: 22px center;

  &:hover {
    background-color: ${theme.colors.myBoardMenuBgHover};
  }
  &.active {
    color: ${theme.colors.notifToastBorder};
    padding-left: 63px;
    border-left: 3px solid ${theme.colors.notifToastBorder};
    background-image: ${props => `url(${props.activeicon})`};
    background-repeat: no-repeat;
    background-position: 19px center;
  }

  @media ${theme.breakpoints.mobile} {
    height: fit-content;
    width: fit-content;
    flex: 0 0 25%;
  }

  @media ${theme.breakpoints.tablet} {
    height: fit-content;
    width: fit-content;
    flex: 0 0 25%;
  }
`;

const StyledLabel = styled.span`
  padding-top: ${isWindowsOS() ? 6 : 0}px;
`;

export type Props = {
  to: string;
  label: string;
  icon?: string;
  activeIcon?: string;
};

const Component = ({
  to,
  label,
  icon,
  activeIcon,
}: Props): React.ReactElement => {
  return icon && activeIcon ? (
    <StyledIconNavLink to={to} inactiveicon={icon} activeicon={activeIcon}>
      <StyledLabel>{label}</StyledLabel>
    </StyledIconNavLink>
  ) : (
    <StyledNavLink to={to}>{label}</StyledNavLink>
  );
};

export default Component;
