export default class UploadAdapter {
  loader: any;
  uploadUrl: string;
  xhr: XMLHttpRequest;

  constructor(loader: any, url: string) {
    this.loader = loader;
    this.uploadUrl = url;
    this.xhr = new XMLHttpRequest();
  }

  upload = async (): Promise<any> => {
    return this.loader.file.then(
      (file: any) =>
        new Promise((resolve, reject) => {
          this._initRequest();
          this._initListeners(resolve, reject, file);
          this._sendRequest(file);
        }),
    );
  };

  abort = (): void => {
    if (this.xhr) {
      this.xhr.abort();
    }
  };

  _initRequest = (): void => {
    const xhr = this.xhr;

    xhr.open('POST', this.uploadUrl, true);
    xhr.responseType = 'json';
  };

  _initListeners = (
    resolve: { (value: unknown): void; (arg0: { default: any }): void },
    reject: { (reason?: any): void; (arg0: string | undefined): any },
    file: any,
  ): void => {
    const xhr = this.xhr;
    const loader = this.loader;
    const genericErrorText = "Couldn't upload file:" + ` ${file?.name}.`;

    xhr.addEventListener('error', () => reject(genericErrorText));
    xhr.addEventListener('abort', () => reject());
    xhr.addEventListener('load', () => {
      const response = xhr.response;

      if (!response || response.error) {
        return reject(
          response && response.error
            ? response.error.message
            : genericErrorText,
        );
      }

      // If the upload is successful, resolve the upload promise with an object containing
      // at least the "default" URL, pointing to the image on the server.
      resolve({
        default: response.data.url,
      });
    });

    if (xhr.upload) {
      xhr.upload.addEventListener('progress', evt => {
        if (evt.lengthComputable) {
          loader.uploadTotal = evt.total;
          loader.uploaded = evt.loaded;
        }
      });
    }
  };

  // Prepares the data and sends the request.
  _sendRequest = (file: any): void => {
    const data = new FormData();

    data.append('upload_file', file);

    this.xhr.send(data);
  };
}
