import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { Formik, FormikHelpers } from 'formik';
import { useMutation, useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router';
import theme from '../../../constants/themes';
import { ResetPasswordForm } from '../../organisms/ResetPasswordForm';
import { ResetPasswordSchema } from './validation';
import { useQuery as useQueryUtils } from '../../../utils/query';
import { useAuthHooks } from '../../../hooks/auth';
import { Toast } from '../../atoms/Toast';
import { Props as INotification } from '../../atoms/Toast/Component';
import { Flagman } from '../../atoms/Icons';
import { Footer } from '../../molecules/Footer';
import { usePageHooks } from '../../../hooks/page';
import { MobileHeader } from '../../molecules/MobileHeader';
import queryKeys from '../../../constants/queryKeys';

const StyledContainer = styled.div`
  padding: 36px;
`;

const StyledHeader = styled.div`
  cursor: pointer;
  border-bottom: 1px solid #e6e6e6;

  @media ${theme.breakpoints.mobile} {
    display: none;
  }

  @media ${theme.breakpoints.tablet} {
    display: none;
  }
`;

const StyledFlagman = styled(Flagman)`
  margin-bottom: 10px;
  path {
    fill: #18cf77;
    :nth-child(1) {
      fill: black;
    }
    :nth-child(2) {
      fill: black;
    }
    :nth-child(3) {
      fill: black;
    }
  }
`;

const StyledTitle = styled.div`
  font-family: ${theme.fonts.primary};
  font-weight: 700;
  font-size: 34px;
  line-height: 49px;
  letter-spacing: -0.02em;
  color: ${theme.colors.black};
  margin-top: 20px;
  width: fit-content;
`;

const Divider = styled.div`
  width: 100%;
  height: 11px;
  background-color: ${theme.colors.roleSelectTitleUnderline};
`;

const StyledFormWrapper = styled.div`
  height: calc(100vh - 499px);
  min-height: 400px;
  align-items: center;
  justify-content: center;
  display: flex;

  @media ${theme.breakpoints.mobile} {
    padding: 50px 8px;
  }

  @media ${theme.breakpoints.tablet} {
    padding: 50px 8px;
  }
`;

const Component = (): React.ReactElement => {
  const [apiError, setApiError] = useState('');
  const [notification, setNotification] = useState<INotification | null>(null);
  const { useUpdatePassword } = useAuthHooks();
  const { resetPassword } = useUpdatePassword();
  const navigate = useNavigate();
  const token = useQueryUtils('token') || '';
  const { useFetchPage } = usePageHooks();
  const { fetchPage } = useFetchPage();

  const {
    mutate: resetPasswordMutation,
    isLoading,
  } = useMutation(
    ({
      token,
      password,
      passwordConfirmation,
    }: {
      token: string;
      password: string;
      passwordConfirmation: string;
    }) => resetPassword(token, password, passwordConfirmation),
  );

  const onSubmit = useCallback(
    (
      values: { password: string; repassword: string },
      { resetForm }: FormikHelpers<{ password: string; repassword: string }>,
    ) => {
      setApiError('');
      const params: {
        token: string;
        password: string;
        passwordConfirmation: string;
      } = {
        token,
        password: values.password,
        passwordConfirmation: values.repassword,
      };
      resetPasswordMutation(params, {
        onSuccess: res => {
          resetForm();
          setNotification({
            isOpen: true,
            message: res?.message || '',
            type: 'success',
            position: 'top-right',
            onClose: handleCloseToast,
          });
          setTimeout(() => {
            navigate('/signin');
          }, 2000);
        },
        onError: error => {
          const err = error as AxiosError;
          setApiError(err.message);
        },
      });
    },
    [token],
  );

  const { data: getPageData, isFetching: isFetchingPageData } = useQuery(
    [queryKeys.FETCH_PAGE],
    () => {
      return fetchPage('TOP_PAGE');
    },
  );

  const handleCloseToast = () => {
    setNotification(null);
  };

  return (
    <>
      <MobileHeader
        links={getPageData?.sections[0]?.header?.content}
        isFetching={isFetchingPageData}
      />
      <StyledContainer>
        <StyledHeader>
          <a onClick={() => navigate('/top')}>
            <StyledFlagman />
          </a>
        </StyledHeader>
        <StyledTitle>
          パスワードリセット
          <Divider />
        </StyledTitle>
        <StyledFormWrapper>
          <Formik
            initialValues={{ password: '', repassword: '' }}
            validationSchema={ResetPasswordSchema}
            onSubmit={onSubmit}>
            {({
              handleChange,
              handleSubmit,
              handleBlur,
              touched,
              values,
              errors,
            }): React.ReactElement => {
              return (
                <ResetPasswordForm
                  isLoading={isLoading}
                  password={values.password}
                  repassword={values.repassword}
                  onChangePassword={handleChange('password')}
                  onChangeRepassword={handleChange('repassword')}
                  onPressReset={handleSubmit}
                  errors={{
                    password: (touched.password && errors.password) || '',
                    repassword: (touched.repassword && errors.repassword) || '',
                    apiError,
                  }}
                  onBlurPassword={handleBlur('password')}
                  onBlurRepassword={handleBlur('repassword')}
                />
              );
            }}
          </Formik>
        </StyledFormWrapper>
        {notification ? <Toast {...notification} /> : null}
      </StyledContainer>
      <Footer
        footerContents={[
          getPageData?.sections[6]?.footer?.content?.plan,
          getPageData?.sections[6]?.footer?.content?.frequently_used_links,
          getPageData?.sections[6]?.footer?.content?.company_overview,
        ]}
        addressContents={{
          address: getPageData?.sections[6]?.footer?.content?.company_address,
          telephoneNumber: getPageData?.sections[6]?.footer?.content?.tel,
          faxNumber: getPageData?.sections[6]?.footer?.content?.fax,
        }}
        faqsContents={{
          privacyPolicy:
            getPageData?.sections[6]?.footer?.content?.privacy_policy,
          termsOfService:
            getPageData?.sections[6]?.footer?.content?.terms_of_service,
          transactionLaw:
            getPageData?.sections[6]?.footer?.content?.transaction_law,
        }}
        isFetching={isFetchingPageData}
      />
    </>
  );
};

export default Component;
