import { RandomCard } from '../../../domain/entities/card';
import CardRepository from '../../ports/CardRepository';

export type fetchRandomCardUseCase = (
  exclusionCardIds: (string | number)[],
) => Promise<RandomCard>;

export const buildFetchRandomCard = (dependencies: {
  cardRepo: CardRepository;
}): fetchRandomCardUseCase => {
  const { cardRepo } = dependencies;

  const fetchRandomCard: fetchRandomCardUseCase = async exclusionCardIds =>
    cardRepo.find(exclusionCardIds);

  return fetchRandomCard;
};
