import React, { SVGProps } from 'react';

type Props = SVGProps<SVGSVGElement>;

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 18"
      {...props}>
      <g id="layer1">
        <path
          id="path3078"
          fill="#186cae"
          d="M0-.81H20V9.79l-1,1.52,1,1.35v6.16H0v-10l.62-.69L0,7.47Z"
        />
        <path
          id="path3082"
          fill="#ffffff"
          d="M3.87,12.87V9.79H7.19l.36.46.36-.46H20v2.87a1.36,1.36,0,0,1-.68.21H12.61l-.41-.48v.48H10.89V12a1.09,1.09,0,0,1-.57.12H9.87v.71h-2l-.35-.46-.36.46Z"
        />
        <path
          id="path3080"
          fill="#ffffff"
          d="M0,7.47.75,5.75H2l.43,1v-1h1.6l.26.7.24-.7h7.22V6.1a1.57,1.57,0,0,1,1-.35h2.34l.42,1v-1H16.9l.37.55V5.75h1.35V8.83H17.27l-.36-.54v.54h-2l-.19-.48H14.2L14,8.83H12.67a1.33,1.33,0,0,1-.88-.34v.34h-2l-.4-.48v.48H1.85l-.2-.48H1.12l-.2.48H0Z"
        />
        <path
          id="path3046"
          fill="#186cae"
          d="M1,6.13,0,8.44H.66L.85,8H1.93l.18.46h.68l-1-2.31Zm.38.54.33.81H1.05Z"
        />
        <path
          id="path3048"
          fill="#186cae"
          d="M2.85,8.44V6.13h.94l.54,1.48.53-1.49h.93V8.44H5.2V6.74l-.62,1.7H4.06l-.62-1.7v1.7Z"
        />
        <path
          id="path3050"
          fill="#186cae"
          d="M6.19,8.44V6.13H8.1v.52H6.78V7H8.07v.49H6.78v.41H8.1v.5Z"
        />
        <path
          id="path3052"
          fill="#186cae"
          d="M8.44,6.13V8.44H9V7.62h.24l.71.82h.71l-.77-.85a.7.7,0,0,0,.64-.71.75.75,0,0,0-.82-.75ZM9,6.65H9.7a.24.24,0,1,1,0,.48H9V6.65Z"
        />
        <path id="path3054" fill="#186cae" d="M11.4,8.44h-.6V6.13h.6Z" />
        <path
          id="path3056"
          fill="#186cae"
          d="M12.82,8.44h-.13a1,1,0,0,1-1-1.14,1.06,1.06,0,0,1,1.16-1.17h.65v.55h-.67a.56.56,0,0,0-.55.62.57.57,0,0,0,.63.64h.16Z"
        />
        <path
          id="path3058"
          fill="#186cae"
          d="M14.1,6.13l-1,2.31h.66L13.93,8H15l.19.46h.67l-1-2.31Zm.37.54.33.81h-.66l.33-.81Z"
        />
        <path
          id="path3060"
          fill="#186cae"
          d="M15.94,8.44V6.13h.74l1,1.45V6.13h.58V8.44H17.5L16.52,7V8.44Z"
        />
        <path
          id="path3062"
          fill="#186cae"
          d="M4.27,12.48V10.17H6.19v.52H4.87v.39H6.16v.49H4.87V12H6.19v.5Z"
        />
        <path
          id="path3064"
          fill="#186cae"
          d="M13.65,12.48V10.17h1.91v.52H14.24v.39h1.29v.49H14.24V12h1.32v.5Z"
        />
        <path
          id="path3066"
          fill="#186cae"
          d="M6.26,12.48l.93-1.14-1-1.17H7l.57.72.57-.72h.71l-1,1.15.94,1.16H8.08l-.55-.71L7,12.48Z"
        />
        <path
          id="path3068"
          fill="#186cae"
          d="M8.89,10.17v2.31h.6v-.73h.62c.52,0,.91-.27.91-.8a.77.77,0,0,0-.84-.78Zm.6.52h.65a.26.26,0,0,1,.29.27.27.27,0,0,1-.29.26H9.49v-.53Z"
        />
        <path
          id="path3072"
          fill="#186cae"
          d="M11.28,10.17v2.31h.59v-.82h.24l.71.82h.71l-.77-.85a.71.71,0,0,0,.64-.71.75.75,0,0,0-.82-.75Zm.59.52h.67a.26.26,0,0,1,.27.24.26.26,0,0,1-.27.24h-.67Z"
        />
        <path
          id="path3074"
          fill="#186cae"
          d="M15.83,12.48V12H17c.17,0,.25-.1.25-.2s-.08-.19-.25-.19h-.53a.65.65,0,0,1-.72-.69c0-.37.23-.73.92-.73h1.14l-.25.52h-1c-.19,0-.25.1-.25.19a.2.2,0,0,0,.22.2h.55c.52,0,.74.29.74.66a.7.7,0,0,1-.77.74Z"
        />
        <path
          id="path3076"
          fill="#186cae"
          d="M18,12.48V12h1.17c.17,0,.25-.1.25-.2s-.08-.19-.25-.19h-.53a.65.65,0,0,1-.72-.69c0-.37.24-.73.92-.73H20l-.24.52h-1c-.19,0-.25.1-.25.19a.2.2,0,0,0,.22.2h.55c.52,0,.74.29.74.66a.7.7,0,0,1-.76.74Z"
        />
      </g>
    </svg>
  );
};

export default Component;
