import React from 'react';

type Props = {
  [key: string]: string | number | Function;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="26"
      height="30"
      viewBox="0 0 26 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M23.1111 17.3324V12.0588C23.1111 7.32795 19.955 3.34266 15.6794 2.14413C15.2562 1.05883 14.222 0.294128 13 0.294128C11.778 0.294128 10.7438 1.05883 10.3206 2.14413C6.045 3.34413 2.88889 7.32795 2.88889 12.0588V17.3324L0.423224 19.8427C0.288829 19.979 0.182247 20.141 0.109612 20.3195C0.036978 20.4979 -0.000273649 20.6892 1.51331e-06 20.8824V23.8235C1.51331e-06 24.2136 0.152184 24.5876 0.423069 24.8634C0.693955 25.1392 1.06136 25.2941 1.44445 25.2941H24.5556C24.9387 25.2941 25.3061 25.1392 25.5769 24.8634C25.8478 24.5876 26 24.2136 26 23.8235V20.8824C26.0003 20.6892 25.963 20.4979 25.8904 20.3195C25.8178 20.141 25.7112 19.979 25.5768 19.8427L23.1111 17.3324ZM23.1111 22.353H2.88889V21.4912L5.35456 18.9809C5.48895 18.8446 5.59553 18.6825 5.66817 18.5041C5.7408 18.3256 5.77805 18.1343 5.77778 17.9412V12.0588C5.77778 8.00442 9.01767 4.70589 13 4.70589C16.9823 4.70589 20.2222 8.00442 20.2222 12.0588V17.9412C20.2222 18.3324 20.3739 18.7059 20.6454 18.9809L23.1111 21.4912V22.353ZM13 29.7059C13.8945 29.707 14.7672 29.4244 15.4967 28.8973C16.2262 28.3702 16.7763 27.6248 17.0704 26.7647H8.92956C9.22374 27.6248 9.7738 28.3702 10.5033 28.8973C11.2328 29.4244 12.1055 29.707 13 29.7059Z"
        fill="#60BF91"
      />
    </svg>
  );
};

export default Component;
