import { Radio } from '@mui/material';
import React, { ChangeEvent } from 'react';
import styled from 'styled-components';

const BpIcon = styled.span`
  border-radius: 50%;
  width: 16px;
  height: 16px;
  box-shadow: 0px 0px 0px 4px rgba(49, 132, 253, 0.5);
  background-color: #f5f8fa;
  border: 1px solid #86b7fe;
  background-image: linear-gradient(
    180deg,
    hsla(0, 0%, 100%, 0.8),
    hsla(0, 0%, 100%, 0)
  );

  .Mui-focusVisible & {
    outline: 2px auto rgba(19, 124, 189, 0.6);
    outline-offset: 2;
  }

  input:hover ~ & {
    background-color: #ebf1f5;
  }

  input:disabled ~ & {
    box-shadow: none;
    background: rgba(206, 217, 224, 0.5);
  }
`;

const BpCheckedIcon = styled(BpIcon)`
  background-color: #0d6efd;
  background-image: linear-gradient(
    180deg,
    hsla(0, 0%, 100%, 0.1),
    hsla(0, 0%, 100%, 0)
  );
  ::before {
    display: block;
    width: 16px;
    height: 16px;
    background-image: radial-gradient(#fff, #fff 28%, transparent 32%);
    content: '';
  }
  input:hover ~ & {
    background-color: #106ba3;
  }
`;

export type Props = {
  className?: string;
  checked?: boolean;
  name?: string;
  value?: unknown;
  onChange?: (event: ChangeEvent, checked: boolean) => void;
};

const Component = ({
  className,
  checked,
  name,
  onChange,
  value,
}: Props): React.ReactElement => {
  return (
    <Radio
      checked={checked}
      className={className}
      name={name}
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      onChange={onChange}
      value={value}
    />
  );
};

export default Component;
