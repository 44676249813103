import React from 'react';

type Props = {
  [key: string]: string | number | Function;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M12.5001 0.0253906C5.61114 0.0253906 0.0253906 5.61114 0.0253906 12.5001C0.0253906 19.389 5.61114 24.9747 12.5001 24.9747C19.389 24.9747 24.9747 19.389 24.9747 12.5001C24.9747 5.61114 19.389 0.0253906 12.5001 0.0253906ZM12.5001 22.8585C6.78064 22.8585 2.14163 18.2195 2.14163 12.5001C2.14163 10.0218 3.01318 7.74409 4.4667 5.96199L19.0381 20.5334C17.256 21.9869 14.9783 22.8585 12.5001 22.8585ZM20.5334 19.0381L5.96199 4.4667C7.74409 3.01318 10.0218 2.14163 12.5001 2.14163C18.2195 2.14163 22.8585 6.78064 22.8585 12.5001C22.8585 14.9783 21.9869 17.256 20.5334 19.0381Z"
        fill="#E0232E"
      />
    </svg>
  );
};

export default Component;
