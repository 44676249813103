import { loadStripe, Stripe } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router';
import styled from 'styled-components';
import theme from '../../../constants/themes';
import { ChevronLeft } from '../../atoms/Icons';
import { PaymentCardForm } from '../../organisms/PaymentCardForm';
import { Elements } from '@stripe/react-stripe-js';
import { Plan } from '../../../domain/entities/plan';
import './custom.css';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
`;

const InformationWrapper = styled.div<{
  bgColor?: string;
}>`
  display: flex;
  flex: 1;
  background: ${({ bgColor }) => bgColor || theme.colors.lightBtn};
  padding: 2em 4em;
  flex-direction: column;
`;

const FormWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const SettlementContainer = styled.div`
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 49px;
  letter-spacing: -0.02em;
  color: ${theme.colors.white};
  display: flex;
  flex-direction: column;
  margin-top: 1.5em;
  width: fit-content;

  span {
    background: ${theme.colors.white};
    height: 11px;
    width: 100%;
  }
`;

const AccountContainer = styled.div`
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  letter-spacing: -0.02em;
  color: ${theme.colors.white};
  margin-top: 2em;
`;

const MonthlyPricingContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1em;

  span {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 29px;
    display: flex;
    align-items: flex-end;
    letter-spacing: -0.02em;
    color: ${theme.colors.white};
  }
`;

const Pricing = styled.div`
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 49px;
  display: flex;
  align-items: flex-end;
  letter-spacing: -0.02em;
  color: ${theme.colors.white};
`;

const PricingContainer = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

const BottomContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-end;
  flex-direction: column;
  gap: 3em;
`;
const ChevronLeftContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex: 1;

  svg {
    cursor: pointer;
  }
`;

const BottomInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 5em;

  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  /* identical to box height */

  text-align: center;
  letter-spacing: -0.02em;
  color: ${theme.colors.white};

  a {
    :first-child {
      font-weight: 400;
      font-size: 20px;
      line-height: 29px;
      letter-spacing: -0.02em;
    }

    color: ${theme.colors.white};
    text-decoration: none;
  }
`;

export type Props = {};

const Component = ({}: Props): React.ReactElement => {
  const location = useLocation();
  const navigate = useNavigate();
  const [stripeKey, setStripeKey] = useState<string>();
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null>>();
  const [stripeIsLoading, setStripeIsLoading] = useState<boolean>(true);
  const [planData, setPlanData] = useState<Plan>({
    id: 0,
    type: '',
    description: '1カ月につき',
    price: 500,
    pricing: '',
    content: [],
    buttonName: '決済',
    customerLimit: 1,
    channelLimit: 1,
    themeColor: theme.colors.lightBtn,
  });

  useEffect(() => {
    if (location.state) {
      setPlanData((location.state as { [key: string]: any })?.selectedPlan);
    }
  }, [location.state]);

  useEffect(() => {
    if (!process.env.REACT_APP_STRIPE_API_KEY) {
      throw new Error('Stripe not configured');
    }

    setStripeKey(process.env.REACT_APP_STRIPE_API_KEY);
  }, []);

  useEffect(() => {
    if (stripeKey && !stripePromise) {
      setStripePromise(loadStripe(stripeKey));
      setStripeIsLoading(false);
    }
  }, [stripeKey, stripePromise]);

  return (
    <Container>
      <InformationWrapper bgColor={planData?.themeColor}>
        {/* <StyledLogo /> */}
        <SettlementContainer>
          決済
          <span></span>
        </SettlementContainer>
        <PricingContainer>
          <AccountContainer>
            IDEAMAN {planData?.type}プランに申し込む
          </AccountContainer>
          <MonthlyPricingContainer>
            <Pricing>￥{planData?.price}</Pricing>
            <span>{planData?.description}</span>
          </MonthlyPricingContainer>
        </PricingContainer>
        <BottomContainer>
          <ChevronLeftContainer>
            <ChevronLeft onClick={() => navigate('/plan-select')} />
          </ChevronLeftContainer>
          <BottomInfo>
            <a></a>
            <a href="/privacy">プライバシーポリシー</a>
            <a href="/terms">利用規約</a>
            <a href="/commercial-rule">特定商取引法</a>
          </BottomInfo>
        </BottomContainer>
      </InformationWrapper>
      <FormWrapper>
        {stripeIsLoading ? (
          <></>
        ) : (
          <Elements stripe={stripePromise as Promise<Stripe | null>}>
            <PaymentCardForm
              themeColor={planData?.themeColor}
              planId={planData?.id}
              className="w-60"
            />
          </Elements>
        )}
      </FormWrapper>
    </Container>
  );
};

export default Component;
