import BoardRepository from '../../ports/BoardRepository';
import { GenericResponse } from '../../../domain/entities/rest';

export type voteCustomerUseCase = (
  boardCode: string,
  customerId: number,
) => Promise<GenericResponse>;

export const buildVoteCustomer = (dependencies: {
  boardRepo: BoardRepository;
}): voteCustomerUseCase => {
  const { boardRepo } = dependencies;

  const voteCustomer: voteCustomerUseCase = async (boardCode, customerId) =>
    boardRepo.voteCustomer(boardCode, customerId);

  return voteCustomer;
};
