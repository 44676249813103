import BoardRepository from '../../ports/BoardRepository';

export type createBoardUseCase = (payload: {
  name: string;
  teamId?: number;
  diagram: string;
}) => Promise<string>;

export const buildCreateBoard = (dependencies: {
  boardRepo: BoardRepository;
}): createBoardUseCase => {
  const { boardRepo } = dependencies;

  const createBoard: createBoardUseCase = async payload =>
    boardRepo.createBoard(payload);

  return createBoard;
};
