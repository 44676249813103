import React from 'react';
import { VerifyEmailTemplate } from '../../templates/VerifyEmail';

type Props = {};

const Component = ({}: Props): React.ReactElement => {
  return <VerifyEmailTemplate />;
};

export default Component;
