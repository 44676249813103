import AuthService from '../../ports/AuthService';

export type microsoftSignInUseCase = (
  email: string,
  name: string,
  accountId: string,
  invitationCode: string,
  avatar: string,
) => Promise<{ user: object | null }>;

export const buildMicrosoftSignIn = (dependencies: {
  authService: AuthService;
}): microsoftSignInUseCase => {
  const { authService } = dependencies;

  const microsoftSignIn: microsoftSignInUseCase = (
    email,
    name,
    accountId,
    invitationCode,
    avatar: string,
  ) =>
    authService.signInUsingMicrosoft(
      email,
      name,
      accountId,
      invitationCode,
      avatar,
    );

  return microsoftSignIn;
};
