import React from 'react';
import styled from 'styled-components';
import theme from '../../../constants/themes';
import Skeleton from '@mui/material/Skeleton';
import { useTranslation } from 'react-i18next';
import { RoleItem } from '../../molecules/RoleItem';
import { RoleSelectItem } from '../../../domain/entities/role';

const Wrapper = styled.div`
  width: 90%;
  max-width: 1050px;
  max-height: 75%;
  padding: 50px 100px;
  background-color: ${theme.colors.white};
  overflow-y: auto;
`;

const Title = styled.span`
  font-family: ${theme.fonts.primary};
  font-weight: 700;
  font-size: 34px;
  line-height: 40px;
  color: ${theme.colors.black};
  border-bottom: 11px solid ${theme.colors.roleSelectTitleUnderline};
  padding-bottom: 5px;
`;

const RoleItemsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-height: 810px;
  width: fit-content;
  margin-top: 25px;
`;

const StyledRoleItem = styled(RoleItem)`
  flex: 0 0 0;
  margin-bottom: 25px;
`;

export type Props = {
  roles: RoleSelectItem[];
  isLoading?: boolean;
  isUpdatingRole?: boolean;
  onPressRole: (id: number) => void;
};

const Component = ({
  roles,
  isLoading,
  isUpdatingRole,
  onPressRole,
}: Props): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Title>{t('roleSelectTitle')}</Title>
      {isLoading ? (
        <Skeleton width={1000} height={530} />
      ) : (
        <RoleItemsWrapper>
          {roles.map((role, index) => {
            return (
              <StyledRoleItem
                key={role.id}
                roleId={role.id}
                roleImg={role.image}
                roleTitle={role.title}
                roleDesc={role.content}
                theme={role.primary_color}
                isLast={roles.length === index + 1}
                isDisabled={isUpdatingRole}
                onPressSelectRole={onPressRole}
              />
            );
          })}
        </RoleItemsWrapper>
      )}
    </Wrapper>
  );
};

export default Component;
