import React, { useState } from 'react';
import styled from 'styled-components';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import theme from '../../../constants/themes';
import { MenuArrow } from '../../atoms/Icons';

const StyledMenuArrow = styled(MenuArrow)`
  width: 15px;
  height: 15px;
`;

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.colors.white,
    color: theme.colors.baseColor,
    border: `1px solid ${theme.colors.strokeLight}`,
    borderRadius: 4,
    top: 6,
    padding: '4px 0',
    left: '20px',
  },
  [`& .${tooltipClasses.arrow}::before`]: {
    border: `1px solid ${theme.colors.strokeLight}`,
  },
  [`&.mainMenu span.${tooltipClasses.arrow}`]: {
    left: '-27px !important', // this is to overide inline css on the dropdown
  },
  [`&.mainMenu div.MuiTooltip-tooltipPlacementTop`]: {
    marginTop: 20,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.colors.white,
  },
  [`&&&.secondMainMenu .${tooltipClasses.tooltipPlacementRight}`]: {
    marginLeft: -10,
    marginTop: -10,
  },
}));

const StyledMenuItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 167px;
  transition: background-color 0.2s ease;
  padding: 5px 11px;
  margin: 0;
  :hover {
    background-color: ${theme.colors.dropdownOptionHover};
  }
`;

const Label = styled.div`
  flex: 1;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
`;

export type Props = {
  children: React.ReactElement;
  menu: {
    label: string;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
    subMenu?: {
      label: string;
      onClick?: React.MouseEventHandler<HTMLDivElement>;
    }[];
  }[];
  className?: string;
};

const Component = ({
  children,
  menu,
  className,
}: Props): React.ReactElement => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <ClickAwayListener
      onClickAway={() => {
        setIsOpen(false);
      }}>
      <span className="tooltip-wrapper">
        <StyledTooltip
          open={isOpen}
          className={className + ' mainMenu'}
          placement="top-start"
          title={
            <>
              {menu.map((menuItem, menuItemIndex) =>
                menuItem.subMenu && menuItem.subMenu.length > 0 ? (
                  <StyledTooltip
                    placement="right-start"
                    key={`menu-item-${menuItemIndex}`}
                    className={'secondMainMenu'}
                    title={
                      <>
                        {menuItem.subMenu.map(
                          (subMenuItem, subMenuItemIndex) => (
                            <StyledMenuItem
                              onClick={ev => {
                                if (
                                  typeof subMenuItem.onClick !== 'undefined'
                                ) {
                                  setIsOpen(!isOpen);
                                  subMenuItem.onClick(ev);
                                }
                              }}
                              key={`menu-item-${menuItemIndex}-${subMenuItemIndex}`}>
                              <Label>{subMenuItem.label}</Label>
                            </StyledMenuItem>
                          ),
                        )}
                      </>
                    }
                    arrow>
                    <StyledMenuItem>
                      <Label>{menuItem.label}</Label>
                      <StyledMenuArrow />
                    </StyledMenuItem>
                  </StyledTooltip>
                ) : (
                  <StyledMenuItem
                    key={`menu-item-${menuItemIndex}`}
                    onClick={ev => {
                      if (menuItem.onClick) {
                        setIsOpen(!isOpen);
                        menuItem.onClick(ev);
                      }
                    }}>
                    <Label>{menuItem.label}</Label>
                  </StyledMenuItem>
                ),
              )}
            </>
          }
          arrow>
          <span
            onClick={() => {
              setIsOpen(!isOpen);
            }}>
            {children}
          </span>
        </StyledTooltip>
      </span>
    </ClickAwayListener>
  );
};

export default Component;
