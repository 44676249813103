import React, { createElement, useState } from 'react';
import styled from 'styled-components';
import theme from '../../../constants/themes';
import { FaqContent } from '../TopBody/types';
import createDOMPurify from 'dompurify';
import { renderToStaticMarkup } from 'react-dom/server';
import { FaqDownArrow, FaqUpArrow } from '../../atoms/Icons';

const reactSvgComponentToMarkupString = (Component: any, props: any) =>
  `data:image/svg+xml,${encodeURIComponent(
    renderToStaticMarkup(createElement(Component, props)),
  )}`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const FaqTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  letter-spacing: -0.02em;
  padding: 0.5em 0.2em;
`;

const FaqAccordionContainer = styled.div`
  display: flex;
  flex: 1;

  padding: 0 0.2em;
`;

const AccordionContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const AccordionButton = styled.button<{ isActive?: boolean }>`
  text-align: left;
  border-radius: 5px;
  background: ${theme.colors.dropdownBg};
  border: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  border: 1px solid ${theme.colors.dropdownBorder};
  letter-spacing: -0.02em;
  color: ${theme.colors.infoToastText};
  display: flex;
  align-items: center;
  gap: 1em;

  ::after {
    content: ${({ isActive }) =>
      isActive
        ? `url(${reactSvgComponentToMarkupString(FaqUpArrow, {})})`
        : `url(${reactSvgComponentToMarkupString(FaqDownArrow, {})})`};
    float: right;
    margin-right: 20px;
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }
`;

const AccordionTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  padding: 1em;
`;

const Panel = styled.div`
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  background-color: ${theme.colors.white};
  border: 1px solid ${theme.colors.dropdownBorder};
  border-top: none;
  border-bottom: none;
  border-radius: 5px;
  width: -webkit-fill-available;

  &:last-of-type {
    border-bottom: 1px solid ${theme.colors.dropdownBorder};
  }
`;

const PanelContent = styled.div`
  padding: 2em;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: ${theme.colors.textColor};
`;

const DOMPurify = createDOMPurify(window as any);

type ActiveAccordionType = {
  index: number;
  active: boolean;
};

export type Props = {
  faqContent: FaqContent;
  isFetching?: boolean;
};

const Component = ({ isFetching, faqContent }: Props): React.ReactElement => {
  const [activeAccordion, setActiveAccordion] = useState<ActiveAccordionType[]>(
    [],
  );

  const toggleAccordion = (index: number, active: boolean) => {
    const newActiveAccordion = [...activeAccordion];
    newActiveAccordion[index] = { index, active };
    setActiveAccordion(newActiveAccordion);
  };

  return (
    <Container>
      {isFetching ? null : (
        <>
          <FaqTitle>{faqContent?.title}</FaqTitle>
          <FaqAccordionContainer>
            <AccordionContainer>
              {faqContent.item.map((item, index) => (
                <>
                  <AccordionButton
                    isActive={activeAccordion[index]?.active}
                    key={index}
                    onClick={() =>
                      toggleAccordion(index, !activeAccordion[index]?.active)
                    }>
                    <AccordionTitle>{item.title}</AccordionTitle>
                  </AccordionButton>
                  {activeAccordion[index]?.active &&
                    index === activeAccordion[index]?.index && (
                      <Panel>
                        <PanelContent
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(item.description),
                          }}
                        />
                      </Panel>
                    )}
                </>
              ))}
            </AccordionContainer>
          </FaqAccordionContainer>
        </>
      )}
    </Container>
  );
};

export default Component;
