import React from 'react';

type Props = {
  [key: string]: string | number | Function;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M1.42857 -3.74669e-07L5 3.57143L8.57143 -6.24448e-08L10 0.714286L5 5.71429L-3.12224e-08 0.714285L1.42857 -3.74669e-07Z"
        fill="#252B42"
      />
    </svg>
  );
};

export default Component;
