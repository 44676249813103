import React, { SVGProps } from 'react';

type Props = SVGProps<SVGSVGElement>;

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M15.4167 1.66675C15.3062 1.66675 15.2002 1.71065 15.1221 1.78879C15.0439 1.86693 15 1.97291 15 2.08341V4.16675H12.9167C12.8062 4.16675 12.7002 4.21065 12.6221 4.28879C12.5439 4.36693 12.5 4.47291 12.5 4.58341C12.5 4.69392 12.5439 4.7999 12.6221 4.87804C12.7002 4.95618 12.8062 5.00008 12.9167 5.00008H15V7.08341C15 7.19392 15.0439 7.2999 15.1221 7.37804C15.2002 7.45618 15.3062 7.50008 15.4167 7.50008C15.5272 7.50008 15.6332 7.45618 15.7113 7.37804C15.7895 7.2999 15.8334 7.19392 15.8334 7.08341V5.00008H17.9167C18.0272 5.00008 18.1332 4.95618 18.2113 4.87804C18.2895 4.7999 18.3334 4.69392 18.3334 4.58341C18.3334 4.47291 18.2895 4.36693 18.2113 4.28879C18.1332 4.21065 18.0272 4.16675 17.9167 4.16675H15.8334V2.08341C15.8334 1.97291 15.7895 1.86693 15.7113 1.78879C15.6332 1.71065 15.5272 1.66675 15.4167 1.66675ZM8.33335 8.75008C8.33335 9.0816 8.20166 9.39954 7.96724 9.63396C7.73282 9.86838 7.41487 10.0001 7.08335 10.0001C6.75183 10.0001 6.43389 9.86838 6.19947 9.63396C5.96505 9.39954 5.83335 9.0816 5.83335 8.75008C5.83335 8.41856 5.96505 8.10062 6.19947 7.8662C6.43389 7.63178 6.75183 7.50008 7.08335 7.50008C7.41487 7.50008 7.73282 7.63178 7.96724 7.8662C8.20166 8.10062 8.33335 8.41856 8.33335 8.75008ZM12.5 10.8334C12.96 10.8334 13.3417 11.2101 13.2509 11.6617C13.0587 12.6032 12.5473 13.4493 11.8031 14.057C11.0588 14.6647 10.1275 14.9967 9.16669 14.9967C8.20586 14.9967 7.27454 14.6647 6.5303 14.057C5.78607 13.4493 5.27463 12.6032 5.08252 11.6617C4.99085 11.2101 5.37335 10.8334 5.83335 10.8334H12.5ZM11.25 10.0001C11.5815 10.0001 11.8995 9.86838 12.1339 9.63396C12.3683 9.39954 12.5 9.0816 12.5 8.75008C12.5 8.41856 12.3683 8.10062 12.1339 7.8662C11.8995 7.63178 11.5815 7.50008 11.25 7.50008C10.9185 7.50008 10.6006 7.63178 10.3661 7.8662C10.1317 8.10062 10 8.41856 10 8.75008C10 9.0816 10.1317 9.39954 10.3661 9.63396C10.6006 9.86838 10.9185 10.0001 11.25 10.0001Z"
        fill="#3D4752"
      />
      <path
        d="M9.16667 16.6667C10.7138 16.6667 12.1975 16.0522 13.2915 14.9582C14.3854 13.8642 15 12.3805 15 10.8334V9.58342C15 9.47291 15.0439 9.36693 15.122 9.28879C15.2002 9.21065 15.3062 9.16675 15.4167 9.16675C15.5272 9.16675 15.6332 9.21065 15.7113 9.28879C15.7894 9.36693 15.8333 9.47291 15.8333 9.58342V10.8334C15.8333 12.152 15.4423 13.4409 14.7098 14.5372C13.9773 15.6335 12.9361 16.488 11.7179 16.9926C10.4997 17.4972 9.15927 17.6292 7.86607 17.372C6.57286 17.1148 5.38497 16.4798 4.45262 15.5475C3.52027 14.6151 2.88534 13.4272 2.6281 12.134C2.37087 10.8408 2.50289 9.50037 3.00747 8.28219C3.51206 7.06402 4.36654 6.02283 5.46287 5.29028C6.5592 4.55774 7.84813 4.16675 9.16667 4.16675H10.4167C10.5272 4.16675 10.6332 4.21065 10.7113 4.28879C10.7894 4.36693 10.8333 4.47291 10.8333 4.58341C10.8333 4.69392 10.7894 4.7999 10.7113 4.87804C10.6332 4.95618 10.5272 5.00008 10.4167 5.00008H9.16667C7.61957 5.00008 6.13584 5.61466 5.04188 6.70863C3.94792 7.80259 3.33334 9.28632 3.33334 10.8334C3.33334 12.3805 3.94792 13.8642 5.04188 14.9582C6.13584 16.0522 7.61957 16.6667 9.16667 16.6667Z"
        fill="#3D4752"
      />
    </svg>
  );
};

export default Component;
