import { useCallback } from 'react';
import { RoleSelectItem } from '../../domain/entities/role';
import { useDependencies } from '..';
import { RoleHooks } from '.';

export const useRole: RoleHooks['useRole'] = (): {
  fetchRoles: () => Promise<RoleSelectItem[]>;
} => {
  const { roleInteractor } = useDependencies();
  const { fetchRoles: getRoles } = roleInteractor;

  const fetchRoles = useCallback(async () => {
    const response = await getRoles();

    return response;
  }, []);

  return { fetchRoles };
};
