import TeamRepository from '../../ports/TeamRepository';
import { DeleteTeamMemberSuccess } from '../../../domain/entities/team';

export type deleteTeamMemberUseCase = (
  teamId: string,
  customerId: string,
) => Promise<DeleteTeamMemberSuccess>;

export const buildDeleteTeamMember = (dependencies: {
  teamRepo: TeamRepository;
}): deleteTeamMemberUseCase => {
  const { teamRepo } = dependencies;

  const inviteTeamMember: deleteTeamMemberUseCase = (teamId, customerId) =>
    teamRepo.deleteTeamMember(teamId, customerId);

  return inviteTeamMember;
};
