import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { Formik, FormikHelpers } from 'formik';
import { useMutation, useQuery } from 'react-query';
import { AxiosError } from 'axios';
import theme from '../../../constants/themes';
import { Flagman } from '../../atoms/Icons';
import { Toast } from '../../atoms/Toast';
import { Props as INotification } from '../../atoms/Toast/Component';
import { MobileHeader } from '../../molecules/MobileHeader';
import { ForgotPaswordForm } from '../../organisms/ForgotPaswordForm';
import { ForgotPasswordSchema } from './validation';
import { useAuthHooks } from '../../../hooks/auth';
import { usePageHooks } from '../../../hooks/page';
import { Footer } from '../../molecules/Footer';
import { useNavigate } from 'react-router-dom';
import queryKeys from '../../../constants/queryKeys';

const StyledContainer = styled.div`
  padding: 36px;

  @media ${theme.breakpoints.mobile} {
    padding: 0px;
  }

  @media ${theme.breakpoints.tablet} {
    padding: 0px;
  }
`;

const StyledHeader = styled.div`
  border-bottom: 1px solid #e6e6e6;
  cursor: pointer;

  @media ${theme.breakpoints.mobile} {
    display: none;
  }

  @media ${theme.breakpoints.tablet} {
    display: none;
  }
`;

const StyledFlagman = styled(Flagman)`
  width: max-content;
  height: fit-content;
  cursor: pointer;
  path {
    fill: #18cf77;
    :nth-child(1) {
      fill: black;
    }
    :nth-child(2) {
      fill: black;
    }
    :nth-child(3) {
      fill: black;
    }
  }
`;

const StyledTitle = styled.div`
  font-family: ${theme.fonts.primary};
  font-weight: 700;
  font-size: 34px;
  line-height: 49px;
  letter-spacing: -0.02em;
  color: ${theme.colors.black};
  margin-top: 20px;
  width: fit-content;

  @media ${theme.breakpoints.mobile} {
    display: none;
  }

  @media ${theme.breakpoints.tablet} {
    display: none;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 11px;
  background-color: ${theme.colors.roleSelectTitleUnderline};

  @media ${theme.breakpoints.mobile} {
    display: none;
  }

  @media ${theme.breakpoints.tablet} {
    display: none;
  }
`;

const StyledFormWrapper = styled.div`
  height: calc(100vh - 499px);
  min-height: 400px;
  align-items: center;
  justify-content: center;
  display: flex;

  @media ${theme.breakpoints.mobile} {
    padding: 50px 8px;
  }

  @media ${theme.breakpoints.tablet} {
    padding: 50px 8px;
  }
`;

const Component = (): React.ReactElement => {
  const [notification, setNotification] = useState<INotification | null>(null);
  const [apiError, setApiError] = useState('');
  const navigate = useNavigate();
  const { useUpdatePassword } = useAuthHooks();
  const { forgotPassword } = useUpdatePassword();
  const { useFetchPage } = usePageHooks();
  const { fetchPage } = useFetchPage();

  const {
    mutate: forgotPasswordMutation,
    isLoading,
  } = useMutation((email: string) => forgotPassword(email));

  const onSubmit = useCallback(
    (
      values: { email: string },
      { resetForm }: FormikHelpers<{ email: string }>,
    ) => {
      setApiError('');
      forgotPasswordMutation(values.email, {
        onSuccess: res => {
          resetForm();
          setNotification({
            isOpen: true,
            message: res?.message || '',
            type: 'success',
            position: 'top-right',
            onClose: handleCloseToast,
          });
        },
        onError: error => {
          const err = error as AxiosError;
          setApiError(err.message);
        },
      });
    },
    [],
  );

  const { data: getPageData, isFetching: isFetchingPageData } = useQuery(
    [queryKeys.FETCH_PAGE],
    () => {
      return fetchPage('TOP_PAGE');
    },
  );

  const handleCloseToast = () => {
    setNotification(null);
  };

  return (
    <>
      <MobileHeader
        links={getPageData?.sections[0]?.header?.content}
        isFetching={isFetchingPageData}
      />
      <StyledContainer>
        <StyledHeader>
          <a onClick={() => navigate('/top')}>
            <StyledFlagman />
          </a>
        </StyledHeader>
        <StyledTitle>
          パスワードを忘れた場合
          <Divider />
        </StyledTitle>
        <StyledFormWrapper>
          <Formik
            initialValues={{ email: '' }}
            validationSchema={ForgotPasswordSchema}
            onSubmit={onSubmit}>
            {({
              handleChange,
              handleSubmit,
              handleBlur,
              touched,
              values,
              errors,
              setFieldTouched,
            }): React.ReactElement => {
              return (
                <ForgotPaswordForm
                  isLoading={isLoading}
                  email={values.email}
                  onChangeEmail={handleChange('email')}
                  onPressReissue={handleSubmit}
                  errors={{
                    email: (touched.email && errors.email) || '',
                    apiError,
                  }}
                  onBlurEmail={handleBlur('email')}
                  setFieldTouched={setFieldTouched}
                />
              );
            }}
          </Formik>
        </StyledFormWrapper>
        {notification ? <Toast {...notification} /> : null}
      </StyledContainer>
      <Footer
        footerContents={[
          getPageData?.sections[6]?.footer?.content?.plan,
          getPageData?.sections[6]?.footer?.content?.frequently_used_links,
          getPageData?.sections[6]?.footer?.content?.company_overview,
        ]}
        addressContents={{
          address: getPageData?.sections[6]?.footer?.content?.company_address,
          telephoneNumber: getPageData?.sections[6]?.footer?.content?.tel,
          faxNumber: getPageData?.sections[6]?.footer?.content?.fax,
        }}
        faqsContents={{
          privacyPolicy:
            getPageData?.sections[6]?.footer?.content?.privacy_policy,
          termsOfService:
            getPageData?.sections[6]?.footer?.content?.terms_of_service,
          transactionLaw:
            getPageData?.sections[6]?.footer?.content?.transaction_law,
        }}
        isFetching={isFetchingPageData}
      />
    </>
  );
};

export default Component;
