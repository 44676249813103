import BoardRepository from '../../ports/BoardRepository';
import { UnfavoriteSuccess } from '../../../domain/entities/board';

export type unfavoriteBoardUseCase = (
  boardCode: string,
) => Promise<UnfavoriteSuccess>;

export const buildUnfavoriteBoard = (dependencies: {
  boardRepo: BoardRepository;
}): unfavoriteBoardUseCase => {
  const { boardRepo } = dependencies;

  const unfavoriteBoard: unfavoriteBoardUseCase = boardCode =>
    boardRepo.unfavoriteBoard(boardCode);

  return unfavoriteBoard;
};
