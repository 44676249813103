import React from 'react';
import { ChromePicker, ColorChangeHandler } from 'react-color';
import styled from 'styled-components';
import theme from '../../../constants/themes';
import { Button } from '../../atoms/Button';

const Wrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.25);
  background-color: ${theme.colors.white};
  position: static;
  .chrome-picker {
    /* appears be the quickest way to override inline style https://github.com/casesandberg/react-color/issues/666 */
    box-shadow: none !important;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 5px 0 5px 0;
`;

const StyledButton = styled(Button)`
  &&&&& {
    width: 80px;
    height: 30px;
    padding-left: 7px;
    padding-right: 7px;
  }
`;

export type Props = {
  onChangeComplete?: ColorChangeHandler;
  color?: string;
  className?: string;
  onClickCancel?: React.MouseEventHandler<HTMLButtonElement>;
  onClickDone?: React.MouseEventHandler<HTMLButtonElement>;
};

const Component = ({
  className,
  color,
  onChangeComplete = () => {},
  onClickDone,
  onClickCancel,
}: Props): React.ReactElement => {
  return (
    <Wrapper className={className}>
      <ChromePicker
        onChangeComplete={onChangeComplete}
        color={color}
        styles={{
          default: {
            body: {
              boxShadow: 'none',
            },
          },
        }}
      />
      {onClickDone && onClickCancel && (
        <ButtonsWrapper>
          <StyledButton
            onMouseDown={onClickCancel}
            title="Cancel"
            theme="blackHollow"
          />
          <StyledButton
            onMouseDown={onClickDone}
            title="Done"
            theme="lightSecondary"
          />
        </ButtonsWrapper>
      )}
    </Wrapper>
  );
};

export default Component;
