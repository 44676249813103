export default {
  // Login Screen
  todoApp: 'Todo App',
  employeeNumber: '社員番号',
  password: 'パスワード',
  login: 'サインイン',
  signInTitle: 'サインイン',
  emailLabel: 'メールアドレス',
  passwordLabel: 'パスワード',
  rememberMe: 'サインインしたままにする',
  thirdPartySignIn: 'もしくは別の方法でログインする',
  fieldIsRequired: '必須項目です。',
  emailDNE: 'メールアドレスが存在しません。',
  invalidEmail:
    'メールアドレスは、有効なメールアドレス形式で指定してください。',
  forgotPassword: 'パスワードを忘れた場合',
  signUpPretext: 'アカウントがない場合 ',
  signUp: 'アカウント作成',
  signUpTitle: 'サインアップ',
  nameLabel: '氏名',
  register: 'サインアップ',
  passwordMinLength: 'パスワードは、8文字以上で指定してください。',
  signIn: 'サインイン。',
  signInPretext: 'すでにアカウントをお持ちですか？',

  // Change Password Screen
  pleaseSetNewPassword: '新しいパスワードを設定してください',
  newPassword: '新しいパスワード',
  newPasswordConfirm: '新しいパスワード（確認）',
  change: '変更',
  changePassword: 'パスワード変更',
  member: 'メンバー',
  substituteRecord: '代理打刻',

  // Home Screen
  home: 'ホーム',
  offDuty: '勤務外',
  onDuty: '勤務中',
  constructionNumber: '三機工業 20-011 マツダ中関B棟',
  constructionSubject: '防塵室新築工事ダクト工事',
  start: '勤務開始',
  modify: '修正',
  finish: '勤務終了',
  cancel: 'キャンセル',
  confirm: '確認',
  confirmAttendance: '以下の内容で出勤を打刻します。よろしいでしょうか？',
  all: '全員',

  // Errors
  required: 'このフィールドは入力必須です。',
  requiredEmail: 'メールアドレスは、必ず指定してください。',
  requiredOldPassword: '旧パスワードは、必ず指定してください。',
  requiredPassword: 'パスワードは、必ず指定してください。',
  requiredRenewedPassword: '再新パスワードは、必ず指定してください。',
  requiredName: '氏名は、必ず指定してください。',
  maxLengthEmail: 'メールアドレスは、255文字以下にしてください。',
  maxLengthPassword: 'パスワードは、255文字以下で指定してください。',
  maxLengthOldPassword: '旧パスワードは、255文字以下で指定してください。',
  maxLengthRenewedPassword: '再新パスワードは、255文字以下で指定してください。',
  minLengthOldPassword: '旧パスワードは、8文字以上で指定してください。',
  minLengthRenewedPassword: '再新パスワードは、8文字以上で指定してください。',
  maxLengthFullName: '氏名は、255文字以下で指定してください。',
  loginError: '社員番号またはパスワードに誤りがあります',
  changePassNotMatchError: 'パスワードが一致しません。',
  changePassCaseError:
    '小文字アルファベット・半角数字を最低１文字ずつ使ってください。',
  somethingWentWrong: 'Something went wrong. Please retry.',
  boardSharing: 'ボード共有',
  upgrade: 'アップグレード',
  networkError: 'ネットワークエラー',
  differentOldPassword:
    'パスワードと旧パスワードには、異なった内容を指定してください。',
  passwordsMustMatch: 'パスワードと、再新パスワードとが、一致していません。',
  resetRequiredPassword: 'パスワードは、必ず指定してください。',
  resetConfirmPasswordMinLength:
    '再入力パスワードは、8文字以上で指定してください。',
  resetConfirmPasswordMaxLength:
    '再入力パスワードは、255文字以下で指定してください。',
  resetRequiredReentryPassword: '再入力パスワードは、必ず指定してください。',
  resetReentryPasswordMustMatch:
    '再入力パスワードとパスワードには同じ値を指定してください。',
  maximumMessageCharacter: 'メッセージ内容は255文字以下で指定してください。',

  // Verify Email
  verifyPrompt: 'アカウント登録確認メールを送信しました。',
  verificationSent: '以下の宛先にメールを送信しました',
  verificationEmail: '{{email}}にIDEAMANアカウントの認証をしてください。',
  returnToHome: '「ホームへ」をクリックすると、ホームページ画面に戻ります。',
  resendPrompt: 'メールがない場合、',
  resend: '認証メールを再送信',
  homeBtn: 'ホームへ',
  resendSuccess: '送信された電子メール検証',

  // Email Verified
  emailVerifiedTitle: 'THANK YOU!',
  registered: 'アカウント登録完了',
  registeredSubtext: 'メールアドレスの認証が完了しました。',
  toMyBoard: 'マイボードへ',
  participant: '参加者',
  send: '送信',

  // My Board List
  latestFilter: '最近作成したボード',
  latestTeamFilter: '{{teamName}}のボード',
  favoriteFilter: 'お気に入りボード',
  addTeamPlaceholder: 'チーム名',
  addTeamModalTitle: 'チーム作成',
  board: 'ボード',
  addBtn: '保存',
  addBoard: '新規',
  delete: '削除',
  newChannel: 'ボード新規',
  personal: '個人',
  teams: 'チーム',
  addTeam: '追加',
  profile: 'プロフィール',
  searchPlaceholder: '検索',
  updatedAtSort: '変更日',
  createdBySort: '変更者',
  boardNameSort: 'ボード名',
  template: 'テンプレート',
  selectRoleBtn: '{{roleName}}として',
  roleSelectTitle: '役割選択',
  basicInformation: '基本情報',
  familyName: '氏名',
  mailAddress: 'メールアドレス',
  plan: 'プラン',
  oldPassword: '旧パスワード',
  newProfilePassword: '新パスワード',
  renewedPassword: '再新パスワード',
  accountDeletion: 'アカウント削除',
  deleteInfo:
    'アカウントを削除すると全てのデータやボードなどがなくなります。\nご注意してください。',
  withdrawal: '退会',
  keep: '保存',
  edit: '編集',
  uploadedImage: 'アップロードした画像',
  searchResults: '検索結果',
  upload: 'アップロード',
  inviteTeamMembers: 'チームメンバー招待',
  addTeamMember: 'メンバー追加',
  createTeamMaxLength: 'チーム名は、255文字以下で指定してください。',
  teamNameRequired: 'チーム名は必ず指定してください。',
  teamIdRequired: 'チームは必ず指定してください。',
  teamIdMin: '選択されたチームは正しくありません。',
  warningLabel:
    'すべての機能を使用できるようにメールアドレスを更新してください。',
  warningLink: 'プロフィール更新ページへ移動',
  emailInstruction: '複数のメールアドレスをカンマ（,）で区切って入力できます。',

  //PlanCards
  yen: '円',
  perMonth: '/月額',

  // Top Page
  topLogin: '会員ログイン',
  topSignup: '無料登録',
  credits: '©2022 Flagman. All Rights Reserved.',
  pleaseChoosePlan: 'プランを選んでください',

  // deleteUser
  deleteUserPrompt: 'アカウントを削除してよろしいでしょうか？',

  // Payment History
  month: '月',
  paymentHistorySettings: '設定',
  currentPlan: '現行プラン',
  cardLabel: '支払情報',
  date: '日付',
  paymentHistoryLabel: '支払履歴',
  explanation: '説明',
  amount: '金額',
  status: 'ステータス',

  //Member Management
  invoice: '請求書',
  teamMemberManagement: 'チームメンバー管理',
  memberManagement: 'メンバー管理',
  accountType: 'アカウントタイプ',
  editMember: 'メンバー編集',
  deleteConfirmation: '削除確認',
  deleteThisAccount: 'アカウントを削除してよろしいでしょうか？',
  deleteThisAccountInTeam:
    'このメンバーをチームから削除してもよろしいでしょうか？',
  deleteThisAccountInBoard:
    'このメンバーをボードから削除してもよろしいでしょうか？',
  no: 'いいえ',
  yes: 'はい',
  manager: '管理者',

  // profile screen change password
  passwordMustMatch: 'パスワードと、再入力パスワードとが、一致していません。',
  oldPasswordRequired: '古いパスワードが必要です',
  newPasswordRequired: '新しいパスワードが必要です',
  renewedPasswordRequired: '更新されたパスワードが必要です',

  // top page
  invalidImageFormat:
    'アップロードファイルにはjpeg, jpg, svg, png, gifタイプのファイルを指定してください。',
  invalidVideoFormat:
    'アップロードファイルにはjpeg MP4 タイプのファイルを指定してください。',
  carouselRequired: '顧客レビューは必ず指定してください。',
  faqRequired: 'よくある質問を指定する場合は、項目も指定してください。',

  // hint
  otherHint: '別のカードを引く',
  hint: 'ヒント',
};
