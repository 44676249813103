import { Rating } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from '../../../constants/themes';
import { OrangeStar, OrangeStarNotFilled } from '../../atoms/Icons';
import { CarouselContent as CarouselContentType } from '../TopBody/types';
import createDOMPurify from 'dompurify';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
const ClientTitle = styled.div`
  font-style: normal;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  letter-spacing: -0.02em;
  padding: 0.5em 0.2em;
`;

const CarouselContainer = styled.div`
  position: relative;

  padding: 0 0.2em;
`;

const CarouselSlide = styled.div`
  display: none;
  flex-direction: row;
  gap: 10em;

  &.slide {
    animation-name: fade;
    animation-duration: 1.5s;
  }

  &.active {
    display: flex;
  }
`;

const CarouselContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5em;
`;

const CarouselIndicators = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1em;
  flex-direction: row;
  gap: 0.5em;
`;

const SlideIndicator = styled.span`
  background: #c4c4c4;
  width: 30px;
  height: 2px;
  cursor: pointer;

  &.active {
    background: ${theme.colors.notifToastBorder};
  }
`;

const Testimonial = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TestimonialTitle = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.1px;
  color: ${theme.colors.infoToastText};

  h1,
  h2,
  h3 {
    margin: 0;
  }
`;
const TestimonialSubTitle = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: ${theme.colors.textColor};

  p {
    margin: 0;
  }
`;

const TestimonialRating = styled.div`
  margin-top: 1em;
`;

const TestimonialDetails = styled.div`
  margin-top: 1em;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.2px;
  color: ${theme.colors.textColor};

  p:first-of-type {
    margin: 0;
  }
`;

const DOMPurify = createDOMPurify(window as any);

export type Props = {
  carouselContent: CarouselContentType;
  isFetching?: boolean;
};

const Component = ({
  isFetching,
  carouselContent,
}: Props): React.ReactElement => {
  const [activeCarouselIndex, setActiveCarouselIndex] = useState(0);
  const [intervalStatus, setIntervalStatus] = useState<'start' | 'stop'>(
    'start',
  );

  const updateCarouselIndex = (index: number) => {
    const carouselLength = carouselContent.contents.length;
    if (index < 0) {
      index = carouselLength - 1;
    } else if (index >= carouselLength) {
      index = 0;
    }

    setActiveCarouselIndex(index);
  };

  useEffect(() => {
    let interval: any;
    if (intervalStatus === 'start') {
      interval = setInterval(() => {
        updateCarouselIndex(activeCarouselIndex + 1);
      }, 3000);
    } else if (intervalStatus === 'stop') {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  });

  return (
    <Container>
      {isFetching ? null : (
        <>
          <ClientTitle>{carouselContent.title}</ClientTitle>
          <CarouselContainer>
            {carouselContent.contents.map((content, index) => (
              <CarouselSlide
                onMouseOver={() => setIntervalStatus('stop')}
                onMouseOut={() => setIntervalStatus('start')}
                key={index}
                className={`slide ${
                  activeCarouselIndex === index ? 'active' : ''
                }`}>
                <CarouselContent>
                  <div style={{ position: 'relative' }}>
                    <img height={292} width={182} src={content.image} />
                  </div>
                  <Testimonial>
                    <TestimonialTitle
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(content.fullname),
                      }}
                    />
                    <TestimonialSubTitle
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(content.role),
                      }}
                    />
                    <TestimonialRating>
                      <Rating
                        readOnly={true}
                        icon={<OrangeStar />}
                        emptyIcon={<OrangeStarNotFilled />}
                        value={carouselContent.contents[0].rating}
                      />
                    </TestimonialRating>
                    <TestimonialDetails
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(content.description),
                      }}
                    />
                  </Testimonial>
                </CarouselContent>
              </CarouselSlide>
            ))}

            <CarouselIndicators>
              {carouselContent.contents.map((content, index) => (
                <SlideIndicator
                  key={index}
                  className={`${activeCarouselIndex === index ? 'active' : ''}`}
                />
              ))}
            </CarouselIndicators>
          </CarouselContainer>
        </>
      )}
    </Container>
  );
};

export default Component;
