import { Node } from 'react-flow-renderer';

export const arrayChunks = (array: any[], chunk_size: number): Array<any> => {
  const arrayLength = array ? array.length : 0;
  return Array(Math.ceil(arrayLength / chunk_size))
    .fill(1)
    .map((_, index) => index * chunk_size)
    .map(begin => array.slice(begin, begin + chunk_size));
};

export const WHITELISTED_NODE_TYPES = ['canvas', 'dropArea'];

export const removeWhitelistedNodes = (nodes: Node[]): Node[] => {
  return nodes.filter(
    node => node.type && !WHITELISTED_NODE_TYPES.includes(node.type),
  );
};

export const includeWhitelistedNodes = (
  localNodes: Node[],
  remoteNodes: Node[],
  ownSelectedNodeIds: string[],
): Node[] => {
  const remoteNodeIds = remoteNodes.map(node => node.id);

  const filteredLocalNodes = localNodes.filter(node => {
    const isInRemote = remoteNodeIds.includes(node.id);
    const isSelectedLocally = ownSelectedNodeIds.includes(node.id);
    const isAreaNode = node.type && WHITELISTED_NODE_TYPES.includes(node.type);
    return isInRemote || isSelectedLocally || isAreaNode;
  });
  const filteredRemoteNodes = remoteNodes.filter(node => {
    const isSelectedLocally = ownSelectedNodeIds.includes(node.id);
    return !isSelectedLocally;
  });
  const mergedNodes = [...filteredLocalNodes, ...filteredRemoteNodes];
  const reducedArr: { [nodeId: string]: Node } = mergedNodes.reduce(
    (acc, cur) => {
      return { ...acc, [cur.id]: cur };
    },
    {},
  );
  return Object.values(reducedArr);
};
