import React from 'react';
import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import { Edge, Node } from 'react-flow-renderer';
import { ModalWrapper } from '../../molecules/ModalWrapper';
import { SelectTemplate } from '../../organisms/SelectTemplate';
import { SelectableTemplate } from '../../organisms/SelectTemplate/Component';
import { ToastData } from '../../templates/DiagramEditor/types';

const Container = styled.div`
  width: 1047px;
  max-height: 80vh;
  overflow: auto;
`;

const LoadingWrapper = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingIcon = styled(CircularProgress)`
  width: 39px !important;
  height: 39px !important;
`;

export type Props = {
  templates: SelectableTemplate[];
  isOpen: boolean;
  onClose?: () => void;
  disabled?: boolean;
  toastTemplate: ToastData | null;
  isAddingBoard?: boolean;
  onClickDiagram?: (payload: {
    id: number;
    xmlStr?: string;
    name: string;
  }) => void;
};

const Component = ({
  isOpen,
  onClose,
  templates,
  disabled,
  toastTemplate,
  isAddingBoard,
  onClickDiagram,
}: Props): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={onClose}
      title={t('template')}
      toastTemplate={toastTemplate}>
      <Container>
        {isAddingBoard ? (
          <LoadingWrapper>
            <LoadingIcon />
          </LoadingWrapper>
        ) : (
          <SelectTemplate
            templates={templates}
            disabled={disabled}
            onClickDiagram={onClickDiagram}
          />
        )}
      </Container>
    </ModalWrapper>
  );
};

export default Component;
