import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Flagman } from '../../atoms/Icons';
import { EmailVerified } from '../../molecules/EmailVerified';
import { useGlobalState } from '../../../hooks/global';
import { useMutation, useQuery } from 'react-query';
import { useAuthHooks } from '../../../hooks/auth';
import signInImage from '../../../assets/images/signin-image.png';
import { Footer } from '../../molecules/Footer';
import { usePageHooks } from '../../../hooks/page';
import { MobileHeader } from '../../molecules/MobileHeader';
import theme from '../../../constants/themes';
import queryKeys from '../../../constants/queryKeys';

const Container = styled.div`
  width: 100%;
  min-width: 923px;
  min-height: 800px;
  height: calc(100vh - 119px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;

  @media ${theme.breakpoints.mobile} {
    width: 100%;
    min-width: 100%;
    height: calc(100vh - 119px - 58px);
    min-height: 400px;
    margin: 0;
  }

  @media ${theme.breakpoints.tablet} {
    width: 100%;
    min-width: 100%;
    height: calc(100vh - 119px - 58px);
    justify-content: center;
    margin: 0;
  }
`;

const FlagmanWrapper = styled.div`
  position: absolute;
  top: 36px;
  left: 36px;
  cursor: pointer;

  @media ${theme.breakpoints.mobile} {
    display: none;
  }

  @media ${theme.breakpoints.tablet} {
    display: none;
  }
`;

const StyledSignInImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const FormWrapper = styled.div`
  width: max-content;
  height: max-content;
  display: flex;
  flex: 0 0 60%;
  flex-direction: column;
  align-items: center;

  @media ${theme.breakpoints.tablet} {
    padding: 50px 0px;
  }

  @media ${theme.breakpoints.mobile} {
    padding: 50px 0px;
    flex: 0 0 100%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
`;

const StyledImageDiv = styled.div`
  flex: 0 0 40%;
  height: 100%;

  @media ${theme.breakpoints.mobile} {
    display: none;
  }

  @media ${theme.breakpoints.tablet} {
    display: none;
  }
`;

export type Props = {};

const Component = ({}: Props): React.ReactElement => {
  const { useFetchUser } = useAuthHooks();
  const { fetchUserDetails } = useFetchUser();
  const { useFetchPage } = usePageHooks();
  const { fetchPage } = useFetchPage();

  const navigate = useNavigate();
  const {
    useCurrentUser: { currentUser, setCurrentUser },
  } = useGlobalState();

  const onPressMyBoard = () => {
    getUserDetail();
  };

  useEffect(() => {
    if (currentUser?.user.isVerified) {
      navigate('my-board');
    }
  }, [currentUser?.user.isVerified]);

  const { mutate: getUserDetail } = useMutation(
    async () => await fetchUserDetails(),
    {
      onSuccess: res => {
        if (res) {
          const plan = res.plan;
          setCurrentUser({
            ...currentUser,
            user: {
              ...currentUser?.user,
              isVerified: !!res.email_verified_at,
              customer: {
                ...currentUser?.user.customer,
                plan,
                plan_id: plan?.id,
              },
            },
          });
        }
      },
    },
  );

  const { data: getPageData, isFetching: isFetchingPageData } = useQuery(
    [queryKeys.FETCH_PAGE],
    () => {
      return fetchPage('TOP_PAGE');
    },
  );

  return (
    <>
      <MobileHeader
        links={getPageData?.sections[0]?.header?.content}
        isFetching={isFetchingPageData}
      />
      <Container>
        <FlagmanWrapper>
          <a onClick={() => navigate('/top')}>
            <Flagman />
          </a>
        </FlagmanWrapper>
        <FormWrapper>
          <EmailVerified onPressMyBoardBtn={onPressMyBoard} />
        </FormWrapper>
        <StyledImageDiv>
          <StyledSignInImage src={signInImage} alt="sign-in banner" />
        </StyledImageDiv>
      </Container>
      <Footer
        footerContents={[
          getPageData?.sections[6]?.footer?.content?.plan,
          getPageData?.sections[6]?.footer?.content?.frequently_used_links,
          getPageData?.sections[6]?.footer?.content?.company_overview,
        ]}
        addressContents={{
          address: getPageData?.sections[6]?.footer?.content?.company_address,
          telephoneNumber: getPageData?.sections[6]?.footer?.content?.tel,
          faxNumber: getPageData?.sections[6]?.footer?.content?.fax,
        }}
        faqsContents={{
          privacyPolicy:
            getPageData?.sections[6]?.footer?.content?.privacy_policy,
          termsOfService:
            getPageData?.sections[6]?.footer?.content?.terms_of_service,
          transactionLaw:
            getPageData?.sections[6]?.footer?.content?.transaction_law,
        }}
        isFetching={isFetchingPageData}
      />
    </>
  );
};

export default Component;
