import events from '../../../../../constants/socket';
import { DiagramEdge, DiagramNode } from '../../types';
import { removeWhitelistedNodes } from '../../../../../utils/arrays';
import xmljs from 'xml-js';

export function sendSocket(
  nodes: DiagramNode[],
  edges: DiagramEdge[],
  name: string,
  token: any,
  socket: any,
  initializedBoardCode: any,
): void {
  if (socket) {
    const jsonData = JSON.stringify({
      _declaration: { _attributes: { version: '1.0' } },
      data: { nodes: removeWhitelistedNodes(nodes), edges },
    });

    const xmlStr = xmljs.json2xml(jsonData, { compact: true });
    const eventData = {
      customers_selected_node_ids: '',
      diagram_content: xmlStr,
      board_code: initializedBoardCode,
      username: name,
      token,
    };
    socket.emit(events.CLIENT.SEND_DIAGRAM_FILE_CONTENT, eventData);
  }
}
