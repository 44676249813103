import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import theme from '../../../constants/themes';
import { isWindowsOS } from '../../../utils/booleans';

const StyledLink = styled(Link)`
  font-family: ${theme.fonts.primary};
  font-weight: 400;
  font-size: 16px;
  line-height: ${isWindowsOS() ? '34px' : '175%'};
  color: ${theme.colors.linkColor};
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export type Props = {
  to: string;
  label: string;
  className?: string;
};

const Component = ({ to, label, className }: Props): React.ReactElement => {
  return (
    <StyledLink className={className} to={to}>
      {label}
    </StyledLink>
  );
};

export default Component;
