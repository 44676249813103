import React, { useState, useEffect, forwardRef } from 'react';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { Stop, Success, Info, NotifBell } from '../Icons';
import theme from '../../../constants/themes';
import { Snackbar } from '@mui/material';

const themes = {
  error: {
    border: `thin solid ${theme.colors.errorToastBorder}`,
    backgroundColor: `${theme.colors.errorToast}`,
    color: `${theme.colors.errorToastText}`,
  },
  success: {
    border: `thin solid ${theme.colors.successToastBorder}`,
    backgroundColor: `${theme.colors.successToast}`,
    color: `${theme.colors.successToastText}`,
  },
  info: {
    border: `thin solid ${theme.colors.infoToastBorder}`,
    backgroundColor: `${theme.colors.infoToast}`,
    color: `${theme.colors.infoToastText}`,
  },
  notification: {
    border: `thin solid ${theme.colors.notifToastBorder}`,
    backgroundColor: `${theme.colors.notifToast}`,
    color: `${theme.colors.notifToastBorder}`,
  },
};

const CustomizedAlert = styled(MuiAlert)<{
  type: 'success' | 'error' | 'info' | 'notification';
}>`
  background-color: ${(props): string => themes[props.type].backgroundColor};
  border: ${(props): string => themes[props.type].border};
  border-radius: 0px;
  box-shadow: 0px 13px 19px rgba(0, 0, 0, 0.07);
  font-family: ${theme.fonts.primary};
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  color: ${(props): string => themes[props.type].color};
  top: 100px;
`;

const CustomizedStack = styled(Stack)`
  position: absolute;
  z-index: 1400;
`;

export type Props = {
  type: 'success' | 'error' | 'info' | 'notification';
  message: string;
  isOpen: boolean;
  position:
    | 'top-center'
    | 'top-right'
    | 'bottom-right'
    | 'bottom-left'
    | 'top-left';
  onClose: () => void;
};

const Component = ({
  type = 'success',
  message,
  isOpen = false,
  position = 'top-right',
  onClose,
}: Props): React.ReactElement => {
  const [open, setOpen] = useState(false);
  const [keyPosition, setKeyPosition] = useState({
    vertical: 'top' as 'top' | 'bottom',
    horizontal: 'right' as 'left' | 'center' | 'right',
  });

  const Icons: { [key: string]: React.ReactNode } = {
    success: <Success />,
    error: <Stop />,
    info: <Info />,
    notification: <NotifBell />,
  };

  useEffect(() => {
    if (isOpen) {
      setOpen(true);
    }
  }, []);

  useEffect(() => {
    setKeyPosition({
      vertical: position.split('-')[0] as 'top' | 'bottom',
      horizontal: position.split('-')[1] as 'left' | 'center' | 'right',
    });
  }, [position]);

  const handleClose = (
    _event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    onClose();
    setOpen(false);
  };

  const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
  ) {
    return (
      <CustomizedAlert
        elevation={6}
        ref={ref}
        variant="filled"
        type={type}
        {...props}
      />
    );
  });

  return (
    <CustomizedStack spacing={2} sx={{ width: '100%' }}>
      <Snackbar
        anchorOrigin={{
          vertical: keyPosition.vertical,
          horizontal: keyPosition.horizontal,
        }}
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        key={keyPosition.vertical + keyPosition.horizontal}>
        <Alert
          onClose={handleClose}
          sx={{ width: '100%', marginTop: '35px' }}
          icon={Icons[type]}>
          {message}
        </Alert>
      </Snackbar>
    </CustomizedStack>
  );
};

export default Component;
