import React from 'react';

type Props = {
  [key: string]: string | number | Function;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M15.64 12.4887C14.3145 12.4887 13.1472 13.179 12.4786 14.2189L7.21794 11.4548C7.40675 11.0072 7.51123 10.5156 7.51123 10C7.51123 9.48452 7.40675 8.9929 7.21794 8.54525L12.4786 5.78116C13.1472 6.82108 14.3145 7.51135 15.64 7.51135C17.7109 7.51135 19.3957 5.82658 19.3957 3.75574C19.3957 1.68477 17.7109 0 15.64 0C13.5692 0 11.8844 1.68477 11.8844 3.75566C11.8844 4.27123 11.9889 4.7628 12.1777 5.21045L6.91699 7.97454C6.24839 6.93462 5.08112 6.24434 3.75561 6.24434C1.68477 6.24434 0 7.92912 0 10C0 12.0709 1.68477 13.7557 3.75561 13.7557C5.08112 13.7557 6.24843 13.0654 6.91699 12.0255L12.1777 14.7895C11.9889 15.2372 11.8844 15.7288 11.8844 16.2443C11.8844 18.3152 13.5692 20 15.64 20C17.7109 20 19.3957 18.3152 19.3957 16.2443C19.3957 14.1735 17.7109 12.4887 15.64 12.4887ZM15.64 0.645161C17.3551 0.645161 18.7505 2.04052 18.7505 3.75566C18.7505 5.47075 17.3551 6.86611 15.64 6.86611C13.9249 6.86611 12.5295 5.47075 12.5295 3.75566C12.5295 2.04052 13.9249 0.645161 15.64 0.645161ZM3.75557 13.1105C2.04047 13.1105 0.645118 11.7151 0.645118 10C0.645118 8.28486 2.04047 6.88951 3.75557 6.88951C5.47067 6.88951 6.86602 8.28486 6.86602 10C6.86602 11.7151 5.47067 13.1105 3.75557 13.1105ZM15.64 19.3548C13.9249 19.3548 12.5295 17.9595 12.5295 16.2443C12.5295 14.5292 13.9249 13.1339 15.64 13.1339C17.3551 13.1339 18.7505 14.5292 18.7505 16.2443C18.7505 17.9595 17.3551 19.3548 15.64 19.3548Z"
        fill="white"
      />
    </svg>
  );
};

export default Component;
