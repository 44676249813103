import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import { motion, Variants, AnimatePresence } from 'framer-motion';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { MessageArea } from '../../atoms/MessageArea';
import { ChatBoxModal } from '../../molecules/ChatBoxModal';
import { useChatHooks } from '../../../hooks/chat';
import { useGlobalState } from '../../../hooks/global';
import { useBoardHooks } from '../../../hooks/board';
import { BOARD_CODE_IF_NEW } from '../../pages/BoardEditor/Component';
import { BoardCustomers } from '../../../domain/entities/board';
import { arrayToObject } from '../../../utils/objects';
import queryKeys from '../../../constants/queryKeys';

const StyledContainer = styled(Box)`
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: end;
  bottom: 10px;
  right: 10px;
  z-index: 1201;
  height: 75vh;
  justify-content: flex-end;
  pointer-events: none;
`;

const StyledChatBoxMotion = styled(motion.div)`
  overflow: hidden;
  pointer-events: auto;
`;

const MessageAreaWrapper = styled(Box)`
  z-index: 1;
  pointer-events: auto;
`;

type Props = {
  onToggleChatBox: (toggle: boolean) => void;
  isOpen?: boolean;
  boardName?: string;
  boardCode?: string;
  onMouseEnter?: (value: boolean) => void;
};

const Component = ({
  onToggleChatBox,
  isOpen = false,
  boardName = '',
  boardCode,
  onMouseEnter = () => {},
}: Props): React.ReactElement => {
  const { t } = useTranslation();
  const [isActive, setActive] = useState<boolean>(false);
  const [enableScrollMention, setEnableScrollMention] = useState<boolean>(
    false,
  );
  const [activeCustomers, setActiveCustomers] = useState<BoardCustomers[]>([]);
  const [message, setMessage] = useState<string>('');
  const {
    useBoardAPI,
    activeCustomerIdsState = {
      activeCustomerIds: [],
      setActiveCustomerIds: () => {},
    },
  } = useBoardHooks();
  const { fetchBoardCustomers } = useBoardAPI();
  const { useCurrentUser } = useGlobalState();
  const { currentUser } = useCurrentUser;
  const { useChat } = useChatHooks();
  const {
    messages,
    setMessages,
    sendMessage,
    fetchNewMessages,
    isChatBoxOpen,
    setChatBoxOpen,
    mentionCount,
    setMentionCount,
  } = useChat(boardCode || '');
  const { activeCustomerIds } = activeCustomerIdsState;

  const { data: customers = [] } = useQuery(
    [queryKeys.FETCH_BOARD_CUSTOMERS, boardCode],
    () => {
      if (!boardCode || boardCode === BOARD_CODE_IF_NEW) {
        throw new Error('invalid value for `board code`');
      }
      return fetchBoardCustomers(boardCode);
    },
  );

  // currentUser global has no roles or null so we have to call custmores_in_board api to get the user role
  // see: https://gitlab.com/saltovn/brain-diagram-backend/-/issues/189
  const currentUserLogged = customers.filter(
    item => item.id === currentUser?.user.customer.id,
  );

  const variant: Variants = {
    slideIn: {
      height: 'auto',
      transition: {
        ease: 'easeInOut',
        height: {
          duration: 0.3,
        },
      },
    },
  };

  const onFocus = (focus: boolean) => {
    if (focus) {
      onToggleChatBox(focus);
      setChatBoxOpen(focus);
      setEnableScrollMention(false);
    }
  };

  const onClose = () => {
    onToggleChatBox(false);
    setChatBoxOpen(false);
  };

  const onSubmit = (message: string, customerIds: (string | number)[]) => {
    if (message && message.length <= 255 && !isFetching && boardCode) {
      const mentionId = customerIds.length > 0 ? customerIds.join(',') : '';
      sendMessage({
        customerId: currentUser?.user.customer.id || 0,
        boardCode,
        message,
        username: currentUser?.user?.customer?.fullname || '',
        token: currentUser?.user?.token || '',
        avatar: currentUser?.user?.customer?.avatar || '',
        role:
          currentUser?.user?.customer?.role?.name ||
          currentUserLogged[0].role ||
          '',
        mentionId,
      });
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onUpdateMotion = (latest: any) =>
    setActive(latest.height > 10 || latest.height === 'auto');

  const { isFetching } = useQuery(
    [queryKeys.FETCH_CHAT_MESSAGES, boardCode],
    () => {
      if (!boardCode || boardCode === 'new') {
        throw new Error('Invalid value for `board code`');
      }
      return fetchNewMessages(boardCode);
    },
    {
      onSuccess: res => {
        if (res?.messages) {
          const messages = res?.messages;
          const data = messages.map(value => {
            const date = new Date(value.sentAt);
            const displayTime = `${date.getHours()}:${(date.getMinutes() < 10
              ? '0'
              : '') + date.getMinutes()}`;

            const mentionIds = value.mentionId.split(',');
            const isMentioned =
              mentionIds.filter(
                (item: string) => +item === currentUser?.user.customer.id,
              ).length > 0;
            return {
              id: value.id,
              boardCode: boardCode || '',
              message: value.content,
              displayPhoto: value.customer.avatar,
              displayTime,
              name: value.customer.fullName,
              role: value.customer.role,
              mentionId: value.mentionId,
              isMentioned,
              isRead: true,
            };
          });
          setMessages(data);
        }
      },
      refetchOnMount: true,
    },
  );

  const onClickMention = () => {
    setTimeout(() => {
      mentionCount > 0 && setEnableScrollMention(true);
    }, 500);
    setMentionCount(0);
    setChatBoxOpen(true);
    onToggleChatBox(true);
  };

  useEffect(() => {
    if (customers.length > 0 && activeCustomerIds.length > 0) {
      const cutomersObj = arrayToObject(customers) as {
        [id: number]: BoardCustomers;
      };
      const uniqueCustomerIds = Array.from(new Set(activeCustomerIds));
      const activeUsers = uniqueCustomerIds.map(id => cutomersObj[id]);
      setActiveCustomers(activeUsers.filter(item => item !== undefined));
    }
  }, [customers, activeCustomerIds]);

  useEffect(() => {
    !isChatBoxOpen && setEnableScrollMention(false);
  }, [isChatBoxOpen]);

  useEffect(() => {
    !isOpen && setChatBoxOpen(false);
  }, [isOpen]);

  const filterCustomers = activeCustomers.filter(
    item => item.id !== currentUser?.user.customer.id,
  );

  return (
    <StyledContainer>
      <AnimatePresence>
        {isChatBoxOpen && (
          <StyledChatBoxMotion
            onUpdate={onUpdateMotion}
            exit={{
              height: 0,
              y: 30,
              transition: {
                height: { duration: 0.5 },
              },
            }}
            initial={{ height: 0 }}
            animate="slideIn"
            variants={variant}>
            <ChatBoxModal
              onMouseEnter={onMouseEnter}
              error={message.length > 255 ? t('maximumMessageCharacter') : ''}
              isScrollMention={enableScrollMention}
              isLoading={isFetching}
              template={boardName}
              onClose={onClose}
              messages={messages}
            />
          </StyledChatBoxMotion>
        )}
      </AnimatePresence>
      <MessageAreaWrapper>
        <MessageArea
          message={message}
          setMessage={setMessage}
          customers={filterCustomers}
          onClickMention={onClickMention}
          mentionCount={mentionCount}
          isLoading={isFetching}
          isActive={isActive}
          onSubmit={onSubmit}
          onFocus={onFocus}
        />
      </MessageAreaWrapper>
    </StyledContainer>
  );
};

export default Component;
