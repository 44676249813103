import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import theme from '../../../constants/themes';
import { arrayChunks } from '../../../utils/arrays';
import {
  OrangeStar,
  OrangeStarNotFilled,
  Plus,
  Trash,
  UploadCloud,
} from '../../atoms/Icons';
import { CarouselContent as CarouselContentType } from './types';
import { Toast } from '../../atoms/Toast';
import { Props as INotification } from '../../atoms/Toast/Component';
import { usePageHooks } from '../../../hooks/page';
import { useMutation } from 'react-query';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import InlineEditor from '@itsmitchyyy/ckeditor5-build-inline';
import { Rating } from '@mui/material';
import createDOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import { replaceNbsp } from '../../../utils/strings';

const ClientContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5em;
  gap: 3em;
`;

const ClientTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 1em;
`;

const ClientTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  letter-spacing: -0.02em;
  color: ${theme.colors.black};

  span {
    padding-bottom: 0.2em;
    border-bottom: 3px solid ${theme.colors.notifToastBorder};
  }
`;

const ClientContent = styled.div``;

const CarouselContainer = styled.div`
  position: relative;
`;

const CarouselContent = styled.div`
  width: 500px;
  display: flex;
  flex-direction: row;
  gap: 2em;
  position: relative;

  .CKEditorMarginGap {
    gap: 0.2em;

    .ck.ck-editor__editable_inline:last-child {
      min-height: 110px;
    }
  }
`;

const CarouselSlide = styled.div`
  display: none;
  flex-direction: row;
  gap: 10em;

  &.slide {
    animation-name: fade;
    animation-duration: 1.5s;
  }

  &.active {
    display: flex;
  }
`;

const CarouselIndicators = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1.5em;
  flex-direction: row;
  gap: 0.5em;
`;

const SlideIndicator = styled.span`
  background: #c4c4c4;
  width: 40px;
  height: 5px;
  cursor: pointer;

  &.active {
    background: ${theme.colors.notifToastBorder};
    }
  }
`;

const StyledPlus = styled(Plus)`
  height: 30px;
  width: 30px;
`;

const AddNewContent = styled.div`
  background: ${theme.colors.notifToastBorder};
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
`;

const UploadFileButton = styled.div`
  background: #ffd200;
  width: 90px;
  height: 70px;
  display: flex;
  position: absolute;
  top: 10px;
  right: 10px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
`;

const StyledUploadCLoud = styled(UploadCloud)`
  height: 30px;
  width: 30px;
`;

const Testimonial = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TestimonialTitle = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.1px;
  color: ${theme.colors.infoToastText};

  p,
  h1,
  h2,
  h3 {
    margin: 0;
  }
`;
const TestimonialSubTitle = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: ${theme.colors.textColor};

  p {
    margin: 0;
  }
`;

const TestimonialRating = styled.div`
  margin-top: 1em;
`;

const TestimonialDetails = styled.div`
  margin-top: 1em;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.2px;
  color: ${theme.colors.textColor};

  p:first-of-type {
    margin: 0;
  }
`;

const StyledTrash = styled(Trash)`
  width: 25px;
  height: 25px;
  fill: ${theme.colors.errorToastBorder};
`;

const DeleteCarouselIconContainer = styled.div`
  position: absolute;
  top: -50px;
  right: 0;
  padding: 0.5em;
  border: 1px solid ${theme.colors.errorToastBorder};
  height: 25px;
  border-radius: 5px;
  cursor: pointer;

  :hover {
    background: ${theme.colors.errorToast};
    border: 1px solid ${theme.colors.errorToast};
  }
`;

const ErrorWrapper = styled.div`
  color: ${theme.colors.errorToastText};
`;

const DivContainer = styled.div<{ hasError?: boolean }>`
  .ck.ck-editor__editable.ck-rounded-corners:not(.ck-editor__nested-editable) {
    ${({ hasError }) =>
      hasError ? `border: 1px solid ${theme.colors.errorToastText}` : ''};
  }
`;

const DOMPurify = createDOMPurify(window as any);

export type Props = {
  title: string;
  allowEditContent?: boolean;
  onAddNewElement?: () => void;
  carouselContent: CarouselContentType;
  onChangeCarouselData?: (data: CarouselContentType) => void;
  addingNewElement?: () => void;
  serverErrors?: any;
};

const Component = ({
  title,
  allowEditContent,
  onAddNewElement,
  carouselContent,
  onChangeCarouselData,
  serverErrors,
}: Props): React.ReactElement => {
  const { t } = useTranslation();
  const { useUploadMedia } = usePageHooks();
  const { uploadMedia } = useUploadMedia();
  const [activeCarouselIndex, setActiveCarouselIndex] = useState(0);
  const [uploadedCarouselImage, setUploadedCarouselImage] = useState<string[]>(
    [],
  );
  const carouselInputFile = useRef<any>([]);
  const [selectedCarouselIndex, setSelectedCarouselIndex] = useState<number>(0);
  const [notification, setNotification] = useState<INotification | null>(null);
  const [intervalStatus, setIntervalStatus] = useState<'start' | 'stop'>(
    'start',
  );

  const changeFileHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;

    if (!fileList) return;

    if (
      !['jpeg', 'jpg', 'png', 'gif', 'svg+xml'].includes(
        fileList[0].type.replace(/(.*)\//g, ''),
      )
    ) {
      setNotification({
        isOpen: true,
        message: t('invalidImageFormat'),
        type: 'error',
        position: 'top-right',
        onClose: () => setNotification(null),
      });
      return;
    }

    uploadMediaMutation(fileList[0]);
  };

  const { mutate: uploadMediaMutation } = useMutation(
    (file: File) => {
      return uploadMedia(file);
    },
    {
      onSuccess: data => {
        const newData = [...uploadedCarouselImage];
        newData[selectedCarouselIndex] = data.url;
        setUploadedCarouselImage(newData);
        setSelectedCarouselIndex(0);
      },
      onError: error => {
        setNotification({
          isOpen: true,
          message: String(error)
            .replace('Error:', '')
            .trim(),
          type: 'error',
          position: 'top-right',
          onClose: () => setNotification(null),
        });
      },
    },
  );

  const updateCarouselIndex = (index: number) => {
    const carouselLength = arrayChunks(carouselContent.contents, 2).length;
    if (index < 0) {
      index = 0;
    } else if (index >= carouselLength) {
      index = carouselLength - 1;
    }

    setActiveCarouselIndex(index);
  };

  const handleDeleteCarousel = (index: number) => {
    const carouselLength = arrayChunks(carouselContent.contents, 2).length;
    const carouselData = { ...carouselContent };
    carouselData.contents.splice(index, 1);
    uploadedCarouselImage.splice(index, 1);

    if (onChangeCarouselData) onChangeCarouselData(carouselData);

    if (carouselLength > 1) updateCarouselIndex(Math.round(index / 2));
  };

  useEffect(() => {
    let interval: any;
    if (intervalStatus === 'start') {
      interval = setInterval(() => {
        updateCarouselIndex(activeCarouselIndex + 1);
      }, 3000);
    } else if (intervalStatus === 'stop') {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  });

  useEffect(() => {
    if (uploadedCarouselImage.length && onChangeCarouselData) {
      const carouselData = { ...carouselContent };
      carouselData.contents[selectedCarouselIndex].image =
        uploadedCarouselImage[selectedCarouselIndex];
      onChangeCarouselData(carouselData);
    }
  }, [uploadedCarouselImage]);

  useEffect(() => {
    const carouselLength = arrayChunks(carouselContent.contents, 2).length;
    const chunkIndex = carouselLength ? carouselLength * 2 - 1 : 0;
    if (serverErrors && serverErrors[`client_review.${chunkIndex}.rating`]) {
      updateCarouselIndex(Math.round(chunkIndex / 2));
    }
  }, [serverErrors]);

  return (
    <ClientContainer id="client">
      <ClientTitleContainer>
        <ClientTitle>
          <span>{title}</span>
        </ClientTitle>
        {allowEditContent && (
          <AddNewContent onClick={onAddNewElement}>
            <StyledPlus />
          </AddNewContent>
        )}
      </ClientTitleContainer>
      <ClientContent>
        <CarouselContainer>
          {(serverErrors && !!serverErrors?.[`client_review`]) ||
          (carouselContent.contents && !carouselContent.contents.length) ? (
            <ErrorWrapper>
              <span>
                {serverErrors?.[`client_review`] || t('carouselRequired')}
              </span>
            </ErrorWrapper>
          ) : null}
          {arrayChunks(carouselContent.contents, 2)?.map(
            (chunk, chunkIndex) => (
              <CarouselSlide
                onMouseOver={() => setIntervalStatus('stop')}
                onMouseOut={() => setIntervalStatus('start')}
                key={chunkIndex}
                className={`slide ${
                  chunkIndex === activeCarouselIndex ? 'active' : ''
                }`}>
                {chunk?.map((content: any, index: any) => (
                  <>
                    <CarouselContent
                      key={carouselContent.contents.length - index}>
                      {allowEditContent ? (
                        <DeleteCarouselIconContainer
                          onClick={() =>
                            handleDeleteCarousel(chunkIndex * 2 + index)
                          }>
                          <StyledTrash />
                        </DeleteCarouselIconContainer>
                      ) : null}

                      <div style={{ position: 'relative' }}>
                        <img
                          height={292}
                          width={182}
                          src={
                            uploadedCarouselImage?.[chunkIndex * 2 + index] ||
                            content.image ||
                            'https://via.placeholder.com/300'
                          }
                          alt=""
                        />
                        {serverErrors &&
                          !!serverErrors?.[
                            `client_review.${chunkIndex * 2 + index}.image`
                          ] && (
                            <ErrorWrapper>
                              <span>
                                {
                                  serverErrors?.[
                                    `client_review.${chunkIndex * 2 +
                                      index}.image`
                                  ]
                                }
                              </span>
                            </ErrorWrapper>
                          )}
                        {allowEditContent && (
                          <UploadFileButton
                            onClick={() => {
                              if (carouselInputFile?.current) {
                                setSelectedCarouselIndex(
                                  chunkIndex * 2 + index,
                                );
                                carouselInputFile?.current[
                                  chunkIndex * 2 + index
                                ]?.click();
                              }
                            }}>
                            <StyledUploadCLoud />
                            <input
                              ref={el =>
                                (carouselInputFile.current[
                                  chunkIndex * 2 + index
                                ] = el)
                              }
                              type="file"
                              id={`carousel_file_${chunkIndex * 2 + index}`}
                              onChange={changeFileHandler}
                              style={{ display: 'none' }}
                            />
                          </UploadFileButton>
                        )}
                      </div>
                      <Testimonial
                        className={allowEditContent ? 'CKEditorMarginGap' : ''}>
                        {allowEditContent ? (
                          <>
                            <DivContainer
                              hasError={
                                serverErrors &&
                                !!serverErrors?.[
                                  `client_review.${chunkIndex * 2 +
                                    index}.fullname`
                                ]
                              }>
                              {serverErrors &&
                                !!serverErrors?.[
                                  `client_review.${chunkIndex * 2 +
                                    index}.fullname`
                                ] && (
                                  <ErrorWrapper>
                                    <span>
                                      {
                                        serverErrors?.[
                                          `client_review.${chunkIndex * 2 +
                                            index}.fullname`
                                        ]
                                      }
                                    </span>
                                  </ErrorWrapper>
                                )}

                              <CKEditor
                                key={`carousel_editor--fullname_${carouselContent
                                  .contents.length - index}`}
                                editor={InlineEditor}
                                config={{
                                  extraAllowedContent: 'div(*)',
                                  allowedContent: true,
                                }}
                                data={content.fullname}
                                onChange={(event: any, editor: any) => {
                                  const data = editor.getData();

                                  const carouselData = { ...carouselContent };

                                  carouselData.contents[
                                    chunkIndex * 2 + index
                                  ].fullname = data;

                                  if (onChangeCarouselData) {
                                    onChangeCarouselData(carouselData);
                                  }
                                }}
                              />
                            </DivContainer>

                            <DivContainer
                              hasError={
                                serverErrors &&
                                !!serverErrors?.[
                                  `client_review.${chunkIndex * 2 + index}.role`
                                ]
                              }>
                              {serverErrors &&
                                !!serverErrors?.[
                                  `client_review.${chunkIndex * 2 + index}.role`
                                ] && (
                                  <ErrorWrapper>
                                    <span>
                                      {
                                        serverErrors?.[
                                          `client_review.${chunkIndex * 2 +
                                            index}.role`
                                        ]
                                      }
                                    </span>
                                  </ErrorWrapper>
                                )}

                              <CKEditor
                                key={`carousel_editor--role_${chunkIndex * 2 +
                                  index}`}
                                style={{ marginTop: '1em' }}
                                editor={InlineEditor}
                                config={{
                                  extraAllowedContent: 'div(*)',
                                  allowedContent: true,
                                }}
                                data={content.role}
                                onChange={(event: any, editor: any) => {
                                  const data = editor.getData();

                                  const carouselData = { ...carouselContent };
                                  carouselData.contents[
                                    chunkIndex * 2 + index
                                  ].role = data;
                                  if (onChangeCarouselData) {
                                    onChangeCarouselData(carouselData);
                                  }
                                }}
                              />
                            </DivContainer>
                          </>
                        ) : (
                          <>
                            <TestimonialTitle
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                  replaceNbsp(content.fullname),
                                ),
                              }}
                            />
                            <TestimonialSubTitle
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                  replaceNbsp(content.role),
                                ),
                              }}
                            />
                          </>
                        )}

                        <DivContainer
                          hasError={
                            serverErrors &&
                            !!serverErrors?.[
                              `client_review.${chunkIndex * 2 + index}.rating`
                            ]
                          }>
                          {serverErrors &&
                            !!serverErrors?.[
                              `client_review.${chunkIndex * 2 + index}.rating`
                            ] && (
                              <ErrorWrapper>
                                <span>
                                  {
                                    serverErrors?.[
                                      `client_review.${chunkIndex * 2 +
                                        index}.rating`
                                    ]
                                  }
                                </span>
                              </ErrorWrapper>
                            )}
                          <TestimonialRating>
                            <Rating
                              readOnly={!allowEditContent}
                              icon={<OrangeStar />}
                              emptyIcon={<OrangeStarNotFilled />}
                              value={content.rating}
                              onChange={(
                                event: SyntheticEvent,
                                value: number | null,
                              ) => {
                                const carouselData = { ...carouselContent };
                                carouselData.contents[
                                  chunkIndex * 2 + index
                                ].rating = value ?? 0;
                                if (onChangeCarouselData) {
                                  onChangeCarouselData(carouselData);
                                }
                              }}
                            />
                          </TestimonialRating>
                        </DivContainer>
                        {allowEditContent ? (
                          <DivContainer
                            hasError={
                              serverErrors &&
                              !!serverErrors?.[
                                `client_review.${chunkIndex * 2 +
                                  index}.description`
                              ]
                            }>
                            {serverErrors &&
                              !!serverErrors?.[
                                `client_review.${chunkIndex * 2 +
                                  index}.description`
                              ] && (
                                <ErrorWrapper>
                                  <span>
                                    {
                                      serverErrors?.[
                                        `client_review.${chunkIndex * 2 +
                                          index}.description`
                                      ]
                                    }
                                  </span>
                                </ErrorWrapper>
                              )}
                            <CKEditor
                              key={`carousel_editor--description_${chunkIndex *
                                2 +
                                index}`}
                              editor={InlineEditor}
                              config={{
                                extraAllowedContent: 'div(*)',
                                allowedContent: true,
                                style: {
                                  minHeight: '110px',
                                },
                              }}
                              data={content.description}
                              onChange={(event: any, editor: any) => {
                                const data = editor.getData();

                                const carouselData = { ...carouselContent };
                                carouselData.contents[
                                  chunkIndex * 2 + index
                                ].description = data;
                                if (onChangeCarouselData) {
                                  onChangeCarouselData(carouselData);
                                }
                              }}
                            />
                          </DivContainer>
                        ) : (
                          <TestimonialDetails
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(
                                replaceNbsp(content.description),
                              ),
                            }}
                          />
                        )}
                      </Testimonial>
                    </CarouselContent>
                  </>
                ))}
              </CarouselSlide>
            ),
          )}

          <CarouselIndicators>
            {arrayChunks(carouselContent.contents, 2)?.map((child, index) => (
              <SlideIndicator
                onClick={() => setActiveCarouselIndex(index)}
                key={index}
                className={`${index === activeCarouselIndex ? 'active' : ''}`}
              />
            ))}
          </CarouselIndicators>
        </CarouselContainer>
      </ClientContent>
      {notification ? <Toast {...notification} /> : null}
    </ClientContainer>
  );
};

export default Component;
