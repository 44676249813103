import { createContext, SetStateAction, Dispatch, useContext } from 'react';
import { Node, Edge, OnNodesChange, OnEdgesChange } from 'react-flow-renderer';
import {
  DiagramEdge,
  DiagramNode,
} from '../../components/templates/DiagramEditor/types';
import {
  Board,
  BoardCustomers,
  BoardMedia,
  MyBoard,
  SortType,
  FavoriteSuccess,
  UnfavoriteSuccess,
  Template,
  InviteCustomerToBoardSuccess,
} from '../../domain/entities/board';
import { GenericResponse } from '../../domain/entities/rest';
import { UpdateRoleSuccess } from '../../domain/entities/role';

export type BoardHooks = {
  nodeState: {
    nodes: Node[];
    getNodes: () => Node[];
    // NOTE: be careful when calling this as this will persist the node state via socket
    setNodes: Dispatch<SetStateAction<Node[]>>;
    onNodesChange: OnNodesChange;
    setNodesFromSocket?: Dispatch<SetStateAction<Node[]>>;
    clearLocalNodes: () => void;
  };
  edgeState: {
    edges: Edge[];
    getEdges: () => Edge[];
    // NOTE: be careful when calling this as this will persist the edge state via socket
    setEdges: Dispatch<SetStateAction<Edge[]>>;
    onEdgesChange: OnEdgesChange;
    setEdgesFromSocket?: Dispatch<SetStateAction<Edge[]>>;
    clearLocalEdges: () => void;
  };
  cursorState?: {
    cursors: {
      [id: string]: { id: number; name: string; posX: number; posY: number };
    };
    setCursors: Dispatch<
      SetStateAction<{
        [id: string]: {
          id: number;
          name: string;
          posX: number;
          posY: number;
        };
      }>
    >;
  };
  activeCustomerIdsState: {
    activeCustomerIds: number[];
    setActiveCustomerIds: Dispatch<SetStateAction<number[]>>;
  };
  boardVoteState: {
    boardVote: string;
    setBoardVote: Dispatch<SetStateAction<string>>;
  };
  customersNodeIdsState: {
    customersSelectedNodeIds: {
      [key: number]: string[];
    } | null;
    setCustomersSelectedNodeIds: Dispatch<
      SetStateAction<{
        [key: string]: string[];
      } | null>
    >;
  };
  useActions: {
    actions?: { undo: () => void; redo: () => void }[];
    insertAction: (callbacks: { undo: () => void; redo: () => void }) => void;
    clearActions: () => void;
    undo: () => void;
    redo: () => void;
  };
  useMySelectedNodes: {
    recentlySelectedNodeIds: string[];
    setRecentlySelectedNodeIds: (nodeIds: string[]) => void;
  };
  useClipboard: {
    copiedNodes: DiagramNode[];
    setCopiedNodes: (items: DiagramNode[]) => void;
  };
  useBoardAPI: () => {
    createBoardFromTemplate: (payload: {
      name: string;
      teamId?: number;
      diagramUrl?: string;
      xmlStr?: string;
    }) => Promise<string>;
    fetchBoardCustomers: (boardCode: string) => Promise<BoardCustomers[]>;
    fetchBoardCustomer: (
      customerId: number,
      boardCode: string,
    ) => Promise<BoardCustomers>;
    fetchBoardByCode: (boardCode: string) => Promise<Board | undefined>;
    updateBoard: (payload: {
      boardCode: string;
      name?: string;
      xmlString?: string;
    }) => Promise<void>;
    saveBoard: (payload: {
      nodes: Node[];
      edges: Edge[];
      boardCode: string;
    }) => Promise<GenericResponse>;
    fetchMyBoards: (
      search: string,
      sortKey: SortType,
      isFavorite: boolean,
      teamId?: number,
    ) => Promise<MyBoard[]>;
    inviteCustomer: (
      boardCode: string,
      emails: string[],
    ) => Promise<InviteCustomerToBoardSuccess>;
    deleteCustomer: (
      boardCode: string,
      customerId: string,
    ) => Promise<GenericResponse>;
    updateCustomerRole: (
      boardCode: string,
      roleId: number,
    ) => Promise<UpdateRoleSuccess>;
    uploadMedia: (file: File, type: number) => Promise<void>;
    fetchMedia: (customerId: number) => Promise<BoardMedia[]>;
    setBoardAsFavorite: (boardCode: string) => Promise<FavoriteSuccess>;
    unfavoriteBoard: (boardCode: string) => Promise<UnfavoriteSuccess>;
    fetchTemplateList: () => Promise<Template[]>;
    voteCustomer: (
      boardCode: string,
      customerId: number,
    ) => Promise<GenericResponse>;
    acceptBoardInvitation: (invitationCode: string) => Promise<GenericResponse>;
  };
  useBoardDiagram: () => {
    fetchBoardDiagram: (
      url: string,
    ) => Promise<{
      nodes: DiagramNode[];
      edges: DiagramEdge[];
      xmlStr: string;
    }>;
  };
  useBoardSocket: () => {
    // NOTE use this function only when you are sure you are not calling setNodes. doing so might send diagram data to socket multiple times
    forceDiagramUpdate: () => void;
    nodes: Node[];
    setNodes: Dispatch<SetStateAction<Node[]>>;
    onNodesChange: OnNodesChange;
    edges: Edge[];
    setEdges: Dispatch<SetStateAction<Edge[]>>;
    onEdgesChange: OnEdgesChange;
    sendCursorPosition: (
      posX: number,
      posY: number,
      customerId: number,
      customerName: string,
    ) => void;
    joinBoard: () => void;
    sendVote: (
      boardCode: string,
      voteData: {
        badges: number;
        customerId: number;
        totalVote: number;
        time: number;
      },
    ) => void;
    sendSelectedNodeIds: (userId: number, nodeIds: string[]) => void;
    sendLatestDiagramRequest: (boardCode: string) => void;
    removeActiveUser: (boardCode: string, userId: number) => void;
  };
};

export const BoardHooksContext = createContext<BoardHooks | null>(null);

export const useBoardHooks = (): BoardHooks => {
  const context = useContext(BoardHooksContext);
  if (!context) {
    throw new Error(`Context not instantiated`);
  }
  return context;
};
