import LabelNode from './LabelNode';
import LineNode from './LineNode';
import MediaNode from './MediaNode';
import ShapeNode from './ShapeNode';
import StickyNode from './StickyNode';
import StrokeNode from './StrokeNode';
import CanvasNode from './CanvasNode';
import CustomEdge from './CustomEdge';
import DropAreaNode from './DropAreaNode';

const customNodeTypes = {
  sticky: StickyNode,
  label: LabelNode,
  shape: ShapeNode,
  line: LineNode,
  stroke: StrokeNode,
  media: MediaNode,
  // NOTE: these should not be commited to nodes
  canvas: CanvasNode,
  dropArea: DropAreaNode,
};

const customEdgeTypes = {
  custom: CustomEdge,
};

export { customNodeTypes, customEdgeTypes };
