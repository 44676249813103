import React from 'react';

type Props = {
  [key: string]: string | number | Function;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M20.366 7.38292L2.0439 0.716073C1.89515 0.66238 1.7342 0.652171 1.57987 0.686639C1.42554 0.721106 1.28421 0.798826 1.1724 0.910711C1.06058 1.0226 0.982912 1.16402 0.948467 1.31845C0.914021 1.47288 0.924224 1.63393 0.977882 1.78277L7.64047 20.1166C7.69867 20.2777 7.80504 20.4169 7.94511 20.5153C8.08519 20.6137 8.25217 20.6666 8.42333 20.6666C8.59012 20.6668 8.75313 20.6168 8.89122 20.5232C9.02931 20.4296 9.13614 20.2967 9.19785 20.1416L12.3959 12.1414L20.391 8.9413C20.549 8.88133 20.6851 8.77467 20.7811 8.6355C20.8771 8.49632 20.9285 8.33122 20.9285 8.16211C20.9285 7.99301 20.8771 7.8279 20.7811 7.68873C20.6851 7.54955 20.549 7.4429 20.391 7.38292H20.366ZM11.4465 10.7163L11.1133 10.8497L10.9801 11.183L8.42333 17.4999L3.15155 2.89113L17.751 8.16628L11.4465 10.7163Z"
        fill="black"
      />
    </svg>
  );
};

export default Component;
