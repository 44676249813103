import React from 'react';
import styled from 'styled-components';
import Skeleton from '@mui/material/Skeleton';
import { AddBoardCard } from '../../molecules/AddBoardCard';
import { BoardCard } from '../../molecules/BoardCard';
import { MyBoard } from '../../../domain/entities/board';
import theme from '../../../constants/themes';

const Container = styled.div`
  width: 100%;
  display: block;
`;

const StyledBoardCard = styled(BoardCard)`
  display: inline-block;
  margin: 0 15px 15px 0;

  @media ${theme.breakpoints.mobile} {
    margin: 0 0 15px 0;
  }
`;

const StyledAddBoardCard = styled(AddBoardCard)`
  display: inline-block;
  margin: 0 15px 15px 0;

  @media ${theme.breakpoints.mobile} {
    margin: 0 0 15px 0;
    width: 100%;
  }
`;

const StyledSkeleton = styled(Skeleton)`
  &&&& {
    width: 250px;
    height: 195px;
    display: inline-block;
    margin: 0 15px 15px 0;
  }

  @media ${theme.breakpoints.mobile} {
    &&&& {
      width: 100%;
      margin 0 0 15px 0;
    }
  }
`;

export type Props = {
  boards: MyBoard[];
  isLoading?: boolean;
  onPressAddNewBoard: () => void;
  onPressBoardCard: (boardId: number, boardCode: string) => void;
  onPressFavorite: (boardId: number, isFavorite: boolean) => void;
};

const Component = ({
  boards,
  isLoading,
  onPressAddNewBoard,
  onPressBoardCard,
  onPressFavorite,
}: Props): React.ReactElement => {
  return (
    <Container>
      {isLoading ? (
        <>
          <StyledSkeleton variant="rectangular" />
          <StyledSkeleton variant="rectangular" />
          <StyledSkeleton variant="rectangular" />
          <StyledSkeleton variant="rectangular" />
        </>
      ) : (
        <>
          <StyledAddBoardCard onPressAddBoard={onPressAddNewBoard} />
          {boards.map(board => {
            return (
              <StyledBoardCard
                key={board.id}
                boardId={board.id}
                boardImg={board.diagramFile}
                boardName={board.name}
                createdBy={board.createdBy ?? ''}
                createdAt={new Date(board.updatedAt).toLocaleDateString(
                  'en-CA',
                )}
                users={
                  board.customers
                    ? board.customers.map(customer => customer.avatar)
                    : []
                }
                isFavorite={board.isFavorite}
                onPressBoardCard={boardId =>
                  onPressBoardCard(boardId, board.code)
                }
                onPressFavorite={onPressFavorite}
              />
            );
          })}
        </>
      )}
    </Container>
  );
};

export default Component;
