import React from 'react';
import { Top } from '../../templates/Top';

type Props = {};

const Component = ({}: Props): React.ReactElement => {
  return <Top />;
};

export default Component;
