import React from 'react';
import styled from 'styled-components';

const StyledMedia = styled.img`
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
`;

export type Props = {
  id?: string;
  style?: React.CSSProperties;
  src: string;
  onRef?: (ref: HTMLImageElement | null) => void;
};

const Component = ({
  id,
  src,
  onRef = () => {},
  style,
}: Props): React.ReactElement => {
  return <StyledMedia id={id} src={src} style={style} ref={onRef} />;
};

export default Component;
