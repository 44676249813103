import React from 'react';

type Props = {
  [key: string]: string | number | Function;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="33"
      height="22"
      viewBox="0 0 33 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}>
      <rect width="33" height="22" fill="url(#pattern0)" />
      <defs>
        <pattern
          id="pattern0"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1">
          <use
            xlinkHref="#image0_6107_17589"
            transform="translate(-0.00505051) scale(0.010101 0.0151515)"
          />
        </pattern>
        <image
          id="image0_6107_17589"
          width="100"
          height="66"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABCCAYAAAChF3N/AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAuIwAALiMBeKU/dgAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAATmSURBVHic7ZxtiFRVGMd/z5nR3c1ezKwEM5LStMiXKEuCGi0DC6JZ3UBxZzeDPhWE0Ruy7WkVpIJAsQwCcV0wcKvdFCV8ge2LJYqFhUpCrZFFZQqKru7M3qcPbjq7bM65M3Pr7u75fZtzz/k//5n/fT1z7wWPx+PxeDweT/mR/g019XZcViVt0Fmq3KhQ8X8YG4wInFHhmCi7T/3MFx0dNleExkVSKZscM4EmFV4CqsrqdHhyVIUX2pvtjjCDEgDzX1xTcXVl13aEOmBEJPaGHzcILJ46bc5vRw52HHAdZACqTp98X+Cx6LwNWxKIflBdZx92HWCq65vuV2VplK6GOUlVVltrjUtno0HwPAMc3D1lZcbBTma5dDTAnIjNeAAC5rp0M8D4iK14ABUmuPQzQGXEXjyAOP7OTgcaz3+HDyRm+EBihg8kZvhAYoYPJGb4QGJG0rGfqsjybHbkh9s2vXGqxJqSrl0xBelZBzxSotaQw2kLUdjc3ty4qgxhAGhbS8PhXJaFwLky6A0pnAIRpaPchbd+bE8IfFdu3cGO6xZyPoriqnRFoTuY8Qf1mOEDiRk+kJjhA4kZPpCY4QOJGT6QmOEDiRk+kJjhNnUijImkujA2Et1BjOvUyaKams2JchauXmLvBaaWU3Mo4DT9LnBfrvLQruqM/Ujgj1IK9gjGBHKPir5O783ensu4/h8CQkohpSUWFAWVUlWGLv6gHjN8IDHDBxIzfCAxwwcSM3wgMcMHEjN8IDHD/cIQtmFYa6ATQ3ehzrluxomReYK+Rt/n3nMqrDXQFpA4nkj0XPEqMciRVJEJEmgGIdNv8UlRVgTCnkSSEwU9XaBShMlieBWY3WehcEiRlSryfdIEZwtpaTejA5GZItoIbk9HueAWiLJv+u08Za0NQmj/COxJ19lTKKvz2te0N9uXw5gEfgB2pzP2GiD9T2OgUv95S+PWkFqHHq99d+coOXsEuKW37UxPgnlb1jf+GlLrwIJM04GAYD9l2tu4TS4atoQM4xKBSbb1a+r/2RlRyR97YeT5qduL0dnR8spZ4NIbFhT2bllvw4YBwKcb3/wGpbOYsQPh+Ow0J4st0JWr6DPWiCn+dlTTx8fp1tZneorW4rKWCKXeIvtXieMv4baFKHcWW+Aq0zWlr5ZOLlZLVfN9jK15zhb9P41I3ndSivaUStkkwh3Fju+P235PWLqgrunusOI1Ne9ViQYr8tsCtOHJxauuD62VWTkeWJbvKpfl7VTKhjkxASBda+eq8kRe0/R05q26sDqAjL5VlgOhv8+/CqYz1nUuPAvsAn4RpeCYQLhO4CEuHzjzOSHKlzhu6iqME3hUYdQAi48Ce0Ud7qQ3JFSZyMWXJQz09oqvgCOiZB08VQAzgWkF6wIibPis2T5bqF+YtWsEML/XTGEDV148VoUFIWpfaQ2YBExy8VR4NWI2MNtJKyL8hWHM8IHEDB9IzDBAKefyHkdUcbqwNsDvEXvxAArHXfoZYF/EXjyAEfY79VNhU9RmPPyZOHftTpeOZsZEPkH9VhIlKtLY2rrM6QFXAXi63t4mytcoN0drbfihsKl9o12Cy2Upvae97RtspwoPKm77OY8TPaK8M6LrrgyOYUC/GQ5rrfn2JxYaWKTKA8BN+Ptvw3AOOKawSzSxrq2l4XBYgb8BUYFbHnAQtjEAAAAASUVORK5CYII="
        />
      </defs>
    </svg>
  );
};

export default Component;
