import {
  UpdatePageContent,
  UpdatePageSection,
} from '../../../domain/entities/page';
import { GenericResponse } from '../../../domain/entities/rest';
import PageRepository from '../../ports/PageRepository';

export type updatePageSectionUseCase = (
  pageId: number,
  data: UpdatePageSection | UpdatePageContent,
) => Promise<GenericResponse>;

export const buildUpdatePageSection = (dependencies: {
  pageRepo: PageRepository;
}): updatePageSectionUseCase => {
  const { pageRepo } = dependencies;

  const updatePageSection: updatePageSectionUseCase = async (
    pageId: number,
    data: UpdatePageSection | UpdatePageContent,
  ) => pageRepo.updatePageSection(pageId, data);

  return updatePageSection;
};
