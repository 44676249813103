import React from 'react';
import styled from 'styled-components';
import Box, { BoxProps } from '@mui/material/Box';
import theme from '../../../constants/themes';

const StyledCard = styled(Box)`
  height: 153px;
  width: 100px;
  background-color: ${theme.colors.gameCardBaseColor};
  border: 1px solid ${theme.colors.gameCardBorderColor};
  border-radius: 10px;
`;

const Component = (props: BoxProps): React.ReactElement => {
  return <StyledCard {...props} />;
};

export default Component;
