import React, { SVGProps } from 'react';

type Props = SVGProps<SVGSVGElement>;

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 20 18"
      {...props}>
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="-201.15"
          y1="-241"
          x2="-201.07"
          y2="-241"
          gradientTransform="translate(14145.7 41151.65) scale(70.26 170.72)"
          gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#0c7940" />
          <stop offset="0.23" stopColor="#15863f" />
          <stop offset="0.74" stopColor="#3da036" />
          <stop offset="1" stopColor="#5cb233" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="-199.09"
          y1="-241"
          x2="-199.01"
          y2="-241"
          gradientTransform="translate(14217.29 41784.84) scale(71.41 173.35)"
          gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#1e296f" />
          <stop offset="0.48" stopColor="#184f93" />
          <stop offset="0.83" stopColor="#1766a6" />
          <stop offset="1" stopColor="#196eac" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-3"
          x1="-200.1"
          y1="-241"
          x2="-200.03"
          y2="-241"
          gradientTransform="translate(13884.84 40657.67) scale(69.35 168.67)"
          gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#6c2c2f" />
          <stop offset="0.17" stopColor="#882730" />
          <stop offset="0.57" stopColor="#be1533" />
          <stop offset="0.86" stopColor="#db1536" />
          <stop offset="1" stopColor="#e4133a" />
        </linearGradient>
      </defs>
      <path
        fill="#fffffff"
        d="M20,14.59A3.28,3.28,0,0,1,16.87,18H0V3.41A3.27,3.27,0,0,1,3.1,0H20Z"
      />
      <path
        fill="url(#linear-gradient)"
        d="M15.62,1.14a2.35,2.35,0,0,0-2.23,2.44V16.87h3.26a2.34,2.34,0,0,0,2.23-2.44V1.14Z"
      />
      <path
        fill="url(#linear-gradient-2)"
        d="M3.33,1.14A2.34,2.34,0,0,0,1.11,3.58V16.86H4.36a2.34,2.34,0,0,0,2.22-2.44V1.14Z"
      />
      <path
        fill="url(#linear-gradient-3)"
        d="M9.48,1.14A2.34,2.34,0,0,0,7.25,3.58V16.89h3.26a2.35,2.35,0,0,0,2.23-2.44V1.14Z"
      />
      <path
        fill="#fffffff"
        d="M1.1,10.67A9.38,9.38,0,0,0,3.38,11c2.11,0,2.74-.82,2.74-1.82V6.56H4.21V9.14A1.06,1.06,0,0,1,3,10.24a4.32,4.32,0,0,1-1.92-.5Z"
      />
      <path
        fill="#fffffff"
        d="M17,8.66v0a1,1,0,0,0,1-1c0-.64-.53-1-1.24-1H13.39v4.27h3.45c.8,0,1.41-.44,1.41-1.11S17.69,8.7,17,8.66ZM14.48,7.23h1.28a.53.53,0,0,1,0,1H14.48Zm1.44,2.89H14.48V9h1.44a.59.59,0,0,1,.45.58A.57.57,0,0,1,15.92,10.12Z"
      />
      <path
        fill="#fffffff"
        d="M7.25,10.24a4.44,4.44,0,0,0,3.12.71,9.92,9.92,0,0,0,1.74-.27V9.74a4.45,4.45,0,0,1-1.68.49c-1.2.1-1.92-.49-1.92-1.53s.72-1.61,1.92-1.52a4.11,4.11,0,0,1,1.68.49V6.73a9.13,9.13,0,0,0-1.74-.27,4.39,4.39,0,0,0-3.12.71Z"
      />
    </svg>
  );
};

export default Component;
