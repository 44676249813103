import AuthService from '../../ports/AuthService';

export type fbSignUpUseCase = (
  email: string,
  name: string,
  userID: string,
  invitationCode: string,
) => Promise<{ user: object | null }>;

export const buildFbSignUp = (dependencies: {
  authService: AuthService;
}): fbSignUpUseCase => {
  const { authService } = dependencies;

  const fbSignUp: fbSignUpUseCase = (email, name, userID, invitationCode) =>
    authService.signUpUsingFb(email, name, userID, invitationCode);

  return fbSignUp;
};
