import RoleRepository from '../../ports/RoleRepository';
import { RoleSelectItem } from '../../../domain/entities/role';

export type fetchRolesUseCase = () => Promise<RoleSelectItem[]>;

export const buildFetchRoles = (dependencies: {
  roleRepo: RoleRepository;
}): fetchRolesUseCase => {
  const { roleRepo } = dependencies;

  const fetchRoles: fetchRolesUseCase = () => roleRepo.fetchRoles();

  return fetchRoles;
};
