import axios from 'axios';

import HttpAdapterAxiosImpl from '../interface/adapters/HttpAdapterAxiosImpl';

import AuthServiceRestImpl from '../interface/services/AuthServiceRestImpl';
import TodoRepositoryImpl from '../interface/repositories/TodoRepositoryImpl';
import BoardRepositoryImpl from '../interface/repositories/BoardRepositoryImpl';
import LocalStorageServiceImpl from '../interface/services/LocalStorageImpl';
import CardRepositoryImpl from '../interface/repositories/CardRepositoryImpl';
import TeamRepositoryImpl from '../interface/repositories/TeamRepositoryImpl';
import ChatRepositoryImpl from '../interface/repositories/ChatRepositoryImpl';
import RoleRepositoryImpl from '../interface/repositories/RoleRepositoryImpl';

import authUseCases from '../usecases/modules/auth';
import todoUseCases from '../usecases/modules/todo';
import boardUseCases from '../usecases/modules/board';
import localStorageUseCases from '../usecases/modules/localStorage';
import cardUseCases from '../usecases/modules/card';
import teamUseCases from '../usecases/modules/team';
import chatUseCases from '../usecases/modules/chat';
import roleUseCases from '../usecases/modules/role';
import planUseCases from '../usecases/modules/plan';
import pageUseCases from '../usecases/modules/page';
import paymentUseCases from '../usecases/modules/payment';
import urls from '../constants/urls';
import PlanRepositoryImpl from '../interface/repositories/PlanRepositoryImpl';
import PageRepositoryImpl from '../interface/repositories/PageRepositoryImpl';
import PaymentRepositoryImpl from '../interface/repositories/PaymentRepositoryImpl';

const {
  todo: todoUrls,
  auth: authUrls,
  board: boardUrls,
  card: cardUrls,
  chat: chatUrls,
  team: teamUrls,
  role: roleUrls,
  plans: planUrls,
  page: pageUrls,
  payment: paymentUrls,
} = urls;

const httpAdapter = new HttpAdapterAxiosImpl(axios);

const authService = new AuthServiceRestImpl(httpAdapter, authUrls);

const todoRepo = new TodoRepositoryImpl(httpAdapter, todoUrls);
const boardRepo = new BoardRepositoryImpl(httpAdapter, boardUrls);

const localStorageService = new LocalStorageServiceImpl();
const cardRepo = new CardRepositoryImpl(httpAdapter, cardUrls);
const teamRepo = new TeamRepositoryImpl(httpAdapter, teamUrls);
const chatRepo = new ChatRepositoryImpl(httpAdapter, chatUrls);
const roleRepo = new RoleRepositoryImpl(httpAdapter, roleUrls);
const planRepo = new PlanRepositoryImpl(httpAdapter, planUrls);
const pageRepo = new PageRepositoryImpl(httpAdapter, pageUrls);
const paymentRepo = new PaymentRepositoryImpl(httpAdapter, paymentUrls);

const authInteractor = authUseCases({ authService });
const todoInteractor = todoUseCases({ todoRepo });
const boardInteractor = boardUseCases({ boardRepo });
const localStorageInteractor = localStorageUseCases({ localStorageService });
const cardInteractor = cardUseCases({ cardRepo });
const teamInteractor = teamUseCases({ teamRepo });
const chatInteractor = chatUseCases({ chatRepo });
const roleInteractor = roleUseCases({ roleRepo });
const planInteractor = planUseCases({ planRepo });
const pageInteractor = pageUseCases({ pageRepo });
const paymentInteractor = paymentUseCases({ paymentRepo });

export const dependencies = {
  httpAdapter,
  todoInteractor,
  authInteractor,
  boardInteractor,
  localStorageInteractor,
  cardInteractor,
  teamInteractor,
  chatInteractor,
  roleInteractor,
  planInteractor,
  pageInteractor,
  paymentInteractor,
};

export type Dependencies = typeof dependencies;

export { paymentRepo };
