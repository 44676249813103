import { buildSignInUser, signInUserUseCase } from './signin-user';
import { buildSignOut, signOutUseCase } from './signout';
import { buildGoogleSignIn, googleSignInUseCase } from './signin-google';
import {
  buildFetchUserDetails,
  fetchUserDetailsUseCase,
} from './fetch-user-details';
import { buildFbSignIn, fbSignInUseCase } from './signin-fb';
import {
  buildMicrosoftSignIn,
  microsoftSignInUseCase,
} from './signin-microsoft';
import { buildSignUp, signUpUseCase } from './sign-up';
import { buildGoogleSignUp, googleSignUpUseCase } from './signup-google';
import { buildFbSignUp, fbSignUpUseCase } from './signup-fb';
import {
  buildMicrosoftSignUp,
  microsoftSignUpUseCase,
} from './signup-microsoft';
import {
  buildResendVerification,
  resendVerificationUseCase,
} from './resend-verification';
import { buildUpdateUser, updateUserUseCase } from './update-user';
import { buildForgotPassword, forgotPasswordUseCase } from './forgot-password';
import { buildResetPassword, resetPasswordUseCase } from './reset-password';

import AuthService from '../../ports/AuthService';
import { buildDeleteUser, deleteUserUseCase } from './delete-user';

export default (dependencies: {
  authService: AuthService;
}): {
  signIn: signInUserUseCase;
  signOut: signOutUseCase;
  googleSignIn: googleSignInUseCase;
  fbSignIn: fbSignInUseCase;
  microsoftSignIn: microsoftSignInUseCase;
  signUp: signUpUseCase;
  googleSignUp: googleSignUpUseCase;
  fbSignUp: fbSignUpUseCase;
  microsoftSignUp: microsoftSignUpUseCase;
  resendVerification: resendVerificationUseCase;
  fetchUserDetails: fetchUserDetailsUseCase;
  updateUser: updateUserUseCase;
  deleteUser: deleteUserUseCase;
  forgotPassword: forgotPasswordUseCase;
  resetPassword: resetPasswordUseCase;
} => {
  const { authService } = dependencies;
  const signIn = buildSignInUser({ authService });
  const signOut = buildSignOut({ authService });
  const googleSignIn = buildGoogleSignIn({ authService });
  const fbSignIn = buildFbSignIn({ authService });
  const microsoftSignIn = buildMicrosoftSignIn({ authService });
  const signUp = buildSignUp({ authService });
  const googleSignUp = buildGoogleSignUp({ authService });
  const fbSignUp = buildFbSignUp({ authService });
  const microsoftSignUp = buildMicrosoftSignUp({ authService });
  const resendVerification = buildResendVerification({ authService });
  const updateUser = buildUpdateUser({ authService });
  const fetchUserDetails = buildFetchUserDetails({ authService });
  const deleteUser = buildDeleteUser({ authService });
  const forgotPassword = buildForgotPassword({ authService });
  const resetPassword = buildResetPassword({ authService });

  return {
    signIn,
    signOut,
    googleSignIn,
    fbSignIn,
    microsoftSignIn,
    signUp,
    googleSignUp,
    fbSignUp,
    microsoftSignUp,
    resendVerification,
    updateUser,
    fetchUserDetails,
    deleteUser,
    forgotPassword,
    resetPassword,
  };
};
