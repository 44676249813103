import React, { SVGProps } from 'react';

type Props = SVGProps<SVGSVGElement>;

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="10"
      height="20"
      viewBox="0 0 10 20"
      fill="black"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path d="M4.53749 15H6.46149V5.38H4.53749V15Z" fill="inherit" />
    </svg>
  );
};

export default Component;
