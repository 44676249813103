import { createContext, useContext } from 'react';
import { Customer, User } from '../../domain/entities/user';
import { Card } from '../../domain/entities/card';
import { Props as INotification } from '../../components/atoms/Toast/Component';

export type CurrentUser =
  | { user: User; invitation?: { status: boolean; message: string } }
  | undefined;

export type GlobalState = {
  useCurrentUser: {
    currentUser: CurrentUser;
    setCurrentUser: (user?: object) => void;
    updateUserCustomerFields: (customer: Partial<Customer>) => void;
  };
  useCurrentCards: {
    activeCard: Card | undefined;
    currentFlippedCards: Card[];
    currentCardIds: (string | number)[];
    socketCard: Card | undefined;
    setActiveCard: (card: Card | undefined) => void;
    setCurrentFlippedCards: (cards: Card[]) => void;
    setCurrentCardIds: (ids: (string | number)[]) => void;
    setSocketCard: (card: Card | undefined) => void;
  };
  useToast: {
    notification: INotification | null;
    setNotification: (notification?: object) => void;
  };
};

export const GlobalStateContext = createContext<GlobalState | null>(null);

export const useGlobalState = (): GlobalState => {
  const context = useContext(GlobalStateContext);
  if (!context) {
    throw new Error(`Context not instantiated`);
  }
  return context;
};
