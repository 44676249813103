const colors = {
  primary: '#FFD200',
  primaryDisabled: 'rgba(96, 191, 145, 0.4)',
  secondary: '#37C88C',
  secondaryDisabled: 'rgba(55, 200, 140, 0.4)',
  black: '#000000',
  white: '#FFFFFF',
  strokeLight: '#DEE2E6',
  baseColor: '#68717A',
  iris60: 'rgba(253, 203, 9, 0.6)',
  primary60: 'rgba(96, 191, 145, 0.6)',
  textColor: '#737373',
  primaryBtn: '#23A6F0',
  hoverPrimaryBtn: '#2094D8',
  activePrimaryBtn: '#1D84C0',
  disabledPrimaryBtn: 'rgba(35, 166, 240, 0.4)',
  secondaryBtn: '#37C88C',
  hoverSecondaryBtn: '#30B37D',
  activeSecondaryBtn: '#2B9F6F',
  disabledSecondaryBtn: 'rgba(27, 197, 115, 0.4)',
  hoverLightSecondaryBtn: '#56AC83',
  activeLightSecondaryBtn: '#56AC83',
  disabledLightSecondaryBtn: 'rgba(96, 191, 145, 0.4)',
  lightBtn: '#FFD200',
  hoverLightBtn: '#E6BB00',
  activeLightBtn: '#CCA700',
  disabledLightBtn: 'rgba(253, 235, 192, 0.4)',
  warningBtn: '#CB5559',
  hoverWarningBtn: '#B84D51',
  activeWarningBtn: '#A34548',
  disabledWarningBtn: 'rgba(203, 85, 89, 0.4)',
  disabledBlackHollowBtn: 'rgba(0, 0, 0, 0.4)',
  disabledBlackHollowText: 'rgba(115, 115, 115, 0.4)',
  inputBorder: '#737373',
  activeCheckboxBorder: '#6e7485',
  text01: '#232D42',
  text02: '#8A92A6',
  linkColor: '#3F7FE0',
  textError: '#FF0000',
  errorToastBorder: '#DF1E1E',
  errorToast: '#FFDEDE',
  errorToastText: '#E0232E',
  successToastBorder: '#76D8A3',
  successToast: '#F2FFF7',
  successToastText: '#2DC071',
  infoToastBorder: '#D0D0D0',
  infoToast: '#F3F3F3',
  infoToastText: '#252B42',
  notifToastBorder: '#60BF91',
  notifToast: '#F8FAFF',
  gameCardBaseColor: '#FDCB09',
  gameCardBorderColor: '#CEA400',
  gameCardWrapperBaseColor: '#EE6D43',
  gameCardSubWrapperColor: '#FFFAE8',
  otherHintRoundBtn: '#34A0A7',
  otherHintRoundBtnActive: '#2B959D',
  paleYellow: '#FEE06B',
  bgPaleGray: '#F7F7F7',
  modalBg: 'rgba(81, 81, 81, 0.36)',
  favBtn: '#E64040',
  dropdownBg: '#F9F9F9',
  dropdownText: '#252B42',
  dropdownBorder: '#E6E6E6',
  dropdownOptionBg: '#E4E4E4',
  dropdownOptionHover: 'rgba(204, 204, 204, 0.4)',
  dropdownOptionSelected: 'rgba(182, 182, 182, 0.4)',
  dropdownOptionSelectedHover: 'rgba(159, 159, 159, 0.4)',
  border: '#e4e4e4',
  myboardMenuBg: '#F9F8F8',
  myBoardMenuBgHover: 'rgba(200, 200, 200, 0.25)',
  myBoardHomeLabel: 'rgba(96, 191, 145, 0.6)',
  inActiveMyBoardHomeLabel: 'rgba(115, 115, 115, 0.6)',
  cardListBorder: '#DEDEDE',
  roleItemBorder: '#BEBBBA',
  roleSelectBg: 'rgba(81, 81, 81, 0.36)',
  roleSelectTitleUnderline: '#18CF77',
  topHeaderBorder: 'rgba(4, 4, 4, 0.09)',
  topFooterBg: 'rgba(196, 196, 196, 0.17)',
  inlineErrorText: '#842029',
  inlineErrorToastBg: '#F8D7DA',
  inlineErrorToastBorder: '#F5C2C7',
  addTeamMemberDropdownBorder: '#DADADA',
  warningBg: '#FFF3CD',
  warningBorder: '#FFEEBA',
  warningText: '#856404',
  upgradePlanBorder: '#D7D7D7',
  planDuration: '#8EC2F2',
  gray: '#C1C5C3',
  burgerHover: '#5C5C5C',
  adminNavigationColor: '#A5443E',
  heartBorder: '#E74040',
  emailInstruction: '#C4C4C4',
};

// primary = JP, secondary = EN
const fonts = {
  primary: 'コーポレートロゴ・M',
  secondary: 'Brain Storming Arial Rounded MT Bold',
};

const breakpoints = {
  mobile: '(min-width: 320px) and (max-width: 576px)',
  tablet: '(min-width: 577px) and (max-width: 922px)',
  pc: '(min-width: 923px)',
};

export default {
  colors,
  fonts,
  breakpoints,
};
