import React, { CSSProperties } from 'react';
import ReactFlow, {
  ConnectionMode,
  Edge,
  Node,
  ReactFlowProvider,
} from 'react-flow-renderer';
import styled from 'styled-components';
import {
  customEdgeTypes,
  customNodeTypes,
} from '../../templates/DiagramEditor/components';

const Wrapper = styled.div`
  position: relative;
  display: inline-flex;
  height: 100%;
  width: 100%;
  user-select: none;
  .public-DraftEditor-content {
    /* appears to be the only way to disble highlighting of contenteditable=true because user-select is inline style from draft-js */
    user-select: none !important;
    user-modify: read-only;
  }
`;
const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
`;

const StyledReactFlow = styled(ReactFlow)`
  z-index: 1;
`;

export type Props = {
  diagramId: string;
  style?: CSSProperties;
};

const Component = ({
  nodes,
  edges,
}: {
  nodes: Node[];
  edges: Edge[];
}): React.ReactElement => {
  return (
    <Wrapper>
      <Overlay />
      <ReactFlowProvider>
        <StyledReactFlow
          elementsSelectable={false}
          zoomOnPinch={false}
          zoomOnScroll={false}
          zoomOnDoubleClick={false}
          panOnDrag={false}
          panOnScroll={false}
          onInit={ref => setTimeout(() => ref.fitView({ minZoom: 0 }), 300)}
          nodeTypes={customNodeTypes}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          edgeTypes={customEdgeTypes}
          connectionMode={ConnectionMode.Loose}
          nodes={nodes}
          edges={edges}
        />
      </ReactFlowProvider>
    </Wrapper>
  );
};

export default Component;
