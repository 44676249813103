import React from 'react';

type Props = {
  [key: string]: string | number | Function;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 18"
      {...props}>
      <defs>
        {/* <style>.cls-1{fill:#f79910;}.cls-2{fill:#cc1009;}.cls-3{fill:#f8b340;}.cls-4{fill:#160a66;}.cls-5{fill:#fff;}</style> */}
      </defs>
      <g id="layer1">
        <g id="g10305">
          <path
            id="path2268"
            fill="#f79910"
            d="M20,9A6,6,0,0,1,8,9H8A6,6,0,1,1,20,9Z"
          />
          <path
            id="path1350"
            fill="#cc1009"
            d="M6,3A6,6,0,0,0,6,15a5.93,5.93,0,0,0,4-1.56h0a5.26,5.26,0,0,0,.61-.64H9.4A5,5,0,0,1,9,12.18h2.11a5.07,5.07,0,0,0,.35-.65H8.6a4.73,4.73,0,0,1-.25-.62h3.31a6,6,0,0,0,.17-3.18H8.17c.05-.22.11-.43.17-.63h3.32a4.51,4.51,0,0,0-.26-.64H8.6a6.31,6.31,0,0,1,.34-.63h2.12a5,5,0,0,0-.47-.64H9.41a6.92,6.92,0,0,1,.6-.61A5.93,5.93,0,0,0,6,3Z"
          />
          <path
            id="use9412"
            fill="#f8b340"
            d="M19.4,12.14a.15.15,0,0,1,.09,0,.19.19,0,0,1,.08.07.26.26,0,0,1,0,.2.23.23,0,0,1-.07.07l-.1,0-.1,0a.23.23,0,0,1-.07-.07.26.26,0,0,1,0-.2.23.23,0,0,1,.07-.07.2.2,0,0,1,.1,0m0,0-.08,0a.09.09,0,0,0-.06.06.19.19,0,0,0,0,.08.24.24,0,0,0,0,.08.09.09,0,0,0,.06.06l.08,0,.08,0a.09.09,0,0,0,.06-.06.24.24,0,0,0,0-.08.19.19,0,0,0,0-.08l-.06-.06a.12.12,0,0,0-.08,0m-.09.27v-.21h.13l0,0v0a.05.05,0,0,1,0,0,0,0,0,0,1,0,0h0l0,.05,0,0h0l0,0,0,0h0v.09h0m0-.12h.08s0,0,0,0v0h-.08v.06"
          />
        </g>
        <g id="g16480">
          <g id="g13802">
            <path
              id="path13804"
              fill="#160a66"
              d="M8,10.72l.08-.54-.16,0c-.22,0-.25-.12-.23-.2l.21-1.09h.34l.07-.62H8L8,7.92H7.36S7,10,7,10.27a.47.47,0,0,0,.5.54A1.41,1.41,0,0,0,8,10.72Z"
            />
            <path
              id="path13806"
              fill="#160a66"
              d="M8.17,9.69a1,1,0,0,0,1.1,1.12A1.94,1.94,0,0,0,10,10.7l.11-.62a2.36,2.36,0,0,1-.72.16c-.68,0-.56-.51-.56-.51h1.31a4.49,4.49,0,0,0,.09-.58.88.88,0,0,0-.93-.94C8.65,8.22,8.17,8.91,8.17,9.69Zm1.12-.9c.36,0,.29.41.29.44h-.7S8.94,8.79,9.29,8.79Z"
            />
            <path
              id="path13808"
              fill="#160a66"
              d="M13.33,10.72l.11-.69a1.49,1.49,0,0,1-.53.16c-.45,0-.63-.35-.63-.72,0-.75.39-1.17.82-1.17a1.11,1.11,0,0,1,.59.19l.1-.68a1.28,1.28,0,0,0-.64-.22c-1,0-1.56.69-1.56,1.89a1.17,1.17,0,0,0,1.18,1.33A3,3,0,0,0,13.33,10.72Z"
            />
            <path
              id="path13810"
              fill="#160a66"
              d="M4.47,8.23a2.26,2.26,0,0,0-.78.14l-.09.55a2.09,2.09,0,0,1,.7-.11c.23,0,.41,0,.41.22a.48.48,0,0,1,0,.16l-.28,0c-.62,0-1.13.24-1.13.95a.6.6,0,0,0,.62.68.8.8,0,0,0,.64-.28v.23h.56L5.34,9A.75.75,0,0,0,4.47,8.23Zm.09,1.43c0,.1,0,.61-.41.61A.23.23,0,0,1,3.9,10c0-.18.1-.39.56-.39C4.57,9.64,4.55,9.65,4.56,9.66Z"
            />
            <path
              id="path13812"
              fill="#160a66"
              d="M5.94,10.79c.14,0,1,0,1-.81s-.76-.64-.76-1c0-.16.13-.21.35-.21l.44,0L7,8.28a3.06,3.06,0,0,0-.59,0c-.47,0-1,.19-1,.84s.8.65.8,1-.22.22-.4.22a2.55,2.55,0,0,1-.56-.09l-.09.55A1.88,1.88,0,0,0,5.94,10.79Z"
            />
            <path
              id="path13814"
              fill="#160a66"
              d="M18.58,7.72l-.11.86a.85.85,0,0,0-.62-.33c-.7,0-1.07.7-1.07,1.51,0,.52.26,1,.79,1a.83.83,0,0,0,.6-.26l0,.23h.62l.48-3Zm-.27,1.67c0,.34-.17.78-.51.78s-.34-.19-.34-.49c0-.49.22-.82.5-.82S18.31,9,18.31,9.39Z"
            />
            <path
              id="path13816"
              fill="#160a66"
              d="M1,10.76,1.4,8.47l.06,2.29H1.9l.82-2.29-.36,2.29H3l.5-3h-1L1.86,9.57l0-1.85H.89l-.51,3Z"
            />
            <path
              id="path13818"
              fill="#160a66"
              d="M10.83,10.76c.19-1,.26-1.89.8-1.71A3.57,3.57,0,0,1,12,8.11s0,0-.19,0-.67.58-.67.58l.06-.36h-.59l-.4,2.47Z"
            />
            <g id="use14699">
              <path
                id="path13810-2"
                data-name="path13810"
                fill="#160a66"
                d="M14.66,8.23a2.17,2.17,0,0,0-.77.14l-.09.55a2,2,0,0,1,.69-.11c.24,0,.41,0,.41.22a.48.48,0,0,1,0,.16l-.27,0c-.62,0-1.13.24-1.13.95a.6.6,0,0,0,.61.68.8.8,0,0,0,.64-.28v.23h.56L15.54,9A.75.75,0,0,0,14.66,8.23Zm.1,1.43c0,.1,0,.61-.41.61A.23.23,0,0,1,14.1,10c0-.18.09-.39.56-.39C14.77,9.64,14.74,9.65,14.76,9.66Z"
              />
            </g>
            <path
              id="use14701"
              fill="#160a66"
              d="M16.18,10.76c.1-.77.29-1.86.8-1.71.07-.41,0-.41-.17-.41h-.35l.06-.36h-.59l-.4,2.47Z"
            />
          </g>
          <g id="g10289">
            <path
              id="path4157"
              fill="#ffffff"
              d="M8.14,10.53,8.22,10l-.17,0c-.22,0-.24-.12-.23-.21L8,8.7h.34l.08-.6H8.1l.07-.37H7.53s-.38,2.09-.38,2.34.21.55.51.54A1.63,1.63,0,0,0,8.14,10.53Z"
            />
            <path
              id="path4155"
              fill="#ffffff"
              d="M8.34,9.5a1,1,0,0,0,1.1,1.11,1.64,1.64,0,0,0,.67-.1l.12-.62a2,2,0,0,1-.68.16c-.68,0-.57-.51-.57-.51h1.3A4.7,4.7,0,0,0,10.36,9,.85.85,0,0,0,9.45,8C8.81,8,8.34,8.72,8.34,9.5Zm1.12-.9c.36,0,.29.4.29.43H9S9.11,8.6,9.46,8.6Z"
            />
            <path
              id="path4151"
              fill="#ffffff"
              d="M13.49,10.53l.12-.69a1.65,1.65,0,0,1-.53.15.62.62,0,0,1-.63-.71c0-.76.39-1.17.82-1.17a1.14,1.14,0,0,1,.58.18L14,7.62a2.28,2.28,0,0,0-.72-.16c-.74,0-1.45.64-1.45,1.84,0,.79.38,1.32,1.15,1.32A2.82,2.82,0,0,0,13.49,10.53Z"
            />
            <path
              id="path4149"
              fill="#ffffff"
              d="M4.63,8a2.24,2.24,0,0,0-.77.14l-.09.55a1.85,1.85,0,0,1,.69-.12c.24,0,.41,0,.41.22a.41.41,0,0,1,0,.16H4.58c-.55,0-1.13.23-1.13.94a.61.61,0,0,0,.61.69.85.85,0,0,0,.66-.29l0,.24h.56l.25-1.75A.75.75,0,0,0,4.63,8Zm.14,1.43c0,.1-.07.61-.45.61a.24.24,0,0,1-.25-.24c0-.18.09-.39.56-.39C4.74,9.45,4.75,9.46,4.77,9.47Z"
            />
            <path
              id="path4145"
              fill="#ffffff"
              d="M6.1,10.6c.15,0,1,0,1-.81s-.76-.64-.76-1c0-.16.12-.2.35-.2l.43,0,.08-.56A2.88,2.88,0,0,0,6.59,8c-.47,0-1,.19-1,.83s.79.66.79,1-.22.22-.39.22a1.93,1.93,0,0,1-.57-.1l-.08.56A2.08,2.08,0,0,0,6.1,10.6Z"
            />
            <path
              id="path4139"
              fill="#ffffff"
              d="M18.77,7.53l-.14.86A.84.84,0,0,0,18,8.05c-.58,0-1.07.71-1.07,1.52,0,.52.26,1,.79,1a.79.79,0,0,0,.59-.27l0,.23h.62l.49-3ZM18.48,9.2c0,.33-.17.78-.52.78s-.33-.19-.33-.49c0-.5.22-.82.5-.82S18.48,8.82,18.48,9.2Z"
            />
            <path
              id="path4133"
              fill="#ffffff"
              d="M1.18,10.56l.39-2.28.06,2.28h.44l.82-2.28-.37,2.28h.65l.51-3h-1L2,9.39,2,7.53H1.08l-.51,3Z"
            />
            <path
              id="path4131"
              fill="#ffffff"
              d="M11,10.57c.19-1.06.22-1.91.66-1.76a2.87,2.87,0,0,1,.24-.74h-.12c-.29,0-.5.39-.5.39l.06-.36h-.6l-.39,2.47Z"
            />
            <g id="use8523">
              <path
                id="path4149-2"
                data-name="path4149"
                fill="#ffffff"
                d="M15,8a2.24,2.24,0,0,0-.77.14l-.09.55a1.91,1.91,0,0,1,.69-.12c.24,0,.41,0,.41.22a.41.41,0,0,1,0,.16h-.27c-.55,0-1.13.23-1.13.94a.61.61,0,0,0,.61.69.85.85,0,0,0,.66-.29l0,.24h.56l.25-1.75A.75.75,0,0,0,15,8Zm.14,1.43c0,.1-.07.61-.45.61a.24.24,0,0,1-.25-.24c0-.18.09-.39.56-.39C15.07,9.45,15.08,9.46,15.1,9.47Z"
              />
            </g>
            <g id="use8525">
              <path
                id="path4131-2"
                data-name="path4131"
                fill="#ffffff"
                d="M16.35,10.57c.19-1.06.22-1.91.66-1.76a2.87,2.87,0,0,1,.24-.74h-.12c-.29,0-.5.39-.5.39l.06-.36h-.6l-.39,2.47Z"
              />
            </g>
            <path
              id="text9407"
              fill="#ffffff"
              d="M19.4,10.19l.09,0a.16.16,0,0,1,.07.07.22.22,0,0,1,0,.1.19.19,0,0,1,0,.1.16.16,0,0,1-.07.07.15.15,0,0,1-.09,0,.16.16,0,0,1-.1,0l-.07-.07a.19.19,0,0,1,0-.1.22.22,0,0,1,0-.1.23.23,0,0,1,.07-.07l.1,0m0,0-.08,0a.09.09,0,0,0-.06.06.12.12,0,0,0,0,.08.11.11,0,0,0,0,.08.09.09,0,0,0,.06.06l.08,0,.08,0,.06-.06a.19.19,0,0,0,0-.08.24.24,0,0,0,0-.08.17.17,0,0,0-.06-.06l-.08,0m-.09.27v-.21h.13l0,0a0,0,0,0,1,0,0,.08.08,0,0,1,0,0h-.05l0,0s0,0,0,0l0,0h0l0,0,0,0h0v.09h0m0-.12h.08v0h-.08v.06"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Component;
