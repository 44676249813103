import React from 'react';
import styled from 'styled-components';
import { Lock } from '../../../../atoms/Icons';

const Overlay = styled.div`
  background-color: #33669922;
  position: absolute;
`;

const LockWrapper = styled.div`
  position: absolute;
  right: 2px;
  top: 2px;
`;

export type Props = {
  width?: number;
  height?: number;
  transform?: string;
  zIndex?: number;
  style?: React.CSSProperties;
};

const SPACE_AROUND = 20;

const Component = ({
  width = 0,
  height = 0,
  transform,
  zIndex,
  style,
}: Props): React.ReactElement => {
  return (
    <Overlay
      className="nodrag"
      style={{
        width: (width || 0) + SPACE_AROUND,
        height: (height || 0) + SPACE_AROUND,
        top: -SPACE_AROUND / 2,
        left: -SPACE_AROUND / 2,
        transform,
        zIndex,
        ...style,
      }}>
      <LockWrapper>
        <Lock width={15} height={15} />
      </LockWrapper>
    </Overlay>
  );
};

export default Component;
