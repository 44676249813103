import React, { SVGProps } from 'react';

type Props = SVGProps<SVGSVGElement>;

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="61"
      height="8"
      viewBox="0 0 61 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M52.2857 8L30.5 3L8.71429 8L0 7L30.5 0L61 7L52.2857 8Z"
        fill="#CACACA"
      />
    </svg>
  );
};

export default Component;
