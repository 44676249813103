import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import activeInvoiceTab from '../../../assets/images/active-invoice-tab.svg';
import activeProfileTab from '../../../assets/images/active-profile-tab.svg';
import invoiceTab from '../../../assets/images/invoice-tab.svg';
import profileTab from '../../../assets/images/profile-tab.svg';
import theme from '../../../constants/themes';
import { MyBoardTeam } from '../../../domain/entities/team';
import { isWindowsOS } from '../../../utils/booleans';
import { Button } from '../../atoms/Button';
import {
  AccordionArrow,
  ActiveHome,
  GrayPlus,
  Home,
  Plus,
} from '../../atoms/Icons';
import { MyBoardItem } from '../../atoms/MyBoardItem';
import { MyBoardSubItem } from '../../atoms/MyBoardSubItem';
import { useGlobalState } from '../../../hooks/global';

const Container = styled.div`
  width: 300px;
  height: calc(100vh - 50px);
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 50px;
  z-index: 998;
  background-color: ${theme.colors.myboardMenuBg};

  @media ${theme.breakpoints.mobile} {
    display: none;
  }

  @media ${theme.breakpoints.tablet} {
    display: none;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const NewBoardBtn = styled(Button)`
  width: 200px;
  height: 44px;
  margin-top: 21px;
  margin-bottom: 7px;
  margin-left: auto;
  margin-right: auto;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
`;

const HomeWrapper = styled.div`
  width: 279px;
  height: 69px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: inherit;
  padding-left: 21px;
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.myBoardMenuBgHover};
  }
`;

const homeThemes = {
  home: {
    fontWeight: 'bolder',
    color: theme.colors.notifToastBorder,
  },
  personal: {
    fontWeight: 600,
    color: theme.colors.textColor,
  },
  team: {
    fontWeight: 600,
    color: theme.colors.textColor,
  },
  profile: {
    fontWeight: 600,
    color: theme.colors.textColor,
  },
  'plan-billing': {
    fontWeight: 600,
    color: theme.colors.textColor,
  },
};

const HomeLabel = styled.span<{
  item: 'home' | 'personal' | 'team' | 'profile' | 'plan-billing';
}>`
  font-family: ${theme.fonts.primary};
  font-weight: ${props => homeThemes[props.item].fontWeight};
  font-size: 14px;
  line-height: 24px;
  color: ${props => homeThemes[props.item].color};
  margin-left: 23px;
  padding-top: ${isWindowsOS() ? 6 : 0}px;
`;

const StyledAccordionArrow = styled(AccordionArrow)`
  position: absolute;
  right: 20px;
  align-self: center;
`;

const TeamsLabel = styled.div<{ isActive?: boolean; isopen?: boolean }>`
  height: 66px;
  padding-left: 66px;
  display: flex;
  align-items: center;
  font-family: ${theme.fonts.primary};
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: ${props =>
    props.isActive ? theme.colors.notifToastBorder : theme.colors.textColor};
  background-color: ${theme.colors.myboardMenuBg};
  position: relative;
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.myBoardMenuBgHover};
  }

  ${StyledAccordionArrow} {
    transform: ${props => (props.isopen ? `rotate(180deg)` : `rotate(0deg)`)};
  }
`;

const TeamsWrapper = styled.div<{ isopen?: boolean }>`
  display: ${props => (props.isopen ? 'block' : 'none')};
  width: 300px;
  height: max-content;
  max-height: calc(100vh - 50px - 483px);
  overflow-y: auto;
  overflow-x: hidden;
`;

const AddTeamBtn = styled(Button)`
  width: 133px;
  height: 44px;
  border-radius: 5px;
  margin: 11px auto;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
`;

export type Props = {
  teams: MyBoardTeam[];
  activeItem: 'home' | 'personal' | 'team' | 'profile' | 'plan-billing';
  className?: string;
  onPressNewChannel: () => void;
  onPressAddTeam: () => void;
};

const Component = ({
  teams,
  activeItem,
  onPressNewChannel,
  onPressAddTeam,
  className,
}: Props): React.ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isTeamListOpen, setIsTeamListOpen] = useState(activeItem === 'team');
  const { useCurrentUser } = useGlobalState();
  const { currentUser } = useCurrentUser;
  useEffect(() => {
    if (
      activeItem === 'profile' ||
      activeItem === 'personal' ||
      activeItem === 'home' ||
      activeItem === 'plan-billing'
    ) {
      setIsTeamListOpen(false);
    }
  }, [activeItem]);

  const renderHomeIcon = () => {
    switch (activeItem) {
      case 'home':
        return <ActiveHome />;
      default:
        return <Home />;
    }
  };

  return (
    <Container className={className}>
      <ContentWrapper>
        <NewBoardBtn
          title={t('newChannel')}
          theme="lightSecondary"
          onPress={onPressNewChannel}
          icon={<Plus />}
        />
        <HomeWrapper onClick={() => navigate('/my-board/personal')}>
          {renderHomeIcon()}
          <HomeLabel item={activeItem}>{t('home')}</HomeLabel>
        </HomeWrapper>
        <TeamsLabel
          isActive={activeItem === 'team'}
          isopen={isTeamListOpen}
          onClick={() => setIsTeamListOpen(!isTeamListOpen)}>
          {t('teams')}
          <StyledAccordionArrow />
        </TeamsLabel>
        <TeamsWrapper isopen={isTeamListOpen}>
          {teams.length > 0 &&
            teams.map(team => {
              return (
                <MyBoardSubItem
                  key={team.id}
                  to={`/my-board/team/${team.id}`}
                  label={team.name}
                />
              );
            })}
        </TeamsWrapper>
        {currentUser?.user?.customer?.plan?.type !== 'FREE' ? (
          <AddTeamBtn
            theme="blackHollow"
            title={t('addTeam')}
            icon={<GrayPlus />}
            onPress={onPressAddTeam}
          />
        ) : (
          ''
        )}
        <MyBoardItem
          to="/my-board/profile"
          label={t('profile')}
          icon={profileTab}
          activeIcon={activeProfileTab}
        />
        <MyBoardItem
          to="/my-board/plan-billing"
          label={t('invoice')}
          icon={invoiceTab}
          activeIcon={activeInvoiceTab}
        />
      </ContentWrapper>
    </Container>
  );
};

export default Component;
