import React, { SVGProps } from 'react';

type Props = SVGProps<SVGSVGElement>;

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="10"
      height="20"
      viewBox="0 0 10 20"
      fill="black"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M5.49985 15.182C7.70985 15.182 9.02285 13.843 9.02285 12.27C9.02285 10.892 8.25585 10.138 7.09885 9.657L5.83785 9.137C5.01885 8.812 4.32985 8.552 4.32985 7.863C4.32985 7.239 4.86285 6.862 5.70785 6.862C6.50085 6.862 7.13785 7.148 7.76185 7.655L8.72385 6.446C7.94385 5.653 6.82585 5.211 5.70785 5.211C3.77085 5.211 2.37985 6.42 2.37985 7.993C2.37985 9.384 3.36785 10.177 4.32985 10.567L5.61685 11.113C6.47485 11.477 7.05985 11.711 7.05985 12.426C7.05985 13.089 6.53985 13.518 5.53885 13.518C4.69385 13.518 3.77085 13.102 3.09485 12.465L1.98985 13.791C2.92585 14.688 4.19985 15.182 5.49985 15.182Z"
        fill="inherit"
      />
      <path d="M1.44385 10.775H9.55585V11.425H1.44385V10.775Z" fill="inherit" />
    </svg>
  );
};

export default Component;
