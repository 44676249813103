import React from 'react';

type Props = {
  [key: string]: string | number | Function;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <mask
        id="mask0_29_2407"
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="-1"
        y="0"
        width="17"
        height="16">
        <path
          d="M7.99988 16C12.4182 16 15.9999 12.4183 15.9999 8C15.9999 7.41157 15.9364 6.83798 15.8158 6.28571H7.99988V9.6H12.4053C11.7514 11.4 10.0258 12.6857 7.99988 12.6857C5.41203 12.6857 3.31416 10.5878 3.31416 8C3.31416 5.41215 5.41203 3.31429 7.99988 3.31429C9.31896 3.31429 10.5107 3.85935 11.3623 4.73654L13.6944 2.38108C12.2438 0.911091 10.2283 0 7.99988 0C3.5816 0 -0.00012207 3.58172 -0.00012207 8C-0.00012207 12.4183 3.5816 16 7.99988 16Z"
          fill="#C4C4C4"
        />
      </mask>
      <g mask="url(#mask0_29_2407)">
        <path
          d="M-5.14282 8.02905L3.47781e-05 3.65707L5.71432 8.11422L3.49322e-05 12.3428L-5.14282 8.02905Z"
          fill="#FEBD01"
        />
        <path
          d="M0 3.6571L6.45714 -2.80004L13.4286 -0.285753L16.4571 5.08568L7.82857 5.42853L5.71429 8.11425L0 3.6571Z"
          fill="#FF4131"
        />
        <path
          d="M7.6571 5.94293L16.9142 5.71436L16.8 9.82864L14.5142 14.7429L9.82853 11.0858L7.6571 10.0572V5.94293Z"
          fill="#0286F7"
        />
        <path
          d="M0 12.3428L5.71429 8.11426L6.34286 10.9714L9.82857 11.0857L14.5143 14.7428L9.77143 16.8L3.88571 16.4571L0 12.3428Z"
          fill="#01A94D"
        />
      </g>
    </svg>
  );
};

export default Component;
