import React from 'react';

type Props = {
  [key: string]: string | number | Function | object;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="43"
      height="39"
      viewBox="0 0 43 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g clipPath="url(#clip0_2104_8963)">
        <path
          d="M35.5239 9.01027L8.55612 15.549C7.71549 15.7761 7.4826 16.8512 8.28302 17.3316L28.9033 30.7003C29.4877 31.1338 30.3495 30.8089 30.5846 30.2459L36.8241 10.3151C37.1226 9.45891 36.3646 8.78309 35.5239 9.01027Z"
          stroke="black"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M34.8592 9.99176L17.4329 22.5879L15.8873 29.7224C15.6968 30.602 16.8868 31.3714 17.683 30.8276L23.2772 26.9235"
          stroke="black"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2104_8963">
          <rect
            width="35.3588"
            height="32"
            fill="white"
            transform="translate(7.62842) rotate(12.2229)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Component;
