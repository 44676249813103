import * as Yup from 'yup';
import i18n from '../../../constants/words';

export const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email(i18n.t('invalidEmail'))
    .required(i18n.t('requiredEmail'))
    .max(255, i18n.t('maxLengthEmail')),
  password: Yup.string()
    .required(i18n.t('requiredPassword'))
    .min(8, i18n.t('passwordMinLength'))
    .max(255, i18n.t('maxLengthPassword')),
});
