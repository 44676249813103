import AuthService from '../../ports/AuthService';

export type signUpUseCase = (
  name: string,
  email: string,
  password: string,
  invitationCode: string,
) => Promise<object | null>;

export const buildSignUp = (dependencies: {
  authService: AuthService;
}): signUpUseCase => {
  const { authService } = dependencies;

  const signUp: signUpUseCase = (name, email, password, invitationCode) =>
    authService.signUp(name, email, password, invitationCode);

  return signUp;
};
