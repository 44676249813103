import {
  buildFetchRandomCard,
  fetchRandomCardUseCase,
} from './fetch-random-card';

import CardRepository from '../../ports/CardRepository';

export default (dependencies: {
  cardRepo: CardRepository;
}): {
  fetchRandomCard: fetchRandomCardUseCase;
} => {
  const { cardRepo } = dependencies;
  const fetchRandomCard = buildFetchRandomCard({ cardRepo });

  return { fetchRandomCard };
};
