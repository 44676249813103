import CardRepository from '../../usecases/ports/CardRepository';
import { HttpAdapter } from '../../usecases/ports/HttpAdapter';
import { RandomCard } from '../../domain/entities/card';

export default class CardRepositoryImpl implements CardRepository {
  httpAdapter: HttpAdapter;
  urls: { [key: string]: string };

  constructor(httpAdapter: HttpAdapter, urls: { [key: string]: string }) {
    this.httpAdapter = httpAdapter;
    this.urls = urls;
  }

  find = async (exclusionCardIds: (string | number)[]): Promise<RandomCard> => {
    const response = await this.httpAdapter.get(this.urls.getRandomCard, {
      params: { exclusion_card_ids: exclusionCardIds },
    });

    const data = response.data.data;
    const cards = {
      availableCards: data.available_cards,
      randomCard: {
        id: data.random_card.id,
        cardName: data.random_card.card_name,
        cardImage: data.random_card.card_image,
        cardContent: data.random_card.card_content,
        createdBy: data.random_card?.created_by,
        updatedBy: data.random_card?.updated_by,
        createdAt: data.random_card?.created_at,
        updatedAt: data.random_card?.updated_at,
      },
    };

    return cards;
  };
}
