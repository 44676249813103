import React from 'react';
import { PageContent } from '../../templates/PageContent';

type Props = {};

const Component = ({}: Props): React.ReactElement => {
  return <PageContent />;
};

export default Component;
