import React from 'react';
import styled from 'styled-components';
import Skeleton from '@mui/material/Skeleton';
import theme from '../../../constants/themes';
import { Board, Star, Trophy } from '../../atoms/Icons';
import { Tooltip } from '../../atoms/Tooltip';
import { isWindowsOS } from '../../../utils/booleans';
import { handleTextOverflow } from '../../../utils/strings';
import { nFormatter } from '../../../utils/number';

const Container = styled.div`
  background-color: ${theme.colors.white};
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  display: inline-flex;
  min-height: 225px;
  max-height: 225px;
  width: 150px;
  cursor: pointer;
  position: relative;

  &:hover {
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.2);
    transform: scale(0.98);
  }
`;

const Wrapper = styled.span`
  background-color: ${theme.colors.lightBtn};
  border-radius: 5px;
  padding: 10px 10px 7px 10px;
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  flex: 1;
`;

const NameLabel = styled.span`
  font-style: normal;
  font-weight: 200;
  font-size: 9px;
  line-height: ${isWindowsOS() ? 11 : 16}px;
  text-align: center;
  text-transform: uppercase;
  color: ${theme.colors.white};
  margin-top: ${isWindowsOS() ? 3 : 0}px;
  letter-spacing: 2px;
`;

const RoleLabel = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: ${isWindowsOS() ? 12 : 16}px;
  text-align: center;
  text-transform: uppercase;
  color: ${theme.colors.white};
  padding-top: ${isWindowsOS() ? 8 : 0}px;
  word-break: break-word;
  white-space: pre-wrap;
  letter-spacing: 2px;
  margin-top: 11px;
`;

const EnRoleLabel = styled.span<{ fontSize?: string }>`
  font-style: normal;
  font-weight: 200;
  font-size: ${({ fontSize }) => fontSize || '11px'};
  line-height: ${isWindowsOS() ? 5 : 9}px;
  text-align: center;
  color: ${theme.colors.white};
  margin-top: ${isWindowsOS() ? 5 : 0}px;
  letter-spacing: 2px;
`;

const Separator = styled.div`
  width: 90%;
  height: 2px;
  border-radius: 1px;
  background-color: ${theme.colors.white};
  margin: 5px 0px;
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 129.79px;
  border-radius: 5px;
  img {
    width: 100%;
    height: 129.79px;
    border-radius: 5px;
    object-fit: cover;
  }
`;

const Achievemnts = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 5px;
  left: 5px;
  height: 62px;
  width: max-content;
`;

const AchievementBadge = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1.5px;
  background-color: ${theme.colors.secondary};
  border-radius: 4px;
  width: 36px;
  height: 15px;
`;

const StarBadge = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.primary};
  height: 40px;
  width: 40px;
  border-radius: 60% 0 0 0;
  color: ${theme.colors.white};
  position: absolute;
  top: 110.79px;
  right: 0;

  &:active {
    transform: scale(0.97);
  }

  svg {
    position: absolute;
    height: 28.44px;
    width: 30.77px;
    path {
      fill: ${theme.colors.secondary};
    }
  }
  span {
    position: absolute;
    z-index: 2;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 29px;
    text-transform: uppercase;
    font-family: ${theme.fonts.primary};
    padding-top: ${isWindowsOS() ? 9 : 0}px;
  }
`;

const AchievementCount = styled.span`
  ${isWindowsOS() ? 'height: 10px' : ''};
  font-family: ${theme.fonts.primary};
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: ${theme.colors.white};
  text-align: left;
  width: max-content;
`;

export type Props = {
  className?: string;
  id: number;
  name: string;
  englishRole?: string;
  role: string;
  boards: number;
  stars: number;
  trophies: number;
  badge: number;
  displayPhoto: string;
  isLoading?: boolean;
  onClick: (id: number) => void;
  onClickVote: (customerId: number) => void;
};

const Component = ({
  id,
  className,
  name,
  englishRole,
  role,
  boards,
  stars,
  trophies,
  badge,
  displayPhoto,
  isLoading,
  onClick,
  onClickVote,
}: Props): React.ReactElement => {
  const handleOnClick = () => onClick(id);

  const handleOnClickVote = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    !isLoading && onClickVote(id);
  };

  return (
    <Container onClick={handleOnClick} className={className}>
      <Achievemnts>
        <AchievementBadge>
          <Board />
          <AchievementCount>{nFormatter(boards, 1)}</AchievementCount>
        </AchievementBadge>
        <AchievementBadge>
          <Star />
          <AchievementCount>{nFormatter(stars, 1)}</AchievementCount>
        </AchievementBadge>
        <AchievementBadge>
          <Trophy />
          <AchievementCount>{nFormatter(trophies, 1)}</AchievementCount>
        </AchievementBadge>
      </Achievemnts>
      <StarBadge onClick={handleOnClickVote}>
        {isLoading && (
          <Skeleton
            animation="wave"
            variant="circular"
            width={50}
            height={40}
            sx={{
              backgroundColor: '#6e6b693d',
            }}
          />
        )}
        <>
          <Star />
          {badge.toString().length > 9 ? (
            <Tooltip label={badge.toString()} position="right">
              <span style={{ fontSize: '14px' }}>9+</span>
            </Tooltip>
          ) : (
            <span>{badge}</span>
          )}
        </>
      </StarBadge>
      <Wrapper>
        <ImageWrapper>
          <img src={displayPhoto} />
        </ImageWrapper>
        <RoleLabel>{role}</RoleLabel>
        <EnRoleLabel>{englishRole}</EnRoleLabel>
        <Separator />
        {name.length <= 15 ? (
          <NameLabel>{name}</NameLabel>
        ) : (
          <Tooltip label={name} position="right">
            <NameLabel>{handleTextOverflow(name, 15)}</NameLabel>
          </Tooltip>
        )}
      </Wrapper>
    </Container>
  );
};

export default Component;
