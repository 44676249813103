import React, { forwardRef, useImperativeHandle } from 'react';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import { Stop, Success, Info, NotifBell } from '../Icons';
import theme from '../../../constants/themes';
import { useSnackbar, VariantType } from 'notistack';
import PropTypes from 'prop-types';

const themes = {
  error: {
    border: `thin solid ${theme.colors.errorToastBorder}`,
    backgroundColor: `${theme.colors.errorToast}`,
    color: `${theme.colors.errorToastText}`,
  },
  success: {
    border: `thin solid ${theme.colors.successToastBorder}`,
    backgroundColor: `${theme.colors.successToast}`,
    color: `${theme.colors.successToastText}`,
  },
  info: {
    border: `thin solid ${theme.colors.infoToastBorder}`,
    backgroundColor: `${theme.colors.infoToast}`,
    color: `${theme.colors.infoToastText}`,
  },
  default: {
    border: `thin solid ${theme.colors.notifToastBorder}`,
    backgroundColor: `${theme.colors.notifToast}`,
    color: `${theme.colors.notifToastBorder}`,
  },
};

const CustomizedAlert = styled(MuiAlert)<{
  type: 'success' | 'error' | 'info' | 'default';
}>`
  && {
    background-color: ${(props): string => themes[props.type].backgroundColor};
    border: ${(props): string => themes[props.type].border};
    border-radius: 0px;
    box-shadow: 0px 13px 19px rgba(0, 0, 0, 0.07);
    font-family: ${theme.fonts.primary};
    font-size: 16px;
    line-height: 26px;
    font-weight: 500;
    color: ${(props): string => themes[props.type].color};
    top: 100px;
    padding: 6px 0;
  }

  .MuiAlert-icon {
    padding-left: 16px;
  }

  .MuiAlert-action {
    padding: 4px 16px 0 16px;
    margin-left: 0;
    margin-right: 0;
  }
`;

export type Props = {};

const Component = ({}: Props, ref: React.Ref<any>): React.ReactElement => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const Icons: { [key: string]: React.ReactNode } = {
    success: <Success />,
    error: <Stop />,
    info: <Info />,
    default: <NotifBell />,
  };

  const Alert = forwardRef<
    HTMLDivElement,
    AlertProps & {
      icontype: 'success' | 'error' | 'info' | 'default' | null | undefined;
    }
  >(function Alert(props, ref) {
    return (
      <CustomizedAlert
        elevation={6}
        ref={ref}
        variant="filled"
        type={props.icontype ?? 'info'}
        {...props}
      />
    );
  });

  Alert.propTypes = {
    icontype: PropTypes.oneOf([
      'success',
      'error',
      'default',
      'info',
      null,
      undefined,
    ]),
  };

  useImperativeHandle(ref, () => ({
    toggleSnackBar(
      message: string,
      type: VariantType,
      position:
        | 'top-center'
        | 'top-right'
        | 'bottom-right'
        | 'bottom-left'
        | 'top-left',
    ) {
      const keyPosition = {
        vertical: position.split('-')[0] as 'top' | 'bottom',
        horizontal: position.split('-')[1] as 'left' | 'center' | 'right',
      };

      enqueueSnackbar(message, {
        variant: type,
        anchorOrigin: keyPosition,
        autoHideDuration: 5000,
        content: (key, message) => {
          return (
            <Alert
              key={key}
              onClose={() => closeSnackbar(key)}
              sx={{ width: '100%' }}
              icontype={
                type as
                  | 'success'
                  | 'error'
                  | 'info'
                  | 'default'
                  | null
                  | undefined
              }
              icon={Icons[type]}>
              {message}
            </Alert>
          );
        },
      });
    },
  }));

  return <></>;
};

export default forwardRef(Component);
